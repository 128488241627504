import { FC } from "react";
import { Meeting } from "../../interfaces/Meeting";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import moment from "moment";
import { useIntl } from "react-intl";
import { getFileType, getNameUrl, isFileUrl, truncateText } from "../../functions/general";

interface MeetingAttachmentsProps {
    meeting: Meeting,
    title?: string
    url?: string | string[]
}

const MeetingAttachments: FC<MeetingAttachmentsProps> = ({ meeting, title, url }) => {
    const intl = useIntl()

    return (
        <div className="modal fade" tabIndex={-1} id={`attachments-meeting-modal-${meeting.id}`}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        {
                            title ?
                                <h5 className="modal-title">{title}</h5>
                                :
                                <h5 className="modal-title">
                                    {intl.formatMessage({ id: "FORM.LABEL.PHOTO" }, { title: meeting.merchant_name })} <br />
                                    {moment(meeting.date_meet).format('DD MMM YYYY')}
                                </h5>
                        }
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body text-center w-100">
                        {
                            title ?
                                typeof url == "string" ?
                                    <img src={url} alt={title} className="img-fluid" />
                                    :
                                    isFileUrl(url![0]) ?
                                        url!.map((data: string, index: number) =>
                                            <>
                                                <div className="d-flex align-items-center border border-dashed border-gray-300 rounded ps-2 mb-3">
                                                    {
                                                        getFileType(data) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} alt="" style={{ width: "20px" }} />
                                                            : getFileType(data) === "doc" || getFileType(data) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} alt="" style={{ width: "20px" }} />
                                                                : getFileType(data) === "ppt" || getFileType(data) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} alt="" style={{ width: "20px" }} />
                                                                    : getFileType(data) === "xlsx" || getFileType(data) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} alt="" style={{ width: "20px" }} />
                                                                        : <img src={toAbsoluteUrl('/media/logos/file.png')} alt="" style={{ width: "20px" }} />
                                                    }
                                                    <a key={index} href={data} className="m-3" target="_blank" rel="noopener noreferrer">{getNameUrl(data)}</a>
                                                </div>
                                            </>
                                        )
                                        :
                                        url!.map((data: string, index: number) =>
                                            <>
                                                <div className="d-flex align-items-center border border-dashed border-gray-300 rounded ps-2 mb-3">
                                                    <img src={toAbsoluteUrl('/media/logos/image.png')} alt="" style={{ width: "20px" }} />
                                                    <a key={index} href={data} className="m-3" target="_blank" rel="noopener noreferrer">{getNameUrl(data)}</a>
                                                </div>
                                            </>
                                        )
                                :
                                meeting.attachments && meeting.attachments.map((attachment, index) => (
                                    <img src={attachment.url} alt={attachment.name} key={index} className="img-fluid" />
                                ))

                        }
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                        >
                            {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { MeetingAttachments }