import RadioGroup from "@mui/material/RadioGroup";
import { StringSupportOption } from "prettier";
import { useEffect, useState } from "react";
import { FormControlLabel, Radio } from "@mui/material";
import { Pie } from 'react-chartjs-2';
import { Modal } from "bootstrap";
import { WidgetPieChartModal } from "./WidgetPieChartModal";
import { useIntl } from "react-intl";
import useNameWidget from "../../hooks/NameWidget";
import { customNumberFormat, roundNominal } from "../../functions/general";

type Props = {
    className: string
    title: string
    isLoading: boolean
    data: any
    date?: string
}

const DashboardPie: React.FC<Props> = ({ className, title, data, isLoading, date }) => {
    const [productLabels, setProductLabels] = useState<string[]>([])
    const [productQty, setProductQty] = useState<number[]>([])
    const [productNominal, setProductNominal] = useState<number[]>([])
    const [productColors, setProductColors] = useState<string[]>([])

    const [pieValue, setPieValue] = useState({} as any)
    const [pieRadioValue, setPieRadioValue] = useState<"nominal" | "quantity">("nominal")

    const [showPieChartModal, setShowPieChartModal] = useState<boolean>(false);
    const [tableDataPieChart, setTableDataPieChart] = useState([]);
    const intl = useIntl()
    const nameWidget = data.title.includes("Monthly") ? `${data.alias} (${intl.formatMessage({ id: "FORM.LABEL.MONTHLY" })})` : data.alias;
    // const nameWidget = useNameWidget(data.title);

    const handlePieValue = (value: any) => {
        setPieRadioValue(value.target.value);
        if (value.target.value === "nominal") {
            setPieValue(productNominal)
        } else if (value.target.value === "quantity") {
            setPieValue(productQty)
        }
    }

    const handleShowModal = () => {
        if (pieRadioValue === 'quantity') {
            setTableDataPieChart(data.contents[0].values)
        } else if (pieRadioValue === 'nominal') {
            setTableDataPieChart(data.contents[1].values)
        }
        setShowPieChartModal(true)
    }

    useEffect(() => {
        let productNames = data.contents[0].values.map((product: any) => (
            roundNominal(product.value) > 0 && product.name
        ))

        let productQtys = data.contents[0].values.map((product: any) => (
            roundNominal(product.value)
        ))

        let productNominals = data.contents[1].values.map((product: any) => (
            roundNominal(product.value)
        ))

        let color = data.contents[0].values.map((product: any) => (
            product.color ?? "#D33115"
        ))

        setProductLabels(productNames)
        setProductNominal(productNominals)
        setProductQty(productQtys)
        setProductColors(color)

        if (pieRadioValue === "nominal") {
            setPieValue(productNominals)
        } else if (pieRadioValue === "quantity") {
            setPieValue(productQtys)
        }
    }, [data, pieRadioValue])

    useEffect(() => {
        if (showPieChartModal) {
            const modalElement = document.getElementById(`pie-chart-modal`);
            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowPieChartModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showPieChartModal, setShowPieChartModal]);


    return (
        <>
            <div className={`card card-effect mb-6 align-items-center h-100 ${className}`} onClick={() => handleShowModal()}>
                <div className="card-body" style={{ alignSelf: "center" }}>
                    <div className="card-title mb-3 d-block">
                        <span className="fs-3 fw-bolder"><i className='fas fa-info-circle me-2 fs-4' style={{ color: "#c2c4d0" }}></i>{nameWidget}</span><br />
                        <span>{date}</span>
                    </div>

                    {
                        !isLoading ?
                            data.contents[0].values.length > 0 ? (
                                <>
                                    <RadioGroup
                                        name="pieRadio"
                                        value={pieRadioValue}
                                        onChange={handlePieValue}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <FormControlLabel
                                                    value="nominal"
                                                    control={<Radio color="primary" />}
                                                    label={intl.formatMessage({ id: "FORM.LABEL.NOMINAL" })}
                                                    labelPlacement="end"
                                                />
                                            </div>
                                            <div className="col">
                                                <FormControlLabel
                                                    value="quantity"
                                                    control={<Radio color="primary" />}
                                                    label={intl.formatMessage({ id: "FORM.LABEL.QUANTITY" })}
                                                    labelPlacement="end"
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                    <Pie
                                        data={{
                                            labels: productLabels,
                                            datasets: [
                                                {
                                                    label: intl.formatMessage({ id: "FORM.LABEL.SOLD" }),
                                                    data: pieValue,
                                                    backgroundColor: productColors,
                                                    hoverOffset: 4,
                                                },
                                            ]
                                        }}
                                        style={{ maxWidth: "280px", maxHeight: "280px", minWidth: "280px", minHeight: "280px", margin: 'auto' }}
                                    />
                                </>
                            ) : (
                                <div className="" style={{ padding: "60px" }} onClick={(e) => e.stopPropagation()}>
                                    <h3>{intl.formatMessage({ id: "FORM.LABEL.DATA_NOT_FOUND" })}</h3>
                                </div>
                            )
                            :
                            <div className="loading-overlay">
                                <div className="loader"></div>
                            </div>
                    }
                </div>
            </div>

            {
                (showPieChartModal && !isLoading && data !== undefined) &&
                <WidgetPieChartModal tableData={tableDataPieChart} title={nameWidget!} type={pieRadioValue} />
            }
        </>
    )
}

export { DashboardPie }