import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';

const useHandleCloseModal = (modalRef: any, closeModalRef: any, formik: any) => {
    const intl = useIntl();

    const handleCloseModal = (e: any) => {
        e.preventDefault();
        var value = Object.entries(formik.values).filter(([key, value]) => value !== null && value !== "")

        if (value.length > 0) {
            Swal.fire({
                title: intl.formatMessage({ id: 'FORM.VALIDATION.CANCEL' }),
                text: intl.formatMessage({ id: 'FORM.VALIDATION.DESC_CANCEL' }),
                icon: 'warning',
                showCancelButton: !0,
                buttonsStyling: !1,
                confirmButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.YES' }) + ", " + intl.formatMessage({ id: 'FORM.ACTION.CANCEL' }),
                cancelButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.NO' }),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-active-light",
                },
                reverseButtons: true,
                heightAuto: false,
            }).then((result) => {
                if (result.isConfirmed) closeModalRef.current?.click()
            });
        } else {
            closeModalRef.current?.click()
        }
    };

    const handleClickOutside = (e: any) => {
        if (modalRef.current && modalRef.current === e.target) {
            e.preventDefault();
            var value = Object.entries(formik.values).filter(([key, value]) => value !== null && value !== "")
            if (value.length > 0) {
                Swal.fire({
                    title: intl.formatMessage({ id: 'FORM.VALIDATION.CANCEL' }),
                    text: intl.formatMessage({ id: 'FORM.VALIDATION.DESC_CANCEL' }),
                    icon: 'warning',
                    showCancelButton: !0,
                    buttonsStyling: !1,
                    confirmButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.YES' }) + ", " + intl.formatMessage({ id: 'FORM.ACTION.CANCEL' }),
                    cancelButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.NO' }),
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-active-light",
                    },
                    reverseButtons: true,
                    heightAuto: false,
                }).then((result) => {
                    if (result.isConfirmed) closeModalRef.current?.click()
                });
            } else {
                closeModalRef.current?.click()
            }
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [formik]);

    return {
        handleCloseModal
    };
};

export default useHandleCloseModal;