import React, { useState, useCallback, useRef, useEffect } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { FileMessage } from '../../interfaces/Email/FileMessage'
import { useEmailStore } from '../../stores/EmailStore'
import { useFormik } from 'formik'
import { sendReplyEmail } from '../../api/EmailCRUD'
import Swal from 'sweetalert2'
import ReactQuill, { Quill } from 'react-quill'
import DragAndDropFile from './BroadcastEmail/FileUploader/DragAndDropFile'
import { formatFileSize } from '../../functions/email'
import 'react-tooltip/dist/react-tooltip.css'
import quillEmoji from 'quill-emoji'
import 'quill-emoji/dist/quill-emoji.css'
import Resizer from 'react-image-file-resizer'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css';
import { useAttachmentGeneral } from './hooks/useAttachmentGeneral'
import { useUserStore } from '../../hooks/UserEmail'


const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji

Quill.register(
  {
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji,
  },
  true
)

type ReplyEmailProps = {
  setIsReplyOpen: (value: boolean) => void
  emailTo: string
  bodyForReply: string
  threadEmailDate?: string
  cc?: string[]
  replyType: 'reply' | 'replyAll'
}

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['link',],
    ['emoji'],
  ],
  'emoji-toolbar': true,
  'emoji-shortname': true,
  clipboard: {
    matchVisual: false,
  },
}

const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'link', 'image', 'emoji']

const ReplyEmail = ({ setIsReplyOpen, emailTo, bodyForReply, threadEmailDate, cc, replyType }: ReplyEmailProps) => {
  const { detailEmail, typeMenu, uidThread, isThreadOpen, setIsthreadOpen } = useEmailStore()
  const { userEmail } = useUserStore()
  const quillRef = useRef<ReactQuill>(null)
  const [isAlertShown, setIsAlertShown] = useState(false)
  const {
    temporaryCopyFile,
    setTemporaryCopyFile,
    dragAndDropFile,
    setDragAndDropFile,
    totalFileSize,
    setTotalFileSize,
    showModal,
    handleOpenAttachment,
    handleCloseModal,
    onDrop,
    removeFile,
  } = useAttachmentGeneral();


  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve((reader.result as string).split(',')[1])
      reader.onerror = (error) => reject(error)
    })
  }

  const formatNestedReply = (body: string) => {
    const lines = body.split('\n')
    let formattedBody = ''
    let quoteLevel = 0

    lines.forEach(line => {
      if ((line.startsWith('Pada') && line.includes('menulis:'))) {
        // Start a new quote level
        quoteLevel++
        formattedBody += `<blockquote style="margin:0px 0px 0px ${0.8 * quoteLevel}ex;border-left:1px solid rgb(204,204,204);padding-left:1ex;">\n`
        formattedBody += `<p>${line}</p>\n`
      } else if (line.startsWith('>')) {
        // Continue current quote level
        formattedBody += `<p>${line.substring(1).trim()}</p>\n`
      } else if (line.trim() === '') {
        // Empty line, potentially ending a quote level
        if (quoteLevel > 0) {
          quoteLevel--
          formattedBody += '</blockquote>\n'
        } else {
          formattedBody += '<p></p>\n'
        }
      } else {
        // Regular line
        formattedBody += `<p>${line}</p>\n`
      }
    })

    // Close any remaining blockquotes
    while (quoteLevel > 0) {
      formattedBody += '</blockquote>\n'
      quoteLevel--
    }

    return formattedBody
  }

  useEffect(() => {
    // UNTUK DETAIL EMAIL 
    const formatDay = moment(detailEmail.date).format('dddd')
    const formatDate = moment(detailEmail.date).format('D MMMM YYYY')
    const formatTime = moment(detailEmail.date).format('HH:mm')
    // UNTUK READ THREAD MAIL
    const formatDayThread = moment(threadEmailDate).format('dddd')
    const formatDateThread = moment(threadEmailDate).format('D MMMM YYYY')
    const formatTimeThread = moment(threadEmailDate).format('HH:mm')

    const formattedBodyForReply = formatNestedReply(bodyForReply)

    const templateForReply = `
                      <div class="gmail_quote">
                        <div dir="ltr" class="gmail_attr">
                          Pada ${!isThreadOpen ? formatDay : formatDayThread}, ${!isThreadOpen ? formatDate : formatDateThread} pukul ${!isThreadOpen ? formatTime : formatTimeThread} &lt;<a href="mailto:${emailTo}">${emailTo}</a>&gt; menulis:
                        </div>
                        ${formattedBodyForReply}
                      </div>
                    `

    if (quillRef.current) {
      const editor = quillRef.current.getEditor()

      // Insert newlines at the beginning
      editor.setText('\n\n')

      // Insert the template after the newlines
      editor.clipboard.dangerouslyPasteHTML(2, templateForReply)

      // Set cursor at the beginning
      editor.setSelection(0, 0)
    }

    // Update formik values if you're using formik
    // formik.setFieldValue('body', '\n\n' + templateForReply)
  }, [detailEmail.date, emailTo, bodyForReply])

  const getUidForReply = (uid: number) => {
    if (uid === undefined) {
      // console.log('berjalan')
      if (!uidThread) return
      return uidThread
    } else {
      return uid
    }
  }

  const formik = useFormik({
    initialValues: {
      from: userEmail?.email || '',
      to: emailTo,
      cc: replyType === 'replyAll' ? cc?.join(', ') || '' : '',
      body: '',
      files: [],
    },

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // console.log(values.cc)

      try {
        const response = await sendReplyEmail(getUidForReply(detailEmail.id) || detailEmail.id, typeMenu, values.body, values.files, values.cc)
        if (response?.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Berhasil Reply!',
            text: 'Reply Terkirim.',
            heightAuto: false,
          })
          formik.resetForm()
          setTotalFileSize(0)
          setIsReplyOpen(false)
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'Ok',
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  const toggleReplyEmail = () => {
    setIsReplyOpen(false)
  }

  const handleChangeBody = (value: any) => {
    if (value.replace(/<p>\s*<br\s*\/?>\s*<\/p>/g, '').trim().length === 0) {
      formik.setFieldValue('body', ' ')
    } else {
      formik.setFieldValue('body', value)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resizeImage = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200, // maksimum lebar
        200, // maksimum tinggi
        'PNG', // format output
        100, // kualitas output (0-100)
        0, // rotasi gambar
        (uri) => {
          resolve(uri as string)
        },
        'base64', // output dalam format base64
        200, // lebar output
        200 // tinggi output
      )
    })
  }

  const handlePaste = useCallback(
    async (event: ClipboardEvent) => {
      const items = event.clipboardData?.items
      if (items) {
        const fileItems = Array.from(items).filter((item) => item.kind === 'file')
        const filePromises = fileItems.map((item) => {
          return new Promise<File | null>(async (resolve, reject) => {
            const blob = item.getAsFile()
            if (blob) {
              try {
                if (item.type.startsWith('image/')) {
                  event.preventDefault()
                  const resizedImage = await resizeImage(blob)
                  const editor = quillRef.current?.getEditor()
                  const range = editor?.getSelection()
                  if (editor && range) {
                    editor.insertEmbed(range.index, 'image', resizedImage)
                  }
                  resolve(null)
                } else {
                  event.preventDefault()
                  resolve(blob)
                }
              } catch (error) {
                console.error('Error processing file:', error)
                reject(error)
              }
            } else {
              resolve(null)
            }
          })
        })

        try {
          const newFiles = await Promise.all(filePromises)
          const validFiles = newFiles.filter((file): file is File => file !== null)
          setTemporaryCopyFile((prevState: File[]) => [...prevState, ...validFiles])
          const newTotalFileSize = validFiles.reduce((total: number, file: File) => total + file.size, 0)
          setTotalFileSize(prevNumber => prevNumber + newTotalFileSize)
        } catch (error) {
          console.error('Error processing files:', error)
        }
      }
    },
    [quillRef, formik, convertFileToBase64, resizeImage]

  )

  useEffect(() => {
    if (!totalFileSize) return
    if (totalFileSize > 25 * 1024 * 1024 && !isAlertShown) {
      setIsAlertShown(true)
      if (!showModal) {
        Swal.fire({
          icon: 'warning',
          title: 'Peringatan!',
          text: 'Total Size Lebih dari 25mb, Klik OK untuk menghapus file terakhir',
          heightAuto: false,
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
            let currentSize = totalFileSize
            const updatedFiles = [...temporaryCopyFile]

            while (currentSize > 25 * 1024 * 1024 && updatedFiles.length > 0) {
              const removedFile = updatedFiles.pop() as File
              currentSize -= removedFile.size
            }

            formik.setFieldValue('files', updatedFiles)
            setTemporaryCopyFile(updatedFiles)
            setTotalFileSize(currentSize)
          }
          setIsAlertShown(false)
        })
      }

    }
  }, [totalFileSize, formik.values.files, isAlertShown, showModal])

  useEffect(() => {
    formik.setFieldValue('files', temporaryCopyFile)
  }, [temporaryCopyFile])

  useEffect(() => {
    const editor = quillRef.current?.getEditor()
    if (editor) {
      editor.root.addEventListener('paste', handlePaste)
      return () => {
        editor.root.removeEventListener('paste', handlePaste)
      }
    }
  }, [handlePaste])

  return (
    <div className='container-fluid' style={{ border: '0.7px solid #9098a3', borderRadius: '10px' }}>
      <style>
        {
          `
                    .ql-toolbar {
                    border-top: none !important;
                    border-left: none !important;
                    border-right: none !important;
                    border-bottom: 1px solid #EFF2F5 !important;
                    border: none !important;
                    padding: 8px 17px !important;
                    }

                    .ql-container{
                        border: none !important;
                        padding: 5px 15px !important;
                    }
                  `
        }
      </style>
      <div>
        <div className='card-header align-items-center'>
          <div className='card-title'>
            <h2>Reply Email</h2>
          </div>
        </div>
        <div className='card-body p-0'>
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className='d-block'>
              <div className='align-items-center border-bottom ps-8 min-h-50px d-flex'>
                <div className='text-dark fw-bolder'>Kepada</div>
                <input
                  disabled
                  type='text'
                  className='form-control form-control-transparent border-0'
                  {...formik.getFieldProps('to')}
                />
              </div>
              {replyType === 'replyAll' && (
                <div className='align-items-center border-bottom ps-8 min-h-50px d-flex'>
                  <div className='text-dark fw-bolder'>Cc</div>
                  <input
                    disabled
                    type='text'
                    className='form-control form-control-transparent border-0'
                    {...formik.getFieldProps('cc')}
                  />
                </div>
              )}
              <div className='ql-toolbar ql-snow p-0 border-bottom border-top-0 border-right-0 border-left-0 pb-3'>
                <div id='kt_inbox_form_editor' className='bg-transparent border-0 h-400px'>
                  <ReactQuill
                    ref={quillRef}
                    style={{ height: '85%' }}
                    theme='snow'
                    value={formik.values.body}
                    onChange={handleChangeBody}
                    modules={modules}
                    formats={formats}
                  />
                </div>
                {showModal && (
                  <DragAndDropFile
                    onDrop={onDrop}
                    onClose={handleCloseModal}
                    values={temporaryCopyFile}
                    totalFileSize={totalFileSize}
                    setTotalFileSize={setTotalFileSize}
                    valuesDragAndDropFile={dragAndDropFile}
                    setTemporaryFile={setTemporaryCopyFile}
                    setValuesDragAndDropFile={setDragAndDropFile}
                  />
                )}
                {temporaryCopyFile.map((file: File, index) => {
                  return (
                    <div className='dropzone dropzone-queue px-8 py-1' key={index}>
                      <div
                        className='dropzone-items'
                        style={{ padding: '7px', borderRadius: '5px', backgroundColor: '#f1faff' }}
                      >
                        <div
                          className='dropzone-item dz-processing dz-success dz-complete d-flex justify-content-between'
                          id=''
                          style={{ alignItems: 'center' }}
                        >
                          <div className='dropzone-file'>
                            <div
                              className='dropzone-filename'
                              title='some_image_file_name.jpg'
                              style={{ color: '#7e8299' }}
                            >
                              <span data-dz-name=''>{file.name}</span>
                              <strong> ({formatFileSize(file.size)})</strong>
                            </div>
                            <div className='dropzone-error' data-dz-errormessage=''></div>
                          </div>
                          <div className='dropzone-toolbar'>
                            <span
                              className='dropzone-delete cursor-pointer'
                              onClick={() => removeFile(index)}
                            >
                              <i className='bi bi-x fs-1'></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='d-flex flex-stack gap-2 py-5 ps-8 pe-5 border-top'>
              <div className='d-flex align-items-center me-3'>
                <span className='btn btn-sm  px-4 me-2 button-hover' onClick={handleOpenAttachment}>
                  <span className='svg-icon svg-icon-2'>
                    <KTSVG path='/media/svg/general/attachment.svg' />
                  </span>
                  <span className='ms-2 d-none d-md-inline-block'>Lampiran</span>
                </span>
              </div>
              <div className='d-flex align-items-center'>
                <span className='cursor-pointer' onClick={toggleReplyEmail}>
                  <h5 className='my-auto'>Batal</h5>
                </span>
                <div
                  style={{ width: '1px', height: '35px', backgroundColor: '#ccc', margin: '0 10px' }}
                ></div>
                <div className='btn-group me-4'>
                  <button
                    type='submit'
                    className={`btn btn-primary fs-bold px-6 ${formik.isSubmitting ? 'disabled' : ''
                      }`}
                    data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}
                    disabled={totalFileSize > 25 * 1024 * 1024}
                  >
                    <span className='indicator-label'>Kirim</span>
                    <span className='indicator-progress'>
                      Loading...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ReplyEmail
