import React, { FC, useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import { useEmailStore } from '../../stores/EmailStore'
import { Message, useToaster } from 'rsuite'
import { loginEmail } from '../../api/EmailCRUD'
import { useUserStore } from '../../hooks/UserEmail'

const LoginEmail: FC = () => {
  const { setTokenEmail, setTypeEmail } = useEmailStore()
  const closeModalRef = useRef<HTMLButtonElement>(null)
  const toaster = useToaster();
  const [showPassword, setShowPassword] = useState(false);
  const { setUserEmail } = useUserStore()

  // component toaster
  const message = (
    <Message showIcon type={'success'} closable>
      <strong>{'Welcome'}!</strong> Login Wooblazz Mail Berhasill.
    </Message>
  );

  const formikEmail = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email harus diisi'),
      password: Yup.string().required('Password harus diisi'),
    }),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await loginEmail(
          values.email,
          values.password,
          '52-220-99-204.cprapid.com'
        );
        if (response?.status === 200) {
          setUserEmail(response?.data.data[0])
          setTokenEmail(response?.data.token);
          localStorage.setItem('token_email', response?.data.token);
          setTypeEmail('listEmail');
          closeModalRef.current?.click();
          toaster.push(message, { placement: 'topEnd', duration: 3000 });
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error.response?.data.message,
          confirmButtonText: 'Ok',
          heightAuto: false,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className='d-flex flex-column flex-lg-row-fluid py-10 h-100'>
      <div className='d-flex flex-center flex-column flex-column-fluid'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '9px',
          }}
        >
          <img
            src='/media/logos/wooblazz-email.png'
            alt='Wooblazz Mail Logo'
            className='w-350px w-md-400 w-lg-420px'
          />
          <div className='w-350px w-md-450 w-lg-500px p-3 p-lg-7 mx-auto bg-body rounded shadow-sm'>
            <div className='text-center mb-15'>
              <h1 className='text-dark'>Masuk Email</h1>
            </div>
            <ul
              className='nav nav-pills nav-pills-custom row position-relative mx-0 mb-9'
              style={{ justifyContent: 'center' }}
            >
              <li className='nav-item col-4 mx-0 p-0'>
                <a
                  className='nav-link d-flex justify-content-center w-100 border-0 h-100 active'
                  data-bs-toggle='pill'
                  href='#widget_looyal'
                >
                </a>
              </li>
              <span className='position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded'></span>
            </ul>

            <div className='tab-content'>
              <div className='tab-pane fade active show' id='widget_looyal'>
                <form
                  onSubmit={formikEmail.handleSubmit}
                  noValidate
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                >
                  <div className='fv-row mb-10 fv-plugins-icon-container'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Email</label>
                    <input
                      value={formikEmail.values.email}
                      onChange={(e) => {
                        formikEmail.setFieldValue('email', e.target.value.replace(/\s/g, ''))
                      }}
                      className={clsx('form-control form-control-lg form-control-solid txt-email', {
                        'is-invalid': formikEmail.touched.email && formikEmail.errors.email,
                      })}
                      type='text'
                      name='email'
                      autoComplete='off'
                    />
                    {formikEmail.touched.email && formikEmail.errors.email && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert' className='text-danger'>
                          {formikEmail.errors.email}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10 fv-plugins-icon-container'>
                    <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>
                      Password
                    </label>
                    <div className='input-group'>
                      <input
                        value={formikEmail.values.password}
                        onChange={(e) => {
                          formikEmail.setFieldValue('password', e.target.value.replace(/\s/g, ''))
                        }}
                        className={clsx('form-control form-control-lg form-control-solid txt-password', {
                          'is-invalid': formikEmail.touched.password && formikEmail.errors.password,
                        })}
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        autoComplete='off'
                      />
                      <button
                        type='button'
                        className='btn btn-outline-secondary'
                        onClick={togglePasswordVisibility}
                      >
                        <span className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></span>
                      </button>
                    </div>
                    {formikEmail.touched.password && formikEmail.errors.password && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert' className='text-danger'>
                          {formikEmail.errors.password}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='btnSubmitLooyal'
                      className={`btn btn-lg btn-primary w-100 mb-5 ${formikEmail.isSubmitting ? 'disabled' : ''
                        }`}
                      data-kt-indicator={formikEmail.isSubmitting ? 'on' : 'off'}
                    >
                      <span className='indicator-label'>Masuk</span>
                      <span className='indicator-progress'>
                        Loading...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <span className='mt-3 opacity-50'>
            <h5>2024 &copy; PT Tunas Kreasi Digital</h5>
          </span>
        </div>
      </div>
    </div>
  )
}

export { LoginEmail }
