import { FC, useEffect, useRef, useState } from "react";
import { useChatStore } from "../../../stores/ChatStore";
import { ScanQR } from "../ScanQR";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import useAccessToken from "../../../hooks/AccessToken";
import { checkSession } from "../../../api/ChatHelper";
import { clearDB } from "../../../db";
import SelectUser from "./SelectUser";
import { useCustomeStore } from "../../../stores/CustomeStore";
import useUser from "../../../hooks/User";
import useSWR from "swr";
import axios from "axios";
import { UserChat } from "../../../interfaces/Chat/UserChat";
import { useTokenCustomCompany } from "../../../stores/useTokenCustomCompany";
import { USER_MANAGER_HAPPY_PLAY } from "../../../constant/General";
import useShowCompanyById from "../../../hooks/useShowCompanyById";

const API_URL = process.env.REACT_APP_API_URL

interface OnboardingProps {
    totalRecentChats: number
    totalChats: number
}

const Onboarding: FC<OnboardingProps> = ({ totalRecentChats, totalChats }) => {
    const { connected, isFirstSyncing, persistedMessages, setConnected, recentChatHistories, recentChatIsSyncing } = useChatStore()

    const token = useAccessToken()
    const [persistedMessagesCount, setPersistedMessagesCount] = useState(0)
    const [recentChatProgress, setRecentChatProgress] = useState(0)
    const [progress, setProgress] = useState(0)
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const [countConnected, setCountConnected] = useState(0)
    const { companyId } = useCustomeStore()
    const user = useUser()
    const { tokenCustomCompany } = useTokenCustomCompany()
    const { company } = useShowCompanyById(user.data.company_id)
    

    const checkConnection = async () => {
        try {
            let customToken = ''
            if (company) {
                customToken = tokenCustomCompany;
            }

            const response = await checkSession(token, customToken, company || null)

            if (response.data.data.isActive) {
                setCountConnected((prevCount) => {
                    if (prevCount < 3) {
                        return prevCount + 1;
                    } else {
                        setConnected(response.data.data.isActive);
                        return prevCount; // Jangan increment jika sudah mencapai batas
                    }
                });
            } else setCountConnected(0)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!connected) {
            intervalRef.current = setInterval(checkConnection, 500);
        } else if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [connected, company]);

    useEffect(() => {

        let persistedMessagesCount = 0

        Object.keys(persistedMessages).forEach((key) => {
            persistedMessagesCount += persistedMessages[key].messages.length
        })

        // console.log(persistedMessagesCount, totalChats)

        setPersistedMessagesCount(persistedMessagesCount)

        setProgress(Math.trunc(persistedMessagesCount / totalChats * 100))
    }, [persistedMessages, totalChats])

    useEffect(() => {
        setRecentChatProgress(Math.trunc(recentChatHistories.length / totalRecentChats * 100))
    }, [recentChatHistories, totalRecentChats])

    return (
        <>
            {
                !connected &&
                <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
                    <div className="card card-custom w-100" style={{ maxWidth: "800px" }}>
                        <div className="card-header text-center">
                            <img className="img-fluid py-5" src={toAbsoluteUrl('/media/logos/wooblazz-chat.png')} alt='WooBlazz CRM Logo' style={{ width: "24%", height: "fit-content" }} />
                        </div>
                        {/* {
                            user.data.company_id === companyId.happyPlay ? (
                                <div className="card-body">
                                    <h1>Select User to login Wooblazz Chat</h1>
                                    <br />
                                    <SelectUser users={usersChat || []} isLoading={isLoadingChat} />
                                </div>
                            ) : (
                              
                            )
                        } */}
                        <div className="card-body row">
                            <div className="col-md-7">
                                <h1>Use WooBlazz CRM Chat</h1>
                                <br />
                                <ol className="fs-5">
                                    <li>Buka WhatsApp di ponsel Anda.</li>
                                    <li>Tekan Menu untuk di Android, atau Settings di iPhone</li>
                                    <li>Tekan Linked devices lalu Link a device</li>
                                    <li>Arahkan ponsel Anda ke layar ini untuk mengscan kode QR</li>
                                </ol>
                            </div>
                            <div className="col-md-5 d-flex justify-content-center align-items-center">
                                <ScanQR />
                            </div>
                        </div>
                    </div>
                </div >
            }
            {/* {
                connected && isFirstSyncing &&
                <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
                    <div className="card card-custom w-100">
                        <div className="card-header text-center">
                            <img className="img-fluid py-5" src={toAbsoluteUrl('/media/logos/wooblazz-chat.png')} alt='WooBlazz CRM Logo' style={{ width: "250px", height: "100px" }} />
                        </div>
                        <div className="card-body">
                            {
                                recentChatIsSyncing ?
                                    <>
                                        <h1>Fetching Data {!isNaN(recentChatProgress) ? recentChatProgress : 0}%</h1>
                                        <br />
                                        <div className="progress" role="progressbar">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${recentChatProgress}%` }}></div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <h1>Syncing Messages {!isNaN(progress) ? progress : 0}%</h1>
                                        <br />
                                        <div className="progress" role="progressbar">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${progress}%` }}></div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div >
            } */}
        </>
    )
}

export { Onboarding }