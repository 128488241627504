import React, {useState, useRef, useCallback, useEffect, createContext, useContext} from 'react'
import Select, {components} from 'react-select'
import {useVirtualizer} from '@tanstack/react-virtual'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

export const VirtualizedSelectContext = createContext<any | undefined>(undefined)

export interface Option {
  id?: string
  label: string
  value: string
  color?: string
  nameCustomField?: string
  nameTemplate?: string
  nameMerchant?: string
  idCustomField?: string
  idTemplate?: string
  idMerchant?: string
  customFieldCompanyCode?: string
  idMerchantLink?: string
}

export interface VirtualizedSelectOptionsType {
  id?: string
  value?: string
  label: string
  group?: string
  options?: Option[]
  nameCustomField?: string
  nameTemplate?: string
  nameMerchant?: string
  idCustomField?: string
  idTemplate?: string
  idMerchant?: string
  customFieldCompanyCode?: string
  idMerchantLink?: string
}

export interface VirtualizedSelectProps {
  title?: string
  key?: string
  options: VirtualizedSelectOptionsType[]
  value: Option | Option[] | null
  // onChange: (option: Option | Option[]) => void
  onChange: (option: any) => void
  onBlur?: () => void
  placeholder?: string
  className?: string
  isLoading?: boolean
  isShowGroup?: boolean
  isDisabled?: boolean
  isMulti?: boolean
  isClearable?: boolean
  estimateSize?: number
  overscan?: number
  components?: {
    ClearIndicator?: React.ElementType<any>
    Control?: React.ElementType<any>
    DropdownIndicator?: React.ElementType<any>
    DownChevron?: React.ElementType<any>
    CrossIcon?: React.ElementType<any>
    Group?: React.ElementType<any>
    GroupHeading?: React.ElementType<any>
    IndicatorsContainer?: React.ElementType<any>
    IndicatorSeparator?: React.ElementType<any>
    Input?: React.ElementType<any>
    LoadingIndicator?: React.ElementType<any>
    Menu?: React.ElementType<any>
    MenuList?: React.ElementType<any>
    MenuPortal?: React.ElementType<any>
    LoadingMessage?: React.ElementType<any>
    NoOptionsMessage?: React.ElementType<any>
    MultiValue?: React.ElementType<any>
    MultiValueContainer?: React.ElementType<any>
    MultiValueLabel?: React.ElementType<any>
    MultiValueRemove?: React.ElementType<any>
    Option?: React.ComponentType<{option: VirtualizedSelectOptionsType; onClick?: () => void}>
    Placeholder?: React.ElementType<any>
    SelectContainer?: React.ElementType<any>
    SingleValue?: React.ComponentType<{value: Option}>
    ValueContainer?: React.ElementType<any>
  }
}

export default function VirtualizedSelect({
  title = '',
  key,
  options,
  value,
  onChange,
  onBlur,
  placeholder = 'Select an option',
  className = '',
  isLoading = false,
  isShowGroup = true,
  isDisabled = false,
  isMulti = false,
  isClearable = true,
  estimateSize = 35,
  overscan = 5,
  components,
}: VirtualizedSelectProps) {
  const intl = useIntl()

  const [isOpen, setIsOpen] = useState(false)
  const [isGroup, setIsGroup] = useState(false)
  const [groupList, setGroupList] = useState<string[]>([])
  const [hover, setHover] = useState<string>()
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<VirtualizedSelectOptionsType[]>([])
  const [selectedOption, setSelectedOption] = useState<Option | Option[] | null>(null)
  const [searchTerm, setSearchTerm] = useState('')

  const containerRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  const getOptions = options ? options.flatMap((option) =>
    option.options
      ? option.options
          .filter((opt) => opt.label.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((opt) => ({...opt, group: option.label}))
      : option.label.toLowerCase().includes(searchTerm.toLowerCase())
      ? [option]
      : []
  ) : []

  useEffect(() => {
    // console.log('options', placeholder, options)
    if(options){
      const newGroupList = Array.from(
        new Set(options.flatMap((option) => (option.options ? [option.label] : [])))
      ).filter((label): label is string => label !== undefined)
      setGroupList((prev) => [...prev, ...newGroupList])
    } else {

    }
    setFilteredOptions(getOptions)
  }, [options])

  useEffect(() => {
    groupList.length > 0 ? setIsGroup(true) : setIsGroup(false)
  }, [groupList])

  useEffect(() => {
    if (Array.isArray(value)) {
      // Handle multi-select values
      const validValues = value.filter((val) =>
        options.some((option) =>
          option.options
            ? option.options.some((opt) => opt.value === val.value)
            : option.value === val.value
        )
      )
      // console.log('masokkkkk', title, validValues)
      setSelectedOption(validValues)
    } else if (
      value &&
      options.some((option) =>
        option.options
          ? option.options.some((opt) => opt.value === value.value)
          : option.value === value.value
      )
    ) {
      // Handle single select value
      setSelectedOption(value as Option)
    } else {
      setSelectedOption(null)
    }
  }, [value])

  useEffect(() => {
    if (searchTerm.length === 0 || searchTerm === '') {
      // setFilteredOptions(
      //   options.flatMap((option) =>
      //     option.options
      //       ? option.options
      //           .filter((opt) =>
      //             Array.isArray(selectedOption)
      //               ? selectedOption.some((val) => val.value === opt.value)
      //               : selectedOption?.value === opt.value
      //           )
      //           .map((opt) => ({...opt, group: option.label}))
      //       : option.label.toLowerCase()
      //       ? [option]
      //       : []
      //   )
      // )
      if (Array.isArray(selectedOption) && selectedOption.length > 0 && isMulti) {
        setFilteredOptions(
          getOptions.filter((opt) => {
            return !selectedOption.find((so) => {
              return opt.value === so.value && opt.label === so.label
            })
          })
        )
      } else {
        !Array.isArray(selectedOption) &&
          setFilteredOptions(
            getOptions.filter((opt) => {
              return selectedOption?.value !== opt.value && selectedOption?.label !== opt.label
            })
          )
      }
    } else {
      setFilteredOptions(getOptions)
    }
  }, [searchTerm])

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false)
        if (isOpen) {
          onBlur?.()
        }
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isOpen || filteredOptions.length === 0) return;
  
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActiveIndex((prev) =>
          prev === null || prev === filteredOptions.length - 1 ? 0 : prev + 1
        );
      }
  
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActiveIndex((prev) =>
          prev === null || prev === 0 ? filteredOptions.length - 1 : prev - 1
        );
      }
  
      if (e.key === 'Enter' && activeIndex !== null) {
        e.preventDefault();
        handleOptionClick(filteredOptions[activeIndex] as Option);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, filteredOptions, activeIndex]);
  

  const rowVirtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => estimateSize, []),
    overscan
  })

  useEffect(() => {
    if (activeIndex !== null) {
      rowVirtualizer.scrollToIndex(activeIndex);
    }
  }, [activeIndex, rowVirtualizer]);
  
  useEffect(() => {
    if (!isOpen) setActiveIndex(null);
  }, [isOpen]);
  
  useEffect(() => {
    setActiveIndex(null);
  }, [filteredOptions]);
  

  const toggleDropdown = () => {
    if (isOpen) {
      onBlur?.()
    }
    setIsOpen((prev) => !prev)
  }

  const handleOptionClick = (option: Option) => {
    if (isMulti) {
      setSelectedOption((prev) => {
        const options = Array.isArray(prev) ? prev : [] // Pastikan prev selalu array
        const exists = options.find((opt) => opt.value === option.value)

        return exists ? options.filter((opt) => opt.value !== option.value) : [...options, option]
      })
      onChange(Array.isArray(selectedOption) ? [...selectedOption, option] : ([option] as Option[]))
    } else {
      // Jika single-select
      setSelectedOption(option as Option)
      onChange(option as Option)
      setIsOpen(false)
      }
      setFilteredOptions(
        getOptions.filter((opt) => opt.value !== option.value && opt.label !== option.label)
      )
      setSearchTerm('')
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleClear = () => {
    setSelectedOption(isMulti ? [] : null)
    setFilteredOptions(getOptions)
    setSearchTerm('')
    onChange(isMulti ? [] : null)
  }

  // useEffect(() => {
  //   if (containerRef.current) {
  //     console.log('containerRef.current.offsetWidth', containerRef.current.offsetWidth)
  //   }
  // }, [containerRef])

  const OptionComponent = components?.Option || DefaultOption
  const SingleValueComponent = components?.SingleValue || DefaultSingleValue

  useEffect(() => {
    // console.log('selectedOption', title, selectedOption)
    if (Array.isArray(selectedOption) && selectedOption.length > 0 && isMulti) {
      // console.log('getOptions', getOptions)
      setFilteredOptions(
        getOptions.filter((opt) => {
          return !selectedOption.find((so) => {
            return opt.value === so.value && opt.label === so.label
          })
        })
      )
    }
    // else {
    //   setFilteredOptions(options)
    // }
  }, [selectedOption])

  // useEffect(() => {
  //   if (isMulti) {
  //     setSelectedOption([])
  //   } else {
  //     console.log('isMulti tidaaak', placeholder, value)
  //     setSelectedOption(null)
  //   }
  // }, [isMulti])

  return (
    // <VirtualizedSelectContext.Provider
    //   value={{value, isMulti, selectedOption, setSelectedOption, setIsOpen}}
    // >
    // </VirtualizedSelectContext.Provider>

      <div ref={containerRef} className={clsx('position-relative', className)} key={key}>
        {isOpen ? (
          <>
            <div
              className='d-flex flex-row border border-secondary border-2 rounded justify-content-between'
              // style={{}}
            >
              <div className='d-flex flex-row flex-wrap w-100 gap-2 p-2 align-items-between justify-content-start'>
                {isMulti && Array.isArray(selectedOption) && selectedOption.length > 0 ? (
                  <>
                    <ShowMultiValue
                      selectedOption={selectedOption as Option[]}
                      handleClose={(v: Option) => {
                        setSelectedOption?.((prev) => {
                          const updatedOptions = Array.isArray(prev) ? prev.filter((opt) => opt.value !== v.value) : [];
                          setFilteredOptions(
                            getOptions.filter(
                              (opt) => !updatedOptions.some((so) => so.value === opt.value)
                            )
                          );
                          onChange(updatedOptions);
                          return updatedOptions; 
                        });
                      }}
                      
                    />
                  </>
                ) : (
                  <div className='p-2 w-100 text-truncate'>
                    {typeof selectedOption === 'object' ? (
                      <SingleValueComponent
                        value={selectedOption as Option}
                        placeholder={placeholder}
                      />
                    ) : (
                      <span>{placeholder}</span>
                    )}
                    {/* {(selectedOption as Option)?.label || placeholder} */}
                  </div>
                )}
                <input
                  type='text'
                  className='border border-0 form-control w-100'
                  placeholder='Search...'
                  value={searchTerm}
                  onChange={handleSearch}
                  autoFocus
                />
              </div>

              <div className='p-2 d-flex position-relative align-content-center'>
                {isClearable && selectedOption && (
                  <div className='d-flex align-items-center cursor-pointer px-2 h-100 gap-4'>
                    <i onClick={handleClear} className='bi bi-x-lg align-middle p-2 fw-bold' />
                    <i onClick={toggleDropdown} className='bi bi-chevron-up fw-bold p-4' />
                  </div>
                )}
                {/* <div
                onClick={toggleDropdown}
                className='position-absolute w-100 bg-transparent'
                style={{height: '100%', width: '100%', zIndex: 100, top: 0, left: 0}}
              /> */}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={`d-flex align-items-center justify-content-between gap-1 p-2 border border-2 rounded bg-white ${
                isDisabled ? 'bg-secondary' : ''
              }`}
              onClick={isDisabled ? undefined : toggleDropdown}
              style={{cursor: isDisabled ? 'not-allowed' : 'pointer', minWidth: '150px'}}
            >
              {Array.isArray(selectedOption) ? (
                <div className=' d-flex flex-wrap gap-2 align-items-center justify-content-start align-items-stretch '>
                  {selectedOption.length > 0 ? (
                    <div className='d-flex flex-row flex-wrap w-100 gap-2 align-items-between justify-content-start'>
                      <ShowMultiValue
                        selectedOption={selectedOption}
                        handleClose={(v: Option) => {
                          setSelectedOption?.((prev) => {
                            const updatedOptions = Array.isArray(prev) ? prev.filter((opt) => opt.value !== v.value) : [];
                         
                            setFilteredOptions(
                              getOptions.filter(
                                (opt) => !updatedOptions.some((so) => so.value === opt.value)
                              )
                            );
                            onChange(updatedOptions);
                            return updatedOptions;
                          });
                        }}
                        
                      />
                      {/* <span className='pl-3 w-100 text-dark text-truncate py-4'>{placeholder}</span> */}
                    </div>
                  ) : (
                    <span className='pl-3 w-100 text-truncate' style={{color: '#8c8c8b'}}>
                      {placeholder}
                    </span>
                  )}
                </div>
              ) : (
                <>
                  {typeof selectedOption === 'object' ? (
                    <SingleValueComponent
                      value={selectedOption as Option}
                      placeholder={placeholder}
                    />
                  ) : (
                    <span>{placeholder}</span>
                  )}
                </>
                // <span className='pl-3 w-100 bg-warning d-flex justify-content-start align-items-center text-truncate'>
                //   {selectedOption?.label || placeholder}
                // </span>
              )}
              <div className='d-flex align-items-center cursor-pointer px-2 h-100 gap-4'>
                {isClearable && selectedOption && (
                  <i onClick={handleClear} className='bi bi-x-lg align-middle p-2 fw-bold' />
                )}
               <div
                onClick={() => {
                  // console.log('click');
                  setIsOpen(true);
                }}
                className="bg-danger"
                style={{ position: 'relative' }}
              >
                <i
                  className="bi bi-chevron-down fw-bold"
                  style={{
                    pointerEvents: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </div>

              </div>
            </div>
          </>
        )}

        {isOpen && (
          <div
            className='position-absolute mt-1 bg-white border rounded-1 shadow'
            style={{zIndex: 1000, width: containerRef.current?.offsetWidth
              ? containerRef.current.offsetWidth > 300
                ? '100%'
                : '600px'
              : '100%',}}
          >
            <div
              ref={parentRef}
              // className='overflow-auto'
              style={{
                maxHeight: '300px',
                width: containerRef.current?.offsetWidth
                  ? containerRef.current.offsetWidth > 300
                    ? 'auto'
                    : '600px'
                  : 'auto',
                overflowX: containerRef.current?.offsetWidth
                  ? containerRef.current.offsetWidth > 300
                    ? 'hidden'
                    : 'scroll'
                  : 'hidden',
                overflowY: 'auto',
              }}
            >
              <div
                style={{
                  minHeight: `${rowVirtualizer.getTotalSize()}px`,
                  position: 'relative',
                }}
              >
                {filteredOptions.length === 0 ? (
                  <div className='d-flex gap-4 align-items-center justify-content-center w-100 h-100 p-8'>
                    <i className='bi bi-emoji-frown' style={{fontSize: '2rem'}} />{' '}
                    <span style={{fontSize: '1.5rem'}}>
                      {intl.formatMessage({id: 'FORM.CONFIRM.DATA_NOT_FOUND'}, {title})}
                    </span>
                  </div>
                ) : (
                  rowVirtualizer.getVirtualItems().map((virtualItem, index) => {
                    const option = filteredOptions[virtualItem.index]
                    if (!option) return null

                    return (
                      <div
                        key={virtualItem.index}
                        className={`position-absolute 
                        w-100`}
                        style={{
                          top: `${virtualItem.start}px`,
                          height: `${virtualItem.size}px`,
                          width: containerRef.current?.offsetWidth,
                          background: 
                                    activeIndex === virtualItem.index
                                      ? '#d9f7be' // Highlight untuk opsi aktif
                                      : hover === virtualItem.index.toString() &&
                                        (!Array.isArray(selectedOption) ||
                                          !selectedOption.some((opt) => opt.value === option.value))
                                      ? '#b1d9fa'
                                      : Array.isArray(selectedOption) &&
                                        selectedOption.some((opt) => opt.value === option.value)
                                      ? '#c9c9c9'
                                      : 'transparent',
                        }}
                        onClick={() => {
                          if (Array.isArray(selectedOption)) {
                            if (!selectedOption.some((opt) => opt.value === option.value)) {
                              handleOptionClick(option as Option)
                            }
                          } else {
                            handleOptionClick(option as Option)
                          }
                        }}
                      >
                        <div
                          onMouseEnter={() => setHover(index.toString())}
                          onMouseLeave={() => setHover('')}
                          className={`d-flex align-items-center w-100 h-100`}
                          style={{
                            background:
                              hover === index.toString() &&
                              (!Array.isArray(selectedOption) ||
                                !selectedOption.some((opt) => opt.value === option.value))
                                ? '#b1d9fa'
                                : Array.isArray(selectedOption) &&
                                  selectedOption.some((opt) => opt.value === option.value)
                                ? '#c9c9c9'
                                : 'transparent',
                          }}
                        >
                          <div
                            className={`d-flex align-items-center w-100 h-100 ${
                              isGroup ? 'px-3' : 'p-0'
                            }`}
                          >
                            {isGroup && (
                              <>
                                <div
                                  className='rounded-1 text-white p-2 text-nowrap text-truncate w-auto'
                                  style={{
                                    overflowX: 'hidden',
                                    display: 'block',
                                    maxWidth: '300px',
                                    fontSize: '11px',
                                    background: '#969696',
                                  }}
                                >
                                  {option.group}
                                </div>
                              </>
                            )}

                            <OptionComponent
                              key={option.value}
                              option={option}
                              onClick={() => {}}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
            </div>
          </div>
        )}
      </div>
  )
}

const ShowMultiValue = ({
  selectedOption,
  handleClose,
}: {
  selectedOption: Option[]
  handleClose: (v: Option) => void
}) => {
  // const {haha} = useContext(VirtualizedSelectContext);

  return (
    <>
      {Array.isArray(selectedOption) &&
        selectedOption.map((v) => (
          <div
            key={v.value}
            className='d-flex m-0 align-items-center rounded'
            style={{background: '#e5e5e5', maxWidth: '300px'}}
          >
            <div
              className='border-start border-secondary px-2 text-black'
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                maxWidth: '300px',
                fontSize: '11px',
              }}
            >
              {v.label}
            </div>
            <div
              className='d-flex align-items-center cursor-pointer p-2 h-100'
              onClick={(e) => {
                e.stopPropagation() // Prevent parent onClick event
                handleClose(v)
              }}
            >
              <i className='bi bi-x-lg align-middle' />
            </div>
          </div>
        ))}
    </>
  )
}

const DefaultOption = ({
  children,
  option,
  onClick,
}: {
  children?: React.ReactNode
  option: VirtualizedSelectOptionsType
  onClick: () => void
}) => (
  <div
    onClick={onClick}
    className='px-3 py-2 '
    style={{
      cursor: 'pointer',
      // display: '-webkit-box',
      // WebkitLineClamp: 2,
      // WebkitBoxOrient: 'vertical',
      // overflow: 'hidden',
      // maxWidth: '800px',
    }}
  >
    {children || option.label}
  </div>
)

const DefaultSingleValue = ({value, placeholder}: {value: Option; placeholder: string}) => {
  return (
    <span className='pl-3 w-100 d-flex justify-content-start align-items-center text-truncate'>
      {value?.label || placeholder}
    </span>
  )
}

export const dummyGroupOptions = Array.from({length: 10000}, (_, i) => ({
  label: `Group ${i + 1}`,
  options: [
    {
      value: `${i + 1}-eed2be4b-5fad-48ee-a484-f6465f9804e7`,
      label: `aku adalah ying / 6287832312 ${i + 1}`,
    },
    {
      value: `${i + 1}-832499fa-ddba-4243-a3eb-5d3afc55a9c2`,
      label: `mbaru1 / 62888888888 ${i + 1}`,
    },
    {
      value: `${i + 1}-d1a4975f-182e-403f-b82a-c2c595628a79`,
      label: `oke / 627878 ${i + 1}`,
    },
    {
      value: `${i + 1}-96b78def-99c3-433b-9fc9-435a85da01b5`,
      label: `sdfsd / 62312312 ${i + 1}`,
    },
    {
      value: `${i + 1}-4f509ec7-995b-40ca-a441-c3d2247e68ca`,
      label: `siapa ya kamu / 6287837483 ${i + 1}`,
    },
  ],
}))
export const dummySingleOptions = Array.from({length: 10000}, (_, i) => ({
  value: `${i + 1}-4f509ec7-995b-40ca-a441-c3d2247e68ca`,
  label: `siapa ya kamu / 6287837483 ${i + 1}`,
}))

// --------------------------------------------------------- Usage of the Virtualized React-Select
export const VirtualizedMenuList = (props: any) => {
  const {options, children, maxHeight} = props

  // Reference for the scrollable container
  const listRef = React.useRef<HTMLDivElement>(null)

  // Virtualizer setup
  const virtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => listRef.current,
    estimateSize: () => 35, // Estimated height of each option
    overscan: 5,
  })

  return (
    <components.MenuList {...props}>
      {/* Scrollable container */}
      <div
        ref={listRef}
        style={{
          maxHeight: maxHeight,
          overflowY: 'auto', // Ensure scrolling happens here
        }}
      >
        {/* Virtualized container */}
        <div
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            position: 'relative',
          }}
        >
          {virtualizer.getVirtualItems().map((virtualRow) => (
            <div
              key={virtualRow.key + 'xx'}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {children[virtualRow.index]}
            </div>
          ))}
        </div>
      </div>
    </components.MenuList>
  )
}

export const MyVirtualizedSelect = () => {
  return (
    <Select
      options={dummySingleOptions}
      components={{MenuList: VirtualizedMenuList}}
      styles={{
        menu: (base) => ({
          ...base,
          overflow: 'hidden', // Prevent outer scrollbar
        }),
        menuList: (base) => ({
          ...base,
          padding: 0, // Remove extra padding to align content
        }),
      }}
    />
  )
}
