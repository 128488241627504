import { useIntl } from 'react-intl';

const useOptionTypeConference = () => {

    const intl = useIntl();

    const option = [
        {
            label: intl.formatMessage({ id: 'FORM.LABEL.SCHEDULED' }),
            value: 'SCHEDULE'
        },
        {
            label: intl.formatMessage({ id: 'FORM.LABEL.INSTANT' }),
            value: 'NON_SCHEDULE'
        }
    ]

    return option;
}

export default useOptionTypeConference;