import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { deleteEmail } from "../../api/MasterEmailCRUD";
import { Email } from "../../interfaces/Email";
import { Modal } from "bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';

const API_URL = process.env.REACT_APP_API_URL

interface Props {
    email: Email
}

const DeleteEmail: FC<Props> = ({ email }) => {
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken();
    const { tablesData, setTablesData } = useDataTableStore();
    const intl = useIntl();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .required(intl.formatMessage({ id: "FORM.VALIDATION.REQUIRED" }))
                .test('match', intl.formatMessage({ id: "FORM.VALIDATION.EMAIL_MISMATCH" }),
                    (value) => value === email.email)
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                const deleteEmailFunction = deleteEmail(token, email.id);
                const response = await deleteEmailFunction(`${API_URL}email/delete-email`);
                if (response.success) {
                    Swal.fire({
                        icon: "success",
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: `${intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" })}.`,
                        heightAuto: false,
                    });
                    closeModalRef.current?.click();
                    setTablesData('emails', (tablesData.emails.filter((data) => data.id !== email.id)));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response?.data?.message || 'An error occurred',
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                });
                closeModalRef.current?.click();
            } finally {
                setSubmitting(false);
            }
        }
    });

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`delete-email-modal-${email.id}`} >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.DELETE" })} {email.email}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="modal-body">
                                <p>{intl.formatMessage({ id: "FORM.CONFIRMATION" })} {intl.formatMessage({ id: "FORM.ACTION.DELETE" }).toLowerCase()} {email.email}?</p>
                                <p>{intl.formatMessage({ id: "FORM.CONFIRMATION_DELETE_EMAIL" })}</p>
                                <p>{intl.formatMessage({ id: "FORM.CONFIRMATION_DELETE_EMAIL_2" })}</p>
                                <div className="mb-10 fv-row">
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control form-control-solid txt-email"
                                        placeholder="Email"
                                        autoComplete="off"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.email}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="submit"
                                    className={`btn btn-danger me-10 ${formik.isSubmitting ? 'disabled' : ''}`}
                                    disabled={!formik.isValid || formik.isSubmitting}
                                    data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}
                                >
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DeleteEmail }
