import {FC} from 'react'
import { useRouteStore } from '../../../../stores/RouteStore'

const Error404: FC = () => {
  const { prevRoute } = useRouteStore()

  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-5'>Page Not Found</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-20'>
        The page <span className='text-gray-700'>{prevRoute}</span> was not found
      </div>
    </>
  )
}

export {Error404}
