import { FC, useState } from "react";
import ReactJson from "react-json-view";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CodeProps, ResponseExample } from "../../interfaces/OpenAPI";
import { useIntl } from "react-intl";
import Select from "react-select";



const Code: FC<CodeProps> = ({ responseExample, python, javascript, php, bash, type }) => {
    const intl = useIntl()
    const [codeBlock, setCodeBlock] = useState(python);
    const [selectOption, setSelectOption] = useState("Python");
    const option = [
        {
            label: 'Python',
            value: 'Python'
        },
        {
            label: 'JavaScript',
            value: 'JavaScript'
        },
        {
            label: 'PHP',
            value: 'PHP'
        },
        {
            label: 'cURL',
            value: 'cURL'
        }
    ]

    const handleSendCodeChange = (value: string) => {
        setSelectOption(value);
        switch (value) {
            case 'JavaScript':
                setCodeBlock(javascript);
                break;
            case 'PHP':
                setCodeBlock(php);
                break;
            case 'cURL':
                setCodeBlock(bash);
                break;
            default:
                setCodeBlock(python);
                break;
        }
    };

    return (
        <div style={{ paddingTop: "5rem" }}>
            <style>
                {`
                    .icon-container {
                        background-color: unset !important;
                    }
                `}
            </style>

            {
                responseExample &&
                <>
                    <h1 className="fs-4 text-white">{intl.formatMessage({ id: "OPEN_API.EXAMPLE_RESPONSE" })}</h1>
                    <ReactJson style={{ padding: "20px" }} src={responseExample} theme={"google"} />
                </>
            }

            <h1 className="fs-4 text-white mt-5">{intl.formatMessage({ id: "OPEN_API.EXAMPLE_USAGE" })}</h1>
            <Select
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: 'none',
                        backgroundColor: "white",
                        // color: "white",
                        width: "150px",
                    }),
                    option: (baseStyles, { isSelected, isFocused }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                        cursor: 'pointer',
                        color: isSelected ? 'white' : "black"
                        // width: "150px",
                    }),
                    menuList: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "150px",
                    }),
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "150px",
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: 'none',
                    })
                }}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'none',
                    },
                })}
                onChange={(option) => handleSendCodeChange(option?.value!)}
                value={option.find(option => option.value === selectOption) || null}
                className='react-select-styled mt-1 mb-3'
                classNamePrefix='react-select'
                options={option}
                isSearchable={false}
            />

            <SyntaxHighlighter style={atomOneDark}>
                {codeBlock}
            </SyntaxHighlighter>
        </div>
    )
}

export { Code }