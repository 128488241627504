import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import Select, { components } from "react-select";
import Swal from "sweetalert2";
import useAccessToken from "../../../hooks/AccessToken";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { CustomField, SettingMultiMerchants, } from "../../../interfaces/Settings";
import { SelectOption, SelectOptionMerchantLink } from "../../../interfaces/SelectOption";
import { saveSettingMerchantLink, updateTemplateSettingMerchant } from "../../../api/SettingCRUD";
import { useIntl } from "react-intl";
import clsx from "clsx";
import * as Yup from 'yup';
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { RowCustomField } from "../RowCustomField";
import { useMerchantStore } from "../../../stores/MerchantStore";

interface Props {
    data: SettingMultiMerchants
    optionMerchantLink: SelectOptionMerchantLink[]
    optionType: SelectOption[]
    optionsResetSerial: SelectOption[]
    dataPipeline: SelectOption[]
    dataMerchant: SelectOption[]
}

const CustomFieldMultiMerchant: FC<Props> = ({ data, optionMerchantLink, optionType, optionsResetSerial, dataPipeline, dataMerchant }) => {
    const { settings, setSettings } = useSettingsStore()
    const { multiMerchants, setMultiMerchants, setSelectMultiMerchant, selectMultiMerchant } = useMerchantStore()
    const token = useAccessToken()
    const [keysMerchant, setKeysMerchant] = useState<string[]>([])

    const [fieldMerchant, setFieldMerchant] = useState<CustomField>({})
    const [loadingMerchant, setLoadingMerchant] = useState(false)
    const [loadingMerchantPreferences, setLoadingMerchantPreferences] = useState(false)
    const intl = useIntl()
    const allOptionPipeline = [{ label: intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: settings.pipeline_title }), value: 'all' }]

    const formik = useFormik({
        initialValues: {
            key: '',
        },
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            let isDuplicate = false
            let isNameColumnEmpty = false
            let isOptionEmpty = false

            Object.keys(fieldMerchant).map((key1) => {
                if (fieldMerchant[key1].type === "option" || fieldMerchant[key1].type === "multiple") {
                    if ((fieldMerchant[key1].value as string[]).includes("")) {
                        isOptionEmpty = true
                    }
                }

                if (fieldMerchant[key1].name === "") {
                    isNameColumnEmpty = true
                }

                Object.keys(fieldMerchant).map((key2) => {
                    if ((fieldMerchant[key1].name)?.trim() === (fieldMerchant[key2].name)?.trim() && key1 !== key2) {
                        isDuplicate = true
                    }
                })
            })

            const modifiedObject = Object.fromEntries(
                Object.entries(fieldMerchant).map(([key, field]) => {
                    const { new: _, ...rest } = field;

                    if (fieldMerchant[key].type === 'merchant' && (field.value as string[]).includes('all')) {
                        rest.value = dataMerchant.filter((item: any) => item.value !== 'all').map((item: any) => item.value);
                    }

                    return [key, rest];
                })
            );

            if (isOptionEmpty) return false
            if (isNameColumnEmpty) return false
            if (isDuplicate) {
                Swal.fire({
                    title: intl.formatMessage({ id: "FORM.VALIDATION.DUPLICATE" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME_COLUMN" }) }),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    heightAuto: false
                })
                return false
            }

            setLoadingMerchant(true)

            try {
                const res = await updateTemplateSettingMerchant(data.id, data.name, modifiedObject, data.leadTemplates.map((template) => template.id), token)
                if (res.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    Object.values(fieldMerchant).forEach(field => {
                        field.new = false;
                    });
                    setSelectMultiMerchant({ ...selectMultiMerchant, customFields: fieldMerchant })
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.respons.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setLoadingMerchant(false)
            }
        }
    });

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const formikPreferencesTemplate = useFormik({
        initialValues: {
            value: data.name,
            accessPipeline: data.leadTemplates ? data.leadTemplates.length === dataPipeline.filter(item => item.value !== 'all').length ? allOptionPipeline : data.leadTemplates.map((lead) => { return { label: lead.name, value: lead.id } }) : [],
            merchantLink: data.merchantLink ? data.merchantLink : [],
        },
        validationSchema: Yup.object().shape({
            value: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            accessPipeline: Yup.array().min(0, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: settings.pipeline_title })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROGRESS" }) })).nullable(),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            const merchantTemplateDestination = values.merchantLink.reduce((acc: any, item: SelectOptionMerchantLink) => {
                if (!acc[item.idTemplate!]) {
                    acc[item.idTemplate!] = {
                        listIdCustomField: []
                    };
                }

                acc[item.idTemplate!].listIdCustomField.push(item.value);
                return acc;
            }, {});

            setLoadingMerchantPreferences(true)
            try {
                let accessPipeline = values.accessPipeline.map(item => item.value);

                if (values.accessPipeline.find(progress => progress.value === 'all')) accessPipeline = dataPipeline.filter(item => item.value !== 'all').map(item => item.value);
                else accessPipeline = values.accessPipeline.map(item => item.value);

                const response = await updateTemplateSettingMerchant(data.id, values.value, data.customFields, accessPipeline.map((value) => value), token)
                const response2 = await saveSettingMerchantLink(data.idMerchantLink!, data.id, merchantTemplateDestination, token)
                if (response.data.success && response2.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })

                    setMultiMerchants(multiMerchants.map(item => item.id === data.id ? { ...response.data.data, label: response.data.data.name, value: response.data.data.id } : item));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.respons.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setLoadingMerchantPreferences(false)
            }
        }
    })

    const formikCustomColumn = useFormik({
        initialValues: {
            value: '',
        },
        validationSchema: Yup.object().shape({
            value: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            let isDuplicate = false
            let isNameColumnEmpty = false
            let isOptionEmpty = false

            Object.keys(fieldMerchant).map((key1) => {
                if (fieldMerchant[key1].type === "option" || fieldMerchant[key1].type === "multiple") {
                    if ((fieldMerchant[key1].value as string[]).includes("")) {
                        isOptionEmpty = true
                    }
                }

                if (fieldMerchant[key1].name === "") {
                    isNameColumnEmpty = true
                }

                Object.keys(fieldMerchant).map((key2) => {
                    if ((fieldMerchant[key1].name)?.trim() === (fieldMerchant[key2].name)?.trim() && key1 !== key2) {
                        isDuplicate = true
                    }
                })
            })

            const modifiedObject = Object.fromEntries(
                Object.entries(fieldMerchant).map(([key, value]) => {
                    const { new: _, ...rest } = value;
                    return [key, rest];
                })
            );

            if (isOptionEmpty) return false
            if (isNameColumnEmpty) return false
            if (isDuplicate) {
                Swal.fire({
                    title: intl.formatMessage({ id: "FORM.VALIDATION.DUPLICATE" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME_COLUMN" }) }),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    heightAuto: false
                })
                return false
            }

            setLoadingMerchant(true)

            try {
                const res = await updateTemplateSettingMerchant(data.id, data.name, modifiedObject, data.leadTemplates.map((template) => template.id), token)
                if (res.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    Object.values(fieldMerchant).forEach(field => {
                        field.new = false;
                    });

                    setMultiMerchants(multiMerchants.map(item => item.id === data.id ? { ...res.data.data, label: res.data.data.name, value: res.data.data.id } : item));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.respons.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setLoadingMerchant(false)
            }
        }
    });

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const updateColumns = (field: CustomField) => {
                const keys = Object.keys(field);
                const oldIndex = keys.indexOf(active.id);
                const newIndex = keys.indexOf(over.id);

                const newKeys = arrayMove(keys, oldIndex, newIndex);

                const newField: CustomField = {};
                newKeys.forEach(key => {
                    newField[key] = field[key];
                });

                return newField;
            };

            const newColumns = updateColumns(fieldMerchant);
            setFieldMerchant(newColumns);
        }
    };

    const addMerchantColumn = () => {
        var key = crypto.randomUUID()
        const addColumnMerchant = {
            ...fieldMerchant,
            [key]: {
                name: `${intl.formatMessage({ id: "FORM.LABEL.COLUMN" })} ` + (Object.keys(fieldMerchant).length + 1),
                type: 'text',
                required: false,
                value: [],
                score: "",
                new: true
            }
        };
        setFieldMerchant(addColumnMerchant);
        setKeysMerchant([...keysMerchant, key])
    }

    const CustomOptionMerchantLink = (props: any) => {
        return (
            <components.Option {...props}>
                <span>{props.data.nameTemplate} / <b>{props.data.nameCustomField}</b></span>
            </components.Option>
        );
    };

    const CustomMultiValueMerchantLink = (props: any) => {
        return (
            <components.MultiValue {...props}>
                <span>{props.data.nameTemplate} / <b>{props.data.nameCustomField}</b></span>
            </components.MultiValue>
        );
    };

    useEffect(() => {
        formikCustomColumn.setFieldValue('value', data.name ? data.name : 'Template Merchant')
        if (data.customFields) {
            const modifiedData = Object.fromEntries(
                Object.entries(data.customFields).map(([key, field]) => {
                    const { new: _, ...rest } = field;
                    
                    if (data.customFields[key].type === 'merchant') {
                        rest.value = rest.value.length === 0 ? ['all'] : rest.value.length === dataMerchant.filter((item: any) => item.value !== 'all').length ? ['all'] : rest.value;
                    }

                    return [key, rest];
                })
            );

            setFieldMerchant(modifiedData)
            setKeysMerchant(Object.keys(`${modifiedData}`))
        }
    }, [data])


    return (
        <>
            <div className="card mb-3">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">{intl.formatMessage({ id: "FORM.LABEL.PREFERENCES" }, { title: "Template " + data.name })}</h3>
                    </div>
                </div>
                <div>
                    <form onSubmit={formikPreferencesTemplate.handleSubmit} noValidate>
                        <div className="card-body border-top px-9 py-9">
                            <div className="row">
                                <span className="col-md-7 col-lg-9 col-xl-9 d-flex flex-column align-items-start">
                                    <span className="fw-bolder fs-5 mb-0">{`${intl.formatMessage({ id: "FORM.LABEL.NAME" })} `}</span>
                                    <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_SETTING_NAME_TEMPLATE" })}</span>
                                </span>
                                <div className="col-md-5 col-lg-3 col-xl-3">
                                    <input
                                        {...formikPreferencesTemplate.getFieldProps('value')}
                                        className={clsx(
                                            'form-control',
                                            { 'is-invalid': formikPreferencesTemplate.touched.value && formikPreferencesTemplate.errors.value },
                                        )}
                                        type='text'
                                        name='value'
                                    />
                                    {formikPreferencesTemplate.touched.value && formikPreferencesTemplate.errors.value && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formikPreferencesTemplate.errors.value}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="separator separator-dashed my-6"></div>
                            <div className="row">
                                <label className="col-md-7 col-lg-9 col-xl-9 align-items-start">
                                    <span className="form-check-label d-flex flex-column align-items-start">
                                        <span className="fw-bolder fs-5 mb-0">{intl.formatMessage({ id: "FORM.LABEL.SETTING_MERCHANT_PIPELINE" }, { title2: settings.pipeline_title, title: settings.merchant_title })}</span>
                                        <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_SETTING_MERCHANT_PIPELINE" }, { title: settings.pipeline_title, title2: settings.merchant_title })}</span>
                                    </span>
                                </label>
                                <div className="col-md-5 col-lg-3 col-xl-3">
                                    <Select
                                        key={data.id}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                border: "1px solid #E4E6EF",
                                                backgroundColor: "none",
                                            }),
                                            option: (baseStyles, { isSelected, isFocused }) => ({
                                                ...baseStyles,
                                                backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                                cursor: 'pointer'
                                            }),
                                            indicatorSeparator: (baseStyles, state) => ({
                                                ...baseStyles,
                                                display: 'none',
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: 'none',
                                            },
                                        })}
                                        isMulti
                                        onChange={(selectedOptions) => {
                                            var endIndex = selectedOptions.length - 1
                                            if (selectedOptions.length > 0 && selectedOptions[endIndex].value === 'all') {
                                                formikPreferencesTemplate.setFieldValue('accessPipeline', allOptionPipeline)
                                            } else {
                                                formikPreferencesTemplate.setFieldValue('accessPipeline', selectedOptions.filter(item => item.value !== 'all'))
                                            }
                                        }}
                                        onBlur={
                                            () => formikPreferencesTemplate.values.accessPipeline.length === 0 && formikPreferencesTemplate.setFieldTouched("accessPipeline", true)
                                        }
                                        value={formikPreferencesTemplate.values.accessPipeline as any}
                                        className='react-select-styled'
                                        classNamePrefix='react-select'
                                        options={dataPipeline}
                                        placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${settings.pipeline_title}`}
                                    />
                                    {formikPreferencesTemplate.touched.accessPipeline && formikPreferencesTemplate.errors.accessPipeline && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formikPreferencesTemplate.errors.accessPipeline}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="separator separator-dashed my-6"></div>
                            <div className="row">
                                <label className="col-md-7 col-lg-9 col-xl-9 align-items-start">
                                    <span className="form-check-label d-flex flex-column align-items-start">
                                        <span className="fw-bolder fs-5 mb-0">{intl.formatMessage({ id: "FORM.LABEL.SETTING_MERCHANT_LINK" })}</span>
                                        <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_SETTING_MERCHANT_LINK" }, { title: settings.merchant_title })}</span>
                                    </span>
                                </label>
                                <div className="col-md-5 col-lg-3 col-xl-3">
                                    <Select
                                        key={data.id}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                border: "1px solid #E4E6EF",
                                                backgroundColor: "none",
                                            }),
                                            option: (baseStyles, { isSelected, isFocused }) => ({
                                                ...baseStyles,
                                                backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                                cursor: 'pointer'
                                            }),
                                            indicatorSeparator: (baseStyles, state) => ({
                                                ...baseStyles,
                                                display: 'none',
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: 'none',
                                            },
                                        })}
                                        isMulti
                                        onChange={(selectedOptions) => {
                                            formikPreferencesTemplate.setFieldValue('merchantLink', selectedOptions)
                                        }}
                                        value={formikPreferencesTemplate.values.merchantLink as any}
                                        className='react-select-styled'
                                        classNamePrefix='react-select'
                                        options={optionMerchantLink}
                                        placeholder={intl.formatMessage({ id: "FORM.PLACEHOLDER.CHOOSE_COLUMN" })}
                                        components={{
                                            Option: CustomOptionMerchantLink,
                                            MultiValue: CustomMultiValueMerchantLink
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="submit" className={`btn btn-primary ${loadingMerchantPreferences ? "disabled" : ""}`} data-kt-indicator={loadingMerchantPreferences ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">{intl.formatMessage({ id: "FORM.LABEL.CUSTOM_COLUMN" })}</h3>
                    </div>
                </div>
                <div id="kt_account_settings_email_preferences">
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="card-body border-top px-9 py-9">
                            {
                                <DndContext onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={closestCenter}>
                                    <SortableContext items={Object.keys(fieldMerchant) as any} strategy={verticalListSortingStrategy}>
                                        {
                                            Object.keys(fieldMerchant).map((key, index) => {
                                                return (
                                                    <RowCustomField
                                                        key={key}
                                                        id={key}
                                                        type="merchant"
                                                        customField={fieldMerchant}
                                                        index={index}
                                                        optionType={optionType}
                                                        optionsResetSerial={optionsResetSerial}
                                                        dataMerchant={dataMerchant}
                                                        setCustomField={setFieldMerchant}
                                                    />
                                                )
                                            })
                                        }
                                    </SortableContext>
                                </DndContext>
                            }
                        </div>
                        <div className="card-footer py-6 px-9">
                            <div className="row">
                                <label className="col-xl-3"></label>
                                <div className="col-lg-9 col-xl-9" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <button type="button" className="btn btn-secondary" onClick={() => addMerchantColumn()}>
                                        <i className="fas fa-plus"></i>
                                        {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.COLUMN" })}
                                    </button>
                                    <button type="submit" className={`btn btn-primary ${loadingMerchant ? "disabled" : ""}`} data-kt-indicator={loadingMerchant ? 'on' : 'off'}>
                                        <span className="indicator-label">
                                            {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                        </span>
                                        <span className="indicator-progress">
                                            Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export { CustomFieldMultiMerchant }