import { UserModel } from "../app/modules/auth/models/UserModel"
import { RecentChatHistory } from "../interfaces/Chat/RecentChatHistory"
import { CustomeStoreCompanyIdProps } from "../stores/CustomeStore"

const focusChatInput = async () => {
    await new Promise(resolve => setTimeout(resolve, 0))
    const chatInput = document.getElementById('chat-input') as HTMLTextAreaElement
    chatInput.focus()
}

export { focusChatInput }

export const checkedIsUserHappyPlay = (user: UserModel, companyId: CustomeStoreCompanyIdProps) => {
    if ((user.data.company_id === companyId.happyPlay) && user.data.is_superadmin) {
        return true
    }
    return false
}

export const getInitialsOrPhonePrefix = (recentChatHistory: { chat_name: string; chat_phone: string }): string => {
    if (recentChatHistory.chat_name) {
        const words = recentChatHistory.chat_name.split(' ');
        const initials = words.length > 1
            ? words.map((word: string) => word[0]).join('')
            : words[0][0];

        return recentChatHistory.chat_name !== recentChatHistory.chat_phone
            ? initials.substring(0, 2).toUpperCase()
            : recentChatHistory.chat_phone.substring(0, 2).toUpperCase();
    }
    return recentChatHistory.chat_phone.substring(0, 2).toUpperCase();
};

export const sortListChat = (data: RecentChatHistory[]) => {
    const sortedHistories = [...data].sort(
        (a, b) => new Date(b.chat_date).getTime() - new Date(a.chat_date).getTime()
    );

    return sortedHistories
}