import { FC, ReactNode, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Product } from "../../../interfaces/Product";
import { AddProduct } from "../../../components/Products/AddProduct";
import { UpdateProduct } from "../../../components/Products/UpdateProduct";
import { DeleteProduct } from "../../../components/Products/DeleteProduct";
import { Modal } from "bootstrap";
import { UserModel } from "../../modules/auth/models/UserModel";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useIntl } from "react-intl";
import { customNumberFormat } from "../../../functions/general";
import { DataTableImport } from "../../../components/DataTable/DataTableImport";
import { ExpandableElement } from "../../../components/General/ExpandableElement";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MasterProducts: FC = () => {

    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [product, setProduct] = useState<Product>();
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);

    const intl = useIntl()

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const tableColumns: TableColumn<Product>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '70px',
            cell: (row) => {
                return (
                    <>
                        {
                            (
                                ((row.creator_id === user.data.id) || user.data.is_superadmin) ||
                                (user.data.permission_product_update !== 'NONE' || user.data.permission_product_delete !== 'NONE')
                            ) &&
                            <>
                                <IconButton
                                    aria-label={`more-${row.id}`}
                                    id={`menu-anchor-${row.id}`}
                                    onClick={() => handleClick(row.id)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    id={`menu-${row.id}`}
                                    anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                                    open={openMenuId === row.id}
                                    onClose={handleClose}
                                    disableScrollLock={true}
                                >
                                    {
                                        (
                                            ((row.creator_id === user.data.id) || user.data.is_superadmin) ||
                                            (user.data.permission_product_update !== 'NONE')
                                        ) &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowUpdateModal(true);
                                            setProduct(row);
                                        }}>
                                            <i className="fas fa-edit fs-4 me-4"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                                        </MenuItem>
                                    }
                                    {
                                        (
                                            ((row.creator_id === user.data.id) || user.data.is_superadmin) ||
                                            (user.data.permission_product_delete !== 'NONE')
                                        ) &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowDeleteModal(true);
                                            setProduct(row);
                                        }}>
                                            <i className="fas fa-trash fs-3 me-5"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                                        </MenuItem>
                                    }
                                </Menu>
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            selector: row => row.name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => ( <div className="d-flex flex-row gap-2">
                <div>
                {row.name}
                </div>
                <div>
                <OverlayTrigger
                    key={`labels-${row.id}`}
                    placement='right'
                    overlay={<Tooltip id='tooltip-labels'>                            
                        {    row?.productLabels?.map((label, index) => (
                                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                                    <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.label.color||"black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                    </svg>
                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {label.label.name}
                                    </span>
                                </div>
                            ))
                    }
                    </Tooltip>}
                >
                    <span
                        id={`labels-${row.id}`}
                        className='cursor-pointer'
                        data-toggle='tooltip'
                        data-placement='right'
                        title=''
                    >
                        {(row.productLabels?.length && row.productLabels.length > 0) ? (
                            row.productLabels.length === 1 ? (
                                <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: row.productLabels[0].label.color||"black" }} >
                                    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                </svg>
                            ) : (
                                <div className="ps-1" style={{ color: row.productLabels[1].label.color||"black" }}>
                                    <svg width="18px" height="15px" viewBox="0 0 18 15">
                                        <path fill={`${row.productLabels[0].label.color}`} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                        <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                    </svg>
                                </div>

                            )
                        ) : <></>}
                    </span>
                </OverlayTrigger>
                </div>
        </div>),
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRICE' }),
            wrap: true,
            allowOverflow: true,
            style: {
                display: "flex",
                flexWrap: "wrap"
            },
            cell: row => row.price  ? customNumberFormat(row.price) : '-'
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.VAT' }),
            wrap: true,
            allowOverflow: true,
            style: {
                display: "flex",
                flexWrap: "wrap"
            },
            cell: row => row.vatPercentage  ? `${row.vatPercentage} %`  : '-'
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.UNIT' }),
            wrap: true,
            allowOverflow: true,
            cell: row => row.productUnits.length > 0 ?
                row.productUnits.map((data, index) => {
                    if (index === row.productUnits.length - 1) {
                        // Jika ini adalah elemen terakhir dalam array
                        return customNumberFormat(data.convertionToPieces) + " PCS/" + data.unit.name;
                    } else {
                        // Untuk elemen-elemen lain dalam array
                        return customNumberFormat(data.convertionToPieces) + " PCS/" + data.unit.name + ", ";
                    }
                }).join('')
                :
                'PCS'
        },
        {
            name: 'SKU',
            selector: row => row.sku?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.sku ? row.sku : '-',
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
            selector: row => row.note?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => {
                if (row.note) {
                    var data = (row.note).replaceAll("\n", "<br/>")
                    return <ExpandableElement text={data} maxHeight={100} />;
                } else return '-'
            },
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COLOR' }),
            center: true,
            width: '75px',
            selector: row => row.color ?? "#D33115",
            cell: row => {
                return (
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '6px',
                            backgroundColor: row.color ?? "#D33115",
                        }}
                    />
                );
            },
        },
    ];

    let actions: ReactNode[] = []
    if (user.data.permission_product_create !== "NONE" || user.data.permission_product_update !== "NONE") {
        actions.push(<DataTableImport source={"template-product"} columns={tableColumns} />)
    }
    if (user.data.permission_product_create !== "NONE") {
        actions.push(<AddProduct />)
    }

    useEffect(() => {
        if (showUpdateModal && product) {
            const modalElement = document.getElementById(`update-product-modal-${product.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, product, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal && product) {
            const modalElement = document.getElementById(`delete-product-modal-${product.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, product, setShowDeleteModal]);

    return (
        <>
            <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'MENU.PRODUCTS' })}</li>
            </ol>
            <MasterDataTable
                tableKey="products"
                tableColumns={tableColumns}
                actions={actions}
                apiURL="products"
            />
            {
                showUpdateModal && <UpdateProduct product={product!} />
            }
            {
                showDeleteModal && <DeleteProduct product={product!} />
            }

        </>
    )
}

export { MasterProducts }