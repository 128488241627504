import React, { FC, useState, useEffect } from 'react'
import moment from 'moment'
import { EmailMessage } from '../../interfaces/Email/EmailMessage'
import { useEmailStore } from '../../stores/EmailStore'
import axios from 'axios'
import useAccessToken from '../../hooks/AccessToken'
import { useUserStore } from '../../hooks/UserEmail'

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL

type EmailProps = {
  email: EmailMessage
  typeMenu: string
  countCheckList: number
  setCountCheckList: (count: number) => void
  filterWords?: string[]
}

const ListEmail: FC<EmailProps> = ({
  email,
  typeMenu,
  countCheckList,
  setCountCheckList,
  filterWords,
}) => {
  const { emails, checkedEmailIds, tokenEmail, setThreadEmail, setCheckedEmailIds, setTypeEmail, setDetailEmail, setUidThread, setIsthreadOpen, currentPage } =
    useEmailStore()
  const { userEmail } = useUserStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const token = useAccessToken()

  const exportToArrayEmail = email.to.split(',')

  function formatDate(inputDate: moment.Moment): string {
    const today = moment()
    const yesterday = moment().subtract(1, 'day')
    const lastMonth = moment().subtract(1, 'month')

    if (inputDate.isSame(today, 'day')) {
      return inputDate.format('HH:mm') // Menampilkan jam jika hari ini
    } else if (inputDate.isSame(yesterday, 'day') || inputDate.isAfter(lastMonth)) {
      return inputDate.format('DD MMM') // Menampilkan tanggal dan bulan jika kemarin atau dalam 1 bulan terakhir
    } else {
      return inputDate.format('DD/MM/YY') // Menampilkan tanggal, bulan, dan tahun jika sudah melewati 1 bulan
    }
  }

  const checkEmails = (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxId = event.currentTarget.id
    const emailId = checkboxId.split('-')[3]
    if (checked) {
      setCheckedEmailIds([...checkedEmailIds, emailId])
      setCountCheckList(countCheckList + 1)
    } else {
      setCheckedEmailIds(checkedEmailIds.filter((id) => id !== emailId))
      setCountCheckList(countCheckList - 1)
    }
  }

  useEffect(() => {
    const checkbox = document.getElementById('check-all-list-email') as HTMLInputElement | null
    if (emails.length === countCheckList) {
      if (checkbox) {
        checkbox.checked = true
      }
    } else {
      if (checkbox) {
        checkbox.checked = false
      }
    }
  }, [countCheckList, emails.length])

  const highlightText = (text: any, highlightWords: string[]) => {
    const escapedWords = highlightWords.map((word: any) =>
      word.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
    )
    const regex = new RegExp(`(${escapedWords.join('|')})`, 'gi')
    if (text) {
      return text
        .toString()
        .replace(regex, `<mark style="background-color: #FFD172; padding: 0px;">$1</mark>`)
    }
    return ''
  }

  const formatFilterWord = (filterWords: string[]) => {
    return filterWords.flatMap((word) => word.split(''))
  }

  const formattedWords = formatFilterWord(filterWords ?? [''])

  const joinSubjectAndBody = (email: EmailMessage) => {
    const cleanedSubject = email.subject.replace(/^(Re: )+/i, 'Re: ')
    const highlightedSubject = highlightText(cleanedSubject, filterWords ?? [])
    const highlightedBody = email.body ? highlightText(email.body, filterWords ?? []) : ''

    if (formattedWords.length > 1) {
      return `<span class="email-content email-subject-body"><span style="${email.read || email.readAnswered ? '' : 'font-weight: bold;'
        }">${highlightedSubject}</span> - <span style="color: #818181">${highlightedBody}</span></span>`
    } else {
      return `<span class="email-content email-subject-body"><span style="${email.read || email.readAnswered ? '' : 'font-weight: bold;'
        }">${cleanedSubject}</span> ${typeMenu !== 'draft' ? `- <span style="color: #818181">${email.body}</span></span>` : `<span style="color: #818181">${email.body ? email.body : ''}</span></span>`}`
    }
  }

  const checkForReplies = async (email: EmailMessage) => {
    setIsLoading(true)
    if (email.subject.startsWith('Fwd: ')) {
      setDetailEmail(email);
      setTypeEmail('detailEmail', currentPage);;
    } else {
      if (email.references && email.references.length > 0) {
        try {
          const concateMsgIdAndReference = email.references.join(" ") + " " + email.messageId;
          const response = await axios.post(`${API_URL_EMAIL}dashboard/read-thread-email`,
            { messageIds: concateMsgIdAndReference },
            {
              headers: {
                'X-auth-token': `Bearer ${tokenEmail}`,
                'x-crm-auth-token': token,
              }
            }
          );
          setThreadEmail(response.data);
          setTypeEmail('threadedEmail', currentPage);
          setUidThread(email.id);
          setIsthreadOpen(true);
        } catch (error) {
          console.error('Error fetching email thread:', error);
          setDetailEmail(email);
          setTypeEmail('detailEmail', currentPage);
        }
      } else {
        setDetailEmail(email);
        setTypeEmail('detailEmail');
      }
    }
    setIsLoading(false)
  };

  return (
    <>
      <tr className={`cursor-pointer ${isLoading ? 'opacity-50' : ''}`} style={{ position: 'relative' }}>
        <td className='ps-5 ps-md-9 w-10px'>
          <div
            className='form-check form-check-sm form-check-custom form-check-solid mt-2'
            style={{ margin: 0 }}
          >
            <input
              checked={checkedEmailIds.includes(email.id.toString())}
              className='form-check-input'
              type='checkbox'
              id={'check-list-email-' + email.id}
              onChange={(e) => checkEmails(e.target.checked, e)}
              style={{ margin: 0 }}
            />
          </div>
        </td>
        <td className='' onClick={(e) => !isLoading && checkForReplies(email)}>
          <div className='d-flex flex-column text-dark'>
            <div className='d-flex align-items-center'>
              {/* Display the sender */}
              {typeMenu === 'inbox' ||
                typeMenu === 'spam' ||
                typeMenu === 'archive' ||
                typeMenu === 'trash' ? (
                <>
                  <span
                    className={`${email.read || email.readAnswered ? '' : 'fw-bolder'}`}
                    dangerouslySetInnerHTML={{
                      __html: formattedWords.length > 0
                        ? highlightText(userEmail?.email === email.from ? 'Saya' : `${email.from}`, filterWords ?? [''])
                        : userEmail?.email === email.from
                          ? 'Saya'
                          : `${email.from}`,
                    }}
                  />
                  {email.merchantName && (
                    <span className='border-3 border-start ps-2 ms-2 d-flex align-content-center justify-content-center'>
                      <img src="media/svg/general/merchant.svg" alt="merchant" className="h-20px" />
                      <h6 className='my-auto ms-2 opacity-50 fs-7'
                        dangerouslySetInnerHTML={{
                          __html: formattedWords.length > 0
                            ? highlightText(email.merchantName, filterWords ?? [''])
                            : email.merchantName,
                        }}
                      />
                    </span>
                  )}
                </>
              ) : typeMenu === 'sent' ? (
                <span className={`${email.read || email.readAnswered ? '' : 'fw-bolder'}`}>
                  Kepada:{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formattedWords.length > 0
                        ? highlightText(
                          (exportToArrayEmail ?? []).length > 1
                            ? `${exportToArrayEmail[0].split('@')[0]}...`
                            : exportToArrayEmail?.map((email) => email.split('@')[0]).join(', '),
                          filterWords ?? ['']
                        )
                        : (exportToArrayEmail ?? []).length > 1
                          ? `${exportToArrayEmail[0].split('@')[0]}...`
                          : exportToArrayEmail?.map((email) => email.split('@')[0]).join(', '),
                    }}
                  />
                </span>
              ) : typeMenu === 'draft' ? (
                <span
                  className={`${email.read || email.readAnswered ? '' : 'fw-bolder'}`}
                  dangerouslySetInnerHTML={{
                    __html: formattedWords.length > 0
                      ? highlightText(email.to, filterWords ?? [''])
                      : email.to,
                  }}
                />
              ) : (
                <></>
              )}
            </div>
            <div className='mt-2'>
              <div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: joinSubjectAndBody(email),
                  }}
                />
              </div>
            </div>
          </div>
        </td>
        <td
          className='w-50px w-md-80px text-end fs-7 pe-9 email-date'
          onClick={(e) => !isLoading && checkForReplies(email)}
        >
          <span className='fw-bold mt-3'>
            {formatDate(moment(email.date, 'YYYY-MM-DD HH:mm:ss'))}
          </span>
        </td>
        {isLoading && (
          <td
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </td>
        )}
      </tr>
    </>
  )
}

export { ListEmail }
