import React from 'react';
import { Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useEmailStore } from '../../stores/EmailStore';
import { useUserStore } from '../../hooks/UserEmail';

const ProfileAvatar = () => {
    const {
        setTokenEmail,
        setTypeEmail,
        setTypeMenu
    } = useEmailStore();
    const { userEmail, clearUserEmail } = useUserStore()
    const getFirstNameMail = (email: string) => {
        return email.split('@')[0];
    };

    const btnLogoutEmail = () => {
        Swal.fire({
            title: 'Keluar',
            text: 'Apakah Anda yakin ingin keluar?',
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Ya, keluar',
            cancelButtonText: 'Tidak',
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-active-light me-3',
            },
            reverseButtons: true,
            heightAuto: false,
            preConfirm: () => {
                setTokenEmail('');
                clearUserEmail();
                setTypeEmail('formLogin');
                setTypeMenu('formLogin');
            },
        });
    };

    return (
        <Dropdown>
            <Dropdown.Toggle as="div" className="menu-item d-none d-sm-block cursor-pointer" id="dropdown-basic">
                <div style={{ display: 'flex', alignItems: 'center', padding: '0px 10px' }}>
                    {
                        userEmail?.photoUrl ? (
                            <span style={{ borderRadius: '100%', backgroundColor: '#DEDEDE', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img
                                    src={userEmail?.photoUrl}
                                    alt='user-icon'
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                        objectFit: 'cover'
                                    }}
                                />
                            </span>
                        ) : (
                            <span style={{ borderRadius: '100%', backgroundColor: '#DEDEDE', padding: '5px' }}>
                                <img style={{ width: '27px', height: '27px' }} src='/media/icons/duotune/communication/com013.svg' alt='user-icon' />
                            </span>
                        )
                    }
                    <div className='d-flex align-items-center ms-1 ms-lg-3'>
                        <div className='symbol symbol-30px symbol-md-40px'>
                            <h5 className='my-auto menu-title fw-bold'>{getFirstNameMail(userEmail?.email || '')}</h5>
                        </div>
                    </div>
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="menu-dropdown" style={{ backgroundColor: '#FCFCFC', borderRadius: '15px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                <div className='menu-gray-800 menu-state-bg menu-state-primary fw-bold fs-6 w-250px'>
                    <div className='menu-item px-3 mb-2'>
                        <div className='menu-content d-flex flex-column align-items-center justify-content-between gap-4'>
                            <div className='fw-bolder d-flex align-items-center fs-5'>
                                Haii, {getFirstNameMail(userEmail?.email || '')}
                            </div>
                            {
                                userEmail?.photoUrl ? (
                                    <span style={{ borderRadius: '100%', backgroundColor: '#DEDEDE', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img
                                            src={userEmail?.photoUrl}
                                            alt='user-icon'
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '50%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </span>
                                ) : (
                                    <span style={{ borderRadius: '100%', backgroundColor: '#DEDEDE', padding: '5px' }}>
                                        <img style={{ width: '27px', height: '27px' }} src='/media/icons/duotune/communication/com013.svg' alt='user-icon' />
                                    </span>
                                )
                            }
                            <p className='fw-bold text-muted text-hover-primary fs-7 my-auto'>{userEmail?.email || ''}</p>
                            <div
                                style={{
                                    borderRadius: '10px',
                                    color: 'white',
                                    width: '100%',
                                }}
                                className='cursor-pointer d-flex bg-danger gap-2 py-2 px-3 justify-content-center'
                                onClick={btnLogoutEmail}
                            >
                                <span className='my-auto'>
                                    <img
                                        style={{ width: '16px', height: '16px', color: 'white' }}
                                        src='/media/svg/general/signout.svg'
                                        alt='sign-out'
                                    />
                                </span>
                                <p className='my-auto'>Keluar</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown >
    );
};

export default ProfileAvatar;