import React from 'react'
import { FC, ReactNode, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { MasterDataTable } from '../../../components/DataTable/MasterDataTable';
import useUser from "../../../hooks/User";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useIntl } from "react-intl";
import { AddEmail } from '../../../components/Email/AddEmail';
import { Email } from '../../../interfaces/Email';
import { UpdateEmail } from '../../../components/Email/UpdateEmail';
import { Modal } from "bootstrap";
import { DeleteEmail } from '../../../components/Email/DeleteEmail';
import Avatar from '../../../components/Email/Avatar';
import ChangePasswordEmail from '../../../components/Email/ChangePasswordEmail';
import { customNumberFormat } from '../../../functions/general';
import { getCompanyLimitEmail, handleLoginWithToken } from '../../../api/MasterEmailCRUD';
import { KTSVG } from '../../../_metronic/helpers';
import useAccessToken from '../../../hooks/AccessToken';
import useSWR from 'swr';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useUserStore } from '../../../hooks/UserEmail';


const API_URL = process.env.REACT_APP_API_URL

const MasterEmail = () => {
    const user = useUser();
    const token = useAccessToken()
    const intl = useIntl();
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [showUpdateProfile, setShowUpdateProfile] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showUpdatePassword, setShowUpdatePassword] = useState<boolean>(false);
    const [emails, setEmails] = useState<Email>()
    const [hideColumns, setHideColumns] = useState<TableColumn<Email>[]>([]);
    const [isLoadingLogin, setIsLoadingLogin] = useState<boolean>(true);
    const { setUserEmail } = useUserStore()

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const { data: dataLimitEmail, isLoading, mutate } = useSWR(
        [`${API_URL}email/company-email-limit?companyId=${user.data.company_id}`, token],
        getCompanyLimitEmail
    );

    // fetcher
    const handleEmailLogin = async (row: Email, token: string) => {
        try {
            setIsLoadingLogin(true);
            const response = await axios.post(
                `${API_URL}email/redirect-token`, { id: row.id },
                {
                    headers: {
                        'X-Auth-token': token
                    }
                }
            );
            if (response.data.success && response.data.data.token) {
                const responseToken = await handleLoginWithToken(response.data.data.token, token);
                if (responseToken.success) {
                    setUserEmail(responseToken.data[0])
                    localStorage.setItem('token_email', responseToken.token);
                    const redirectUrl = new URL('/email', window.location.origin);
                    window.open(redirectUrl.toString(), '_blank');
                    Swal.fire({
                        icon: 'success',
                        title: 'Login Berhasil',
                    })
                }
            } else {
                console.error('Failed to get login token or email');
                Swal.fire({
                    icon: 'error',
                    title: 'Login Gagal',
                })
            }
        } catch (error) {
            console.error('Error logging in to email:', error);
        } finally {
            setIsLoadingLogin(false);
        }
    };

    var tableColumns: TableColumn<Email>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            allowOverflow: true,
            width: '70px',
            id: 'fixedLeft',
            cell: (row) => {
                return (
                    <>
                        <IconButton
                            aria-label={`more-${row.id}`}
                            id={`menu-anchor-${row.id}`}
                            onClick={() => handleClick(row.id)}
                        >
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            id={`menu-${row.id}`}
                            anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                            open={openMenuId === row.id}
                            onClose={handleClose}
                            disableScrollLock={true}
                        >
                            <MenuItem onClick={() => {
                                handleClose();
                                setShowUpdateProfile(true);
                                setEmails(row)
                            }}>
                                <i className="fas fa-edit fs-4 me-3"></i>
                                <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE_PROFILE' })}</span>
                            </MenuItem>

                            <MenuItem onClick={() => {
                                handleClose();
                                setShowUpdatePassword(true);
                                setEmails(row)
                            }}>
                                <i className="fa fa-key fs-3 me-3"></i>
                                <span>{intl.formatMessage({ id: 'FORM.ACTION.CHANGE_PASSWORD' })}</span>
                            </MenuItem>

                            <MenuItem onClick={() => {
                                handleClose();
                                setShowDeleteModal(true);
                                setEmails(row)
                            }}>
                                <i className="fas fa-trash fs-3 me-4"></i>
                                <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                            </MenuItem>
                        </Menu>
                    </>
                )
            },
            omit: hideColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }))?.omit
        },
        {
            name: 'Avatar',
            width: '100px',
            selector: row => row.photoUrl,
            allowOverflow: true,
            reorder: true,
            omit: hideColumns.find(item => item.name === 'Avatar')?.omit,
            cell: (row) => {
                if (!row.photoUrl || row.photoUrl === '-') {
                    return <span
                        style={{ borderRadius: '100%', backgroundColor: '#DEDEDE', padding: '5px' }}
                        className='my-2'
                    >
                        <img
                            style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                            src={'/media/icons/duotune/communication/com013.svg'}
                            alt='user-icon'
                        />
                    </span>
                } else {
                    return <Avatar url={row.photoUrl} />
                }
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.USER' }),
            width: '250px',
            selector: row => row.username,
            sortable: true,
            allowOverflow: true,
            reorder: true,
            omit: hideColumns.find(item => item.name === 'Nama')?.omit,
            cell: (row) => {
                return row.username
            }
        },
        {
            name: intl.formatMessage({ id: 'FORM.LABEL.NAME' }),
            width: '250px',
            selector: row => {
                const concatName = `${row.firstName} ${row.lastName}`;
                return concatName
            },
            sortable: true,
            allowOverflow: true,
            reorder: true,
            omit: hideColumns.find(item => item.name === 'Nama')?.omit,
            cell: (row) => {
                const concatName = `${row.firstName} ${row.lastName}`;
                return concatName
            }
        },
        {
            name: 'Email',
            width: '200px',
            selector: row => row.email?.toLowerCase(),
            sortable: true,
            cell: row => {
                return <p>{row.email}</p>
            },
            reorder: true,
            omit: hideColumns.find(item => item.name === 'Email')?.omit,
        },
        {
            name: 'Login',
            width: '200px',
            cell: row => {
                if (row.userId === user.data.id) {
                    return <span>
                        <span className='cursor-pointer p-2' style={{ backgroundColor: '#ABABAB', borderRadius: '5px' }} onClick={() => {
                            if (row.statusData === 'New') {
                                setShowUpdatePassword(true)
                                setEmails(row)
                            } else {
                                handleEmailLogin(row, token)
                            }
                        }}>
                            {
                                (row.inboxCount !== undefined && row.inboxCount > 0) ? (
                                    <span className='rounded-circle d-flex align-items-center justify-content-center position-absolute text-white' style={{ top: '8px', left: '42px', width: '15px', height: '15px', backgroundColor: '#FF4B4B' }}>{row.inboxCount}</span>
                                ) : <></>
                            }
                            <KTSVG className='svg-icon-1' path='/media/svg/general/email.svg' />
                        </span>
                    </span>
                } else {
                    return <span>-</span>
                }
            },
            reorder: true,
            omit: hideColumns.find(item => item.name === 'Login')?.omit,
        },
    ];

    let actions: ReactNode[] = []
    if (user.data.is_superadmin) {
        actions.push(<AddEmail />)
    }

    useEffect(() => {
        if (showUpdateProfile && emails) {
            const modalElement = document.getElementById(`update-email-modal-${emails.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                const handleModalHide = () => {
                    setShowUpdateProfile(false);
                };

                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }

    }, [showUpdateProfile, emails, setShowUpdateProfile]);

    useEffect(() => {
        if (showDeleteModal && emails) {
            const modalElement = document.getElementById(`delete-email-modal-${emails.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, emails, setShowDeleteModal]);

    return (
        <>
            <div className='row'>
                <div className="my-5">
                    {
                        isLoading ? <span className="indicator-progress">
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> : (
                            <div className="d-flex align-items-center w-250px flex-column float-end">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                    <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'FORM.LABEL.EMAIL_LIMIT' })}</span>
                                    <span className="fw-bolder fs-6">{customNumberFormat(parseInt(dataLimitEmail.data.totalEmails))} / {customNumberFormat(parseInt(dataLimitEmail.data.limitEmails))}</span>
                                </div>
                                <div className="h-5px mx-3 w-100" style={{ backgroundColor: "white" }}>
                                    <div className="bg-success rounded h-5px" role="progressbar" style={{ width: Math.round(dataLimitEmail.data.totalEmails / dataLimitEmail.data.limitEmails * 100) + "%" }}>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
                <MasterDataTable
                    tableKey="emails"
                    tableColumns={tableColumns}
                    actions={actions}
                    expanded
                    apiURL={user.data.is_superadmin ? `email/master-emails?companyId=${user.data.company_id}` : `email/master-emails?userId=${user.data.id}&companyId=${user.data.company_id}`}
                    order='desc'
                />
            </div>

            {showUpdateProfile && <UpdateEmail email={emails as Email} />}

            {showDeleteModal && (
                <DeleteEmail email={emails as Email} />
            )}

            <ChangePasswordEmail
                mutate={mutate}
                show={showUpdatePassword}
                onHide={() => { setShowUpdatePassword(false) }}
                email={emails as Email}
                myEmail={emails?.email}
                typeModul='crm'
            />
        </>
    )
}

export default MasterEmail