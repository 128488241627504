import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import moment from 'moment';
import { UserDataType } from '../../../interfaces/Email';

export const exportTemplateExcelEmail = async (
    source: string,
    typeAction: string,
    nameFile: string,
    nameHeader: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    customFields?: any,
    user?: UserDataType,
    param?: any,
    type?: string,
) => {
    let exportData: any[] = [];

    const workbook = createWorkbook();
    const worksheet = createWorksheet(workbook, "Sheet1");
    const worksheetInfo = createWorksheet(workbook, "Info");
    let header: string[] = []
    let subHeader: string[] = []

    if (source === 'template-detail-group-email') {
        if (typeAction === 'insert') {
            header = [
                ...nameHeader.insert.email.header
            ];

            subHeader = [
                ...nameHeader.insert.email.subHeader
            ];

            if (type === 'error') {
                header.push('Error')
                subHeader.push(nameHeader.error) // menambahkan text error 
                Array.from(param.listErrorInsert).map((row: any, index) => {
                    let temp_excel = [];
                    temp_excel.push(
                        row.name,
                        row.email,
                        ...Object.keys(customFields).map((field: any) =>
                            // menambahkan string kosong jika tidak ada data
                            row[field] ? row[field] : ''
                        ),
                        row.error
                    );

                    exportData.push(temp_excel);
                });
            }
        } else if (typeAction === 'update') {
            await worksheet.protect('mjioerh3uiqWHEIOPHQWIOPEJHQWIO', { // jika update maka di protect semuanya, kecuali column data yang di update dan jumlah kolom custom field nya
                formatColumns: true,
                autoFilter: true
            });

            header = [
                ...nameHeader.update.email.header
            ];

            subHeader = [
                ...nameHeader.update.email.subHeader
            ];

            if (type === 'error') {
                header.push('Error')
                subHeader.push(nameHeader.error)
                Array.from(param.listErrorUpdate).map((row: any, index) => {
                    var temp_excel = [];
                    temp_excel.push(
                        btoa(row.id.slice(0, 3) + Math.floor(100 + Math.random() * 900) + row.id.slice(3)), // menambahkan identifier untuk update agar tahu field mana yang di update
                        row.name,
                        row.email,
                        // mungkin di sini di tambahkan error dari custom field nya
                        ...row.dataCustomField.map((field: any) =>
                            row[field] ? row[field] : ''
                        ),
                        row.error
                    );

                    exportData.push(temp_excel);
                });
            } else {
                param.dataTables.map((row: any) => {
                    var temp_excel = [];
                    temp_excel.push(
                        btoa(row.id.slice(0, 3) + Math.floor(100 + Math.random() * 900) + row.id.slice(3)), // identifier untuk update
                        row.name,
                        row.email
                    );
                    row.dataCustomField.forEach((field: any) => {
                        temp_excel.push(Object.values(field)[0]); // -> ['1234'] -> '1234' -> merubah field {'wd3rada': '1234'} -> '1234'
                    });
                    exportData.push(temp_excel);
                });
            }
        }

        worksheet.getRow(1).values = header
        worksheet.getRow(2).values = subHeader
        setColumnWidths(worksheet, source, header!, exportData);
        styleColumns(worksheet, 1);
        styleColumns(worksheet, 2);
        addDataRows(worksheet, exportData, source, typeAction, type, param);
        addWorkSheetInfo(worksheetInfo, nameFile, nameHeader, user!);
    }



    exportWorkbook(workbook, nameFile, setIsLoading);
};

export const exportTemplateExcelEmailGroup = async (
    source: string,
    typeAction: string,
    nameFile: string,
    nameHeader: any,
    data?: any
) => {
    let exportData: any[] = [];

    const workbook = createWorkbook();
    const worksheet = createWorksheet(workbook, "Sheet1");

    let header: string[] = []

    if (source === 'template-detail-group-email') {
        if (typeAction === 'export') {
            header = [
                ...nameHeader.export.email
            ];

            data.map((row: any) => {
                var temp_excel = [];
                const customFieldValues = row.dataCustomField.map((field: any) => {
                    return Object.values(field)[0];
                })
                temp_excel.push(
                    row.name,
                    row.email,
                    ...customFieldValues
                );

                exportData.push(temp_excel);
            });
        }
        worksheet.getRow(1).values = header
        setColumnWidths(worksheet, source, header!, exportData);
        addDataRows(worksheet, exportData, source, typeAction);
        styleColumns(worksheet, 1);
    }

    exportWorkbook(workbook, nameFile);
};

const addWorkSheetInfo = async (worksheetInfo: ExcelJS.Worksheet, nameFile: string, nameHeader: any, user: UserDataType) => {
    worksheetInfo.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });

    // Sheet Information berisi Header (merge A1 B1), dan terdapat pengunduh, dan waktu unduh, serta terakhir terdapat source -> sesuai template "template-detail-group-email"

    worksheetInfo.mergeCells('A1', 'B1');
    worksheetInfo.getCell('A1').value = nameHeader.info.fileInformation;
    worksheetInfo.getCell('A2').value = nameHeader.info.downloader;
    worksheetInfo.getCell('B2').value = `${user.firstName} ${user.lastName}`;
    worksheetInfo.getCell('A3').value = nameHeader.info.downloadTime;
    worksheetInfo.getCell('B3').value = `${moment().format('DD MMM YYYY HH:mm')}`;
    worksheetInfo.getCell('A4').value = nameHeader.info.type;
    worksheetInfo.getCell('B4').value = nameFile;

    worksheetInfo.getRow(1).font = {
        name: 'Arial Black',
        size: 16,
    };
    worksheetInfo.getRow(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheetInfo.getCell('A4').font = {
        color: {
            argb: 'FFFFFFFF'
        }
    };
    worksheetInfo.getCell('B4').font = {
        color: {
            argb: 'FFFFFFFF'
        }
    };

    worksheetInfo.columns = [
        {
            width: 25,
        },
        {
            width: 30,
        },
    ];
}

const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Looyal';
    workbook.lastModifiedBy = 'Looyal';
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
};

const createWorksheet = (workbook: ExcelJS.Workbook, sheetName: string) => {
    const worksheet = workbook.addWorksheet(sheetName);
    return worksheet;
};

const addDataRows = ( // kemungkinan untuk addDataRows di email masih belum di gunakan 
    worksheet: ExcelJS.Worksheet,
    data: any[],
    source: string,
    // user: UserModel,
    typeAction: string,
    type?: string,
    param?: any,
) => {
    data.forEach((rowData) => {
        worksheet.addRow(rowData);
    });

    if (source === 'template-detail-group-email') {
        if (typeAction === 'update') {
            let dataLength = type === 'error' ? param.listErrorUpdate.length : param.dataTables.length
            worksheet.eachRow({ includeEmpty: true }, function (row, rowIndex) {
                if (rowIndex > 2 && rowIndex <= dataLength + 2) {
                    for (let cellIndex = 2; cellIndex <= worksheet.actualColumnCount; cellIndex++) {
                        row.getCell(cellIndex).protection = { locked: false };
                    }
                }
            });
        }
    }
};

const setColumnWidths = (worksheet: ExcelJS.Worksheet, source: string, fields: string[], data: any) => {
    const colWidths = Object.keys(fields).map(() => ({ width: 25 }));
    const colStyles = Object.keys(fields).map(() => ({ numFmt: '@' }));

    worksheet.columns = Object.keys(fields).map((field, index) => ({
        ...colWidths[index], style: colStyles[index],
    }));
};

const styleColumns = (
    worksheet: ExcelJS.Worksheet,
    indexRow: number
) => {
    if (indexRow === 1) {
        worksheet.getRow(indexRow).alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
        };
        worksheet.getRow(indexRow).font = {
            name: 'Arial Black',
            size: 12,
            bold: true,
        };
        worksheet.getRow(indexRow).eachCell(function (cell) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '34A8EB',
                },
            };
        });
    } else if (indexRow === 2) {
        worksheet.getRow(indexRow).eachCell(function (cell) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '66B2FF'
                },
            }
        });
    }



    var endRow = worksheet.actualRowCount;
};

const exportWorkbook = async (
    workbook: ExcelJS.Workbook,
    fileName: string,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        if (setIsLoading) setIsLoading(false)
    });
};
