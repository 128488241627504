import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import { insertMerchant, insertMerchantWithTemplate } from "../../api/MerchantsCRUD";
import useAccessToken from "../../hooks/AccessToken";
import Select from "react-select";
import useSWR, { mutate } from "swr";
import axios from "axios";
import { City } from "../../interfaces/City";
import { nanoid } from "@reduxjs/toolkit";
import { useSettingsStore } from "../../stores/SettingsStore";
import { CustomField, CustomFieldSerialProps, SettingMultiMerchants } from "../../interfaces/Settings";
import moment from "moment";
import { insertMerchantFieldMultitAttachment, insertMerchantFieldtAttachment } from "../../api/ResourceAPI";
import { DatePicker } from "rsuite";
import { ChatWindowData } from "../../interfaces/Chat/ChatWindowData";
import { Merchant } from "../../interfaces/Merchant";
import useUser from "../../hooks/User";
import { User } from "../../interfaces/User";
import { SelectOption } from "../../interfaces/SelectOption";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { checkWhatsapp } from "../../api/Whatsapp";
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps, isValidUrl, getDataById } from "../../functions/general";
import { resolve } from "dns";
import { useIntl } from "react-intl";
import useTodayRange from "../../hooks/TodayRange";
import InputAttachment from "../Input/InputAttachment";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { useCustomeStore } from "../../stores/CustomeStore";
import { tailspin } from 'ldrs'
import { generateSerialApi } from "../../api/Generals";
import InputPhone from "../Input/InputPhone";
import { useMerchantStore } from "../../stores/MerchantStore";
import { useLocation, useParams } from "react-router-dom";
import { useChatStore } from "../../stores/ChatStore";
import VirtualizedSelect, { Option, VirtualizedSelectOptionsType } from "../Select/SelectVirtualized";

interface CityOption {
    value: string;
    label: string;
}

interface AddMerchantProps {
    chatWindowData?: ChatWindowData
    addMerchant?: (merchant: Merchant) => void;
}

const AddMerchant: FC<AddMerchantProps> = ({ chatWindowData, addMerchant }) => {
    const todayRange = useTodayRange();
    const { companyId } = useCustomeStore()
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { selectMultiMerchant, multiMerchants, merchants, simpleMerchants, simpleMerchantLoading, merchantLoading, setSimpleMerchants, setSimpleMerchantLoading, setMerchants } = useMerchantStore()
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()
    const [cityData, setCityData] = useState<CityOption[]>([]);
    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const { settings } = useSettingsStore()
    tailspin.register()
    const { pathname } = useLocation()
    const [codeUrl, setCodeUrl] = useState((pathname).split('/').pop());
    const { templateMerchantId, templatePipelineId } = useParams();
    const [customFields, setCustomFields] = useState<CustomField>({})
    const [dataCustomFieldSerial, setDataCustomFieldSerial] = useState<CustomFieldSerialProps>({});
    const refArray = useRef<(HTMLInputElement | null)[]>([]);
    const user = useUser()
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const [checkWa, setCheckWa] = useState("")
    const [prevCountryCode, setPrevCountryCode] = useState("62");
    const [selectTemplateMerchant, setSelectTemplateMerchant] = useState<string>('')
    const [merchantTemplateOption, setMerchantTemplateOption] = useState<SelectOption[]>([])
    const intl = useIntl()

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL

    const getUserURL = () => {
        if (user.data.permission_merchant_create === "ALL") {
            return `${API_URL}users`
        } else {
            return `${API_URL}user/subordinates`
        }
    }

    const { data = [], error, isLoading } = useSWR(`${API_URL}cities`, fetcher)
    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(getUserURL(), fetcher)
    const { data: labels = [], error: labelsError, isLoading: labelsLoading } = useSWR(`${API_URL}labels?usableBy[]=merchant`, fetcher)
    const { data: merchantTemplateData = [], error: merchantTemplateError, isLoading: merchantTemplateLoading } = useSWR(`${API_URL}merchant-templates${templatePipelineId ? `?leadTemplateId=` + templatePipelineId : ""}`, fetcher)
    const { data: simpleMerchantsData = [], error: simpleMerchantsError, isLoading: simpleMerchantsLoading } = useSWR(`${API_URL}simple-merchants`, fetcher)

    const [labelsOption, setLabelOption] = useState([])

    useEffect(() => {
        const merchantTemplateOption: SelectOption[] = merchantTemplateData.map((template: SettingMultiMerchants) => {
            return {
                label: template.name,
                value: template.id
            }
        })
        setMerchantTemplateOption(merchantTemplateOption)
    }, [merchantTemplateData])

    useEffect(() => {
        const newLabelsOption = labels.map((label: any) => { return { value: label.id, label: label.name } })
        setLabelOption(newLabelsOption)
    }, [labels])

    const formSchema = Object.keys(customFields).reduce((obj: any, item: string) => {
        const field = customFields[item];
        const isRequiredMessage = intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: field.name });
        const isValidUrlMessage = intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" });

        if (field.type === 'multiple' || field.type === 'images' || field.type === 'files' || field.type === 'merchant') {
            obj[item] = field.required
                ? Yup.array().min(1, isRequiredMessage).required(isRequiredMessage).nullable()
                : Yup.array().notRequired().nullable();
        } else if (field.type === 'date') {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        } else if (field.type === 'link') {
            obj[item] = field.required
                ? Yup.string().nullable().required(isRequiredMessage).test('is-required', isValidUrlMessage, function (value) {
                    return !value || isValidUrl(value);
                })
                : Yup.string().nullable().test('is-required', isValidUrlMessage, function (value) {
                    return !value || isValidUrl(value);
                });
        } else if (field.type === 'serial') {
            obj[item] = Yup.array().notRequired().nullable();
        }
        else {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        }

        return obj;
    }, {});

    const validationSchema = Yup.object().shape({
        ownerId: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PIC" }) })),
        name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
        merchantTemplateId: templateMerchantId ? Yup.string().notRequired() : Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FILTER.LABEL.TEMPLATE" }) })),
        city: user.data.company_id === companyId.elgisa ? Yup.string().notRequired().nullable() : Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.CITY" }) })),
        phone: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PHONE" }) })),
        labels: Yup.array().notRequired(),
        email: Yup.string().email(intl.formatMessage({ id: "FORM.VALIDATION.EMAIL" })).notRequired().nullable(),
        ...formSchema
    });

    const initialValues = {
        ownerId: user.data.permission_merchant_create !== 'TEAM' && user.data.permission_merchant_create !== 'ALL' ? user.data.name : '',
        name: chatWindowData?.name ?? '',
        city: '',
        phone: chatWindowData?.phone ?? '',
        email: '',
        merchantTemplateId: selectTemplateMerchant,
        labels: '',
        ...Object.fromEntries(Object.keys(customFields).map((key) => [key, null])),
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true);
            setSubmitting(true);
            try {
                await checkWaValid(values.phone).then(async (valCheckWa) => {
                    // Assuming values is of type Record<string, string> and customFields is of type fieldCustom
                    const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                        (obj, item) => ({
                            ...obj,
                            [item]: (values as Record<string, string | string[] | null>)[item],
                        }),
                        {}
                    );

                    const convertCustomFields: Record<string, string> = Object.keys(customFields).reduce(
                        (obj, item) => ({
                            ...obj,
                            [`custom_fields.${item}`]: (values as Record<string, string | string[] | null>)[`custom_fields.${item}`] ?? "",
                        }),
                        {}
                    );

                    // Use Promise.all to await all async operations
                    var countResourceError = 0
                    await Promise.all(
                        Object.keys(customFields).map(async (key) => {
                            if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                                try {
                                    if (values[key as keyof typeof values]) {
                                        const resourceResponse = await insertMerchantFieldtAttachment(values[key as keyof typeof values]);
                                        if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                        else countResourceError++
                                    }
                                } catch (error) {
                                    countResourceError++
                                }
                            } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                                try {
                                    if (values[key as keyof typeof values]) {
                                        const resourceResponse = await insertMerchantFieldMultitAttachment(values[key as keyof typeof values] as any);
                                        if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data : null;
                                        else countResourceError++
                                    }
                                } catch (error) {
                                    countResourceError++
                                }
                            } else if (customFields[key].type === 'multiple') {
                                var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
                                customFieldsValues[key] = multipleValues as string
                            } else if (customFields[key].type === 'serial') {
                                try {
                                    if (dataCustomFieldSerial[key].value.length === 0) {
                                        const serialResponse = await generateSerialApi(key, "Merchant", token);
                                        customFieldsValues[key] = serialResponse.data.success ? serialResponse.data.data : null;
                                    } else {
                                        customFieldsValues[key] = dataCustomFieldSerial[key].value
                                    }
                                } catch (error) {
                                    countResourceError++
                                }
                            } else if (customFields[key].type === 'merchant') {
                                convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as any, simpleMerchants)?.map((item: any) => item.name).join(", ");
                            }
                        })
                    );

                    if (values.phone.startsWith('0')) {
                        values.phone = '62' + values.phone.substring(1);
                    }

                    // Continue with insertMerchant after checkWaValid and insertMerchantFieldAttachment
                    var isWhatsappPhone = valCheckWa === "true" ? true : false;
                    const merchantLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label } }) : []

                    if (countResourceError > 0) {
                        Swal.fire({
                            icon: "error",
                            text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_FAILED" })}.`,
                            heightAuto: false,
                            confirmButtonText: 'Ok'
                        })
                        setModalBtnLoading(false);
                        setSubmitting(false);
                    } else {
                        const response = await insertMerchantWithTemplate(
                            templateMerchantId || values.merchantTemplateId,
                            merchantLabels,
                            user.data.permission_merchant_create === 'TEAM' || user.data.permission_merchant_create === 'ALL' ? values.ownerId : user.data.id,
                            values.name,
                            values.city ? values.city : null,
                            values.phone.replace("+", ""),
                            isWhatsappPhone,
                            token,
                            values.email ? values.email : null,
                            customFieldsValues
                        );

                        if (response.data.success) {
                            Swal.fire({
                                icon: "success",
                                title: intl.formatMessage({ id: 'ALERT.TITLE.SUCCESS' }),
                                text: intl.formatMessage({ id: 'FORM.VALIDATION.ADD_SUCCESS' }),
                                // target: document.getElementById(`add-merchant-modal`),
                                heightAuto: false,
                            });
                            closeModalRef.current?.click();
                            if ((window.location.pathname === '/meetings' || window.location.pathname.includes('/pipelines')) && addMerchant) addMerchant(response.data.data);
                            if (window.location.pathname === '/chat') {
                                const { filteredRecentChatHistories, setRecentChatHistories } = useChatStore.getState();

                                const newFilteredRecentChatHistories = filteredRecentChatHistories.map(recentChat => {
                                    if (recentChat.chat_phone === chatWindowData?.phone) {
                                        let merchantId = [...recentChat.merchant_ids!, response.data.data.id]
                                        let merchantName = [...recentChat.merchant_name!, response.data.data.name]

                                        return {
                                            ...recentChat,
                                            merchant_id: merchantId.join(", "),
                                            merchant_ids: merchantId,
                                            merchant_name: merchantName.join(", "),
                                        };
                                    }
                                    return recentChat;
                                })

                                setRecentChatHistories(() => newFilteredRecentChatHistories);
                                setMerchants([...merchants, response.data.data]);
                            }
                            mutate(`${API_URL}chat/merchants-leads`)
                            mutate(`${API_URL}merchants`)
                            setTablesData('merchants', ([
                                {
                                    ...response.data.data,
                                    ...convertCustomFields,
                                    lastMeetEnd: "-",
                                    lastLeadCreatedTime: "-",
                                    dateCreated: response.data.data.dateCreated
                                },
                                ...tablesData.merchants
                            ]));
                        }
                    }
                });
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    heightAuto: false,
                    // target: document.getElementById(`add-merchant-modal`),
                    confirmButtonText: 'Ok'
                });
            } finally {
                setModalBtnLoading(false);
                setSubmitting(false);
            }
        },
    });

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            formik.setFieldValue('phone', "+62")
            setCheckWa("")

            Object.keys(customFields).map((key, index) => {
                // Reset 
                const ref = refArray.current[index];
                if (ref && ref.value) {
                    ref.value = "";
                }

                if (customFields[key].type === "multiple") {
                    var checkboxes = document.getElementsByName(key)
                    checkboxes.forEach((checkbox: any) => {
                        checkbox.checked = false
                    })
                }

                if (customFields[key].type === "date") {
                    formik.setFieldValue(key, null)
                }
            });
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        if (!isLoading) {
            const cityData = data.map((city: City) => ({
                value: city.id,
                label: city.name
            }));
            cityData.sort((a: CityOption, b: CityOption) => a.label.localeCompare(b.label));
            setCityData(cityData)
        }
    }, [data])

    useEffect(() => {
        if (codeUrl !== (pathname).split('/').pop()) {
            setCodeUrl((pathname).split('/').pop()!)
        }
    }, [pathname])

    useEffect(() => {
        if (!usersLoading) {
            const userData = users.map((user: User) => ({
                value: user.id,
                label: user.name
            }));

            if (user.data.permission_merchant_create !== 'ALL') {
                userData.unshift({ value: user.data.id, label: user.data.name })
            }
            setUsersData(userData)
        }
    }, [users])

    useEffect(() => {
        if (Object.keys(selectMultiMerchant).length !== 0) {
            var data = selectMultiMerchant.customFields
            const transformedData = Object.keys(data).reduce((acc: any, key: string) => {
                acc[key] = {
                    loading: false,
                    value: []
                };
                return acc;
            }, {});

            setCustomFields(data)
            setDataCustomFieldSerial(transformedData)
        }
    }, [selectMultiMerchant])

    useEffect(() => {
        setSimpleMerchantLoading(simpleMerchantsLoading)
        if (!simpleMerchantsLoading) {
            setSimpleMerchants(simpleMerchantsData)
        }
    }, [simpleMerchantsData]);

    useEffect(() => {
        if (formik.values.merchantTemplateId) {
            setCustomFields(multiMerchants.find((pipeline: SettingMultiMerchants) => pipeline.id === formik.values.merchantTemplateId)!.customFields)
        }
    }, [formik.values.merchantTemplateId])

    const checkWaValid = async (phone: string) => {
        setCheckWa("loading")
        try {
            const response = await checkWhatsapp(phone.replace("+", ""))
            setCheckWa(response.data.result.exists ? "true" : "false")
            return response.data.result.exists ? "true" : "false";
        } catch (error) {

        }
    }

    const handleGenerateSerial = async (key: string) => {
        setDataCustomFieldSerial(prevState => ({
            ...prevState,
            [key]: { ...prevState[key], loading: true }
        }));

        try {
            const response = await generateSerialApi(key, "Merchant", token);
            if (response.data.success) {
                formik.setFieldValue(key, response.data.data)
                setDataCustomFieldSerial(prevState => ({
                    ...prevState,
                    [key]: { ...prevState[key], value: response.data.data }
                }));
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                target: document.getElementById('add-merchant-modal'),
                heightAuto: false,
            })
        } finally {
            setDataCustomFieldSerial(prevState => ({
                ...prevState,
                [key]: { ...prevState[key], loading: false }
            }));
        }
    }

    const CustomSelectMerchantOption = (props: { option: VirtualizedSelectOptionsType; onClick?: () => void }) => {
        const { option, onClick } = props;

        return (
            <div className="d-flex align-items-center px-3 h-100" style={{ cursor: 'pointer', background: option.value === 'chocolate' ? '#d9d9d9' : 'transparent', }}>
                {user.data.company_id === companyId.esb ? (
                    <span className="align-middle"><b>{option.nameMerchant}</b> / {option.customFieldCompanyCode ? option.customFieldCompanyCode : "-"}</span>
                ) : (
                    <span className="align-middle">{option.nameMerchant}</span>
                )}
            </div>
        );
    };

    const CustomSelectMultiMerchantValue = (props: { value: Option; placeholder?: string }) => {
        const { value, placeholder } = props;
        if (!value?.value) return <span>{placeholder}</span>

        return (
            user.data.company_id === companyId.esb ?
                <span className="align-middle"><b>{value.nameMerchant}</b> / {value.customFieldCompanyCode ? value.customFieldCompanyCode : "-"}</span>
                :
                <span className="align-middle">{value.nameMerchant}</span>
        );
    }

    return (
        <div>
            {
                !chatWindowData && window.location.pathname !== '/chat' && window.location.pathname !== '/meetings' && !window.location.pathname.includes('/pipelines') &&
                <button type="button"
                    className="btn btn-primary mt-3"
                    data-bs-toggle="modal"
                    data-bs-target="#add-merchant-modal"
                >
                    <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {selectMultiMerchant?.name}
                </button>
            }
            <div className="modal fade" tabIndex={-1} id="add-merchant-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {settings.merchant_title}{window.location.pathname === '/meetings' || window.location.pathname.includes('/pipelines') ? " Baru" : ""}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                {
                                    !templateMerchantId &&
                                    (
                                        <>
                                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.TEMPLATE_TITLE' }, { title: settings.merchant_title })}</label>
                                            <div className="input-group mb-3">
                                                {
                                                    <Select
                                                        options={merchantTemplateOption}
                                                        placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.TEMPLATE_TITLE' }, { title: settings.merchant_title })}`}
                                                        value={merchantTemplateOption.find(option => option.value === formik.values.merchantTemplateId) || null}
                                                        onChange={(option) => {
                                                            formik.handleChange({
                                                                target: {
                                                                    name: "merchantTemplateId",
                                                                    value: option?.value || ""
                                                                }
                                                            })
            
                                                            setSelectTemplateMerchant(option?.value || "")
                                                        }}
                                                        onBlur={
                                                            () => formik.values.merchantTemplateId === "" && formik.setFieldTouched("merchantTemplateId", true)
                                                        }
                                                        className="w-100 z-index-4"
                                                    />
                                                }
                                            </div>
                                            {formik.touched.merchantTemplateId && formik.errors.merchantTemplateId && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{formik.errors.merchantTemplateId}</span>
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                                {/* Users */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.PIC' })}</label>
                                <div className="input-group mb-3">
                                    {
                                        user.data.permission_merchant_create === 'TEAM' || user.data.permission_merchant_create === 'ALL' ?
                                            <Select
                                                key={
                                                    window.location.pathname === '/chat' ? nanoid() : ''
                                                }
                                                options={usersData}
                                                placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.PIC' })}`}
                                                value={usersData.find(option => option.value === formik.values.ownerId) || null}
                                                onChange={
                                                    option => formik.handleChange({
                                                        target: {
                                                            name: "ownerId",
                                                            value: option?.value || ""
                                                        }
                                                    })
                                                }
                                                onBlur={
                                                    () => formik.values.ownerId === "" && formik.setFieldTouched("ownerId", true)
                                                }
                                                className="w-100 "
                                                isDisabled={usersLoading}
                                            />
                                            :
                                            <div className="input-group mb-3">
                                                <input
                                                    {...formik.getFieldProps('ownerId')}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': formik.touched.ownerId && formik.errors.ownerId },
                                                    )}
                                                    type='text'
                                                    name='ownerId'
                                                    autoComplete='off'
                                                    onInput={(e) => handleCaps(e)}
                                                    disabled
                                                />
                                            </div>
                                    }
                                </div>
                                {formik.touched.ownerId && formik.errors.ownerId && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.ownerId}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={intl.formatMessage({ id: 'FORM.LABEL.NAME' })}
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.name}</span>
                                    </div>
                                )}
                                <label className={`form-label fs-6 fw-bolder text-dark ${user.data.company_id !== companyId.elgisa && "required"}`}>{intl.formatMessage({ id: 'FORM.LABEL.CITY' })}</label>
                                <div className="input-group mb-3">
                                    <Select
                                        // key={nanoid()}
                                        options={cityData}
                                        placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.CITY' })}`}
                                        value={cityData.find(option => option.value === formik.values.city) || null}
                                        onChange={
                                            option => formik.handleChange({
                                                target: {
                                                    name: "city",
                                                    value: option?.value || ""
                                                }
                                            })
                                        }
                                        onBlur={
                                            () => formik.values.city === "" && formik.setFieldTouched("city", true)
                                        }
                                        className="w-100"
                                        isDisabled={isLoading}
                                    />
                                </div>
                                {formik.touched.city && formik.errors.city && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.city}</span>
                                    </div>
                                )}

                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.PHONE' })}</label>
                                {
                                    checkWa === "true" ?
                                        <span className="text-success" style={{ float: "inline-end" }}>{intl.formatMessage({ id: "FORM.VALIDATION.VALID_WHATSAPP" })}</span>
                                        : checkWa === "false" ?
                                            <span className="text-danger" style={{ float: "inline-end" }}>{intl.formatMessage({ id: "FORM.VALIDATION.INVALID_WHATSAPP" })}</span>
                                            : checkWa === "loading" ?
                                                <span className="spinner-border spinner-border-sm align-middle" style={{ float: "inline-end" }}></span>
                                                : <></>
                                }
                                <div style={{ marginBottom: "0.75rem" }}>
                                    <PhoneInput
                                        countryCodeEditable={false}
                                        country="id"
                                        value={formik.values.phone}
                                        onChange={(value, country: any) => {
                                            if (country.dialCode !== prevCountryCode) {
                                                setPrevCountryCode(country.dialCode);
                                                setCheckWa("")
                                                formik.setFieldValue('phone', country.dialCode);
                                            } else {
                                                formik.setFieldValue('phone', value);
                                            }
                                        }}
                                        onBlur={() => checkWaValid(formik.values.phone)}
                                        enableSearch={true}
                                        inputStyle={{ width: "100%" }}
                                        dropdownClass="z-index-3"
                                        disabled={chatWindowData ? true : false}
                                    />
                                </div>
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.phone}</span>
                                    </div>
                                )}
                                {/* label */}
                                {
                                    user.data.company_id !== companyId.esb &&
                                    <>
                                        <label className='form-label fs-6 fw-bolder text-dark '>{intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}</label>
                                        <div className="input-group mb-3">
                                            <Select
                                                closeMenuOnSelect={false}
                                                openMenuOnClick
                                                isMulti
                                                options={labelsOption}
                                                placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}`}
                                                value={labelsOption && Array.isArray(labelsOption)
                                                    ? labelsOption.filter((option: any) =>
                                                        formik.values.labels.includes(option.value)
                                                    )
                                                    : []}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions
                                                        ? selectedOptions.map((option: any) => option.value)
                                                        : []
                                                    formik.setFieldValue('labels', selectedValues)
                                                }}
                                                className='w-100'
                                                onBlur={() =>
                                                    formik.values.labels === '' &&
                                                    formik.setFieldTouched('labels', true)
                                                }
                                            />
                                        </div>
                                        {formik.touched.labels && formik.errors.labels && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.labels}</span>
                                            </div>
                                        )}
                                    </>
                                }
                                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder='Email'
                                        {...formik.getFieldProps('email')}
                                        className={clsx(
                                            'form-control form-control-lg txt-email',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='email'
                                        name='email'
                                        autoComplete='off'
                                    />
                                </div>
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.email}</span>
                                    </div>
                                )}
                                {
                                    customFields && Object.keys(customFields).map((key, index) => {
                                        if (customFields[key].type === 'text') {
                                            return (
                                                <div key={index}>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            placeholder={customFields[key].name}
                                                            {...formik.getFieldProps(key)}
                                                            className={clsx(
                                                                'form-control form-control-lg',
                                                                { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                            )}
                                                            type='text'
                                                            name={key}
                                                            autoComplete='off'
                                                            style={{ zIndex: 0 }}
                                                            required={true}
                                                            onInput={(e) => handleCaps(e)}
                                                            ref={ref => {
                                                                refArray.current[index] = ref; // took this from your guide's example.
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'serial') {
                                            return (
                                                <div key={index}>
                                                    <label className={`form-label fs-6 fw-bolder text-dark`}>{customFields[key].name}</label>
                                                    {
                                                        dataCustomFieldSerial[key].loading ?
                                                            <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" style={{ float: "inline-end" }}>
                                                                <l-tailspin
                                                                    size="15"
                                                                    stroke="3"
                                                                    speed="0.9"
                                                                    color="#ffffff"
                                                                ></l-tailspin>
                                                            </span>
                                                            :
                                                            <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" onClick={() => handleGenerateSerial(key)} style={{ float: "inline-end", cursor: "pointer" }}>{intl.formatMessage({ id: 'FORM.ACTION.GENERATE_SERIAL' })}</span>
                                                    }
                                                    <div className="d-flex mb-3 gap-3">
                                                        <input
                                                            disabled
                                                            placeholder={customFields[key].name}
                                                            {...formik.getFieldProps(key)}
                                                            value={typeof formik.values[key as keyof typeof formik.values] === 'string' ? formik.values[key as keyof typeof formik.values] : (formik.values[key as keyof typeof formik.values] as any)?.join("")}
                                                            className={clsx('form-control form-control-lg')}
                                                            type='text'
                                                            name={key}
                                                            autoComplete='off'
                                                            style={{ zIndex: 0 }}
                                                            ref={ref => {
                                                                refArray.current[index] = ref; // took this from your guide's example.
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'link') {
                                            return (
                                                <div key={index}>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            placeholder={customFields[key].name}
                                                            {...formik.getFieldProps(key)}
                                                            className={clsx(
                                                                'form-control form-control-lg txt-link',
                                                                { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                            )}
                                                            type='text'
                                                            name={key}
                                                            autoComplete='off'
                                                            style={{ zIndex: 0 }}
                                                            required={true}
                                                            ref={ref => {
                                                                refArray.current[index] = ref; // took this from your guide's example.
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'phone') {
                                            return (
                                                <div>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <InputPhone keyString={key} formik={formik} type="input" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'image') {
                                            return (
                                                <>
                                                    {/* Attachments */}
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format JPG, JPEG, PNG</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={false} type="input" accept="image/png, image/jpeg, image/jpg" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'images') {
                                            return (
                                                <>
                                                    {/* Attachments */}
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format JPG, JPEG, PNG</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={true} type="input" accept="image/png, image/jpeg, image/jpg" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'file') {
                                            return (
                                                <>
                                                    {/* Attachments */}
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={false} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'files') {
                                            return (
                                                <>
                                                    {/* Attachments */}
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={true} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'date') {
                                            return (
                                                <>
                                                    {/* Date */}
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <DatePicker
                                                            ranges={todayRange}
                                                            oneTap
                                                            editable={false}
                                                            format="dd MMM yyyy"
                                                            value={formik.values[key as keyof typeof formik.values] ? new Date(formik.values[key as keyof typeof formik.values]) : null}
                                                            defaultValue={null}
                                                            style={{ width: "100%", zIndex: 0 }}
                                                            container={document.querySelector('#add-merchant-modal') as HTMLElement}
                                                            onChange={(value: any) => {
                                                                if (value) formik.setFieldValue(key, moment(value).format('YYYY-MM-DD'))
                                                                else formik.setFieldValue(key, null)
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'option') {

                                            const options = customFields[key].value.map((option, index) => ({
                                                value: option,
                                                label: option
                                            }))

                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <Select
                                                            // key={nanoid()}
                                                            isClearable
                                                            options={options}
                                                            placeholder={`Pilih ${customFields[key].name}`}
                                                            value={options.find(option => option.value === formik.values[key as keyof typeof formik.values]) || null}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: key,
                                                                        value: option?.value || ""
                                                                    }
                                                                })
                                                            }
                                                            onBlur={
                                                                () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                                            }
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'merchant') {
                                            var filteredMerchants = simpleMerchants
                                            if((customFields[key].value as string[]).length > 0) {
                                                filteredMerchants = simpleMerchants.filter((merchant: Merchant) => (customFields[key].value as string[]).includes(merchant.merchantTemplateId))
                                            }

                                            const isEsbCompany = user.data.company_id === companyId.esb
                                            const options = filteredMerchants.map((option: Merchant) => ({
                                                value: option.id,
                                                label: `${option.name}${isEsbCompany ? " / " + (option.customFieldCompanyCode || "-") : ""}`,
                                                nameMerchant: option.name,
                                                customFieldCompanyCode: option.customFieldCompanyCode
                                            }))

                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <VirtualizedSelect
                                                        isMulti={true}
                                                        title={settings.merchant_title}
                                                        options={options}
                                                        isDisabled={simpleMerchantLoading}
                                                        className={clsx("w-100 mb-3")}
                                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                        value={
                                                            options.filter(option =>
                                                                formik.values[key as keyof typeof formik.values]?.includes(option.value)
                                                            )
                                                        }
                                                        onChange={(selectedOptions) =>
                                                            formik.setFieldValue(
                                                                key,
                                                                selectedOptions ? selectedOptions.map((option: any) => option.value) : []
                                                            )
                                                        }
                                                        onBlur={() => {
                                                            if (formik.values[key as keyof typeof formik.values] === "") {
                                                                formik.setFieldTouched(key, true);
                                                            }
                                                        }}
                                                        components={
                                                            {
                                                                Option: CustomSelectMerchantOption,
                                                                MultiValue: CustomSelectMultiMerchantValue,
                                                            }
                                                        }
                                                    />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'multiple') {
                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <div className="form-check form-check-custom form-check-solid" style={{ display: 'contents' }}>
                                                            {
                                                                customFields[key].value.map((option, index) => (
                                                                    <div style={{ display: 'flex', padding: "3px", overflowWrap: "anywhere" }} key={index}>
                                                                        <input
                                                                            key={index}
                                                                            {...formik.getFieldProps(key)}
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={option as string}
                                                                            name={key}
                                                                        />
                                                                        <label className="form-check-label me-2">{option}</label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        return null
                                    })
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { AddMerchant }