import { FC, useEffect } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { Reimburse } from "../../interfaces/Reimbursement";
import { Modal } from "bootstrap";
import { useIntl } from "react-intl";

interface ReimburseAttachmentsProps {
    reimburse: Reimburse;
    image: string;
    reimburseType: string;
}

const ReimburseAttachments: FC<ReimburseAttachmentsProps> = ({ reimburse, image, reimburseType }) => {
    const intl = useIntl()

    return (
        <div className="modal fade" tabIndex={-1} id={`attachments-reimburse-modal`}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                                <h5 className="modal-title">{reimburseType === "Pengeluaran" ? intl.formatMessage({ id: 'FORM.LABEL.PROOF_OF_EXPENSE' }) : intl.formatMessage({ id: 'FORM.LABEL.PROOF_OF_PAYMENT' })} {reimburse.name}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body text-center">
                        <img src={image} alt={image} className="img-fluid" />
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                        >
                            {intl.formatMessage({ id: 'FORM.ACTION.CLOSE' })}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ReimburseAttachments }