import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import useUser from "./User";
import { CustomField } from "../interfaces/Settings";
import { useCustomeStore } from "../stores/CustomeStore";
import { usePipelineStore } from "../stores/PipelineStore";
import { useSettingsStore } from "../stores/SettingsStore";
import { useReceivableStore } from "../stores/Receivable";

export const useNameColumn = () => {
    const { multiPipelines } = usePipelineStore()
    const { settings } = useSettingsStore();
    const { companyId } = useCustomeStore();
    const intl = useIntl();
    const user = useUser();
    const { templatePipelineId } = useParams()
    const { selectMultiPipelineCustomField } = useReceivableStore()


    var arrayCustomFieldPipeline: CustomField = templatePipelineId && multiPipelines.length > 0 ? multiPipelines.find(item => item.id === templatePipelineId)?.customFields! : {};


    let filteredCustomFieldPipeline = Object.fromEntries(
        Object.entries(arrayCustomFieldPipeline).filter(([key, value]) => {
            return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial' && value.type !== 'merchant' && value.type !== 'user' && value.type !== 'pipeline';
        })
    );

    /*
    Custom Field :

    Jangka Waktu Sewa, Periode Start Sewa, Periode Berakhir Sewa, Nilai Jaminan, Attachment Doc inv
    */

    let nameHeaderExcel = {
        export: {
            accountReceivable: {
                header: [
                    'Nama Tenant', 'Keterangan', 'Telepon', 'Nominal Termin', 'Nominal Kontrak', 'Produk', 'Sumber'
                ],
                subHeader: [
                    'Nama Tenant', 'Keterangan', 'Telepon', 'Nominal Termin', 'Nominal Kontrak', 'Produk', 'Sumber'
                ]
            }
        }
    }

    return nameHeaderExcel
}

export default useNameColumn