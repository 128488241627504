import { create } from 'zustand';
import { SelectOption } from '../interfaces/SelectOption';
import { DateRange } from "rsuite/esm/DateRangePicker";
type DataTableState = {
  tablesData: { [key: string]: any[] };
  filteredTablesData: { [key: string]: any[] };
  selectedFilters: { [key: string]: { [key: string]: string } };
  dateRangeMerchants: DateRange | null;
  selectedColumns: { [key: string]: SelectOption[] };
  filterTextTable: { [key: string]: string };
  isLoadingTable: { [key: string]: boolean };
  isValidatingTable: { [key: string]: boolean };
  isFilterDate: boolean;
  dateStart: string;
  dateEnd: string;
  isFullyLoaded: boolean;
  allDataPipeline: any[];
  fetchCounter: number;
  setFetchCounter: (value: number) => void;
  setTablesData: (tableKey: string, data: any[]) => void;
  setFilteredTablesData: (tableKey: string, filteredData: any[]) => void;
  setSelectedFilter: (tableKey: string, filterType: string, value: string[]) => void;
  setFilterTextTable: (tableKey: string, filterTextTable: string) => void;
  setSelectColumn: (tableKey: string, value: SelectOption[]) => void;
  setIsLoadingTable: (tableKey: string, value: boolean) => void;
  setIsValidatingTable: (tableKey: string, value: boolean) => void;
  setIsFilterDate: (value: boolean) => void;
  setDateStart: (value: string) => void;
  setDateEnd: (value: string) => void;
  setDateRangeMerchants: (value: DateRange | null) => void;
  setIsFullyLoaded: (value: boolean) => void;
  setAllDataPipeline: (value: any[]) => void;
};

export const useDataTableStore = create<DataTableState>((set) => ({
  dateRangeMerchants: null,
  isFilterDate: false,
  tablesData: {},
  filteredTablesData: {},
  selectedFilters: {},
  filterTextTable: {},
  selectedColumns: {},
  isLoadingTable: {},
  isValidatingTable: {},
  dateStart: '',
  dateEnd: '',
  isFullyLoaded: false,
  allDataPipeline: [],
  fetchCounter: 0,
  setFetchCounter: (value) => set({ fetchCounter: value }),
  setAllDataPipeline: (value) => set({ allDataPipeline: value }),
  setIsFullyLoaded: (value) => set({ isFullyLoaded: value }),
  setDateRangeMerchants: (value) => set({ dateRangeMerchants: value }),
  setIsFilterDate: (value) => set({ isFilterDate: value }),
  setDateStart: (value) => set({ dateStart: value }),
  setDateEnd: (value) => set({ dateEnd: value }),
  setIsLoadingTable: (tableKey, value) => {
    set((state) => ({
      isLoadingTable: {
        ...state.isLoadingTable,
        [tableKey]: value,
      },
    }));
  },
  setIsValidatingTable: (tableKey, value) => {
    set((state) => ({
      isValidatingTable: {
        ...state.isValidatingTable,
        [tableKey]: value,
      },
    }));
  },
  setTablesData: (tableKey, data) => {
    set((state) => ({
      tablesData: {
        ...state.tablesData,
        [tableKey]: data,
      },
    }));
  },
  setFilteredTablesData: (tableKey, filteredData) => {
    set((state) => ({
      filteredTablesData: {
        ...state.filteredTablesData,
        [tableKey]: filteredData,
      },
    }));
  },
  setSelectedFilter: (tableKey: string, filterType: string, value: string[]) => {
    set(
      (state) =>
      ({
        selectedFilters: {
          ...state.selectedFilters,
          [tableKey]: {
            ...state.selectedFilters[tableKey],
            [filterType]: value,
          },
        },
      } as DataTableState)
    );
  },

  setFilterTextTable: (tableKey, filterTextTable) => {
    set((state) => ({
      filterTextTable: {
        ...state.filterTextTable,
        [tableKey]: filterTextTable,
      },
    }));
  },

  setSelectColumn: (tableKey, filteredData) => {
    set((state) => ({
      selectedColumns: {
        ...state.selectedColumns,
        [tableKey]: filteredData,
      },
    }));
  },
}));
