import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const PROFILE = `${API_URL}user-update-profile-picture/`

type UpdateProfile = {
    profilePicture: string,
    token: string,
}

export const updateProfile = async ({
    profilePicture,
    token
} : UpdateProfile) => {
    return axios.patch(PROFILE, {
        profilePicture,
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}