import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { debounce } from 'lodash';

interface ExpandableElementProps {
    text: string;
    maxHeight: number;
}

const ExpandableElement: FC<ExpandableElementProps> = ({ text, maxHeight }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showExpandButton, setShowExpandButton] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const intl = useIntl();

    const toggleExpand = () => setIsExpanded(!isExpanded);

    useEffect(() => {
        const checkOverflow = () => {
            if (contentRef.current) {
                const isOverflowing = contentRef.current.scrollHeight > maxHeight;
                setShowExpandButton(isOverflowing);
            }
        };
    
        const onResize = () => {
            requestAnimationFrame(checkOverflow);
        };
    
        window.addEventListener('resize', onResize);
        checkOverflow(); // Memeriksa overflow saat mount
    
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [text, maxHeight]);
    
    return (
        <div>
            <div
                ref={contentRef}
                className="expandable-element"
                dangerouslySetInnerHTML={{ __html: text }}
                style={{
                    wordWrap: "break-word",
                    maxHeight: showExpandButton && !isExpanded ? `${maxHeight}px` : 'none',
                    overflow: showExpandButton && !isExpanded ? 'hidden' : 'visible',
                    display: showExpandButton && !isExpanded ? '-webkit-box' : 'block',
                    WebkitLineClamp: showExpandButton && !isExpanded ? 3 : 'unset',
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal',
                }}
            />
            {showExpandButton && (
                <span
                    onClick={toggleExpand}
                    style={{ color: '#3292FF', cursor: 'pointer', display: 'block', marginTop: '5px' }}
                >
                    {isExpanded ? intl.formatMessage({ id: "FORM.ACTION.HIDE" }) : intl.formatMessage({ id: "FORM.ACTION.READ_MORE" })}
                    <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} style={{ fontSize: '0.8em', color: '#3292FF', marginLeft: '5px' }}></i>
                </span>
            )}
        </div>
    );
}

export { ExpandableElement };
