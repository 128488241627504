import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import { Pipeline, ListProduct } from '../../interfaces/index';
import { TableColumn } from 'react-data-table-component';
import { getDateSummary, sortColumnDatatable } from '../general';
import moment from 'moment';
import 'moment-duration-format';
import { UserModel } from '../../app/modules/auth/models/UserModel';
import { generateLabel } from './exportExcelUtil';

interface IProducLabelMerge {
    productName: string
    productLabelName: string
    productLabelColor: string
}
interface IProductLabelAcc {
    [key: string]: {
        productLabelColor: string;
        productLabelName: string;
        productSName: string[];
    };
}
const formatCustomField = (row: any, field: string, type: string) => {
    if (!row.custom_fields || !row.custom_fields[field] || row.custom_fields[field].length === 0) return '-';

    const value = (row as any)[`custom_fields.${field}`];
    if (type === 'invoice_reminder') {
        const parsedValue = JSON.parse(value);
        return [
            parsedValue.invoice_url,
            parsedValue.date_due ? moment(parsedValue.date_due).format('DD MMM YYYY') : '-',
        ];
    }
    if (type === 'date') return value ? moment(value).format('DD MMM YYYY') : '-';
    if (type === 'multiple') return value.toString().split(',').join(', ');
    if (type === 'images' || type === 'files') return value.toString().split(',').join('\n ');
    if (type === 'serial') return value.join('');

    return value;
};

const formatLeadProductDetails = (leadProducts: ListProduct[], detailType: keyof ListProduct) => {
    const details = leadProducts.map((product) => product[detailType]);
    if (detailType == "productLabels") {
        const labels = Object.values(
            leadProducts
                .flatMap((leadProduct) =>
                    leadProduct.productLabels?.map((productLabel): IProducLabelMerge => {
                        return {
                            productName: leadProduct.productName,
                            productLabelName: productLabel.name,
                            productLabelColor: productLabel.color,
                        };
                    })
                )
                .reduce((acc: IProductLabelAcc, item) => {
                    const { productLabelColor, productLabelName, productName } = item as IProducLabelMerge;
                    if (!acc[productLabelName]) {
                        acc[productLabelName] = {
                            productLabelColor,
                            productLabelName,
                            productSName: [],
                        };
                    }
                    acc[productLabelName].productSName.push(productName);
                    return acc;
                }, {} as IProductLabelAcc))

        const productLabelsAsJsonStrings = labels.map(label => {
            return JSON.stringify({ [label.productLabelName]: label.productSName.join() });
        });


        const serializedProductLabels = productLabelsAsJsonStrings.join(", ",).replaceAll("{", " ( ").replaceAll("}", " ) ").replaceAll('"', "");
        const serializedProductLabelsNewLine = serializedProductLabels.replaceAll(") ,", ") ,\n")
        return serializedProductLabelsNewLine
    }

    return details.length > 0 ? details.join('\n') : '-';
};

const getStatus = (status: string) => {
    switch (status) {
        case 'hot':
            return '(Hot)';
        case 'cold':
            return '(Cold)';
        default:
            return '';
    }
};

export const exportExcelPipeline = async (
    nameFile: string,
    source: string,
    settings: any,
    nameHeader: {
        [key: string]: string[];
    },
    param: any,
    selectMultiPipeline: any,
    data?: any[],
    columns?: TableColumn<Pipeline>[],
    user?: UserModel,
) => {
    let exportData: any[] = [];
    var arrayCustomFieldPipelines = Object.keys(selectMultiPipeline).length !== 0
        ? selectMultiPipeline.customFields
        : [];

    const workbook = createWorkbook();
    const worksheet = createWorksheet(workbook, source);
    let subSectorIndex = -1;
    let invoiceIndex = -1;
    let comapnyCodeIndex = -1;
    let nameIndex1 = -1;

    // console.log(arrayCustomFieldPipelines, 'arrayCustomFieldPipelines')

    if (source === 'Pipeline') {
        let nameColumn = nameHeader.pipeline
        if (user?.data.company_id === "7763fccc-af52-43e1-8907-4678ab2423d1") {
            nameColumn.push('Company Code')
        }

        var header = [
            ...nameColumn,
            ...Object.keys(arrayCustomFieldPipelines).flatMap((key) => {
                if (arrayCustomFieldPipelines[key].type === 'invoice_reminder') {
                    return [
                        arrayCustomFieldPipelines[key].name + ' (File)',
                        arrayCustomFieldPipelines[key].name + ' (Tgl Expired)',
                    ];
                } else {
                    return arrayCustomFieldPipelines[key].name;
                }
            }),
        ];

        if (user?.data.company_id !== "d2f2a3da-3829-4872-8891-cae921d555b5") {
            header.splice(2, 1);
        }

        if (
            user?.data.company_id === param.companyId.enterpriseSales ||
            user?.data.company_id === param.companyId.enterpriseService
        ) {
            header.push('Invoice');

            invoiceIndex = header.map((item) => item.toLowerCase()).indexOf('invoice');
            sortColumnDatatable(header, invoiceIndex, 2);
        }

        if (user?.data.company_name === 'Heiszco') {
            subSectorIndex = header.map((item) => item.toLowerCase()).indexOf('sub sector/nama project');
            sortColumnDatatable(header, 10, 4);
            sortColumnDatatable(header, subSectorIndex, 5);
        }

        if (user!.data.company_id === '7763fccc-af52-43e1-8907-4678ab2423d1') {
            comapnyCodeIndex = header.map((item) => item.toLowerCase()).indexOf('company code');
            nameIndex1 = header.findIndex(col => col === `Nama ${settings.merchant_title}` || col === `${settings.merchant_title}'s Name`);
            comapnyCodeIndex !== -1 && sortColumnDatatable(header, comapnyCodeIndex, nameIndex1);
        }

        worksheet.getRow(1).values = header;
        Array.from(data!).forEach((row, index) => {
            const temp_excel = [
                moment(row.date_created).format('DD MMM YYYY'),
                `${row.merchant_name} ${getStatus(row.hot_warm_cold_status)}`,
                row.note ? row.note : '-',
                row.owner_name,
                parseInt(row.lead_logs_count),
                row.lead_subprogress_name
                    ? `${row.lead_progress_name} (${row.lead_subprogress_name})`
                    : row.lead_progress_name,
                row.merchant_phone,
                row.merchant_is_whatsapp_phone ? 'Valid' : 'Tidak Valid',
                getDateSummary(row.lead_age),
                row.nominal,
                ...(user?.data.company_name === "Maspion Square" ? [Number(row.nominal_without_vat)] : []),
                formatLeadProductDetails(row.leadProducts, 'productName'),
                formatLeadProductDetails(row.leadProducts, 'leadProductQty'),
                row.lead_source_name,
                row.creator_name,
                moment(row.date_start).format('DD MMM YYYY'),
                row.date_end ? moment(row.date_end).format('DD MMM YYYY') : '-',
                generateLabel(row) ? generateLabel(row) : '-',
                formatLeadProductDetails(row.leadProducts, 'productLabels') ? formatLeadProductDetails(row.leadProducts, 'productLabels') : '-',
            ];

            if (user!.data.company_id === '7763fccc-af52-43e1-8907-4678ab2423d1') {
                temp_excel.push(row.merchant_company_code ? row.merchant_company_code : '-');
            }

            if (selectMultiPipeline.customFields) {
                Object.keys(arrayCustomFieldPipelines).forEach((field) => {
                    const type = arrayCustomFieldPipelines[field].type;
                    const formattedField = formatCustomField(row, field, type);

                    if (type === 'invoice_reminder') {
                        temp_excel.push(...formattedField);
                    } else {
                        temp_excel.push(formattedField);
                    }
                });
            }

            if (user?.data.company_id !== "d2f2a3da-3829-4872-8891-cae921d555b5") {
                temp_excel.splice(2, 1);
            }

            if (user?.data.company_id === param.companyId.enterpriseSales) {
                temp_excel.push(
                    row.custom_fields[param.keyCustomfield.serial.enterpriseSales]
                        ? (row.custom_fields[param.keyCustomfield.serial.enterpriseSales] as string[]).join('')
                        : '-'
                );
                sortColumnDatatable(temp_excel, invoiceIndex, 2);
            }
            if (user?.data.company_id === param.companyId.enterpriseService) {
                temp_excel.push(
                    row.custom_fields[param.keyCustomfield.serial.enterpriseService]
                        ? (row.custom_fields[param.keyCustomfield.serial.enterpriseService] as string[]).join('')
                        : '-'
                );
                sortColumnDatatable(temp_excel, invoiceIndex, 2);
            }

            if (user?.data.company_name === 'Heiszco') {
                sortColumnDatatable(temp_excel, 10, 4);
                sortColumnDatatable(temp_excel, subSectorIndex, 5);
            }

            if (user!.data.company_id === '7763fccc-af52-43e1-8907-4678ab2423d1' && comapnyCodeIndex !== -1) {
                sortColumnDatatable(temp_excel, comapnyCodeIndex, 1);
            }

            exportData.push(temp_excel);
        });

        addDataRows(worksheet, exportData, source, param, header, columns!, comapnyCodeIndex, user);
        setColumnWidths(worksheet, source, arrayCustomFieldPipelines, exportData);
        styleColumns(worksheet, source, param, columns!, 1, user);
    }
    exportWorkbook(workbook, source, `${nameFile}.xlsx`);
};

const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Looyal';
    workbook.lastModifiedBy = 'Looyal';
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
};

const createWorksheet = (workbook: ExcelJS.Workbook, source: string, sheetName: string = 'Sheet1') => {
    let worksheet;
    if (source === 'Daily Attendance')
        worksheet = workbook.addWorksheet(sheetName, {
            views: [{ state: 'frozen', xSplit: 2, topLeftCell: 'H1' }],
        });
    else worksheet = workbook.addWorksheet(sheetName);
    return worksheet;
};

const addDataRows = (
    worksheet: ExcelJS.Worksheet,
    data: any[],
    source: string,
    param: any,
    header: string[],
    columns: TableColumn<Pipeline>[],
    comapnyCodeIndex: number,
    user?: UserModel
) => {
    if (source === 'Pipeline') {
        var i = 1;
        var totalNominal = 0;
        let totalNominalBeforeVAT = 0;

        // Set Data Body
        data.forEach((rowData, index) => {



            // buat maspion aja sementara, nilai ini ditambahkan dengan kondisi nama company maspion, bisa dicek di // Set Data Footer
            totalNominalBeforeVAT += (user?.data.company_name === "Heiszco")
                ? rowData[12]
                : (user?.data.company_id === param.companyId.enterpriseSales || user?.data.company_id === param.companyId.enterpriseService) ? rowData[10] : rowData[9];

            totalNominal += (user?.data.company_name === "Heiszco") ? rowData[11] : (user?.data.company_id === param.companyId.enterpriseSales || user?.data.company_id === param.companyId.enterpriseService) ? rowData[9] : (user?.data.company_id === "7763fccc-af52-43e1-8907-4678ab2423d1" && comapnyCodeIndex !== -1) ? rowData[9] : rowData[8];
            var row = worksheet.addRow(rowData);
            i++;



            row.eachCell((cell, colIndex) => {
                cell.alignment = {
                    vertical: 'top',
                    wrapText: true,
                    shrinkToFit: true,
                };

                // Apply specific style for numeric columns
                if (user?.data.company_name === "Heiszco") {
                    if ([7, 12, 13, 14].includes(colIndex)) {
                        cell.alignment.horizontal = 'right';
                        cell.numFmt = '#,##0;[Red]-#,##0';
                    }
                } else if (user?.data.company_id === param.companyId.enterpriseSales || user?.data.company_id === param.companyId.enterpriseService) {
                    if ([5, 10, 11, 12].includes(colIndex)) {
                        cell.alignment.horizontal = 'right';
                        cell.numFmt = '#,##0;[Red]-#,##0';
                    }
                } else if (user?.data.company_id === "7763fccc-af52-43e1-8907-4678ab2423d1" && comapnyCodeIndex !== -1) {
                    if ([5, 10, 11, 12].includes(colIndex)) {
                        cell.alignment.horizontal = 'right';
                        cell.numFmt = '#,##0;[Red]-#,##0';
                    }
                } else {
                    if ([4, 9, 10, 11].includes(colIndex)) {
                        cell.alignment.horizontal = 'right';
                        cell.numFmt = '#,##0;[Red]-#,##0';
                    }
                }
            });
        });

        // Set Data Footer
        var endRow = worksheet.actualRowCount + 1;
        if (user?.data.company_name === "Heiszco") {
            worksheet.getCell(`L${endRow}`).value = totalNominal;
            worksheet.getCell(`L${endRow}`).numFmt = '#,##0;[Red]-#,##0';
        } else if (user?.data.company_id === param.companyId.enterpriseSales || user?.data.company_id === param.companyId.enterpriseService) {
            worksheet.getCell(`J${endRow}`).value = totalNominal;
            worksheet.getCell(`J${endRow}`).numFmt = '#,##0;[Red]-#,##0';
        } else if (user?.data.company_id === "7763fccc-af52-43e1-8907-4678ab2423d1" && comapnyCodeIndex !== -1) {
            worksheet.getCell(`J${endRow}`).value = totalNominal;
            worksheet.getCell(`J${endRow}`).numFmt = '#,##0;[Red]-#,##0';
        } else {
            worksheet.getCell(`I${endRow}`).value = totalNominal;
            worksheet.getCell(`I${endRow}`).numFmt = '#,##0;[Red]-#,##0';
            if (user?.data.company_name === "Maspion Square") {
                worksheet.getCell(`J${endRow}`).value = totalNominalBeforeVAT;
                worksheet.getCell(`J${endRow}`).numFmt = '#,##0;[Red]-#,##0';
            }
        }

        // Dynamic column
        const columnIndicesToRemove: number[] = [];
        if (user?.data.company_id === "d2f2a3da-3829-4872-8891-cae921d555b5") columnIndicesToRemove.push(3)
        if (user?.data.company_id === "7763fccc-af52-43e1-8907-4678ab2423d1") columnIndicesToRemove.push(16)

        // Cari semua indeks kolom yang perlu dihapus
        header.forEach((item, headerIndex) => {
            columns.forEach((column) => {
                if (column.omit && item === column.name) {
                    columnIndicesToRemove.push(headerIndex + 1); // +1 untuk 1-based index
                }
            });
        });

        // Hapus kolom dari worksheet berdasarkan indeks yang ditemukan
        columnIndicesToRemove.sort((a, b) => b - a).forEach((index) => {
            worksheet.spliceColumns(index, 1);
        });
    } else {
        // Set Data Body
        data.forEach((rowData, index) => {
            worksheet.addRow(rowData);
        });
    }
};

const setColumnWidths = (worksheet: ExcelJS.Worksheet, source: string, fields: any[], data: any) => {
    if (source === 'Pipeline') {
        const maxColumnWidths: number[] = [];
        data.forEach((row: any) => {
            row.forEach((cell: any, columnIndex: number) => {
                const cellLength = String(cell).length;
                if (!maxColumnWidths[columnIndex] || cellLength > maxColumnWidths[columnIndex]) {
                    maxColumnWidths[columnIndex] = cellLength;
                }
            });
        });

        // Atur lebar kolom berdasarkan panjang teks terpanjang
        // maxColumnWidths.forEach((width, columnIndex) => {
        //     worksheet.getColumn(columnIndex + 1).width = width < 20 ? 20 : width + 2 > 40 ? 40 : width + 2; // Tambahkan sedikit padding
        // });

        const colWidths = [20, 30, 30, 20, 20, 30, 30, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, ...Object.keys(fields).map((field) => 20)];
        worksheet.columns = colWidths.map((width) => ({ width }));
    }
};

const styleColumns = (
    worksheet: ExcelJS.Worksheet,
    source: string,
    param: any,
    columns: TableColumn<Pipeline>[],
    indexRow: number,
    user?: UserModel
) => {
    worksheet.getRow(indexRow).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheet.getRow(indexRow).eachCell(function (cell) {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'F6991D',
            },
        };
    });
    worksheet.getRow(indexRow).font = {
        name: 'Arial Black',
        size: 10,
        bold: true,
    };

    var endRow = worksheet.actualRowCount;
    if (source === 'Pipeline') {
        var index = (user?.data.company_name === "Heiszco") ? 12 : (user?.data.company_id === param.companyId.enterpriseSales || user?.data.company_id === param.companyId.enterpriseService) ? 10 : (user?.data.company_id === '7763fccc-af52-43e1-8907-4678ab2423d1') ? 10 : 9
        if (!columns[index].omit) {
            worksheet.getRow(endRow).font = {
                name: 'Arial Black',
                size: 10,
                bold: true,
            };
        }
    }
};

const exportWorkbook = async (workbook: ExcelJS.Workbook, source: string, fileName: string) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    });
};
