import React from 'react'
interface DetailMoreInfoProps {
  from: string
  to: string | string[]
  subject: string
  cc?: string | string[]
  bcc?: string
  date: string
}

const DetailMoreInfo: React.FC<DetailMoreInfoProps> = ({ from, to, subject, cc, date, bcc }) => {
  const formattedTo = Array.isArray(to) ? to.join(', ') : to
  const formattedCc = Array.isArray(cc) ? cc.join(', ') : cc
  const formattedBcc = Array.isArray(bcc) ? bcc.join(', ') : bcc

  return (
    <ul className='dropdown-menu px-3' style={{ minWidth: '300px', maxWidth: '300px !important', overflow: 'hidden' }}>
      <li>
        <table className='table mb-0'>
          <tbody>
            <tr>
              <td className='w-75px text-muted'>Dari:</td>
              <td>{from}</td>
            </tr>
            {cc && Object.keys(cc).length > 0 ? (
              typeof cc === 'string' ? (
                <tr>
                  <td className='text-muted'>Cc:</td>
                  <td>{cc}</td>
                </tr>
              ) : (
                <tr>
                  <td className='text-muted'>Cc:</td>
                  <td>{formattedCc}</td>
                </tr>
              )
            ) : <></>}
            {bcc && Object.keys(bcc).length > 0 ? (
              typeof bcc === 'string' ? (
                <tr>
                  <td className='text-muted'>Bcc:</td>
                  <td>{bcc}</td>
                </tr>
              ) : (
                <tr>
                  <td className='text-muted'>Bcc:</td>
                  <td>{formattedBcc}</td>
                </tr>
              )
            ) : <></>}
            <tr>
              <td className='text-muted'>Tanggal:</td>
              <td>{date}</td>
            </tr>
            <tr>
              <td className='text-muted'>Subjek:</td>
              <td style={{ wordBreak: 'break-word' }}>{subject}</td>
            </tr>
            <tr>
              {/* Jikalau dia ngreplay boleh pakai Reply-to, dan tidak cukup to */}
              <td className='text-muted'>Kepada:</td>
              <td>{formattedTo}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ul>
  )
}

export default DetailMoreInfo

