import { useState, useEffect } from 'react';
import { checkWhatsapp } from '../api/Whatsapp';

type CheckWaStatus = 'loading' | 'true' | 'false' | '';

interface UseCheckWaProps {
    phoneNumber: string | null;
}

const useCheckWa = ({ phoneNumber }: UseCheckWaProps) => {
    const [checkWa, setCheckWa] = useState<CheckWaStatus>('');

    const checkWaValid = async (phone: string) => {
        if (!phone) return;

        setCheckWa('loading');
        try {
            const response = await checkWhatsapp(phone.replace('+', ''));
            setCheckWa(response.data.result.exists ? 'true' : 'false');
        } catch (error) {
            console.log(error);
            setCheckWa('false');
        }
    };

    useEffect(() => {
        if (!phoneNumber || phoneNumber === '') {
            setCheckWa('');
        }
    }, [phoneNumber]);

    return {
        checkWa,
        setCheckWa,
        checkWaValid,
    };
};

export default useCheckWa;