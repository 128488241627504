import { useFormik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { SelectOption, SelectOptionMerchantLink } from "../../interfaces/SelectOption";
import { useSettingsStore } from "../../stores/SettingsStore";
import useAccessToken from "../../hooks/AccessToken";
import { CustomField, DetailSettingMerchantLink, OptionSettingMerchantLink, SettingMerchantLink, SettingMultiMerchants } from "../../interfaces/Settings";
import { getListMerchantLinkSetting, getMerchantLinkSetting, saveSetting } from "../../api/SettingCRUD";
import { useIntl } from 'react-intl';
import clsx from "clsx";
import * as Yup from 'yup';
import moment from "moment";
import { KTSVG } from "../../_metronic/helpers";
import { AddTemplateSettingMerchant } from "./Merchant/AddTemplateSettingMerchant copy";
import { DeleteTemplateSettingMerchant } from "./Merchant/DeleteTemplateSettingMerchant";
import { useMerchantStore } from "../../stores/MerchantStore";
import { CustomFieldMultiMerchant } from "./Merchant/CustomFieldMultiMerchant";

interface Props {
    optionType: SelectOption[]
    optionsResetSerial: SelectOption[]
    dataPipeline : SelectOption[]
    dataMerchant: SelectOption[]
}

const CustomFieldMerchant: FC<Props> = ({ optionType, optionsResetSerial, dataMerchant, dataPipeline }) => {
    const { settings, setSettings } = useSettingsStore()
    const token = useAccessToken()
    const { multiMerchants } = useMerchantStore()

    const [optionMerchantLink, setOptionMerchantLink] = useState<SelectOptionMerchantLink[]>([])
    const [fieldMerchant, setFieldMerchant] = useState<CustomField>({})
    const [selectTemplate, setSelectTemplate] = useState(multiMerchants ? multiMerchants[0] : {} as any)
    const [loadingMerchantPreferences, setLoadingMerchantPreferences] = useState(false)
    const [resultMultiMerchants, setResultMultiMerchants] = useState<SettingMultiMerchants[]>([])
    const intl = useIntl()
    const linkref = useRef(null)

    const formikPreferences = useFormik({
        initialValues: {
            key: '',
            value: settings.merchant_title ? settings.merchant_title : intl.formatMessage({ id: "MENU.MERCHANTS" }),
        },
        validationSchema: Yup.object().shape({
            value: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (values.value === "") return false

            setLoadingMerchantPreferences(true)

            try {
                const response = await saveSetting("merchant_title", values.value, token)
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    Object.values(fieldMerchant).forEach(field => {
                        field.new = false;
                    });
                    setSettings({
                        ...settings,
                        merchant_title: values.value,
                    })
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.respons.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setLoadingMerchantPreferences(false)
            }
        }
    })

    const handleGetListMerchantLink = async (id: string) => {
        try {
            const res = await getListMerchantLinkSetting(id, token)
            if (res.data.success) {
                const option = res.data.data.flatMap((item: OptionSettingMerchantLink) => {
                    return Object.keys(item.dataCustomField || {}).map((key: any) => ({
                        label: item.nameTemplate + ' / ' + item.dataCustomField[key].name,
                        value: key,
                        nameTemplate: item.nameTemplate,
                        idTemplate: item.idTemplate,
                        nameCustomField: item.dataCustomField[key].name
                    }));
                });

                setOptionMerchantLink(option)
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                heightAuto: false
            })
        }
    }

    useEffect(() => {
        if (selectTemplate) {
            handleGetListMerchantLink(selectTemplate.id)
        }
    }, [])

    useEffect(() => {
        const updateMultiMerchant = async () => {
            try {
                const updatedMerchants = await Promise.all(
                    multiMerchants.map(async (item: SettingMultiMerchants) => {
                        try {
                            // Panggil API secara paralel
                            const resMerchantLink = await getMerchantLinkSetting(item.id, token);

                            // Periksa apakah respons berhasil
                            if (resMerchantLink.data.success) {
                                const merchantLinkData = resMerchantLink.data.data.merchantTemplateDestinationData;

                                // Kembalikan data yang telah diperbarui
                                return {
                                    ...item,
                                    idMerchantLink: resMerchantLink.data.data.id,
                                    merchantLink: merchantLinkData.map((data: DetailSettingMerchantLink) => ({
                                        label: data.templateName + ' / ' + data.nameCustomField,
                                        value: data.idCustomField,
                                        nameTemplate: data.templateName,
                                        idTemplate: data.templateId,
                                        nameCustomField: data.nameCustomField
                                    })),
                                };
                            }
                        } catch (error) {
                            // Log error untuk item spesifik
                            console.error(`Error updating merchant ${item.id}:`, error);
                        }

                        // Jika terjadi error, kembalikan item asli
                        return item;
                    })
                );

                // Tetapkan hasil langsung ke state
                setResultMultiMerchants(updatedMerchants);
            } catch (error) {
                console.error("Error updating multiMerchants:", error);
            }
        };


        updateMultiMerchant();
    }, [multiMerchants]);

    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">{intl.formatMessage({ id: "FORM.LABEL.PREFERENCES" }, { title: settings.merchant_title })}</h3>
                    </div>
                </div>
                <div id="kt_account_settings_email_preferences">
                    <form onSubmit={formikPreferences.handleSubmit} noValidate>
                        <div className="card-body border-top px-9 py-9">
                            <div className="row">
                                <span className="col-md-7 col-lg-9 col-xl-9 d-flex flex-column align-items-start">
                                    <span className="fw-bolder fs-5 mb-0">{`${intl.formatMessage({ id: "FORM.LABEL.NAME" })} `}</span>
                                    <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_SETTING_NAME" })}</span>
                                </span>
                                <div className="col-md-5 col-lg-3 col-xl-3">
                                    <input
                                        {...formikPreferences.getFieldProps('value')}
                                        className={clsx(
                                            'form-control',
                                            { 'is-invalid': formikPreferences.touched.value && formikPreferences.errors.value },
                                        )}
                                        type='text'
                                        name='value'
                                    />
                                    {formikPreferences.touched.value && formikPreferences.errors.value && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formikPreferences.errors.value}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="submit" className={`btn btn-primary ${loadingMerchantPreferences ? "disabled" : ""}`} data-kt-indicator={loadingMerchantPreferences ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div>
                <div className="d-flex align-items-center gap-3">
                    <div className="card-toolbar custom-tabs mb-3">
                        <ul className="nav">
                            {
                                multiMerchants?.map((data: any, index: number) => (
                                    <li className="nav-item" style={{ position: "relative" }} key={data.id}>
                                        <a
                                            ref={index === 0 ? linkref : null}
                                            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light ps-4 fw-bolder ${index === 0 ? 'active pe-4' : 'pe-8'}`}
                                            data-bs-toggle="tab"
                                            href={`#tab_${data.id}`}
                                            onClick={() => handleGetListMerchantLink(data.id)}
                                        >
                                            <span>{data.name}</span>
                                        </a>

                                        {
                                            index !== 0 &&
                                            <span
                                                className="text-hover-primary cursor-pointer"
                                                onClick={() => setSelectTemplate(data)}
                                                style={{ position: "absolute", right: "5px", top: "7px" }}
                                                data-bs-toggle="modal"
                                                data-bs-target="#delete-template-setting-merchant-modal"
                                            >
                                                <KTSVG path="media/icons/duotune/general/gen040.svg" className="svg-icon-5 m-0" />
                                            </span>
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <span className='menu-icon cursor-pointer text-hover-primary mb-3' data-bs-toggle="modal" data-bs-target="#add-template-setting-merchant-modal">
                        <KTSVG path="media/icons/duotune/general/gen041.svg" className='svg-icon-1' />
                    </span>
                </div>
            </div>


            <div className="tab-content" id="myTabContent">
                {
                    resultMultiMerchants.map((data: SettingMultiMerchants, index: number) => (
                        <div className={`tab-pane fade ${index === 0 && 'show active'}`} id={`tab_${data.id}`} role="tabpanel">
                            <CustomFieldMultiMerchant dataPipeline={dataPipeline} dataMerchant={dataMerchant} optionMerchantLink={optionMerchantLink} key={data.id} data={data} optionType={optionType} optionsResetSerial={optionsResetSerial} />
                        </div>
                    ))
                }
            </div>

            <AddTemplateSettingMerchant />
            <DeleteTemplateSettingMerchant selectTemplate={selectTemplate} linkRef={linkref} />
        </>
    )
}

export { CustomFieldMerchant }