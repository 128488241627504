import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET = `${API_URL}kawasaki/`;

export const getUserPerformance = async (url: string, dateStartFrom: string, dateStartTo: string, token: string) => {
    const params = url === 'meeting-activity-categories'
        ? {
            dateMeetFrom: dateStartFrom,
            dateMeetTo: dateStartTo
        }
        : {
            dateStartedFrom: dateStartFrom,
            dateStartedTo: dateStartTo
        };
        
    return axios.get(`${GET}${url}-performance`, {
        params,
        headers: {
            'X-Auth-token': token
        }
    });
};
