import axios from 'axios';

const RESOURCE_URL = `https://resource.looyal.id/crm-wooblazz/`;
const NEW_RESOURCE_URL = `https://resource.looyal.id/attachments/wooblazz/crm/`;

const CHAT = `${RESOURCE_URL}store-whatsapp-chat-attachments`;

const BANNER = `${NEW_RESOURCE_URL}banner/company-information`;
const MEETING = `${NEW_RESOURCE_URL}meeting`;
const MEETING_CUSTOM_FIELD = `${NEW_RESOURCE_URL}meeting/custom-field`;
const LEAD = `${NEW_RESOURCE_URL}lead`;
const LEAD_CUSTOM_FIELD = `${NEW_RESOURCE_URL}lead/custom-field`;
const REPORT_LOG = `${NEW_RESOURCE_URL}report/log`;
const PROFILE = `${NEW_RESOURCE_URL}user/profile-picture`;
const MERCHANT_CUSTOM_FIELD = `${NEW_RESOURCE_URL}merchant/custom-field`;
const CLOCK_IN = `${NEW_RESOURCE_URL}user-attendance/clock-in`;
const CLOCK_OUT = `${NEW_RESOURCE_URL}user-attendance/clock-out`;
const REIMBURSE = `${NEW_RESOURCE_URL}reimbursement`;
const PAID_OFF_REIMBURSE = `${NEW_RESOURCE_URL}reimbursement/paid-off`;
const EMAIL = `${NEW_RESOURCE_URL}email/avatar`;
const EMAIL_ATTACHMENT = `${NEW_RESOURCE_URL}email/attachment`;
const PAYMENT_TERMIN = `${NEW_RESOURCE_URL}termin/paid`
const USERS_CUSTOM_FIELD = `${NEW_RESOURCE_URL}users/custom-field`
const CUSTOM_FIELD_ATTACHMENT_TERMIN = `${NEW_RESOURCE_URL}termin/custom-field`
const QUICK_REPLY = `${NEW_RESOURCE_URL}chat/quick-reply`


export const insertBannerAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(BANNER, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertMeetingAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(MEETING, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertMeetingFieldAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(MEETING_CUSTOM_FIELD, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertMeetingFieldMultiAttachment = async (files: File[]) => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append('files', file);
  });

  return axios.post(MEETING_CUSTOM_FIELD, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertLeadAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(LEAD, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertLeadFieldAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(LEAD_CUSTOM_FIELD, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertUserFieldAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(USERS_CUSTOM_FIELD, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertUserFieldMultiAttachment = async (files: File[]) => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append('files', file);
  });
  
  return axios.post(USERS_CUSTOM_FIELD, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertLeadFieldMultiAttachment = async (files: File[]) => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append('files', file);
  });

  return axios.post(LEAD_CUSTOM_FIELD, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertReportLogdAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(REPORT_LOG, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertChatAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(CHAT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertMerchantFieldtAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(MERCHANT_CUSTOM_FIELD, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertMerchantFieldMultitAttachment = async (files: File[]) => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append('files', file);
  });

  return axios.post(MERCHANT_CUSTOM_FIELD, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertProfilePicture = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(PROFILE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertClockInPicture = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(CLOCK_IN, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertClockOutPicture = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(CLOCK_OUT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertReimburseAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(REIMBURSE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertPaidOffReimburseAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(PAID_OFF_REIMBURSE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertEmailAttachment = async (file: string | File) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(EMAIL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertEmailAttachmentMulti = async (files: FormData) => {
  return axios.post(EMAIL_ATTACHMENT, files, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertPaymentTerminAttachment = async (file: File | string) => {
  try {
    const formData = new FormData()

    formData.append('files', file)
    const response = await axios.post(PAYMENT_TERMIN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const insertPaymentTerminMultiAttachmentCustomField = async (files: File[]) => {
  try {
    const formData = new FormData()

    files.forEach(file => {
      formData.append('files', file)
    })
    const response = await axios.post(CUSTOM_FIELD_ATTACHMENT_TERMIN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const insertPaymentTerminAttachmentCustomField = async (file: File | string) => {
  try {
    const formData = new FormData()

    formData.append('files', file)
    const response = await axios.post(CUSTOM_FIELD_ATTACHMENT_TERMIN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const insertQuickReplyAttachment = async (files: File[]) => {
  try {
    const formData = new FormData()

    files.forEach(file => {
      formData.append('files', file)
    })
    const response = await axios.post(QUICK_REPLY, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}
