import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import useAccessToken from "../../hooks/AccessToken";
import { Meeting, MeetingAttachment } from "../../interfaces/Meeting";
import { updateMeeting, updateVisitMeeting } from "../../api/MeetingsCRUD";
import moment from "moment";
import useUser from "../../hooks/User";
import { SelectOption } from "../../interfaces/SelectOption";
import Select from "react-select"
import { nanoid } from "@reduxjs/toolkit";
import { CustomField, CustomFieldSerialProps } from "../../interfaces/Settings";
import { useSettingsStore } from "../../stores/SettingsStore";
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps, isValidUrl } from "../../functions/general";
import { useIntl } from "react-intl";
import { insertMeetingAttachment, insertMeetingFieldAttachment, insertMeetingFieldMultiAttachment } from "../../api/ResourceAPI";
import InputAttachment from "../Input/InputAttachment";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { DatePicker } from "rsuite";
import { generateSerialApi } from "../../api/Generals";
import { addDays, subDays } from "date-fns";
import useTodayRange from "../../hooks/TodayRange";
import { useCustomeStore } from "../../stores/CustomeStore";
import InputPhone from "../Input/InputPhone";
import useSWR from "swr";
import axios from "axios";

interface Props {
    meeting: Meeting
}

const UpdateStatusMeeting: FC<Props> = ({ meeting }) => {
    const user = useUser()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { companyId } = useCustomeStore()
    const todayRange = useTodayRange();
    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()
    const intl = useIntl()
    const refArray = useRef<(HTMLInputElement | null)[]>([]);
    const { settings } = useSettingsStore()
    const [customFields, setCustomFields] = useState<CustomField>({})
    const [dataCustomFieldSerial, setDataCustomFieldSerial] = useState<CustomFieldSerialProps>({});
    const [statusData, setStatusData] = useState<SelectOption[]>([
        {
            value: "COMPLETED",
            label: intl.formatMessage({ id: 'MEETING.STATUS.CONFIRMED' })
        },
        {
            value: "CANCELLED",
            label: intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' })
        },
    ]);

    const formSchema = Object.keys(customFields).reduce((obj: any, item: string) => {
        const field = customFields[item];
        const isRequiredMessage = intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: field.name });
        const isValidUrlMessage = intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" });

        if (field.type === 'multiple' || field.type === 'images' || field.type === 'files') {
            obj[item] = field.required
                ? Yup.array().min(1, isRequiredMessage).required(isRequiredMessage).nullable()
                : Yup.array().notRequired().nullable();
        } else if (field.type === 'date') {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        } else if (field.type === 'link') {
            obj[item] = field.required
                ? Yup.string().nullable().required(isRequiredMessage).test('is-required', isValidUrlMessage, function (value) {
                    return !value || isValidUrl(value);
                })
                : Yup.string().nullable().test('is-required', isValidUrlMessage, function (value) {
                    return !value || isValidUrl(value);
                });
        } else if (field.type === 'serial') {
            obj[item] = Yup.array().notRequired().nullable();
        } else {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        }

        return obj;
    }, {});

    const validationSchema = Yup.object().shape({
        attachment: Yup.mixed().test({
            name: 'attachment',
            exclusive: true,
            message: intl.formatMessage(
                { id: "FORM.VALIDATION.FIELD_REQUIRED" },
                { title: intl.formatMessage({ id: "FORM.LABEL.PHOTO" }, { title: settings.meeting_title }) }
            ),
            test: function (value) {
                const isMeetingVisitWithDisableCapture = meeting.type === 'VISIT' && settings.meeting_visit_disable_capture_camera;
                const isRequired = 
                    this.parent && 
                    (this.parent.status === "COMPLETED" || this.parent.status === "ONGOING") &&
                    !isMeetingVisitWithDisableCapture;
    
                if (this.parent && this.parent.formik && this.parent.formik.setTouched) {
                    this.parent.formik.setTouched({
                        ...this.parent.formik.touched,
                        attachment: isRequired,
                    });
                }

                return isRequired ? Boolean(value) : true;
            }
        }).nullable(),
        labels: Yup.array().notRequired(),
        ...formSchema
    });
    
    const initialValues = {
        status: meeting.status === intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) ? "" : meeting.status === intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) ? "COMPLETED" : meeting.status === intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) ? "ONGOING" : "CANCELLED",
        attachment: (meeting.attachments !== null && meeting.attachments.length > 0) ? meeting.attachments[0].url : "",
        note: meeting.note ? meeting.note : "",
        labels: meeting.labels.map((label) => { return label.id }),
        ...Object.fromEntries(Object.keys(customFields).map((key) => [key,
            meeting.custom_fields && meeting.custom_fields[key] ?
                customFields[key].type === 'date' ?
                    moment(meeting.custom_fields[key], 'YYYY-MM-DD', true).isValid() ?
                        meeting.custom_fields[key]
                        : null
                    : meeting.custom_fields[key]
                : null]
        )),
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (values.status === 'COMPLETED') {
                setModalBtnLoading(true)
                // Assuming values is of type Record<string, string> and customFields is of type fieldCustom
                const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                    (obj, item) => ({
                        ...obj,
                        [item]: (values as Record<string, string | string[] | null>)[item],
                    }),
                    {}
                );

                // Use Promise.all to await all async operations
                let countResourceError = 0
                await Promise.all(
                    Object.keys(customFields).map(async (key) => {
                        if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                            try {
                                if (values[key as keyof typeof values] && typeof values[key as keyof typeof values] === 'object') {
                                    const resourceResponse = await insertMeetingFieldAttachment(values[key as keyof typeof values] as string | File);
                                    if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                    else countResourceError++
                                }
                            } catch (error) {
                                countResourceError++
                            }
                        } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                            try {
                                if (values[key as keyof typeof values]) {
                                    let dataMultiAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item !== 'string')
                                    let dataUrlAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item === 'string')

                                    const resourceResponse = await insertMeetingFieldMultiAttachment(dataMultiAttachment);
                                    if (resourceResponse.data.success) (customFieldsValues[key] as any) = resourceResponse.data.data.length > 0 ? [...dataUrlAttachment, ...resourceResponse.data.data] : dataUrlAttachment.length > 0 ? dataUrlAttachment : null;
                                    else countResourceError++
                                }
                            } catch (error) {
                                countResourceError++
                            }
                        } else if (customFields[key].type === 'multiple') {
                            var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
                            customFieldsValues[key] = multipleValues as string
                        } else if (customFields[key].type === 'serial') {
                            try {
                                if (dataCustomFieldSerial[key].value.length === 0) {
                                    const serialResponse = await generateSerialApi(key, "Meeting", token);
                                    customFieldsValues[key] = serialResponse.data.success ? serialResponse.data.data : null;
                                } else {
                                    customFieldsValues[key] = dataCustomFieldSerial[key].value
                                }
                            } catch (error) {
                                countResourceError++
                            }
                        }
                    })
                );

                if (countResourceError > 0) {
                    Swal.fire({
                        icon: "error",
                        text: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_FAILED" })}.`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })
                    setModalBtnLoading(false);
                } else {
                    try {
                        let attachments: MeetingAttachment[] = []
                        if (values.attachment && typeof values.attachment !== 'string') {
                            const resourceResponse = await insertMeetingAttachment(values.attachment)
                            if (resourceResponse.data.data.length > 0) {
                                const parts = resourceResponse.data.data[0].split('/');
                                const fileName = parts[parts.length - 1];
                                attachments = [
                                    {
                                        name: fileName,
                                        url: resourceResponse.data.data[0]
                                    }
                                ]
                            }
                        } else if (values.attachment && typeof values.attachment === 'string') {
                            attachments = [
                                {
                                    name: values.attachment,
                                    url: values.attachment
                                }
                            ]
                        }

                        var response: any = {}
                        if (meeting.type === "VISIT") {
                            const meetingLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label } }) : []

                            response = await updateVisitMeeting({
                                idMeeting: meeting.id,
                                note: values.note,
                                attachmentLink: attachments.length > 0 ? attachments[0].url : null,
                                token: token,
                                customFields: customFieldsValues,
                                meetingLabels
                            })
                        } else {
                            const meetingLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label } }) : []

                            response = await updateMeeting({
                                meetingLabels,
                                id: meeting.id,
                                start: {
                                    date: meeting.allDay ? moment(`${meeting.date_meet}`).format("YYYY-MM-DD") : null,
                                    dateTime: !meeting.allDay ? moment(`${meeting.date_meet}`).toISOString(true) : null
                                },
                                end: {
                                    date: meeting.allDay ? moment(`${meeting.date_meet_end}`).add(meeting.allDay ? 1 : 0, 'days').format("YYYY-MM-DD") : null,
                                    dateTime: !meeting.allDay ? moment(`${meeting.date_meet_end}`).add(meeting.allDay ? 1 : 0, 'days').toISOString(true) : null,
                                },
                                ownerId: meeting.owner_id,
                                attachments: attachments,
                                status: values.status,
                                reminderMinutes: meeting.reminderMinutes,
                                userAttendees: meeting.attendees,
                                token: token,
                                notification: false,
                                note: values.note,
                                latitude: meeting.latitude,
                                longitude: meeting.longitude,
                                location: meeting.location,
                                customFields: customFieldsValues,
                            })
                        }

                        if (response.data.success) {
                            Swal.fire({
                                icon: 'success',
                                heightAuto: false,
                                title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" })}.`,
                                timer: 2000,
                                timerProgressBar: true
                            })
                            // const meetingLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label)=>{return{id:label}}):[]

                            setTablesData('meetings', (tablesData.meetings.map(item => {
                                if (item.id === meeting.id) {
                                    return {
                                        ...item,
                                        status: response.data.data.status === "PLANNED" ? intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) : response.data.data.status === "COMPLETED" ? intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) : response.data.data.status === "ONGOING" ? intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) : intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' }),
                                        attachments: response.data.data.attachments,
                                        note: response.data.data.note,
                                        date_meet: moment(response.data.data.date_meet).format("YYYY-MM-DD HH:mm:ss"),
                                        date_meet_end: response.data.data.allDay ? moment(response.data.data.date_meet_end).subtract(1, 'days').local().format("YYYY-MM-DD HH:mm:ss") : moment(response.data.data.date_meet_end).format("YYYY-MM-DD HH:mm:ss"),
                                        custom_fields: response.data.data.custom_fields,
                                        logs: response.data.data.logs,
                                        labels: response.data.data.labels,
                                    }
                                } else {
                                    return item
                                }
                            })));
                            closeModalRef.current?.click();
                        }
                    } catch (error: any) {
                        Swal.fire({
                            icon: 'error',
                            title: error.response.data.message,
                            confirmButtonText: 'Ok',
                            heightAuto: false,
                        })
                    } finally {
                        setModalBtnLoading(false)
                    }
                }
            } else if (values.status === 'ONGOING') {
                setModalBtnLoading(true)

                // Assuming values is of type Record<string, string> and customFields is of type fieldCustom
                const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                    (obj, item) => ({
                        ...obj,
                        [item]: (values as Record<string, string | string[] | null>)[item],
                    }),
                    {}
                );

                // Use Promise.all to await all async operations
                let countResourceError = 0
                await Promise.all(
                    Object.keys(customFields).map(async (key) => {
                        if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                            try {
                                if (values[key as keyof typeof values] && typeof values[key as keyof typeof values] === 'object') {
                                    const resourceResponse = await insertMeetingFieldAttachment(values[key as keyof typeof values] as string | File);
                                    if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                    else countResourceError++
                                }
                            } catch (error) {
                                countResourceError++
                            }
                        } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                            try {
                                if (values[key as keyof typeof values]) {
                                    let dataMultiAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item !== 'string')
                                    let dataUrlAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item === 'string')

                                    const resourceResponse = await insertMeetingFieldMultiAttachment(dataMultiAttachment);
                                    if (resourceResponse.data.success) (customFieldsValues[key] as any) = resourceResponse.data.data.length > 0 ? [...dataUrlAttachment, ...resourceResponse.data.data] : dataUrlAttachment.length > 0 ? dataUrlAttachment : null;
                                    else countResourceError++
                                }
                            } catch (error) {
                                countResourceError++
                            }
                        } else if (customFields[key].type === 'multiple') {
                            var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
                            customFieldsValues[key] = multipleValues as string
                        } else if (customFields[key].type === 'serial') {
                            try {
                                if (dataCustomFieldSerial[key].value.length === 0) {
                                    const serialResponse = await generateSerialApi(key, "Meeting", token);
                                    customFieldsValues[key] = serialResponse.data.success ? serialResponse.data.data : null;
                                } else {
                                    customFieldsValues[key] = dataCustomFieldSerial[key].value
                                }
                            } catch (error) {
                                countResourceError++
                            }
                        }
                    })
                );

                if (countResourceError > 0) {
                    Swal.fire({
                        icon: "error",
                        text: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_FAILED" })}.`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })
                    setModalBtnLoading(false);
                } else {
                    try {
                        let attachments: MeetingAttachment[] = []
                        if (values.attachment && typeof values.attachment !== 'string') {
                            const resourceResponse = await insertMeetingAttachment(values.attachment)
                            if (resourceResponse.data.data.length > 0) {
                                const parts = resourceResponse.data.data[0].split('/');
                                const fileName = parts[parts.length - 1];
                                attachments = [
                                    {
                                        name: fileName,
                                        url: resourceResponse.data.data[0]
                                    }
                                ]
                            }
                        } else if (values.attachment && typeof values.attachment === 'string') {
                            attachments = [
                                {
                                    name: values.attachment,
                                    url: values.attachment
                                }
                            ]
                        }
                        const meetingLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label } }) : []
                        const response = await updateVisitMeeting({
                            idMeeting: meeting.id,
                            note: values.note,
                            attachmentLink: attachments[0].url,
                            token: token,
                            customFields: customFieldsValues,
                            meetingLabels
                        })
                        if (response.data.success) {
                            Swal.fire({
                                icon: 'success',
                                heightAuto: false,
                                title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" })}.`,
                                timer: 2000,
                                timerProgressBar: true
                            })

                            setTablesData('meetings', (tablesData.meetings.map(item => {
                                if (item.id === meeting.id) {
                                    return {
                                        ...item,
                                        status: response.data.data.status === "PLANNED" ? intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) : response.data.data.status === "COMPLETED" ? intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) : response.data.data.status === "ONGOING" ? intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) : intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' }),
                                        attachments: response.data.data.attachments,
                                        note: response.data.data.note,
                                        date_meet: moment(response.data.data.date_meet).format("YYYY-MM-DD HH:mm:ss"),
                                        date_meet_end: response.data.data.date_meet_end === null ? null : response.data.data.allDay ? moment(response.data.data.date_meet_end).subtract(1, 'days').local().format("YYYY-MM-DD HH:mm:ss") : moment(response.data.data.date_meet_end).format("YYYY-MM-DD HH:mm:ss"),
                                        custom_fields: response.data.data.custom_fields,
                                        labels: response.data.data.labels,

                                    }
                                } else {
                                    return item
                                }
                            })));
                            closeModalRef.current?.click();
                        }
                    } catch (error: any) {
                        Swal.fire({
                            icon: 'error',
                            title: error.response.data.message,
                            confirmButtonText: 'Ok',
                            heightAuto: false,
                        })
                    } finally {
                        setModalBtnLoading(false)
                    }
                }
            } else {
                Swal.fire({
                    title: intl.formatMessage({ id: 'FORM.VALIDATION.NOTIFICATION' }),
                    html: intl.formatMessage({ id: 'FORM.VALIDATION.SEND_NOTIFICATION' }),
                    icon: "question",
                    showCancelButton: true,
                    showDenyButton: true,
                    buttonsStyling: false,
                    confirmButtonText: intl.formatMessage({ id: 'FORM.ACTION.SEND_NOTIFICATION' }),
                    denyButtonText: intl.formatMessage({ id: 'FORM.ACTION.DONT_SEND_NOTIFICATION' }),
                    cancelButtonText: intl.formatMessage({ id: 'FORM.ACTION.CANCEL' }),
                    customClass: {
                        confirmButton: "btn btn-active-color-primary",
                        denyButton: "btn btn-active-color-gray-400",
                        cancelButton: "btn btn-active-color-gray-400",
                    },
                    reverseButtons: true,
                    heightAuto: false,
                    // target: document.getElementById(`update-status-meeting-modal-${meeting.id}`),
                }).then(async (result) => {
                    var notification = false

                    if (result.isConfirmed) {
                        notification = true
                    } else if (result.isDenied) {
                        notification = false
                    }

                    if (!result.isDismissed) {
                        setModalBtnLoading(true)

                        // Assuming values is of type Record<string, string> and customFields is of type fieldCustom
                        const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                            (obj, item) => ({
                                ...obj,
                                [item]: (values as Record<string, string | string[] | null>)[item],
                            }),
                            {}
                        );

                        // Use Promise.all to await all async operations
                        let countResourceError = 0
                        await Promise.all(
                            Object.keys(customFields).map(async (key) => {
                                if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                                    try {
                                        if (values[key as keyof typeof values] && typeof values[key as keyof typeof values] === 'object') {
                                            const resourceResponse = await insertMeetingFieldAttachment(values[key as keyof typeof values] as string | File);
                                            if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                            else countResourceError++
                                        }
                                    } catch (error) {
                                        countResourceError++
                                    }
                                } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                                    try {
                                        if (values[key as keyof typeof values]) {
                                            let dataMultiAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item !== 'string')
                                            let dataUrlAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item === 'string')

                                            const resourceResponse = await insertMeetingFieldMultiAttachment(dataMultiAttachment);
                                            if (resourceResponse.data.success) (customFieldsValues[key] as any) = resourceResponse.data.data.length > 0 ? [...dataUrlAttachment, ...resourceResponse.data.data] : dataUrlAttachment.length > 0 ? dataUrlAttachment : null;
                                            else countResourceError++
                                        }
                                    } catch (error) {
                                        countResourceError++
                                    }
                                } else if (customFields[key].type === 'multiple') {
                                    var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
                                    customFieldsValues[key] = multipleValues as string
                                } else if (customFields[key].type === 'serial') {
                                    try {
                                        if (dataCustomFieldSerial[key].value.length === 0) {
                                            const serialResponse = await generateSerialApi(key, "Meeting", token);
                                            customFieldsValues[key] = serialResponse.data.success ? serialResponse.data.data : null;
                                        } else {
                                            customFieldsValues[key] = dataCustomFieldSerial[key].value
                                        }
                                    } catch (error) {
                                        countResourceError++
                                    }
                                }
                            })
                        );

                        if (countResourceError > 0) {
                            Swal.fire({
                                icon: "error",
                                text: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_FAILED" })}.`,
                                heightAuto: false,
                                confirmButtonText: 'Ok'
                            })
                            setModalBtnLoading(false);
                        } else {
                            try {
                                let attachments: MeetingAttachment[] = []
                                if (values.attachment && typeof values.attachment !== 'string') {
                                    const resourceResponse = await insertMeetingAttachment(values.attachment)
                                    if (resourceResponse.data.data.length > 0) {
                                        const parts = resourceResponse.data.data[0].split('/');
                                        const fileName = parts[parts.length - 1];
                                        attachments = [
                                            {
                                                name: fileName,
                                                url: resourceResponse.data.data[0]
                                            }
                                        ]
                                    }
                                } else if (values.attachment && typeof values.attachment === 'string') {
                                    attachments = [
                                        {
                                            name: values.attachment,
                                            url: values.attachment
                                        }
                                    ]
                                }
                                const meetingLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label } }) : []
                                const response = await updateMeeting({
                                    id: meeting.id,
                                    start: {
                                        date: meeting.allDay ? moment(`${meeting.date_meet}`).format("YYYY-MM-DD") : null,
                                        dateTime: !meeting.allDay ? moment(`${meeting.date_meet}`).toISOString(true) : null
                                    },
                                    end: {
                                        date: meeting.allDay ? moment(`${meeting.date_meet_end}`).add(meeting.allDay ? 1 : 0, 'days').format("YYYY-MM-DD") : null,
                                        dateTime: !meeting.allDay ? moment(`${meeting.date_meet_end}`).add(meeting.allDay ? 1 : 0, 'days').toISOString(true) : null,
                                    },
                                    ownerId: meeting.owner_id,
                                    attachments: attachments,
                                    status: values.status,
                                    reminderMinutes: meeting.reminderMinutes,
                                    userAttendees: meeting.attendees,
                                    token: token,
                                    notification: notification,
                                    note: values.note,
                                    latitude: meeting.latitude,
                                    longitude: meeting.longitude,
                                    location: meeting.location,
                                    customFields: customFieldsValues,
                                    meetingLabels,
                                })
                                if (response.data.success) {
                                    Swal.fire({
                                        icon: 'success',
                                        heightAuto: false,
                                        title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" })}.`,
                                        timer: 2000,
                                        timerProgressBar: true
                                    })
                                    setTablesData('meetings', (tablesData.meetings.map(item => {
                                        if (item.id === meeting.id) {
                                            return {
                                                ...item,
                                                meetingStatusNote: response.data.data.meetingStatusNote,
                                                status: response.data.data.status === "PLANNED" ? intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) : response.data.data.status === "COMPLETED" ? intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) : response.data.data.status === "ONGOING" ? intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) : intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' }),
                                                attachments: response.data.data.attachments,
                                                note: response.data.data.note,
                                                date_meet: moment(response.data.data.date_meet).format("YYYY-MM-DD HH:mm:ss"),
                                                date_meet_end: response.data.data.allDay ? moment(response.data.data.date_meet_end).subtract(1, 'days').local().format("YYYY-MM-DD HH:mm:ss") : moment(response.data.data.date_meet_end).format("YYYY-MM-DD HH:mm:ss"),
                                                custom_fields: response.data.data.custom_fields,
                                                labels: response.data.data.labels
                                            }
                                        } else {
                                            return item
                                        }
                                    })));
                                    closeModalRef.current?.click();
                                }
                            } catch (error: any) {
                                Swal.fire({
                                    icon: 'error',
                                    title: error.response.data.message,
                                    confirmButtonText: 'Ok',
                                    heightAuto: false,
                                })
                            } finally {
                                setModalBtnLoading(false)
                            }
                        }
                    }
                })
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listeners
        const handleModalHidden = () => {
            formik.resetForm();

            Object.keys(customFields).forEach((key, index) => {
                // Reset 
                const ref = refArray.current[index];
                if (ref && ref.value) {
                    ref.value = "";
                }

                if (customFields[key].type === "multiple") {
                    var checkboxes = document.getElementsByName(key)
                    checkboxes.forEach((checkbox: any) => {
                        checkbox.checked = false
                    })
                }
                if (customFields[key].type === "date") {
                    formik.setFieldValue(key, null)
                }
            });
        };

        // Attach the event listeners
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        if (meeting.status === intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' })) {
            setStatusData([...statusData, { value: 'ONGOING', label: intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) }])
        }
    }, [])

    useEffect(() => {
        if (settings.meeting_custom_fields) {
            var data = JSON.parse(settings.meeting_custom_fields)
            const transformedData = Object.keys(data).reduce((acc: any, key: string) => {
                acc[key] = {
                    loading: false,
                    value: meeting.custom_fields[key] ? meeting.custom_fields[key] : []
                };
                return acc;
            }, {});

            setCustomFields(data)
            setDataCustomFieldSerial(transformedData)
        }
    }, [settings])

    const handleGenerateSerial = async (key: string) => {
        setDataCustomFieldSerial(prevState => ({
            ...prevState,
            [key]: { ...prevState[key], loading: true }
        }));

        try {
            const response = await generateSerialApi(key, "Meeting", token);
            if (response.data.success) {
                formik.setFieldValue(key, response.data.data)
                setDataCustomFieldSerial(prevState => ({
                    ...prevState,
                    [key]: { ...prevState[key], value: response.data.data }
                }));
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                target: document.getElementById(`update-status-meeting-modal-${meeting.id}`),
                heightAuto: false,
            })
        } finally {
            setDataCustomFieldSerial(prevState => ({
                ...prevState,
                [key]: { ...prevState[key], loading: false }
            }));
        }
    }

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);
    const API_URL = process.env.REACT_APP_API_URL
    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const { data: labels = [], error: labelsError, isLoading: labelsLoading } = useSWR(`${API_URL}labels?usableBy[]=meeting`, fetcher)


    const [labelsOption, setLabelOption] = useState([])

    useEffect(() => {
        const newLabelsOption = labels.map((label: any) => { return { value: label.id, label: label.name } })
        setLabelOption(newLabelsOption)
    }, [labels])

    return (
        <div className="modal fade" tabIndex={-1} id={`update-status-meeting-modal-${meeting.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" style={{ overflowWrap: "anywhere" }}>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} {meeting.merchant_name} <br /> {intl.formatMessage({ id: 'FORM.LABEL.DATE' }, { title: "" })} {moment(meeting.date_meet).format('DD-MM-YYYY')}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {
                                meeting.status !== intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) &&
                                <>
                                    {/* Status */}
                                    <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.STATUS' })}</label>
                                    <div className="input-group mb-3">
                                        <Select
                                            key={nanoid()}
                                            options={statusData}
                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.STATUS" })}`}
                                            value={statusData.find(option => option.value === formik.values.status)}
                                            isDisabled={meeting.status === intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) ? false : true}
                                            onChange={
                                                option => formik.handleChange({
                                                    target: {
                                                        name: "status",
                                                        value: option?.value
                                                    }
                                                })
                                            }
                                            onBlur={
                                                () => formik.values.status === "" && formik.setFieldTouched("status", true)
                                            }
                                            className="w-100"
                                        />
                                    </div>
                                </>
                            }

                            {/* Attachments */}
                            {
                                meeting.status === intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) &&
                                <>
                                    <label className={`form-label fs-6 fw-bolder text-dark ${(formik.values.status === "COMPLETED" || formik.values.status === "ONGOING") ? 'required' : ''}`}>{intl.formatMessage({ id: 'FORM.LABEL.PHOTO' }, { title: settings.meeting_title })}</label>
                                    <InputAttachment keyString={"attachment"} formik={formik} isMulti={false} type="update-status-meeting" accept="image/png, image/jpeg, image/jpg" />
                                    {formik.touched.attachment && formik.errors.attachment && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.attachment}</span>
                                        </div>
                                    )}
                                </>
                            }

                            {/* Note */}
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.NOTE' })}</label>
                            <div className="input-group mb-3">
                                <textarea
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.NOTE' })}
                                    id="floatingTextarea"
                                    {...formik.getFieldProps('note')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.note && formik.errors.note },
                                    )}
                                    style={{ zIndex: 0 }}
                                    name="note"
                                    onInput={(e) => handleCaps(e)}
                                >
                                </textarea>
                            </div>
                            {formik.touched.note && formik.errors.note && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.note}</span>
                                </div>
                            )}
                            {/* label */}
                            {
                                user.data.company_id !== companyId.esb &&
                                <>
                                    <label className='form-label fs-6 fw-bolder text-dark '>{intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}</label>
                                    <div className="input-group mb-3">
                                        <Select
                                            closeMenuOnSelect={false}
                                            openMenuOnClick
                                            isMulti
                                            options={labelsOption}
                                            placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}`}
                                            value={labelsOption.filter((option: any) =>
                                                formik.values.labels.includes(option.value)
                                            )
                                            }
                                            onChange={(selectedOptions) => {
                                                const selectedValues = selectedOptions
                                                    ? selectedOptions.map((option: any) => option.value)
                                                    : []
                                                formik.setFieldValue('labels', selectedValues)
                                            }}
                                            className='w-100'
                                            onBlur={() =>
                                                formik.values.labels.length == 0 &&
                                                formik.setFieldTouched('labels', true)
                                            }
                                        />
                                    </div>
                                    {formik.touched.labels && formik.errors.labels && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.labels}</span>
                                        </div>
                                    )}
                                </>
                            }
                            {
                                (settings.update_meeting) &&
                                customFields && Object.keys(customFields).map((key: string, index: number) => {
                                    if (customFields[key].type === 'text') {
                                        return (
                                            <div key={index}>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        {...formik.getFieldProps(key)}
                                                        className={clsx(
                                                            'form-control form-control-lg',
                                                            { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                        )}
                                                        type='text'
                                                        name={key}
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}
                                                        required={true}
                                                        onInput={(e) => handleCaps(e)}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'serial') {
                                        return (
                                            <div key={index}>
                                                <label className={`form-label fs-6 fw-bolder text-dark`}>{customFields[key].name}</label>
                                                {
                                                    dataCustomFieldSerial[key].loading ?
                                                        <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" style={{ float: "inline-end" }}>
                                                            <l-tailspin
                                                                size="15"
                                                                stroke="3"
                                                                speed="0.9"
                                                                color="#ffffff"
                                                            ></l-tailspin>
                                                        </span>
                                                        :
                                                        <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" onClick={() => handleGenerateSerial(key)} style={{ float: "inline-end", cursor: "pointer" }}>{intl.formatMessage({ id: 'FORM.ACTION.GENERATE_SERIAL' })}</span>
                                                }
                                                <div className="d-flex mb-3 gap-3">
                                                    <input
                                                        disabled
                                                        placeholder={customFields[key].name}
                                                        {...formik.getFieldProps(key)}
                                                        value={typeof formik.values[key as keyof typeof formik.values] === 'string' ? formik.values[key as keyof typeof formik.values] : (formik.values[key as keyof typeof formik.values] as any)?.join("")}
                                                        className={clsx('form-control form-control-lg')}
                                                        type='text'
                                                        name={key}
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'link') {
                                        return (
                                            <div key={index}>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        placeholder={customFields[key].name}
                                                        {...formik.getFieldProps(key)}
                                                        className={clsx(
                                                            'form-control form-control-lg txt-link',
                                                            { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                        )}
                                                        type='text'
                                                        name={key}
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}
                                                        required={true}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'phone') {
                                        return (
                                            <div>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <InputPhone keyString={key} formik={formik} type="update" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'image') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format PNG, JPG, JPEG</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={false} type="update" accept="image/png, image/jpeg, image/jpg" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'images') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format PNG, JPG, JPEG</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={true} type="update" accept="image/png, image/jpeg, image/jpg" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'file') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format PDF, XLX, XLSX, DOCS, PPT</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={false} type="update" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'files') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format PDF, XLX, XLSX, DOCS, PPT</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={true} type="update" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'date') {
                                        var min: number | null = null
                                        var max: number | null = null

                                        if (customFields[key].min === undefined || customFields[key].min === null) min = null //Tidak ada batasan
                                        else if (customFields[key].min) min = Number(customFields[key].min)
                                        else min = 0 //Batasan hari ini

                                        if (customFields[key].max === undefined || customFields[key].max === null) max = null //Tidak ada batasan
                                        else if (customFields[key].max) max = Number(customFields[key].max)
                                        else max = 0 //Batasan hari ini

                                        return (
                                            <>
                                                {/* Date */}
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <DatePicker
                                                        ranges={todayRange}
                                                        oneTap
                                                        editable={false}
                                                        format="dd MMM yyyy"
                                                        value={formik.values[key as keyof typeof formik.values] ? new Date(formik.values[key as keyof typeof formik.values] as string | number | Date) : null}
                                                        defaultValue={null}
                                                        style={{ width: "100%", zIndex: 0 }}
                                                        container={document.querySelector(`#update-status-meeting-modal-${meeting.id}`) as HTMLElement}
                                                        onChange={(value: any) => {
                                                            if (value) formik.setFieldValue(key, moment(value).format('YYYY-MM-DD'))
                                                            else formik.setFieldValue(key, null)
                                                        }}
                                                        shouldDisableDate={date => {
                                                            var startDate = min === null ? null : min > 0 ? addDays(new Date(), min) : subDays(new Date(), min * -1);
                                                            var endDate = max === null ? null : max > 0 ? addDays(new Date(), max) : subDays(new Date(), max * -1);

                                                            date.setHours(0, 0, 0, 0);
                                                            if (startDate !== null && endDate !== null) {
                                                                startDate.setHours(0, 0, 0, 0);
                                                                endDate.setHours(0, 0, 0, 0);
                                                                return date > endDate || date < startDate
                                                            } else if (startDate !== null && endDate === null) {
                                                                startDate.setHours(0, 0, 0, 0);
                                                                return date < startDate
                                                            } else if (startDate === null && endDate !== null) {
                                                                endDate.setHours(0, 0, 0, 0);
                                                                return date > endDate
                                                            } else return false
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'option') {

                                        const options = customFields[key].value.map((option, index) => ({
                                            value: option,
                                            label: option
                                        }))

                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <Select
                                                        key={
                                                            window.location.pathname === '/chat' ? nanoid() : ''
                                                        }
                                                        isClearable
                                                        options={options}
                                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                        value={options.find(option => option.value === formik.values[key as keyof typeof formik.values]) || null}
                                                        onChange={
                                                            option => formik.handleChange({
                                                                target: {
                                                                    name: key,
                                                                    value: option?.value || ""
                                                                }
                                                            })
                                                        }
                                                        onBlur={
                                                            () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                                        }
                                                        className="w-100"
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'multiple') {
                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <div className="form-check form-check-custom form-check-solid" style={{ display: "contents" }}>
                                                        {
                                                            customFields[key].value.map((option, index) => (
                                                                <div style={{ display: "flex", padding: "3px", overflowWrap: "anywhere" }} key={index}>
                                                                    <input
                                                                        key={index}
                                                                        {...formik.getFieldProps(key)}
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value={option as string}
                                                                        name={key}
                                                                        checked={formik.values[key as keyof typeof formik.values]?.includes(option as string)}
                                                                    />
                                                                    <label className="form-check-label me-2">{option as string}</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                    return null
                                })
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleCloseModal}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export { UpdateStatusMeeting }