import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import debounce from "lodash.debounce";
import moment from "moment";
import 'moment/locale/id';
import { useDataTableStore } from "../stores/DataTableStore";
import { ListProduct } from "../interfaces/Pipeline";
import { TableColumn } from "react-data-table-component";
import { customSort } from "../functions/general";
import { Label, LabelOption } from "../interfaces/Label";
import { useMerchantStore } from "../stores/MerchantStore";

const useSearch = (tableKey: string, tableColumns: TableColumn<any>[], order?: 'asc' | 'desc', orderBy?: string | number) => {
    const { tablesData, setFilteredTablesData, selectedFilters, dateRangeMerchants, setDateRangeMerchants, filteredTablesData } = useDataTableStore();
    const [filterText, setFilterText] = useState({ [tableKey]: '' });
    const [debouncedFilterText, setDebouncedFilterText] = useState(filterText[tableKey]);
    const [orderColumn, setOrderColumn] = useState<string | number>(orderBy ?? 1);
    const [orderDirection, setOrderDirection] = useState<string>(order!);
    const { setTempSortTableData } = useMerchantStore()
    // Debounce function
    const debounceFilter = useCallback(
        debounce((text) => setDebouncedFilterText(text), 300), // 300ms debounce time
        []
    );

    const isDate = (value: any): value is Date | string => {
        return moment(value, moment.ISO_8601, true).isValid();
    };

    const handleSearch = (text: string) => {
        setFilterText({ ...filterText, [tableKey]: text });
    };

    useEffect(() => {
        debounceFilter(filterText[tableKey]);
    }, [filterText[tableKey], debounceFilter]);

    // console.log(tablesData, 'tablesData')
    // console.log(filteredTablesData, 'filteredTablesData')

    useEffect(() => {
        if (tablesData[tableKey]) {
            const filteredData = tablesData[tableKey].filter((item: any) => {

                if (tableKey === 'merchants' && dateRangeMerchants && dateRangeMerchants[0] && dateRangeMerchants[1]) {
                    const startDateTime = moment(dateRangeMerchants[0]).startOf('day');
                    const endDateTime = moment(dateRangeMerchants[1]).endOf('day');
                    const dateCreated = moment(item.dateCreated);
                    if (!dateCreated.isBetween(startDateTime, endDateTime, 'day', '[]')) {
                        return false;
                    }
                } else {
                    setDateRangeMerchants(null)
                }

                if (debouncedFilterText) {
                    const filterWords = debouncedFilterText.split(' ');

                    const isTextInValues = Object.values(item).some(val => {
                        if (isDate(val)) {
                            const formattedDate = moment(val).format('DD MMMM YYYY');
                            return filterWords.every(word => {
                                const regexText = '.*' + word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '.*';
                                const regex = new RegExp(regexText, 'i');
                                return regex.test(formattedDate);
                            });
                        }
                        return filterWords.every(word => {
                            const regexText = '.*' + word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '.*';
                            const regex = new RegExp(regexText, 'i');
                            return regex.test(String(val));
                        });
                    });

                    if (!isTextInValues) {
                        return false;
                    }
                }

                for (const filterKey in selectedFilters[tableKey]) {
                    const filterValues: any = selectedFilters[tableKey][filterKey];
                    if (filterValues?.length > 0) {
                        if (filterKey === "leadProducts") {
                            const itemValue = (item as any)[filterKey].map((data: ListProduct) => data.productName).join(', ');
                            if (!filterValues.some((value: any) => itemValue.includes(value))) {
                                return false;
                            }
                        } else if (filterKey === "leadProductLabels") {
                            const itemValueLabels = (item as any)['leadProducts'].map((data: ListProduct) => data.productLabels?.map((label: Label) => label.name).join(', ')).join(', ');
                            if (!filterValues.some((value: any) => itemValueLabels.includes(value))) {
                                return false;
                            }
                        } else if (filterKey === "userOwner" || filterKey === "reimbursementCategory") {
                            const itemValue = (item as any)[filterKey].id;
                            if (!filterValues.includes(itemValue)) {
                                return false;
                            }
                        } else if (filterKey === "labels") {
                            const itemValue = (item as any)[filterKey].map((data: LabelOption) => data.name).join(', ');
                            if (!filterValues.some((value: any) => itemValue.includes(value))) {
                                return false;
                            }
                        } else {
                            const itemValue = (item as any)[filterKey];
                            if (!filterValues.includes(itemValue)) {
                                return false; // If the item's value for this filterKey is not in the selected filter values, exclude the item.
                            }
                        }
                    }
                }

                return true;  // If all filters match (or are empty), include this item.
            });

            if (tableKey === 'all-user-attendances' && orderColumn && orderDirection) {
                setFilteredTablesData(tableKey, customSort(filteredData, tableColumns.at(typeof orderColumn === "string" ? parseInt(orderColumn) - 1 : orderColumn - 1)?.selector, orderDirection));
            } else {
                setFilteredTablesData(tableKey, filteredData);
                setTempSortTableData(filteredData, 'desc')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilterText, tablesData, selectedFilters, dateRangeMerchants]);

    return { filterText, handleSearch };
}

export default useSearch