import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table'
import { UserChat } from '../../../interfaces/Chat/UserChat'
import { useTokenCustomCompany } from '../../../stores/useTokenCustomCompany'
import { useChatStore } from '../../../stores/ChatStore'
import Swal from 'sweetalert2'
import useUser from '../../../hooks/User'
import ModalSqanQr from './ModalSqanQr'
import useModalEffect from '../../../hooks/useModalEffect'
import { clearDB } from '../../../db'
import { useDispatch } from 'react-redux'
import * as auth from '../../../app/modules/auth/redux/AuthRedux'
import { UserModel } from '../../../app/modules/auth/models/UserModel'
import { checkSession } from '../../../api/ChatHelper'
import useAccessToken from '../../../hooks/AccessToken'
import { clear } from 'console'
import { mutate } from 'swr'
import useShowCompanyById from '../../../hooks/useShowCompanyById'
import { useIntl } from 'react-intl'

const API_URL = process.env.REACT_APP_API_URL

interface SelectUserProps {
    users: UserChat[]
    isLoading: boolean
}

const SelectUser = ({ users, isLoading }: SelectUserProps) => {
    const { setConnected } = useChatStore()
    const { setTokenCustomCompany, setNumberCustomCompany } = useTokenCustomCompany()
    const [globalFilter, setGlobalFilter] = useState('')
    const [isChecking, setIsChecking] = useState(false)
    const token = useAccessToken()
    const user = useUser()
    const [showModalSqanQr, setShowModalSqanQr] = useState(false)
    const { company, companyChatToken } = useShowCompanyById(user.data.company_id)
    const [sorting, setSorting] = useState<SortingState>([])
    const intl = useIntl()

    const createColumns = (
        handleLogin: (data: UserChat) => Promise<void>,
        isChecking: boolean
    ) => {
        const columnHelper = createColumnHelper<UserChat>()
        return [
            columnHelper.accessor('userPhone', {
                header: 'Nomor',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('userName', {
                header: 'Nama',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('connected', {
                header: 'Status',
                cell: (info) => {
                    const data = info.row.original;
                    return data.connected ? (
                        <span className="badge badge-light-success fs-8 fw-bolder" >
                            <i className="fas fa-link me-2"></i> {intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CONNECTED' })}
                        </span >
                    ) : (
                        <span className="badge badge-light-danger fs-8 fw-bolder">
                            <i className="fas fa-unlink me-2"></i> {intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DISCONNECTED' })}
                        </span>
                    )
                }
            }),
            columnHelper.accessor('userId', {
                header: 'Aksi',
                cell: (info) => {
                    const data = info.row.original;
                    const isConnected = data.connected;

                    return (
                        <button
                            onClick={() => {
                                handleLogin(data);
                            }}
                            disabled={!isConnected || isChecking}
                            className={`btn btn-sm ${isConnected ? 'btn-primary' : 'btn-secondary'}`}
                        >
                            {isConnected ? 'Login' : 'Not Connected'}
                        </button>
                    );
                },
                enableSorting: false
            }),

        ]
    }

    useEffect(() => {
        const backdrops = document.querySelectorAll('.modal-backdrop.fade.show') as NodeListOf<HTMLDivElement>
        if (backdrops && backdrops.length > 1) {
            backdrops[0].remove()
        }
    }, [showModalSqanQr]);

    /* eslint-disable react-hooks/exhaustive-deps */
    const handleLogin = async (data: UserChat) => {
        if (!company) return;
        try {
            setIsChecking(true)
            const response = await checkSession(token, data.token, company)

            if (response.status === 200) {
                const isActive = response.data.data.isActive
                setConnected(isActive)
                if (isActive) {
                    setTokenCustomCompany(data.token)
                    setNumberCustomCompany(data.userPhone)

                    setTimeout(() => {
                        window.open('/chat', '_blank')
                        clearDB()
                    }, 100)
                } else {
                    setTokenCustomCompany('')
                    setNumberCustomCompany('')
                    if (user.data.chat_phone === data.userPhone) {
                        setShowModalSqanQr(true)
                        // window.open('/chat', '_blank')
                    } else {
                        Swal.fire({
                            title: 'Peringatan',
                            text: 'User belum connect wooblazz chat',
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                }
            }
        } catch (error) {
            console.error(error)
            Swal.fire({
                title: 'Error',
                text: 'Terjadi kesalahan saat memeriksa session',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        } finally {
            setIsChecking(false)
        }
    }

    const columns = createColumns(handleLogin, isChecking)

    const table = useReactTable({
        data: users,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            globalFilter,
            sorting,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: (row, columnId, filterValue) => {
            const searchValue = filterValue.toLowerCase()
            const userName = String(row.original.userName).toLowerCase()
            const userPhone = String(row.original.userPhone).toLowerCase()
            return userName.includes(searchValue) || userPhone.includes(searchValue)
        },
        initialState: {
            pagination: {
                pageSize: 5,
                pageIndex: 0,
            },
        },
    })

    return (
        <>
            <div className="mb-4">
                <div className="input-group" style={{ width: '300px' }}>
                    <span className="input-group-text">
                        <i className="fas fa-search"></i>
                    </span>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Cari nama atau nomor..."
                        value={globalFilter}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                    {globalFilter && (
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => setGlobalFilter('')}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    )}
                </div>
            </div>
            <table className="table table-row-dashed table-row-gray-230 align-middle gs-0 gy-4 text-center">
                <thead className="table-light" style={{ fontWeight: 'bold' }}>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    style={{ textAlign: 'center' }}
                                    onClick={header.column.getToggleSortingHandler()}
                                    className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                                >
                                    {header.isPlaceholder ? null : (
                                        <div className="d-flex justify-content-center align-items-center">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {header.column.getCanSort() && (
                                                <span className="ms-2">
                                                    {{
                                                        asc: <i className="fas fa-sort-up"></i>,
                                                        desc: <i className="fas fa-sort-down"></i>,
                                                    }[header.column.getIsSorted() as string] ?? <i className="fas fa-sort"></i>}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan={columns.length} className="text-center py-4">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="spinner-border text-primary" role="status">
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ) : table.getRowModel().rows.length === 0 ? (
                        <tr>
                            <td colSpan={columns.length} className="text-center py-4">
                                No data available
                            </td>
                        </tr>
                    ) : (
                        table.getRowModel().rows.map((row) => (
                            <tr key={row.id} style={{ textAlign: 'center' }}>
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <div className="d-flex justify-content-between align-items-center mt-4">
                <div className="d-flex align-items-center gap-5">
                    <select
                        className="form-select form-select-sm font-weight-bold mr-4 border-0 bg-light"
                        style={{ width: "75px" }}
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                    >
                        {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <nav className='d-flex justify-content-center align-items-center'>
                    <div className="fs-7 text-gray-700">
                        {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} -{" "}
                        {Math.min(
                            (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                            table.getFilteredRowModel().rows.length
                        )}{" "}
                        of {table.getFilteredRowModel().rows.length}
                    </div>
                    <ul className="pagination mb-0">
                        <li className={`page-item ${!table.getCanPreviousPage() ? "disabled" : ""}`}>
                            <button
                                className="page-link"
                                onClick={() => table.setPageIndex(0)}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <i className="fas fa-angle-double-left"></i>
                            </button>
                        </li>
                        <li className={`page-item ${!table.getCanPreviousPage() ? "disabled" : ""}`}>
                            <button
                                className="page-link"
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <i className="fas fa-angle-left"></i>
                            </button>
                        </li>
                        <li className={`page-item ${!table.getCanNextPage() ? "disabled" : ""}`}>
                            <button
                                className="page-link"
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                <i className="fas fa-angle-right"></i>
                            </button>
                        </li>
                        <li className={`page-item ${!table.getCanNextPage() ? "disabled" : ""}`}>
                            <button
                                className="page-link"
                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                disabled={!table.getCanNextPage()}
                            >
                                <i className="fas fa-angle-double-right"></i>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            {
                showModalSqanQr && <ModalSqanQr
                    isOpen={showModalSqanQr}
                    onClose={() => {
                        setShowModalSqanQr(false)
                        mutate(`${API_URL}${companyChatToken}`)
                    }}
                />
            }
        </>
    )
}

export default SelectUser