import { FC, useRef, useState } from "react";
import { ChatListItem } from "./ChatListItem";
import { useChatStore } from "../../stores/ChatStore";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useIntl } from "react-intl";
import { MerchantLeads, RecentChatHistory } from "../../interfaces/Chat/RecentChatHistory";
import { useSettingsStore } from "../../stores/SettingsStore";
import useUser from "../../hooks/User";

interface ChatListProps {
  chatMerchants: MerchantLeads[];
}

const ChatList: FC<ChatListProps> = () => {
  const { selectChatWindow, setSelectChatWindow, filteredRecentChatHistories } = useChatStore();
  const user = useUser()
  const intl = useIntl();
  const { settings } = useSettingsStore()
  const parentRef = useRef<HTMLDivElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [clickedChat, setClickedChat] = useState<RecentChatHistory>({} as RecentChatHistory);
  const sortedChatHistories = filteredRecentChatHistories
  
  const virtualizer = useVirtualizer({
    count: sortedChatHistories.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => {
      // Optimalkan ukuran estimasi berdasarkan tipe data
      const item = sortedChatHistories[index];
      return item.profile_picture_url === "contact" ? 250 : 300;
    },
    overscan: 5, // Tambahkan elemen buffer di atas dan bawah
    enabled: true,
  });

  const items = virtualizer.getVirtualItems();

  const handleContextMenu = (event: React.MouseEvent, chatItem: RecentChatHistory) => {
    event.preventDefault();
    if (selectChatWindow.length === 0) {
      setPosition({ x: event.clientX, y: event.clientY });
      setClickedChat(chatItem);
      setDropdownVisible(true);
    }
  };

  const handleClickChatWindow = () => {
    setSelectChatWindow([
      ...selectChatWindow,
      { name: clickedChat.chat_name, phone: clickedChat.chat_phone },
    ]);
  };

  return (
    <>
      <div
        ref={parentRef}
        style={{
          height: "100%",
          overflowY: "auto",
          position: "relative",
          contain: 'strict',
        }}
      >
        <div
          style={{
            height: virtualizer.getTotalSize(),
            position: "relative",
            width: '100%',
          }}
        >
          {items.map((virtualItem) => {
            const chatItem = sortedChatHistories[virtualItem.index];

            return (
              <div
                key={virtualItem.key.toString()}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  transform: `translateY(${virtualItem.start}px)`,
                }}
                onContextMenu={(event) => handleContextMenu(event, chatItem)}
                onClick={() => setDropdownVisible(false)}
              >
                {
                  (!settings.chat_show_pic_only || user.data.is_superadmin) &&
                  <ChatListItem recentChatHistory={chatItem} />
                }
                {
                  settings.chat_show_pic_only &&
                  !user.data.is_superadmin &&
                  chatItem.merchant_ids?.length !== 0 &&
                  <ChatListItem recentChatHistory={chatItem} />
                }
              </div>
            );
          })}
        </div>
      </div>

      {dropdownVisible && (
        <ul
          style={{
            position: "fixed",
            top: position.y,
            left: position.x,
            backgroundColor: "white",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            listStyle: "none",
            padding: "10px 0px",
            borderRadius: "8px",
            zIndex: 1000,
          }}
        >
          <li>
            <div className="dropdown-item cursor-pointer" onClick={handleClickChatWindow}>
              {intl.formatMessage({ id: "FORM.ACTION.LABEL_CHAT" })}
            </div>
          </li>
        </ul>
      )}
    </>
  );
};

export { ChatList };
