import axios from 'axios'

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL

const instance = axios.create({ baseURL: API_URL_EMAIL })

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token_email')
    if (token) {
      config.headers['X-Auth-Token'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
