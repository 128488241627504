import { FC, RefObject, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { EventData } from "../../interfaces/EventData";
import { updateEvent } from "../../api/EventCRUD";
import FullCalendar from "@fullcalendar/react";
import { ScheduleConference } from "../../interfaces/Conference";
import { EventImpl } from "@fullcalendar/core/internal";
import { deletetScheduleConference } from "../../api/ConferenceCRUD";
import useUser from "../../hooks/User";
import { useIntl } from "react-intl";

interface eventProps {
    id: string,
    events: []
}

interface Props {
    closeModalDetailRef: RefObject<HTMLDivElement>
    calendarRef: RefObject<FullCalendar>
    eventData: EventImpl
    dataCalendars: ScheduleConference[]
    listEvent: eventProps[]
    setListEvent: React.Dispatch<React.SetStateAction<eventProps[]>>
}

const DeleteScheduleConference: FC<Props> = ({ closeModalDetailRef, calendarRef, eventData, dataCalendars, listEvent, setListEvent }) => {
    const user = useUser()
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [detailEvent, setDetailEvent] = useState<ScheduleConference>(dataCalendars.find(data => data.id === eventData.id)!);
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const token = useAccessToken()
    const intl = useIntl()

    const handleDeleteProduct = async () => {
        setModalBtnLoading(true)
        try {
            const response = await deletetScheduleConference(
                detailEvent?.roomId,
                detailEvent?.id,
                token,
            )
            if (response.data.success || response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                    text: `${intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" })}.`,
                    heightAuto: false,
                })

                setListEvent(listEvent.filter((data: any) => data.events[0]!.id! !== detailEvent.id))
                calendarRef.current?.getApi().getEventById(eventData.id)?.remove()
                closeModalRef.current?.click();
                closeModalDetailRef.current?.click();
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={'delete-event-modal'}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.LABEL.DELETE_CONFERENCE_SCHEDULE' })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>{intl.formatMessage({ id: "FORM.CONFIRMATION" })} {intl.formatMessage({ id: "FORM.ACTION.DELETE" }).toLowerCase()} {detailEvent?.roomTitle} ?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleDeleteProduct}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DeleteScheduleConference }