import React, { useRef } from 'react';
import { KTSVG } from '../../_metronic/helpers';
import { MyAttendance } from '../../interfaces/Attendance';
import { useIntl } from 'react-intl';
interface NoteModalProps {
    attendance: MyAttendance
    note: string
    updateType: string
}

const AttendanceNote: React.FC<NoteModalProps> = ({ attendance, note, updateType }) => {
    const intl = useIntl()
    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`note-attendance-modal`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ lineBreak: 'anywhere' }}>{updateType == "clockIn" ? intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_IN_NOTES" }) : intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_OUT_NOTES" })}&nbsp;{attendance.userName}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>{note}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { AttendanceNote }