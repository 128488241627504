import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import { SelectOption } from "../../interfaces/SelectOption";
import moment from "moment";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { User } from "../../interfaces/User";
import { DatePicker } from "rsuite";
import { KTSVG } from "../../_metronic/helpers";
import { useIntl } from "react-intl";
import { Currency } from "../../interfaces/Reimbursement";
import { insertLimit, insertLimitBulk } from "../../api/ReimbursementLimitCRUD";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { endOfMonth, endOfYear, startOfYear } from 'date-fns';

const customNumberFormat = (valueString: string) => {
    const onlyNumbers = valueString.replace(/\D+/g, '');  // remove non-digits
    const parts = onlyNumbers.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
}

interface Props {
    startDate: Date;
    endDate: Date;
    setStartDate: (date:Date) => void;
    setEndDate: (date:Date) => void;
}

const AddReimbursementLimit: FC<Props> = ({ startDate, endDate, setStartDate, setEndDate }) => {
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const datetimeRef = useRef<any>(null);
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const [userFormik, setUserFormik] = useState<string[]>([]);
    const [currencyData, setCurrencyData] = useState<SelectOption[]>([]);
    const [dateFormat, setDateFormat] = useState<string>("MM-YYYY");

    const intl = useIntl()

    const limitTypeData = [{
        label: intl.formatMessage({ id: "LIMIT.TYPE.MONTHLY" }),
        value: "MONTHLY",
        format: "MM-YYYY"
    }, {
        label: intl.formatMessage({ id: "LIMIT.TYPE.YEARLY" }),
        value: "YEARLY",
        format: "YYYY"
    }]

    const API_URL = process.env.REACT_APP_API_URL
    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(`${API_URL}users`, fetcher)
    const { data: currencies = [], error: currenciesError, isLoading: currenciesLoading } = useSWR(`${API_URL}currencies`, fetcher)

    useEffect(() => {
        if (!usersLoading) {
            const usersData = users.map((user: User) => ({
                value: user.id,
                label: user.name
            }));
            setUsersData(usersData)
        }
    }, [users])

    const handleNominalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = customNumberFormat(e.target.value);
        formik.setFieldValue('nominal', formattedValue);
    };

    const formik = useFormik({
        initialValues: {
            user: userFormik,
            nominal: '',
            date: null,
            type: '',
            currency: '',
        },
        validationSchema: Yup.object().shape({
            nominal: Yup.string()
                .required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.LIMIT_NOMINAL" }) }))  // Moved this up so it runs first
                .test('is-numeric', 'Invalid format', value => {
                    return value ? !isNaN(Number(value.replace(/\./g, ''))) : true;  // If value is present, then check format
                }),
            user: Yup.array().min(1, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })),
            date: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.LIMIT_TIME_STARTED" }) })).nullable(),
            type: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.LIMIT_TYPE" }) })),
            currency: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.CURRENCY" }) })),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setSubmitting(true)
            setModalBtnLoading(true)
            try {
                const datas: any = []
                if (values.user.length > 1){ 
                    values.user.forEach((user: any) => {
                        datas.push({
                            ownerId: user,
                            period: moment(values.date).format("YYYY-MM") + "-01",
                            type: values.type,
                            currencyId: parseInt(values.currency),
                            amount: (values.nominal).split('.').join('')
                        });
                    });
                }
                const response = values.user.length === 1 ? await insertLimit(values.user[0], moment(values.date).format("YYYY-MM") + "-01", values.type, parseInt(values.currency), Number(values.nominal.replaceAll(".", "")), token) : await insertLimitBulk(datas, token)
                if (response.data.success) {
                    if (values.user.length === 1) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" })}.`,
                            heightAuto: false,
                        })

                        if (moment(response.data.data.period.start).isBefore(moment(startDate))) {
                            setStartDate(new Date(response.data.data.period.start))
                        } else if (moment(response.data.data.period.end).isAfter(moment(endDate))) {
                            const subtractEnd = moment(response.data.data.period.end, 'YYYY-MM-DD').subtract(1, "days").format('YYYY-MM-DD')
                            setEndDate(new Date(subtractEnd))
                        } else {
                            setTablesData('reimbursement-limits', ([...tablesData['reimbursement-limits'], {...response.data.data,
                                userId: response.data.data.owner.id,
                                userName: response.data.data.owner.name
                            }]))
                        }
                    } else if (values.user.length > 1) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" })}.${response.data.data.rejectedLimits.length > 0 ? ' ' + response.data.data.rejectedLimits.length + ' ' + (response.data.data.rejectedLimits.length > 1 ? intl.formatMessage({ id: "FORM.LABEL.DATAS" }).toLowerCase() : intl.formatMessage({ id: "FORM.LABEL.DATA" }).toLowerCase()) + ' ' + intl.formatMessage({ id: "FORM.VALIDATION.ADD_FAILED" }).toLowerCase() : ''}`,
                            heightAuto: false,
                        })

                        const periodStart = values.type === 'MONTHLY' ? moment(values.date).format("YYYY-MM") + "-01" : moment(values.date).startOf('year')
                        const periodEnd = values.type === 'MONTHLY' ? moment(values.date).endOf('month') : moment(values.date).endOf('year')

                        if (moment(periodStart).isBefore(moment(startDate))) {
                            setStartDate(new Date(moment(periodStart).toDate()))
                        } else if (moment(periodEnd).isAfter(moment(endDate))) {
                            setEndDate(new Date(moment(periodEnd).toDate()))
                        } else {
                            mutate(`${API_URL}reimbursement-limits`);
                        }
                    }
                    closeModalRef.current?.click();
                    formik.resetForm()
                    formik.setFieldValue('date', null)
                }
            } catch (error: any) {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            formik.setFieldValue('date', null)
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const handleChange = (
        selectedOptions: any,
    ) => {
        const selectedValues = (selectedOptions as SelectOption[]).map(option => option.value);
        formik.setFieldValue(
            'user',
            selectedOptions ? selectedValues : [],
        );
    };

    const getValue = () => {
        if (formik.values.user.length > 0) return usersData.filter((option) => formik.values.user.indexOf(option.value) >= 0)
        else return []
    };

    useEffect(() => {
        if (!currenciesLoading) {
            let sortCurrencies = currencies
            
            sortCurrencies.forEach(function(currency: Currency, index: number) {
                if (currency.code === "IDR" && currency.name === "Rupiah") {
                    sortCurrencies.splice(index, 1);
                    sortCurrencies.unshift(currency);
                }
            });

            const currencyData = sortCurrencies.map((currency: Currency) => ({
                value: currency.id,
                label: currency.code + ' - ' + currency.name + (currency.symbol ? (' (' + currency.symbol + ')') : '')
            }));

            // Prepend the two objects to the salesData array
            setCurrencyData(currencyData);
        }
    }, [currencies]);

    const handleOpen = () => {
        datetimeRef.current?.setViewDate(formik.values.date ?? new Date());
    };

    return (
        <div>
            <button type="button"
                className="btn btn-primary mt-3"
                data-bs-toggle="modal"
                data-bs-target="#tambah-limit-modal"
            >
                <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'FORM.MENU.LIMITS' })}
            </button>
            <div className="modal fade" tabIndex={-1} id="tambah-limit-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'FORM.MENU.LIMITS' })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.USER' })}</label>
                                <div className="input-group mb-3">
                                    <Select
                                        key={nanoid()}
                                        options={usersData}
                                        placeholder={intl.formatMessage({ id: 'FORM.ACTION.CHOOSE_USER' })}
                                        value={getValue()}
                                        onChange={
                                            handleChange
                                        }
                                        onBlur={
                                            () => formik.values.user.length === 0 && formik.setFieldTouched("user", true)
                                        }
                                        className="w-100"
                                        isDisabled={usersLoading}
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                                {formik.touched.user && formik.errors.user && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.user}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.LIMIT_TYPE' })}</label>
                                <div className="input-group mb-3">
                                    <Select
                                        key={nanoid()}
                                        options={limitTypeData}
                                        placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.LIMIT_TYPE' })}`}
                                        value={limitTypeData.find(option => option.value === formik.values.type)}
                                        onChange={(option) => {
                                            formik.handleChange({
                                                target: {
                                                    name: "type",
                                                    value: option?.value
                                                }
                                            })
                                            setDateFormat(option ? option.format : "MM-YYYY")
                                            datetimeRef.current?.navigate(option && option.value === 'YEARLY'? 'years' : 'months')
                                            formik.setFieldValue('date', null)
                                        }}
                                        onBlur={
                                            () => formik.values.type === "" && formik.setFieldTouched("type", true)
                                        }
                                        className="w-100"
                                        isDisabled={usersLoading}
                                    />
                                </div>
                                {formik.touched.type && formik.errors.type && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.type}</span>
                                    </div>
                                )}
                                {formik.values.type && (
                                    <>
                                        <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.LIMIT_TIME_STARTED' })}</label>
                                        <div className="input-group mb-3">
                                            {/* <DatePicker
                                                editable={false}
                                                oneTap
                                                value={formik.values.date}
                                                format="MM-yyyy"
                                                style={{ width: "100%", zIndex: 0 }}
                                                container={document.querySelector('#tambah-limit-modal') as HTMLElement}
                                                onChange={(value: any) => {
                                                    if (value) formik.setFieldValue('date', value)
                                                    else formik.setFieldValue('date', null)
                                                }}
                                                // shouldDisableDate={(date) => {
                                                //     return date < startOfMonth(new Date());
                                                // }}
                                            /> */}
                                            <Datetime 
                                                dateFormat={dateFormat} 
                                                value={formik.values.date ?? ''}
                                                timeFormat={false} 
                                                className="w-100" 
                                                inputProps={{ placeholder: dateFormat, readOnly: true, value: (formik.values.date ? moment(formik.values.date).format(dateFormat) : '') }} 
                                                closeOnSelect={true}
                                                onChange={(value: any) => {
                                                    if (value) formik.setFieldValue('date', value)
                                                    else formik.setFieldValue('date', null)

                                                    // setViewDate(value ?? new Date());
                                                }}
                                                onOpen={handleOpen}
                                                ref={datetimeRef}
                                                renderInput={(props, openCalendar, closeCalendar) => {
                                                    function clear() {
                                                        props.onChange({ target: { value: "" } });
                                                    }
                                                    return (
                                                        <div className="input-group">
                                                            <input {...props} />
                                                            <button type="button" className="btn bg-transparent btn-clear-date" onClick={clear}>
                                                                <i className="fa fa-times"></i>
                                                            </button>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                        {formik.touched.date && formik.errors.date && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.date}</span>
                                            </div>
                                        )}
                                    </>
                                )}
                                {/* Currency */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.CURRENCY' })}</label>
                                <div className="input-group mb-3">
                                    <Select
                                        key={nanoid()}
                                        options={currencyData}
                                        placeholder={intl.formatMessage({ id: 'FORM.ACTION.CHOOSE_CURRENCY' })}
                                        value={currencyData.find(option => option.value === formik.values.currency) || null}
                                        onChange={
                                            option => formik.handleChange({
                                                target: {
                                                    name: "currency",
                                                    value: option?.value || ""
                                                }
                                            })
                                        }
                                        onBlur={
                                            () => formik.values.currency === "" && formik.setFieldTouched("currency", true)
                                        }
                                        className="w-100"
                                        isDisabled={currenciesLoading}
                                    />
                                </div>
                                {formik.touched.currency && formik.errors.currency && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.currency}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.LIMIT_NOMINAL' })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={intl.formatMessage({ id: 'FORM.LABEL.LIMIT_NOMINAL' })}
                                        value={formik.values.nominal}
                                        onChange={handleNominalChange}
                                        onBlur={formik.handleBlur}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.nominal && formik.errors.nominal },
                                        )}
                                        type='text'
                                        name='nominal'
                                        autoComplete='off'
                                        style={{ zIndex: 0 }}
                                    />
                                </div>
                                {formik.touched.nominal && formik.errors.nominal && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.nominal}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { AddReimbursementLimit }