import { FC, useEffect, useState } from "react";
import { Message } from "../../../interfaces/Chat/Message";
import { useChatStore } from "../../../stores/ChatStore";
import Swal from "sweetalert2";
import moment from "moment";
import useUser from "../../../hooks/User";
import { focusChatInput } from "../../../functions/chat";

interface ChatActionProps {
    message: Message
}

const ChatAction: FC<ChatActionProps> = ({ message }) => {

    const user = useUser()

    const { messages, setMessages, socket, phoneNumber,
        editMessage, setEditMessage, replyMessage, setReplyMessage,
        setIsOpenCheckBoxForward, setSelectedForwardMessages, setCurrentMessageForward,
        forwardChatPayload, setForwardChatPayload } = useChatStore()

    const handleEditChat = (id: string) => {
        setEditMessage(messages.find((message) => message.id === id)!.text)
        setMessages(() => messages.map((message) => {
            if (message.id === id) {
                return {
                    ...message,
                    is_editing: true,
                }
            }
            return message
        }))
    }

    const handleReplyChat = (message: Message) => {
        setReplyMessage(message)
        const id = message.id
        setMessages(() => messages.map((message) => {
            if (id === message.id) {
                return {
                    ...message,
                    is_replying: true,
                }
            }
            return message
        }))
    }

    const handleUnsendChat = (id: string) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Pesan yang telah dihapus tidak dapat dikembalikan!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Mohon tunggu',
                    html: 'Sedang menghapus pesan',
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })
                socket.emit("unsendChat", { receiver_phone: phoneNumber, id: id })
            }
        })
    }

    const handleReplyClick = () => {
        handleReplyChat(message)
        focusChatInput()
    }

    const handleForwardChatClick = () => {
        if (!message.chat_j_message) {
            return
        }
        setIsOpenCheckBoxForward(true)
        setSelectedForwardMessages([message])
        setCurrentMessageForward(message)
        setForwardChatPayload({
            targets: [message.phone],
            rawMessages: [{ chat_j_message: message.chat_j_message ?? '', date_created: message.time ?? '' }]
        })
    }

    return (
        <div className="dropdown dropend">
            <span className={`${message.type === 'in' ? 'ms-2' : 'me-2'} mt-1 position-absolute top-0 ${message.type === 'in' ? 'start-0' : 'end-0'} p-0`} role="button" id="message-actions-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fas fa-chevron-down fs-5" />
                <span className="caret"></span>
            </span>
            <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4 dropdown-menu text-center"
                aria-labelledby="message-actions-dropdown">
                {
                    message.phone === user.data.chat_phone && message.text &&
                    // Check if the time hasn't passed 1.59 minutes mark
                    moment().diff(moment(message.time, 'YYYY-MM-DD HH:mm:ss'), 'minutes') < 2 &&
                    <div className="menu-item menu-item-hover p-1 fs-4" role='button' onClick={() => { handleEditChat(message.id!) }}>
                        Edit
                    </div>
                }
                <div className="menu-item menu-item-hover p-1 fs-4" role='button' onClick={handleReplyClick}>
                    Reply
                </div>
                {
                    message.chat_j_message &&
                    <div className="menu-item menu-item-hover p-1 fs-4" role='button' onClick={handleForwardChatClick}>
                        Forward
                    </div>
                }
                {
                    message.phone === user.data.chat_phone &&
                    <div className="menu-item menu-item-hover p-1 fs-4" role='button' onClick={() => { handleUnsendChat(message.id!) }}>
                        Unsend
                    </div>
                }
            </div>
        </div>
    )
}

export { ChatAction }