import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const GET_COMPANY = `${API_URL}company`
const SWITCH_COMPANY = `${API_URL}switch-company-branch`

export const getCompanies = async (token: string) => {
    return axios.get(GET_COMPANY,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const switchCompany = async (userId: string, token: string) => {
    return axios.post(SWITCH_COMPANY,
        {
            userId,
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}