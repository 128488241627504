import { FC, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Modal } from "bootstrap";
import { AddAttendanceTemplate } from "../../../components/AttendanceTemplate/AddAttendanceTemplate";
import { UpdateAttendanceTemplate } from "../../../components/AttendanceTemplate/UpdateAttendanceTemplate";
import { DeleteAttendanceTemplate } from "../../../components/AttendanceTemplate/DeleteAttendanceTemplate";
import { AttendanceTemplate } from "../../../interfaces/Attendance";
import moment from "moment";
import { MapAttendance } from "../../../components/Attendance/MapAttendance";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useIntl } from "react-intl";

const SettingAttendanceTemplate: FC = () => {

    const [showMapModal, setShowMapModal] = useState<boolean>(false);
    const [latitudeMap, setLatitudeMap] = useState<number>();
    const [longitudeMap, setLongitudeMap] = useState<number>();
    const [attendance, setAttendance] = useState<AttendanceTemplate>();

    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showUpdateHoursModal, setShowUpdateHoursModal] = useState<boolean>(false);

    const intl = useIntl();
    const arrayDays = [intl.formatMessage({ id: "SUNDAY" }), intl.formatMessage({ id: "MONDAY" }), intl.formatMessage({ id: "TUESDAY" }), intl.formatMessage({ id: "WEDNESDAY" }), intl.formatMessage({ id: "THURSDAY" }), intl.formatMessage({ id: "FRIDAY" }), intl.formatMessage({ id: "SATURDAY" })]

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const checkNullArray = (array: any[]) => {
        return array.every(function (v) { return v === null; })
    }

    const tableColumns: TableColumn<AttendanceTemplate>[] = [
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.ACTION" }),
            center: true,
            width: '70px',
            cell: (row) => {
                return (
                    <>
                        {
                            row.name &&
                            <>
                                <IconButton
                                    aria-label={`more-${row.id}`}
                                    id={`menu-anchor-${row.id}`}
                                    onClick={() => handleClick(row.id)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    id={`menu-${row.id}`}
                                    anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                                    open={openMenuId === row.id}
                                    onClose={handleClose}
                                    disableScrollLock={true}
                                >
                                    <MenuItem onClick={() => {
                                        handleClose();
                                        setShowUpdateModal(true);
                                        setAttendance(row);
                                    }}>
                                        <i className="fas fa-edit fs-4 me-4"></i>
                                        <span>{intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}</span>
                                    </MenuItem>

                                    <MenuItem onClick={() => {
                                        handleClose();
                                        setShowDeleteModal(true);
                                        setAttendance(row);
                                    }}>
                                        <i className="fas fa-trash fs-3 me-5"></i>
                                        <span>{intl.formatMessage({ id: "FORM.ACTION.DELETE" })}</span>
                                    </MenuItem>
                                </Menu>
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NAME" }),
            selector: row => row.name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.name
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_IN.HOUR" }),
            selector: row => row.timeClockIn?.length > 0 ?? "-",
            // sortable: true,
            style: {
                flexDirection: 'column',
                alignItems: 'start',
                marginBottom: '10px',
                marginTop: '10px',
                gap: '5px',
            },
            cell: row => row.timeClockIn.length > 0 && !checkNullArray(row.timeClockIn) ?
                row.timeClockIn.map((timeClockIn, index) => {
                    return (
                        <span key={index} className="">{arrayDays[index] + " : "}{timeClockIn ? moment(timeClockIn, [moment.ISO_8601, 'HH:mm']).format('HH:mm') : '-'}</span>
                    )
                })
                :
                '-'
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_IN.TOLERANCE" }),
            selector: row => (row.timeClockInTolerance && row.timeClockInTolerance?.length > 0) ?? "-",
            // sortable: true,
            style: {
                flexDirection: 'column',
                alignItems: 'start',
                marginBottom: '10px',
                marginTop: '10px',
                gap: '5px',
            },
            cell: row => row.timeClockInTolerance && row.timeClockInTolerance.length > 0 && !checkNullArray(row.timeClockInTolerance) ?
                row.timeClockInTolerance.map((timeClockInTolerance, index) => {
                    return (
                        <span key={index} className="">{arrayDays[index] + " : "}{timeClockInTolerance ? moment(timeClockInTolerance, [moment.ISO_8601, 'HH:mm']).format('HH:mm') : '-'}</span>
                    )
                })
                :
                '-'
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_OUT.HOUR" }),
            selector: row => row.timeClockOut?.length > 0 ?? "-",
            // sortable: true,
            style: {
                flexDirection: 'column',
                alignItems: 'start',
                marginBottom: '10px',
                marginTop: '10px',
                gap: '5px',
            },
            cell: row => row.timeClockOut.length > 0 && !checkNullArray(row.timeClockOut) ?
                row.timeClockOut.map((timeClockOut, index) => {
                    return (
                        <span key={index} className="">{arrayDays[index] + " : "}{timeClockOut ? moment(timeClockOut, [moment.ISO_8601, 'HH:mm']).format('HH:mm') : '-'}</span>
                    )
                })
                :
                '-'
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.ATTENDANCE_LOCATION" }),
            selector: row => (row.latitudeClockIn && row.latitudeClockIn?.length > 0 && row.longitudeClockIn && row.longitudeClockIn?.length > 0) ? row.latitudeClockIn[0] + ", " + row.longitudeClockIn[0] : '-',
            center: true,
            cell: row => row.latitudeClockIn && row.latitudeClockIn?.length > 0 && row.longitudeClockIn && row.longitudeClockIn?.length > 0 && !checkNullArray(row.latitudeClockIn) && !checkNullArray(row.longitudeClockIn) ?
                <button className="btn btn-sm btn-flush p-1"
                    onClick={() => {
                        setShowMapModal(true);
                        setLatitudeMap(row.latitudeClockIn && row.latitudeClockIn[0]);
                        setLongitudeMap(row.longitudeClockIn && row.longitudeClockIn[0]);
                        setAttendance(row);
                    }}
                >
                    <i className="fas fs-2 fa-map-marked-alt"></i>
                </button>
                :
                '-'
            ,
        },
        // {
        //     name: 'Lokasi Clock-out',
        //     selector: row => (row.latitudeClockOut && row.latitudeClockOut?.length > 0 && row.longitudeClockOut && row.longitudeClockOut?.length > 0) ? row.latitudeClockOut[0] + ", " + row.longitudeClockOut[0] : '-',
        //     style: {
        //         flexDirection: 'column',
        //         alignItems: 'start',
        //         marginBottom: '10px',
        //         marginTop: '10px',
        //         gap: '5px',
        //     },
        //     cell: row => row.latitudeClockOut && row.latitudeClockOut?.length > 0 && row.longitudeClockOut && row.longitudeClockOut?.length > 0 ?
        //         row.latitudeClockOut.map((latitudeClockOut, index) => {
        //             return (
        //                 <div className="d-flex" style={{width: "max-content"}}>
        //                     {arrayDays[index] + " :"}&nbsp;
        //                     <a href="#" key={index} onClick={(e) => {
        //                             e.preventDefault();
        //                             setShowMapModal(true);
        //                             setLatitudeMap(latitudeClockOut);
        //                             setLongitudeMap(row.longitudeClockOut && row.longitudeClockOut[index]);
        //                             setAttendance(row);
        //                             setType("Clock-out");
        //                             setDay(arrayDays[index]);
        //                         }}
        //                     >
        //                         Lihat lokasi
        //                     </a>
        //                 </div>
        //             )
        //         })
        //         :
        //         '-'
        //     ,
        // },
    ];

    const actions = [
        <AddAttendanceTemplate />
    ]

    useEffect(() => {
        if (showMapModal && attendance) {
            const modalElement = document.getElementById(`map-attendance-modal-${attendance.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowMapModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showMapModal, attendance, setShowMapModal]);

    useEffect(() => {
        if (showUpdateModal && attendance) {
            const modalElement = document.getElementById(`update-attendance-modal-${attendance.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                if (!showUpdateHoursModal) modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    if (!showUpdateHoursModal) setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, attendance, setShowUpdateModal, showUpdateHoursModal]);

    useEffect(() => {
        if (showDeleteModal && attendance) {
            const modalElement = document.getElementById(`delete-attendance-modal-${attendance.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, attendance, setShowDeleteModal]);

    return (
        <>
            <MasterDataTable
                tableKey="attendance-templates"
                tableColumns={tableColumns}
                actions={actions}
                apiURL="attendance-templates"
            />

            {
                showMapModal && <MapAttendance latitude={latitudeMap!} longitude={longitudeMap!} attendance={attendance!} />
            }
            {
                showUpdateModal && <UpdateAttendanceTemplate attendance={attendance!} showUpdateHoursModal={showUpdateHoursModal} setShowUpdateHoursModal={setShowUpdateHoursModal} />
            }
            {
                showDeleteModal && <DeleteAttendanceTemplate attendance={attendance!} />
            }

        </>
    )
}

export { SettingAttendanceTemplate }