import React from "react";

type Props = {
    id: number
    checked: boolean | undefined
    onChange: any
}

const Checkbox: React.FC<Props> = ({ id, checked, onChange }) => {
    return (
        <input key={id} className="form-check-input h-20px w-20px" type="checkbox" value={id} checked={checked ?? false} onChange={onChange}/>
    );
};

export default Checkbox;