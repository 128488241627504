import React, { FC, useState } from 'react';

interface Props {
    currentPage: number
    totalPages: number
    pagination: number[]
    itemsPerPage: number
    totalItems: number
    setCurrentPage: (page: number) => void
    setItemPerPage: (page: number) => void
}

const Pagination: FC<Props> = ({ currentPage, totalPages, pagination, itemsPerPage, totalItems, setCurrentPage, setItemPerPage }) => {
    const startIndex = (currentPage - 1) * itemsPerPage + 1;
    const endIndex = Math.min(currentPage * itemsPerPage, totalItems);

    const handlePerPage = (pageNumber: number) => {
        if(pageNumber > totalItems) setCurrentPage(1)
        setItemPerPage(pageNumber);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Logic to render pagination numbers
    const renderPaginationNumbers = () => {
        const maxPagesToShow = 5;
        const halfMaxPages = Math.floor(maxPagesToShow / 2);
        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > maxPagesToShow) {
            if (currentPage <= halfMaxPages) {
                endPage = maxPagesToShow;
            } else if (currentPage + halfMaxPages >= totalPages) {
                startPage = totalPages - maxPagesToShow + 1;
            } else {
                startPage = currentPage - halfMaxPages;
                endPage = currentPage + halfMaxPages;
            }
        }

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        // Add ellipsis if necessary
        if (startPage > 1) {
            pages.unshift('...');
        }
        if (endPage < totalPages) {
            pages.push('...');
        }

        return pages;
    };



    return (
        <div className="row px-9 pt-8">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                <div className="dataTables_length me-3" id="kt_inbox_listing_length">
                    <label>
                        <select
                            style={{ backgroundColor: "white" }}
                            name="kt_inbox_listing_length"
                            aria-controls="kt_inbox_listing"
                            className="form-select form-select-sm form-select-solid"
                            onChange={(event) => handlePerPage(parseInt(event.target.value))}
                            value={itemsPerPage}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                </div>
                <div className="text-muted">
                    {startIndex} - {endIndex} of {totalItems}
                </div>
            </div>
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                <div className="dataTables_paginate paging_simple_numbers" id="kt_inbox_listing_paginate">
                    <ul className="pagination">
                        <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`} onClick={prevPage}>
                            <span aria-controls="kt_inbox_listing" data-dt-idx="0" tabIndex={0} className="page-link cursor-pointer">
                                <i className="previous"></i>
                            </span>
                        </li>

                        {renderPaginationNumbers().map((page: number | string, index: number) => (
                            <li key={index} className={`paginate_button page-item ${page === currentPage ? 'active' : ''}`}>
                                {typeof page === 'number' ? (
                                    <span
                                        aria-controls="kt_inbox_listing"
                                        data-dt-idx={page}
                                        tabIndex={0}
                                        className="page-link cursor-pointer"
                                        onClick={() => handlePageChange(page)}
                                        style={{ transition: "none" }}
                                    >
                                        {page}
                                    </span>
                                ) : (
                                    <span className="d-flex justify-content-center align-items-center" style={{ transition: "none", height: "2.5rem", minWidth: "2.5rem" }}>...</span>
                                )}
                            </li>
                        ))}


                        <li className={`paginate_button page-item next ${currentPage === totalPages ? 'disabled' : ''}`} onClick={nextPage}>
                            <span aria-controls="kt_inbox_listing" data-dt-idx="3" tabIndex={0} className="page-link cursor-pointer">
                                <i className="next"></i>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Pagination;


