import { FC, ReactNode, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Modal } from "bootstrap";
import moment from "moment";
import { Meeting, MeetingAttendance, VisitPermision } from "../../../interfaces/Meeting";
import { DateRangePicker } from "rsuite";
import { AddMeeting } from "../../../components/Meetings/AddMeeting";
import { DeleteMeeting } from "../../../components/Meetings/DeleteMeeting";
import { MeetingAttachments } from "../../../components/Meetings/MeetingAttachments";
import { UpdateMeeting } from "../../../components/Meetings/UpdateMeeting";
import { AddPipeline } from "../../../components/Pipelines/AddPipeline";
import useUser from "../../../hooks/User";
import { UserScopeModal, defaultScope } from "../../../components/DataTable/UserScopeModal";
import { UserScope } from "../../../interfaces/UserScope";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useSWR, { mutate } from "swr";
import { MapMeeting } from "../../../components/Meetings/MapMeeting";
import { CustomField } from "../../../interfaces/Settings";
import { FilterMeeting } from "../../../components/Meetings/FilterMeeting";
import { DataTableExport } from "../../../components/DataTable/DataTableExport";
import { UpdateStatusMeeting } from "../../../components/Meetings/UpdateStatusMeeting";
import { useIntl } from "react-intl";
import usePredefinedRanges from "../../../hooks/PredefinedRanges";
import useCalendarLocale from "../../../hooks/CalendarLocale";
import { customNumberFormat } from "../../../functions/general";
import { endOfMonth, startOfMonth } from "date-fns";
import { DetailMeeting } from "../../../components/Meetings/DetailMeeting";
import { ExpandableElement } from "../../../components/General/ExpandableElement";
import axios from "axios";
import useAccessToken from "../../../hooks/AccessToken";
import { UpdateVisitMeeting } from "../../../components/Meetings/UpdateVisitMeeting";
import { DetailVisit } from "../../../components/Meetings/DetailVisit";
import { AddReimbursement } from "../../../components/Reimbursement/AddReimbursement";
import { OverlayTrigger, Tooltip as TooltipComp } from "react-bootstrap";
import { Tooltip } from "react-tippy";
import { useCustomeStore } from "../../../stores/CustomeStore";

interface MasterMeetingsContentProps {
    merchantId?: string
}

const MasterMeetingsContent: FC<MasterMeetingsContentProps> = ({ merchantId }) => {
    const API_URL = process.env.REACT_APP_API_URL
    const today = new Date()
    const { companyId } = useCustomeStore()
    const [startDate, setStartDate] = useState<Date>(startOfMonth(today));
    const [endDate, setEndDate] = useState<Date>(endOfMonth(today));
    const [loading, setLoading] = useState<boolean>(true);

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const { settings } = useSettingsStore()

    const user = useUser()
    const token = useAccessToken()
    const [showAddPipelineModal, setShowAddPipelineModal] = useState<boolean>(false);
    const [showAddReimbursementModal, setShowAddReimbursementModal] = useState<boolean>(false);
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showUpdateStatusModal, setShowUpdateStatusModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showDetailVisitModal, setShowDetailVisitModal] = useState<boolean>(false);
    const [showMapModal, setShowMapModal] = useState<boolean>(false);
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [latitudeMap, setLatitudeMap] = useState<string>("");
    const [longitudeMap, setLongitudeMap] = useState<string>("");
    const [visit, setVisit] = useState<VisitPermision>({} as VisitPermision);
    const [meeting, setMeeting] = useState<Meeting>();
    const [user_id, setUser_id] = useState<string>(user.data.id);
    const [user_name, setUser_name] = useState<string>(user.data.name);
    const [scope, setScope] = useState<UserScope>(defaultScope(user.data.permission_meeting_read));
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [customFields, setCustomFields] = useState<CustomField>({})
    const [showAttachmentCustomFieldModal, setShowAttachmentCustomFieldModal] = useState<boolean>(false);
    const [key, setKey] = useState<string>('');
    const [url, setUrl] = useState<string | string[]>('');
    const [manageColumns, setManageColumns] = useState<TableColumn<Meeting>[]>([]);
    const [isMounted, setIsMounted] = useState(false);

    const intl = useIntl()

    const predefinedRanges = usePredefinedRanges();
    const calendarLocale = useCalendarLocale();

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)
    const { data: checkVisit = [], isLoading: checkVisitLoading } = useSWR(`${API_URL}visits/insert-permission`, fetcher)

    useEffect(() => {
        if (!checkVisitLoading) {
            setVisit({ permission: checkVisit.permission, id: checkVisit.id, customFields: checkVisit.customFields })
        }
    }, [checkVisit])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 666) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const onRowClicked = (row: Meeting) => {
        setMeeting(row)
        setShowDetailModal(true);
    };

    const tableColumns: TableColumn<Meeting>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '70px',
            id: 'fixedLeft',
            cell: (row) => {
                return (
                    <>
                        <IconButton
                            aria-label={`more-${row.id}`}
                            id={`menu-anchor-${row.id}`}
                            onClick={() => handleClick(row.id)}
                        >
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            id={`menu-${row.id}`}
                            anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                            open={openMenuId === row.id}
                            onClose={handleClose}
                            disableScrollLock={true}
                        >
                            {
                                (!row.meeting_is_pipeline) && (user.data.is_superadmin || user.data.permission_pipeline_create !== 'NONE') &&
                                <MenuItem onClick={() => {
                                    handleClose();
                                    setShowAddPipelineModal(true);
                                    setMeeting(row);
                                }}>
                                    <i className="fas fa-plus fs-4 me-4"></i>
                                    <span>{settings.pipeline_title}</span>
                                </MenuItem>
                            }

                            {
                                user.data.id === row.owner_id &&
                                <MenuItem onClick={() => {
                                    handleClose();
                                    setShowAddReimbursementModal(true);
                                    setMeeting(row);
                                }}>
                                    <i className="fas fa-plus fs-4 me-4"></i>
                                    <span>{intl.formatMessage({ id: 'FORM.ACTION.REIMBURSEMENTS' })}</span>
                                </MenuItem>
                            }

                            {
                                (user.data.is_superadmin || user.data.permission_meeting_update !== 'NONE') &&
                                <MenuItem onClick={() => {
                                    handleClose();
                                    setShowUpdateStatusModal(true);
                                    setMeeting(row);
                                }}>
                                    <i className="fas fa-edit fs-4 me-4"></i>
                                    <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                                </MenuItem>
                            }

                            {
                                (user.data.is_superadmin || user.data.permission_meeting_delete !== 'NONE') &&
                                <MenuItem onClick={() => {
                                    handleClose();
                                    setShowDeleteModal(true);
                                    setMeeting(row);
                                }}>
                                    <i className="fas fa-trash fs-4 me-4"></i>
                                    <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                                </MenuItem>
                            }

                            {
                                row.type === 'VISIT' &&
                                <MenuItem onClick={() => {
                                    handleClose();
                                    setShowDetailVisitModal(true);
                                    setMeeting(row);
                                }}>
                                    <i className="fas fa-info-circle fs-4 me-4"></i>
                                    <span>{intl.formatMessage({ id: 'FORM.ACTION.VISIT_DETAIL' })}</span>
                                </MenuItem>
                            }
                        </Menu>
                    </>
                )
            },
            omit: manageColumns[0] ? manageColumns[0].omit : merchantId ? true : false
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHOTO' }),
            style: { cursor: "pointer" },
            width: '70px',
            center: true,
            reorder: true,
            cell: (row) => {
                if (row.attachments !== null && row.attachments.length > 0) {
                    return (
                        <span role="button" onClick={
                            () => {
                                setShowAttachmentModal(true);
                                setMeeting(row);
                            }
                        }>
                            <i className="fas fs-2 fa-eye"></i>
                        </span>
                    )
                } else return "-"
            },
            omit: manageColumns[1] ? manageColumns[1].omit : false
        },
        {
            name: settings.merchant_title,
            style: { cursor: "pointer" },
            width: '200px',
            selector: row => row.merchant_name?.toLowerCase() ?? "-",
            sortable: true,
            cell: (row) => {
                if (row.type === "VISIT") {
                    return (
                        <>       
                    <div className="d-flex flex-row gap-2">
                    <div>
                    <span>{row.merchant_name}</span>
                    <span className="badge badge-light-primary ms-3" >{intl.formatMessage({ id: 'MEETING.STATUS.VISIT' })}</span>
                    </div>
                    <div>
                            <OverlayTrigger
                                placement='right'
                                overlay={<TooltipComp id='tooltip-labels'>                                        
                                    {    row?.labels?.map((label, index) => (
                                            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                                                <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color||"black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                                    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                                </svg>
                                                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {label.name}
                                                </span>
                                            </div>
                                        ))
                                }
                                </TooltipComp>}
                            >
                                <span
                                    // id={`labels-${recentChatHistory.chat_id}`}
                                    className='cursor-pointer'
                                    data-toggle='tooltip'
                                    data-placement='right'
                                    title=''
                                >
                                    {(row.labels?.length && row.labels.length > 0) ? (
                                        row.labels.length === 1 ? (
                                            <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: row.labels[0].color||"black" }} >
                                                <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                            </svg>
                                        ) : (
                                            <div className="ps-1" style={{ color: row.labels[1].color||"black" }}>
                                                <svg width="18px" height="15px" viewBox="0 0 18 15">
                                                    <path fill={`${row.labels[0].color||"black"}`} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                                    <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                                </svg>
                                            </div>

                                                )
                                            ) : <></>}
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </>
                    )
                } else return (
                    <div className="d-flex flex-row gap-2">
                        <div>
                            {row.merchant_name}
                        </div>
                        <div>
                            <OverlayTrigger
                                // key={`labels-${recentChatHistory.chat_id}`}
                                placement='right'
                                overlay={<TooltipComp id='tooltip-labels'>
                                    
                                        
                                    {    row?.labels?.map((label, index) => (
                                            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                                                <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color||"black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                                    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                                </svg>
                                                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {label.name}
                                                </span>
                                            </div>
                                        ))
                                }
                                </TooltipComp>}
                            >
                                <span
                                    // id={`labels-${recentChatHistory.chat_id}`}
                                    className='cursor-pointer'
                                    data-toggle='tooltip'
                                    data-placement='right'
                                    title=''
                                >
                                    {(row.labels?.length && row.labels.length > 0) ? (
                                        row.labels.length === 1 ? (
                                            <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: row.labels[0].color||"black" }} >
                                                <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                            </svg>
                                        ) : (
                                            <div className="ps-1" style={{ color: row.labels[1].color||"black" }}>
                                                <svg width="18px" height="15px" viewBox="0 0 18 15">
                                                    <path fill={`${row.labels[0].color||"black"}`} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                                    <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                                </svg>
                                            </div>

                                        )
                                    ) : <></>}
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>


                )
            },
            reorder: true,
            omit: manageColumns[2] ? manageColumns[2].omit : false
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
            style: { cursor: "pointer" },
            width: '200px',
            selector: row => row.owner_name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.owner_name,
            reorder: true,
            omit: manageColumns[3] ? manageColumns[3].omit : false
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.date_meet,
            sortable: true,
            cell: (row) => {
                return (
                    row.allDay ? moment(row.date_meet).format("DD MMM YYYY") : moment(row.date_meet).format("DD MMM YYYY HH:mm")
                )
            },
            reorder: true,
            omit: manageColumns[4] ? manageColumns[4].omit : false
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.date_meet_end,
            sortable: true,
            cell: (row) => {
                if (row.date_meet_end === null) return "-"
                else return row.allDay ? moment(row.date_meet_end).format("DD MMM YYYY") : moment(row.date_meet_end).format("DD MMM YYYY HH:mm")
            },
            reorder: true,
            omit: manageColumns[5] ? manageColumns[5].omit : false
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PARTICIPANT' }),
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.attendees.length > 0 ? row.attendees.map((data: MeetingAttendance) => data.name).join(', ') : "-",
            sortable: true,
            cell: (row) => {
                return (
                    row.attendees.length > 0 ? row.attendees.map((data: MeetingAttendance) => data.name).join(', ') : "-"
                )
            },
            reorder: true,
            omit: manageColumns[6] ? manageColumns[6].omit : false
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
            style: { cursor: "pointer" },
            width: "200px",
            selector: row => row.creator_name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.creator_name,
            reorder: true,
            omit: manageColumns[7] ? manageColumns[7].omit : false
        },
        {
            name: user?.data.company_id === companyId.maxy ? intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DISCUSSION_NOTES' }) : intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
            style: { cursor: "pointer" },
            width: "250px",
            selector: row => row.note?.toLowerCase() ?? '-',
            cell: row => {
                if (row.note) {
                    var data = (row.note).replaceAll("\n", "<br/>")
                    return <ExpandableElement text={data} maxHeight={100} />;
                } else return '-'
            },
            reorder: true,
            omit: manageColumns[8] ? manageColumns[8].omit : false
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LOCATION' }),
            style: { cursor: "pointer" },
            width: '600px',
            selector: row => row.location,
            reorder: true,
            cell: (row) => {
                return (
                    <>
                        {
                            row.latitude && row.longitude ?
                                <span>
                                    {row.location}
                                    <i
                                        className="fas fs-5 fa-map-marker-alt ms-3 cursor-pointer text-hover-primary"
                                        onClick={() => {
                                            setShowMapModal(true);
                                            setLatitudeMap(row.latitude);
                                            setLongitudeMap(row.longitude);
                                            setMeeting(row);
                                        }}
                                    ></i>
                                </span>
                                :
                                <b>-</b>
                        }
                    </>
                )
            },
            omit: manageColumns[9] ? manageColumns[9].omit : false
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.REMINDER' }),
            style: { cursor: "pointer" },
            width: '150px',
            center: true,
            selector: row => row.reminderMinutes === null ? "-" : customNumberFormat(parseInt(row.reminderMinutes)) + " Menit",
            sortable: true,
            reorder: true,
            omit: manageColumns[10] ? manageColumns[10].omit : false
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.STATUS' }),
            style: { cursor: "pointer" },
            width: '200px',
            center: true,
            selector: row => row.status,
            cell(row) {
                if (row.status === intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' })) {
                    return <span className="badge badge-info" >{intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' })}</span>
                } else if (row.status === intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' })) {
                    return <span className="badge badge-success" >{intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' })}</span>
                } else if (row.status === intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' })) {
                    return <span className="badge badge-warning" >{intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' })}</span>
                } else {
                    return (
                        <>
                            {
                                (row.meetingStatusNote)?.includes("sistem") ?
                                    <Tooltip
                                        title={`${intl.formatMessage({ id: 'TOOLTIP.AUTO_CANCEL_SYSTEM' })}`}
                                        theme="light"
                                        arrow={true}
                                        className="btn btn-icon btn-flush"
                                        style={{ cursor: "default", display: "flex" }}
                                    >
                                        <span className="badge badge-danger" >{intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' })}</span>
                                        <i className='fas fa-info-circle ms-2 fs-7'></i>
                                    </Tooltip>
                                    :
                                    <span className="badge badge-danger" >{intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' })}</span>
                            }
                        </>
                    )
                }
            },
            sortable: true,
            reorder: true,
            omit: manageColumns[11] ? manageColumns[11].omit : false
        },
    ];

    const tableColumnsWithCustomFields: TableColumn<Meeting>[] = [
        ...tableColumns,
        ...Object.keys(customFields).map((key, index) => {
            if (customFields[key].type === 'date') {
                return {
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    selector: (row: Meeting) => row.custom_fields && row.custom_fields[key] ? moment(row.custom_fields[key]).format('DD-MM-YYYY') : '-',
                    sortable: true,
                    reorder: true,
                    width: "150px",
                    omit: manageColumns[index + 12] ? manageColumns[index + 12].omit : false,
                    cell: (row: Meeting) => {
                        if (row.custom_fields && row.custom_fields[key]) {
                            return (
                                moment(row.custom_fields[key]).format("DD MMM YYYY")
                            )
                        } else {
                            return '-'
                        }
                    }
                };
            }
            if (customFields[key].type === 'image' || customFields[key].type === 'images') {
                return {
                    width: "70px",
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    center: true,
                    cell: (row: Meeting) => {
                        if (row.custom_fields && row.custom_fields[key]) {
                            return (
                                <span role="button" onClick={
                                    () => {
                                        setShowAttachmentCustomFieldModal(true);
                                        setMeeting(row);
                                        setKey(key);
                                        setUrl(row.custom_fields[key] as any);
                                    }
                                }>
                                    <i className="fas fs-2 fa-eye"></i>
                                </span>
                            )
                        } else {
                            return '-'
                        }
                    },
                    reorder: true,
                    omit: manageColumns[index + 12] ? manageColumns[index + 12].omit : false,
                }
            }
            if (customFields[key].type === 'file' || customFields[key].type === 'files') {
                return {
                    width: "70px",
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    center: true,
                    cell(row: Meeting) {
                        const fileUrl = row.custom_fields && row.custom_fields[key];
                        if (typeof fileUrl === "string") {
                            return fileUrl ? (
                                <a
                                    href={fileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="ms-2 btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                >
                                    <i className="fas fa-file-invoice fs-3 float-end"></i>
                                </a>
                            ) : '-';
                        } else {
                            return fileUrl ? (
                                <span role="button" onClick={() => {
                                    setShowAttachmentCustomFieldModal(true);
                                    setMeeting(row);
                                    setKey(key);
                                    setUrl(fileUrl as string[]);
                                }}>
                                    <i className="fas fa-file-invoice fs-3 float-end"></i>
                                </span>
                            ) : '-';
                        }
                    },
                    reorder: true,
                    omit: manageColumns[index + 12] ? manageColumns[index + 12].omit : false,
                }
            }
            if (customFields[key].type === 'multiple') {
                return {
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    cell: (row: Meeting) => {
                        const values = row.custom_fields && row.custom_fields[key] && row.custom_fields[key].toString()
                        return values ? values.split(',').join(', ') : '-'
                    },
                    sortable: true,
                    reorder: true,
                    width: "150px",
                    omit: manageColumns[index + 12] ? manageColumns[index + 12].omit : false,
                };
            }
            if (customFields[key].type === 'link') {
                return {
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    grow: 2,
                    cell: (row: Meeting) => {
                        if (row.custom_fields && row.custom_fields[key]) {
                            return (
                                <a href={row.custom_fields[key] as string} rel="noreferrer" className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600" target="_blank">Lihat<i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i></a>
                            )
                        } else return "-"
                    },
                    sortable: true,
                    reorder: true,
                    width: "150px",
                    omit: manageColumns[index + 12] ? manageColumns[index + 12].omit : false,
                };
            }
            if (customFields[key].type === 'serial') {
                return {
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    cell: (row: Meeting) => {
                        const values = row.custom_fields && row.custom_fields[key];
                        return values ? (values as string[]).join('') : '-';
                    },
                    sortable: true,
                    reorder: true,
                    width: "150px",
                    omit: manageColumns[index + 12] ? manageColumns[index + 12].omit : false,
                };
            }
            return {
                name: customFields[key].name,
                style: { cursor: "pointer" },
                selector: (row: Meeting) => {
                    // Check if row.custom_fields is defined and not null
                    const fieldValue = row.custom_fields && row.custom_fields[key];
                    // Check if fieldValue is an object and not null
                    const displayValue = fieldValue && typeof fieldValue === 'object' && Object.keys(fieldValue).length === 0
                        ? '-'
                        : fieldValue;

                    return (displayValue as string)?.toLowerCase() || '-';
                },
                allowOverflow: true,
                sortable: true,
                width: "150px",
                omit: manageColumns[index + 12] ? manageColumns[index + 12].omit : false,
                cell: (row: Meeting) => {
                    // Check if row.custom_fields is defined and not null
                    const fieldValue = row.custom_fields && row.custom_fields[key];

                    // Check if fieldValue is an object and not null
                    const displayValue = fieldValue && typeof fieldValue === 'object' && Object.keys(fieldValue).length === 0
                        ? '-'
                        : fieldValue;

                    return displayValue || '-';
                },
                reorder: true
            };
        }),
    ];

    let actions: ReactNode[] = [];
    if (user.data.is_allowed_export_excel) {
        actions.push(<DataTableExport source={"Meeting"} columns={manageColumns} />)
    }

    if (user.data.permission_meeting_create !== 'NONE' && !merchantId) {
        if (visit.permission) actions.push(<AddMeeting setStartDate={setStartDate} startDate={startDate} setEndDate={setEndDate} endDate={endDate} setVisit={setVisit} />);
        else actions.push(<UpdateVisitMeeting idMeeting={visit.id} setVisit={setVisit} visit={visit} />)
    }
    actions.push(<FilterMeeting manageColumns={manageColumns} setManageColumns={setManageColumns} />);

    useEffect(() => {
        setManageColumns(tableColumnsWithCustomFields);
    }, [customFields])

    useEffect(() => {
        if (showAddPipelineModal && meeting) {
            const modalElement = document.getElementById(`add-pipeline-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddPipelineModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddPipelineModal, meeting, setShowAddPipelineModal]);

    useEffect(() => {
        if (showAddReimbursementModal && meeting) {
            const modalElement = document.getElementById(`add-reimbursement-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddReimbursementModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddReimbursementModal, meeting, setShowAddReimbursementModal]);

    useEffect(() => {
        if (showAttachmentModal && meeting) {
            const modalElement = document.getElementById(`attachments-meeting-modal-${meeting.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAttachmentModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAttachmentModal, meeting, setShowAttachmentModal]);

    useEffect(() => {
        if (showUpdateStatusModal && meeting) {
            const modalElement = document.getElementById(`update-status-meeting-modal-${meeting.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateStatusModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateStatusModal, meeting, setShowUpdateStatusModal]);

    useEffect(() => {
        if (showUpdateModal && meeting) {
            const modalElement = document.getElementById(`update-meeting-modal-${meeting.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, meeting, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal && meeting) {
            const modalElement = document.getElementById(`delete-meeting-modal-${meeting.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, meeting, setShowDeleteModal]);

    useEffect(() => {
        if (showMapModal && meeting) {
            const modalElement = document.getElementById(`map-meeting-modal-${meeting.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowMapModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showMapModal, meeting, setShowMapModal]);

    useEffect(() => {
        if (showAttachmentCustomFieldModal && meeting) {
            const modalElement = document.getElementById(`attachments-meeting-modal-${meeting.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAttachmentCustomFieldModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAttachmentCustomFieldModal, meeting, setShowAttachmentCustomFieldModal]);

    useEffect(() => {
        if (showDetailModal && meeting) {
            const modalElement = document.getElementById(`detail-meeting-modal-${meeting.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDetailModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDetailModal, meeting, setShowDetailModal]);

    useEffect(() => {
        if (showDetailVisitModal && meeting) {
            const modalElement = document.getElementById(`detail-visit-modal-${meeting.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDetailVisitModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDetailVisitModal, meeting, setShowDetailVisitModal]);

    useEffect(() => {
        if (isMounted) {
            if (startDate && endDate) {
                mutate(`${API_URL}meetings`);
            }
        } else {
            setIsMounted(true);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (settings.meeting_custom_fields) {
            setCustomFields(JSON.parse(settings.meeting_custom_fields))
        }
    }, [settings])

    return (
        <>
            <div className="d-flex justify-content-between mb-5">

                <ol className="breadcrumb text-muted fs-6 fw-bold">
                    <li className="breadcrumb-item text-dark">{settings.meeting_title}</li>
                </ol>
                <DateRangePicker
                    cleanable={false}
                    character=" - "
                    format="dd MMM yyyy"
                    locale={calendarLocale}
                    value={[startDate, endDate]}
                    ranges={predefinedRanges}
                    placement="bottomEnd"
                    onChange={(value) => {
                        if (value && value.length === 2) {
                            setStartDate(value[0]);
                            setEndDate(value[1]);
                        }
                    }}
                    showOneCalendar={isMobile}
                    disabled={loading}
                    isoWeek={true}
                    hidden={merchantId ? true : false}
                />
            </div>

            {
                (user.data.permission_meeting_read !== 'OWNED' && user.data.permission_meeting_read !== 'NONE') && !merchantId &&
                <UserScopeModal
                    user_id={user_id}
                    setUser_id={setUser_id}
                    user_name={user_name}
                    setUser_name={setUser_name}
                    scope={scope} setScope={setScope}
                    permission={user.data.permission_meeting_read}
                    apiURL="meetings"
                />
            }

            <MasterDataTable
                tableKey="meetings"
                tableColumns={tableColumnsWithCustomFields}
                actions={actions}
                apiURL="meetings"
                dateStart={moment(startDate).toISOString(true)}
                dateEnd={moment(endDate).add(1, 'days').toISOString(true)}
                user_id={user_id}
                merchantId={merchantId}
                scope={scope}
                setLoading={setLoading}
                orderBy={5}
                order="desc"
                onRowClicked={onRowClicked}
            />

            {
                showDetailModal && <DetailMeeting data={meeting!} />
            }
            {
                showDetailVisitModal && <DetailVisit data={meeting!} />
            }
            {
                showAddPipelineModal && <AddPipeline meeting={meeting!} merchantId={[meeting?.merchant_id!]} />
            }
            {
                showAddReimbursementModal && <AddReimbursement meeting={meeting!} />
            }
            {
                showAttachmentCustomFieldModal && <MeetingAttachments title={customFields[key].name!} meeting={meeting!} url={url} />
            }
            {
                showUpdateModal && <UpdateMeeting meeting={meeting!} />
            }
            {
                showUpdateStatusModal && <UpdateStatusMeeting meeting={meeting!} />
            }
            {
                showDeleteModal && <DeleteMeeting meeting={meeting!} />
            }
            {
                showMapModal && <MapMeeting latitude={latitudeMap} longitude={longitudeMap} meeting={meeting!} />
            }
            {
                showAttachmentModal && <MeetingAttachments meeting={meeting!} />
            }
        </>
    )
}

export { MasterMeetingsContent }