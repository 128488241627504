import * as ExcelJS from 'exceljs';
import { CustomField } from '../../../interfaces/Settings';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';
import { checkWhatsapp } from '../../../api/Whatsapp';
import { insertMerchantWithTemplate } from '../../../api/MerchantsCRUD';
import useAccessToken from '../../../hooks/AccessToken';
import { mutate } from 'swr';

interface UseHandleImportMerchantProps {
    customFields: CustomField
    setListData: (data: any) => void
    templateMerchantId: string
    dataUsers: any
    dataCities: any
    selectMultiMerchant: any
    dataSuccess: any
    dataError: any
}

const API_URL = process.env.REACT_APP_API_URL

const useHandleImportMerchant = ({ customFields, setListData, templateMerchantId, dataUsers, dataCities, selectMultiMerchant, dataSuccess, dataError }: UseHandleImportMerchantProps) => {
    const intl = useIntl()
    const token = useAccessToken()


    const handleChangeMerchant = (e: any) => {
        setListData([]);
        var check_excel = true;
        var empty_excel = false;
        var success = false;
        const file = e;
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();

        var filteredCustomFieldMerchants = Object.fromEntries(
            Object.entries(customFields).filter(([key, value]) => {
                // Ensure value is an object and has a type property before filtering
                return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial' && value.type !== 'merchant' && value.type !== 'user' && value.type !== 'pipeline';
            })
        );

        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer: any = reader.result;
            wb.xlsx.load(buffer).then((workbook: any) => {
                const newData: any = [];
                var nameWorksheet = workbook.getWorksheet("Sheet1")
                var nameWorksheetInfo = workbook.getWorksheet("Info")
                if (nameWorksheetInfo !== undefined) {
                    nameWorksheetInfo.eachRow(async (rowInfo: any, rowIndexInfo: number) => {
                        if (rowIndexInfo > 3) {
                            if (rowInfo.values[2] === `Template Insert Merchant ${templateMerchantId}` || rowInfo.values[2] === `Template Error Insert Merchant ${templateMerchantId}`) {
                                nameWorksheet?.eachRow((row: any, rowIndex: number) => {
                                    if (rowIndex > 2) {
                                        const rowData = row.values
                                        if (Array.isArray(rowData)) {
                                            var pic: any = dataUsers.find((data: any) => data.name === rowData[2]);
                                            var city: any = dataCities.find((data: any) => data.name === rowData[3]);

                                            const customFieldsStartIndex = 6; // mulai dari kolom ke 6 untuk custom field

                                            const customFieldsData = Object.fromEntries(Object.keys(filteredCustomFieldMerchants).map((key, index) => {
                                                const rowIndex = customFieldsStartIndex + index;
                                                const rowValue = rowData[rowIndex];
                                                const fieldType = filteredCustomFieldMerchants[key].type;
                                                let fieldValue;

                                                if (fieldType === 'image' || fieldType === 'file' || fieldType === 'text' || fieldType === 'link') {
                                                    fieldValue = typeof rowValue === 'object' ? rowValue.text : rowValue;
                                                } else {
                                                    fieldValue = rowValue;
                                                }

                                                return [key, fieldValue];
                                            }));

                                            newData.push({
                                                name: typeof rowData[1] === 'object' ? rowData[1].text ? rowData[1].text : rowData[1].url : rowData[1],
                                                pic_id: pic?.id,
                                                pic_name: pic?.name,
                                                city_id: city?.id,
                                                city_name: city?.name,
                                                phone: typeof rowData[4] === 'object' ? rowData[4].text ? rowData[4].text : rowData[4].url : rowData[4],
                                                email: typeof rowData[5] === 'object' ? rowData[5].text ? rowData[5].text : rowData[5].url : rowData[5],
                                                ...customFieldsData,
                                            })

                                            check_excel = true
                                            success = true
                                            empty_excel = false
                                        }
                                    } else {
                                        empty_excel = true
                                        return false
                                    }
                                });
                            } else {
                                check_excel = false
                                return false
                            }
                        }
                    })
                } else {
                    check_excel = false
                }
                if (empty_excel) {
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({ id: 'FORM.VALIDATION.FILL_DATA_FIRST' }),
                        confirmButtonText: 'Ok'
                    });
                }

                if (!check_excel) {
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({ id: 'FORM.VALIDATION.EXCEL_TEMPLATE_NOT_MATCHING' }),
                        confirmButtonText: 'Ok'
                    });
                }
                if (success) setListData(newData);
            });
        };
    };

    const handleInsertMerchant = async (data: any) => {
        const responses = await Promise.all([checkWhatsapp(data.phone)]);
        const isWhatsappPhone = responses[0].data.result.exists;
        const customFieldsValues: Record<string, string> = Object.keys(customFields).reduce(
            (obj, item) => ({
                ...obj,
                [item]: (data as Record<string, string>)[item] ? (data as Record<string, string>)[item] : null,
            }),
            {}
        );

        const response = await insertMerchantWithTemplate(
            selectMultiMerchant.id,
            [],
            data.pic_id,
            data.name,
            data.city_id,
            data.phone ? data.phone.toString().replace("+", "") : "",
            isWhatsappPhone,
            token,
            data.email ? data.email : null,
            customFieldsValues
        );

        if (response.data.success) {
            mutate(`${API_URL}merchants`)
            dataSuccess.push({
                ...response.data.data,
                lastMeetEnd: "-",
                lastLeadCreatedTime: "-",
                dateCreated: response.data.data.dateCreated
            })
        } else {
            // var mergeError = { ...data, error: response.data.message }
            // dataError.push(mergeError)
        }
    }

    return {
        handleChangeMerchant,
        handleInsertMerchant
    }
}

export default useHandleImportMerchant