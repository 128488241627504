import { create } from 'zustand';
import { User } from '../interfaces/User';

type UserState = {
    users: User[];
    userSubordinates: User[];
    userLoading: boolean;
    userSubordinateLoading: boolean;
    setUsers: (users: User[]) => void;
    setUserSubordinates: (userSubordinates: User[]) => void;
    setUserLoading: (userLoading: boolean) => void;
    setUserSubordinateLoading: (userSubordinateLoading: boolean) => void;
}

export const useUserStore = create<UserState>((set, getState) => ({
    users: [],
    userSubordinates: [],
    userLoading: false,
    userSubordinateLoading: false,

    setUsers: (users) => {
        set({ users });
    },
    setUserSubordinates: (userSubordinates) => {
        set({ userSubordinates });
    },
    setUserLoading: (userLoading) => {
        set({ userLoading });
    },
    setUserSubordinateLoading: (userSubordinateLoading) => {
        set({ userSubordinateLoading });
    },
}));