import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

const ADD_PROGRESS_SETTINGS = `${API_URL}role-lead-progress-accesses`
const DELETE_PROGRESS_SETTINGS = `${API_URL}role-lead-progress-accesses`

const addProgressSettings = async (roleId: string, leadProgressIds: string[], accessType: string, token: string) => {

    //convert 'null' to null value
    const processedLeadProgressIds = leadProgressIds.map((id: string) => id === 'new' ? null : id)

    return axios.put(ADD_PROGRESS_SETTINGS, {
        roleId,
        leadProgressIds: processedLeadProgressIds,
        accessType
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

const deleteProgressSettings = async (progressSettingsId: string, token: string) => {
    return axios.delete(DELETE_PROGRESS_SETTINGS + '/' + progressSettingsId, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export { addProgressSettings, deleteProgressSettings }