import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const confirmOTP = async (code: string, otp: string) => {
    return await axios.post(`${API_URL}confirm-login`, { code, otp })
}

export const resendOTP = async (code: string) => {
    return await axios.post(`${API_URL}resend-login-otp`, { code })
}

export const sendOTP = async (username: string, password: string) => {
    return await axios.post(`${API_URL}user-send-otp`, { username, password })
}