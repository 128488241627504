import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { UserDataType } from '../interfaces/Email';
interface UserStore {
  userEmail: UserDataType | null;
  setUserEmail: (userData: UserDataType) => void;
  clearUserEmail: () => void;
}

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      userEmail: null,
      setUserEmail: (userData) => set({ userEmail: userData }),
      clearUserEmail: () => {
        localStorage.removeItem('user-storage')
        set({ userEmail: null })
      },
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
)