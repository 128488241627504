import Skeleton from 'react-loading-skeleton'
import useNameWidget from '../../hooks/NameWidget'
import moment from "moment";
import { KTSVG } from '../../_metronic/helpers';
import { customNumberFormat, roundNominal } from '../../functions/general';

type Props = {
    className: string
    color: string
    icon: string
    title?: string
    description: string
    progress: string
    isLoading: boolean
    progress2?: string
    date?: string
}

const DashboardWidget: React.FC<Props> = ({ className, color, icon, title, description, progress, isLoading, progress2, date }) => {
    const nameWidget = description;
    const val1 = parseInt(progress.replace(/[^0-9-]/g, ''), 10); // Izinkan negatif
    const val2 = progress2 ? parseInt(progress2.replace(/[^0-9-]/g, ''), 10) : 0; // Izinkan negatif

    let percentage: number;
    if (val2 === 0) percentage = val1 === 0 ? 0 : 100; // Jika val1 dan val2 sama-sama nol
    else percentage = Math.round(((val1 - val2) / Math.abs(val2)) * 100);

    // Tangani kasus khusus untuk hasil tidak valid
    if (!Number.isFinite(percentage) || Number.isNaN(percentage)) {
        percentage = 0; // Default fallback
    }

    return (
        <div className={`${className} card-dashboard`} style={{ position: "relative", backgroundColor: color }}>
            {/* <img src="/media/svg/shapes/abstract-1.svg" alt="" style={{position: "absolute", right: "0", mixBlendMode: "soft-light", minInlineSize: "-webkit-fill-available"}} /> */}
            {/* begin::Body */}
            <div className='card-body my-3 mx-3'>
                {
                    date && icon ?
                        <>
                            <div className='d-flex justify-content-between'>
                                <span className={`d-flex card-title text-light-${color} fs-5 d-block gap-3`}>
                                    {nameWidget}
                                    {
                                        isLoading ?
                                            <span style={{ width: "50px" }}><Skeleton /></span>
                                            :
                                            percentage > 0 ?
                                                <span className="badge badge-success fs-6 py-1 px-2 d-flex flex-center" style={{ height: 'max-content' }}>
                                                    <KTSVG path={'/media/icons/duotune/arrows/arr067.svg'} className='svg-icon-7 svg-icon-white me-1' />
                                                    {customNumberFormat(percentage)}%
                                                </span>
                                                : percentage < 0 ?
                                                    <span className="badge badge-danger fs-6 py-1 px-2 d-flex flex-center" style={{ height: 'max-content' }}>
                                                        <KTSVG path={'/media/icons/duotune/arrows/arr068.svg'} className='svg-icon-7 svg-icon-white me-1' />
                                                        {customNumberFormat(Math.abs(percentage))}%
                                                    </span>
                                                    : <></>

                                    }
                                </span>
                                <div className="icon-container text-center p-2">
                                    <i className={`${icon} fs-1 text-white`}></i>
                                    <img src={`${icon}`} />
                                </div>
                            </div>
                            <div className='d-flex' style={{ gap: '50px' }}>
                                <div className='d-grid'>
                                    {date}
                                    <span className='text-white fs-1 me-2' style={{ fontWeight: "700" }}>{isLoading ? <Skeleton /> : roundNominal(progress)}</span>
                                </div>
                                {
                                    progress2 &&
                                    <div className='d-grid gap-1'>
                                        {moment(date, 'MMM YYYY').subtract(1, 'month').format('MMM YYYY')}
                                        <span className='text-white fs-3 me-2'>{isLoading ? <Skeleton /> : roundNominal(progress2)}</span>
                                    </div>
                                }
                            </div>
                        </>
                        :
                        <div className='d-grid text-center'>
                            <span className={`card-title text-light-${color} fs-5`}>
                                {nameWidget}
                            </span>
                            <span className='text-white fs-1 fw-bolder me-2'>{isLoading ? <Skeleton /> : roundNominal(progress)}</span>
                        </div>

                }


                <span className='fw-bold text-muted fs-7'>{title}</span>
            </div>
            {/* end:: Body */}
        </div>
    )
}

export { DashboardWidget }