import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill';
import Select, { SelectInstance } from "react-select";
import { KTSVG } from '../../../_metronic/helpers';
import useSWR from 'swr';
import { createBroadcastEmail, createDraftBroadcastEmail, deleteDraftBroadcastEmail, getCheckColumnCustomFields, getGroupContactEmail, getVariableCustome, updateBroadcastEmail, updateDraftBroadcastEmail } from '../../../api/EmailCRUD';
import { GroupEmailTypes, ContentType, ContentTemplateAttachment } from '../../../interfaces/Email/BroadCastEmailType';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useEmailStore } from '../../../stores/EmailStore';
import AddVariableDropdown from './AddVariableDropdown';
import AddModalSetting, { SettingsBroadcastEmail } from './addModal/AddModalSetting';
import { useAttachment } from '../hooks/useAttachment';
import { formatFileSize, getBase64, getNextContentCharCode } from '../../../functions/email';
import DragAndDropFileBc from './FileUploader/DragAndDropFileBc';
import { ICreateBroadcastEmail, ICreateDraftBroadcastEmail, IUpdateBroadcastEmail, IUpdateDraftBroadcastEmail } from '../../../interfaces/Email/InputEmailTypes';
import { useUserStore } from '../../../hooks/UserEmail';
import { useImagePasteBroadcast } from '../hooks/useImagePasteBroadcast';
import ModalInsertUrl from '../ModalInsertUrl';
import useModalEffect from '../../../hooks/useModalEffect';

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['emoji'],
    ],
    'emoji-toolbar': true,
    'emoji-shortname': true,
}

const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'link', 'image', 'emoji', 'list'];

interface SelectType {
    label: string
    value: string
}


const BroadcastEmailCore = () => {
    const [groupEmails, setGroupEmails] = useState<SelectType[]>([])
    const [selectGroupId, setSelectGroupId] = useState('')
    const [contentTemplates, setContentTemplates] = useState<ContentType[]>([{ content: String.fromCharCode(65), subject: '', message: '', attachment: [] }])
    const [activeTemplateIndex, setActiveTemplateIndex] = useState(0)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isCheckCustomField, setIsCheckCustomField] = useState(false)
    const selectInputRef = useRef<SelectInstance<SelectType | null>>(null)
    const [settings, setSettings] = useState<SettingsBroadcastEmail>({
        sendTimeOption: 'Segera',
        customDate: null,
        interval: 0,
    })
    const { setTypeEmail, setTypeMenu, setActiveSidebar, dataEditValuesBroadcast, typeEditBroadcast, setTypeEditBroadcast } = useEmailStore()
    const [listVariables, setListVariables] = useState<string[]>([])
    const { userEmail } = useUserStore()
    const [isLoading, setIsLoading] = useState(false)
    const quillRef = useRef<ReactQuill>(null)
    const [isInsertUrl, setIsInsertUrl] = useState(false)
    const [valueInsertUrl, setValueInsertUrl] = useState('')

    const { data: listGroup = [], isLoading: isLoadingGroup } = useSWR<GroupEmailTypes[]>(`${API_URL_EMAIL}groupcontact/list-group?userId=${userEmail?.id}`, getGroupContactEmail)

    const {
        temporaryCopyFile,
        setTemporaryCopyFile,
        dragAndDropFile,
        setDragAndDropFile,
        totalFileSize,
        setTotalFileSize,
        showModal,
        handleOpenAttachment,
        handleCloseModal,
        onDrop,
        removeFile,
        uploadAttachments,
        attachments,
        setAttachments,
    } = useAttachment();

    const { handlePaste, checkFileSizeLimit } = useImagePasteBroadcast({
        quillRef,
        setTemporaryCopyFile,
        setTotalFileSize,
        totalFileSize,
        showModal,
        activeTemplateIndex
    });



    const fetchCustomVariables = async () => {
        try {
            const response = await getVariableCustome(`${API_URL_EMAIL}groupcontact/variable-data-group-contact?idGroupContact=${selectGroupId}`)
            if (response) {
                setListVariables(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchGetCheckCustomField = async () => {
        try {
            const response = await getCheckColumnCustomFields(`${API_URL_EMAIL}groupcontact/check-data-group-contact?idGroupContact=${selectGroupId}`)
            if (response?.data.success === false) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: response?.data?.message || 'Terdapat data user yang tidak memiliki custom field!',
                })
            }
        } catch (error: any) {
            setIsCheckCustomField(true)
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response?.data?.message || 'An error occurred',
                confirmButtonText: 'Ok',
            })
        }
    }

    useEffect(() => {
        if (selectGroupId) {
            fetchGetCheckCustomField()
            fetchCustomVariables()
        }
    }, [selectGroupId])

    useEffect(() => {
        if (listGroup && listGroup.length > 0) {
            const newValues = listGroup.map((item) => ({
                label: `${item.name} (${item.userDataCount} Email)`,
                value: item.id
            }))
            setGroupEmails(newValues)
        }
    }, [listGroup])

    // efect untuk setTemplate 
    useEffect(() => {
        if (dataEditValuesBroadcast) {
            setContentTemplates(prevTemplates => {
                // hanya update jika data berbeda
                if (JSON.stringify(prevTemplates) !== JSON.stringify(dataEditValuesBroadcast.content)) {
                    return dataEditValuesBroadcast.content;
                }
                return prevTemplates;
            });

            setSettings({
                sendTimeOption: 'Custom',
                customDate: new Date(dataEditValuesBroadcast.schedule),
                interval: dataEditValuesBroadcast.interval
            });

            setSelectGroupId(dataEditValuesBroadcast.groupContactId);

            // Set existing attachments
            const existingAttachments = dataEditValuesBroadcast.content.map(content => content.attachment || []);
            setAttachments(existingAttachments as (File | ContentTemplateAttachment)[][]);
            setTemporaryCopyFile(existingAttachments as (File | ContentTemplateAttachment)[][]);
        }
    }, [dataEditValuesBroadcast]);

    // Handle template changes
    const handleTemplateChange = useCallback((index: number, field: keyof ContentType, value: string) => {
        setContentTemplates(prevTemplates => {
            const newTemplates = [...prevTemplates];
            newTemplates[index] = {
                ...newTemplates[index],
                [field]: value
            };
            return newTemplates;
        });
    }, []);

    const handleChangeMessage = useCallback((value: string) => {
        console.log(value, 'value')
        if (value.trim() !== "<p><br></p>") {
            handleTemplateChange(activeTemplateIndex, 'message', value);
        }
    }, [activeTemplateIndex, handleTemplateChange]);

    const handleChangeSubject = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        handleTemplateChange(activeTemplateIndex, 'subject', e.target.value);
    }, [activeTemplateIndex, handleTemplateChange]);

    const addContentTemplate = useCallback(() => {
        if (contentTemplates.length < 5) {
            setContentTemplates(prevTemplates => [...prevTemplates, { content: getNextContentCharCode(prevTemplates[prevTemplates.length - 1].content), subject: '', message: '', attachment: [] }]);
            setAttachments(prevAttachments => [...prevAttachments, []]);
            setActiveTemplateIndex(prevIndex => prevIndex + 1);
        }
    }, [contentTemplates.length]);

    const removeContentTemplate = useCallback((index: number) => {
        if (contentTemplates.length > 1) {
            setContentTemplates(prevTemplates => prevTemplates.filter((_, i) => i !== index));
            setAttachments(prevAttachments => prevAttachments.filter((_, i) => i !== index));
            setActiveTemplateIndex(prevIndex => Math.min(prevIndex, contentTemplates.length - 2));
        }
    }, [contentTemplates.length]);

    const checkDateBeforeBroadcast = () => {
        if (settings.sendTimeOption === 'Custom') {
            if (settings.customDate && settings.customDate < new Date()) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: 'Tanggal broadcast sudah melewati waktu yang ditentukan',
                })
                return false
            }
        }
        return true
    }

    const formik = useFormik({
        initialValues: {
            broadcastName: dataEditValuesBroadcast?.broadcastName || '',
            groupContactId: dataEditValuesBroadcast?.groupContactId || '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)
                const uploadedAttachments = await uploadAttachments();

                const updatedContentTemplates = contentTemplates.map((template, index) => ({
                    ...template,
                    attachment: uploadedAttachments[index] || []
                }));

                const newValues: ICreateBroadcastEmail = {
                    userId: userEmail?.id || '',
                    groupContactId: values.groupContactId,
                    broadcastName: values.broadcastName,
                    content: updatedContentTemplates,
                    interval: settings.interval ? settings.interval : 0,
                    schedule: settings.sendTimeOption === 'Segera' ? new Date().toISOString() : settings.customDate?.toISOString() || new Date().toISOString(),
                }
                const response = await createBroadcastEmail(newValues)
                if (!checkDateBeforeBroadcast()) return;

                if (response) {
                    if (typeEditBroadcast === 'draftBroadcastEmail') {
                        await deleteDraftBroadcastEmail(dataEditValuesBroadcast?.id || '')
                    }
                    Swal.fire({
                        icon: 'success',
                        title: 'Broadcast Email Berhasil',
                        confirmButtonText: 'Ok',
                    })
                    setTypeEmail('broadcastEmail')
                    setTypeMenu('broadcastEmail')
                    setActiveSidebar({
                        inbox: '',
                        sent: '',
                        draft: '',
                        trash: '',
                        archive: '',
                        spam: '',
                        groupEmail: '',
                        broadcastEmail: 'active'
                    })
                    setTypeEditBroadcast('')
                }
            } catch (error) {
                console.error(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Broadcast Email Gagal',
                    text: 'Terjadi kesalahan saat mengirim broadcast email.',
                    confirmButtonText: 'Ok',
                })
            } finally {
                setSubmitting(false)
            }
        }
    })

    const handleSaveBroadcast = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            const uploadedAttachments = await uploadAttachments();

            const updatedContentTemplates = contentTemplates.map((template, index) => ({
                ...template,
                attachment: uploadedAttachments[index] || []
            }));

            const valuesUpdate: IUpdateBroadcastEmail = {
                broadcastId: dataEditValuesBroadcast?.id || '',
                broadcastName: formik.values.broadcastName,
                content: updatedContentTemplates,
                interval: settings.interval ? settings.interval : 0,
                schedule: settings.sendTimeOption === 'Segera' ? new Date().toISOString() : settings.customDate?.toISOString() || new Date().toISOString(),
            }

            const valuesUpdateDraft: IUpdateDraftBroadcastEmail = {
                id: dataEditValuesBroadcast?.id || '',
                dataDraft: {
                    broadcastName: formik.values.broadcastName,
                    content: updatedContentTemplates,
                    interval: settings.interval ? settings.interval : 0,
                    schedule: settings.sendTimeOption === 'Segera' ? new Date().toISOString() : settings.customDate?.toISOString() || new Date().toISOString(),
                    groupContactId: formik.values.groupContactId,
                },
            }
            if (typeEditBroadcast === 'broadcastEmail') {
                const response = await updateBroadcastEmail(valuesUpdate);
                if (response) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Broadcast berhasil diubah',
                    });
                    setTypeEmail('broadcastEmail');
                    setTypeMenu('broadcastEmail');
                    setActiveSidebar({
                        inbox: '',
                        sent: '',
                        draft: '',
                        trash: '',
                        archive: '',
                        spam: '',
                        groupEmail: '',
                        broadcastEmail: 'active'
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Broadcast Gagal diubah',
                    })
                }
            } else if (typeEditBroadcast === 'draftBroadcastEmail') {
                const response = await updateDraftBroadcastEmail(valuesUpdateDraft)
                if (response) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Draft berhasil diubah',
                    })
                    setTypeEmail('broadcastEmail');
                    setTypeMenu('broadcastEmail');
                    setActiveSidebar({
                        inbox: '',
                        sent: '',
                        draft: '',
                        trash: '',
                        archive: '',
                        spam: '',
                        groupEmail: '',
                        broadcastEmail: 'active'
                    });
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    };

    const getImagesFormEditor = document.querySelectorAll('.ql-editor img') as NodeListOf<HTMLImageElement>
    getImagesFormEditor.forEach((img) => {
        img.style.maxWidth = '120px'
    })

    useEffect(() => {
        const editor = quillRef.current?.getEditor()
        if (editor) {
            editor.root.addEventListener('paste', handlePaste)
            return () => {
                editor.root.removeEventListener('paste', handlePaste)
            }
        }
    }, [handlePaste])

    useEffect(() => {
        checkFileSizeLimit(temporaryCopyFile as (File | ContentTemplateAttachment)[][]);
    }, [totalFileSize, showModal, checkFileSizeLimit, temporaryCopyFile]);


    const handleSaveDraft = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            const uploadedAttachments = await uploadAttachments();

            const updatedContentTemplates = contentTemplates.map((template, index) => ({
                ...template,
                attachment: uploadedAttachments[index] || []
            }));

            const newValues: ICreateDraftBroadcastEmail = {
                dataDraft: {
                    broadcastName: formik.values.broadcastName,
                    groupContactId: formik.values.groupContactId,
                    content: updatedContentTemplates,
                    interval: settings.interval ? settings.interval : 0,
                    schedule: settings.sendTimeOption === 'Segera' ? new Date().toISOString() : settings.customDate?.toISOString() || new Date().toISOString(),
                },
                userId: userEmail?.id || '',
            }

            const response = await createDraftBroadcastEmail(newValues)
            if (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Save Draft Email Berhasil',
                })
                setTypeEmail('broadcastEmail');
                setTypeMenu('broadcastEmail');
                setActiveSidebar({
                    inbox: '',
                    sent: '',
                    draft: '',
                    trash: '',
                    archive: '',
                    spam: '',
                    groupEmail: '',
                    broadcastEmail: 'active'
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Save Draft Email Gagal',
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleInsertVariable = (variable: string) => {
        if (quillRef.current) {
            const editor = quillRef.current.getEditor()
            const range = editor.getSelection(true)
            editor.insertText(range.index, `[${variable}]`, 'user')
        }
    }

    const handleResetForm = () => {
        Swal.fire({
            icon: 'warning',
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin ingin reset semua form?',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Ya, reset',
        }).then((result) => {
            if (result.isConfirmed) {
                formik.resetForm()
                setContentTemplates([{ content: String.fromCharCode(65), subject: '', message: '', attachment: [] }])
                setActiveTemplateIndex(0)
                selectInputRef.current?.clearValue()
            }
        })
    }

    const handleOpenAttachmentForTemplate = (index: number) => {
        setActiveTemplateIndex(index);
        handleOpenAttachment(index);
    };

    const handleSaveAttachments = () => {
        const newAttachment = [...dragAndDropFile];
        setAttachments((prevAttachments) => {
            const updatedAttachments = [...prevAttachments];
            updatedAttachments[activeTemplateIndex] = newAttachment;
            return updatedAttachments;
        });
        handleCloseModal();
    };

    useEffect(() => {
        const isValid = formik.values.groupContactId !== '' &&
            formik.values.broadcastName !== '' &&
            contentTemplates.every(template => template.subject !== '' && template.message !== '')
        setIsDisabled(!isValid)
    }, [formik.values, contentTemplates])

    useEffect(() => {
        if (contentTemplates.length > 0 && activeTemplateIndex >= contentTemplates.length) {
            setActiveTemplateIndex(contentTemplates.length - 1);
        }
    }, [contentTemplates]);

    useModalEffect({
        showModal: isInsertUrl,
        setShowModal: setIsInsertUrl,
        modalId: 'insert-url-modal'
    })


    let actions: ReactNode[] = []
    actions.push(<AddModalSetting settings={settings} setSettings={setSettings} />)

    return (
        <>
            <div className='p-2 position-relative'>
                <div className='d-flex align-items-center justify-content-between border-bottom min-h-50px px-8'>
                    <label className='text-dark fw-bolder' style={{ minWidth: '110px' }}>Penerima Broadcast</label>
                    <span className='d-flex align-items-center gap-3' style={{ minWidth: '950px' }}>
                        <Select
                            ref={selectInputRef}
                            id="groupContactId"
                            name="groupContactId"
                            className="w-100"
                            value={groupEmails.find((option) => option.value === selectGroupId)}
                            options={isLoadingGroup ? [] : groupEmails}
                            onChange={(option) => {
                                formik.setFieldValue('groupContactId', option?.value || '')
                                setSelectGroupId(option?.value || '')
                            }}
                            isLoading={isLoadingGroup}
                            isDisabled={dataEditValuesBroadcast ? true : false}
                        />
                        {actions.map((action, index) => (
                            <React.Fragment key={index}>{action}</React.Fragment>
                        ))}
                    </span>
                </div>
                <div className='border-bottom'>
                    <input
                        id="broadcastName"
                        name="broadcastName"
                        autoComplete='off'
                        className='form-control form-control-transparent border-0 px-8 min-h-45px'
                        placeholder='Nama Broadcast (Wajib)'
                        onChange={formik.handleChange}
                        value={formik.values.broadcastName}
                    />
                </div>
                <div className='d-flex justify-content-between align-items-start'>
                    <div className="pt-4 px-4 rounded mb-5" style={{ width: '17%', height: '300px' }}>
                        <div id="containerBtnContent">
                            {contentTemplates.map((_, index) => {
                                return (
                                    <button
                                        key={index}
                                        type="button"
                                        className={`btn w-100 mb-4 btn-light-primary ${index === activeTemplateIndex ? 'active' : ''} text-start`}
                                        onClick={() => setActiveTemplateIndex(index)}
                                    >
                                        {
                                            index > 0 ? (
                                                <>
                                                    <span className="fs-6 text-capitalize">Konten {contentTemplates[index].content}</span>
                                                    <span className="float-end" onClick={(e) => { e.stopPropagation(); removeContentTemplate(index); }}>
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                </>
                                            ) : (
                                                <span className="fs-6 text-capitalize">Konten {contentTemplates[index].content}</span>
                                            )
                                        }
                                    </button>
                                )
                            })}
                        </div>
                        {contentTemplates.length < 5 && (
                            <button type="button" className="btn w-100 mb-4 bg-light-primary bg-hover-primary text-hover-white" onClick={addContentTemplate}>
                                <i className="fas fa-plus-circle fs-2 text-primary"></i>
                            </button>
                        )}
                    </div>
                    <div className=' p-0 border-bottom border-top-0 border-right-0 border-left-0 pb-3' style={{ width: '82%' }}>
                        <div className='border-bottom' style={{ border: '1px solid #C0C0C0' }}>
                            <input
                                id="subject"
                                name="subject"
                                autoComplete='off'
                                className='form-control form-control-transparent border-0 px-8 min-h-45px'
                                placeholder='Subject'
                                onChange={handleChangeSubject}
                                value={contentTemplates[activeTemplateIndex]?.subject || dataEditValuesBroadcast?.content[activeTemplateIndex]?.subject || ''}
                            />
                        </div>
                        <div id='kt_inbox_form_editor' className='bg-transparent border-0 h-300px' style={{ position: 'relative' }}>
                            <ReactQuill
                                ref={quillRef}
                                style={{ height: '85%' }}
                                theme='snow'
                                modules={modules}
                                formats={formats}
                                onChange={handleChangeMessage}
                                value={contentTemplates[activeTemplateIndex]?.message || dataEditValuesBroadcast?.content[activeTemplateIndex]?.message || ''}
                            />
                            {selectGroupId && (
                                <div className='position-absolute top-0 end-0 me-4' style={{ marginTop: '2px' }}>
                                    <div style={{ width: '150px' }}>
                                        <AddVariableDropdown
                                            VariableColumns={listVariables}
                                            onVariableClick={handleInsertVariable}
                                        />
                                    </div>
                                </div>
                            )}
                            {/* <button
                                className='btn btn-sm btn-light-primary position-absolute top-0'
                                style={{ right: '170px', marginTop: '7px', padding: '5px 10px' }}
                                onClick={() => setIsInsertUrl(!isInsertUrl)}
                            >Insert Url</button> */}
                            {showModal && (
                                <DragAndDropFileBc
                                    onDrop={onDrop}
                                    onClose={handleCloseModal}
                                    values={attachments[activeTemplateIndex] || []}
                                    totalFileSize={totalFileSize}
                                    setTotalFileSize={setTotalFileSize}
                                    valuesDragAndDropFile={dragAndDropFile}
                                    setTemporaryFile={setTemporaryCopyFile}
                                    setValuesDragAndDropFile={setDragAndDropFile}
                                    onSave={handleSaveAttachments}
                                    activeTemplateIndex={activeTemplateIndex}
                                />
                            )}
                        </div>
                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {attachments[activeTemplateIndex]?.map((file: File | ContentTemplateAttachment, index) => {
                                const fileName = file instanceof File ? file.name : file.name;
                                const fileSize = file instanceof File ? file.size : file.size;
                                return (
                                    <div className='dropzone dropzone-queue px-8 py-1' key={index} >
                                        <div
                                            className='dropzone-items'
                                            style={{ padding: '7px', borderRadius: '5px', backgroundColor: '#f1faff' }}
                                        >
                                            <div
                                                className='dropzone-item dz-processing dz-success dz-complete d-flex justify-content-between'
                                                id=''
                                                style={{ alignItems: 'center' }}
                                            >
                                                <div className='dropzone-file'>
                                                    <div
                                                        className='dropzone-filename'
                                                        title={fileName}
                                                        style={{ color: '#7e8299' }}
                                                    >
                                                        <span data-dz-name=''>{fileName}</span>
                                                        <strong> ({formatFileSize(fileSize)})</strong>
                                                    </div>
                                                    <div className='dropzone-error' data-dz-errormessage=''></div>
                                                </div>
                                                <div className='dropzone-toolbar'>
                                                    <span
                                                        className='dropzone-delete cursor-pointer'
                                                        onClick={() => removeFile(activeTemplateIndex, index)}
                                                    >
                                                        <i className='bi bi-x fs-1'></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-stack gap-2 py-5 ps-8 pe-5 border-top'>
                    <div className='d-flex align-items-center me-3'>
                        {
                            dataEditValuesBroadcast ? (
                                <>
                                    <button className='btn btn-sm px-4 me-2 button-hover' disabled={isLoading} onClick={(e) => handleSaveBroadcast(e)}>
                                        <span className='svg-icon svg-icon-2'>
                                            <KTSVG path='/media/svg/general/draft.svg' />
                                        </span>
                                        <span className='indicator-label ms-2 d-md-inline-block'>Simpan Broadcast</span>
                                        <span className='indicator-progress'>
                                            Loading...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    </button>
                                    <div
                                        style={{ width: '1px', height: '35px', backgroundColor: '#ccc', margin: '0 10px' }}
                                    ></div>
                                </>
                            ) : (
                                <>
                                    <button className='btn btn-sm px-4 me-2 button-hover' disabled={isLoading} onClick={(e) => handleSaveDraft(e)}>
                                        <span className='svg-icon svg-icon-2'>
                                            <KTSVG path='/media/svg/general/draft.svg' />
                                        </span>
                                        <span className='indicator-label ms-2 d-md-inline-block'>Simpan Sebagai Draft</span>
                                        <span className='indicator-progress'>
                                            Loading...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    </button>
                                    <div
                                        style={{ width: '1px', height: '35px', backgroundColor: '#ccc', margin: '0 10px' }}
                                    ></div>
                                </>
                            )
                        }
                        <span className='btn btn-sm  px-4 me-2 button-hover' onClick={() => handleOpenAttachmentForTemplate(activeTemplateIndex)}>
                            <span className='svg-icon svg-icon-2'>
                                <KTSVG path='/media/svg/general/attachment.svg' />
                            </span>
                            <span className='ms-2 d-none d-md-inline-block'>Lampiran</span>
                        </span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='cursor-pointer' onClick={handleResetForm}>
                            <h5 className='my-auto'>Batal</h5>
                        </span>
                        <div
                            style={{ width: '1px', height: '35px', backgroundColor: '#ccc', margin: '0 10px' }}
                        ></div>
                        <div className='btn-group me-4'>
                            <button
                                className={`btn btn-primary fs-bold px-6 ${formik.isSubmitting ? 'disabled' : ''}`}
                                data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}
                                disabled={formik.isSubmitting || isDisabled || isCheckCustomField}
                                onClick={() => formik.handleSubmit()}
                            >
                                <span className='svg-icon svg-icon-2'>
                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' />
                                </span>
                                <span className='indicator-label'>Broadcast</span>
                                <span className='indicator-progress'>
                                    Loading...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* {
                isInsertUrl && (
                    <ModalInsertUrl
                        setValueInsertUrl={setValueInsertUrl}
                        editorRef={quillRef}
                        valueInsertUrl={valueInsertUrl}
                        onClose={() => {
                            setIsInsertUrl(false)
                            setValueInsertUrl('') // Reset value saat modal ditutup
                        }}
                    />
                )
            } */}
        </>
    )
}

export default BroadcastEmailCore