import { UserModel } from "../../app/modules/auth/models/UserModel";
import { AccountReceivableType } from '../../interfaces/AccountReceivable';
import { TableColumn } from "react-data-table-component";
import ExcelJS from 'exceljs';
import { customNumberFormat } from "../../functions/general";

const styleHeader = (worksheet: ExcelJS.Worksheet, rowIndex: number, color: string = 'F6991D', isSubHeader: boolean = false) => {
    worksheet.getRow(rowIndex).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheet.getRow(rowIndex).eachCell(function (cell) {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: color }
        };
    });
    worksheet.getRow(rowIndex).font = {
        name: 'Arial',
        size: isSubHeader ? 8 : 10,  // Ukuran font lebih kecil untuk subHeader
        bold: !isSubHeader,  // Bold hanya untuk header utama
    };
};

export const exportExcelAccountReceivable = async (
    nameFile: string,
    source: string,
    nameHeader: {
        export: {
            accountReceivable: {
                header: string[];
                subHeader: string[];
            }
        }
    },
    data: any[],
    dataCustomField: any
) => {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Account Receivable');

        if (nameFile === 'Sewa Permanen') {
            const headers = ['No Invoice', 'Nama Tenant', 'No Unit', 'Keterangan', 'Telepon', 'Nominal Termin', 'Nominal Kontrak', 'Produk', 'Sumber', 'Jangka Waktu Sewa', 'Periode Start Sewa', 'Periode Berakhir Sewa', 'Nilai Jaminan', 'Attachment Doc Inv']
            const subHeaders = ['No Invoice', 'Nama Tenant', 'Custom Field - No Unit', 'Keterangan', 'Telepon', 'Nominal Termin', 'Nominal Pipeline', 'Produk', 'Sumber', 'Custom Field - Jangka Waktu Sewa', 'Custom Field - Periode Start Sewa', 'Custom Field - Periode Berakhir Sewa', 'Custom Field - Nilai Jaminan', 'Attachment Doc Inv']

            worksheet.addRow(headers);
            worksheet.addRow(subHeaders);

            styleHeader(worksheet, 1, 'F6991D', false);
            styleHeader(worksheet, 2, 'FFE699', true);

            let totalTerminNominal = 0;
            let totalLeadNominal = 0;

            data.forEach(item => {
                const row = worksheet.addRow([
                    item['No. Invoice'] || '-',
                    item.merchantName || '-', // Merchant Name
                    item['No Unit'] || '-',
                    item.terminDescription || '-',        // Keterangan
                    item.merchantPhone || '-',            // Telepon
                    Math.ceil(item.terminNominal || 0),             // Nominal Termin
                    Math.ceil(item.leadNominal || 0),               // Nominal Kontrak
                    item.leadName || '-',                // Produk
                    item.leadSourceName || '-',          // Sumber
                    item['Jangka Waktu Sewa'] || '-',
                    item['Periode Start Sewa'] || '-',
                    item['Periode Berakhir Sewa'] || '-',
                    item['Nilai Jaminan'] || '-',
                    item['Attachment Doc Inv'] || '-'
                ]);

                totalTerminNominal += Math.ceil(item.terminNominal || 0);
                totalLeadNominal += Math.ceil(item.leadNominal || 0);

                row.eachCell((cell, colNumber) => {
                    cell.alignment = { vertical: 'middle', wrapText: true };

                    // Format numbers untuk kolom nominal
                    if (colNumber === 3 || colNumber === 4) {
                        cell.numFmt = '#,##0;[Red]-#,##0';
                        cell.alignment.horizontal = 'right';
                    }
                });
            });

            const totalRow = worksheet.addRow([ // untuk total row
                'Total',
                '',
                '',
                '',
                '',
                totalTerminNominal,
                totalLeadNominal,
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ]);

            totalRow.font = { bold: true };
            totalRow.getCell(3).numFmt = '#,##0;[Red]-#,##0';
            totalRow.getCell(4).numFmt = '#,##0;[Red]-#,##0';

            // Set column widths
            worksheet.columns = [
                { width: 30 },  // Keterangan
                { width: 15 },  // Telepon
                { width: 20 },  // Nominal Termin
                { width: 20 },  // Nominal Kontrak
                { width: 20 },  // Produk
                { width: 15 },  // Sumber
                { width: 25 },  // Nama Merchant
                { width: 20 },  // Template Merchant
                { width: 15 },  // Jangka Waktu
                { width: 15 },  // Nilai Jaminan
                { width: 15 },  // No Unit
                { width: 15 },  // Periode Mulai
                { width: 15 },  // Periode Akhir
                { width: 15 },  // No Invoice
            ];
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${nameFile}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        } else if (nameFile === 'Sewa Temporary/Pameran') {
            const headers = ['No Invoice', 'Nama Tenant', 'No Unit', 'Keterangan', 'Telepon', 'Nominal Termin', 'Nominal Kontrak', 'Produk', 'Sumber', 'Jangka Waktu Sewa', 'Periode Start Sewa', 'Periode Berakhir Sewa', 'Nilai Jaminan', 'Attachment']
            const subHeaders = ['No Invoice', 'Nama Tenant', 'Custom Field - No Unit', 'Keterangan', 'Telepon', 'Nominal Termin', 'Nominal Pipeline', 'Produk', 'Sumber', 'Custom Field - Jangka Waktu Sewa', 'Custom Field - Periode Start Sewa', 'Custom Field - Periode Berakhir Sewa', 'Custom Field - Nilai Jaminan', 'Attachment']

            worksheet.addRow(headers);
            worksheet.addRow(subHeaders);

            styleHeader(worksheet, 1, 'F6991D', false);
            styleHeader(worksheet, 2, 'FFE699', true);

            let totalTerminNominal = 0;
            let totalLeadNominal = 0;

            data.forEach(item => {
                const row = worksheet.addRow([
                    item['No. Invoice'] || '-',
                    item.merchantName || '-', // Merchant Name
                    item['No Unit'] || '-',
                    item.terminDescription || '-',        // Keterangan
                    item.merchantPhone || '-',            // Telepon
                    Math.ceil(item.terminNominal || 0),             // Nominal Termin
                    Math.ceil(item.leadNominal || 0),               // Nominal Kontrak
                    item.leadName || '-',                // Produk
                    item.leadSourceName || '-',          // Sumber
                    item['Jangka Waktu Sewa'] || '-',
                    item['Periode Start Sewa'] || '-',
                    item['Periode Berakhir Sewa'] || '-',
                    item['Nilai Jaminan'] || '-',
                    item['attacmentDoc'] || '-'
                ]);

                totalTerminNominal += Math.ceil(item.terminNominal || 0);
                totalLeadNominal += Math.ceil(item.leadNominal || 0);

                row.eachCell((cell, colNumber) => {
                    cell.alignment = { vertical: 'middle', wrapText: true };

                    // Format numbers untuk kolom nominal
                    if (colNumber === 3 || colNumber === 4) {
                        cell.numFmt = '#,##0;[Red]-#,##0';
                        cell.alignment.horizontal = 'right';
                    }
                });
            });

            const totalRow = worksheet.addRow([ // untuk total row
                'Total',
                '',
                '',
                '',
                '',
                totalTerminNominal,
                totalLeadNominal,
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ]);

            totalRow.font = { bold: true };
            totalRow.getCell(3).numFmt = '#,##0;[Red]-#,##0';
            totalRow.getCell(4).numFmt = '#,##0;[Red]-#,##0';

            // Set column widths
            worksheet.columns = [
                { width: 30 },  // Keterangan
                { width: 15 },  // Telepon
                { width: 20 },  // Nominal Termin
                { width: 20 },  // Nominal Kontrak
                { width: 20 },  // Produk
                { width: 15 },  // Sumber
                { width: 25 },  // Nama Merchant
                { width: 20 },  // Template Merchant
                { width: 15 },  // Jangka Waktu
                { width: 15 },  // Nilai Jaminan
                { width: 15 },  // No Unit
                { width: 15 },  // Periode Mulai
                { width: 15 },  // Periode Akhir
                { width: 15 },  // No Invoice
            ];
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${nameFile}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        } else if (nameFile === 'Service Charge') {
            const headers = ['No Invoice', 'Nama Tenant', 'No Unit', 'Keterangan', 'Progres', 'Telepon', 'Nominal Kontrak', 'Produk', 'Sumber', 'Periode Penagihan', 'Jatuh Tempo', 'Attachment']
            const subHeaders = ['No Invoice', 'Nama Tenant', 'Custom Field - No Unit', 'Keterangan', 'Progres', 'Telepon', 'Nominal Pipeline', 'Produk', 'Sumber', 'Custom Field - Periode Penagihan', 'Custom Field - Jatuh Tempo', 'Attachment']

            worksheet.addRow(headers);
            worksheet.addRow(subHeaders);

            styleHeader(worksheet, 1, 'F6991D', false);
            styleHeader(worksheet, 2, 'FFE699', true);

            let totalTerminNominal = 0;
            let totalLeadNominal = 0;

            data.forEach(item => {
                const row = worksheet.addRow([
                    item['No. Invoice'] || '-',
                    item.merchantName || '-', // Merchant Name
                    item['No Unit'] || '-',
                    item.terminDescription || '-',        // Keterangan
                    item.progress || '-',
                    item.merchantPhone || '-',            // Telepon
                    Math.ceil(item.terminNominal || 0),             // Nominal Termin
                    Math.ceil(item.leadNominal || 0),               // Nominal Kontrak
                    item.leadName || '-',                // Produk
                    item.leadSourceName || '-',          // Sumber
                    item['Periode Penagihan'] || '-',
                    item['Jatuh Tempo'] || '-',
                    item['attacmentDoc'] || '-'
                ]);

                totalTerminNominal += Math.ceil(item.terminNominal || 0);
                totalLeadNominal += Math.ceil(item.leadNominal || 0);

                row.eachCell((cell, colNumber) => {
                    cell.alignment = { vertical: 'middle', wrapText: true };

                    // Format numbers untuk kolom nominal
                    if (colNumber === 3 || colNumber === 4) {
                        cell.numFmt = '#,##0;[Red]-#,##0';
                        cell.alignment.horizontal = 'right';
                    }
                });
            });

            const totalRow = worksheet.addRow([ // untuk total row
                'Total',
                '',
                '',
                '',
                '',
                '',
                // totalTerminNominal,
                totalLeadNominal,
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ]);

            totalRow.font = { bold: true };
            totalRow.getCell(3).numFmt = '#,##0;[Red]-#,##0';
            totalRow.getCell(4).numFmt = '#,##0;[Red]-#,##0';

            // Set column widths
            worksheet.columns = [
                { width: 30 },  // Keterangan
                { width: 15 },  // Telepon
                { width: 20 },  // Nominal Termin
                { width: 20 },  // Nominal Kontrak
                { width: 20 },  // Produk
                { width: 15 },  // Sumber
                { width: 25 },  // Nama Merchant
                { width: 20 },  // Template Merchant
                { width: 15 },  // Jangka Waktu
                { width: 15 },  // Nilai Jaminan
                { width: 15 },  // No Unit
                { width: 15 },  // Periode Mulai
                { width: 15 },  // Periode Akhir
                { width: 15 },  // No Invoice
            ];
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${nameFile}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        } else if (nameFile === 'Listrik') {
            const headers = ['No Invoice', 'Nama Tenant', 'No Unit', 'Keterangan', 'Progres', 'Telepon', 'Nominal Kontrak', 'Produk', 'Sumber', 'Periode Penagihan', 'Periode Start Pemakaian', 'Periode Berakhir Pemakaian', 'Tanggal Jatuh Tempo', 'Faktor Meter', 'Daya', 'Meter Lalu', 'Meter Akhir', 'Attachment Doc inv']
            const subHeaders = ['No Invoice', 'Nama Tenant', 'Custom Field - No Unit', 'Keterangan', 'Progres', 'Telepon', 'Nominal Pipeline', 'Produk', 'Sumber', 'Custom Field - Periode Penagihan', 'Custom Field - Periode Start Pemakaian', 'Custom Field - Periode Berakhir Pemakaian', 'Custom Field - Tanggal Jatuh Tempo', 'Custom Field - Faktor Meter', 'Custom Field - Daya', 'Custom Field - Meter Lalu', 'Custom Field - Meter Akhir', 'Attachment Doc inv']

            worksheet.addRow(headers);
            worksheet.addRow(subHeaders);

            styleHeader(worksheet, 1, 'F6991D', false);
            styleHeader(worksheet, 2, 'FFE699', true);

            let totalTerminNominal = 0;
            let totalLeadNominal = 0;

            data.forEach(item => {
                const row = worksheet.addRow([
                    item['No. Invoice'] || '-',
                    item.merchantName || '-', // Merchant Name
                    item['No Unit'] || '-',
                    item.terminDescription || '-',        // Keterangan
                    item.progress || '-',
                    item.merchantPhone || '-',            // Telepon
                    Math.ceil(item.leadNominal || 0),               // Nominal Kontrak
                    item.leadName || '-',                // Produk
                    item.leadSourceName || '-',          // Sumber
                    item['Periode Penagihan'] || '-',
                    item['Periode Start Pemakaian'] || '-',
                    item['Periode Berakhir Pemakaian'] || '-',
                    item['Jatuh Tempo'] || '-',
                    item['Faktor Meter'] || '-',
                    item['Daya'] || '-',
                    item['Meter Lalu'] || '-',
                    item['Meter Akhir'] || '-',
                    item['attacmentDoc'] || '-'
                ]);

                totalTerminNominal += Math.ceil(item.terminNominal || 0);
                totalLeadNominal += Math.ceil(item.leadNominal || 0);

                row.eachCell((cell, colNumber) => {
                    cell.alignment = { vertical: 'middle', wrapText: true };

                    // Format numbers untuk kolom nominal
                    if (colNumber === 3 || colNumber === 4) {
                        cell.numFmt = '#,##0;[Red]-#,##0';
                        cell.alignment.horizontal = 'right';
                    }
                });
            });

            const totalRow = worksheet.addRow([ // untuk total row
                'Total',
                '',
                '',
                '',
                '',
                '',
                // totalTerminNominal,
                totalLeadNominal,
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ]);

            totalRow.font = { bold: true };
            totalRow.getCell(3).numFmt = '#,##0;[Red]-#,##0';
            totalRow.getCell(4).numFmt = '#,##0;[Red]-#,##0';

            // Set column widths
            worksheet.columns = [
                { width: 30 },  // Keterangan
                { width: 15 },  // Telepon
                { width: 20 },  // Nominal Termin
                { width: 20 },  // Nominal Kontrak
                { width: 20 },  // Produk
                { width: 15 },  // Sumber
                { width: 25 },  // Nama Merchant
                { width: 20 },  // Template Merchant
                { width: 15 },  // Jangka Waktu
                { width: 15 },  // Nilai Jaminan
                { width: 15 },  // No Unit
                { width: 15 },  // Periode Mulai
                { width: 15 },  // Periode Akhir
                { width: 15 },  // No Invoice
                { width: 15 },  // Jangka Waktu
                { width: 15 },  // Nilai Jaminan
                { width: 15 },  // No Unit
                { width: 15 },  // Periode Mulai
                { width: 15 },  // Periode Akhir
                { width: 15 },  // No Invoice
            ];
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${nameFile}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        } else if (nameFile === 'Air') {
            const headers = ['No Invoice', 'Nama Tenant', 'No Unit', 'Keterangan', 'Progres', 'Telepon', 'Nominal Kontrak', 'Produk', 'Sumber', 'Periode Penagihan', 'Tanggal Jatuh Tempo', 'Tanggal Penunjukan Meter', 'Meter Lama', 'Meter Akhir', 'Attachment Doc inv']
            const subHeaders = ['No Invoice', 'Nama Tenant', 'Custom Field - No Unit', 'Keterangan', 'Progres', 'Telepon', 'Nominal Pipeline', 'Produk', 'Sumber', 'Custom Field - Periode Penagihan', 'Custom Field - Tanggal Jatuh Tempo', 'Custom Field - Tanggal Penunjukan Meter', 'Custom Field - Meter Lama', 'Custom Field - Meter Akhir', 'Attachment Doc inv']

            worksheet.addRow(headers);
            worksheet.addRow(subHeaders);

            styleHeader(worksheet, 1, 'F6991D', false);
            styleHeader(worksheet, 2, 'FFE699', true);

            let totalTerminNominal = 0;
            let totalLeadNominal = 0;

            data.forEach(item => {
                const row = worksheet.addRow([
                    item['No. Invoice'] || '-',
                    item.merchantName || '-', // Merchant Name
                    item['No Unit'] || '-',
                    item.terminDescription || '-',        // Keterangan
                    item.progress || '-',
                    item.merchantPhone || '-',            // Telepon
                    Math.ceil(item.leadNominal || 0),               // Nominal Kontrak
                    item.leadName || '-',                // Produk
                    item.leadSourceName || '-',          // Sumber
                    item['Periode Penagihan'] || '-',
                    item['Tanggal Jatuh Tempo'] || '-',
                    item['Tanggal Penunjukan Meter'] || '-',
                    item['Meter Lama'] || '-',
                    item['Meter Akhir'] || '-',
                    item['attacmentDoc'] || '-'
                ]);

                totalTerminNominal += Math.ceil(item.terminNominal || 0);
                totalLeadNominal += Math.ceil(item.leadNominal || 0);

                row.eachCell((cell, colNumber) => {
                    cell.alignment = { vertical: 'middle', wrapText: true };

                    // Format numbers untuk kolom nominal
                    if (colNumber === 3 || colNumber === 4) {
                        cell.numFmt = '#,##0;[Red]-#,##0';
                        cell.alignment.horizontal = 'right';
                    }
                });
            });

            const totalRow = worksheet.addRow([ // untuk total row
                'Total',
                '',
                '',
                '',
                '',
                '',
                // totalTerminNominal,
                totalLeadNominal,
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ]);

            totalRow.font = { bold: true };
            totalRow.getCell(3).numFmt = '#,##0;[Red]-#,##0';
            totalRow.getCell(4).numFmt = '#,##0;[Red]-#,##0';

            // Set column widths
            worksheet.columns = [
                { width: 30 },  // Keterangan
                { width: 15 },  // Telepon
                { width: 20 },  // Nominal Termin
                { width: 20 },  // Nominal Kontrak
                { width: 20 },  // Produk
                { width: 15 },  // Sumber
                { width: 25 },  // Nama Merchant
                { width: 20 },  // Template Merchant
                { width: 15 },  // Jangka Waktu
                { width: 15 },  // Nilai Jaminan
                { width: 15 },  // No Unit
                { width: 15 },  // Periode Mulai
                { width: 15 },  // Periode Akhir
                { width: 15 },  // No Invoice
                { width: 15 },  // Jangka Waktu
                { width: 15 },  // Nilai Jaminan
                { width: 15 },  // No Unit
                { width: 15 },  // Periode Mulai
                { width: 15 },  // Periode Akhir
                { width: 15 },  // No Invoice
            ];
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${nameFile}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        }

    } catch (error) {
        console.error('Error in exportExcelAccountReceivable:', error);
        throw error;
    }
};
