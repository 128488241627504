import { FC, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { KTSVG } from "../../_metronic/helpers";
import { useSettingsStore } from "../../stores/SettingsStore";
import { DataTableSelect } from "../DataTable/DataTableSelect";
import { TableColumn } from "react-data-table-component";
import { Merchant } from "../../interfaces/Merchant";
import { SelectOption } from "../../interfaces/SelectOption";
import { useIntl } from "react-intl";
import { DateRangePicker } from "rsuite";
import useTodayRange from "../../hooks/TodayRange";
import moment from "moment-timezone";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { useMerchantStore } from "../../stores/MerchantStore";
import useSWR from "swr";
import axios from "axios";
import useAccessToken from "../../hooks/AccessToken";
import { LabelOption } from "../../interfaces/Label";
import { userInfo } from "os";
import useUser from "../../hooks/User";
import { useCustomeStore } from "../../stores/CustomeStore";

const API_URL = process.env.REACT_APP_API_URL

interface FilterMerchantProps {
    manageColumns: any,
    setManageColumns: any,
}

const FilterMerchant: FC<FilterMerchantProps> = ({ manageColumns, setManageColumns }) => {
    const { setSelectedFilter, setSelectColumn, setFilteredTablesData, tablesData, setIsFilterDate, filteredTablesData, setDateStart, setDateEnd, setDateRangeMerchants, dateRangeMerchants, selectedFilters } = useDataTableStore();
    const { setTempSortTableData } = useMerchantStore()
    const { settings } = useSettingsStore()
    const [columnsData, setColumnsData] = useState<SelectOption[]>([]);
    const [labelsData, setLabelsData] = useState<SelectOption[]>([]);
    const intl = useIntl()
    const token = useAccessToken()
    const [customFieldMerchant, setCustomFieldMerchant] = useState<any>([])
    const user = useUser()
    const { companyId } = useCustomeStore()

    useEffect(() => {
        if (settings.merchant_custom_fields.length > 0) {
            setCustomFieldMerchant(JSON.parse(settings.merchant_custom_fields))
        }
    }, [settings])

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const selectColumnsConfigs = {
        placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.COLUMN" })}`,
        tableKey: 'merchants',
        filterKey: 'column_merchants',
        options: columnsData,
        onChange: (values: string[]) => setSelectedFilter('merchants', 'column_merchants', values)
    }

    const selectFilterConfigs = [
        {
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.MENU.LABELS" })}`,
            tableKey: 'merchants',
            filterKey: 'labels',
            options: labelsData,
            onChange: (values: string[]) => setSelectedFilter('merchants', 'labels', values)
        },
    ]

    if (user.data.company_id === companyId.happyPlay || companyId.looyalIT) {
        selectFilterConfigs.push({
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.OPTION" })}`,
            tableKey: 'merchants',
            filterKey: `custom_fields.c978ed78-1729-402f-abb2-63089d436fe8`, // c978ed78-1729-402f-abb2-63089d436fe8 // f9c418ec-44a2-4185-b523-4a4445a26071 (dev)
            options: customFieldMerchant['c978ed78-1729-402f-abb2-63089d436fe8']?.value?.map((option: string) => ({
                value: option,
                label: option
            })) || [],
            onChange: (values: string[]) => setSelectedFilter('merchants', 'custom_fields.c978ed78-1729-402f-abb2-63089d436fe8', values)
        });
    }

    const { data: labels = [], error: labelsError, isLoading: labelsLoading } = useSWR<LabelOption[]>(`${API_URL}labels?usableBy[]=merchant`, fetcher)

    const handleChange = (value: boolean) => {
        const updatedColumns = manageColumns.map((data: TableColumn<Merchant>) => ({
            ...data,
            omit: !value,
        }));

        setManageColumns(updatedColumns)
    }

    const handleDateRangeChange = (value: DateRange | null) => {
        setDateRangeMerchants(value as [Date, Date]);
        const startDateTime = moment(value?.[0]).startOf('day');
        const endDateTime = moment(value?.[1]).endOf('day');

        setDateStart(startDateTime.format('DD MMM yyyy'))
        setDateEnd(endDateTime.format('DD MMM yyyy'))
        setIsFilterDate(true)
    };

    const handleResetFilter = () => {
        // Reset Column Manage
        handleChange(true)

        // Reset Data 
        setSelectColumn('merchants', [])

        setDateRangeMerchants(null)
        setFilteredTablesData('merchants', tablesData['merchants'])
        setDateStart('')
        setDateEnd('')
        setTempSortTableData([], 'desc')
        setSelectedFilter('merchants', 'labels', [])
        setSelectedFilter('merchants', 'custom_fields.c978ed78-1729-402f-abb2-63089d436fe8', [])
        setIsFilterDate(false)
    }

    useEffect(() => {
        handleChange(true)
        setSelectColumn('merchants', [])
    }, [])

    useEffect(() => {
        const columnsData = manageColumns.map((column: any) => ({
            value: column.name,
            label: column.name
        }));
        setColumnsData(columnsData)
        if (!labelsLoading) {
            const newLabelsOption = labels.map((label: LabelOption) => ({ value: label.name, label: label.name }))
            setLabelsData(newLabelsOption)
        }
    }, [manageColumns, labels])

    return (
        <>
            {
                (manageColumns.some((data: TableColumn<Merchant>) => data.omit === true) || dateRangeMerchants || (selectedFilters['merchants'] && (selectedFilters['merchants']['labels']?.length > 0 || selectedFilters['merchants']['custom_fields.c978ed78-1729-402f-abb2-63089d436fe8']?.length > 0))) ?
                    <>
                        <button className="btn mt-3 me-3" style={{ backgroundColor: "#f1faff", border: "2px solid #009EF7", padding: "calc(0.75rem) calc(1.5rem)" }} id="drawer_filter_merchant">
                            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 svg-icon-primary m-0' />
                        </button>
                        <button type="button"
                            className="btn btn-white mt-3 text-primary"
                            onClick={() => { handleResetFilter() }}
                        >
                            Reset Filter
                        </button>
                    </>
                    :
                    <button type="button"
                        className="btn btn-light mt-3"
                        id="drawer_filter_merchant"
                    >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 m-0' />
                    </button>
            }

            <div
                id="kt_drawer_filter_merchant"
                className="bg-white"
                data-kt-drawer="true"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                data-kt-drawer-toggle="#drawer_filter_merchant"
                data-kt-drawer-close="#kt_drawer_example_basic_close"
                data-kt-drawer-width="{default:'300px', 'md': '600px'}"
            >
                <div className="card w-100 rounded-0">
                    <div className="card-header pe-5">
                        <div className="card-title">
                            <div className="d-flex justify-content-center flex-column me-3">
                                <span className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1">Filter {settings.merchant_title}</span>
                            </div>
                        </div>
                        <div className="card-toolbar">
                            <div className="btn btn-sm btn-icon btn-active-light-primary" id="kt_drawer_example_basic_close">
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{ overflowY: "auto" }}>
                        <div>
                            <div style={{ fontWeight: "600" }}>{intl.formatMessage({ id: "FORM.LABEL.MANAGE_COLUMN" })}</div>
                            <div className="separator separator-dashed my-3"></div>
                            <DataTableSelect
                                placeholder={selectColumnsConfigs.placeholder}
                                tableKey={selectColumnsConfigs.tableKey}
                                filterKey={selectColumnsConfigs.filterKey}
                                options={selectColumnsConfigs.options}
                                onChange={selectColumnsConfigs.onChange}
                                manageColumns={manageColumns}
                                setManageColumns={setManageColumns}
                            />
                        </div>
                        <div className="mt-5">
                            <div style={{ fontWeight: "600" }}>
                                {intl.formatMessage({ id: "FORM.LABEL.DATE_CREATED" })}
                            </div>
                            <div className="separator separator-dashed my-3"></div>
                            <DateRangePicker
                                value={dateRangeMerchants as [Date, Date]}
                                onChange={handleDateRangeChange}
                                format="dd MMM yyyy"
                                placeholder={intl.formatMessage({ id: "FORM.LABEL.SELECT_DATE_RANGE" })}
                                className="responsive-daterangepicker"
                                container={() => document.getElementById('kt_drawer_filter_merchant') as HTMLElement}
                            />
                        </div>
                        <div className="mt-5">
                            <div style={{ fontWeight: "600" }}>Filter Data</div>
                            <div className="separator separator-dashed my-3"></div>
                            {
                                selectFilterConfigs.map((config, index) => (
                                    <DataTableSelect
                                        placeholder={config.placeholder}
                                        tableKey={config.tableKey}
                                        filterKey={config.filterKey}
                                        key={index}
                                        options={config.options}
                                        onChange={config.onChange}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { FilterMerchant }