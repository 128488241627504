import { useState, useEffect } from 'react';

const useCamera = (localStorageKey = 'is-camera-granted') => {
  const [shouldRequestCamera, setShouldRequestCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(true);
  const [isCamera, setIsCamera] = useState<boolean>(false);

  const onSuccess = () => {
    setLoading(false);
    setError(false);
    setIsCamera(true);
  };

  const onError = () => {
    setLoading(false);
    setError(true);
    setIsCamera(false);
  };

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia supported.');
      navigator.mediaDevices
        .getUserMedia({ video: true })

        // Success callback
        .then((stream) => {
          stream.getTracks().forEach((x) => x.stop());
          setShouldRequestCamera(true);
        })

        // Error callback
        .catch((err) => {
          console.error(`The following getUserMedia error occurred: ${err}`);
          onError();
        });
    } else {
      console.log('getUserMedia not supported on your browser!');
      onError();
    }
  }, []);

  useEffect(() => {
    if (shouldRequestCamera) {
      setLoading(true);
      onSuccess();
    }
  }, [shouldRequestCamera]);

  return { loading, error, isCamera };
};

export default useCamera;
