import { FC, useEffect, useRef, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { useFormik } from "formik";
import * as Yup from 'yup';
import useUser from "../../hooks/User";
import { updateProfile } from "../../api/ProfileCRUD";
import useAccessToken from "../../hooks/AccessToken";
import Swal from "sweetalert2";
import { insertProfilePicture } from "../../api/ResourceAPI";
import { useDispatch } from "react-redux";
import * as auth from '../../app/modules/auth/redux/AuthRedux'

const ChangeProfile: FC = () => {

    const user = useUser()
    const token = useAccessToken()
    const modalRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch()

    const [file, setFile] = useState<string | null>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const closeModalRef = useRef<HTMLButtonElement>(null);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files[0]) {
            setFile(URL.createObjectURL(e.target.files[0]));
            formik.handleChange({
                target: {
                    name: "profilePicture",
                    value: e.target.files[0]
                }
            })
        }
    }

    const handleCloseClick = () => {
        formik.resetForm();
        setFile(null);
    };

    const validationSchema = Yup.object().shape({
        profilePicture: Yup.mixed().required('Foto profil harus diisi'),
    });

    const formik = useFormik({
        initialValues: {
            profilePicture: '',
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true);
            setSubmitting(true);

            try {
                const resourceResponse = await insertProfilePicture(values.profilePicture!)

                const response = await updateProfile({
                    profilePicture: resourceResponse.data.data[0],
                    token: token,
                });
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: 'Foto profil berhasil diubah',
                        timer: 2000,
                        timerProgressBar: true
                    })

                    const newUserData = { ...user, data: { ...user.data, profile_picture: resourceResponse.data.data[0] } };
                    dispatch(auth.actions.fulfillUser(newUserData))
                    closeModalRef.current?.click();
                }
            } catch (error: any) {
                setStatus(error.response.data.message);
            } finally {
                setSubmitting(false);
                setModalBtnLoading(false);
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm();
            setFile(null);
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    return (
        <>
            <div className="modal fade" tabIndex={-1} id="modal-change-profile" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Ubah Foto Profil</h5>
                            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" >
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body fs-6">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                { !file && !user.data.profile_picture &&
                                    <div className="mb-5">    
                                        <h4 className="mb-5">Upload foto profil berupa wajah</h4>
                                        <p>1. Pastikan foto cukup terang</p>
                                        <p>2. Pastikan foto wajah jelas</p>
                                        <div className="text-center">
                                            <p>Contoh foto:</p>
                                            <img src={toAbsoluteUrl('/media/avatars/300-27.jpg')} alt="example_photo" width={150}></img>
                                        </div>
                                    </div>
                                }
                                <label className='form-label fs-6 fw-bolder text-dark required'>Foto Profil</label>
                                <div className="input-group mb-3">
                                    <input
                                        type="file"
                                        onChange={handleChange}
                                        accept="image/png, image/jpeg, image/jpg, image/gif"
                                        className="form-control form-control-lg"
                                    />
                                </div>
                                {
                                    file ?
                                        <img src={file} alt="profile picture" className="img-fluid mt-3" />
                                        :
                                        user.data.profile_picture &&
                                        <img src={user.data.profile_picture} alt="profile picture" className="img-fluid mt-3" />
                                }
                                {formik.touched.profilePicture && formik.errors.profilePicture && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.profilePicture}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                    onClick={handleCloseClick}
                                >
                                    Batal
                                </button>
                                <button type="submit" className={`btn btn-primary ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">Ubah</span>
                                    <span className="indicator-progress">Harap tunggu...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ChangeProfile }