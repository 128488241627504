import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import useAccessToken from "../../hooks/AccessToken";
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps } from "../../functions/general";
import { useIntl } from "react-intl";
import { Unit } from "../../interfaces/Unit";
import { SketchPicker } from "react-color";
import { updateUnit } from "../../api/UnitsCRUD";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

interface Props {
    unit: Unit
}

const UpdateUnit: FC<Props> = ({ unit }) => {

    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()

    const intl = useIntl()

    const formik = useFormik({
        initialValues: {
            name: unit.name,
            alias: unit.alias,
            color: unit.color,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })).nullable(),
            alias: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'Alias' })).nullable(),
            color: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.COLOR" }) })).nullable(),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setSubmitting(true);
            setModalBtnLoading(true)
            try {
                const response = await updateUnit(unit.id, values.name, values.alias, values.color, token)
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" })}`,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    closeModalRef.current?.click();
                    setTablesData('units', (tablesData.units.map(item => {
                        if (item.id === unit.id) {
                            return {
                                ...item,
                                name: values.name,
                                alias: values.alias,
                                color: values.color,
                            }
                        } else {
                            return item
                        }
                    })));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div className="modal fade" tabIndex={-1} id={`update-unit-modal-${unit.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} {unit.name}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    {...formik.getFieldProps('name')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                    )}
                                    type='text'
                                    name='name'
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert'>{formik.errors.name}</span>
                                </div>
                            )}

                            <label className='form-label fs-6 fw-bolder text-dark required'>Alias</label>
                            <div className="input-group mb-3">
                                <input
                                    {...formik.getFieldProps('alias')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.alias && formik.errors.alias },
                                    )}
                                    type='text'
                                    name='alias'
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.alias && formik.errors.alias && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.alias}</span>
                                </div>
                            )}

                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.COLOR' })}</label>
                            <div className="input-group mb-3">
                                <SketchPicker
                                    color={formik.values.color}
                                    onChange={color => {
                                        formik.handleChange({
                                            target: {
                                                name: "color",
                                                value: color.hex
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleCloseModal}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { UpdateUnit }