import { FC, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import useAccessToken from "../../hooks/AccessToken";
import { KTSVG } from "../../_metronic/helpers";
import axios from "axios";
import useSWR from "swr";
import { SelectOption } from "../../interfaces/SelectOption";
import { User } from "../../interfaces/User";
import { DataTableSelect } from "../DataTable/DataTableSelect";
import { Meeting } from "../../interfaces/Meeting";
import { TableColumn } from "react-data-table-component";
import useUser from "../../hooks/User";
import { useIntl } from "react-intl";
import { settings } from "cluster";
import { useSettingsStore } from "../../stores/SettingsStore";
import { LabelOption } from "../../interfaces/Label";

interface FilterMeetingProps {
    manageColumns: any,
    setManageColumns: any,
}

const FilterMeeting: FC<FilterMeetingProps> = ({ manageColumns, setManageColumns }) => {
    const { selectedFilters, setSelectedFilter, setSelectColumn, filteredTablesData } = useDataTableStore();
    const token = useAccessToken()
    const { settings } = useSettingsStore()
    const [labelsData, setLabelsData] = useState<SelectOption[]>([]);
    const [isCheckAll, setIsCheckAll] = useState(true);
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const [columnsData, setColumnsData] = useState<SelectOption[]>([]);
    const user = useUser()
    const intl = useIntl()

    const API_URL = process.env.REACT_APP_API_URL
    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const getUserURL = () => {
        if (user.data.permission_pipeline_create === "ALL") {
            return `${API_URL}users`
        } else {
            return `${API_URL}user/subordinates`
        }
    }

    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(getUserURL(), fetcher)
    const { data: labels = [], error: labelsError, isLoading: labelsLoading } = useSWR<LabelOption[]>(`${API_URL}labels?usableBy[]=meeting`, fetcher)

    const statusData: SelectOption[] = [
        {
            label: intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }),
            value: intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' })
        },
        {
            label: intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }),
            value: intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' })
        },
        {
            label: intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }),
            value: intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' })
        },
        {
            label: intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' }),
            value: intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' })
        },
    ]

    const selectConfigs = [
        {
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.PIC" })}`,
            filterKey: 'owner_name',
            tableKey: 'meetings',
            options: usersData,
            onChange: (values: string[]) => setSelectedFilter('meetings', 'owner_name', values)
        },
        {
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.STATUS" })}`,
            filterKey: 'status',
            tableKey: 'meetings',
            options: statusData,
            onChange: (values: string[]) => setSelectedFilter('meetings', 'status', values)
        },
        {
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.LABELS" })}`,
            filterKey: 'labels',
            tableKey: 'meetings',
            options: labelsData,
            onChange: (values: string[]) => setSelectedFilter('meetings', 'labels', values)
        },
    ];

    const selectColumnsConfigs = {
        placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.COLUMN" })}`,
        tableKey: 'meetings',
        filterKey: 'column_meetings',
        options: columnsData,
        onChange: (values: string[]) => setSelectedFilter('meetings', 'column_meetings', values)
    }

    const handleChange = (value: boolean) => {
        const updatedColumns = manageColumns.map((data: TableColumn<Meeting>) => ({
            ...data,
            omit: !value,
        }));

        setManageColumns(updatedColumns)
    }

    const handleResetFilter = () => {
        // Reset Column Manage
        handleChange(true)

        // Reset Data 
        setSelectedFilter('meetings', 'owner_name', [])
        setSelectedFilter('meetings', 'status', [])
        setSelectedFilter('meetings', 'labels', [])
        setSelectColumn('meetings', [])
    }

    useEffect(() => {
        handleChange(true)
        setSelectedFilter('meetings', 'owner_name', [])
        setSelectColumn('meetings', [])
    }, [])

    useEffect(() => {
        if (!usersLoading) {
            const usersData = users.map((user: User) => ({
                value: user.name,
                label: user.name
            }));

            if (user.data.permission_pipeline_create !== "ALL") {
                usersData.unshift({ value: user.data.name, label: user.data.name })
            }
            // Prepend the two objects to the salesData array
            setUsersData(usersData);
        }
    }, [users]);

    useEffect(() => {
        const columnsData = manageColumns.map((column: any) => ({
            value: column.name,
            label: column.name
        }));
        // columnsData.unshift(
        //     { label: "Semua", value: "ALL" },
        // )
        setColumnsData(columnsData)
    }, [manageColumns])

    useEffect(() => {
        if (!labelsLoading) {
            setLabelsData(labels.map((label: LabelOption) => ({
                value: label.name,
                label: label.name
            })))
        }
    }, [labels])

    return (
        <>
            {
                (manageColumns.some((data: TableColumn<Meeting>) => data.omit === true) || (selectedFilters.meetings?.owner_name?.length > 0 || selectedFilters.meetings?.status?.length > 0 || selectedFilters.meetings?.labels?.length > 0)) ?
                    <>
                        <button className="btn mt-3 me-3" style={{ backgroundColor: "#f1faff", border: "2px solid #009EF7", padding: "calc(0.75rem) calc(1.5rem)" }} id="drawer_filter_meeting">
                            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 svg-icon-primary m-0' />
                        </button>
                        <button type="button"
                            className="btn btn-white mt-3 text-primary"
                            onClick={() => { handleResetFilter() }}
                        >
                            Reset Filter
                        </button>
                    </>
                    :
                    <button type="button"
                        className="btn btn-light mt-3"
                        id="drawer_filter_meeting"
                    >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 m-0' />
                    </button>
            }

            <div
                id="kt_drawer_filter_meeting"
                className="bg-white"
                data-kt-drawer="true"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                data-kt-drawer-toggle="#drawer_filter_meeting"
                data-kt-drawer-close="#kt_drawer_example_basic_close"
                data-kt-drawer-width="{default:'300px', 'md': '400px'}"
            >
                <div className="card w-100 rounded-0">
                    <div className="card-header pe-5">
                        <div className="card-title">
                            <div className="d-flex justify-content-center flex-column me-3">
                                <span className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1">Filter {settings.meeting_title}</span>
                            </div>
                        </div>
                        <div className="card-toolbar">
                            <div className="btn btn-sm btn-icon btn-active-light-primary" id="kt_drawer_example_basic_close">
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{ overflowY: "auto" }}>
                        <div style={{ fontWeight: "600" }}>Filter Data</div>
                        <div className="separator separator-dashed my-3"></div>
                        {
                            selectConfigs.map((config, index) => (
                                <DataTableSelect
                                    placeholder={config.placeholder}
                                    tableKey={config.tableKey}
                                    filterKey={config.filterKey}
                                    key={index}
                                    options={config.options}
                                    onChange={config.onChange}
                                />
                            ))
                        }

                        <div className="mt-10" style={{ fontWeight: "600" }}>{intl.formatMessage({ id: "FORM.LABEL.MANAGE_COLUMN" })}</div>
                        <div className="separator separator-dashed my-3"></div>
                        <DataTableSelect
                            placeholder={selectColumnsConfigs.placeholder}
                            tableKey={selectColumnsConfigs.tableKey}
                            filterKey={selectColumnsConfigs.filterKey}
                            options={selectColumnsConfigs.options}
                            onChange={selectColumnsConfigs.onChange}
                            manageColumns={manageColumns}
                            setManageColumns={setManageColumns}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export { FilterMeeting }