import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { customNumberFormat, getDateSummary, isColorDarkFromHex } from "../../functions/general";
import { useIntl } from "react-intl";
import moment from "moment";
import { CustomField } from "../../interfaces/Settings";
import { useSettingsStore } from "../../stores/SettingsStore";
import useUser from "../../hooks/User";
import { useCustomeStore } from "../../stores/CustomeStore";
import { ExpandableElement } from "../General/ExpandableElement";
import { Meeting, MeetingAttachment, MeetingAttendance } from "../../interfaces/Meeting";
import { Tooltip } from 'react-tippy';
import { OverlayTrigger,Tooltip as TooltipComp } from "react-bootstrap";

interface DetailMeetingProps {
    data: Meeting
}

const DetailMeeting: FC<DetailMeetingProps> = ({ data }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const intl = useIntl()
    const [customLead, setCustomLead] = useState({} as any)
    const [customFields, setCustomFields] = useState<CustomField>({})
    const { settings } = useSettingsStore()

    useEffect(() => {
        const customData: any = {
            attachments: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHOTO' }),
                value: data.attachments.length > 0 ? returnImage(data.attachments as MeetingAttachment[]) : "-"
            },
            merchant_name: {
                name: settings.merchant_title,
                value: returnMerchantName(data)
            },
            owner_name: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
                value: data.owner_name
            },
            date_meet: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
                value: data.allDay ? moment(data.date_meet).format("DD MMM YYYY") : moment(data.date_meet).format("DD MMM YYYY HH:mm")
            },
            date_meet_end: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
                value: data.date_meet_end === null ? "-" : data.allDay ? moment(data.date_meet_end).format("DD MMM YYYY") : moment(data.date_meet_end).format("DD MMM YYYY HH:mm")
            },
            attendees: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PARTICIPANT' }),
                value: data.attendees.length > 0 ? data.attendees.map((data: MeetingAttendance) => data.name).join(', ') : "-"
            },
            creator_name: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
                value: data.creator_name
            },
            location: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LOCATION' }),
                value: data.latitude && data.longitude ? data.location : "-"
            },
            reminderMinutes: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.REMINDER' }),
                value: data.reminderMinutes === null ? "-" : customNumberFormat(parseInt(data.reminderMinutes)) + " Menit"
            },
            status: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.STATUS' }),
                value: returnStatus(data)
            },
            note: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
                value: data.note ? <ExpandableElement text={(data.note).replaceAll('\n', '<br/>')} maxHeight={100} /> : "-"
            },
        }

        const newData = {
            ...customData,
            ...Object.keys(customFields).reduce((acc, key) => {
                let value;
                if (customFields[key].type === 'date') {
                    value = data.custom_fields[key] ? moment(data.custom_fields[key]).format("DD MMM YYYY") : "-";
                } else if (customFields[key].type === 'image' || customFields[key].type === 'images') {
                    value = data.custom_fields[key] ? returnImage(data.custom_fields[key] as string[]) : "-";
                } else if (customFields[key].type === 'file' || customFields[key].type === 'files') {
                    value = data.custom_fields[key] ? returnFile(data.custom_fields[key]) : "-";
                } else if (customFields[key].type === 'multiple') {
                    value = data.custom_fields[key] ? returnMultiple(data.custom_fields[key] as string) : "-";
                } else if (customFields[key].type === 'link') {
                    value = data.custom_fields[key] ? returnLink(data.custom_fields[key] as string) : "-";
                } else if (customFields[key].type === 'serial') {
                    value = data.custom_fields[key] ? (data.custom_fields[key] as string[]).join('') : "-";
                } else {
                    value = data.custom_fields[key] ? data.custom_fields[key] : "-";
                }

                return {
                    ...acc,
                    [key]: {
                        name: customFields[key].name,
                        value
                    }
                };
            }, {})
        };

        setCustomLead(newData);
    }, [customFields])

    useEffect(() => {
        if (settings.meeting_custom_fields) {
            setCustomFields(JSON.parse(settings.meeting_custom_fields))
        }
    }, [settings])

    const returnMerchantName=(row:Meeting)=>{
        if (row.type === "VISIT") {
        return (
            <div className="d-flex flex-row gap-2">
            <div>
            <span>{row.merchant_name}</span>
            <span className="badge badge-light-primary ms-3" >{intl.formatMessage({ id: 'MEETING.STATUS.VISIT' })}</span>
            </div>
            <div>
                    <OverlayTrigger
                        placement='right'
                        overlay={<TooltipComp id='tooltip-labels'>                                        
                            {    row?.labels?.map((label, index) => (
                                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                                        <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color||"black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                            <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                        </svg>
                                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {label.name}
                                        </span>
                                    </div>
                                ))
                        }
                        </TooltipComp>}
                    >
                        <span
                            // id={`labels-${row.labels}`}
                            className='cursor-pointer'
                            data-toggle='tooltip'
                            data-placement='right'
                            title=''
                        >
                            {(row.labels?.length && row.labels.length > 0) ? (
                                row.labels.length === 1 ? (
                                    <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: row.labels[0].color||"black" }} >
                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                    </svg>
                                ) : (
                                    <div className="ps-1" style={{ color: row.labels[1].color||"black" }}>
                                        <svg width="18px" height="15px" viewBox="0 0 18 15">
                                            <path fill={`${row.labels[0].color||"black"}`} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                            <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                        </svg>
                                    </div>

                                        )
                                    ) : <></>}
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
        )
        }else{
           return( <div className="d-flex flex-row gap-2">
            <div>
                {row.merchant_name}
            </div>
            <div>
                <OverlayTrigger
                    placement='right'
                    overlay={<TooltipComp id='tooltip-labels'>
                        
                            
                        {    row?.labels?.map((label, index) => (
                                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                                    <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color||"black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                    </svg>
                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {label.name}
                                    </span>
                                </div>
                            ))
                    }
                    </TooltipComp>}
                >
                    <span
                        className='cursor-pointer'
                        data-toggle='tooltip'
                        data-placement='right'
                        title=''
                    >
                        {(row.labels?.length && row.labels.length > 0) ? (
                            row.labels.length === 1 ? (
                                <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: row.labels[0].color||"black" }} >
                                    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                </svg>
                            ) : (
                                <div className="ps-1" style={{ color: row.labels[1].color||"black" }}>
                                    <svg width="18px" height="15px" viewBox="0 0 18 15">
                                        <path fill={`${row.labels[0].color||"black"}`} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                        <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                    </svg>
                                </div>

                            )
                        ) : <></>}
                    </span>
                </OverlayTrigger>
            </div>
        </div>
        )
        }
    }

    const returnImage = (url: string | string[] | MeetingAttachment[]) => {
        if (Array.isArray(url)) {
            return (url as MeetingAttachment[] | string[]).map((data: MeetingAttachment | string, index: number) => (
                <span key={index}>
                    <a href={(data as MeetingAttachment).url ? (data as MeetingAttachment).url as string : data as string} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })}</a>
            );
        }
    };

    const returnFile = (url: string | string[]) => {
        if (Array.isArray(url)) {
            return url.map((data: string, index: number) => (
                <span key={index}>
                    <a href={data} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })}</a>
            );
        }
    };

    const returnStatus = (data: Meeting) => {
        if (data.status === intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) || data.status === 'SCHEDULED') {
            return <span className="badge badge-info" >{intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' })}</span>
        } else if (data.status === intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) || data.status === 'COMPLETED') {
            return <span className="badge badge-success" >{intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' })}</span>
        } else if (data.status === intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) || data.status === 'ONGOING') {
            return <span className="badge badge-warning" >{intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' })}</span>
        } else {
            return (
                <>
                    {
                        (data.meetingStatusNote)?.includes("sistem") ?
                            <Tooltip
                                title={`${intl.formatMessage({ id: 'TOOLTIP.AUTO_CANCEL_SYSTEM' })}`}
                                theme="light"
                                arrow={true}
                                style={{ cursor: "default", display: "flex", alignItems: "center", width: "min-content" }}
                            >
                                <span className="badge badge-danger" >{intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' })}</span>
                                <i className='fas fa-info-circle ms-2 fs-7'></i>
                            </Tooltip>
                            :
                            <span className="badge badge-danger" >{intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' })}</span>
                    }
                </>
            )
        }
    };

    const returnMultiple = (data: string) => {
        const values = data && data.toString()
        return values ? values.split(',').join(', ') : '-'
    }

    const returnLink = (data: string) => {
        return (
            <a href={data} rel="noreferrer" className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600" target="_blank">Lihat<i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i></a>
        )
    }

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id={`detail-meeting-modal-${data.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Detail {data.merchant_name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form noValidate>
                            <div className="modal-body">
                                <div className="row">
                                    {
                                        Object.keys(customLead).map((key, index) => {
                                            return (
                                                <div className="row pb-2 col-12 col-lg-6 col-xl-6">
                                                    <div className="col-3 text-end" style={{ fontWeight: "500", color: "#767373" }}>{customLead[key].name}</div>
                                                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{customLead[key].value}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                {
                                    data.reimbursements.length > 0 &&
                                    <>
                                        <div className="separator separator-dashed my-5"></div>
                                        <h5 className="card-title align-items-start flex-column mb-5">
                                            Detail Reimbursements
                                        </h5>
                                        {
                                            data.reimbursements.map((dataReimbursement, index) => {
                                                return (
                                                    <div className="row mb-5">
                                                        <div className="col-8 col-lg-4 col-xl-4">
                                                            <div className="d-flex gap-2">
                                                                <span className="text-gray-800 fw-bolder">{dataReimbursement.name}</span>
                                                                {(() => {
                                                                    switch (dataReimbursement.approvalStatus) {
                                                                        case 'REJECTED':
                                                                            return <span className="badge badge-light-danger" style={{ height: "fit-content" }}>{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.REJECTED' })}</span>;
                                                                        case 'APPROVED':
                                                                            return <span className="badge badge-light-success" style={{ height: "fit-content" }}>{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.APPROVED' })}</span>;
                                                                        case 'PAID_OFF':
                                                                            return <span className="badge badge-light-primary" style={{ height: "fit-content" }}>{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.PAID_OFF' })}</span>;
                                                                        default:
                                                                            return <span className="badge badge-light-warning" style={{ height: "fit-content" }}>{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.PENDING' })}</span>;
                                                                    }
                                                                })()}
                                                            </div>
                                                            <span className="text-gray-400 fw-bold d-block text-start ps-0">{dataReimbursement.reimbursementCategory.name}</span>
                                                        </div>

                                                        <div className="col-4 col-lg-2 col-2">
                                                            <div className="text-gray-400 fw-bolder text-end">
                                                                <span className="text-gray-800 fw-bolder d-block">{dataReimbursement.companyCurrency.symbol} {customNumberFormat(dataReimbursement.value)}</span>
                                                                {moment(dataReimbursement.dateSpend).format("DD MMM YYYY")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { DetailMeeting }