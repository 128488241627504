import { FC, useEffect, useState } from "react";
import { RecentChatHistory } from '../../interfaces/Chat/RecentChatHistory';
import { useChatStore } from "../../stores/ChatStore";
import moment from "moment";
import useUser from "../../hooks/User";
import { formatText } from "../../functions/general";
import { ChatWindowData } from "../../interfaces/Chat/ChatWindowData";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { focusChatInput } from "../../functions/chat";
import { useSettingsStore } from "../../stores/SettingsStore";
import { toAbsoluteUrl } from "../../_metronic/helpers";
// import { getProfilePicture } from "../../api/ChatHelper";

interface ChatListItemProps {
    recentChatHistory: RecentChatHistory
}

const ChatListItem: FC<ChatListItemProps> = ({ recentChatHistory }) => {

    const {
        incomingMessageNotifications, setIncomingMessageNotifications,
        setPhoneNumber, setShowChat, phoneNumber,
        persistedMessages, setMessages, chatLabels,
        recentChatHistories, setScrollToId, setRecentChatSelectedPipeline,
        setSelectChatWindow, selectChatWindow, setIsOpenDetailContact,
        setMessage, filteredRecentChatHistories, setFilteredRecentChatHistories,
    } = useChatStore()
    const user = useUser()
    const { settings } = useSettingsStore()
    const [isHovered, setIsHovered] = useState(false);
    const [attachmentCaption, setAttachmentCaption] = useState<string>('')

    const handleMouseOver = () => {
        setIsHovered(true);
    }

    const handleMouseOut = () => {
        setIsHovered(false);
    }

    const handleContactClick = async () => {
        const { incomingMessageNotifications } = useChatStore.getState();
        setMessage('')
        setIsOpenDetailContact(false)
        if (persistedMessages[recentChatHistory.chat_phone] && persistedMessages[recentChatHistory.chat_phone].messages) {
            setMessages(() => persistedMessages[recentChatHistory.chat_phone].messages)
        }
        // set the recent chat history to selected and the rest to not selected
        setFilteredRecentChatHistories((prevState) => prevState.map(chat => {
            if (chat.chat_id === recentChatHistory.chat_id) {
                return {
                    ...chat,
                    selected: true
                }
            } else {
                return {
                    ...chat,
                    selected: false
                }
            }
        }))
        setShowChat(true)
        setPhoneNumber(recentChatHistory.chat_phone)
        if (recentChatHistory.pipelines) setRecentChatSelectedPipeline(recentChatHistory.pipelines[0])
        // delete incomingMessageNotifications[recentChatHistory.chat_phone]
        const { [recentChatHistory.chat_phone]: removedItem, ...rest } = incomingMessageNotifications;

        if (recentChatHistory.profile_picture_url === 'search') setScrollToId(recentChatHistory.chat_id)

        setIncomingMessageNotifications(() => rest);

        focusChatInput()
    }

    const latestMessage =
        incomingMessageNotifications[recentChatHistory.chat_phone] && recentChatHistory.profile_picture_url !== 'search' ?
            (incomingMessageNotifications[recentChatHistory.chat_phone].latestMessage.length > 40 ?
                incomingMessageNotifications[recentChatHistory.chat_phone].latestMessage.slice(0, 40) + '...' :
                incomingMessageNotifications[recentChatHistory.chat_phone].latestMessage
            ) :
            (recentChatHistory.chat_message.length > 40 ?
                recentChatHistory.chat_message.slice(0, 40) + '...' :
                recentChatHistory.chat_message
            )

    useEffect(() => {
        if (recentChatHistory.chat_attachment_name !== '' && recentChatHistory.chat_attachment_name !== null && recentChatHistory.chat_attachment_name !== undefined) {
            if (
                recentChatHistory.chat_attachment_name!.endsWith('.jpg') ||
                recentChatHistory.chat_attachment_name!.endsWith('.png') ||
                recentChatHistory.chat_attachment_name!.endsWith('.jpeg') ||
                recentChatHistory.chat_attachment_name!.endsWith('.gif')
            ) {
                const attachmentCaption = 'Image';
                setAttachmentCaption(() => attachmentCaption);
            } else if (recentChatHistory.chat_attachment_name!.endsWith('.mp4') || recentChatHistory.chat_attachment_name!.endsWith('.mov')) {
                const attachmentCaption = 'Video';
                setAttachmentCaption(() => attachmentCaption);
            } else {
                setAttachmentCaption(() => recentChatHistory.chat_attachment_name!.slice(0, 40) + '...');
            }
        } else {
            setAttachmentCaption(() => '');
        }

    }, [recentChatHistory])

    const getInitialsOrPhonePrefix = (recentChatHistory: { chat_name: string; chat_phone: string }): string => {
        if (recentChatHistory.chat_name) {
            const words = recentChatHistory.chat_name.split(' ');
            const initials = words.length > 1
                ? words.map((word: string) => word[0]).join('')
                : words[0][0];

            return recentChatHistory.chat_name !== recentChatHistory.chat_phone
                ? initials.substring(0, 2).toUpperCase()
                : recentChatHistory.chat_phone.substring(0, 2).toUpperCase();
        }
        return recentChatHistory.chat_phone.substring(0, 2).toUpperCase();
    };

    const handleClickChatWindow = () => {
        if (selectChatWindow.some(item => item.phone === recentChatHistory.chat_phone)) {
            setSelectChatWindow([...selectChatWindow.filter(item => item.phone !== recentChatHistory.chat_phone)])
        } else {
            setSelectChatWindow([...selectChatWindow, { name: recentChatHistory.chat_name, phone: recentChatHistory.chat_phone }])
        }
    }

    const selected = phoneNumber === recentChatHistory.chat_phone
    // console.log(latestMessage, attachmentCaption)
    return (
        <div className={`d-flex flex-stack py-4 px-3 cursor-pointer ${selected && 'rounded'}`} onClick={handleContactClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} style={{ background: `${selected ? '#F1F2F5' : ''}` }}>
            <div className='d-flex align-items-center gap-3'>
                {
                    selectChatWindow.length > 0 &&
                    <div className="form-check form-check-custom form-check-solid" onClick={(e) => { e.stopPropagation(); handleClickChatWindow() }}>
                        <input className="form-check-input" type="checkbox" checked={selectChatWindow.some((item: ChatWindowData) => item.phone === recentChatHistory.chat_phone)} />
                    </div>
                }

                {
                    recentChatHistory.profile_picture_url !== 'search' && recentChatHistory.profile_picture_url !== 'contact' &&
                    <div className='symbol symbol-45px symbol-circle'>
                        <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                            <img src={toAbsoluteUrl('/media/logos/logo-wooblazz.png')} className='symbol symbol-45px symbol-circle img-fluid' style={{padding: "10px"}} alt='profile' />
                        </span>
                    </div>
                }

                <div className=''>
                    <div className="d-flex">
                        <span className='fs-5 fw-bolder mb-2' style={isHovered ? { color: '#009EF7' } : { color: 'black' }}>
                            {
                                recentChatHistory.chat_phone === user.data.chat_phone ? user.data.name + ' (You)' : recentChatHistory.chat_name ? recentChatHistory.chat_name : recentChatHistory.chat_phone
                            }
                        </span>

                        <div>
                            <OverlayTrigger
                                key={`labels-${recentChatHistory.chat_id}`}
                                placement='right'
                                overlay={
                                    <Tooltip id='tooltip-labels'>
                                        {
                                            recentChatHistory?.labels?.map((label, index) => (
                                                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                                                    <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color, width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                                    </svg>
                                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
                                                        {label.name}
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </Tooltip>}
                            >
                                <span
                                    id={`labels-${recentChatHistory.chat_id}`}
                                    className='cursor-pointer'
                                    data-toggle='tooltip'
                                    data-placement='right'
                                    title=''
                                >
                                    {(recentChatHistory.labels?.length && recentChatHistory.labels.length > 0) ? (
                                        recentChatHistory.labels.length === 1 ? (
                                            <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: recentChatHistory.labels[0].color }} >
                                                <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                            </svg>
                                        ) : (
                                            <div className="ps-1" style={{ color: recentChatHistory.labels[1].color }}>
                                                <svg width="18px" height="15px" viewBox="0 0 18 15">
                                                    <path fill={`${recentChatHistory.labels[0].color}`} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                                    <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                                </svg>
                                            </div>

                                        )
                                    ) : <></>}
                                </span>
                            </OverlayTrigger>
                        </div>

                    </div>
                    {
                        (settings.chat_show_pic_only && !user.data.is_superadmin) ?
                            recentChatHistories.find(contact => contact.chat_phone === recentChatHistory.chat_phone)?.merchant_name_PIC &&
                            <p className='fs-7 fst-italic text-gray-600 mb-2'>
                                {
                                    recentChatHistories.find(contact => contact.chat_phone === recentChatHistory.chat_phone)?.merchant_name_PIC
                                }
                            </p>
                            :
                            recentChatHistories.find(contact => contact.chat_phone === recentChatHistory.chat_phone)?.merchant_name_active &&
                            <p className='fs-7 fst-italic text-gray-600 mb-2'>
                                {
                                    recentChatHistories.find(contact => contact.chat_phone === recentChatHistory.chat_phone)?.merchant_name_active
                                }
                            </p>
                    }
                    <div className={`fw-bold ${!selected && 'text-gray-400'}`}>
                        {
                            latestMessage && attachmentCaption === 'Image' ?
                                <i className="fas fa-image me-1"></i> :
                                latestMessage && attachmentCaption === 'Video' &&
                                <i className="fas fa-video me-1"></i>
                        }
                        <span dangerouslySetInnerHTML={{ __html: formatText(latestMessage) }}></span>
                        {
                            recentChatHistory.chat_attachment_name &&
                            <>
                                {
                                    attachmentCaption === 'Image' && !latestMessage &&
                                    <i className="fas fa-image me-1"></i>
                                }
                                {
                                    attachmentCaption === 'Video' && !latestMessage &&
                                    <i className="fas fa-video me-1"></i>
                                }
                                {
                                    attachmentCaption !== 'Image' && attachmentCaption !== 'Video' && !latestMessage &&
                                    <i className="fas fa-file me-1"></i>
                                }
                                {!latestMessage && attachmentCaption}
                            </>
                        }
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column align-items-end ms-2'>
                <span className={`${!selected ? 'text-muted' : 'fw-bolder'} fs-7 mb-1`}>
                    {
                        moment(recentChatHistory.chat_date).isSame(moment(), 'd')
                            ? moment(recentChatHistory.chat_date).format('HH:mm')
                            : moment(recentChatHistory.chat_date).isSame(moment().subtract(1, 'days'), 'd')
                                ? 'Yesterday'
                                : moment(recentChatHistory.chat_date).format('DD/MM/YYYY')
                    }
                </span>
                {
                    incomingMessageNotifications[recentChatHistory.chat_phone] &&
                    incomingMessageNotifications[recentChatHistory.chat_phone].unreadCount > 0 &&
                    recentChatHistory.profile_picture_url !== 'search' &&
                    <span className='badge badge-sm badge-circle badge-success'>
                        {
                            incomingMessageNotifications[recentChatHistory.chat_phone].unreadCount
                        }
                    </span>
                }
            </div>
        </div>
    )
}

export { ChatListItem }