import Select from 'react-select';
import { accessType, ProgressSettings } from '../../../interfaces/ProgressSettings';
import useSWR, { mutate } from 'swr';
import axios from 'axios';
import useAccessToken from '../../../hooks/AccessToken';
import { useEffect, useRef, useState } from 'react';
import { SelectOption } from '../../../interfaces/SelectOption';
import { Progress } from '../../../interfaces/Progress';
import { useIntl } from 'react-intl';
import { addProgressSettings, deleteProgressSettings } from '../../../api/ProgressSettingsCRUD';
import Swal from 'sweetalert2';
import { useSettingsStore } from '../../../stores/SettingsStore';
import { toTitleCase } from '../../../functions/general';

interface ProgressSettingsModalProps {
    roleId: string
    progressSettings: ProgressSettings
}

const ProgressSettingsModal = ({ roleId, progressSettings }: ProgressSettingsModalProps) => {

    const BASE_URL = process.env.REACT_APP_API_URL
    const token = useAccessToken()
    const intl = useIntl()
    const { settings } = useSettingsStore()

    const [progressesOptions, setProgressesOptions] = useState<SelectOption[]>([])

    const [selectedAccessType, setSelectedAccessType] = useState<SelectOption | null>()
    const [selectedProgresses, setSelectedProgresses] = useState<SelectOption[]>([])

    const [isSubmitting, setIsSubmitting] = useState(false)
    const modalCloseBtnRef = useRef<HTMLButtonElement>(null)

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)
        .catch(error => {
            throw error.response ? error.response.data : error;
        })

    const { data: progresses, error, isLoading } = useSWR(`${BASE_URL}lead_progresses`, fetcher)

    useEffect(() => {
        if (progresses) {

            const progressesOptions = progresses.map((progress: Progress) => ({
                label: progress.name,
                value: progress.id
            }))

            const newLabel = settings.caps_lock_inputs ? 'NEW' : 'New'

            const allProgresses = { label: 'Semua Progress', value: 'all' }

            setProgressesOptions([allProgresses, { label: newLabel, value: 'new' }, ...progressesOptions])
        }
    }, [progresses])

    const handleCloseButtonClick = () => {
        setSelectedAccessType(null)
        setSelectedProgresses([])
    }

    const handleUpdateButtonClick = async () => {
        setIsSubmitting(true)

        const stringProgresses = selectedProgresses ? selectedProgresses.map((progress: SelectOption) => progress.value) : []

        if (selectedAccessType && stringProgresses[0] !== 'all') {
            try {
                const response = await addProgressSettings(roleId, stringProgresses, selectedAccessType.value, token)

                if (response.status === 200) {
                    modalCloseBtnRef.current?.click()
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to add progress settings',
                    heightAuto: false
                })
                setIsSubmitting(false)
                return
            }
        }

        if (progressSettings && stringProgresses[0] !== 'all') {
            try {
                const response = await addProgressSettings(roleId, stringProgresses, progressSettings.accessType, token)

                if (response.status === 200) {
                    modalCloseBtnRef.current?.click()
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to add progress settings',
                    heightAuto: false
                })
                setIsSubmitting(false)
                return
            }
        } else if (progressSettings.id) {
            try {
                const response = await deleteProgressSettings(progressSettings.id, token)
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: `${intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" })}.`,
                        heightAuto: false,
                    })
                    mutate(`${BASE_URL}role-lead-progress-accesses?roleId=${progressSettings.roleId}`)
                    modalCloseBtnRef.current?.click()
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false
                })
                setIsSubmitting(false)
                return
            }
        }

        setSelectedAccessType(null)
        setSelectedProgresses([])
        mutate(`${BASE_URL}role-lead-progress-accesses?roleId=${roleId}`)
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Progress settings updated successfully',
            heightAuto: false
        })
        modalCloseBtnRef.current?.click()
        setIsSubmitting(false)
    }

    useEffect(() => {
        if (progressSettings) {
            if (progressSettings.accessType === accessType.CREATE_LEAD_LOG) {
                console.log('remove new')
                setProgressesOptions(progressesOptions.filter(progress => progress.value !== 'new'))
            } else {
                if (!progressesOptions.find(progress => progress.value === 'new')) {
                    const newLabel = settings.caps_lock_inputs ? 'NEW' : 'New'
                    setProgressesOptions([{ label: newLabel, value: 'new' }, ...progressesOptions])
                }
            }

            if (progressSettings.leadProgresses === null) {
                setSelectedProgresses([{ label: 'Semua Progress', value: 'all' }])
            } else {
                setSelectedProgresses(progressSettings.leadProgresses?.map((progress) => ({
                    label: progress.name,
                    value: progress.id !== null ? progress.id : 'new'
                })))
            }
        } else {
            setSelectedProgresses([])
        }
    }, [progressSettings])

    const handleChangeAccessType = (selectedOption: SelectOption) => {
        if (selectedOption.value === accessType.CREATE_LEAD_LOG) {
            setProgressesOptions(progressesOptions.filter(progress => progress.value !== 'new'))
        } else {
            if (!progressesOptions.find(progress => progress.value === 'new')) {
                const newLabel = settings.caps_lock_inputs ? 'NEW' : 'New'
                setProgressesOptions([{ label: newLabel, value: 'new' }, ...progressesOptions])
            }
        }
        setSelectedAccessType(selectedOption)
    }

    const handleChangeProgresses = (selectedOptions: SelectOption[]) => {

        if (selectedOptions.find(progress => progress.value === 'all')) {
            setSelectedProgresses([{ label: 'Semua Progress', value: 'all' }])
        } else {
            setSelectedProgresses(selectedOptions)
        }
    }

    return (
        <div className="modal fade" id="add-progress-settings-modal" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Ubah Pengaturan Progress</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-5">
                            {
                                progressSettings &&
                                <Select
                                    value={

                                        {
                                            label: toTitleCase(progressSettings.accessType.replaceAll('_', ' ')).replace('Lead', settings.pipeline_title),
                                            value: progressSettings.accessType
                                        }
                                    }
                                    isDisabled
                                />
                            }
                        </div>
                        <Select
                            value={selectedProgresses}
                            isMulti
                            options={progressesOptions}
                            placeholder="Pilih progress"
                            onChange={(selectedOptions) => handleChangeProgresses(selectedOptions as SelectOption[])}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={modalCloseBtnRef} onClick={handleCloseButtonClick}>
                            {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                        </button>
                        <button
                            type="submit"
                            className={`btn btn-primary me-10 ${isSubmitting ? "disabled" : ""}`}
                            data-kt-indicator={isSubmitting ? 'on' : 'off'}
                            onClick={handleUpdateButtonClick}
                        >
                            <span className="indicator-label">
                                {
                                    !progressSettings ?
                                        intl.formatMessage({ id: 'FORM.ACTION.ADD' })
                                        :
                                        intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })
                                }
                            </span>
                            <span className="indicator-progress">
                                Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ProgressSettingsModal }