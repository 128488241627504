import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { useIntl } from 'react-intl';
import { endOfYear, startOfYear, subYears } from 'date-fns';

export declare type ValueType = [Date?, Date?];
export declare type DateRange = [Date, Date];
export interface RangeType {
    label: React.ReactNode;
    value: DateRange | ((value?: ValueType) => DateRange);
    closeOverlay?: boolean;
    placement?: 'bottom' | 'left';
}

const useCompactPredefinedRanges = () => {
    const intl = useIntl();

    const predefinedRanges: RangeType[] = [
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.THIS_MONTH" }),
            value: [
                startOfMonth(new Date()), endOfMonth(new Date())
            ],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.LAST_MONTH" }),
            value: [
                startOfMonth(subDays(new Date(), 30)),
                endOfMonth(subDays(new Date(), 30))
            ],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.THIS_YEAR" }),
            value: [
                new Date(new Date().getFullYear(), 0, 1),
                endOfYear(new Date())
            ],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.LAST_YEAR" }),
            value: [
                startOfYear(subYears(new Date(), 1)),
                endOfYear(subYears(new Date(), 1))
            ],
            placement: 'left'
        }

    ];

    return predefinedRanges;
}

export default useCompactPredefinedRanges;