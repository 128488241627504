import clsx from "clsx";
import { FC, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { ListProduct, Pipeline } from "../../interfaces/Pipeline";
import moment from "moment-timezone";
import { customNumberFormat, getDateSummary, truncateText } from "../../functions/general";
import { Modal } from "bootstrap";
import { DetailPipeline } from "./DetailPipeline";
import { Dropdown } from "rsuite";
import { UserModel } from "../../app/modules/auth/models/UserModel";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../setup";
import { useIntl } from "react-intl";
import { fabClasses } from "@mui/material";
import { UpdatePipeline } from "./UpdatePipeline";
import { DeletePipeline } from "./DeletePipeline";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useCustomeStore } from "../../stores/CustomeStore";

interface CardPipelineProps {
    data: Pipeline
    setShowUpdateModal: (value: boolean) => void
    setShowDeleteModal: (value: boolean) => void
    setShowAddLogModal: (value: boolean) => void
    setShowDetailProductModal: (value: boolean) => void
    setPipeline: (value: Pipeline) => void
}

const renderIconButton = (props: any, ref: any) => {
    return (
        <button {...props} ref={ref} type="button" className="btn btn-sm btn-icon btn-color-gray-600">
            <span className="svg-icon svg-icon-2">
                <i className={`fas fs-8 fa-ellipsis-v`}></i>
            </span>
        </button>
    );
};

const CardPipeline: FC<CardPipelineProps> = ({ data, setShowUpdateModal, setShowDeleteModal, setShowAddLogModal, setShowDetailProductModal, setPipeline }) => {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const intl = useIntl()
    const { templatePipelineId } = useParams()
    const { companyId } = useCustomeStore()

    const [openMenuId, setOpenMenuId] = useState<string | null>(null);

    useEffect(() => {
        if (showDetailModal && data) {
            const modalElement = document.getElementById(`detail-pipeline-modal-${data.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDetailModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDetailModal, data, setShowDetailModal]);

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    return (
        <div style={{ position: "relative" }}>
            <IconButton
                aria-label={`more-${data.id}`}
                id={`menu-kanban-anchor-${data.id}`}
                onClick={() => handleClick(data.id)}
                style={{ position: "absolute", right: "3px", top: "5px" }}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id={`menu-${data.id}`}
                anchorEl={document.getElementById(`menu-kanban-anchor-${data.id}`)}
                open={openMenuId === data.id}
                onClose={handleClose}
                disableScrollLock={true}
            >
                {
                    (
                        (data.id === user.data.id || user.data.is_superadmin) ||
                        (user.data.permission_pipeline_update !== 'NONE')
                    ) &&
                    <MenuItem onClick={() => {
                        handleClose();
                        setShowUpdateModal(true);
                        setPipeline(data);
                    }}>
                        <i className="fas fa-edit fs-4 me-4"></i>
                        <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                    </MenuItem>
                }
                {
                    (
                        (data.id === user.data.id || user.data.is_superadmin) ||
                        (user.data.permission_pipeline_delete !== 'NONE')
                    ) &&
                    <MenuItem onClick={() => {
                        handleClose();
                        setShowDeleteModal(true);
                        setPipeline(data);
                    }}>
                        <i className="fas fa-trash fs-3 me-5"></i>
                        <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                    </MenuItem>
                }
                {
                    (
                        (data.id === user.data.id || user.data.is_superadmin) ||
                        (user.data.permission_pipeline_log_read !== 'NONE')
                    ) &&
                    <Link to={`log/${data.id}`} className="text-black" target="_blank" onClick={() => handleClose()}>
                        <MenuItem>
                            <i className="fas fa-book fs-3 me-5"></i>
                            <span>{intl.formatMessage({ id: 'FORM.ACTION.LOGS' })}</span>
                        </MenuItem>
                    </Link>
                }
                {
                    (
                        (data.id === user.data.id || user.data.is_superadmin) ||
                        (user.data.permission_pipeline_log_create !== 'NONE')
                    ) && (data.lead_progress_progress_type !== "FAIL" && data.lead_progress_progress_type !== "SUCCESS") &&
                    <MenuItem onClick={() => {
                        handleClose();
                        setShowAddLogModal(true);
                        setPipeline(data);
                    }}>
                        <i className="fas fa-pen-square fs-3 me-5"></i>
                        <span>{intl.formatMessage({ id: 'FORM.ACTION.ADD_LOG' })}</span>
                    </MenuItem>
                }
                {
                    ((data.lead_progress_name).toLocaleLowerCase() === "termin 1" && (user.data.company_id === companyId.uec || user.data.company_id === companyId.looyalIT)) &&
                    <MenuItem onClick={() => {
                        handleClose();
                        setShowAddLogModal(true);
                        setPipeline(data);
                    }}>
                        <i className="fas fa-pen-square fs-3 me-5"></i>
                        <span>{intl.formatMessage({ id: 'FORM.ACTION.ADD_LOG' })}</span>
                    </MenuItem>
                }
                {
                    <MenuItem onClick={() => {
                        handleClose();
                        setShowDetailProductModal(true);
                        setPipeline(data);
                    }}>
                        <i className="fas fa-info-circle fs-3 me-5"></i>
                        <span>{intl.formatMessage({ id: 'FORM.ACTION.PRODUCTS_DETAIL' })}</span>
                    </MenuItem>
                }
            </Menu>

            {/* <Dropdown renderToggle={renderIconButton} placement="bottomEnd" style={{ position: "absolute", right: "3px", top: "5px" }}>
                {
                    (
                        (data.id === user.data.id || user.data.is_superadmin) ||
                        (user.data.permission_pipeline_update !== 'NONE')
                    ) &&
                    <Dropdown.Item onClick={() => { setShowUpdateModal(true); setPipeline(data) }}>
                        <i className="fas fa-edit fs-4 me-4"></i>
                        {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                    </Dropdown.Item>
                }

                {
                    (
                        (data.id === user.data.id || user.data.is_superadmin) ||
                        (user.data.permission_pipeline_delete !== 'NONE')
                    ) &&
                    <Dropdown.Item onClick={() => { setShowDeleteModal(true); setPipeline(data) }}>
                        <i className="fas fa-trash fs-3 me-5"></i>
                        {intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}
                    </Dropdown.Item>
                }

                {
                    (
                        (data.id === user.data.id || user.data.is_superadmin) ||
                        (user.data.permission_pipeline_log_read !== 'NONE')
                    ) &&
                    <Link to={`log/${data.id}`} target="_blank">
                        <Dropdown.Item>
                            <i className="fas fa-book fs-3 me-5"></i>
                            {intl.formatMessage({ id: 'FORM.ACTION.LOGS' })}
                        </Dropdown.Item>
                    </Link>
                }

                {
                    (
                        (data.id === user.data.id || user.data.is_superadmin) ||
                        (user.data.permission_pipeline_log_create !== 'NONE')
                    ) && (data.lead_progress_progress_type !== "FAIL" && data.lead_progress_progress_type !== "SUCCESS") &&
                    <Dropdown.Item onClick={() => { setShowAddLogModal(true); setPipeline(data) }}>
                        <i className="fas fa-pen-square fs-3 me-5"></i>
                        {intl.formatMessage({ id: 'FORM.ACTION.ADD_LOG' })}
                    </Dropdown.Item>
                }

                {
                    <Dropdown.Item onClick={() => { setShowDetailProductModal(true); setPipeline(data) }}>
                        <i className="fas fa-info-circle fs-3 me-5"></i>
                        {intl.formatMessage({ id: 'FORM.ACTION.PRODUCTS_DETAIL' })}
                    </Dropdown.Item>
                }
            </Dropdown> */}
            <div className="card-body" onClick={() => setShowDetailModal(true)}>
                <div className="d-flex flex-stack mb-3">
                    <div className="badge badge-light-dark">{data.owner_name}</div>

                </div>
                <label className="fs-4 fw-bolder text-gray-900 mb-3">{data.merchant_name}</label>
                <div className="d-flex flex-stack flex-wrapr">
                    <div className="fs-7 fw-bolder text-gray-600">
                        <span className="svg-icon svg-icon-3 me-2">
                            <i className="far fa-calendar"></i>
                        </span>
                        {intl.formatMessage({ id: 'FORM.LABEL.DATE_STARTED' })} {moment(data.date_start).format("DD MMM YYYY")}
                    </div>
                    <div className="fs-7 fw-bolder text-gray-600">
                        <span className="svg-icon svg-icon-3 me-2">
                            <i className="fas fa-book"></i>
                        </span>
                        {customNumberFormat(data.lead_logs_count)}
                    </div>
                </div>
                <div className="d-flex flex-stack flex-wrapr">
                    <span className="ms-1 fs-7 fw-bolder">Rp. {customNumberFormat(Math.round(data.nominal))}</span>
                    <span className="ms-1 fs-7 fw-bolder"><i>{getDateSummary(data.lead_age)}</i></span>
                </div>
                {
                    data.note &&
                    <label className="fs-8 text-gray-900">*{truncateText(data.note, 50)}</label>
                }
            </div>
            {
                showDetailModal && <DetailPipeline data={data} />
            }
        </div>
    )
}

export { CardPipeline }