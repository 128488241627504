import {  IVarDocumentationProps, QueryParameter, RequestBody } from "../../../interfaces/OpenAPI";

export const getDocumentationTemplatePipelineProps = ({intl, settings}:IVarDocumentationProps )=>{    

    // ===================== GET PIPELINES =====================
    const getTemplatePipelinesExample = {
        success: true,
        message: "Berhasil mendapatkan daftar kerangka Pipe.",
        data: [
            {
                id: "2607a804-5bff-45bf-b9e4-2d5fc3412ad9",
                name: "Sales Management",
                customFields: {
                    "350a106b-edea-45c4-8da2-83fad376d34d": {
                        name: "Invoice",
                        type: "invoice_reminder"
                    },
                    "9dfd54b2-9b98-45e9-b62f-867db62d710d": {
                        name: "Merchant",
                        type: "merchant",
                        required: false,
                        value: [],
                        score: "10"
                    }
                }
            },
            {
                id: "b1b6afbd-e1e2-41f1-a57f-7df433ad482d",
                name: "IT 3",
                customFields: {
                    "21db1531-2616-4331-9eaa-db8ff9a8d7a3": {
                        name: "Merchant",
                        type: "merchant",
                        required: false,
                        value: [],
                        score: ""
                    }
                }
            }
        ]
    };
    

    const getTemplatePipelinesParams: QueryParameter[] = [
        {
            field: 'dateStartedFrom',
            type: `Date (${intl.formatMessage({id :"FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({id : "OPEN_API.PARAMS.DESC_DATE"})
        },
        {
            field: 'dateStartedTo',
            type: `Date (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({id : "OPEN_API.PARAMS.DESC_DATE"})
        },
        {
            field: 'dateCreatedFrom',
            type: `Date (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({id : "OPEN_API.PARAMS.DESC_DATE"})
        },
        {
            field: 'dateCreatedTo',
            type:  `Date (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({id : "OPEN_API.PARAMS.DESC_DATE"})

        },
        {
            field: 'dateEndedFrom',
            type: `Date (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({id : "OPEN_API.PARAMS.DESC_DATE"})
        },
        {
            field: 'dateEndedTo',
            type:  `Date (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({id : "OPEN_API.PARAMS.DESC_DATE"})
        },
    ]

    const python_get_template_pipelines = `
    import requests
    import json

    params = {
    "dateStartedFrom": "2024-01-01",
    "dateStartedTo": "2024-12-31",
    "dateCreatedForm": "2024-01-01",
    "dateCreatedTo": "2024-12-31",
    "dateEndedForm": "2024-01-01",
    "dateEndedTo": "2024-12-31",
    }


    reqUrl = "https://crm-api.wooblazz.com/lead_templates"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    response = requests.request("GET", reqUrl, headers=headersList, params=params)
    print(response.text)
    `

    const javascript_get_template_pipelines = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/lead_templates";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })};

    const headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json",
        "X-Auth-token": token
    };

    const params = {
        dateStartedFrom: "2024-01-01",
        dateStartedTo: "2024-12-31",
        dateCreatedForm: "2024-01-01",
        dateCreatedTo: "2024-12-31",
        dateEndedForm: "2024-01-01",
        dateEndedTo: "2024-12-31"
    };

    axios.get(reqUrl, {
        headers: headersList,
        params: params
    })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_get__template_pipelines = `
    $reqUrl = "https://crm-api.wooblazz.com/lead_templates";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })};

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $params = [
        "dateStartedFrom" => "2024-01-01",
        "dateStartedTo" => "2024-12-31",
        "dateCreatedForm" => "2024-01-01",
        "dateCreatedTo" => "2024-12-31",
        "dateEndedForm" => "2024-01-01",
        "dateEndedTo" => "2024-12-31"
    ];

    $queryString = http_build_query($params);
    $urlWithParams = $reqUrl . '?' . $queryString;

    $ch = curl_init();

    curl_setopt($ch, CURLOPT_URL, $urlWithParams);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if (curl_errno($ch)) {
        echo 'Error: ' . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    `

    const bash_get_template_pipelines = `
    REQ_URL="https://crm-api.wooblazz.com/lead_templates"
    
    DATE_STARTED_FROM="2024-01-01"
    DATE_STARTED_TO="2024-12-31"
    DATE_CREATED_FROM="2024-01-01"
    DATE_CREATED_TO="2024-12-31"
    DATE_ENDED_FROM="2024-01-01"
    DATE_ENDED_TO="2024-12-31"
    
    TOKEN="//${intl.formatMessage({ id: 'OPEN_API.DESC_TOKEN' })}"
    
    URL_WITH_PARAMS="\${REQ_URL}?dateStartedFrom=\${DATE_STARTED_FROM}&dateStartedTo=\${DATE_STARTED_TO}&dateCreatedForm=\${DATE_CREATED_FROM}&dateCreatedTo=\${DATE_CREATED_TO}&dateEndedForm=\${DATE_ENDED_FROM}&dateEndedTo=\${DATE_ENDED_TO}"
    
    curl -X GET "\${URL_WITH_PARAMS}" \\
        -H "Accept: */*" \\
        -H "Content-Type: application/json" \\
        -H "X-Auth-token: \${TOKEN}"
    `;
    
    // ===================== GET PIPELINE =====================
    const getTemplatePipelineExample = {
        success: true,
        message: "Berhasil mendapatkan kerangka Pipe.",
        data: {
            id: "2607a804-5bff-45bf-b9e4-2d5fc3412ad9",
            name: "Sales Management",
            customFields: {
                "350a106b-edea-45c4-8da2-83fad376d34d": {
                    name: "Invoice",
                    type: "invoice_reminder"
                },
                "9dfd54b2-9b98-45e9-b62f-867db62d710d": {
                    name: "Merchant",
                    type: "merchant",
                    required: false,
                    value: [],
                    score: "10"
                }
            }
        }
    };
    
    const python_get_template_pipeline = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/lead-templates/<<ID>>"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }
    response = requests.request("GET", reqUrl, headers=headersList)

    print(response.text)
    `
    const javascript_get_template_pipeline = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/lead-templates/<<ID>>";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    axios.get(reqUrl, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_get_template_pipeline = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/lead-templates/<<ID>>";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_get_template_pipeline = `
    curl  -X GET
    'https://crm-api.wooblazz.com/lead-templates/<<ID>>'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}'
    `

    const getTemplatePipelineParam = 
    [
        {
            field: 'ID',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_GET_ID" }, { title: intl.formatMessage({id : "OPEN_API.TEMPLATE_PIPELINE"}) }),
            target: 'getTemplatePipelines',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({id : "OPEN_API.TEMPLATE_PIPELINE"}) })
        }
    ] 


    return   {getTemplatePipelinesExample,getTemplatePipelinesParams,python_get_template_pipelines,javascript_get_template_pipelines,  php_get__template_pipelines,bash_get_template_pipelines, getTemplatePipelineExample,  python_get_template_pipeline, javascript_get_template_pipeline, php_get_template_pipeline, bash_get_template_pipeline, getTemplatePipelineParam}
}