import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, InputGroup } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import { changePassword } from '../../api/EmailCRUD'
import { updateNewPassword, UpdateNewPassword } from '../../api/MasterEmailCRUD'
import { Email } from '../../interfaces/Email'
import useAccessToken from '../../hooks/AccessToken'
import useUser from '../../hooks/User'
import { useDataTableStore } from '../../stores/DataTableStore'

const API_URL = process.env.REACT_APP_API_URL

interface ChangePasswordEmailProps {
  show: boolean  // Ubah ini dari optional menjadi required
  onHide: () => void
  myEmail?: string
  typeModul?: string
  email?: Email
  typeModal?: string
  mutate: () => void
}

interface FormValues {
  currentPassword?: string
  newPassword: string
  confirmNewPassword: string
}

const ChangePasswordEmail: React.FC<ChangePasswordEmailProps> = ({ show, onHide, myEmail, typeModul = 'email', email, mutate }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState('');
  const token = useAccessToken();
  const user = useUser();
  const { tablesData, setTablesData } = useDataTableStore();

  const checkPasswordStrength = (password: string) => {
    const hasMinLength = password.length >= 8;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[!@#$%^&*]/.test(password);

    if (hasMinLength && hasLowercase && hasUppercase && hasNumber && hasSymbol) {
      return 'Strong';
    } else if (hasMinLength && ((hasLowercase && hasUppercase) || (hasNumber && hasSymbol))) {
      return 'Medium';
    } else {
      return 'Weak';
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: email?.statusData === 'New' || user.data.is_superadmin ? Yup.object({
      newPassword: Yup.string()
        .min(12, 'Password baru minimal 12 karakter')
        .matches(/^(?=.*[a-z])/, 'Harus memiliki minimal satu huruf kecil')
        .matches(/^(?=.*[A-Z])/, 'Harus memiliki minimal satu huruf kapital')
        .matches(/^(?=.*[0-9])/, 'Harus memiliki minimal satu angka')
        .matches(/^(?=.*[!@#$%^&*])/, 'Harus memiliki minimal satu simbol (!@#$%^&*)')
        .matches(/^\S*$/, 'Password tidak boleh mengandung spasi')
        .required('Password baru harus diisi'),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Password harus sama')
        .matches(/^\S*$/, 'Password tidak boleh mengandung spasi')
        .required('Konfirmasi password harus diisi'),
    }) :
      Yup.object({
        currentPassword: Yup.string().required('Password lama harus diisi'),
        newPassword: Yup.string()
          .min(12, 'Password baru minimal 12 karakter')
          .matches(/^(?=.*[a-z])/, 'Harus memiliki minimal satu huruf kecil')
          .matches(/^(?=.*[A-Z])/, 'Harus memiliki minimal satu huruf kapital')
          .matches(/^(?=.*[0-9])/, 'Harus memiliki minimal satu angka')
          .matches(/^(?=.*[!@#$%^&*])/, 'Harus memiliki minimal satu simbol (!@#$%^&*)')
          .matches(/^\S*$/, 'Password tidak boleh mengandung spasi')
          .required('Password baru harus diisi'),
        confirmNewPassword: Yup.string()
          .oneOf([Yup.ref('newPassword'), null], 'Password harus sama')
          .matches(/^\S*$/, 'Password tidak boleh mengandung spasi')
          .required('Konfirmasi password harus diisi'),
      }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (typeModul === 'email') { // untuk Wooblazz Mail
          if (!myEmail) return
          const response = await changePassword(myEmail, 'test', values.newPassword)
          if (response) {
            Swal.fire({
              icon: 'success',
              title: 'Password berhasil diubah',
              confirmButtonText: 'Ok',
            })
            onHide()
          }
        } else if (typeModul === 'crm') { // untuk ubah password di wooblazz CRM
          const updateEmailData: UpdateNewPassword = {
            id: email?.id,
            oldPassword: values?.currentPassword,
            newPassword: values?.newPassword,
          };
          const updateEmailPassword = updateNewPassword(token, updateEmailData);
          const response = await updateEmailPassword(`${API_URL}email/update-email`); // email/update-email
          if (response) {
            Swal.fire({
              icon: 'success',
              title: 'Password berhasil diubah',
              confirmButtonText: 'Ok',
            })
            setTablesData('emails', tablesData.emails.map((email) =>
              email.id === response.data.id ? response.data : email
            ))
            onHide()
          }
        }

      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'Ok',
        })
        console.error('Error changing password:', error)
      } finally {
        setSubmitting(false)
        formik.resetForm()
        mutate()
      }
    },
  })

  useEffect(() => {
    setPasswordStrength(checkPasswordStrength(formik.values.newPassword));
  }, [formik.values.newPassword]);

  const preventSpace = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const renderPasswordInput = (
    field: keyof FormValues,
    label: string,
    showPassword: boolean,
    setShowPassword: React.Dispatch<React.SetStateAction<boolean>>
  ) => (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{label}</Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            {...formik.getFieldProps(field)}
            isInvalid={!!formik.touched[field] && !!formik.errors[field]}
            onKeyDown={preventSpace}
            className='txt-email'
            autoComplete='off'
          />
          <Button
            variant="outline-secondary"
            onClick={() => setShowPassword(!showPassword)}
          >
            <i className={`bi ${showPassword ? 'bi-eye-slash text-primary' : 'bi-eye text-primary'}`}></i>
          </Button>
          {formik.touched[field] && formik.errors[field] && (
            <Form.Control.Feedback type="invalid">{formik.errors[field]}</Form.Control.Feedback>
          )}

        </InputGroup>
      </Form.Group >
      {field === 'newPassword' && formik.values.newPassword &&
        < div className="d-flex align-content-center gap-2 mb-5">
          <label className='form-label fs-6 fw-bold text-dark my-auto opacity-70'>Password Strength</label>
          <div className={`password-strength my-auto ${passwordStrength.toLowerCase()}`}>
            {passwordStrength}
          </div>
        </div>
      }
    </>
  )

  const handleClose = () => {
    onHide();
    formik.resetForm();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Ubah Password</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" value={myEmail} disabled />
          </Form.Group>
          {
            typeModul === 'crm' && email?.statusData !== 'New' && !user.data.is_superadmin &&
            renderPasswordInput('currentPassword', 'Password Lama', showCurrentPassword, setShowCurrentPassword)
          }
          {renderPasswordInput('newPassword', 'Password Baru', showNewPassword, setShowNewPassword)}
          {renderPasswordInput('confirmNewPassword', 'Konfirmasi Password Baru', showConfirmPassword, setShowConfirmPassword)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { onHide(); formik.resetForm() }}>
            Batal
          </Button>
          <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? 'Menyimpan...' : 'Simpan'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ChangePasswordEmail