import { FC, useRef, useEffect, useState, RefObject, CSSProperties } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { insertEvent } from "../../api/EventCRUD";
import { Calendar } from "../../interfaces/Calendar";
import axios from "axios";
import { SelectOption } from "../../interfaces/SelectOption";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import useSWR from "swr";
import { SketchPicker } from "react-color";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import TimezoneSelect from "../Schedule/TimezoneSelect";
import { DatePicker } from 'rsuite';
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps, isValidUrl } from "../../functions/general";
import { UserCompany } from "../../interfaces/UserCompany";
import { useSettingsStore } from "../../stores/SettingsStore";
import { Merchant } from "../../interfaces/Merchant";
import { useIntl } from "react-intl";
import useOptionReminder from "../../hooks/OptionReminder";
import { Conference, DetailScheduleConferenceProps, ScheduleConference } from "../../interfaces/Conference";
import useUser from "../../hooks/User";
import { insertScheduleConference, updateScheduleConference } from "../../api/ConferenceCRUD";

interface eventProps {
    id: string,
    events: []
}

interface Props {
    closeModalDetailRef: RefObject<HTMLDivElement>
    calendarRef: RefObject<FullCalendar>
    detailEvent: DetailScheduleConferenceProps
    roomList: Conference[]
    dataCalendars: ScheduleConference[]
    listEvent: eventProps[]
    selectedCalendars: string[]
    setListEvent: React.Dispatch<React.SetStateAction<eventProps[]>>
    setDataCalendars: React.Dispatch<React.SetStateAction<ScheduleConference[]>>
}

const UpdateScheduleConference: FC<Props> = ({ roomList, closeModalDetailRef, calendarRef, detailEvent, dataCalendars, listEvent, selectedCalendars, setListEvent, setDataCalendars }) => {
    const user = useUser()
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const token = useAccessToken()
    const { settings } = useSettingsStore()
    const [conferencesData, setConferencesData] = useState<SelectOption[]>([]);
    const intl = useIntl()

    const formik = useFormik({
        initialValues: {
            idConference: detailEvent.roomId,
            roomTitle: detailEvent.roomTitle,
            startDate: moment(detailEvent.start).format("YYYY-MM-DD HH:mm"),
            endDate: moment(detailEvent.end).format("YYYY-MM-DD HH:mm"),
        },
        validationSchema: Yup.object().shape({
            idConference: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.ROOM" }) })),
            roomTitle: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TITLE" }) })),
            startDate: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE_STARTED" }) })),
            endDate: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE_ENDED" }) })),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)

            try {
                const response = await updateScheduleConference(
                    values.idConference,
                    detailEvent.id,
                    moment(values.startDate).toISOString(true),
                    moment(values.endDate).toISOString(true),
                    values.roomTitle,
                    token,
                )
                if (response.data.success || response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" }),
                        timer: 4000,
                        // timerProgressBar: true
                    })

                    var dataEvent = {
                        id: response.data.data.id,
                        title: response.data.data.roomTitle,
                        start: response.data.data.start,
                        end: response.data.data.end,
                        color: '#2e95d1',
                    }

                    calendarRef.current?.getApi().getEventById(detailEvent.roomId)?.remove()
                    dataCalendars.map((value: ScheduleConference) => {
                        calendarRef.current?.getApi().getEventSourceById(value.roomId)?.remove()
                    })

                    const updateEvent = listEvent.map((value: any) => {
                        if (value.events[0]!.id! === detailEvent.id) {
                            return {
                                ...value,
                                id: response.data.data.roomId,
                                events: [dataEvent]
                            }
                        } else {
                            return value;
                        }
                    })

                    const updateDataCalendars = dataCalendars.map((value: ScheduleConference) => {
                        if (value.id === detailEvent.id) return { ...value, ...response.data.data }
                        else return value
                    })

                    setListEvent(updateEvent as eventProps[]);
                    setDataCalendars(updateDataCalendars)

                    var dataSelectCalendar = [...selectedCalendars, response.data.data.roomId]
                    updateEvent.forEach((e: any) => {
                        if (dataSelectCalendar.includes(e.id)) calendarRef.current?.getApi().addEventSource({ id: e.id, events: e.events })
                    })
                    closeModalRef.current?.click();
                    closeModalDetailRef.current?.click();
                }
                else {
                    throw new Error(response.data.message)
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            calendarRef.current?.getApi().getEventById('tempEvent')?.remove()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        const conferencesData = roomList.reduce((acc: SelectOption[], conference: Conference) => {
            if (conference.roomCategory === 'SCHEDULE') {
                acc.push({
                    value: conference.id,
                    label: conference.roomCode,
                });
            }
            return acc;
        }, []);

        setConferencesData(conferencesData)
    }, [roomList])

    return (
        <div className="modal fade" tabIndex={-1} id={"update-event-modal"} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: "FORM.LABEL.UPDATE_EVENT" })}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {/* Title */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.TITLE' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.TITLE' })}
                                    {...formik.getFieldProps('roomTitle')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.roomTitle && formik.errors.roomTitle },
                                    )}
                                    type='text'
                                    name='roomTitle'
                                    style={{ zIndex: 0 }}
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.roomTitle && formik.errors.roomTitle && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.roomTitle}</span>
                                </div>
                            )}

                            <div className="row mb-2">
                                <div className="col">
                                    {/* StartDate */}
                                    <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.DATE_STARTED' }, { title: "" })}</label>
                                    <div className="input-group">
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            cleanable={false}
                                            editable={false}
                                            format="dd/MM/yyyy HH:mm"
                                            container={document.querySelector('#update-event-modal') as HTMLElement}
                                            value={new Date(formik.values.startDate)}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    var val = moment(value).format('YYYY-MM-DD HH:mm');
                                                    var now = moment().format('YYYY-MM-DD HH:mm');
                                                    var isBeforeDate = moment(val).isBefore(now)

                                                    if (isBeforeDate) formik.setFieldValue('startDate', new Date())
                                                    else formik.setFieldValue('startDate', value)
                                                } else formik.setFieldValue('startDate', null)
                                            }}
                                            shouldDisableDate={(date: Date) => {
                                                const currentDate = moment(date);
                                                return currentDate.isBefore(moment(), 'day');
                                            }}
                                            shouldDisableHour={(hour: number, date: Date) => {
                                                const selectedDate = moment(date);
                                                const now = moment();

                                                if (selectedDate.isSame(now, 'day')) {
                                                    return hour < now.hour();
                                                }
                                                return false;
                                            }}
                                            shouldDisableMinute={(minute: number, date: Date) => {
                                                const selectedDate = moment(date);
                                                const now = moment();

                                                if (selectedDate.isSame(now, 'hour')) {
                                                    return minute < now.minute();
                                                }
                                                return false;
                                            }}
                                        />
                                    </div>
                                    {formik.touched.startDate && formik.errors.startDate && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.startDate}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col">
                                    {/* EndDatee */}
                                    <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.DATE_ENDED' }, { title: "" })}</label>
                                    <div className="input-group">
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            cleanable={false}
                                            editable={false}
                                            placement="bottomEnd"
                                            format="dd/MM/yyyy HH:mm"
                                            container={document.querySelector('#update-event-modal') as HTMLElement}
                                            value={new Date(formik.values.endDate)}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    var val = moment(value).format('YYYY-MM-DD HH:mm');
                                                    var now = moment().format('YYYY-MM-DD HH:mm');
                                                    var isBeforeDate = moment(val).isBefore(now)

                                                    if (isBeforeDate) formik.setFieldValue('endDate', new Date())
                                                    else formik.setFieldValue('endDate', value)
                                                } else formik.setFieldValue('endDate', null)
                                            }}
                                            shouldDisableDate={(date: Date) => {
                                                const currentDate = moment(date);
                                                return currentDate.isBefore(moment(), 'day');
                                            }}
                                            shouldDisableHour={(hour: number, date: Date) => {
                                                const selectedDate = moment(date);
                                                const now = moment();

                                                if (selectedDate.isSame(now, 'day')) {
                                                    return hour < now.hour();
                                                }
                                                return false;
                                            }}
                                            shouldDisableMinute={(minute: number, date: Date) => {
                                                const selectedDate = moment(date);
                                                const now = moment();

                                                if (selectedDate.isSame(now, 'hour')) {
                                                    return minute < now.minute();
                                                }
                                                return false;
                                            }}
                                        />
                                    </div>
                                    {formik.touched.endDate && formik.errors.endDate && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.endDate}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { UpdateScheduleConference }