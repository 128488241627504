import React from 'react';
import { Modal } from 'react-bootstrap'
import { KTSVG } from "../../../_metronic/helpers";

interface ImageModalProps {
    show: boolean
    onHide: () => void
    imageUrl: string
    title: string
}

const ImageModal = ({ show, onHide, imageUrl, title }: ImageModalProps) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="image-modal-title"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="image-modal-title">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={imageUrl} alt={title} style={{ maxWidth: '100%', height: 'auto' }} />
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={onHide}
                >
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImageModal;