import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const ROLE = `${API_URL}role/`;
const ACCESS_ROLE_LEAD = `${API_URL}role-lead-template-accesses/`;

export const insertRole = async (
  name: string,
  has_subordinate: boolean,
  permission_pipeline_create: string,
  permission_pipeline_read: string,
  permission_pipeline_update: string,
  permission_pipeline_delete: string,
  permission_meeting_create: string,
  permission_meeting_read: string,
  permission_meeting_update: string,
  permission_meeting_delete: string,
  permission_merchant_create: string,
  permission_merchant_read: string,
  permission_merchant_update: string,
  permission_merchant_delete: string,
  permission_pipeline_log_create: string,
  permission_user_target_set: string,
  is_allowed_export_excel: boolean,
  is_allowed_send_report: boolean,
  is_allowed_access_hpp: boolean,
  token: string
) => {
  return axios.post(
    ROLE,
    {
      name,
      has_subordinate,
      permission_pipeline_create,
      permission_pipeline_read,
      permission_pipeline_update,
      permission_pipeline_delete,
      permission_meeting_create,
      permission_meeting_read,
      permission_meeting_update,
      permission_meeting_delete,
      permission_merchant_create,
      permission_merchant_read,
      permission_merchant_update,
      permission_merchant_delete,
      permission_pipeline_log_create,
      permission_user_target_set,
      is_allowed_export_excel,
      is_allowed_send_report,
      is_allowed_access_hpp,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateRole = async (
  id: string,
  name: string,
  has_subordinate: boolean,
  permission_pipeline_create: string,
  permission_pipeline_read: string,
  permission_pipeline_update: string,
  permission_pipeline_delete: string,
  permission_meeting_create: string,
  permission_meeting_read: string,
  permission_meeting_update: string,
  permission_meeting_delete: string,
  permission_merchant_create: string,
  permission_merchant_read: string,
  permission_merchant_update: string,
  permission_merchant_delete: string,
  permission_pipeline_log_create: string,
  permission_pipeline_log_read: string,
  permission_pipeline_log_update: string,
  permission_pipeline_log_delete: string,
  permission_pipeline_progress_create: string,
  permission_pipeline_progress_read: string,
  permission_pipeline_progress_update: string,
  permission_pipeline_progress_delete: string,
  permission_pipeline_source_create: string,
  permission_pipeline_source_read: string,
  permission_pipeline_source_update: string,
  permission_pipeline_source_delete: string,
  permission_product_create: string,
  permission_product_read: string,
  permission_product_update: string,
  permission_product_delete: string,
  permission_user_create: string,
  permission_user_read: string,
  permission_user_update: string,
  permission_user_delete: string,
  permission_user_target_set: string,
  is_allowed_export_excel: boolean,
  is_allowed_send_report: boolean,
  is_allowed_access_hpp: boolean,
  token: string
) => {
  return axios.patch(
    ROLE + id,
    {
      name,
      has_subordinate,
      permission_pipeline_create,
      permission_pipeline_read,
      permission_pipeline_update,
      permission_pipeline_delete,
      permission_meeting_create,
      permission_meeting_read,
      permission_meeting_update,
      permission_meeting_delete,
      permission_merchant_create,
      permission_merchant_read,
      permission_merchant_update,
      permission_merchant_delete,
      permission_pipeline_log_create,
      permission_pipeline_log_read,
      permission_pipeline_log_update,
      permission_pipeline_log_delete,
      permission_pipeline_progress_create,
      permission_pipeline_progress_read,
      permission_pipeline_progress_update,
      permission_pipeline_progress_delete,
      permission_pipeline_source_create,
      permission_pipeline_source_read,
      permission_pipeline_source_update,
      permission_pipeline_source_delete,
      permission_product_create,
      permission_product_read,
      permission_product_update,
      permission_product_delete,
      permission_user_create,
      permission_user_read,
      permission_user_update,
      permission_user_delete,
      permission_user_target_set,
      is_allowed_export_excel,
      is_allowed_send_report,
      is_allowed_access_hpp,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const deleteRole = async (id: string, token: string) => {
  return axios.delete(ROLE + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const getAccessRolePipeline = async (id: string, token: string) => {
  return axios.get(ACCESS_ROLE_LEAD + id, {
    headers: {
      'X-Auth-token': token,
    },
  })
}

export const setAccessRolePipeline = (leadTemplateIds: string[], idRole: string, token: string) => {
  return axios.patch(ACCESS_ROLE_LEAD+idRole,
      {
          leadTemplateIds
      },
      {
          headers: {
              'X-Auth-token': token
          },
      }
  )
}