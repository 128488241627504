import axios from "axios"


const API_URL = process.env.REACT_APP_API_URL

const WA_CHECK = `https://wa.looyal.id/whatscheck?number=`
const VALIDATION_PHONE_BY_GET_CONTACT = `https://tool.looyal.id/get-contact/search?phone=`
const GET_LIST_SESSIONS = `https://tool.looyal.id/get-contact/available-session`
const UPDATE_WA = `${API_URL}merchant/`

export const checkWhatsapp = async (phone: string) => {
    return axios.get(WA_CHECK + phone)
}

export const validationPhoneByGetContact = async (phone: string, session: string) => {
    return axios.get(VALIDATION_PHONE_BY_GET_CONTACT + phone + "&session=" + session)
}

export const getListSessions = async () => {
    return axios.get(GET_LIST_SESSIONS)
}

export const updateWhatsapp = async (id: string, res: boolean, token: string) => {
    return axios.patch(UPDATE_WA + id + "/update-is-whatsapp-phone",
        {
            isWhatsappPhone: res
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}
