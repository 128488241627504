import { create } from 'zustand'

type CustomChatState = {
    showModalSelectUser: boolean
    setShowModalSelectUser: (showModalSelectUser: boolean) => void
}

export const useCustomChatStore = create<CustomChatState>()((set) => ({
    showModalSelectUser: false,
    setShowModalSelectUser: (showModalSelectUser) => set({ showModalSelectUser }),
}))
