import { FC, useEffect, useRef, useState } from "react";
import useAccessToken from "../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { deleteUserSession } from "../../api/UsersCRUD";
import useUser from "../../hooks/User";
import { useDispatch } from "react-redux";
import * as auth from "../../app/modules/auth/redux/AuthRedux";
import { clearDB } from "../../db";
import Swal from "sweetalert2";
import moment from 'moment-timezone'
import { useLanguageStore } from "../../stores/LanguageStore";
import { changeLanguage } from "../../api/Language";

interface Props {
}

const LanguageModal: FC<Props> = ({ }) => {
    const user = useUser()
    const accessToken = useAccessToken()
    const { locale, setLocale } = useLanguageStore()
    const dispatch = useDispatch()
    const intl = useIntl()
    const modalRef = useRef<HTMLDivElement>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false);

    const handleChangeLanguage = async () => {
        setModalBtnLoading(true)
        
        try {
            const response = await changeLanguage(locale === 'id' ? 'en' : 'id', accessToken)

            if (response.status === 200) {
                console.log('Language changed')
                setLocale(response.data.data.language)
                moment.locale(response.data.data.language)
                window.location.reload()
            }
        } catch {

        }
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`language-modal`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ borderRadius: "1rem" }}>
                        <div className="modal-body text-center" style={{ padding: "50px 0px" }}>
                            <h3 style={{ fontWeight: "700", fontSize: "25px" }}>{intl.formatMessage({ id: "FORM.VALIDATION.TITLE_CHANGE_LANGUAGE" })}</h3>
                            <p className="fs-5 mb-5 pb-5" style={{ fontWeight: "500" }}>{intl.formatMessage({ id: "FORM.VALIDATION.DESC1_CHANGE_LANGUAGE" })} <br /> {intl.formatMessage({ id: "FORM.VALIDATION.DESC2_CHANGE_LANGUAGE" })}</p>
                            <button type="submit" onClick={handleChangeLanguage} className={`btn btn-primary w-50 rounded-pill mb-3 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.RELOAD" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                            <div className="text-dark cursor-pointer" onClick={() => closeModalRef.current?.click()}>
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="btn btn-light d-none"
                        data-bs-dismiss="modal"
                        ref={closeModalRef}
                    >
                        {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                    </button>
                </div>
            </div>
        </>
    )
}

export { LanguageModal }