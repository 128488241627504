import React, { useState } from 'react';
import PreviewAvatar from './PreviewAvatar';

interface AvatarProps {
  url: string;
}

const Avatar: React.FC<AvatarProps> = ({ url }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = () => {
    setIsPreviewOpen(true)
  };

  const closePreview = () => setIsPreviewOpen(false);

  return (
    <>
      <span
        style={{ borderRadius: '100%', backgroundColor: '#DEDEDE', padding: '5px', cursor: 'pointer' }}
        className='my-2'
        onClick={openPreview}
      >
        <img
          style={{ width: '35px', height: '35px', borderRadius: '50%' }}
          src={url}
          alt='user-icon'
        />
      </span>
      <PreviewAvatar isOpen={isPreviewOpen} onClose={closePreview} imageUrl={url} />
    </>
  );
};

export default Avatar;