import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const PRODUCT = `${API_URL}product/`;
const PRODUCTS = `${API_URL}products`;
const ACCESS_PRODUCT_LEAD = `${API_URL}lead-template-product-accesses/`;

interface ProductLabels {
  labelId: string;
  labelName: string;
}

interface ProductUnits {
  unitId: string;
  unitName: string;
  convertionToPieces: number;
}

export const insertProduct = async (
  name: string,
  color: string,
  token: string,
  productLabels?: ProductLabels[],
  productUnits?: ProductUnits[],
  sku?: string,
  note?: string,
  price?: number,
  vatPercentage?:number,
) => {
  return axios.post(
    PRODUCT,
    {
      name,
      color,
      productLabels,
      productUnits,
      sku,
      note,
      price,
      vatPercentage
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateProduct = async (
  id: string,
  name: string,
  color: string,
  token: string,
  productLabels?: ProductLabels[],
  productUnits?: ProductUnits[],
  sku?: string,
  note?: string,
  price?:number,
  vatPercentage?:number
) => {
  return axios.patch(
    PRODUCT + id,
    {
      name,
      color,
      productLabels,
      productUnits,
      sku,
      note,
      price,
      vatPercentage
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const deleteProduct = async (id: string, token: string) => {
  return axios.delete(PRODUCT + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const getProducts = async (token: string) => {
  return axios.get(PRODUCTS, {
    headers: {
      'X-Auth-token': token,
    },
  })
}

export const getAccessProductPipeline = async (id: string, token: string) => {
  return axios.get(ACCESS_PRODUCT_LEAD + id, {
    headers: {
      'X-Auth-token': token,
    },
  })
}