/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { nanoid } from '@reduxjs/toolkit'
import { OTPModal } from '../../../../components/OTPModal'
import { Modal } from 'bootstrap'
import { sendOTP } from '../../../../api/OTP'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})


const initialValues = {
  username: process.env.REACT_APP_ENV === 'development' ? 'tkd-superadmin' : "",
  password: process.env.REACT_APP_ENV === 'development' ? '12345678' : "",
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [phone, setPhone] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [isShowPassword, setIsShowPassword] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)

      try {
        const response = await sendOTP(values.username, values.password)
        if (response.data.success) {
          setPhone(response.data.data.userPhoneCensored)
          setUsername(values.username)
          setPassword(values.password)
          const modalElement = document.getElementById(`otp-modal-login`);

          if (modalElement) {
            const modalInstance = new Modal(modalElement);
            modalInstance.show();
          }
        }
      } catch (error: any) {
        if (error.response.status === 500) {
          setStatus(error.response.data)
        } else {
          setStatus(error.response.data.message)
        }
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Masuk</h1>
        </div>
        {/* begin::Heading */}

        {formik.status && (
          <div className='mb-lg-10 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
          <input
            placeholder='Username'
            {...formik.getFieldProps('username')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.username && formik.errors.username },
            )}
            type='text'
            name='username'
            autoComplete='off'
            style={{ backgroundColor: "#f5f8fa" }}
          />
          {formik.touched.username && formik.errors.username && (
            <div className='fv-plugins-message-container'>
              <span role='alert' className='text-danger'>{formik.errors.username}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
            </div>
          </div>
          <div className={`input-group mb-3`}>
            <input
              type={isShowPassword ? 'text' : 'password'}
              placeholder='********'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid txt-password',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
              )}
              aria-describedby="groupOldPassword"
              style={{ borderRight: "none", borderTop: "none", borderBottom: "none", borderLeft: "none", backgroundColor: "#f5f8fa" }}
            />
            <span className="input-group-text" id="groupOldPassword" onClick={() => setIsShowPassword(!isShowPassword)} style={{ backgroundColor: "#f5f8fa", border: "none" }}>
              {isShowPassword ? <i className="fas fa-eye"></i> : <i className="far fa-eye-slash"></i>}
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={loading}
          >
            {!loading && <span className='indicator-label'>Masuk</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Loading...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
      <OTPModal username={username} password={password} phone={phone} type={"login"} />
    </>
  )
}
