import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { KTSVG } from '../../../../_metronic/helpers';
import useHandleCloseModal from '../../../../hooks/HandleCloseModal';
import { getColumnCustomFields, updateGroupContactEmailCustomField } from '../../../../api/EmailCRUD';
import Swal from 'sweetalert2';
import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';
import { useCustomFieldStore } from '../../../../stores/FieldEmailStore';
import { useDataTableStore } from '../../../../stores/DataTableStore';
import { useEmailStore } from '../../../../stores/EmailStore';


interface Column {
  id: string;
  name: string;
  type: 'text' | 'number' | 'date'
  required: boolean;
  options?: string[];
}

interface OptionType {
  value: 'text' | 'number' | 'date'
  label: string;
}

const typeOptions: OptionType[] = [
  { value: 'text', label: 'Teks' },
  { value: 'number', label: 'Angka' },
  { value: 'date', label: 'Tanggal' },
];

interface ManageColumnsModalProps {
  idGroupContact: string
}

const API_URL = process.env.REACT_APP_API_URL_EMAIL

const ManageColumnsModal: React.FC<ManageColumnsModalProps> = ({ idGroupContact }: ManageColumnsModalProps) => {
  const [columns, setColumns] = useState<Column[]>([]);
  const intl = useIntl();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const closeModalRef = useRef<HTMLButtonElement>(null);
  const [originalColumns, setOriginalColumns] = useState<Column[]>([]);
  const { setCustomFieldStore } = useCustomFieldStore()
  const { setCustomVariables } = useEmailStore()
  const { data, mutate } = useSWR(`${API_URL}groupcontact/colloum-group-contact?idGroupContact=${idGroupContact}`, getColumnCustomFields);

  useEffect(() => {
    if (data && data.length > 0) {
      const formattedColumns = Object.entries(data[0]).map(([id, columnData]: [string, any]) => ({
        id,
        name: columnData.name,
        type: columnData.type,
        required: columnData.required,
        options: columnData.value,
      }));
      setColumns(formattedColumns);
      setOriginalColumns(formattedColumns);
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const newValues = columns.reduce((acc: any, column) => {
          acc[column.id] = {
            name: column.name,
            type: column.type,
            required: column.required,
            value: column.options || [],
          };
          return acc;
        }, {});

        // Optimistic update
        const optimisticData = [newValues]; // Format data sesuai dengan struktur yang diharapkan
        setCustomVariables(optimisticData);
        setCustomFieldStore(newValues);

        const response = await updateGroupContactEmailCustomField(idGroupContact, newValues);
        if (response.success) {
          // Update cache SWR
          mutate(`${API_URL}groupcontact/colloum-group-contact?idGroupContact=${idGroupContact}`);
          mutate(`${API_URL}groupcontact/user-list-group-contact?idGroupContact=${idGroupContact}`);

          Swal.fire({
            title: 'Success',
            text: 'Data has been updated',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
          closeModalRef.current?.click();
        }
      } catch (error) {
        // Rollback jika gagal
        mutate(); // Revalidate data
        Swal.fire({
          title: 'Error',
          text: 'Failed to update data',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (data && data.length > 0) {
      setCustomFieldStore(data[0])
      setCustomVariables(data)
    }
  }, [data])

  const addColumn = () => {
    const newColumn: Column = {
      id: uuidv4(),
      name: '',
      type: 'text',
      required: false,
    };
    setColumns([...columns, newColumn]);
  };

  const removeColumn = (id: string) => {
    const newFilterValues = columns.filter(column => column.id !== id);
    setColumns(newFilterValues);
  };

  const handleColumnChange = (id: string, field: keyof Column, value: any) => {
    const newValues = columns.map(column =>
      column.id === id ? { ...column, [field]: value } : column
    );
    setColumns(newValues);
  };

  useEffect(() => {
    const modalElement = modalRef.current;

    const handleModalHidden = () => {
      formik.resetForm();
      setColumns([...originalColumns]);
    };

    modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

    return () => {
      modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
    };
  }, [formik, originalColumns]);

  const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

  return (
    <div>
      <button type="button" className="btn btn-light-primary mt-3" data-bs-toggle="modal" data-bs-target="#add-manage-column">
        <i className="fas fa-columns fs-3 me-1"></i>Kelola Kolom
      </button>
      <div className="modal fade" tabIndex={-1} id="add-manage-column" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Kelola Kolom</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                onClick={handleCloseModal}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="modal-body">
                {columns.map((column, index) => (
                  <>
                    <div key={index} className="mb-4">
                      <div className="row align-items-center mb-2">
                        <div className="col-12 col-md-2 mb-2 mb-md-0">
                          <h6 className="mb-0">Kolom {index + 1}</h6>
                        </div>
                        <div className="col-12 col-md-5 mb-2 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            value={column.name}
                            onChange={(e) => handleColumnChange(column.id, 'name', e.target.value)}
                            placeholder="Nama Kolom"
                          />
                        </div>
                        <div className="col-12 col-md-5">
                          <Select
                            options={typeOptions}
                            value={typeOptions.find(option => option.value === column.type)}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                handleColumnChange(column.id, 'type', selectedOption.value);
                              }
                            }}
                            placeholder="Pilih Tipe"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-6 col-md-3 offset-md-2">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`required-${column.id}`}
                              checked={column.required}
                              onChange={(e) => handleColumnChange(column.id, 'required', e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor={`required-${column.id}`}>
                              Wajib diisi
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-md-7 text-end">
                          <button
                            type="button"
                            className="btn btn-sm btn-light-danger"
                            onClick={() => removeColumn(column.id)}
                          >
                            <i className='fas fa-trash me-2'></i>Hapus
                          </button>
                        </div>
                      </div>
                    </div>
                    <div key={column.id} className="separator separator-dashed mb-10"></div>
                  </>

                ))}
                <button
                  type="button"
                  className="btn btn-light-primary"
                  onClick={addColumn}
                >
                  <i className='fas fa-plus me-2'></i>Tambah Kolom
                </button>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  ref={closeModalRef}
                >
                  {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                </button>
                <button
                  type="submit"
                  className={`btn btn-primary ${formik.isSubmitting ? 'disabled' : ''}`}
                  data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}
                >
                  <span className="indicator-label">
                    {intl.formatMessage({ id: 'FORM.ACTION.SAVE' })}
                  </span>
                  <span className="indicator-progress">
                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageColumnsModal;