import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import { insertProgress } from "../../api/ProgressesCRUD";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import { SelectOption } from "../../interfaces/SelectOption";
import moment from "moment";
import { Target } from "../../interfaces/Target";
import { insertTarget } from "../../api/TargetCRUD";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { User } from "../../interfaces/User";
import { UserModel } from "../../app/modules/auth/models/UserModel";
import { RootState } from "../../setup";
import { shallowEqual, useSelector } from "react-redux";
import { DatePicker } from "rsuite";
import { useSettingsStore } from "../../stores/SettingsStore";
import { startOfMonth } from "date-fns";
import { KTSVG } from "../../_metronic/helpers";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

const customNumberFormat = (valueString: string) => {
    const onlyNumbers = valueString.replace(/\D+/g, '');  // remove non-digits
    const parts = onlyNumbers.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
}

const AddTarget: FC = () => {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()
    const datePickerRef = useRef<HTMLInputElement>(null);
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const { settings } = useSettingsStore()
    const [userFormik, setUserFormik] = useState<string[]>([]);

    const intl = useIntl()

    const typeTargetData = [{
        label: intl.formatMessage({ id: "TARGET.TYPE.PAID_PIPELINE_NOMINAL" }, { title: settings.pipeline_title }),
        value: "PIPELINE SUCCESS NOMINAL"
    }, {
        label: intl.formatMessage({ id: "TARGET.TYPE.PRODUCTS_SOLD_QUANTITY" }),
        value: "PRODUCT SOLD QTY"
    }, {
        label: intl.formatMessage({ id: "TARGET.TYPE.MEETINGS_COUNT" }),
        value: "MEETING COUNT"
    }]

    const API_URL = process.env.REACT_APP_API_URL
    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const getUserURL = () => {
        if (user.data.permission_user_target_set === "ALL") {
            return `${API_URL}users`
        } else if (user.data.permission_user_target_set === "TEAM" || user.data.permission_user_target_set === "TEAM EXCEPT SELF") {
            return `${API_URL}user/one-level-subordinates`
        } else {
            return `${API_URL}user/subordinates`
        }
    }

    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(getUserURL(), fetcher)

    useEffect(() => {
        if (!usersLoading) {
            const usersData = users.map((user: User) => ({
                value: user.id,
                label: user.name
            }));
            if (user.data.permission_user_target_set === "WHOLE TEAM" || user.data.permission_user_target_set === "TEAM") {
                usersData.unshift({ value: user.data.id, label: user.data.name })
            }
            setUsersData(usersData)
        }
    }, [users])

    const handleDateOnClick = () => {
        datePickerRef.current?.showPicker();
    }

    const handleNominalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = customNumberFormat(e.target.value);
        formik.setFieldValue('nominal', formattedValue);
    };

    const formik = useFormik({
        initialValues: {
            user: userFormik,
            nominal: '',
            date: null,
            type: ''
        },
        validationSchema: Yup.object().shape({
            nominal: Yup.string()
                .required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TARGET_VALUE" }) }))  // Moved this up so it runs first
                .test('is-numeric', 'Invalid format', value => {
                    return value ? !isNaN(Number(value.replace(/\./g, ''))) : true;  // If value is present, then check format
                }),
            user: Yup.array().min(1, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })),
            date: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PERIOD" }, { title: "" }) })).nullable(),
            type: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TARGET_TYPE" }) })),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setSubmitting(true)
            setModalBtnLoading(true)

            try {
                for (const user of values.user) {
                    const response = await insertTarget(user, (values.nominal).split('.').join(''), moment(values.date).format("YYYY-MM") + "-01", values.type, token)
                }

                Swal.fire({
                    icon: "success",
                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                    text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" })}.`,
                    heightAuto: false,
                })
                closeModalRef.current?.click();
                mutate(`${API_URL}user-targets`);

                formik.resetForm()
                formik.setFieldValue('date', null)
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            formik.setFieldValue('date', null)
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const handleChange = (
        selectedOptions: any,
    ) => {
        const selectedValues = (selectedOptions as SelectOption[]).map(option => option.value);
        formik.setFieldValue(
            'user',
            selectedOptions ? selectedValues : [],
        );
    };
    
    const getValue = () => {
        if (formik.values.user.length > 0) return usersData.filter((option) => formik.values.user.indexOf(option.value) >= 0)
            else return []
    };

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <button type="button"
                className="btn btn-primary mt-3"
                data-bs-toggle="modal"
                data-bs-target="#tambah-target-modal"
            >
                <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'FORM.MENU.TARGETS' })}
            </button>
            <div className="modal fade" tabIndex={-1} id="tambah-target-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'FORM.MENU.TARGETS' })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.USER' })}</label>
                                <div className="input-group mb-3">
                                    <Select
                                        key={nanoid()}
                                        options={usersData}
                                        placeholder={intl.formatMessage({ id: 'FORM.ACTION.CHOOSE_USER' })}
                                        value={getValue()}
                                        onChange={
                                            // option => formik.handleChange({
                                            //     target: {
                                            //         name: "user",
                                            //         value: option?.value
                                            //     }
                                            // })
                                            handleChange
                                        }
                                        onBlur={
                                            () => formik.values.user.length === 0 && formik.setFieldTouched("user", true)
                                        }
                                        className="w-100"
                                        isDisabled={usersLoading}
                                        isMulti={true}
                                    />
                                </div>
                                {formik.touched.user && formik.errors.user && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.user}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.DATE' }, { title: '' })}</label>
                                <div className="input-group mb-3">
                                    <DatePicker
                                        editable={false}
                                        oneTap
                                        value={formik.values.date}
                                        format="MM-yyyy"
                                        style={{ width: "100%", zIndex: 0 }}
                                        container={document.querySelector('#tambah-target-modal') as HTMLElement}
                                        onChange={(value: any) => {
                                            if (value) formik.setFieldValue('date', value)
                                            else formik.setFieldValue('date', null)
                                        }}
                                        shouldDisableDate={(date) => {
                                            return date < startOfMonth(new Date());
                                        }}
                                    />
                                </div>
                                {formik.touched.date && formik.errors.date && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.date}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.TARGET_TYPE' })}</label>
                                <div className="input-group mb-3">
                                    <Select
                                        key={nanoid()}
                                        options={typeTargetData}
                                        placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.TARGET_TYPE' })}`}
                                        value={typeTargetData.find(option => option.value === formik.values.type)}
                                        onChange={
                                            option => formik.handleChange({
                                                target: {
                                                    name: "type",
                                                    value: option?.value
                                                }
                                            })
                                        }
                                        onBlur={
                                            () => formik.values.type === "" && formik.setFieldTouched("type", true)
                                        }
                                        className="w-100"
                                        isDisabled={usersLoading}
                                    />
                                </div>
                                {formik.touched.type && formik.errors.type && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.type}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.TARGET_VALUE' })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={intl.formatMessage({ id: 'FORM.LABEL.TARGET_VALUE' })}
                                        value={formik.values.nominal}
                                        onChange={handleNominalChange}
                                        onBlur={formik.handleBlur}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.nominal && formik.errors.nominal },
                                        )}
                                        type='text'
                                        name='nominal'
                                        autoComplete='off'
                                        style={{ zIndex: 0 }}
                                    />
                                </div>
                                {formik.touched.nominal && formik.errors.nominal && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.nominal}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { AddTarget }