import React, { ReactNode, useEffect, useState } from 'react';
import MasterTableEmail from '../table/MasterTableEmail';
import { TableColumn } from 'react-data-table-component';
import { Tooltip } from "@mui/material";
import { ContentTemplateAttachment, ContentType } from '../../../../interfaces/Email/BroadCastEmailType';
import { useEmailStore } from '../../../../stores/EmailStore';
import { ModalAttachment } from '../ModalAttachment';
import { Modal } from 'bootstrap';
import { renderTooltipContent } from '../../UtilityComponent';
import { renderTableContent } from '../../UtilityComponent';

const DetailDraftBroadcast = () => {
    const { idDetailBroadcastEmail } = useEmailStore();
    const [showModalAttachment, setShowModalAttachment] = useState<boolean>(false);
    const [url, setUrl] = useState<ContentTemplateAttachment[]>([]);

    const baseTableColumns: TableColumn<ContentType>[] = [
        {
            name: 'Template',
            width: '350px',
            sortable: true,
            allowOverflow: true,
            reorder: true,
            // selector: row => ,
            cell: row => {
                console.log(row, 'row')
                // const parsedContent = parseContent(row.content);
                return (
                    <Tooltip title={renderTooltipContent(row)} arrow>
                        <div className="truncate-text">
                            {renderTableContent(row)}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            name: 'Attachment',
            width: '100px',
            sortable: true,
            cell: row => {
                const sources = (row.attachment as ContentTemplateAttachment[]).map((item: ContentTemplateAttachment) => item.source)
                return sources.length > 0 ? (
                    <span role="button" onClick={() => {
                        setShowModalAttachment(true);
                        setUrl(row.attachment as ContentTemplateAttachment[]);
                    }}>
                        <i className="fas fa-file-invoice fs-3 float-end"></i>
                    </span>
                ) : '-';
            },
            reorder: true,
        },
    ];

    useEffect(() => {
        if (showModalAttachment && idDetailBroadcastEmail) {
            const modalElement = document.getElementById(`attachments-broadcast-modal-${idDetailBroadcastEmail}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                const handleModalHide = () => {
                    setShowModalAttachment(false);
                };

                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showModalAttachment, idDetailBroadcastEmail, setShowModalAttachment]);


    let actions: ReactNode[] = []

    return (
        <>
            <div className='row'>
                <MasterTableEmail
                    tableColumns={baseTableColumns}
                    actions={actions}
                    tableKey='detailDraftBroadcastEmail'
                    apiURL={`broadcast/get-broadcast-draft?id=${idDetailBroadcastEmail}`}
                />
            </div>

            {
                showModalAttachment && (
                    <ModalAttachment
                        id={idDetailBroadcastEmail}
                        title='Attachments'
                        url={url}
                    />
                )
            }
        </>
    )
}

export default DetailDraftBroadcast