import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import { Pipeline, ListProduct } from '../interfaces/Pipeline';
import { TableColumn } from 'react-data-table-component';
import { calculateHaversineDistance, getDateSummary, sortColumnDatatable } from './general';
import moment from 'moment';
import { Merchant } from '../interfaces/Merchant';
import { Meeting, MeetingAttendance } from '../interfaces/Meeting';
import { ResponseSuccessValidationPhoneProp } from '../interfaces/ValidationPhone';
import 'moment-duration-format';
import { customDistanceFormat } from '../functions/general';
import { UserModel } from '../app/modules/auth/models/UserModel';
import addDays from 'date-fns/addDays';
import { MyAttendance } from '../interfaces/Attendance';

const formatCustomField = (source: string, row: any, field: string, type: string) => {
  if (source === 'Pipeline' && (!row.custom_fields || !row.custom_fields[field])) return '-';
  if (source === 'Merchant' && (!row.customFields || !row.customFields[field])) return '-';

  const value = (row as any)[`custom_fields.${field}`];
  if (type === 'invoice_reminder') {
    const parsedValue = JSON.parse(value);
    return [
      parsedValue.invoice_url,
      parsedValue.date_due ? moment(parsedValue.date_due).format('DD MMM YYYY') : '-',
    ];
  }
  if (type === 'date') return value ? moment(value).format('DD MMM YYYY') : '-';
  if (type === 'multiple') return value.toString().split(',').join(', ');
  if (type === 'images' || type === 'files') return value.toString().split(',').join('\n ');
  if (type === 'serial') return value.join('');

  return value;
};

const formatLeadProductDetails = (leadProducts: ListProduct[], detailType: keyof ListProduct) => {
  const details = leadProducts.map((product) => product[detailType]);
  return details.length > 0 ? details.join('\n') : '-';
};

const getStatus = (status: string) => {
  switch (status) {
    case 'hot':
      return '(Hot)';
    case 'cold':
      return '(Cold)';
    default:
      return '';
  }
};

export const exportExcel = async (
  nameFile: string,
  source: string,
  settings: any,
  nameHeader: {
    [key: string]: string[];
  },
  param: any,
  selectMultiPipeline: any,
  selectMultiMerchant: any,
  data?: any[],
  columns?:
    | TableColumn<Pipeline>[]
    | TableColumn<Merchant>[]
    | TableColumn<Meeting>[]
    | TableColumn<ResponseSuccessValidationPhoneProp>[],
  user?: UserModel,
  dateStart?: any,
  dateEnd?: string,
  filter?: string,
  holiday?: any[],
  isFilterDate?: boolean,
) => {
  let exportData: any[] = [];
  var arrayCustomFieldPipelines = Object.keys(selectMultiPipeline).length !== 0
    ? selectMultiPipeline.customFields
    : [];
    var arrayCustomFieldMerchants = Object.keys(selectMultiMerchant).length !== 0
    ? selectMultiMerchant.customFields
    : [];
  var arrayCustomFieldMeeting = settings.meeting_custom_fields
    ? JSON.parse(settings.meeting_custom_fields)
    : [];

  const workbook = createWorkbook();
  const worksheet = createWorksheet(workbook, source);
  let subSectorIndex = -1;
  let invoiceIndex = -1;

  if (source === 'Pipeline') {
    var header = [
      ...nameHeader.pipeline,
      ...Object.keys(arrayCustomFieldPipelines).flatMap((key) => {
        if (arrayCustomFieldPipelines[key].type === 'invoice_reminder') {
          return [
            arrayCustomFieldPipelines[key].name + ' (File)',
            arrayCustomFieldPipelines[key].name + ' (Tgl Expired)',
          ];
        } else {
          return arrayCustomFieldPipelines[key].name;
        }
      }),
    ];

    if (user?.data.company_id !== "d2f2a3da-3829-4872-8891-cae921d555b5") {
      header.splice(2, 1);
    }

    if (
      user?.data.company_id === param.companyId.enterpriseSales ||
      user?.data.company_id === param.companyId.enterpriseService
    ) {
      header.push('Invoice');

      invoiceIndex = header.map((item) => item.toLowerCase()).indexOf('invoice');
      sortColumnDatatable(header, invoiceIndex, 2);
    }

    if (user?.data.company_name === 'Heiszco') {
      subSectorIndex = header.map((item) => item.toLowerCase()).indexOf('sub sector/nama project');
      sortColumnDatatable(header, 10, 4);
      sortColumnDatatable(header, subSectorIndex, 5);
    }

    worksheet.getRow(1).values = header;
    Array.from(data!).forEach((row, index) => {
      const temp_excel = [
        moment(row.date_created).format('DD MMM YYYY'),
        `${row.merchant_name} ${getStatus(row.hot_warm_cold_status)}`,
        row.note ? row.note : '-',
        row.owner_name,
        parseInt(row.lead_logs_count),
        row.lead_subprogress_name
          ? `${row.lead_progress_name} (${row.lead_subprogress_name})`
          : row.lead_progress_name,
        row.merchant_phone,
        row.merchant_is_whatsapp_phone ? 'Valid' : 'Tidak Valid',
        getDateSummary(row.lead_age),
        row.nominal,
        formatLeadProductDetails(row.leadProducts, 'productName'),
        formatLeadProductDetails(row.leadProducts, 'productLabels'),
        formatLeadProductDetails(row.leadProducts, 'leadProductQty'),
        row.lead_source_name,
        row.creator_name,
        moment(row.date_start).format('DD MMM YYYY'),
        row.date_end ? moment(row.date_end).format('DD MMM YYYY') : '-',
      ];

      if (selectMultiPipeline.customFields) {
        Object.keys(arrayCustomFieldPipelines).forEach((field) => {
          const type = arrayCustomFieldPipelines[field].type;
          const formattedField = formatCustomField(source, row, field, type);

          if (type === 'invoice_reminder') {
            temp_excel.push(...formattedField);
          } else {
            temp_excel.push(formattedField);
          }
        });
      }

      if (user?.data.company_id !== "d2f2a3da-3829-4872-8891-cae921d555b5") {
        temp_excel.splice(2, 1);
      }

      if (user?.data.company_id === param.companyId.enterpriseSales) {
        temp_excel.push(
          row.custom_fields[param.keyCustomfield.serial.enterpriseSales]
            ? (row.custom_fields[param.keyCustomfield.serial.enterpriseSales] as string[]).join('')
            : '-'
        );
        sortColumnDatatable(temp_excel, invoiceIndex, 2);
      }
      if (user?.data.company_id === param.companyId.enterpriseService) {
        temp_excel.push(
          row.custom_fields[param.keyCustomfield.serial.enterpriseService]
            ? (row.custom_fields[param.keyCustomfield.serial.enterpriseService] as string[]).join('')
            : '-'
        );
        sortColumnDatatable(temp_excel, invoiceIndex, 2);
      }

      if (user?.data.company_name === 'Heiszco') {
        sortColumnDatatable(temp_excel, 10, 4);
        sortColumnDatatable(temp_excel, subSectorIndex, 5);
      }

      exportData.push(temp_excel);
    });

    addDataRows(worksheet, exportData, source, param, columns!, user);
    setColumnWidths(worksheet, source, arrayCustomFieldPipelines, exportData);
    styleColumns(worksheet, source, param, columns!, 1, user);
  } else if (source === 'Merchant') {
    if (isFilterDate && dateStart && dateEnd) {
      addFileInformationDateRange(worksheet, dateStart, dateEnd);
    }

    Array.from(data!).map((row, index) => {
      var temp_excel = [];
      temp_excel.push(
        row.name,
        row.ownerName,
        row.cityName,
        row.phone,
        row.isWhatsappPhone ? 'Valid' : 'Tidak Valid',
        row.lastMeetEnd,
        row.lastLeadCreatedTime,
        row.leadsCount,
        row.meetingsCount,
        row.creatorName,
        moment(row.dateCreated).format('DD MMM YYYY HH:mm')
      );

      if (selectMultiMerchant.customFields) {
        Object.keys(arrayCustomFieldMerchants).forEach((field) => {
          const type = arrayCustomFieldMerchants[field].type;
          const formattedField = formatCustomField(source, row, field, type);
  
          if (type === 'invoice_reminder') {
            temp_excel.push(...formattedField);
          } else {
            temp_excel.push(formattedField);
          }
        });
      }

      exportData.push(temp_excel);
    });


    worksheet.getRow(isFilterDate ? 4 : 1).values = [
      ...nameHeader.merchant,
      ...Object.keys(arrayCustomFieldMerchants).map((field: any) => arrayCustomFieldMerchants[field].name),
    ];

    addDataRows(worksheet, exportData, source, param, columns!);
    setColumnWidths(worksheet, source, arrayCustomFieldMerchants, exportData);
    styleColumns(worksheet, source, param, columns!, 1, undefined, isFilterDate);
  } else if (source === 'Meeting') {
    Array.from(data!).map((row: any, index) => {
      var temp_excel = [];
      temp_excel.push(
        row.attachments.length > 0 ? row.attachments[0].url : '-',
        row.merchant_name,
        row.owner_name,
        row.allDay ? moment(row.date_meet).format('DD MMM YYYY') : moment(row.date_meet).format('DD MMM YYYY HH:mm'),
        row.date_meet_end === null ? '-' : row.allDay ? moment(row.date_meet_end).format('DD MMM YYYY') : moment(row.date_meet_end).format('DD MMM YYYY HH:mm'),
        row.attendees.length > 0 ? row.attendees.map((data: MeetingAttendance) => data.name).join(', ') : '-',
        row.creator_name,
        row.note,
        row.location,
        row.reminderMinutes === null ? '-' : String(row.reminderMinutes),
        row.type === "MEETING" ? "-" : row.duration,
        row.type === "MEETING" ? "Meeting" : "Visit",
        row.status
      );

      exportData.push(temp_excel);
    });

    if (settings.meeting_custom_fields) {
      data!.map((row: any, indexData: number) => {
        Object.keys(arrayCustomFieldMeeting).map((field, index) => {
          if (arrayCustomFieldMeeting[field].type === 'date') {
            exportData[indexData].push(
              row.custom_fields && row.custom_fields[field]
                ? moment(row.custom_fields[field]).format('DD MMM YYYY')
                : '-'
            );
          } else if (arrayCustomFieldMeeting[field].type === 'multiple') {
            exportData[indexData].push(
              row.custom_fields && row.custom_fields[field]
                ? row.custom_fields[field].toString().split(',').join(', ')
                : '-'
            );
          } else if (arrayCustomFieldMeeting[field].type === 'serial') {
            exportData[indexData].push(
              row.custom_fields && row.custom_fields[field]
                ? row.custom_fields[field].join('')
                : '-'
            );
          } else {
            exportData[indexData].push(
              row.custom_fields && row.custom_fields[field] ? row.custom_fields[field] : '-'
            );
          }
        });
      });
    }

    worksheet.getRow(1).values = [
      ...nameHeader.meeting,
      ...Object.keys(arrayCustomFieldMeeting).map((field: any) => arrayCustomFieldMeeting[field].name),
    ];

    addDataRows(worksheet, exportData, source, param, columns!);
    setColumnWidths(worksheet, source, arrayCustomFieldMeeting, exportData);
    styleColumns(worksheet, source, param, columns!, 1);
  } else if (source === 'Report Logs Pipeline') {
    const formatAttachments = (attachments: any[]): string => {
      if (!attachments || attachments.length === 0) return '-';
      return attachments.map((att) => att.url).join('\n');
    };
    Array.from(data!).map((row, index) => {
      var temp_excel = [];
      temp_excel.push(
        row.creator_name ?? '-',
        moment(row.date_created).format('DD MMMM YYYY HH:mm'),
        row.duration,
        row.lead_sub_progress_name
          ? row.lead_progress_name + ' (' + row.lead_sub_progress_name + ')'
          : row.lead_progress_name,
        row.description,
        formatAttachments(row.attachments)
      );
      exportData.push(temp_excel);
    });

    const title = nameFile.substring(nameFile.indexOf('[') + 1, nameFile.indexOf(']'));
    worksheet.getCell('A1').value = 'Laporan Log Pipeline';
    worksheet.getCell('A2').value = title;
    worksheet.mergeCells('A1', 'F1');
    worksheet.mergeCells('A2', 'F2');

    worksheet.getRow(4).values = [...nameHeader.reportLog];

    addDataRows(worksheet, exportData, source, param, columns!);
    setColumnWidths(worksheet, source, arrayCustomFieldMeeting, exportData);
    styleColumns(worksheet, source, param, columns!, 4);
  } else if (source === 'success-validation-phone') {
    Array.from(data!).map((row, index) => {
      var temp_excel = [];
      temp_excel.push(
        row.name,
        row.phoneNumber,
        row.merchant,
        row.provider,
        typeof row.tags === 'string' ? row.tags : row.tags === null ? '-' : row.tags.join(', ')
      );

      exportData.push(temp_excel);
    });

    worksheet.getRow(1).values = [`Nama`, `Telepon`, `Nama Merchant`, `Provider`, `Tag`];

    addDataRows(worksheet, exportData, source, param, columns!);
    setColumnWidths(worksheet, source, [], exportData);
    styleColumns(worksheet, source, param, columns!, 1);
  } else if (source === 'template-validation-phone') {
    worksheet.getRow(1).values = [`Telepon`, `Nama Merchant`];

    addDataRows(worksheet, exportData, source, param, columns!);
    setColumnWidths(worksheet, source, [], exportData);
    styleColumns(worksheet, source, param, columns!, 1);
  } else if (source === 'Attendance') {
    Array.from(data!).map((row, index) => {
      var temp_excel = [];
      temp_excel.push(
        row.userName,
        row.dateClockIn ? moment(row.dateClockIn).format('DD MMM YYYY HH:mm') : '-',
        row.dateClockInDifference && row.dateClockInDifference < 0
          ? moment
            .duration(moment(row.dateClockIn).diff(moment(row.dateTemplateClockIn), 'minutes'), 'minutes')
            .as('minutes') !== 0
            ? moment
              .duration(
                moment(row.dateClockIn).startOf('minute').diff(moment(row.dateTemplateClockIn), 'minutes'),
                'minutes'
              )
              .format('h [jam] m [menit]')
            : moment
              .duration(moment(row.dateClockIn).diff(moment(row.dateTemplateClockIn), 'seconds'), 'seconds')
              .format('h [jam] m [menit] s [detik]')
          : '-',
        row.positionClockInDifference && row.positionClockInDifference > (row.templateRadiusClockIn ?? 0)
          ? customDistanceFormat(row.positionClockInDifference)
          : !row.positionClockInDifference &&
            (row.templateLatitudeClockIn || row.templateLatitudeClockOut) &&
            (row.templateLongitudeClockIn || row.templateLongitudeClockOut) &&
            calculateHaversineDistance(
              row.latitudeClockIn,
              row.longitudeClockIn,
              row.templateLatitudeClockIn ?? row.templateLatitudeClockOut,
              row.templateLongitudeClockIn ?? row.templateLongitudeClockOut
            ) > (row.templateRadiusClockIn ?? 0) &&
            row.latitudeClockIn &&
            row.longitudeClockIn
            ? customDistanceFormat(
              calculateHaversineDistance(
                row.latitudeClockIn,
                row.longitudeClockIn,
                row.templateLatitudeClockIn ?? row.templateLatitudeClockOut,
                row.templateLongitudeClockIn ?? row.templateLongitudeClockOut
              )
            )
            : '-',
        row.imageClockIn ? { text: 'Lihat foto', hyperlink: row.imageClockIn } : '-',
        row.descriptionClockIn ?? '-',
        row.dateClockOut ? moment(row.dateClockOut).format('DD MMM YYYY HH:mm') : '-',
        row.dateClockOutDifference && row.dateClockOutDifference < 0
          ? moment
            .duration(moment(row.dateTemplateClockOut).diff(moment(row.dateClockOut), 'minutes'), 'minutes')
            .as('minutes') !== 0
            ? moment
              .duration(
                moment(row.dateTemplateClockOut).diff(
                  moment(row.dateClockOut).startOf('minute'),
                  'minutes'
                ),
                'minutes'
              )
              .format('h [jam] m [menit]')
            : moment
              .duration(
                moment(row.dateTemplateClockOut).diff(moment(row.dateClockOut), 'seconds'),
                'seconds'
              )
              .format('h [jam] m [menit] s [detik]')
          : '-',
        row.positionClockOutDifference && row.positionClockOutDifference > (row.templateRadiusClockOut ?? 0)
          ? customDistanceFormat(row.positionClockOutDifference)
          : !row.positionClockOutDifference &&
            (row.templateLatitudeClockIn || row.templateLatitudeClockOut) &&
            (row.templateLongitudeClockIn || row.templateLongitudeClockOut) &&
            calculateHaversineDistance(
              row.latitudeClockOut,
              row.longitudeClockOut,
              row.templateLatitudeClockOut ?? row.templateLatitudeClockIn,
              row.templateLongitudeClockOut ?? row.templateLongitudeClockIn
            ) > (row.templateRadiusClockOut ?? 0) &&
            row.latitudeClockOut &&
            row.longitudeClockOut
            ? customDistanceFormat(
              calculateHaversineDistance(
                row.latitudeClockOut,
                row.longitudeClockOut,
                row.templateLatitudeClockOut ?? row.templateLatitudeClockIn,
                row.templateLongitudeClockOut ?? row.templateLongitudeClockIn
              )
            )
            : '-',
        row.imageClockOut ? { text: 'Lihat foto', hyperlink: row.imageClockOut } : '-',
        row.descriptionClockOut ?? '-'
      );

      exportData.push(temp_excel);
    });

    worksheet.getRow(1).values = [
      `Nama User`,
      `Waktu Masuk`,
      `Terlambat Masuk`,
      `Masuk di Luar Area`,
      `Foto Masuk`,
      `Keterangan Masuk`,
      `Waktu Keluar`,
      `Keluar Lebih Awal`,
      `Keluar di Luar Area`,
      `Foto Keluar`,
      `Keterangan Keluar`,
    ];

    addDataRows(worksheet, exportData, source, param, columns!);
    setColumnWidths(worksheet, source, [], exportData);
    styleColumns(worksheet, source, param, columns!, 1);

    // add link style to hyperlink
    for (let i = 1; i <= exportData.length; i++) {
      if (typeof worksheet.getCell('E' + (i + 1)).value === 'object')
        worksheet.getCell('E' + (i + 1)).font = {
          ...worksheet.getCell('E' + (i + 1)).font,
          underline: true,
          color: { theme: 10 },
        };
      if (typeof worksheet.getCell('J' + (i + 1)).value === 'object')
        worksheet.getCell('J' + (i + 1)).font = {
          ...worksheet.getCell('J' + (i + 1)).font,
          underline: true,
          color: { theme: 10 },
        };
    }
  } else if (source === 'Daily Attendance') {
    const startDate = moment(dateStart).locale('id').format('D MMM YYYY');
    const endDate = moment(dateEnd).locale('id').format('D MMM YYYY');
    const allColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q'];

    worksheet.mergeCells('A1', 'B1');
    worksheet.mergeCells('A3', 'B3');
    worksheet.getCell('A1').value = `REKAP KEHADIRAN HARIAN`;
    worksheet.getCell('A3').value = user?.data.company_name;
    worksheet.getCell('A4').value = `Periode`;
    worksheet.getCell('B4').value = `${startDate === endDate ? startDate : startDate + ' - ' + endDate}`;
    worksheet.getCell('A5').value = `Jumlah User`;
    worksheet.getCell('B5').value = `${data?.length} user`;

    worksheet.getRow(1).height = 35;
    worksheet.getRow(3).height = 35;
    worksheet.getRow(4).height = 25;
    worksheet.getRow(5).height = 25;

    styleRowsAttendance(worksheet, allColumns, 1, false, false, 15, 'E7E6E6');
    styleRowsAttendance(worksheet, ['A', 'B'], 3, false, false, 15);
    styleRowsAttendance(worksheet, ['A'], 4, false, false, 10, 'F9B95D');
    styleRowsAttendance(worksheet, ['A'], 5, false, false, 10, 'F9B95D');

    const tableHeader = [
      `No`,
      `Tanggal`,
      `Masuk`,
      `Keluar`,
      `Toleransi`,
      `Lokasi`,
      `Radius (meter)`,
      `Jam Masuk`,
      `Jam Keluar`,
      `Lokasi Masuk`,
      `Lokasi Keluar`,
      `Terlambat`,
      `Keluar Lebih Awal`,
      `Masuk diluar Area`,
      `Keluar diluar Area`,
      `Keterangan Masuk`,
      `Keterangan Keluar`,
      `Libur`,
    ];
    worksheet.columns = tableHeader.map((column) => ({
      width: column.length + 20,
    }));

    let rowNum = 7;
    data?.forEach((userData) => {
      worksheet.getCell('A' + rowNum).value = userData.user.userName;
      worksheet.mergeCells('A' + (rowNum + 1), 'B' + (rowNum + 1));
      worksheet.mergeCells('C' + (rowNum + 1), 'G' + (rowNum + 1));
      worksheet.mergeCells('H' + (rowNum + 1), 'K' + (rowNum + 1));
      worksheet.mergeCells('L' + (rowNum + 1), 'Q' + (rowNum + 1));
      worksheet.getCell('C' + (rowNum + 1)).value = 'Template';
      worksheet.getCell('H' + (rowNum + 1)).value = 'Kehadiran Harian';
      worksheet.getRow(rowNum + 2).values = tableHeader;
      styleRowsAttendance(worksheet, allColumns, rowNum, false, false, 10, 'F9B95D');
      styleRowsAttendance(worksheet, ['A', 'H'], rowNum + 1, true, true);
      styleRowsAttendance(worksheet, ['A', 'B', 'H', 'I', 'J', 'K'], rowNum + 2, true, true);
      styleRowsAttendance(worksheet, ['C', 'L'], rowNum + 1, true, true, 10, 'E7E6E6');
      styleRowsAttendance(
        worksheet,
        ['C', 'D', 'E', 'F', 'G', 'L', 'M', 'N', 'O', 'P', 'Q'],
        rowNum + 2,
        true,
        true,
        10,
        'E7E6E6'
      );
      exportData = [];

      userData.userAttendances!.forEach((row: MyAttendance, index: number) => {
        var temp_excel = [];
        temp_excel.push(
          index + 1,
          moment(addDays(new Date(dateStart), index))
            .locale('id')
            .format('D MMM YYYY'),
          row && row.dateTemplateClockIn ? moment(row.dateTemplateClockIn).format('HH:mm') : '-',
          row && row.dateTemplateClockOut ? moment(row.dateTemplateClockOut).format('HH:mm') : '-',
          row && row.dateTemplateClockInTolerance
            ? moment(row.dateTemplateClockInTolerance).format('HH:mm')
            : '-',
          row && row.templateLatitudeClockIn && row.templateLongitudeClockIn
            ? {
              text: 'Lihat lokasi',
              hyperlink:
                'http://maps.google.com/maps?q=' +
                row.templateLatitudeClockIn.toString() +
                ',' +
                row.templateLongitudeClockIn.toString(),
            }
            : row && row.templateLatitudeClockOut && row.templateLongitudeClockOut
              ? {
                text: 'Lihat lokasi',
                hyperlink:
                  'http://maps.google.com/maps?q=' +
                  row.templateLatitudeClockOut.toString() +
                  ',' +
                  row.templateLongitudeClockOut.toString(),
              }
              : '-',
          row && row.templateRadiusClockIn ? row.templateRadiusClockIn : '-',
          row && row.dateClockIn ? moment(row.dateClockIn).format('HH:mm') : '-',
          row && row.dateClockOut ? moment(row.dateClockOut).format('HH:mm') : '-',
          row &&
            row.positionClockInDifference &&
            row.positionClockInDifference > (row.templateRadiusClockIn ?? 0)
            ? 'Diluar area'
            : row &&
              row.positionClockInDifference &&
              row.positionClockInDifference < (row.templateRadiusClockIn ?? 0)
              ? 'Didalam area'
              : '-',
          row &&
            row.positionClockOutDifference &&
            row.positionClockOutDifference > (row.templateRadiusClockOut ?? 0)
            ? 'Diluar area'
            : row &&
              row.positionClockOutDifference &&
              row.positionClockOutDifference < (row.templateRadiusClockOut ?? 0)
              ? 'Didalam area'
              : '-',
          row && row.dateClockInDifference && row.dateClockInDifference < 0
            ? moment
              .duration(moment(row.dateClockIn).diff(moment(row.dateTemplateClockIn), 'minutes'), 'minutes')
              .as('minutes') !== 0
              ? moment
                .duration(
                  moment(row.dateClockIn)
                    .startOf('minute')
                    .diff(moment(row.dateTemplateClockIn), 'minutes'),
                  'minutes'
                )
                .format('h [jam] m [menit]')
              : moment
                .duration(
                  moment(row.dateClockIn).diff(moment(row.dateTemplateClockIn), 'seconds'),
                  'seconds'
                )
                .format('h [jam] m [menit] s [detik]')
            : '-',
          row && row.dateClockOutDifference && row.dateClockOutDifference < 0
            ? moment
              .duration(
                moment(row.dateTemplateClockOut).diff(moment(row.dateClockOut), 'minutes'),
                'minutes'
              )
              .as('minutes') !== 0
              ? moment
                .duration(
                  moment(row.dateTemplateClockOut).diff(
                    moment(row.dateClockOut).startOf('minute'),
                    'minutes'
                  ),
                  'minutes'
                )
                .format('h [jam] m [menit]')
              : moment
                .duration(
                  moment(row.dateTemplateClockOut).diff(moment(row.dateClockOut), 'seconds'),
                  'seconds'
                )
                .format('h [jam] m [menit] s [detik]')
            : '-',
          row &&
            row.positionClockInDifference &&
            row.positionClockInDifference > (row.templateRadiusClockIn ?? 0)
            ? customDistanceFormat(row.positionClockInDifference)
            : row &&
              !row.positionClockInDifference &&
              (row.templateLatitudeClockIn || row.templateLatitudeClockOut) &&
              (row.templateLongitudeClockIn || row.templateLongitudeClockOut) &&
              calculateHaversineDistance(
                row.latitudeClockIn,
                row.longitudeClockIn,
                row.templateLatitudeClockIn ?? row.templateLatitudeClockOut,
                row.templateLongitudeClockIn ?? row.templateLongitudeClockOut
              ) > (row.templateRadiusClockIn ?? 0) &&
              row.latitudeClockIn &&
              row.longitudeClockIn
              ? customDistanceFormat(
                calculateHaversineDistance(
                  row.latitudeClockIn,
                  row.longitudeClockIn,
                  row.templateLatitudeClockIn ?? row.templateLatitudeClockOut,
                  row.templateLongitudeClockIn ?? row.templateLongitudeClockOut
                )
              )
              : '-',
          row &&
            row.positionClockOutDifference &&
            row.positionClockOutDifference > (row.templateRadiusClockOut ?? 0)
            ? customDistanceFormat(row.positionClockOutDifference)
            : row &&
              !row.positionClockOutDifference &&
              (row.templateLatitudeClockIn || row.templateLatitudeClockOut) &&
              (row.templateLongitudeClockIn || row.templateLongitudeClockOut) &&
              calculateHaversineDistance(
                row.latitudeClockOut,
                row.longitudeClockOut,
                row.templateLatitudeClockOut ?? row.templateLatitudeClockIn,
                row.templateLongitudeClockOut ?? row.templateLongitudeClockIn
              ) > (row.templateRadiusClockOut ?? 0) &&
              row.latitudeClockOut &&
              row.longitudeClockOut
              ? customDistanceFormat(
                calculateHaversineDistance(
                  row.latitudeClockOut,
                  row.longitudeClockOut,
                  row.templateLatitudeClockOut ?? row.templateLatitudeClockIn,
                  row.templateLongitudeClockOut ?? row.templateLongitudeClockIn
                )
              )
              : '-',
          row && row.descriptionClockIn ? row.descriptionClockIn : '-',
          row && row.descriptionClockOut ? row.descriptionClockOut : '-',
          holiday?.some((e) => e.tanggal === moment(addDays(new Date(dateStart), index)).format('YYYY-MM-D'))
            ? holiday?.find(
              (e) => e.tanggal === moment(addDays(new Date(dateStart), index)).format('YYYY-MM-D')
            ).keterangan
            : ''
        );

        exportData.push(temp_excel);
      });

      addDataRows(worksheet, exportData, source, param, columns!);

      for (let i = 0; i < exportData.length; i++) {
        worksheet.getRow(rowNum + 3).alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        };
        worksheet.getRow(rowNum).height = 25;
        worksheet.getRow(rowNum + 1).height = 25;
        worksheet.getRow(rowNum + 2).height = 25;
        worksheet.getRow(rowNum + 3).height = 25;

        if (typeof worksheet.getCell('F' + (rowNum + 3)).value === 'object')
          worksheet.getCell('F' + (rowNum + 3)).font = {
            ...worksheet.getCell('F' + (rowNum + 3)).font,
            underline: true,
            color: { theme: 10 },
          };

        if (worksheet.getCell('J' + (rowNum + 3)).value === 'Didalam area')
          worksheet.getCell('J' + (rowNum + 3)).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'E8FFF3',
            },
          };
        else if (worksheet.getCell('J' + (rowNum + 3)).value === 'Diluar area')
          worksheet.getCell('J' + (rowNum + 3)).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FFF5F8',
            },
          };

        if (worksheet.getCell('K' + (rowNum + 3)).value === 'Didalam area')
          worksheet.getCell('K' + (rowNum + 3)).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'E8FFF3',
            },
          };
        else if (worksheet.getCell('K' + (rowNum + 3)).value === 'Diluar area')
          worksheet.getCell('K' + (rowNum + 3)).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FFF5F8',
            },
          };

        // holiday background yellow
        if (
          holiday?.some(
            (e) =>
              moment(e.tanggal, 'YYYY-MM-D').locale('id').format('D MMM YYYY') ===
              worksheet.getCell('B' + (rowNum + 3)).value
          )
        )
          styleRowsAttendance(worksheet, allColumns, rowNum + 3, false, true, 10, 'FFF8DD', false);

        rowNum++;
      }

      rowNum += 4;
    });
  }

  // Add sheet 'Info'
  if (source === 'Attendance') {
    const worksheetInfo = createWorksheet(workbook, source, 'Info');

    await worksheetInfo.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetInfo.mergeCells('A1', 'B1');
    worksheetInfo.getCell('A1').value = `INFORMASI FILE`;
    worksheetInfo.getCell('A2').value = `Pengunduh`;
    worksheetInfo.getCell('B2').value = `${user?.data.name}`;
    worksheetInfo.getCell('A3').value = `Waktu Unduh`;
    worksheetInfo.getCell('B3').value = `${moment().format('DD MMM YYYY HH:mm')}`;
    worksheetInfo.getCell('A5').value = `Rentang Tanggal`;
    worksheetInfo.getCell('B5').value = `${dateStart === dateEnd ? dateStart : dateStart + ' - ' + dateEnd}`;

    if (filter) {
      worksheetInfo.getCell('A6').value = `Filter Pencarian`;
      worksheetInfo.getCell('B6').value = `${filter}`;
    }

    worksheetInfo.getRow(1).font = {
      name: 'Arial Black',
      size: 16,
    };
    worksheetInfo.getRow(1).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    worksheetInfo.columns = [
      {
        width: 25,
      },
      {
        width: 30,
      },
    ];
  }

  if (source === 'Report Logs Pipeline') return exportWorkbook(workbook, source, `${nameFile}.xlsx`);
  else exportWorkbook(workbook, source, `${nameFile}.xlsx`);
};

// const getStatusText = (status: number): string => {
//     switch (status) {
//         case 1:
//             return 'Berhasil';
//         case 3:
//             return 'Gagal';
//         case 4:
//             return 'Nomor tidak valid';
//         case 5:
//             return 'Melebihi kuota';
//         case 6:
//             return 'Melebihi limit harian';
//         case 7:
//             return 'Tidak terhubung dgn WA';
//         case 8:
//             return 'User berhenti berlangganan';
//         default:
//             return 'Pending';
//     }
// };

const createWorkbook = () => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Looyal';
  workbook.lastModifiedBy = 'Looyal';
  workbook.created = new Date();
  workbook.modified = new Date();
  return workbook;
};

const createWorksheet = (workbook: ExcelJS.Workbook, source: string, sheetName: string = 'Sheet1') => {
  let worksheet;
  if (source === 'Daily Attendance')
    worksheet = workbook.addWorksheet(sheetName, {
      views: [{ state: 'frozen', xSplit: 2, topLeftCell: 'H1' }],
    });
  else worksheet = workbook.addWorksheet(sheetName);
  return worksheet;
};

const addDataRows = (
  worksheet: ExcelJS.Worksheet,
  data: any[],
  source: string,
  param: any,
  columns:
    | TableColumn<Pipeline>[]
    | TableColumn<Merchant>[]
    | TableColumn<Meeting>[]
    | TableColumn<ResponseSuccessValidationPhoneProp>[],
  user?: UserModel,
  startRow?: number
) => {
  if (source === 'Pipeline') {
    var i = 1;
    var totalNominal = 0;

    // Set Data Body
    data.forEach((rowData, index) => {
      totalNominal += (user?.data.company_name === "Heiszco") ? rowData[11] : (user?.data.company_id === param.companyId.enterpriseSales || user?.data.company_id === param.companyId.enterpriseService) ? rowData[9] : rowData[8];
      var row = worksheet.addRow(rowData);
      i++;

      row.eachCell((cell, colIndex) => {
        cell.alignment = {
          vertical: 'top',
          wrapText: true,
          shrinkToFit: true,
        };

        // Apply specific style for numeric columns
        if (user?.data.company_name === "Heiszco") {
          if ([7, 12, 14].includes(colIndex)) {
            cell.alignment.horizontal = 'right';
            cell.numFmt = '#,##0;[Red]-#,##0';
          }
        } else if (
          user?.data.company_id === param.companyId.enterpriseSales ||
          user?.data.company_id === param.companyId.enterpriseService
        ) {
          if ([5, 10, 12].includes(colIndex)) {
            cell.alignment.horizontal = 'right';
            cell.numFmt = '#,##0;[Red]-#,##0';
          }
        } else {
          if ([4, 9, 11].includes(colIndex)) {
            cell.alignment.horizontal = 'right';
            cell.numFmt = '#,##0;[Red]-#,##0';
          }
        }
      });
    });

    // Set Data Footer
    var endRow = worksheet.actualRowCount + 1;
    if (user?.data.company_name === "Heiszco") {
      worksheet.getCell(`L${endRow}`).value = totalNominal;
      worksheet.getCell(`L${endRow}`).numFmt = '#,##0;[Red]-#,##0';
    } else if (
      user?.data.company_id === param.companyId.enterpriseSales ||
      user?.data.company_id === param.companyId.enterpriseService
    ) {
      worksheet.getCell(`J${endRow}`).value = totalNominal;
      worksheet.getCell(`J${endRow}`).numFmt = '#,##0;[Red]-#,##0';
    } else {
      worksheet.getCell(`I${endRow}`).value = totalNominal;
      worksheet.getCell(`I${endRow}`).numFmt = '#,##0;[Red]-#,##0';
    }

    // Dynamic column
    let spliced = 0;
    columns.forEach((column, index) => {
      if (index !== 0 && column.omit) {
        worksheet.spliceColumns(index - spliced, 1);
        spliced++;
      }

      // if(user?.data.company_id !== "d2f2a3da-3829-4872-8891-cae921d555b5") { //Hide Note Column
      //   if(index === 3) worksheet.spliceColumns(index, 1)
      // }
    });
  } else if (source === 'Merchant') {
    // Set Data Body
    data.forEach((rowData, index) => {
      const row = worksheet.addRow(rowData);
      // Adjust cell styling if needed
      row.eachCell((cell, colIndex) => {
        cell.alignment = {
          vertical: 'top',
          wrapText: true,
          shrinkToFit: true,
        };
      });
    });

    // Dynamic column
    let spliced = 0;
    columns.forEach((column, index) => {
      if (index !== 0) {
        if (column.omit) {
          worksheet.spliceColumns(index - spliced, 1);
          spliced++;
        }
      }
    });
  } else if (source === 'Meeting') {
    // Set Data Body
    data.forEach((rowData, index) => {
      worksheet.addRow(rowData);
    });

    // Dynamic column
    let spliced = 0;
    columns.forEach((column, index) => {
      if (index !== 0) {
        if (column.omit) {
          worksheet.spliceColumns(index - spliced, 1);
          spliced++;
        }
      }
    });
  } else if (source === 'Report Logs Pipeline') {
    data.forEach((rowData, index) => {
      var row = worksheet.addRow(rowData);

      row.eachCell((cell, colIndex) => {
        cell.alignment = {
          vertical: 'top',
          wrapText: true,
          shrinkToFit: true,
        };
      });
    });
  } else {
    // Set Data Body
    data.forEach((rowData, index) => {
      worksheet.addRow(rowData);
    });
  }
};

const setColumnWidths = (worksheet: ExcelJS.Worksheet, source: string, fields: any[], data: any) => {
  if (source === 'Pipeline' || source === 'Merchant') {
    const maxColumnWidths: number[] = [];
    data.forEach((row: any) => {
      row.forEach((cell: any, columnIndex: number) => {
        const cellLength = String(cell).length;
        if (!maxColumnWidths[columnIndex] || cellLength > maxColumnWidths[columnIndex]) {
          maxColumnWidths[columnIndex] = cellLength;
        }
      });
    });

    // Atur lebar kolom berdasarkan panjang teks terpanjang
    maxColumnWidths.forEach((width, columnIndex) => {
      worksheet.getColumn(columnIndex + 1).width = width < 20 ? 20 : width + 2 > 40 ? 40 : width + 2; // Tambahkan sedikit padding
    });

    // const colWidths = [20, 30, 30, 20, 20, 30, 30, 20, 20, 20, 20, 20, 20, 20, 20, 20, ...Object.keys(fields).map((field) => 25)];
    // worksheet.columns = colWidths.map((width) => ({ width }));
  // } else if (source === 'Merchant') {
  //   const colWidths = [30, 30, 20, 20, 20, 30, 30, 30, 30, ...Object.keys(fields).map((field) => 25)];
  //   worksheet.columns = colWidths.map((width) => ({ width }));
  } else if (source === 'Meeting') {
    const colWidths = [30, 30, 20, 20, 20, 30, 30, 30, 30, 30, ...Object.keys(fields).map((field) => 25)];
    worksheet.columns = colWidths.map((width) => ({ width }));
  } else if (source === 'Report Logs Pipeline') {
    const colWidths = [20, 20, 20, 20, 20, 20];
    worksheet.columns = colWidths.map((width) => ({ width }));
  } else if (source === 'success-validation-phone') {
    const colWidths = [30, 20, 30, 20, 50];
    worksheet.columns = colWidths.map((width) => ({ width }));
  } else if (source === 'template-validation-phone') {
    const colWidths = [
      { width: 30, style: '@' },
      { width: 30, style: '@' },
    ];
    worksheet.columns = colWidths.map((data) => ({ width: data.width, style: { numFmt: data.style } }));
  } else if (source === 'Attendance') {
    const colWidths = [25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25];
    worksheet.columns = colWidths.map((width) => ({ width }));
  }
};

const styleColumns = (
  worksheet: ExcelJS.Worksheet,
  source: string,
  param: any,
  columns:
    | TableColumn<Pipeline>[]
    | TableColumn<Merchant>[]
    | TableColumn<Meeting>[]
    | TableColumn<ResponseSuccessValidationPhoneProp>[],
  indexRow: number,
  user?: UserModel,
  isFilterDate?: boolean
) => {
  worksheet.getRow(isFilterDate ? 4 : indexRow).alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  worksheet.getRow(isFilterDate ? 4 : indexRow).eachCell(function (cell) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'F6991D',
      },
    };
  });
  worksheet.getRow(isFilterDate ? 4 : indexRow).font = {
    name: 'Arial Black',
    size: 10,
    bold: true,
  };

  var endRow = worksheet.actualRowCount;
  if (source === 'Pipeline') {
    var index = (user?.data.company_name === "Heiszco") ? 12 : (user?.data.company_id === param.companyId.enterpriseSales || user?.data.company_id === param.companyId.enterpriseService) ? 10 : 9
    if (!columns[index].omit) {
      worksheet.getRow(endRow).font = {
        name: 'Arial Black',
        size: 10,
        bold: true,
      };
    }
  }

  if (source === 'Report Logs Pipeline') {
    worksheet.getRow(1).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getRow(1).font = {
      name: 'Arial Black',
      size: 12,
      bold: true,
    };
    worksheet.getRow(2).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getRow(2).font = {
      name: 'Arial Black',
      size: 12,
      bold: true,
    };
  }
};

const styleRowsAttendance = (
  worksheet: ExcelJS.Worksheet,
  column: string[],
  indexRow: number,
  border: boolean,
  center: boolean = false,
  fontsize: number = 10,
  color: string = 'F6991D',
  bold: boolean = true
) => {
  const cellIndex = column.map((column) => {
    return column + indexRow;
  });
  cellIndex.map((key) => {
    worksheet.getCell(key).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: color,
      },
    };
    if (border) {
      worksheet.getCell(key).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    }
  });
  worksheet.getRow(indexRow).alignment = {
    vertical: 'middle',
    horizontal: center ? 'center' : 'left',
    wrapText: true,
  };
  worksheet.getRow(indexRow).font = {
    name: 'Arial',
    size: fontsize,
    bold: bold,
  };
};

const addFileInformationDateRange = (worksheet: ExcelJS.Worksheet, dateStart: string, dateEnd: string) => {
  worksheet.getCell('A1').value = `Filter Tanggal dibuat: ${dateStart} - ${dateEnd}`;
  worksheet.mergeCells('A1:Q1'); // Merge cells to make header span across columns

  worksheet.getCell('A1').alignment = {
    vertical: 'middle',
    horizontal: 'left',
    wrapText: true,
  };
  worksheet.getCell('A1').font = {
    name: 'Arial Black',
    size: 12,
  };

  // Set row height
  worksheet.getRow(1).height = 30;
}

const exportWorkbook = async (workbook: ExcelJS.Workbook, source: string, fileName: string) => {
  if (source === 'Report Logs Pipeline') {
    const report = await workbook.xlsx.writeBuffer().then((buffer) => {
      // Konversi buffer ke blob
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const excelFile = new File([blob], `${fileName}.xlsx`, { type: blob.type, lastModified: Date.now() });
      return excelFile;
    });
    return report;
  } else {
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    });
  }
};
