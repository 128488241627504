import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import DataTable, { ExpanderComponentProps, TableColumn } from "react-data-table-component";
import { useDataTableStore } from "../../stores/DataTableStore";
import useSWR from 'swr';
import axios from "axios";
import useAccessToken from "../../hooks/AccessToken";
import { Pipeline, ProductLabels, ListProduct } from "../../interfaces/Pipeline";
import { UserActivity } from "../../interfaces/UserActivity";
import moment from "moment";
import 'moment/locale/id';
import { DataTableCard } from "./DataTableCard";
import { customNumberFormat, customSort, getDataById, getDateDifference, getDateRangeDuration, getDateSummary, getDurationDay, handleCaps, isColorDarkFromHex } from "../../functions/general";
import { UserPerformance, UserTarget } from "../../interfaces/UserPerformance";
import { RecentChatHistoryPipeline } from "../../interfaces/Chat/RecentChatHistory";
import { useIntl } from "react-intl";
import { KTSVG } from "../../_metronic/helpers";
import useUser from "../../hooks/User";
import { useCustomeStore } from "../../stores/CustomeStore";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';           // Core CSS
import 'primeicons/primeicons.css';                         // Icons
import debounce from "lodash.debounce";
import useSearch from "../../hooks/Search";
import InputSearch from "../Input/InputSearch";
import { useSettingsStore } from "../../stores/SettingsStore";
import { usePipelineStore } from "../../stores/PipelineStore";
import { useMerchantStore } from "../../stores/MerchantStore";
import { useUserStore } from "../../stores/UserStore";
import { useLocation, useParams } from "react-router-dom";
import { calculateDayDurations } from "../../functions/calculateDuration";

interface MasterDataTableProps {
    tableKey: string
    tableColumns: TableColumn<any>[]
    merchantId?: string
    actions?: ReactNode[]
    expanded?: boolean
    apiURL:
    'dashboard/users-performance' | 'meetings' | 'merchants' | `email/master-emails?companyId=${string}` | `email/master-emails?userId=${string}&companyId=${string}` |
    'products' | 'cities' | 'managers' | `receivable-reports/list?leadTemplateId=${string}` |
    'sales_crews' | 'presales_crews' | 'lead_progresses' |
    'source_types' | `leads` | 'roles' | 'lead_sources' |
    'users' | 'user-targets' | 'dashboard/leads-summary' |
    'user-activities' | 'user/subordinates' | 'labels' | `lead_logs/?lead_id=${string}` |
    'attendance-templates' | 'user-attendances' | 'my-user-attendances' |
    'all-user-attendances' | 'my-reimbursements' | 'reimbursements' |
    'reimbursement-categories' | 'abandoned-leads?leadAge=5' | 'lead-product-logs' |
    'units' | `conference/company-room-list?companyId=${string}` | `user-activities?tableType=PIPELINE&activitySourceId=${string}` |
    'reimbursement-limits' | `role-lead-progress-accesses?roleId=${string}` | 'v2/leads' | `merchants${string}` | `merchants?merchantTemplateId=${string}` | `v2/leads${string}`
    codeURL?: string
    dateStart?: string
    dateEnd?: string
    user_id?: string
    scope?: 'self' | 'team' | 'whole-team' | 'all'
    customParams?: any
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
    order?: 'asc' | 'desc'
    orderBy?: string | number
    onRowClicked?: (row: any, event: any) => void
    targetType?: string
    lead?: Pipeline | RecentChatHistoryPipeline
    autoReload?: boolean
    searchComponent?: () => ReactNode
    awaitData?: boolean
}

interface Props extends ExpanderComponentProps<Pipeline> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    name?: string;
}

const ExpandableRowComponent: React.FC<Props> = ({ data }) => {

    const dateEnd = data.date_end ? moment(data.date_end).format('DD MMMM YYYY') : '-'
    const paymentAge = data.date_end ? getDateDifference(data.date_end, moment().format('YYYY-MM-DD')) : '-'

    const intl = useIntl()

    return (
        <>
            <div className="p-10">
                <div className="row">
                    <div className="col-4">
                        <DataTableCard
                            className='card-lg h-100'
                            color='info'
                            icon='fas fa-users'
                            title={intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PIC" })}
                            text1={data.owner_name}
                            text2=""
                        />
                    </div>
                    <div className="col-4">
                        <DataTableCard
                            className='card-lg'
                            color='primary'
                            icon='fas fa-clipboard'
                            title={intl.formatMessage({ id: "MASTERS.DATATABLE.EXPANDED.START" })}
                            text1={`${intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.DATE_STARTED" })}: ${moment(data.date_start).format('DD MMMM YYYY')}`}
                            text2={`${intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.AGE" })}: ${getDateSummary(data.lead_age)}`}
                        />
                    </div>
                    <div className="col-4">
                        <DataTableCard
                            className='card-lg'
                            color='success'
                            icon='fas fa-dollar-sign'
                            title={intl.formatMessage({ id: "MASTERS.DATATABLE.EXPANDED.END" })}
                            text1={`${intl.formatMessage({ id: "MASTERS.DATATABLE.EXPANDED.DATE_PAID" })}: ${dateEnd}`}
                            text2={`${intl.formatMessage({ id: "MASTERS.DATATABLE.EXPANDED.PAYMENT_AGE" })} ${paymentAge}`}
                        />
                    </div>
                </div>
                <p className="mt-5">{intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOTES" })}: {data.note ?? '-'}</p>
                {
                    data.date_invoice &&
                    <>
                        <p>{intl.formatMessage({ id: "MASTERS.DATATABLE.EXPANDED.INVOICE_DATE" })}: {moment(data.date_invoice).format('DD MMMM YYYY')}</p>
                        <p>{intl.formatMessage({ id: "MASTERS.DATATABLE.EXPANDED.INVOICE_DUE_DATE" })}: {moment(data.date_invoice_due).format('DD MMMM YYYY')}</p>
                    </>
                }
            </div>
        </>
    );
};

interface PropsActivity extends ExpanderComponentProps<UserActivity> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    name?: string;
    customParams: any
}

const ExpandableRowComponentActivity: React.FC<PropsActivity> = ({ data, customParams }) => {
    function differenceInObj(firstObj: any, secondObj: any): any {
        let differenceObj: any = {};
        for (const key in firstObj) {
            if (Object.prototype.hasOwnProperty.call(firstObj, key)) {
                const tempObj = firstObj[key]
                if (Array.isArray(tempObj)) {
                    if (JSON.stringify(secondObj[key]) !== JSON.stringify(tempObj)) {
                        differenceObj[key] = { old: tempObj, new: secondObj[key] };
                    }
                }
                else if (typeof tempObj == "object" && typeof secondObj[key] == "object") {
                    if (JSON.stringify(secondObj[key]) !== JSON.stringify(tempObj)) {
                        if (Object.keys(tempObj).length == 0) differenceObj[key] = { old: "", new: secondObj[key] };
                        else differenceObj[key] = { old: tempObj, new: secondObj[key] };
                    }
                }
                else {
                    if (((tempObj && tempObj.length > 0) || (secondObj[key] && secondObj[key].length > 0)) && secondObj[key] !== tempObj) {
                        if (tempObj) differenceObj[key] = { old: tempObj, new: secondObj[key] };
                        else differenceObj[key] = { old: "", new: secondObj[key] };
                    }
                }
            }
        }
        for (const key in secondObj) {
            if (Object.prototype.hasOwnProperty.call(secondObj, key) && !Object.prototype.hasOwnProperty.call(differenceObj, key)) {
                const tempObj = secondObj[key]
                if (Array.isArray(tempObj)) {
                    if (JSON.stringify(firstObj[key]) !== JSON.stringify(tempObj)) {
                        differenceObj[key] = { old: firstObj[key], new: tempObj };
                    }
                }
                else if (typeof tempObj == "object" && typeof firstObj[key] == "object") {
                    if (JSON.stringify(firstObj[key]) !== JSON.stringify(tempObj)) {
                        if (Object.keys(tempObj).length == 0) differenceObj[key] = { old: firstObj[key], new: "" };
                        else differenceObj[key] = { old: firstObj[key], new: tempObj };
                    }
                }
                else {
                    if (((tempObj && tempObj.length > 0) || (firstObj[key] && firstObj[key].length > 0)) && firstObj[key] !== tempObj) {
                        if (tempObj) differenceObj[key] = { old: firstObj[key], new: tempObj };
                        else differenceObj[key] = { old: firstObj[key], new: "" };
                    }
                }
            }
        }

        return differenceObj;
    }

    const diff = differenceInObj(data.old_data, data.new_data)

    function isCustomFields(): any {
        let differenceObj: any = {};
        if (data.table_type == "PIPELINE" || data.table_type == "MERCHANT" || data.table_type == "MEETING") {
            if (diff.custom_fields || diff.custom_fields === "") {
                delete diff.custom_fields
                differenceObj = differenceInObj(data.old_data.custom_fields, data.new_data.custom_fields)
            }
            else if (diff.customFields || diff.customFields === "") {
                delete diff.customFields
                differenceObj = differenceInObj(data.old_data.customFields, data.new_data.customFields)
            }
        }
        return differenceObj;
    }

    const diff_custom = isCustomFields()
    let diff_key = Object.keys(diff).map((d: string) => `${d}`)
    let diff_old = Object.keys(diff).map((d: string) => `${diff[d as keyof typeof diff].old ?? ''}`)
    let diff_new = Object.keys(diff).map((d: string) => `${diff[d as keyof typeof diff].new ?? ''}`)
    if (Object.keys(diff_custom).length > 0) {
        let diff_key_custom = Object.keys(diff_custom).map((d: string) => `${d}`)
        let diff_old_custom = Object.keys(diff_custom).map((d: string) => `${diff_custom[d as keyof typeof diff_custom].old ?? ''}`)
        let diff_new_custom = Object.keys(diff_custom).map((d: string) => `${diff_custom[d as keyof typeof diff_custom].new ?? ''}`)

        for (let i = 0; i < diff_key_custom.length; i++) {
            diff_key.push(diff_key_custom[i])
            diff_old.push(diff_old_custom[i])
            diff_new.push(diff_new_custom[i])
        }
    }

    return (
        <>
            {diff_key.length > 0 && (
                <div className="py-4 px-6 border-bottom-1" style={{ borderBottomColor: 'rgba(0, 0, 0, .12)', borderBottomStyle: 'solid' }}>
                    {diff_key.map((key, index) =>
                        <div className="mt-1 mb-1 row">
                            <div className="col-5"></div>
                            <div className="col-2"><span className='badge badge-secondary text-break text-wrap text-start'>{key}</span></div>
                            <div className="col-2">{(diff_old[index]).toString().startsWith("https://resource.looyal.id/") ? <span role="button" onClick={() => !diff_old[index].includes(",") ? window.open((diff_old[index]).toString(), "_blank") : (customParams.setShowAttachmentModal(true), customParams.setActivity(data), customParams.setUrl(diff_old[index].split(",")))}><i className="fas fs-2 fa-eye"></i></span> : diff_old[index]}</div>
                            <div className="col-1">{" => "}</div>
                            <div className="col-2">{(diff_new[index]).toString().startsWith("https://resource.looyal.id/") ? <span role="button" onClick={() => !diff_new[index].includes(",") ? window.open((diff_new[index]).toString(), "_blank") : (customParams.setShowAttachmentModal(true), customParams.setActivity(data), customParams.setUrl(diff_new[index].split(",")))}><i className="fas fs-2 fa-eye"></i></span> : diff_new[index]}</div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

const rowPreDisabled: (row: any) => boolean = (row: any) => {
    if (row.activity_type && row.activity_type == "UPDATE") {
        return false;
    } else if (!row.activity_type) {
        return false;
    } else {
        return true;
    }
};

const rowPreExpanded: (row: any) => boolean = (row: any) => {
    if (row.activity_type && row.activity_type == "UPDATE") {
        return true;
    } else if (!row.activity_type) {
        return false;
    } else {
        return false;
    }
};

const MasterDataTable: FC<MasterDataTableProps> = ({ tableKey, tableColumns, merchantId, actions, expanded, apiURL, codeURL, dateStart, dateEnd, user_id, scope, customParams, setLoading, order = 'asc', orderBy, onRowClicked, targetType, lead, autoReload = true, searchComponent, awaitData = true }) => {
    const user = useUser()
    const { companyId } = useCustomeStore()
    const { templatePipelineId, templateMerchantId } = useParams()
    const { pipelines, selectMultiPipeline, pipelineLoading } = usePipelineStore()
    const { simpleMerchants, merchantLoading, selectMultiMerchant } = useMerchantStore()
    const { users, userLoading } = useUserStore()
    const { settings } = useSettingsStore()
    const { tablesData, setTablesData, filteredTablesData, setFilteredTablesData, setIsLoadingTable, setIsValidatingTable, isFullyLoaded, setIsFullyLoaded, allDataPipeline, setAllDataPipeline, setFetchCounter, fetchCounter } = useDataTableStore();
    const intl = useIntl()
    const BASE_URL = process.env.REACT_APP_API_URL
    const token = useAccessToken()
    const [nominalPipelines, setNominalPipelines] = useState(0)
    const [totalUserPerformance, setTotalUserPerformance] = useState([
        {
            name: "",
            totalProgress: 0,
            totalTarget: 0
        }
    ])
    const { setTempSortTableData, tempSortTableData } = useMerchantStore()
    const [hasMoreData, setHasMoreData] = useState<boolean>(true)
    const defaultLeadLimit = 50
    let defaultLeadOffset = 0
    const { pathname } = useLocation()

    let params = apiURL === 'meetings' && merchantId ?
        {
            merchant_id: merchantId,
            showAll: true
        }
        : apiURL === "v2/leads" && merchantId ?
            {
                leadTemplateId: templatePipelineId,
                merchant_id: merchantId,
                showAll: true
            }
            : apiURL === "v2/leads" && customParams && customParams === "1" ?
                {
                    leadTemplateId: templatePipelineId,
                    date_created_from: dateStart,
                    date_created_to: dateEnd,
                    user_id: user_id,
                    scope: scope,
                    leadOffset: defaultLeadOffset,
                    leadLimit: defaultLeadLimit
                }
                : apiURL === "v2/leads" && customParams && customParams === "2" ?
                    {
                        leadTemplateId: templatePipelineId,
                        date_start_from: dateStart,
                        date_start_to: dateEnd,
                        user_id: user_id,
                        scope: scope,
                        leadOffset: defaultLeadOffset,
                        leadLimit: defaultLeadLimit
                    }
                    : apiURL === "v2/leads" && customParams && customParams === "3" ?
                        {
                            leadTemplateId: templatePipelineId,
                            date_end_from: dateStart,
                            date_end_to: dateEnd,
                            user_id: user_id,
                            scope: scope,
                            leadOffset: defaultLeadOffset,
                            leadLimit: defaultLeadLimit
                        }
                        : apiURL === 'dashboard/users-performance' || apiURL === 'dashboard/leads-summary' ?
                            {
                                date: dateStart,
                                user_id: user_id,
                                scope: scope
                            }
                            : apiURL === "meetings" ?
                                {
                                    date_meet_from: dateStart,
                                    date_meet_to: dateEnd,
                                    user_id: user_id,
                                    scope: scope
                                }
                                : apiURL.includes('user-activities') ?
                                    {
                                        date_created_from: dateStart,
                                        date_created_to: dateEnd
                                    }
                                    : apiURL === 'user-targets' ?
                                        {
                                            date_start_from: dateStart,
                                            date_start_to: dateEnd,
                                            target_type: targetType,
                                            user_id: user_id,
                                            scope: scope
                                        }
                                        : apiURL === 'my-user-attendances' ?
                                            {
                                                dateClockInFrom: dateStart,
                                                dateClockInTo: dateEnd,
                                            }
                                            : apiURL === 'user-attendances' ?
                                                {
                                                    dateStartReport: dateStart,
                                                    dateEndReport: dateStart,
                                                }
                                                : apiURL === 'all-user-attendances' ?
                                                    {
                                                        dateStartReport: dateStart,
                                                        dateEndReport: dateEnd,
                                                    }
                                                    : apiURL === 'my-reimbursements' || apiURL === 'reimbursements' ?
                                                        {
                                                            dateCreatedFrom: dateStart,
                                                            dateCreatedTo: dateEnd,
                                                        }
                                                        : apiURL === 'lead-product-logs' ?
                                                            {
                                                                leadProductId: user_id
                                                            }
                                                            : apiURL === 'reimbursement-limits' ?
                                                                {
                                                                    periodStartFrom: dateStart,
                                                                    periodStartTo: dateEnd,
                                                                }
                                                                : {}



    const fetchDataWithWorker = (url: string, params: any, token: string) => {
        const worker = new Worker(new URL('./leadWorker.ts', import.meta.url)); // src\components\DataTable\leadWorker.ts
        worker.postMessage({
            url,
            params,
            token,
            defaultLeadLimit: 100,
            selectMultiPipeline,
            settings,
            selectMultiMerchant,
            pipelines,
            users,
            simpleMerchants,
        });

        worker.onmessage = (event) => {
            const { data, done, error, totalNominal } = event.data;

            if (error) {
                console.error('Worker error:', error);
            }

            if (data) {
                setTimeout(() => {
                    setNominalPipelines(totalNominal);
                    setTablesData(tableKey, data)
                }, 10)
            }

            if (done) {
                worker.terminate();
                setIsFullyLoaded(true);
            }
        };
    };

    useEffect(() => {
        if (tableKey === 'pipelines') {
            setIsFullyLoaded(false)
            fetchDataWithWorker(BASE_URL + apiURL, params, token);
        }
    }, [customParams, templatePipelineId, fetchCounter]);

    useEffect(() => {
        if (filteredTablesData[tableKey] && filteredTablesData[tableKey].length > 0) {
            setTablesData(tableKey, [])
        }
    }, [pathname, templatePipelineId])

    useEffect(() => {
        if (setLoading) {
            setLoading(false)
        }
    }, [])

    const fetcher = async (url: string) => {
        try {
            const response = await axios.get(url, {
                headers: { 'X-Auth-token': token },
                params: params
            });
            return response.data.data;
        } catch (error) {
            console.log(error);
            return [];
        }
    };

    const { data = [], error, isLoading, isValidating } = useSWR(
        apiURL === "v2/leads" ? null : BASE_URL + apiURL,
        apiURL === "v2/leads" ? null : fetcher,
        {
            revalidateOnFocus: autoReload,
            revalidateOnReconnect: autoReload
        }
    );

    const handleFormatData = async (dataMain: any) => {
        // Inisialisasi custom fields
        const dataCustomFieldPipeline = (selectMultiPipeline && Object.keys(selectMultiPipeline).length !== 0) ? selectMultiPipeline.customFields : {};
        const dataCustomFieldMeeting = settings.meeting_custom_fields ? JSON.parse(settings.meeting_custom_fields) : {};
        const dataCustomFieldMerchant = (selectMultiMerchant && Object.keys(selectMultiMerchant).length !== 0) ? selectMultiMerchant.customFields : {};

        try {
            // Format data menggunakan Promise.all
            const flattenedData = await Promise.all(
                dataMain.map(async (data: any) => {
                    for (const field in data) {
                        if (field === 'custom_fields' || field === 'customFields') {
                            const customFields = data['custom_fields'] ?? data['customFields'];
                            for (const key in customFields) {
                                if (Array.isArray(customFields[key])) {
                                    // Logika untuk array
                                    if (
                                        (tableKey === 'pipelines' && dataCustomFieldPipeline[key]?.type === 'serial') ||
                                        (tableKey === 'meetings' && dataCustomFieldMeeting[key]?.type === 'serial') ||
                                        (tableKey === 'merchants' && dataCustomFieldMerchant[key]?.type === 'serial')
                                    ) {
                                        data[`custom_fields.${key}`] = customFields[key].join('');
                                    } else if (dataCustomFieldPipeline[key]?.type === 'merchant' || dataCustomFieldMerchant[key]?.type === 'merchant') {
                                        data[`custom_fields.${key}`] = getDataById(customFields[key], simpleMerchants)
                                            ?.map((item: any) => item.name)
                                            .join(', ');
                                    } else if (dataCustomFieldPipeline[key]?.type === 'user') {
                                        data[`custom_fields.${key}`] = getDataById(customFields[key], users)
                                            ?.map((item: any) => item.name)
                                            .join(', ');
                                    } else {
                                        data[`custom_fields.${key}`] = customFields[key].join(', ');
                                    }
                                } else {
                                    // Logika untuk non-array
                                    if (dataCustomFieldPipeline[key]?.type === 'pipeline') {
                                        const pipelineData = getDataById(customFields[key], pipelines);
                                        data[`custom_fields.${key}`] = pipelineData
                                            ? `${pipelineData.name} / Rp.${customNumberFormat(pipelineData.nominal)} / ${moment(pipelineData.dateCreated).format('DD MMM YYYY')}`
                                            : '-';
                                    } else {
                                        data[`custom_fields.${key}`] = customFields[key];
                                    }
                                }
                            }
                        }
                    }
                    return data; // Return hasil format
                })
            );

            // Validasi hasil dan set tabel data
            if (Array.isArray(flattenedData)) {
                setTablesData(tableKey, flattenedData);
            } else {
                console.error('Flattened data kosong atau tidak valid.');
            }
        } catch (error) {
            console.error('Terjadi kesalahan saat memproses data:', error);
        }
    };

    useEffect(() => {
        if (!isLoading && !isValidating && !merchantLoading && !pipelineLoading && !userLoading && tableKey !== 'pipelines') {
            var dataMain = []
            if (tableKey === 'users-performance') {
                dataMain = [...(data as any).usersPerformance]
                // setTablesData(tableKey, data.usersPerformance)
            } else if (tableKey === 'pending-leads') {
                dataMain = [...(data as any).pendingPipelines]
                // setTablesData(tableKey, data.pendingPipelines)
            } else if (tableKey === 'paid-leads') {
                dataMain = [...(data as any).paidPipelines]
                // setTablesData(tableKey, data.succeedPipelines)
            } else if (tableKey === 'sold-product-leads') {
                dataMain = [...(data as any).productsSold]
                // setTablesData(tableKey, data.productsSold)
            } else if (tableKey === 'merchants') {
                const merchants = data.map((eachData: any) => ({
                    ...eachData,
                    lastMeetEnd: eachData.lastMeetEnd ? getDurationDay(eachData.lastMeetEnd) : '-',
                    lastLeadCreatedTime: eachData.lastLeadCreatedTime ? getDurationDay(eachData.lastLeadCreatedTime) : '-',
                    // dateCreated: moment(eachData.dateCreated).format("DD MMM YYYY HH:mm")
                }))

                dataMain = [...merchants]
                // setTablesData(tableKey, merchants)
            } else if (tableKey === 'logs') {
                var mergeData: any[] = []
                mergeData = data

                // const sortedLeadData = mergeData.sort((a: Pipeline, b: Pipeline) => {
                //     return new Date(a.date_created).getTime() - new Date(b.date_created).getTime();
                // });

                if (user.data.company_id === companyId.juraganMaterial) {
                    mergeData.forEach((eachData: any, index: number) => {
                        if (index !== 0) {
                            eachData.duration = getDateRangeDuration(mergeData[index].date_created, mergeData[index - 1].date_created);
                        } else {
                            eachData.duration = "-"
                        }
                    })
                } else {
                    mergeData.forEach((eachData: any, index: number) => {
                        if (index < mergeData.length - 1) {
                            if (user.data.company_id === companyId.esb) {
                                eachData.duration = calculateDayDurations(mergeData[index + 1].date_created, mergeData[index].date_created);
                            } else {
                                eachData.duration = getDateRangeDuration(mergeData[index + 1].date_created, mergeData[index].date_created);
                            }
                        } else {
                            eachData.duration = "-"
                        }
                    })
                }

                // sortedLeadData.sort((a: Pipeline, b: Pipeline) => {
                //     return new Date(b.date_created).getTime() - new Date(a.date_created).getTime();
                // });

                dataMain = [...mergeData]
                // setTablesData(tableKey, mergeData)
            }
            // else if (tableKey === 'pipelines') {
            //     const products = allData.map((eachData: any) => ({
            //         ...eachData,
            //         // handle ketidaksusain round antar BE dan FE saat search Contoh : 3.99 -> 4//
            //         nominalWithoutVatForSearch: eachData.nominal_without_vat ? Math.round(eachData.nominal_without_vat as number).toString() : null,
            //         nominalForSearch: eachData.nominal ? Math.round(eachData.nominal as number).toString() : null,
            //         leadLabelProducts: eachData.leadProducts.map((productLabel: any) => productLabel.productLabels).join(', '),
            //         leadNameProducts: eachData.leadProducts.map((productLabel: any) => productLabel.productName).join(', '),
            //         lead_progress_name: eachData.lead_progress_name === 'NEW' ? intl.formatMessage({ id: "FORM.LABEL.NEW" }) : eachData.lead_progress_name
            //     }))

            //     dataMain = [...products]
            //     // setTablesData(tableKey, products)
            // } 
            else if (tableKey === 'meetings') {
                const meetings = data.map((eachData: any) => ({
                    ...eachData,
                    status: eachData.status === "PLANNED" ? intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) : eachData.status === "COMPLETED" ? intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) : eachData.status === "ONGOING" ? intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) : intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' }),
                    date_meet: moment(eachData.date_meet).format("YYYY-MM-DD HH:mm:ss"),
                    date_meet_end: eachData.date_meet_end === null ? null : eachData.allDay ? moment(eachData.date_meet_end).subtract(1, 'days').local().format("YYYY-MM-DD HH:mm:ss") : moment(eachData.date_meet_end).format("YYYY-MM-DD HH:mm:ss"),
                    duration: eachData.date_meet_end === null ? "-" : getDateRangeDuration(eachData.date_meet, eachData.date_meet_end),
                }))

                dataMain = [...meetings]
                // setTablesData(tableKey, meetings)
            } else if (tableKey === 'units') {
                let defaultUnit = {
                    name: "PIECES",
                    alias: "PCS"
                }

                const mergeData = [...data, defaultUnit]

                dataMain = [...mergeData]
                // setTablesData(tableKey, mergeData)
            } else if (tableKey === 'reimbursement-limits') {
                const limits: any = [];
                data.forEach((eachData: any) => {
                    eachData.limits.forEach((limit: any) => {
                        limits.push({
                            userId: eachData.id,
                            userName: eachData.name,
                            ...limit
                        });
                    });
                });
                dataMain = [...limits]
                // setTablesData(tableKey, limits)
            } else {
                dataMain = [...data]
                // setTablesData(tableKey, data)
            }

            if (setLoading) {
                setLoading(false);
            }

            handleFormatData(dataMain)
        }
    }, [data, merchantLoading, pipelineLoading, userLoading, selectMultiMerchant])

    useEffect(() => {
        setIsLoadingTable(tableKey, isLoading)
        setIsValidatingTable(tableKey, isValidating)
    }, [isLoading, isValidating])

    useEffect(() => {
        if (tablesData[tableKey]) {
            if (tableKey === "pipelines") {
                let totalNominal = 0;

                filteredTablesData[tableKey].forEach((item: any) => {
                    totalNominal += parseInt(item.nominal, 10) || 0; // Mengonversi ke integer
                });

                setNominalPipelines(totalNominal)
            }

            if (tableKey === "users-performance") {
                var headerObjects = []
                var totalNonZeroConvertionRate = filteredTablesData[tableKey].filter(data => data.convertionRate !== 0 && data.convertionRate !== null).length;
                var totalConvertionRate = filteredTablesData[tableKey].reduce((total, data) => total + data.convertionRate, 0);

                const headerArray = [...new Set((data as any).usersPerformance.flatMap((user: UserPerformance) => user.userTargets.map((target: UserTarget) => target.name)))];

                for (var i = 0; i < headerArray.length; i++) {
                    var totalProgress = 0
                    var totalTarget = 0
                    for (var j = 0; j < filteredTablesData[tableKey].length; j++) {
                        if (filteredTablesData[tableKey][j].userTargets[i].name === headerArray[i]) {
                            totalProgress += Number(filteredTablesData[tableKey][j].userTargets[i].progress) ?? 0
                            totalTarget += Number(filteredTablesData[tableKey][j].userTargets[i].target) ?? 0
                        }
                    }

                    headerObjects.push({
                        name: "Total " + headerArray[i],
                        totalProgress: totalProgress,
                        totalTarget: totalTarget
                    })
                }

                const averageConvertionRateObject = {
                    name: intl.formatMessage({ id: 'FORM.LABEL.AVERAGE_CONVERSION_RATE' }),
                    totalProgress: totalNonZeroConvertionRate ? totalConvertionRate / totalNonZeroConvertionRate : 0,
                    totalTarget: 0
                };

                setTotalUserPerformance([
                    averageConvertionRateObject,
                    ...headerObjects
                ])
            }
        }
    }, [filteredTablesData[tableKey]])

    // const handleClearSearch = () => {
    //     setFilterText('')
    //     setFilterTextTable(tableKey, '')
    //   }

    if (error) return (
        <div className="container mt-5 p-0">
            <div className="alert alert-danger" role="alert">
                <h1>Error</h1>
                <span className="lead">{error.response.data.message}</span>
            </div>
        </div>
    )

    const handleSort = (column: any, sortDirection: any) => {
        if (tableKey === 'all-user-attendances') {
            setFilteredTablesData(tableKey, customSort(!isLoading ? filteredTablesData[tableKey] : [], column.selector, sortDirection));
        } else if (tableKey === 'merchants') {
            setTempSortTableData(filteredTablesData[tableKey], sortDirection)
        }
    };

    return (
        <>
            <div className="card rounded">
                {
                    (((isLoading || isValidating) && tableKey !== 'pipelines') || (merchantLoading && tableKey !== 'pipelines') || (userLoading && tableKey !== 'pipelines') || (pipelineLoading && tableKey !== 'pipelines')) &&
                    <div className="loading-overlay">
                        <div className="loader"></div>
                    </div>
                }
                <div className="card-header border-0">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1 skeleton-button" id="divSearch">
                            {
                                tableKey === "pipelines" ?
                                    searchComponent!()
                                    :
                                    <InputSearch
                                        tableKey={tableKey}
                                        tableColumns={tableColumns}
                                    />
                            }
                        </div>
                    </div>
                    <div className="card-toolbar align-self-center">
                        {
                            actions && actions.map((action, index) => (
                                <div key={index} className="me-5 me-md-3">
                                    {action}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="card-body pt-0">
                    {
                        tableKey === 'user_targets' &&
                        <>
                            <div className="d-flex justify-content-end my-1 w-100 flex-wrap">
                                <span className="fw-bolder">{intl.formatMessage({ id: 'FORM.LABEL.SET' })}</span>
                                <span className="ms-5 d-flex"><span className="w-20px h-20px border me-2" style={{ borderRadius: '6px', backgroundColor: '#E8FFF3' }}></span>{intl.formatMessage({ id: 'FORM.LABEL.DONE' })}</span>
                                <span className="ms-4 d-flex"><span className="w-20px h-20px border me-2" style={{ borderRadius: '6px', backgroundColor: '#fee0e0' }}></span>{intl.formatMessage({ id: 'FORM.LABEL.FOLLOWING_PREVIOUS_MONTH' })}</span>
                            </div>
                        </>
                    }
                    <DataTable
                        columns={tableColumns}
                        data={
                            // !isLoading ? apiURL === 'v2/leads' ? allDataPipeline : filteredTablesData[tableKey] : []
                            !isLoading ? filteredTablesData[tableKey] : []
                        }
                        defaultSortFieldId={orderBy}
                        defaultSortAsc={order === 'asc' ? true : false}
                        expandableRowsHideExpander
                        expandOnRowClicked
                        highlightOnHover={tableKey === 'pipelines' || tableKey === 'meetings' || tableKey === 'merchants' || tableKey === 'my-reimbursements' || tableKey === 'reimbursements' ? true : false}
                        expandableRows={expanded}
                        expandableRowsComponent={(props) => {
                            if (tableKey === 'user-activities' || tableKey === 'pipeline-activities') {
                                return <ExpandableRowComponentActivity {...props} customParams={customParams} />;
                            }
                            return null;
                        }}
                        expandableRowDisabled={rowPreDisabled}
                        expandableRowExpanded={rowPreExpanded}
                        pagination
                        onRowClicked={onRowClicked}
                        onSort={handleSort}
                    />

                    {
                        tableKey === "pipelines" ?
                            <>
                                <div className="d-flex justify-content-end mt-1 w-100">
                                    <span className="fw-bolder fs-5 pe-20 percentageCredit2">Total: </span>
                                    <span className="fw-bolder fs-5 pe-1 percentageCredit2">Rp. {customNumberFormat(nominalPipelines)}</span>
                                    {/* Small loading spinner */}
                                    {!isFullyLoaded && (
                                        <div className="spinner-border spinner-border-sm ms-2 my-auto" role="status">
                                        </div>
                                    )}
                                </div>
                            </>
                            :
                            tableKey === "users-performance" &&
                            <>
                                {
                                    totalUserPerformance.map((data, index) => (
                                        <div className="row mt-1 w-100">
                                            <span className="col-0 col-xl-5"></span>
                                            <span className="col-7 col-xl-4 fw-bolder fs-5 percentageCredit2">{data.name} : </span>
                                            {
                                                index === 0 ?
                                                    <span className="col-5 col-xl-3 fw-bolder fs-5 percentageCredit2 text-end">{customNumberFormat(Math.round(data.totalProgress)) + '%'}</span>
                                                    :
                                                    <span className="col-5 col-xl-3 fw-bolder fs-5 percentageCredit2 text-end">{customNumberFormat(data.totalProgress) + "/" + customNumberFormat(data.totalTarget)}</span>
                                            }
                                        </div>
                                    ))
                                }
                            </>
                    }
                </div>
            </div>

        </>
    )
}

export { MasterDataTable }