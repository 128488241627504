import { FC } from "react";
import useUser from "../../hooks/User";
import { base58Encode, rot8Encode } from "../../functions/encryption";

const ScanQR: FC = () => {

    const user = useUser()

    const plaintext = `${user.data.chat_phone}|` + new Date().toISOString();
    const mixedEncoded = rot8Encode(base58Encode(plaintext));

    return (
        <iframe src={`https://wa.looyal.id/scan/${user.data.chat_phone}`} title="scan-qr" height={'250px'} />
    )
}

export { ScanQR }