import React, { FC, useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useEmailStore } from '../../stores/EmailStore'
import axios from 'axios'
import useSWR from 'swr'
import moment from 'moment'
import { Attachment, DetailEmailMessage, EmailMessage, FileDetailMessage } from '../../interfaces/Email/EmailMessage'
import { KTSVG } from '../../_metronic/helpers'
import ReplyEmail from './ReplyEmail'
import DetailMoreInfo from './DetailMoreInfo'
import PreviewAttachment from './PreviewAttachment'
import TopNavbar from './TopNavbar'
import { getFileTypeFromName, downloadAttachment, extractEmail } from '../../functions/email'
import EmailBodyFormatter from './EmailBodyFormatter'
import { AddMerchant } from '../Merchants/AddMerchant'
import { Modal } from 'bootstrap'
import useAccessToken from '../../hooks/AccessToken'
import { useUserStore } from '../../hooks/UserEmail'

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL
interface Props {
  typeMenu: string
  countEmailsMutate?: () => void
}

const DetailEmail: FC<Props> = ({ typeMenu, countEmailsMutate }) => {
  const {
    detailEmail,
    tokenEmail,
    setTypeMenu,
    setActiveSidebar,
    setValuesFromDraft,
    setOpenSendEmail,
    setTypeEmail,
    setCurrentSubject,
  } = useEmailStore()
  const { userEmail } = useUserStore()
  const [dataDetailEmails, setDataDetailEmails] = useState({} as DetailEmailMessage)
  const [isReplyOpen, setIsReplyOpen] = useState(false)
  const [replyType, setReplyType] = useState<'reply' | 'replyAll'>('reply');
  const [isForwardOpen, setIsForwardOpen] = useState(false)
  const [showAddMerchantModal, setShowAddMerchantModal] = useState(false)
  const token = useAccessToken()

  const fetcher = (url: string) =>
    axios
      .post(
        url,
        {
          type: typeMenu,
          uid: detailEmail.id,
        },
        {
          headers: {
            'X-auth-token': 'Bearer ' + tokenEmail,
            'x-crm-auth-token': token,
          },
        }
      )
      .then((res) => res.data.data)

  const { data: emailData = [], isValidating: emailIsValidating } = useSWR(
    `${API_URL_EMAIL}dashboard/read-email`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  useEffect(() => {
    if (!emailIsValidating) {
      if (!detailEmail.read) countEmailsMutate?.()

      let attachments: File[] = []

      if (emailData?.attachments?.length > 0) {
        for (let i = 0; i < emailData.attachments.length; i++) {
          attachments.push({
            ...emailData.attachments[i],
          })
        }
      }

      if (
        typeMenu === 'inbox' ||
        typeMenu === 'spam' ||
        typeMenu === 'archive' ||
        typeMenu === 'trash' // pada trash ini tidak memiliki messageId mungkin bisa di reformat ulang
      ) {
        const cleanedSubject = emailData.subject
          ? emailData.subject.replace(/^(Re: )+/i, 'Re: ')
          : '(Tanpa subjek)'

        // let formattedTo = emailData?.to?.text?.split(',')
        let formattedTo = emailData?.to?.value.map((val: any) => val.address)

        if (cleanedSubject.startsWith('Re:') || cleanedSubject.startsWith('Fwd:')) {
          formattedTo = formattedTo?.map((recipient: string) => {
            const match = recipient.match(/<(.+)>/)
            return match ? match[1].trim() : recipient.trim()
          })
        }

        const data = {
          messageId: emailData.messageId,
          from: (emailData.from?.text ?? '').match(/^(.*?) <(.*?)>$/)
            ? (emailData.from?.text ?? '').match(/^"([^"]+)" <.*>$/)[1].trim()
            : emailData.from?.text ?? '',
          email: (emailData.from?.text ?? '').match(/^(.*?) <(.*?)>$/)
            ? (emailData.from?.text ?? '').match(/^(.*?) <(.*?)>$/)[2].trim()
            : emailData.from?.text ?? '',
          subject: cleanedSubject,
          cc: emailData?.cc?.value?.map((val: any) => val.address) ?? [],
          bcc: emailData.bcc?.value.map((val: any) => val.address) ?? [],
          body: emailData?.text ? emailData.text : emailData.html,
          html: emailData?.html ? emailData.html : emailData.textAsHtml,
          date: moment(emailData.date).format('D MMMM YYYY, HH:mm'),
          to: formattedTo,
          attachments: attachments,
          merchantName: emailData.merchantName || '',
        }
        // console.log(emailData, '<><><')
        setCurrentSubject(cleanedSubject)
        setDataDetailEmails(data)
      } else if (typeMenu === 'sent') {
        const cleanedSubject = emailData.subject
          ? emailData.subject.replace(/^(Re: )+/i, 'Re: ')
          : '(Tanpa subjek)'

        let formattedTo = emailData?.to?.text?.split(',')

        if (cleanedSubject.startsWith('Re:') || cleanedSubject.startsWith('Fwd:')) {
          formattedTo = formattedTo?.map((recipient: string) => {
            const match = recipient.match(/<(.+)>/)
            return match ? match[1].trim() : recipient.trim()
          })
        }

        const data = {
          messageId: emailData.messageId,
          from: emailData.from.text,
          email: emailData.from.text,
          subject: cleanedSubject,
          body: emailData.text ? emailData.text : emailData.html,
          html: emailData?.html ? emailData.html : emailData.textAsHtml,
          cc: emailData?.cc?.value?.map((val: any) => val.address) ?? [],
          bcc: emailData.bcc?.value.map((val: any) => val.address) ?? [],
          date: moment(emailData.date).format('D MMMM YYYY, HH:mm'),
          to: formattedTo,
          attachments: attachments,
          merchantName: emailData.merchantName || '',
        }
        setCurrentSubject(cleanedSubject)
        setDataDetailEmails(data)
      } else if (typeMenu === 'draft') {
        const cekIsCcExist = emailData?.cc?.value?.map((val: any) => val.address) ?? []
        const cekIsBccExist = emailData?.bcc?.value?.map((val: any) => val.address) ?? []
        const cekIsTextExist = emailData?.text ? emailData.text : emailData.html
        const toArray = emailData?.to?.text // ini untuk bagian autocomplete nya ketika ingin edit draft
          ? emailData.to.text.split(',').map((email: any) => email.trim())
          : []
        const cleanedSubject = emailData.subject
          ? emailData.subject.replace(/^(Re: )+/i, 'Re: ')
          : '(Tanpa subjek)'
        const data = {
          id: detailEmail.id,
          from: emailData.from.text,
          cc: cekIsCcExist,
          bcc: cekIsBccExist,
          subject: cleanedSubject,
          body: cekIsTextExist !== 'null' ? cekIsTextExist : '',
          date: moment(emailData.date).format('D MMMM YYYY, HH:mm'),
          to: toArray,
          html: emailData?.html ? emailData.html : emailData.textAsHtml,
          attachments: attachments,
          // merchantName: emailData.merchantName || '',
        }
        setCurrentSubject(cleanedSubject)
        setDataDetailEmails(data)
      }
    }
  }, [emailData, emailIsValidating])

  const toggleOpenReply = (type: 'reply' | 'replyAll') => {
    setIsReplyOpen((prevState) => !prevState);
    setReplyType(type);
  };

  const getCcRecipients = () => {
    if ((dataDetailEmails?.cc?.length ?? 0) > 0) {
      return dataDetailEmails?.cc ?? [];
    }
    return [];
  }

  const getAllRecipients = () => {
    const toRecipients = Array.isArray(dataDetailEmails.to) ? dataDetailEmails.to : [];
    const ccRecipients = getCcRecipients();
    return [...toRecipients, ...ccRecipients]
      .filter(email => email !== userEmail?.email)
      .filter((email, index, self) => self.indexOf(email) === index); // Remove duplicates
  }

  const addMerchant = () => {
    console.log('add merchant')
  }

  const isMerchant = true

  useEffect(() => {
    if (showAddMerchantModal) {
      const modalElement = document.getElementById(`add-merchant-modal`);

      if (modalElement) {
        const modalInstance = new Modal(modalElement);
        modalInstance.show();

        const handleModalHide = () => {
          setShowAddMerchantModal(false);
        };

        modalElement.addEventListener('hidden.bs.modal', handleModalHide);

        return () => {
          modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
        };
      }
    }
  }, [showAddMerchantModal, setShowAddMerchantModal]);

  return (
    <>
      <div className='d-flex align-items-center gap-3 position-relative mb-5'>
        <TopNavbar />
      </div>
      <div className='card detail-email-wrap'> {/* */}
        <div className='card-header align-items-center py-5 gap-5'>
          <div className='d-flex'>
            <span
              className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
              onClick={() => {
                setTypeEmail('listEmail')
              }}
            >
              <span className='svg-icon svg-icon-1 m-0'>
                <KTSVG path='/media/icons/duotune/arrows/arr063.svg' />
              </span>
            </span>
          </div>
          {/* {
            isMerchant && (
              <button className='btn btn-sm btn-primary'
                onClick={() => {
                  setShowAddMerchantModal(true)
                }}>
                Add Merchant
              </button>
            )
          } */}
        </div>
        {emailIsValidating ? (
          <div className='card-body p-0' style={{ marginTop: '300px' }}>
            <div className='loading-overlay'>
              <div className='loader'></div>
            </div>
          </div>
        ) : (
          <div className='card-body'>
            <div className='d-flex flex-wrap gap-2 justify-content-between mb-8'>
              <div className='d-flex align-items-center flex-wrap gap-5'>
                <div className='d-flex flex-column'>
                  <h2 className='fw-bold me-3 my-1' style={{ wordBreak: 'break-word' }}>
                    {dataDetailEmails.subject}
                  </h2>
                </div>
                {typeMenu === 'draft' && (
                  <span className='badge badge-light-primary'>ini adalah pesan draft</span>
                )}
                {typeMenu === 'draft' && (
                  <div>
                    <button
                      className='btn btn-sm btn-primary'
                      onClick={() => {
                        setTypeEmail('sendEmail')
                        setTypeMenu('')
                        setActiveSidebar({
                          inbox: '',
                          sent: '',
                          draft: '',
                          trash: '',
                          archive: '',
                          spam: '',
                          groupEmail: '',
                          broadcastEmail: ''
                        })
                        setValuesFromDraft(dataDetailEmails)
                        setOpenSendEmail(true)
                      }}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div data-kt-inbox-message='message_wrapper'>
              <div className='d-flex flex-wrap gap-2 flex-stack' data-kt-inbox-message='header'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-50 me-4'>
                    <span className='symbol-label text-danger'>
                      {dataDetailEmails.from?.substring(0, 2).toUpperCase()}
                    </span>
                  </div>
                  <div className='pe-5'>
                    <div className='d-flex align-items-center flex-wrap gap-1'>
                      {typeMenu === 'inbox' ||
                        typeMenu === 'spam' ||
                        typeMenu === 'archive' ||
                        typeMenu === 'trash' ? (
                        <>
                          <span className='fw-bolder text-dark'>
                            {dataDetailEmails.from} {'<' + dataDetailEmails.email + '>'}
                          </span>
                          {dataDetailEmails.merchantName && (
                            <span className='ms-2 d-flex align-content-center justify-content-center'>
                              <img src="media/svg/general/merchant.svg" alt="merchant" className="h-20px" />
                              <h6 className='my-auto ms-2 opacity-50 fs-7'>{dataDetailEmails.merchantName}</h6>
                            </span>
                          )}
                        </>
                      ) : typeMenu === 'sent' ? (
                        <span className='fw-bolder text-dark'>{dataDetailEmails.from}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div data-kt-inbox-message='details'>
                      {typeMenu === 'inbox' ? (
                        <>
                          <span className='d-flex gap-2'>
                            <span className='text-muted fw-bold'>
                              Kepada{' '}
                              {(() => {
                                const recipientsArray = Array.isArray(dataDetailEmails.to)
                                  ? dataDetailEmails.to
                                  : (typeof dataDetailEmails.to === 'string' ? [dataDetailEmails.to] : []);

                                const otherRecipients = recipientsArray.filter(email => email !== userEmail?.email);

                                if (recipientsArray.includes(userEmail?.email || '')) {
                                  if (otherRecipients.length === 0) {
                                    return 'Saya';
                                  } else if (otherRecipients.length === 1) {
                                    return `Saya, ${otherRecipients[0]}`;
                                  } else if (otherRecipients.length === 2) {
                                    return `Saya, ${otherRecipients.join(', ')}`;
                                  } else {
                                    return `Saya, ${otherRecipients.slice(0, 2).join(', ')}, dan ${otherRecipients.length - 2} lainnya...`;
                                  }
                                } else {
                                  if (recipientsArray.length <= 3) {
                                    return recipientsArray.join(', ');
                                  } else {
                                    return `${recipientsArray.slice(0, 2).join(', ')}, dan ${recipientsArray.length - 2} lainnya...`;
                                  }
                                }
                              })()}
                            </span>
                            <div style={{ border: 'none !important' }} className='dropdown'>
                              <button
                                // style={{ border: 'none' }}
                                // className='dropdown-toggle'
                                type='button'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                <i className='fas fa fa-caret-down'></i>
                              </button>
                              <DetailMoreInfo {...dataDetailEmails} />
                            </div>
                          </span>
                        </>
                      ) : typeMenu === 'sent' ||
                        typeMenu === 'spam' ||
                        typeMenu === 'archive' ||
                        typeMenu === 'trash' ? (
                        <span className='d-flex gap-2'>
                          <span className='text-muted fw-bold'>
                            Kepada{' '}
                            {(() => {
                              const recipientsArray = Array.isArray(dataDetailEmails.to)
                                ? dataDetailEmails.to
                                : (typeof dataDetailEmails.to === 'string' ? [dataDetailEmails.to] : []);

                              const otherRecipients = recipientsArray.filter(email => email !== userEmail?.email);

                              if (recipientsArray.includes(userEmail?.email || '')) {
                                if (otherRecipients.length === 0) {
                                  return 'Saya';
                                } else if (otherRecipients.length === 1) {
                                  return `Saya, ${otherRecipients[0]}`;
                                } else if (otherRecipients.length === 2) {
                                  return `Saya, ${otherRecipients.join(', ')}`;
                                } else {
                                  return `Saya, ${otherRecipients.slice(0, 2).join(', ')}, dan ${otherRecipients.length - 2} lainnya...`;
                                }
                              } else {
                                if (recipientsArray.length <= 3) {
                                  return recipientsArray.join(', ');
                                } else {
                                  return `${recipientsArray.slice(0, 2).join(', ')}, dan ${recipientsArray.length - 2} lainnya...`;
                                }
                              }
                            })()}
                          </span>
                          <div style={{ border: 'none !important' }} className='dropdown'>
                            <button
                              // style={{ border: 'none' }}
                              // className='dropdown-toggle'
                              type='button'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              <i className='fas fa fa-caret-down'></i>
                            </button>
                            <DetailMoreInfo {...dataDetailEmails} />
                          </div>
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center flex-wrap gap-2'>
                  <span className='fw-bold text-muted text-end me-3'>{dataDetailEmails.date}</span>
                </div>
              </div>
              <div
                style={{
                  display: 'block',
                  maxWidth: '100vw',
                  overflowX: 'auto',
                }}
                className='py-5'
                dangerouslySetInnerHTML={{
                  __html: dataDetailEmails.html === 'null' ? '' : dataDetailEmails.html || '',
                }}
              ></div>
              {dataDetailEmails && dataDetailEmails?.attachments?.length > 0 && (
                <>
                  <div className='separator separator-dashed my-6'></div>
                  <span className='fw-bolder text-dark'>
                    {dataDetailEmails.attachments.length + ' Lampiran'}
                  </span>
                  <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                    {dataDetailEmails.attachments.map((attachment: any) => {
                      return <div key={attachment.filename}>
                        {['jpeg', 'jpg', 'png'].includes(
                          getFileTypeFromName(attachment.filename)
                        ) ? (
                          <PreviewAttachment
                            {...attachment}
                            srcPreview={'data:' + attachment.contentType + ';base64,' + attachment.content.data}
                            onClick={() =>
                              downloadAttachment(
                                attachment.filename,
                                attachment.content.data,
                                attachment.type
                              )
                            }
                          />
                        ) : getFileTypeFromName(attachment.filename) === 'xlsx' ? (
                          <PreviewAttachment
                            {...attachment}
                            onClick={() =>
                              downloadAttachment(
                                attachment.filename,
                                attachment.content.data,
                                attachment.type
                              )
                            }
                          />
                        ) : getFileTypeFromName(attachment.filename) === 'pdf' ? (
                          <PreviewAttachment
                            {...attachment}
                            onClick={() =>
                              downloadAttachment(
                                attachment.filename,
                                attachment.content.data,
                                attachment.type
                              )
                            }
                          />
                        ) : getFileTypeFromName(attachment.filename) === 'docx' ? (
                          <PreviewAttachment
                            {...attachment}
                            onClick={() =>
                              downloadAttachment(
                                attachment.filename,
                                attachment.content.data,
                                attachment.type
                              )
                            }
                          />
                        ) : getFileTypeFromName(attachment.filename) === 'ppt' || 'pptx' ? (
                          <PreviewAttachment
                            {...attachment}
                            onClick={() =>
                              downloadAttachment(
                                attachment.filename,
                                attachment.content.data,
                                attachment.type
                              )
                            }
                          />
                        ) : (
                          <PreviewAttachment
                            {...attachment}
                            onClick={() =>
                              downloadAttachment(
                                attachment.filename,
                                attachment.content.data,
                                attachment.type
                              )
                            }
                          />
                        )}
                      </div>
                    })}
                  </div>
                </>
              )}
              {/* Component Reply dan Teruskan Pesan */}
              {typeMenu === 'draft' ? (
                <></>
              ) : (
                <div
                  style={{ width: '100%' }}
                  className='d-flex align-items-start justify-content-between mt-3'
                >
                  {!isReplyOpen ? (
                    <div className="d-flex gap-2">
                      <span
                        className='btn btn-sm cursor-pointer button-hover d-flex justify-content-center align-items-center'
                        onClick={() => toggleOpenReply('reply')}
                        style={{
                          fontSize: '1.1rem',
                          border: '0.7px solid black',
                          borderRadius: '30px',
                        }}
                      >
                        <img
                          className='w-25px svg-icon'
                          src='/media/svg/general/reply.svg'
                          alt='reply'
                        />
                        <span className='ms-1 fw-bold'>Reply</span>
                      </span>
                      {(getCcRecipients().length > 0 || (Array.isArray(dataDetailEmails.to) && dataDetailEmails.to.length > 1)) && (
                        <span
                          className='btn btn-sm cursor-pointer button-hover d-flex justify-content-center align-items-center'
                          onClick={() => toggleOpenReply('replyAll')}
                          style={{
                            fontSize: '1.1rem',
                            border: '0.7px solid black',
                            borderRadius: '30px',
                          }}
                        >
                          <img
                            className='w-20px svg-icon'
                            src='/media/svg/general/reply-all.svg'
                            alt='reply all'
                          />
                          <span className='ms-1 fw-bold'>Reply All</span>
                        </span>
                      )}
                    </div>
                  ) : (
                    <ReplyEmail
                      setIsReplyOpen={setIsReplyOpen}
                      emailTo={detailEmail.email}
                      cc={replyType === 'replyAll' ? getAllRecipients() : undefined}
                      bodyForReply={dataDetailEmails.body ?? ''}
                      replyType={replyType}
                    />
                  )}
                </div>
              )}
              {/* Component Reply dan Teruskan Pesan */}
            </div>
          </div>
        )}
      </div>

      {
        showAddMerchantModal && <AddMerchant addMerchant={addMerchant} />
      }
    </>
  )
}

export { DetailEmail }
