import { create } from 'zustand';
import { Company } from '../interfaces/Company';

type CompanyState = {
    companies: Company;
    setCompanies: (company: Company) => void;
}

export const useCompanyStore = create<CompanyState>((set, getState) => ({
    companies: {} as Company,

    setCompanies: (company) => set((state) => ({ ...state, companies: company })),
}));