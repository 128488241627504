import Swal from "sweetalert2";
import { KTSVG } from "../../_metronic/helpers";
import { useChatStore } from "../../stores/ChatStore";
import { useIntl } from "react-intl";
import { clearDB, clearDBExceptIncomingMessageNotifications } from "../../db";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTokenCustomCompany } from "../../stores/useTokenCustomCompany";

const ChatAside = () => {
    const intl = useIntl()
    const { clearTokenAndNumber } = useTokenCustomCompany()
    const { menuActive, setMenuActive } = useChatStore()
    const listMenu = [
        {
            id: "chat",
            name: "Chat",
            icon: "/media/icons/duotune/communication/com003.svg",
            to: "list-chat"
        },
        {
            id: "settings",
            name: "Pengaturan",
            icon: "/media/icons/duotune/coding/cod001.svg",
            to: "settings-chat"
        },
        {
            id: "refresh",
            name: "Refresh Data",
            icon: "/media/icons/duotune/arrows/arr029.svg",
            to: ""
        },
    ]

    const handleRefresh = () => {
        Swal.fire({
            title: intl.formatMessage({ id: "ALERT.TITLE.REFRESH_CHAT" }),
            text: intl.formatMessage({ id: "ALERT.TEXT.REFRESH_CHAT" }),
            icon: "question",
            showCancelButton: !0,
            buttonsStyling: !1,
            confirmButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.YES" }),
            cancelButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.NO" }),
            showLoaderOnConfirm: true,
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-active-light me-3',
            },
            reverseButtons: true,
            heightAuto: false,
            preConfirm: async () => {
                clearDBExceptIncomingMessageNotifications()
                window.location.reload()
            },
        })
    }

    return (
        <div className='card card-flush py-5 justify-content-between' style={{ maxHeight: "calc(100vh - 1rem)", height: "calc(100vh - 1rem)", width: "50px", backgroundColor: "#f4f7f9" }}>
            <div className="d-flex flex-column">
                {
                    listMenu.map((item, index) => {
                        return (
                            item.to &&
                            <div className={`menu-icon cursor-pointer align-self-center pb-5`} onClick={() => setMenuActive(item.id)}>
                                <OverlayTrigger
                                    key={`menu-${index}`}
                                    placement='right'
                                    overlay={
                                        <Tooltip id='tooltip-labels'>
                                            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {item.name}
                                            </span>
                                        </Tooltip>}
                                >
                                    <span
                                        id={`menu-${index}`}
                                        className='cursor-pointer'
                                        data-toggle='tooltip'
                                        data-placement='right'
                                        title=''
                                    >
                                        <span key={index}>
                                            <KTSVG path={item.icon} className={`svg-icon-1 ${menuActive.includes(item.id) && "svg-icon-dark"}`} />
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        )
                    })
                }
            </div>
            {
                listMenu.map((item, index) => {
                    return (
                        !item.to &&
                        <div className={`menu-icon cursor-pointer align-self-center`} onClick={handleRefresh}>
                            <OverlayTrigger
                                key={`menu-${index}`}
                                placement='right'
                                overlay={
                                    <Tooltip id='tooltip-labels'>
                                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {item.name}
                                        </span>
                                    </Tooltip>}
                            >
                                <span
                                    id={`menu-${index}`}
                                    className='cursor-pointer'
                                    data-toggle='tooltip'
                                    data-placement='right'
                                    title=''
                                >
                                    <span key={index}>
                                        <KTSVG path={item.icon} className={`svg-icon-1 ${menuActive.includes(item.id) && "svg-icon-dark"}`} />
                                    </span>
                                </span>
                            </OverlayTrigger>
                        </div>
                    )
                })
            }
        </div>
    )
}

export { ChatAside }