import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const CONFERENCE = `${API_URL}conference/company-room-create/`
const CREATE_CONFERENCES = `${API_URL}conference/company-create-meeting-session`
const SCHEDULE_CONFERENCES = `${API_URL}conference/company-room/`

export const insertConference = (roomCode: string, roomCategory: string, companyId: string, token: string) => {
    return axios.post(CONFERENCE,
        {
            roomCode,
            roomCategory,
            companyId
        },
        {
            headers: {
                'X-Auth-token': token
            }
        })
}

export const createConference = (roomCode: string, userId: string, creatorName: string, companyId: string, type: string, dateStart: string, dateEnd: string, token: string) => {
    return axios.post(CREATE_CONFERENCES,
        {
            roomCode,
            userId,
            creatorName,
            companyId,
            type,
            dateStart,
            dateEnd
        },
        {
            headers: {
                'X-Auth-token': token
            }
        })
}

export const getScheduleConference = (token: string, roomsId?: string[], usersId?: string[]) => {
    return axios.get(SCHEDULE_CONFERENCES+'schedules',
        // {
        //     roomsId,
        //     usersId
        // },
        {
            headers: {
                'X-Auth-token': token
            }
        })
}

export const insertScheduleConference = (roomId: string, userId: string, startTime: string, endTime: string, roomTitle: string, token: string) => {
    return axios.post(SCHEDULE_CONFERENCES+roomId+'/book',
        {
            userId,
            startTime,
            endTime,
            roomTitle
        },
        {
            headers: {
                'X-Auth-token': token
            }
        })
}

export const updateScheduleConference = (roomId: string, id: string, startTime: string, endTime: string, roomTitle: string, token: string) => {
    return axios.put(SCHEDULE_CONFERENCES+roomId+'/book/'+id,
        {
            startTime,
            endTime,
            roomTitle
        },
        {
            headers: {
                'X-Auth-token': token
            }
        })
}

export const deletetScheduleConference = (roomId: string, id: string, token: string) => {
    return axios.delete(SCHEDULE_CONFERENCES+roomId+'/book/'+id,
        {
            headers: {
                'X-Auth-token': token
            }
        })
}