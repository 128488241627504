import { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import 'react-loading-skeleton/dist/skeleton.css'
import 'rsuite/dist/rsuite-no-reset.min.css'
import idLocale from "../locales/id.json"
import enLocale from "../locales/en.json"
import { IntlProvider } from 'react-intl'
import { useLanguageStore } from '../stores/LanguageStore'

const App = () => {


  const { locale } = useLanguageStore()

  const allMessages: { [key: string]: { [key: string]: string } } = {
    id: idLocale,
    en: enLocale
  }

  const messages = allMessages[locale]

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <IntlProvider locale={locale} messages={messages}>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </IntlProvider>
    </Suspense>
  )
}

export { App }
