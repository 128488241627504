import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { SelectOption } from "../../interfaces/SelectOption";
import { useSettingsStore } from "../../stores/SettingsStore";
import useAccessToken from "../../hooks/AccessToken";
import { CustomField, TypeSerial } from "../../interfaces/Settings";
import { saveSetting } from "../../api/SettingCRUD";
import { useIntl } from "react-intl";
import moment from "moment";
import { KTSVG } from "../../_metronic/helpers";
import clsx from "clsx";
import * as Yup from 'yup';
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { RowCustomField } from "./RowCustomField";
import { convertFromMinutes, convertToMinutes } from "../../functions/general";

interface Props {
    optionType: SelectOption[]
    optionsResetSerial: SelectOption[]
}

const CustomFieldMeeting: FC<Props> = ({ optionType, optionsResetSerial }) => {
    const { settings, setSettings } = useSettingsStore()
    const token = useAccessToken()

    const [keysMeeting, setKeysMeeting] = useState<string[]>([])
    const [fieldMeeting, setFieldMeeting] = useState<CustomField>({})
    const [loadingMeetingPreferences, setLoadingMeetingPreferences] = useState(false)
    const [loadingMeeting, setLoadingMeeting] = useState(false)
    const [countAutoCancel, setCountAutoCancel] = useState({
        day: 0,
        hour: 0,
        minute: 0
    })
    const intl = useIntl()

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const formikPreferences = useFormik({
        initialValues: {
            key: '',
            value: settings.meeting_title ? settings.meeting_title : intl.formatMessage({ id: "MENU.MEETINGS" }),
            checkAutoCancel: Number(settings.meeting_auto_cancel) > 0 ? true : false,
            valueAutoCancel: settings.meeting_auto_cancel ? settings.meeting_auto_cancel : -1,
            // valueVisitRadius: settings.radius_visit ? settings.radius_visit : 1,
            valueVisitRadius: 500,
            checkUpdateMeeting: settings.update_meeting,
        },
        validationSchema: Yup.object().shape({
            value: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            valueVisitRadius: Yup.number().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.VISIT_RADIUS" }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            let isAutoCancelEmpty = false

            if (values.value === "") return false
            if (Number(values.valueVisitRadius) === 0) {
                Swal.fire({
                    title: intl.formatMessage({ id: "FORM.VALIDATION.RADIUS" }, { title: settings.meeting_title }),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    heightAuto: false
                })
                setLoadingMeetingPreferences(false)
                return false
            }
            if (values.valueVisitRadius < 0) return false
            if (values.checkAutoCancel && values.valueAutoCancel! <= 0) isAutoCancelEmpty = true
            if (isAutoCancelEmpty) {
                Swal.fire({
                    title: intl.formatMessage({ id: "FORM.VALIDATION.MEETING_CANCEL_TIME" }, { title: settings.meeting_title }),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    heightAuto: false
                })
                setLoadingMeetingPreferences(false)
                return false
            }

            setLoadingMeetingPreferences(true)

            try {
                const response = await saveSetting("meeting_title", values.value, token)
                const response2 = await saveSetting("meeting_auto_cancel", String(values.valueAutoCancel === 0 ? -1 : values.valueAutoCancel), token)
                const response3 = await saveSetting("radius_visit", String(values.valueVisitRadius), token)
                const response4 = await saveSetting("update_meeting", String(values.checkUpdateMeeting), token)
                if (response.data.success && response2.data.success && response3.data.success && response4.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    Object.values(fieldMeeting).forEach(field => {
                        field.new = false;
                    });
                    setSettings({
                        ...settings,
                        meeting_title: values.value,
                        meeting_auto_cancel: Number(values.valueAutoCancel),
                        radius_visit: Number(values.valueVisitRadius),
                        update_meeting: values.checkUpdateMeeting
                    })
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.respons.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setLoadingMeetingPreferences(false)
            }
        }
    })

    const formik = useFormik({
        initialValues: {
            key: '',
        },
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            let isDuplicate = false
            let isNameColumnEmpty = false
            let isOptionEmpty = false

            Object.keys(fieldMeeting).map((key1) => {
                if (fieldMeeting[key1].type === "option" || fieldMeeting[key1].type === "multiple") {
                    if ((fieldMeeting[key1].value as string[]).includes("")) {
                        isOptionEmpty = true
                    }
                }

                if (fieldMeeting[key1].name === "") {
                    isNameColumnEmpty = true
                }

                Object.keys(fieldMeeting).map((key2) => {
                    if ((fieldMeeting[key1].name)?.trim() === (fieldMeeting[key2].name)?.trim() && key1 !== key2) {
                        isDuplicate = true
                    }
                })
            })

            const modifiedObject = Object.fromEntries(
                Object.entries(fieldMeeting).map(([key, value]) => {
                    const { new: _, ...rest } = value;
                    return [key, rest];
                })
            );

            if (isOptionEmpty) return false
            if (isNameColumnEmpty) return false
            if (isDuplicate) {
                Swal.fire({
                    title: intl.formatMessage({ id: "FORM.VALIDATION.DUPLICATE" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME_COLUMN" }) }),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    heightAuto: false
                })
                return false
            }

            setLoadingMeeting(true)

            try {
                const res = await saveSetting("meeting_custom_fields", JSON.stringify(modifiedObject), token)
                if (res.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    Object.values(fieldMeeting).forEach(field => {
                        field.new = false;
                    });
                    setSettings({ ...settings, meeting_custom_fields: JSON.stringify(fieldMeeting) })
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.respons.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setLoadingMeeting(false)
            }
        }
    });

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const updateColumns = (field: CustomField) => {
                // Ambil urutan kunci lama
                const keys = Object.keys(field);
                // Temukan indeks lama dan baru
                const oldIndex = keys.indexOf(active.id);
                const newIndex = keys.indexOf(over.id);

                // Urutkan kunci dengan arrayMove
                const newKeys = arrayMove(keys, oldIndex, newIndex);

                // Buat objek baru dengan urutan kunci baru
                const newField: CustomField = {};
                newKeys.forEach(key => {
                    newField[key] = field[key];
                });

                return newField;
            };

            // Perbarui urutan fieldMeeting
            const newColumns = updateColumns(fieldMeeting);
            setFieldMeeting(newColumns);
        }
    };

    const addMeetingColumn = () => {
        var key = crypto.randomUUID()
        const addColumnMeeting = { ...fieldMeeting, [key]: { name: `${intl.formatMessage({ id: "FORM.LABEL.COLUMN" })} ` + (keysMeeting.length + 1), type: 'text', required: false, value: [], score: "", new: true } };
        setFieldMeeting(addColumnMeeting);
        setKeysMeeting([...keysMeeting, key])
    }

    const handleCheckAutoCancel = (checked: boolean) => {
        formikPreferences.setFieldValue('checkAutoCancel', checked)
        formikPreferences.setFieldValue('valueAutoCancel', -1)
        setCountAutoCancel({
            day: 0,
            hour: 0,
            minute: 0
        })
    }

    const handleCheckUpdateMeeting = (checked: boolean) => {
        formikPreferences.setFieldValue('checkUpdateMeeting', checked)
    }

    useEffect(() => {
        formikPreferences.setFieldValue("valueAutoCancel", (convertToMinutes(countAutoCancel)))
    }, [countAutoCancel])

    useEffect(() => {
        if (settings.meeting_custom_fields) {
            setFieldMeeting(JSON.parse(`${settings.meeting_custom_fields}`))
            setKeysMeeting(Object.keys(JSON.parse(`${settings.meeting_custom_fields}`)))
        }

        if (Number(settings.meeting_auto_cancel) > 0) {
            setCountAutoCancel(convertFromMinutes(Number(settings.meeting_auto_cancel)))
            // formikPreferences.setFieldValue("checkAutoCancel", true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings])

    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">{intl.formatMessage({ id: "FORM.LABEL.PREFERENCES" }, { title: settings.meeting_title })}</h3>
                    </div>
                </div>
                <div id="kt_account_settings_email_preferences">
                    <form onSubmit={formikPreferences.handleSubmit} noValidate>
                        <div className="card-body border-top px-9 py-9">
                            <div className="row">
                                <span className="col-md-7 col-lg-9 col-xl-9 d-flex flex-column align-items-start">
                                    <span className="fw-bolder fs-5 mb-0">{`${intl.formatMessage({ id: "FORM.LABEL.NAME" })} `}</span>
                                    <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_SETTING_NAME" })}</span>
                                </span>
                                <div className="col-md-5 col-lg-3 col-xl-3">
                                    <input
                                        {...formikPreferences.getFieldProps('value')}
                                        className={clsx(
                                            'form-control',
                                            { 'is-invalid': formikPreferences.touched.value && formikPreferences.errors.value },
                                        )}
                                        type='text'
                                        name='value'
                                    />
                                    {formikPreferences.touched.value && formikPreferences.errors.value && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formikPreferences.errors.value}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* <div className="separator separator-dashed my-6"></div>
                            <div className="row">
                                <span className="col-md-7 col-lg-9 col-xl-9 d-flex flex-column align-items-start">
                                    <span className="fw-bolder fs-5 mb-0">{intl.formatMessage({ id: "FORM.LABEL.VISIT_RADIUS" })}</span>
                                    <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_VISIT_RADIUS" })}</span>
                                </span>
                                <div className="col-md-5 col-lg-3 col-xl-3">
                                    <div className="input-group mb-5">
                                        <input
                                            {...formikPreferences.getFieldProps('valueVisitRadius')}
                                            className={clsx(
                                                'form-control',
                                                { 'is-invalid': formikPreferences.touched.valueVisitRadius && formikPreferences.errors.valueVisitRadius },
                                            )}
                                            disabled
                                            type='text'
                                            name='valueVisitRadius'
                                            onChange={(e) => { formikPreferences.setFieldValue('valueVisitRadius', (e.target.value).replace(/[^0-9]/g, '')) }}
                                        />
                                        <span className="input-group-text" id="basic-addon2">Meter</span>
                                    </div>
                                    {formikPreferences.touched.valueVisitRadius && formikPreferences.errors.valueVisitRadius && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formikPreferences.errors.valueVisitRadius}</span>
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            <div className="separator separator-dashed my-6"></div>
                            <div className="row">
                                <label className="col-md-7 col-lg-9 col-xl-9 form-check form-switch form-check-custom form-check-solid align-items-start cursor-pointer">
                                    <input className="form-check-input me-3" type="checkbox" {...formikPreferences.getFieldProps('checkUpdateMeeting')} checked={formikPreferences.values.checkUpdateMeeting!} onChange={(e) => handleCheckUpdateMeeting(e.target.checked)} />
                                    <span className="form-check-label d-flex flex-column align-items-start">
                                        <span className="fw-bolder fs-5 mb-0">{intl.formatMessage({ id: "FORM.LABEL.UPDATE_CUSTOM_FIELD" })} {settings.meeting_title}</span>
                                        <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_UPDATE_CUTOM_FIELD" }, { title: settings.meeting_title })}</span>
                                    </span>
                                </label>
                            </div>
                            <div className="separator separator-dashed my-6"></div>
                            <div className="row">
                                <label className="col-md-7 col-lg-9 col-xl-9 form-check form-switch form-check-custom form-check-solid align-items-start cursor-pointer">
                                    <input className="form-check-input me-3" type="checkbox" {...formikPreferences.getFieldProps('checkAutoCancel')} checked={formikPreferences.values.checkAutoCancel!} onChange={(e) => handleCheckAutoCancel(e.target.checked)} />
                                    <span className="form-check-label d-flex flex-column align-items-start">
                                        <span className="fw-bolder fs-5 mb-0">{intl.formatMessage({ id: "FORM.LABEL.MEETING_AUTO_CANCEL_2" }, { title: settings.meeting_title })}</span>
                                        <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_MEETING_AUTO_CANCEL_2" }, { title: settings.meeting_title })}</span>
                                    </span>
                                </label>
                                {
                                    formikPreferences.values.checkAutoCancel &&
                                    <div className="col-md-5 col-lg-3 col-xl-3 d-flex justify-content-end align-items-center">
                                        <div className="d-flex align-items-center gap-2 me-3">
                                            <input
                                                value={countAutoCancel.day === 0 ? "" : countAutoCancel.day}
                                                className={'form-control ms-3'}
                                                type='text'
                                                name='value'
                                                style={{ border: "none", borderBottom: "2px solid #E4E6EF", width: "50px", textAlign: "right" }}
                                                onChange={(e) => { setCountAutoCancel({ ...countAutoCancel, day: Number((e.target.value).replace(/[^0-9]/g, '')) }) }}
                                            />
                                            <label>{intl.formatMessage({ id: "DAYS" })},</label>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 me-3">
                                            <input
                                                value={countAutoCancel.hour === 0 ? "" : countAutoCancel.hour}
                                                className={'form-control ms-3'}
                                                type='text'
                                                name='value'
                                                style={{ border: "none", borderBottom: "2px solid #E4E6EF", width: "50px", textAlign: "right" }}
                                                onChange={(e) => { setCountAutoCancel({ ...countAutoCancel, hour: Number((e.target.value).replace(/[^0-9]/g, '')) }) }}
                                            />
                                            <label>{intl.formatMessage({ id: "HOURS" })},</label>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                            <input
                                                value={countAutoCancel.minute === 0 ? "" : countAutoCancel.minute}
                                                className={'form-control ms-3'}
                                                type='text'
                                                name='value'
                                                style={{ border: "none", borderBottom: "2px solid #E4E6EF", width: "50px", textAlign: "right" }}
                                                onChange={(e) => { setCountAutoCancel({ ...countAutoCancel, minute: Number((e.target.value).replace(/[^0-9]/g, '')) }) }}
                                            />
                                            <label>{intl.formatMessage({ id: "MINUTES" })}</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="submit" className={`btn btn-primary ${loadingMeetingPreferences ? "disabled" : ""}`} data-kt-indicator={loadingMeetingPreferences ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">{intl.formatMessage({ id: "FORM.LABEL.CUSTOM_COLUMN" })}</h3>
                    </div>
                </div>
                <div id="kt_account_settings_email_preferences">
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="card-body border-top px-9 py-9">
                            {
                                <DndContext onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={closestCenter}>
                                    <SortableContext items={Object.keys(fieldMeeting) as any} strategy={verticalListSortingStrategy}>
                                        {
                                            Object.keys(fieldMeeting).map((key, index) => {
                                                return (
                                                    <RowCustomField
                                                        key={key}
                                                        id={key}
                                                        type="meeting"
                                                        customField={fieldMeeting}
                                                        index={index}
                                                        optionType={optionType}
                                                        optionsResetSerial={optionsResetSerial}
                                                        setCustomField={setFieldMeeting}
                                                    />
                                                )
                                            })
                                        }
                                    </SortableContext>
                                </DndContext>
                            }
                        </div>
                        <div className="card-footer py-6 px-9">
                            <div className="row">
                                <label className="col-xl-3"></label>
                                <div className="col-lg-9 col-xl-9" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <button type="button" className="btn btn-secondary" onClick={() => addMeetingColumn()}>
                                        <i className="fas fa-plus"></i>
                                        {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.COLUMN" })}
                                    </button>
                                    <button type="submit" className={`btn btn-primary ${loadingMeeting ? "disabled" : ""}`} data-kt-indicator={loadingMeeting ? 'on' : 'off'}>
                                        <span className="indicator-label">
                                            {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                        </span>
                                        <span className="indicator-progress">
                                            Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export { CustomFieldMeeting }