import { create } from 'zustand';
import { TemplatePipelineProps } from '../interfaces/Pipeline';
import { createJSONStorage, persist } from 'zustand/middleware';

type TemnplatePipeline = {
    userTemplatePipeline: TemplatePipelineProps[];
    setUserTemplatePipeline: (userTemplatePipeline: TemplatePipelineProps[]) => void;
    companyTemplatePipeline: TemplatePipelineProps[];
    setCompanyTemplatePipeline: (companyTemplatePipeline: TemplatePipelineProps[]) => void;
}

export const useTemplatePipeline = create<TemnplatePipeline>()(
    persist(
        (set) => ({
            userTemplatePipeline: [],
            companyTemplatePipeline: [],
            setUserTemplatePipeline: (userTemplatePipeline) => {
                set({ userTemplatePipeline });
            },
            setCompanyTemplatePipeline: (companyTemplatePipeline) => {
                set({ companyTemplatePipeline });
            },
        }),
        {
            name: 'template-pipeline',
            storage: createJSONStorage(() => localStorage),
        }
    )
);
