import { FC, ReactNode, useEffect, useRef, useState } from "react";
import moment from "moment";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { RootState } from "../../../setup";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { Link, useParams } from "react-router-dom";
import { User } from "../../../interfaces/User";
import { AddUser } from "../../../components/user/AddUser";
import { UpdateUser } from "../../../components/user/UpdateUser";
import { DeleteUser } from "../../../components/user/DeleteUser";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Pipeline } from "../../../interfaces/Pipeline";
import { RecentChatHistoryPipeline } from "../../../interfaces/Chat/RecentChatHistory";
import { Log, LogAttachment } from "../../../interfaces/Log";
import { LogAttachments } from "./LogAttachments";
import { SendReportLog } from "./SendReportLog";
import { isColorDarkFromHex, isFileUrl } from "../../../functions/general";
import { useIntl } from "react-intl";
import { AddLog } from "./AddLog";
import useUser from "../../../hooks/User";
import { useCustomeStore } from "../../../stores/CustomeStore";
import { ExpandableElement } from "../../General/ExpandableElement";
import { useDataTableStore } from "../../../stores/DataTableStore";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { UserActivity } from "../../../interfaces/UserActivity";
import { ActivityAttachments } from "../../Activities/ActivityAttachment";
import { settings } from "cluster";
import { ExpandableElementProduct } from "./ExpandableElementProduct";

type LogProps = {
    lead: any,
    setLead?: (lead: Pipeline | RecentChatHistoryPipeline) => void
}

const MasterLog: FC<LogProps> = ({ lead, setLead }) => {
    const user = useUser()
    const intl = useIntl();
    const { code } = useParams();
    const { settings } = useSettingsStore()
    const { tablesData } = useDataTableStore();
    const { companyId } = useCustomeStore()
    const [log, setLog] = useState<Log>();
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);
    const [displayMode, setDisplayMode] = useState<string>('log');
    const [showAttachmentActivityModal, setShowAttachmentActivityModal] = useState<boolean>(false);
    const [activity, setActivity] = useState<UserActivity>();
    const [url, setUrl] = useState<string>('');

    useEffect(() => {
        if (showAttachmentModal && log) {
            const modalElement = document.getElementById(`attachments-log-modal-${log.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAttachmentModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAttachmentModal, log, setShowAttachmentModal]);

    useEffect(() => {
        if (showAttachmentActivityModal && activity) {
            const modalElement = document.getElementById(`attachments-activity-modal-${activity.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAttachmentActivityModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAttachmentActivityModal, activity, setShowAttachmentActivityModal]);

    const hasValidAttachments = (attachments: LogAttachment[] | undefined): boolean => {
        if (!attachments || attachments.length === 0) {
            return false;
        }

        const isValidUrl = (url: string): boolean => {
            try {
                new URL(url);
                return true;
            } catch {
                return false;
            }
        };

        return attachments.some(attachment => isValidUrl(attachment.url));
    };

    const isShowBtnAddLog = (row: Pipeline) => {
        const { is_superadmin, permission_pipeline_log_create, company_id } = user.data;
        const isAuthorized = is_superadmin || permission_pipeline_log_create !== 'NONE';
        const isLeadProgressTerm1 = row?.lead_progress_name.toLocaleLowerCase() === "termin 1";
        const isCompanyMatch = company_id === companyId.uec || company_id === companyId.looyalIT;
        const isLeadInProgress = row?.lead_progress_progress_type !== "FAIL" && row?.lead_progress_progress_type !== "SUCCESS";

        if (settings.unlimited_add_lead_log) return true

        if (isAuthorized && isLeadProgressTerm1 && isCompanyMatch) {
            return true;
        }

        if (isAuthorized && isLeadInProgress) {
            return true;
        }

        return false;
    };

    const columnCustomVedaPraxis: TableColumn<Log> = {
        name: 'Product Log',
        width: '400px',
        selector: (row: Log) => JSON.stringify(row.productLog) || '-',
        cell: (row: Log) => {
            if (!row.productLog) return '-';

            // Ambil semua kunci tanggal
            const dates = Object.keys(row.productLog);

            const data = dates.map((date, dateIndex) => {
                const products = row.productLog[date]; // Produk berdasarkan tanggal
                const productEntries = Object.entries(products); // Konversi produk menjadi array [key, value]

                const total = productEntries.reduce((acc: number, [, product]: [string, any]) => {
                    return acc + product.total_price;
                }, 0);

                return (
                    <div key={dateIndex} className="mb-2">
                        <div className="fw-bolder mb-2">
                            {moment(date).format('DD MMM YYYY HH:mm')}
                        </div>

                        <ul>
                            {productEntries.map(([productId, product]: [string, any], index) => {
                                const newValues = parseInt(product.price_product);
                                return (
                                    <li key={productId}>
                                        <span className="fw-normal">{product.product_name}</span> - Rp{' '}
                                        {new Intl.NumberFormat('id-ID').format(newValues)} x {product.qty}
                                    </li>
                                );
                            })}
                        </ul>

                        <div className="fw-normal">
                            Total: Rp{' '}
                            {new Intl.NumberFormat('id-ID').format(total)}
                        </div>
                        {dateIndex !== dates.length - 1 &&
                            <span className="d-flex justify-content-center my-2">
                                <i className="fas fa-arrow-up"></i>
                            </span>
                        }
                    </div>
                );
            });

            return (
                <ExpandableElementProduct
                    data={data}
                    maxHeight={80}
                />
            );
        }
    };


    const tableColumnLogs: TableColumn<Log>[] = [
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CREATED_BY" }),
            width: '200px',
            selector: row => row.creator_name === "" ? "-" : row.creator_name,
            cell: row => row.creator_name === "" ? "-" : row.creator_name
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.DATE_STARTED" }),
            width: '200px',
            selector: row => moment(row.date_created).format('dddd, DD MMM YYYY HH:mm'),
            cell: (row) => {
                return moment(row.date_created).format('dddd, DD MMM YYYY HH:mm')
            },
        },
        {
            name: user.data.company_id === companyId.juraganMaterial ? intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PREV_PROGRESS_DURATION" }) : intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.DURATION" }),
            width: '200px',
            selector: row => row.duration,
            cell: (row) => {
                return row.duration
            },
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PROGRESS" }),
            width: 'auto',
            selector: row => row.lead_sub_progress_name ? row.lead_progress_name + " (" + row.lead_sub_progress_name + ")" : row.lead_progress_name,
            cell(row) {
                if (row.lead_sub_progress_name) {
                    if (row.lead_progress_color) return <span className="badge" style={{ backgroundColor: row.lead_progress_color, color: isColorDarkFromHex(row.lead_progress_color) ? "white" : "black" }}>{row.lead_progress_name + " (" + row.lead_sub_progress_name + ")"}</span>
                    else return <span className="badge badge-light-info" >{row.lead_progress_name + " (" + row.lead_sub_progress_name + ")"}</span>
                } else {
                    if (row.lead_progress_color) return <span className="badge" style={{ backgroundColor: row.lead_progress_color, color: isColorDarkFromHex(row.lead_progress_color) ? "white" : "black" }}>{row.lead_progress_name}</span>
                    else return <span className="badge badge-light-info" >{row.lead_progress_name}</span>
                }
            },
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.DESCRIPTION" }),
            width: '400px',
            selector: row => row.description ?? '-',
            cell: row => {
                if (row.description) {
                    var data = (row.description).replaceAll("\n", "<br/>")
                    return <ExpandableElement text={data} maxHeight={100} />;
                } else return '-'
            },
        },
        ...(user.data.company_id === companyId.vedaPraxis || companyId.looyalIT ? [columnCustomVedaPraxis] : []),
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.ATTACHMENT" }),
            width: '100px',
            center: true,
            cell: (row) => {
                return hasValidAttachments(row.attachments) ? (
                    <span role="button" onClick={() => {
                        setShowAttachmentModal(true);
                        setLog(row);
                    }}>
                        <i className="fas fs-2 fa-eye"></i>
                    </span>
                ) : "-";
            },
        }

    ];

    const tableColumnActivity: TableColumn<UserActivity>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TIME' }),
            selector: row => row.date_created?.toLowerCase() ?? "-",
            sortable: true,
            cell: (row) => {
                return row.date_created && moment(row.date_created).format('dddd, DD MMM YYYY HH:mm')
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.USER' }),
            width: '20%',
            selector: row => row.user_name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.user_name
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTIVITY' }),
            width: '60%',
            selector: row => row.description?.toLowerCase() ?? "-",
            sortable: true,
            cell: (row) => {
                if (row.activity_type === 'INSERT' && row.table_type === 'PIPELINE LOG') {
                    return <div>
                        {
                            row.table_type === 'PIPELINE LOG' ? <span>{row.description}, progres {row.new_data.Progress}, </span>
                                : <span>{row.description}, </span>
                        }
                        <br />
                        <p dangerouslySetInnerHTML={{ __html: row.new_data.Deskripsi }}></p>
                    </div>
                } else {
                    return <p>{row.description}</p>
                }
            }
        },
    ];

    let actions: ReactNode[] = [];
    if (user.data.is_allowed_send_report) {
        actions.push(<SendReportLog lead={lead} />)
    }

    if (!window.location.pathname.includes("chat")) {
        actions.push(<AddLog lead={lead} setLead={setLead} showAddLogButton={isShowBtnAddLog(lead)} />)
    } else {
        if (isShowBtnAddLog(lead)) {
            actions.push(
                <button
                    type="button"
                    className="btn btn-primary mt-5 mt-md-0"
                    data-bs-toggle="modal"
                    data-bs-target="#tambah-log-modal"
                >
                    {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.MENU.LOGS" })}
                </button>
            )
        }
    }

    return (
        <div className="card">
            <div className="card-header border-0">
                <div className="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-10 nav nav-tabs border-bottom-0">
                    <div className={`fs-4 fw-bolder pb-3 cursor-pointer ${displayMode === 'log' ? 'border-bottom border-3 border-primary' : 'text-muted'}`} onClick={() => setDisplayMode('log')}>Log ({tablesData['logs'] ? tablesData['logs'].length : 0})</div>
                    <div className={`fs-4 fw-bolder pb-3 cursor-pointer ${displayMode === 'activity-pipeline' ? 'border-bottom border-3 border-primary' : 'text-muted'}`} onClick={() => setDisplayMode('activity-pipeline')}>{intl.formatMessage({ id: "FORM.LABEL.ACTIVITIES" }, { title: settings.pipeline_title })} ({tablesData['pipeline-activities'] ? tablesData['pipeline-activities'].length : 0})</div>
                </div>
            </div>

            <div className={`${displayMode === "log" ? "" : "d-none"}`}>
                <MasterDataTable
                    tableKey="logs"
                    tableColumns={tableColumnLogs}
                    apiURL={`lead_logs/?lead_id=${lead.id}`}
                    actions={actions}
                    lead={lead}
                />
            </div>
            <div className={`${displayMode === "activity-pipeline" ? "" : "d-none"}`}>
                {
                    <MasterDataTable
                        tableKey="pipeline-activities"
                        tableColumns={tableColumnActivity}
                        apiURL={`user-activities?tableType=PIPELINE&activitySourceId=${lead.id}`}
                        // orderBy={1}
                        // order="desc"
                        expanded
                        customParams={{ setUrl: setUrl, setActivity: setActivity, setShowAttachmentModal: setShowAttachmentActivityModal }}
                    />
                }
            </div>

            {
                showAttachmentModal && <LogAttachments log={log!} />
            }

            {
                showAttachmentActivityModal && <ActivityAttachments activity={activity!} url={url!} />
            }
        </div>
    )
}

export { MasterLog }