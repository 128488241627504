import { FC } from "react";
import { useChatStore } from "../../../stores/ChatStore";
import { getFileType } from "../../../functions/general";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

interface ChatAttachmentPreviewProps {
    index: number;
    blobUrl: string;
    type: 'image' | 'video' | 'file'
}

const ChatAttachmentPreview: FC<ChatAttachmentPreviewProps> = ({ index, blobUrl, type }) => {

    const { phoneNumber, imageBlobUrls, setImageBlobUrls, videoBlobUrls, setVideoBlobUrls, fileBlobUrls, setFileBlobUrls } = useChatStore()

    const handleDeleteImage = (index: number) => {
        const newImageBlobUrls = { ...imageBlobUrls };

        // Check if phoneNumber exists in imageBlobUrls
        if (newImageBlobUrls[phoneNumber]) {
            // Filter out the imageBlobUrl at the specified index
            newImageBlobUrls[phoneNumber].blobs = newImageBlobUrls[phoneNumber].blobs.filter((imageBlobUrl, i) => i !== index);

            // Update the state with the newImageBlobUrls
            setImageBlobUrls(newImageBlobUrls);
        }
    }

    const handleDeleteVideo = (index: number) => {
        const newVideoBlobUrls = { ...videoBlobUrls };

        // Check if phoneNumber exists in videoBlobUrls
        if (newVideoBlobUrls[phoneNumber]) {
            // Filter out the videoBlobUrl at the specified index
            newVideoBlobUrls[phoneNumber].blobs = newVideoBlobUrls[phoneNumber].blobs.filter((videoBlobUrl, i) => i !== index);

            // Update the state with the newVideoBlobUrls
            setVideoBlobUrls(newVideoBlobUrls);
        }
    }

    const handleDeleteFile = (index: number) => {
        const newFileBlobUrls = { ...fileBlobUrls };

        // Check if phoneNumber exists in fileBlobUrls
        if (newFileBlobUrls[phoneNumber]) {
            // Filter out the fileBlobUrl at the specified index
            newFileBlobUrls[phoneNumber].blobs = newFileBlobUrls[phoneNumber].blobs.filter((fileBlobUrl, i) => i !== index);

            // Update the state with the newFileBlobUrls
            setFileBlobUrls(newFileBlobUrls);
        }
    }

    return (
        <div className="position-relative d-inline-block mt-auto">
            {
                imageBlobUrls[phoneNumber].blobs.length > 0 && imageBlobUrls[phoneNumber].isUploading &&
                <div className="loading-overlay">
                    <div className="loader"></div>
                </div>
            }
            {
                type === 'image' &&
                <>
                    <img key={`image${index}`} alt='Pic' src={blobUrl} width={200} className="p-1 mx-1 bg-dark" />
                    <span role="button" onClick={() => handleDeleteImage(index)}>
                        <i className="fas fa-times-circle fa-lg position-absolute top-0 end-0 m-2 text-danger"></i>
                    </span>
                </>
            }
            {
                videoBlobUrls[phoneNumber].blobs.length > 0 && videoBlobUrls[phoneNumber].isUploading &&
                <div className="loading-overlay">
                    <div className="loader"></div>
                </div>
            }
            {
                type === 'video' &&
                <>
                    <video key={`video${index}`} width={200} controls className="p-1 mx-1 bg-dark">
                        <source src={blobUrl} type="video/mp4" />
                    </video>
                    <span role="button" onClick={() => handleDeleteVideo(index)}>
                        <i className="fas fa-times-circle fa-lg position-absolute top-0 end-0 m-2 text-danger"></i>
                    </span>
                </>
            }
            {
                fileBlobUrls[phoneNumber].blobs.length > 0 && fileBlobUrls[phoneNumber].isUploading &&
                <div className="loading-overlay">
                    <div className="loader"></div>
                </div>
            }
            {
                type === 'file' &&
                <>
                    <a key={`file${index}`} href={blobUrl} target="_blank" rel="noreferrer" className="p-1 mx-1">
                        {
                            getFileType(blobUrl) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} className="mb-2" alt={blobUrl} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                : getFileType(blobUrl) === "doc" || getFileType(blobUrl) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} className="mb-2" alt={blobUrl} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                    : getFileType(blobUrl) === "ppt" || getFileType(blobUrl) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} className="mb-2" alt={blobUrl} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                        : getFileType(blobUrl) === "xlsx" || getFileType(blobUrl) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} className="mb-2" alt={blobUrl} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                            : <img src={toAbsoluteUrl('/media/logos/file.png')} className="mb-2" alt={blobUrl} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                        }
                    </a>
                    <span role="button" onClick={() => handleDeleteFile(index)}>
                        <i className="fas fa-times-circle fa-lg position-absolute top-0 end-0 m-2 text-danger"></i>
                    </span>
                </>
            }
        </div>
    );
};

export { ChatAttachmentPreview };