import { FC, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { User } from "../../interfaces/User";
import { deleteUser } from "../../api/UsersCRUD";
import { useCompanyStore } from "../../stores/CompanyStore";
import { useIntl } from 'react-intl';

interface Props {
    userData: User
}

const DeleteUser: FC<Props> = ({ userData }) => {

    const closeModalRef = useRef<HTMLButtonElement>(null);

    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const { companies, setCompanies } = useCompanyStore()
    const token = useAccessToken()

    const intl = useIntl()

    const handleDeleteProduct = async () => {
        setModalBtnLoading(true)
        try {
            const response = await deleteUser(userData.id, token)
            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                    text: intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" }),
                    heightAuto: false,
                })
                closeModalRef.current?.click();
                setTablesData('users', (tablesData.users.filter((data) => data.id !== userData.id)))
                setCompanies({ ...companies, usersCount: companies.usersCount - 1 })
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`delete-user-modal-${userData.id}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.DELETE" })} {userData.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>{intl.formatMessage({ id: "FORM.CONFIRMATION" })} {intl.formatMessage({ id: "FORM.ACTION.DELETE" }).toLowerCase()} {userData.name} ?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleDeleteProduct}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DeleteUser }