import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import { useCustomChatStore } from '../../../../stores/CustomChatStore'

type Props = {
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AsideMenuChat: React.FC<Props> = ({
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, '/chat')
  const { config } = useLayout()
  const { aside } = config
  const { setShowModalSelectUser } = useCustomChatStore()

  return (
    <div className='menu-item'>
      <Link
        className={clsx('menu-link without-sub', { active: isActive })}
        to={'#'}
        onClick={() => setShowModalSelectUser(true)}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={'/media/icons/duotune/communication/com007.svg'} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
      </Link>
    </div>
  )
}

export { AsideMenuChat }
