import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps } from "../../functions/general";
import useUser from "../../hooks/User";
import { insertConference } from "../../api/ConferenceCRUD";
import Select from "react-select";
import { SelectOption } from "../../interfaces/SelectOption";
import useOptionTypeConference from "../../hooks/OptionTypeConference";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

interface Props {
    limitRooms: number
    totalRooms: number
    setTotalRooms: (value: number) => void
}

const AddConference: FC<Props> = ({ limitRooms, totalRooms, setTotalRooms }) => {
    const user = useUser();
    const intl = useIntl()
    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()

    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const optionType = useOptionTypeConference()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: '',
            type: '',
        },
        validationSchema: Yup.object().shape({
            code: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.ROOM_CODE" }) })),
            type: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TYPE" }) })),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)

            try {
                const response = await insertConference(user.data.company_prefix + "-" + values.code, values.type, user.data.company_id, token)
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_ROOM_SUCCESS" })}`,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    setTablesData('conferences', ([response.data.data, ...tablesData.conferences]))
                    setTotalRooms(totalRooms + 1)
                    closeModalRef.current?.click();
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setSubmitting(false)
                setModalBtnLoading(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <button type="button" className="btn btn-primary mt-3" data-bs-toggle="modal" data-bs-target="#add-conference-modal" disabled={totalRooms >= limitRooms ? true : false}>
                <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'FORM.LABEL.ROOM' })}
            </button>
            <div className="modal fade" tabIndex={-1} id="add-conference-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'FORM.LABEL.ROOM' })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.ROOM_CODE' })}</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text px-1" id="basic-addon1">{user.data.company_prefix}-</span>
                                    <input
                                        placeholder={intl.formatMessage({ id: 'FORM.LABEL.ROOM_CODE' })}
                                        {...formik.getFieldProps('code')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.code && formik.errors.code },
                                        )}
                                        type='text'
                                        onInput={(event) => {
                                            handleCaps(event)
                                            event.currentTarget.value = event.currentTarget.value.replace(/[^a-zA-Z0-9_-]/g, '');
                                        }}
                                        name='code'
                                        autoComplete='off'
                                    />
                                </div>
                                {formik.touched.code && formik.errors.code && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.code}</span>
                                    </div>
                                )}
                                <small>*{intl.formatMessage({ id: 'FORM.LABEL.ROOM_CODE_CANNOT_BE_CHANGED' })}</small><br />

                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.TYPE' })}</label>
                                <div className="input-group mb-3">
                                    <Select
                                        options={optionType}
                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.TYPE" })}`}
                                        value={optionType.find(option => option.value === formik.values.type)}
                                        onChange={
                                            option => formik.handleChange({
                                                target: {
                                                    name: "type",
                                                    value: option?.value
                                                }
                                            })
                                        }
                                        onBlur={
                                            () => formik.values.type === "" && formik.setFieldTouched("type", true)
                                        }
                                        className="w-100"
                                    />
                                </div>
                                {formik.touched.type && formik.errors.type && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.type}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { AddConference }