import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Webcam from "react-webcam";

interface Props {
    keyString: string;
    img: any;
    setImg: any;
    func: any;
    modalBtnLoading: boolean;
}

const CapturePhoto: FC<Props> = ({ keyString, img, setImg, func, modalBtnLoading }) => {
    const webcamRef = useRef<any>(null);
    const intl = useIntl();
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImg(imageSrc);
    }, [webcamRef]);

    useEffect(() => {
        if (keyString === "visit") {
            func();
        }
    }, [img, keyString]);

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                const deviceList = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = deviceList.filter(device => device.kind === "videoinput");
                setDevices(videoDevices);
                if (videoDevices.length > 0) {
                    setSelectedDeviceId(videoDevices[0].deviceId); // Set default to the first device
                }
            } catch (err) {
                console.error("Error fetching devices: ", err);
            }
        };

        fetchDevices();
    }, []);

    const videoConstraints = {
        width: 350,
        height: 350,
        facingMode: "user",
        deviceId: selectedDeviceId, // Set the selected device ID
    };

    return (
        <>
            {keyString === "attendance" ? (
                img === null ? (
                    <div className="d-flex flex-column w-100 align-items-center">
                        <Webcam
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            audio={false}
                            height={250}
                            width={250}
                            ref={webcamRef}
                            mirrored={true}
                        />

                        {
                            devices.length > 1 ?
                                <div className="d-flex align-items-center gap-3">
                                    <select
                                        value={selectedDeviceId}
                                        onChange={(e) => setSelectedDeviceId(e.target.value)}
                                        className="form-select form-select-sm mt-3"
                                    >
                                        {devices.map((device) => (
                                            <option key={device.deviceId} value={device.deviceId}>
                                                {device.label || `Camera ${device.deviceId}`}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        onClick={capture}
                                        className="btn btn-primary w-50 btn-sm mt-3"
                                    >
                                        {intl.formatMessage({ id: "FORM.ACTION.TAKE_PHOTO" })}
                                    </button>
                                </div>
                                :
                                <button
                                    onClick={capture}
                                    className="btn btn-primary btn-sm mt-3"
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.TAKE_PHOTO" })}
                                </button>
                        }
                    </div>
                ) : (
                    <div className="d-flex flex-column w-100 align-items-center">
                        <img src={img} alt="screenshot" />
                        <div className="d-flex gap-3 w-100 align-items-center mt-3 justify-content-center">
                            <button
                                onClick={() => setImg(null)}
                                className="btn btn-secondary btn-sm"
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.RETAKE_PHOTO" })}
                            </button>
                            <button
                                onClick={func}
                                className={`btn btn-primary btn-sm ${modalBtnLoading ? "disabled" : ""}`}
                                data-kt-indicator={modalBtnLoading ? "on" : "off"}
                            >
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                )
            ) : (
                img === null ? (
                    <div className="d-flex flex-column w-100 align-items-center">
                        <Webcam
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            audio={false}
                            height={250}
                            width={250}
                            ref={webcamRef}
                            mirrored={true}
                        />

                        {
                            devices.length > 1 ?
                                <div className="d-flex align-items-center gap-3">
                                    <select
                                        value={selectedDeviceId}
                                        onChange={(e) => setSelectedDeviceId(e.target.value)}
                                        className="form-select form-select-sm mt-3"
                                    >
                                        {devices.map((device) => (
                                            <option key={device.deviceId} value={device.deviceId}>
                                                {device.label || `Camera ${device.deviceId}`}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        onClick={capture}
                                        className="btn btn-primary w-50 btn-sm mt-3"
                                    >
                                        {intl.formatMessage({ id: "FORM.ACTION.TAKE_PHOTO" })}
                                    </button>
                                </div>
                                :
                                <button
                                    onClick={capture}
                                    className="btn btn-primary btn-sm mt-3"
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.TAKE_PHOTO" })}
                                </button>
                        }
                    </div>
                ) : (
                    <div className="d-flex flex-column w-100 align-items-center">
                        <img src={img} alt="screenshot" />
                        <div className="d-flex gap-3 w-100 align-items-center mt-3 justify-content-center">
                            <button
                                onClick={() => setImg(null)}
                                className="btn btn-secondary btn-sm"
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.RETAKE_PHOTO" })}
                            </button>
                        </div>
                    </div>
                )
            )}
        </>
    );
};

export { CapturePhoto };
