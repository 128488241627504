import clsx from 'clsx';
import React, { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { getFileType, getNameUrl, isValidTypeAttachment } from '../../functions/general';
import { toAbsoluteUrl } from '../../_metronic/helpers';

interface Props {
    keyString: string
    formik: any
    accept: string
    isMulti: boolean
    type: string
    isShowPreview?: boolean
    typeCustomField?: "terminLog" | "default"
}

const InputAttachmentGeneral: FC<Props> = ({ keyString, formik, accept, isMulti, type, isShowPreview = true, typeCustomField = "default" }) => {
    const refArray = useRef<(HTMLInputElement | null)[]>([]);
    const intl = useIntl()
    const typeImage = ['png', 'jpg', 'jpeg']
    const typeFile = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']
    const typeImageFile = ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']

    const getValue = () => {
        if (typeCustomField === "terminLog") {
            return formik.values.terminForm?.customFields?.[keyString]
        } else if (typeCustomField === 'default') {
            return formik.values.customFields?.[keyString]
        } else {
            return formik.values[keyString as keyof typeof formik.values]
        }
    }

    const setValue = (value: any) => {
        if (typeCustomField === "terminLog") {
            formik.setFieldValue(`terminForm.customFields.${keyString}`, value)
        } else if (typeCustomField === 'default') {
            formik.setFieldValue(`customFields.${keyString}`, value)
        } else {
            formik.setFieldValue(keyString, value)
        }
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>, keyString: string) {
        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

        if (e.target.files && e.target.files.length > 0) {
            if (isMulti) {
                var multiAttachment = []

                for (let i = 0; i < e.target.files.length; i++) {
                    const file = e.target.files[i];

                    if (!isValidTypeAttachment(file.name, typeFile) && accept.includes('pdf') && !accept.includes('image')) {
                        Swal.fire({
                            icon: "error",
                            title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_FILE_NOT_VALID' })}`,
                            heightAuto: false,
                            confirmButtonText: 'Ok'
                        })

                        return false
                    } else if (!isValidTypeAttachment(file.name, typeImage) && accept.includes('image') && !accept.includes('pdf')) {
                        Swal.fire({
                            icon: "error",
                            title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_IMAGE_NOT_VALID' })}`,
                            heightAuto: false,
                            confirmButtonText: 'Ok'
                        })

                        return false
                    } else if (!isValidTypeAttachment(file.name, typeImageFile)) {
                        Swal.fire({
                            icon: "error",
                            title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_FILE_NOT_VALID' })}`,
                            heightAuto: false,
                            confirmButtonText: 'Ok'
                        })
                    } else if (file.size > MAX_FILE_SIZE) {
                        Swal.fire({
                            icon: "error",
                            title: `${intl.formatMessage({ id: 'FORM.VALIDATION.MAX_FILE_SIZE_5MB' })}`,
                            heightAuto: false,
                            confirmButtonText: 'Ok'
                        })

                        return false
                    } else {
                        multiAttachment.push(file)
                    }
                }


                const currentValue = getValue();
                setValue([...(currentValue || []), ...multiAttachment]);
            } else {
                const file = e.target.files[0];
                if (!isValidTypeAttachment(file.name, typeFile) && accept.includes('pdf') && !accept.includes('image')) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_FILE_NOT_VALID' })}`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })

                    return false
                } else if (!isValidTypeAttachment(file.name, typeImage) && accept.includes('image') && !accept.includes('pdf')) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_FILE_NOT_VALID' })}`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })
                } else if (!isValidTypeAttachment(file.name, typeImageFile)) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_FILE_NOT_VALID' })}`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })
                } else if (file.size > MAX_FILE_SIZE) {
                    formik.setFieldValue(keyString, null);

                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.MAX_FILE_SIZE_5MB' })}`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })

                    return false
                } else if (typeCustomField === 'default') {
                    formik.setFieldValue(`customFields.${keyString}`, file)
                } else {
                    formik.setFieldValue(`terminForm.customFields.${keyString}`, file)
                }
            }
        }
    }

    function deleteFile(indexDelete: number) {
        if (isMulti) {
            if (indexDelete !== -1) {
                const currentValue = getValue();
                const filteredFile = currentValue.filter((file: File | string, index: number) => index !== indexDelete);
                setValue(filteredFile.length === 0 ? null : filteredFile);
            } else {
                setValue(null);
            }
        } else {
            setValue(null);
        }

        const ref = refArray.current[keyString as any];
        if (ref && ref.value) {
            ref.value = "";
        }
    }

    const currentValue = getValue();

    return (
        <>
            {currentValue && currentValue.length > 0 && isMulti && isShowPreview && (
                <div className="d-flex flex-wrap gap-5">
                    {currentValue.map((file: File | string, index: number) => {
                        if (isValidTypeAttachment(typeof file === "string" ? file : file.name, typeFile)) {
                            return (
                                <div className="position-relative">
                                    {
                                        getFileType(typeof file === "string" ? file : file.name) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                            : getFileType(typeof file === "string" ? file : file.name) === "doc" || getFileType(typeof file === "string" ? file : file.name) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                : getFileType(typeof file === "string" ? file : file.name) === "ppt" || getFileType(typeof file === "string" ? file : file.name) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                    : getFileType(typeof file === "string" ? file : file.name) === "xlsx" || getFileType(typeof file === "string" ? file : file.name) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                        : <img src={toAbsoluteUrl('/media/logos/file.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                    }
                                    <div className="position-absolute translate-middle badge badge-sm badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(index)}><i className="fas fa-times text-white"></i></div>
                                </div>
                            )
                        } else {
                            return (
                                <div className="position-relative">
                                    <img src={typeof file === "string" ? file : URL.createObjectURL(file)} className="rounded mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                    <div className="position-absolute translate-middle badge badge-sm badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(index)}><i className="fas fa-times text-white"></i></div>
                                </div>
                            )
                        }
                    })}
                </div>
            )}

            {currentValue && !isMulti && isShowPreview && (
                <div className="d-flex flex-wrap gap-5">
                    {
                        isValidTypeAttachment(typeof getValue() === "string" ? getValue() : getValue().name, typeFile) ?
                            <div className="position-relative">
                                {
                                    getFileType(typeof getValue() === "string" ? getValue() : getValue().name) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} className="mb-2" alt={typeof getValue() === "string" ? getValue() : getValue().name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                        : getFileType(typeof getValue() === "string" ? getValue() : getValue().name) === "doc" || getFileType(typeof getValue() === "string" ? getValue() : getValue().name) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} className="mb-2" alt={typeof getValue() === "string" ? getValue() : getValue().name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                            : getFileType(typeof getValue() === "string" ? getValue() : getValue().name) === "ppt" || getFileType(typeof getValue() === "string" ? getValue() : getValue().name) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} className="mb-2" alt={typeof getValue() === "string" ? getValue() : getValue().name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                : getFileType(typeof getValue() === "string" ? getValue() : getValue().name) === "xlsx" || getFileType(typeof getValue() === "string" ? getValue() : getValue().name) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} className="mb-2" alt={typeof getValue() === "string" ? getValue() : getValue().name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                    : <img src={toAbsoluteUrl('/media/logos/dataFileAddLog.png')} className="mb-2" alt={typeof getValue() === "string" ? getValue() : getValue().name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                }
                                <div className="position-absolute translate-middle badge badge-sm badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(-1)}><i className="fas fa-times text-white"></i></div>
                            </div>
                            :
                            <div className="position-relative">
                                <img src={typeof getValue() === "string" ? getValue() : URL.createObjectURL(getValue())} className="rounded mb-2" alt={typeof getValue() === "string" ? getValue() : getValue().name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                <div className="position-absolute translate-middle badge badge-sm badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(-1)}><i className="fas fa-times text-white"></i></div>
                            </div>
                    }
                </div>
            )}

            <div className="d-flex">
                <input
                    multiple={isMulti}
                    id={`fileInput-${type}-${keyString}`}
                    type="file"
                    ref={ref => {
                        refArray.current[keyString as any] = ref; // took this from your guide's example.
                    }}
                    accept={accept}
                    style={{ zIndex: 0 }}
                    onChange={(e) => handleChange(e, keyString)}
                    onBlur={() => formik.setFieldTouched(keyString, true)}
                    className={clsx(
                        'form-control form-control-lg d-none',
                        { 'is-invalid': formik.touched[keyString as keyof typeof formik.touched] && formik.errors[keyString as keyof typeof formik.errors] },
                    )}
                />

                <div className="input-group">
                    <label className="input-group-text cursor-pointer" htmlFor={`fileInput-${type}-${keyString}`}>
                        {intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })}
                    </label>
                    <label
                        htmlFor={`fileInput-${type}-${keyString}`}
                        id={`fileInputLabel-${type}-${keyString}`}
                        className={clsx(
                            'form-control form-control-lg',
                            { 'is-invalid': formik.touched[keyString as keyof typeof formik.touched] && formik.errors[keyString as keyof typeof formik.errors] },
                            'align-items-center'
                        )}
                        style={{ cursor: 'pointer', textOverflow: "ellipsis", overflow: "hidden", display: "inline-block", whiteSpace: "nowrap", width: "1px" }}
                    >
                        {
                            !currentValue ? intl.formatMessage({ id: "FORM.LABEL.DATA_NOT_FOUND" }) :
                                typeof currentValue === 'string' ? getNameUrl(currentValue) :
                                    isMulti ? `${currentValue.length} data` :
                                        currentValue.name
                        }
                    </label>
                </div>


                {currentValue && (
                    <button
                        type="button"
                        className="btn btn-light-danger ms-3 btn-sm"
                        onClick={() => deleteFile(-1)}
                    >
                        {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                    </button>
                )}
            </div >
        </>
    );
};

export default InputAttachmentGeneral;
