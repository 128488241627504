import { FC, useEffect, useState } from "react";
import { Message } from "../../../interfaces/Chat/Message";
import { useChatStore } from "../../../stores/ChatStore";
import useUser from "../../../hooks/User";

interface ChatReplyProps {
    message: Message
    scrollToChat: (index: number) => void
}

const ChatReply: FC<ChatReplyProps> = ({ message, scrollToChat }) => {

    const user = useUser()
    const { persistedMessages, phoneNumber, messages } = useChatStore()

    const referenceMessage = persistedMessages[phoneNumber] ? persistedMessages[phoneNumber].messages.find(persistedMessage => persistedMessage.id === message.id_reference) : undefined

    const handleReplyClick = () => {
        const index = messages.findIndex(originalMessage => originalMessage.id === message.id_reference);

        if (index !== -1) {
            scrollToChat(index)
        }
    }

    return (
        <div onClick={handleReplyClick} className="cursor-pointer">
            {
                message.id_reference && referenceMessage &&
                <div className="chat-reply bg-secondary p-3 rounded d-flex justify-content-between overflow-auto">
                    <div id='reply-content'>
                        <p id='reply-header' className='fw-bolder'>
                            {
                                persistedMessages[phoneNumber] &&
                                referenceMessage.phone !== user.data.chat_phone &&
                                <span className="text-success">
                                    {referenceMessage.name}
                                </span>
                            }
                            {
                                persistedMessages[phoneNumber] &&
                                referenceMessage.phone === user.data.chat_phone &&
                                <span className="text-primary">
                                    You
                                </span>
                            }
                        </p>
                        <p id='reply-text text-primary'>
                            {
                                (
                                    referenceMessage.attachment_name!.endsWith('.jpg') ||
                                    referenceMessage.attachment_name!.endsWith('.png') ||
                                    referenceMessage.attachment_name!.endsWith('.jpeg')
                                ) ?
                                    <span>
                                        <i className="fas fa-image me-1" />
                                        {!referenceMessage.text && referenceMessage.attachment_name}
                                    </span>
                                    :
                                    (
                                        referenceMessage.attachment_name!.endsWith('.mp4') ||
                                        referenceMessage.attachment_name!.endsWith('.mov')) ?
                                        <span>
                                            <i className="fas fa-video me-1" />
                                            {!referenceMessage.text && referenceMessage.attachment_name}
                                        </span>
                                        :
                                        referenceMessage.attachment_name &&
                                        <span>
                                            <i className="fas fa-file me-1" />
                                            {!referenceMessage.text && referenceMessage.attachment_name}
                                        </span>
                            }
                            {
                                persistedMessages[phoneNumber] &&
                                referenceMessage.text
                            }
                        </p>
                    </div>
                </div>
            }
        </div>
    )
}

export { ChatReply }