import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../_metronic/helpers";
import { ListProduct, PipelineProductLog } from "../../../interfaces/Pipeline";
import { MasterDataTable } from "../../DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import moment from "moment";
import { customNumberFormat } from "../../../functions/general";

interface LeadProductLogProps {
    product: ListProduct;
}

const LeadProductLog: FC<LeadProductLogProps> = ({ product }) => {
    const intl = useIntl()
    const [loading, setLoading] = useState<boolean>(true);
    const tableColumns: TableColumn<PipelineProductLog>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_CREATED' }),
            selector: row => row.dateCreated,
            sortable: true,
            cell: row => moment(row.dateCreated).format('DD MMMM YYYY HH:mm')
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
            selector: row => row.userCreatorName,
            sortable: true,
            cell: row => row.userCreatorName
        },
        {
            name: "HPP",
            selector: row => row.hpp ?? 0,
            sortable: true,
            cell: row => row.hpp || row.hpp == 0 ? customNumberFormat(row.hpp) : ""
        }
    ];

    return (
        <div className="modal fade" tabIndex={-1} id={`log-hpp-modal-${product.leadProductId}`}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.MENU.PRODUCT_HPP_LOG' })} {product.leadProductName}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <MasterDataTable
                            tableKey={`lead-product-logs`}
                            tableColumns={tableColumns}
                            apiURL={"lead-product-logs"}
                            user_id={product.leadProductId}
                            orderBy={1}
                            order="desc"
                            setLoading={setLoading}
                        />
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                        >
                            {intl.formatMessage({ id: 'FORM.ACTION.CLOSE' })}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { LeadProductLog }