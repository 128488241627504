import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import moment from "moment";
import { Reimburse } from "../../interfaces/Reimbursement";
import { Modal } from "bootstrap";
import { customNumberFormat, isColorDarkFromHex } from "../../functions/general";
import { ReimburseAttachments } from "./ReimburseAttachements";
import { useIntl } from "react-intl";
import { SelectOption } from "../../interfaces/SelectOption";
import { useSettingsStore } from "../../stores/SettingsStore";

interface Props {
    data: Reimburse
    setShowDetailMeetingModal: React.Dispatch<React.SetStateAction<boolean>>
    setShowDetailMerchantModal: React.Dispatch<React.SetStateAction<boolean>>
}

const DetailReimbursement: FC<Props> = ({ data, setShowDetailMeetingModal, setShowDetailMerchantModal }) => {
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);
    const [fileAttachment, setFileAttachment] = useState<string>('');
    const [reimburse, setReimburse] = useState<Reimburse>();
    const [reimburseType, setReimburseType] = useState<string>('');
    const intl = useIntl()
    const { settings } = useSettingsStore()

    const checkStatus = (status: string) => {
        switch (status) {
            case 'REJECTED':
                return <span className="badge badge-light-danger">{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.REJECTED' })}</span>
            case 'APPROVED':
                return <span className="badge badge-light-success">{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.APPROVED' })}</span>
            case 'PAID_OFF':
                return <span className="badge badge-light-primary">{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.PAID_OFF' })}</span>
            default:
                return <span className="badge badge-light-warning">{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.PENDING' })}</span>
        }
    }

    const handleClickDetail = (type: string) => {
        closeModalRef.current?.click()
        if(type === 'MEETING') setShowDetailMeetingModal(true)
        else if(type === 'MERCHANT') setShowDetailMerchantModal(true)
    }

    useEffect(() => {
        if (showAttachmentModal && reimburse) {
            const modalElement = document.getElementById(`attachments-reimburse-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                const handleModalHide = () => {
                    setShowAttachmentModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAttachmentModal, reimburse, setShowAttachmentModal]);

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`detail-reimbursement-modal-${data.id}`}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Detail reimbursement {data.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            {/* <img src={image} alt={image} className="img-fluid" /> */}
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                                    <div className="input-group mb-4">{data.name}</div>
                                    <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.CATEGORY' })}</label>
                                    <div className="input-group mb-4">
                                        {
                                            data.reimbursementCategory.color ?
                                                <span className="badge" style={{ backgroundColor: data.reimbursementCategory.color, color: isColorDarkFromHex(data.reimbursementCategory.color) ? "white" : "black" }}>{data.reimbursementCategory.name}</span>
                                                :
                                                <span className="badge badge-light-info" >{data.reimbursementCategory.name}</span>
                                        }
                                    </div>
                                    <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.STATUS' })}</label>
                                    <div className="input-group mb-4">{checkStatus(data.approvalStatus)}</div>
                                    <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.EXPENSE_NOMINAL' })}</label>
                                    <div className="input-group mb-4">{data.companyCurrency.symbol ? (data.companyCurrency.symbol + ' ') : ''}{data.value && customNumberFormat(data.value)}</div>
                                    <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.DESCRIPTION' })}</label>
                                    <div className="input-group mb-4">{data.description}</div>
                                    <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.DATE_SUBMITTED' })}</label>
                                    <div className="input-group mb-4">{moment(data.dateCreated).format('DD MMMM YYYY')}</div>
                                    <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.EXPENSE_DATE' })}</label>
                                    <div className="input-group mb-4">{moment(data.dateSpend).format('DD MMMM YYYY')}</div>
                                    {data.dateApproved &&
                                        <>
                                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.DATE_APPROVED' })}</label>
                                            <div className="input-group mb-4">{moment(data.dateApproved).format('DD MMMM YYYY')}</div>
                                        </>
                                    }
                                    {data.dateRejected &&
                                        <>
                                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.DATE_REJECTED' })}</label>
                                            <div className="input-group mb-4">{moment(data.dateRejected).format('DD MMMM YYYY')}</div>
                                        </>
                                    }
                                    {data.rejectedReason &&
                                        <>
                                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.REASON_REJECTED' })}</label>
                                            <div className="input-group mb-4">{data.rejectedReason}</div>
                                        </>
                                    }
                                    {data.datePaidOff &&
                                        <>
                                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.DATE_PAID_OFF' })}</label>
                                            <div className="input-group mb-4">{moment(data.datePaidOff).format('DD MMMM YYYY')}</div>
                                        </>
                                    }
                                    {
                                        data.reference.id !== null &&
                                        <>
                                            <label className='form-label fs-6 fw-bolder text-dark'>{data.reference.type === 'MEETING' ? settings.meeting_title : settings.merchant_title}</label>
                                            <div className="cursor-pointer input-group mb-4 d-flex align-items-center gap-1 text-primary" onClick={() => handleClickDetail(data.reference.type)}>
                                                {data.reference.type === 'MEETING' ? data.reference.meeting.merchant_name + ' - ' + moment(data.reference.meeting.date_meet).format('DD MMMM YYYY') : data.reference.merchant.name}
                                                <KTSVG path="/media/icons/duotune/arrows/arr095.svg" className='svg-icon-5 svg-icon-primary' />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="col-12 col-md-6">
                                    {data.attachmentsUrl && data.attachmentsUrl.length > 0 && (
                                        <>
                                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.PROOF_OF_EXPENSE' })}</label>
                                            <div className="d-flex flex-wrap gap-5 mb-4">
                                                {data.attachmentsUrl.map((file: string) => {
                                                    return (
                                                        <div className="">
                                                            <img src={file} className="rounded border border-gray-400" alt="bukti" style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }} onClick={
                                                                () => {
                                                                    setShowAttachmentModal(true);
                                                                    setFileAttachment(file);
                                                                    setReimburse(data);
                                                                    setReimburseType('Pengeluaran');
                                                                }
                                                            } />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    )}
                                    {data.paidOffAttachmentUrls && data.paidOffAttachmentUrls.length > 0 && (
                                        <>
                                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.PROOF_OF_PAYMENT' })}</label>
                                            <div className="d-flex flex-wrap gap-5 mb-4">
                                                {data.paidOffAttachmentUrls.map((file: string) => {
                                                    return (
                                                        <div className="">
                                                            <img src={file} className="rounded border border-gray-400" alt="bukti" style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }} onClick={
                                                                () => {
                                                                    setShowAttachmentModal(true);
                                                                    setFileAttachment(file);
                                                                    setReimburse(data);
                                                                    setReimburseType('Pelunasan');
                                                                }
                                                            } />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: 'FORM.ACTION.CLOSE' })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {
                showAttachmentModal && <ReimburseAttachments reimburse={reimburse!} image={fileAttachment!} reimburseType={reimburseType!} />
            }
        </>
    )
}

export { DetailReimbursement }