import React, { FC, useMemo, useState } from "react";
import { CardPipeline } from "../../../components/Pipelines/CardPipeline";
import { LeadProgress } from "../../../interfaces/Progress";
import { Pipeline } from "../../../interfaces/Pipeline";
import { horizontalListSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { KTSVG } from "../../../_metronic/helpers";
import { useProgressStore } from "../../../stores/ProgressStore";
import { Tooltip } from 'react-tippy';
import { useIntl } from "react-intl";
import { tailspin } from 'ldrs'
import { toRupiah } from "../../../functions/general";

interface MasterBoardPipelinesContentProps {
    id: number;
    dataProgress: LeadProgress;
    data: Pipeline[];
    setShowUpdateModal: (value: boolean) => void;
    setShowDeleteModal: (value: boolean) => void;
    setShowAddLogModal: (value: boolean) => void;
    setShowDetailProductModal: (value: boolean) => void;
    setPipeline: (value: Pipeline) => void;
}

const MasterBoardPipelinesContent: FC<MasterBoardPipelinesContentProps> =
    React.memo(
        ({
            id,
            dataProgress,
            data,
            setShowUpdateModal,
            setShowDeleteModal,
            setShowAddLogModal,
            setShowDetailProductModal,
            setPipeline,
        }) => {

            tailspin.register()

            const intl = useIntl()
            const { progress, setProgress } = useProgressStore()
            const [isHovered, setIsHovered] = useState(false);
            const [isLoading, setIsLoading] = useState(false);
            const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
                useSortable({
                    id,
                    transition: {
                        duration: 300, // Durasi yang lebih lama
                        easing: "cubic-bezier(0.25, 0.1, 0.25, 1)", // Easing yang lebih natural
                    },
                });

            const totalNominalPerProgress = data.reduce((acc,card)=>{return acc+=card.nominal},0)
            var style = {};
            if (isDragging) {
                style = {
                    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
                    transition,
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                    zIndex: 1,
                }
            } else {
                style = {
                    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
                    transition,
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                }
            }

            const sortedData = useMemo(
                () => data.sort((a, b) => dataProgress.sort === "desc" ? b.lead_age - a.lead_age : a.lead_age - b.lead_age),
                [data, dataProgress]
            );

            const handleSort = async(typeSort: string) => {
                setIsLoading(true)
                try {
                    await new Promise(resolve => setTimeout(resolve, 500)); 

                    var sortProgress = progress.map(item => {
                        if (item.id === dataProgress.id) {
                            return {
                                ...item,
                                sort: typeSort
                            };
                        }

                        return item;
                    });

                    setProgress(sortProgress);
                } finally {
                    setIsLoading(false)
                }
            }

            return (
                <div ref={setNodeRef} style={style} className="list position-relative z-2" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <div className="d-flex justify-content-between align-items-center" style={{ margin: "7px 0" }} >
                        <label className="list-title">
                            <span className="bullet bullet-dot h-10px w-10px me-3" style={{ backgroundColor: dataProgress.color ? dataProgress.color : "#7239EA" }} ></span>
                            {dataProgress.name}
                            <div className="btn btn-sm btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px ms-1">
                                {
                                    !isLoading ?
                                        dataProgress.sort === "desc" ?
                                            <i className="fas fa-sort-amount-up" onClick={() => handleSort("asc")}></i>
                                            :
                                            <i className="fas fa-sort-amount-down-alt" onClick={() => handleSort("desc")}></i>
                                        :
                                        <l-tailspin
                                            size="15"
                                            stroke="3"
                                            speed="0.9"
                                            color="#a1a5b7"
                                        ></l-tailspin>
                                }
                            </div>
                        </label>
                        <div className="d-flex align-items-center">
                            <label className={`list-count ${isHovered ? "me-1" : "me-3"}`}>{data.length}</label>
                            <Tooltip
                                followCursor={true}
                                title={`${intl.formatMessage({ id: 'FORM.ACTION.HOLD_AND_DRAG_TO_MOVE' })}`}
                                theme="light"
                                arrow={true}
                                className={`${isHovered ? "d-block" : "d-none"}`}
                                style={{ cursor: "default", display: "flex" }}
                            >
                                <div className="btn btn-sm btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px" {...attributes} {...listeners} >
                                    <i className="fas fa-grip-vertical"></i>
                                </div>
                            </Tooltip>

                        </div>
                    </div>
                    <ul className="list-items">
                        {sortedData.map((dataPipeline: Pipeline) => (
                            <CardPipeline
                                key={dataPipeline.id}
                                data={dataPipeline}
                                setShowUpdateModal={setShowUpdateModal}
                                setShowDeleteModal={setShowDeleteModal}
                                setShowAddLogModal={setShowAddLogModal}
                                setShowDetailProductModal={setShowDetailProductModal}
                                setPipeline={setPipeline}
                            />
                        ))}
                    </ul>
                    <div className="h-25 d-flex justify-content-center py-2 list-title" >
                        <div className=" ">
                        Total : {toRupiah(Math.round(totalNominalPerProgress))}
                        </div>
                    </div>
                </div>
            );
        }
    );

export { MasterBoardPipelinesContent };