import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const USER = `${API_URL}user/`;
const USERCHECK = `${API_URL}user-check-available-username/`;
const USER_SESSION = `${API_URL}user-sessions`;
const DELETE_USER_SESSION = `${API_URL}user-session/`;

type InsertUser = {
  name: string;
  username: string;
  password: string;
  phone: string;
  leaderId?: string;
  cityId: string;
  dateJoined: string;
  roleId: string;
  token: string;
  nik?: string;
  attendanceTemplateId?: string;
  customFields?: { [key: string]: string | null };
};

type UpdateUser = {
  id: string;
  name: string;
  nik?: string;
  cityId: string;
  roleId: string;
  leaderId?: string;
  token: string;
  attendanceTemplateId?: string;
  customFields?: { [key: string]: string | null };
};

export const insertUser = async ({
  name,
  username,
  password,
  phone,
  leaderId,
  cityId,
  dateJoined,
  roleId,
  token,
  nik,
  attendanceTemplateId,
  customFields
}: InsertUser) => {
  return axios.post(
    USER,
    {
      name,
      username,
      password,
      nik,
      phone,
      leaderId,
      cityId,
      dateJoined,
      roleId,
      attendanceTemplateId,
      customFields
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateUser = async ({
  id,
  name,
  nik,
  leaderId,
  cityId,
  roleId,
  token,
  attendanceTemplateId,
  customFields
}: UpdateUser) => {
  return axios.patch(
    USER + id,
    {
      name,
      nik,
      leaderId,
      cityId,
      roleId,
      attendanceTemplateId,
      customFields
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const deleteUser = async (id: string, token: string) => {
  return axios.delete(USER + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const checkUsername = async (username: string, token: string) => {
  return axios.get(USERCHECK + username, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const userSession = async (token: string) => {
  return axios.get(USER_SESSION, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const deleteUserSession = async (id: string, token: string) => {
  return axios.delete(DELETE_USER_SESSION + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const getUsers = async (token: string, url: string) => {
  return axios.get(url, {
    headers: {
      'X-Auth-token': token,
    },
  });
}