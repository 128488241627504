import { create } from 'zustand'
import { EmailMessage, DetailEmailMessage, ValueSaveDraft } from '../interfaces/Email/EmailMessage'
import { SetStateAction } from 'react'
import { BroadcastEmailTypes } from '../interfaces/Email/BroadCastEmailType';

/*
  const [customVariables, setCustomVariables] = useState<any[]>([])

*/

type EmailState = {
  customVariables: any[]
  setCustomVariables: (customVariables: any[]) => void
  typeEditBroadcast: string
  setTypeEditBroadcast: (typeEditBroadcast: string) => void
  dataEditValuesBroadcast: BroadcastEmailTypes | null
  setDataEditValuesBroadcast: (dataEditValuesBroadcast: BroadcastEmailTypes | null) => void
  dataEditValuesDraftBroadcast: BroadcastEmailTypes | null
  setDataEditValuesDraftBroadcast: (dataEditValuesDraftBroadcast: BroadcastEmailTypes | null) => void
  toggleBroadcast: boolean
  setToggleBroadcast: (toggleBroadcast: boolean) => void
  isDetailActive: boolean
  setIsDetailActive: (isDetailActive: boolean) => void
  idDetailBroadcastEmail: string
  setIdDetailBroadcastEmail: (idDetailBroadcastEmail: string) => void
  idDetailGroupEmail: string
  setIdDetailGroupEmail: (idDetailGroupEmail: string) => void
  tokenEmail: string
  typeEmail: string
  typeMenu: string
  totalFileSize: number
  uidThread?: number
  setUidThread: (uidThread: number) => void
  setTypeMenu: (typeMenu: string) => void
  activeSidebar: {
    inbox: string
    sent: string
    draft: string
    trash: string
    archive: string
    spam: string
    groupEmail: string
    broadcastEmail: string
  }
  setActiveSidebar: (activeSidebar: {
    inbox: string
    sent: string
    draft: string
    trash: string
    archive: string
    spam: string
    groupEmail: string
    broadcastEmail: string
  }) => void
  valueSaveDraft: {
    from: string
    to: string | string[]
    subject: string
    body: string
    bcc?: string
    cc?: string
    files?: File[]
  }
  emails: EmailMessage[]
  filteredEmails: EmailMessage[]
  detailEmail: EmailMessage
  checkedEmailIds: string[]
  mutate: boolean
  valuesFromDraft: DetailEmailMessage | null
  openSendEmail: boolean
  showSideBar: boolean
  threadEmail: DetailEmailMessage
  isThreadOpen: boolean
  setIsthreadOpen: (isThreadOpen: boolean) => void
  setMutate: (mutate: boolean) => void
  setTokenEmail: (tokenEmail: string) => void
  setTypeEmail: (typeEmail: string, page?: number) => void
  setEmails: (emails: EmailMessage[]) => void
  setFilteredEmails: (filteredEmails: EmailMessage[]) => void
  setDetailEmail: (detailEmail: EmailMessage) => void
  setThreadEmail: (threadEmail: DetailEmailMessage) => void
  setCheckedEmailIds: (checkedEmailIds: string[]) => void
  setValuesFromDraft: (valuesFromDraft: DetailEmailMessage) => void
  setOpenSendEmail: (openSendEmail: boolean) => void
  setValuesSaveDraft: (valueSaveDraft: ValueSaveDraft) => void
  setShowSideBar: (callback: (prevState: boolean) => boolean) => void
  setTotalFileSize: React.Dispatch<React.SetStateAction<number>>
  filterText: string
  setFilterText: (filterText: string) => void
  showClearIcon: boolean
  setShowClearIcon: (showClearIcon: boolean) => void
  currentPage: number
  setCurrentPage: (currentPage: number) => void
  currentSubject: string
  setCurrentSubject: (currentSubject: string) => void
}

export const useEmailStore = create<EmailState>((set) => ({
  customVariables: [],
  setCustomVariables: (customVariables) => set({ customVariables }),
  typeEditBroadcast: '',
  setTypeEditBroadcast: (typeEditBroadcast) => set({ typeEditBroadcast }),
  dataEditValuesDraftBroadcast: null,
  setDataEditValuesDraftBroadcast: (dataEditValuesDraftBroadcast) => set({ dataEditValuesDraftBroadcast }),
  dataEditValuesBroadcast: null,
  setDataEditValuesBroadcast: (dataEditValuesBroadcast) => set({ dataEditValuesBroadcast }),
  idDetailBroadcastEmail: '',
  setIdDetailBroadcastEmail: (idDetailBroadcastEmail) => set({ idDetailBroadcastEmail }),
  toggleBroadcast: false,
  setToggleBroadcast: (toggleBroadcast) => set({ toggleBroadcast }),
  isDetailActive: false,
  setIsDetailActive: (isDetailActive) => set({ isDetailActive }),
  idDetailGroupEmail: '',
  setIdDetailGroupEmail: (idDetailGroupEmail) => set({ idDetailGroupEmail }),
  currentSubject: '',
  setCurrentSubject(currentSubject) {
    set({ currentSubject })
  },
  currentPage: 1,
  setCurrentPage(currentPage) {
    set({ currentPage })
  },
  isThreadOpen: false,
  setIsthreadOpen(isThreadOpen) {
    set({ isThreadOpen })
  },
  uidThread: 0,
  setUidThread(uidThread) {
    set({ uidThread })
  },
  threadEmail: {} as DetailEmailMessage,
  setThreadEmail(threadEmail) {
    set({ threadEmail })
  },
  showClearIcon: false,
  setShowClearIcon: (showClearIcon: boolean) => set({ showClearIcon }),
  filterText: '',
  setFilterText: (filterText: string) => set({ filterText }),
  myEmail: localStorage.getItem('email') ?? '',
  tokenEmail: localStorage.getItem('token_email') ?? '',
  typeEmail: '',
  typeMenu: 'inbox',
  valuesFromDraft: {} as DetailEmailMessage,
  openSendEmail: false,
  totalFileSize: 0,
  setTotalFileSize: (action) => set((state) => ({
    totalFileSize: typeof action === 'function' ? (action as (prevState: number) => number)(state.totalFileSize) : action
  })),
  setTypeMenu: (typeMenu) => set({ typeMenu }),
  activeSidebar: {
    inbox: 'active',
    sent: '',
    draft: '',
    trash: '',
    archive: '',
    spam: '',
    groupEmail: '',
    broadcastEmail: '',
  },
  setActiveSidebar: (activeSidebar) => set({ activeSidebar }),
  emails: [],
  filteredEmails: [],
  detailEmail: {} as EmailMessage,
  checkedEmailIds: [],
  mutate: false,
  showSideBar: false,
  valueSaveDraft: {
    from: '',
    to: '',
    subject: '',
    body: '',
    bcc: '',
    cc: '',
  },
  setMutate: (mutate) => set({ mutate }),
  setTokenEmail: (tokenEmail) => {
    set({ tokenEmail })
    localStorage.setItem('token_email', tokenEmail)
  },
  setTypeEmail: (type: string, page?: number) => {
    set({ typeEmail: type });
    if (page) {
      set({ currentPage: page });
    }
  },
  setEmails: (emails) => {
    set({ emails })
  },
  setShowSideBar: (callback: (prevState: boolean) => boolean) => {
    set((state) => ({ showSideBar: callback(state.showSideBar) }))
  },
  setFilteredEmails: (filteredEmails) => {
    set({ filteredEmails })
  },
  setDetailEmail: (detailEmail) => {
    set({ detailEmail })
  },
  setCheckedEmailIds: (checkedEmailIds) => {
    set({ checkedEmailIds })
  },
  setValuesFromDraft: (valuesFromDraft) => {
    set({ valuesFromDraft })
  },
  setOpenSendEmail(openSendEmail) {
    set({ openSendEmail })
  },
  setValuesSaveDraft: (valueSaveDraft) => set({ valueSaveDraft }),
}))

