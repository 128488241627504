import { FC, useRef, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { useDataTableStore } from "../../../stores/DataTableStore";
import Swal from "sweetalert2";
import useAccessToken from "../../../hooks/AccessToken";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { useIntl } from "react-intl";
import { SettingMultiMerchants, SettingMultiPipelines } from "../../../interfaces/Settings";
import { deleteTemplateSettingMerchant, deleteTemplateSettingPipeline } from "../../../api/SettingCRUD";
import { usePipelineStore } from "../../../stores/PipelineStore";
import { useMerchantStore } from "../../../stores/MerchantStore";

interface Props {
    selectTemplate: any
    linkRef: any
}

const DeleteTemplateSettingMerchant: FC<Props> = ({ selectTemplate, linkRef }) => {
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const token = useAccessToken()
    // const { multiPipelines, setMultiPipelines, setSelectMultiPipeline } = usePipelineStore()
    const { multiMerchants,setMultiMerchants, setSelectMultiMerchant } = useMerchantStore()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const { settings } = useSettingsStore()

    const intl = useIntl()

    const handleDeleteMerchant = async () => {
        setModalBtnLoading(true)
        const isActive = document.getElementById(`tab_${selectTemplate.id}`)?.classList.contains("active");

        try {
            const response = await deleteTemplateSettingMerchant(selectTemplate.id, token)
            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                    text: intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" }),
                    heightAuto: false,
                })
                closeModalRef.current?.click();
                setMultiMerchants(multiMerchants.filter((data) => data.id !== selectTemplate.id))
                if (isActive) linkRef.current?.click()
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                heightAuto: false,
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`delete-template-setting-merchant-modal`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })} Template {selectTemplate?.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>{intl.formatMessage({ id: 'FORM.CONFIRMATION' })} {intl.formatMessage({ id: 'FORM.ACTION.DELETE' }).toLowerCase()} template {selectTemplate?.name}?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
                            </button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleDeleteMerchant}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DeleteTemplateSettingMerchant }