import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import { Product } from "../../interfaces/Product";
import { updateProduct } from "../../api/ProductsCRUD";
import useAccessToken from "../../hooks/AccessToken";
import { CompactPicker, SketchPicker } from "react-color";
import { KTSVG } from "../../_metronic/helpers";
import Select from 'react-select'
import { AddLabel } from "../Labels/AddLabel";
import { Modal } from "bootstrap";
import { SelectOption } from "../../interfaces/SelectOption";
import useSWR from "swr";
import { Label } from "../../interfaces/Label";
import axios from "axios";
import { customNumberFormat, customNumberFormatString, handleCaps } from "../../functions/general";
import { useIntl } from "react-intl";
import { getLabel, getLabelProduct } from "../../api/LabelsCRUD";
import { Unit } from "../../interfaces/Unit";
import { getUnit } from "../../api/UnitsCRUD";
import { nanoid } from "@reduxjs/toolkit";
import { AddUnit } from "../Units/AddUnit";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

interface Props {
    product: Product
}

interface UnitOption {
    id: string
    name: string
    qty: number

}

const UpdateProduct: FC<Props> = ({ product }) => {
    const [labelOptions, setLabelOptions] = useState<SelectOption[]>([])
    const [isLoadingLabel, setIsLoadingLabel] = useState(false)
    const [priceProduct, setPriceProduct] = useState('');
    const [selectedLabels, setSelectedLabels] = useState(product.productLabels.map(data => {
        return {
            label: data.label.name,
            value: data.label.id
        }
    }))
    const [showAddLabelModal, setShowAddLabelModal] = useState(false)

    const [unitOptions, setUnitOptions] = useState<SelectOption[]>([])
    const [isLoadingUnit, setIsLoadingUnit] = useState(false)
    const [selectedUnits, setSelectedUnits] = useState<UnitOption[]>(product.productUnits.map(data => {
        return {
            name: data.unit.name,
            id: data.unit.id,
            qty: data.convertionToPieces
        }
    }))
    const [showAddUnitModal, setShowAddUnitModal] = useState(false)


    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()

    const intl = useIntl()

    const formik = useFormik({
        initialValues: {
            name: product.name,
            label: product.productLabels,
            color: product.color ?? "#D33115",
            sku: product.sku,
            note: product.note,
            price: product.price,
            vatPercentage: product.vatPercentage,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            color: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.COLOR" }) })),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            // convert the labels to id and name
            const labels = selectedLabels.map((data: SelectOption) => {
                return {
                    labelId: data.value,
                    labelName: data.label
                }
            })

            const checkQtyUnit = selectedUnits.some(unit => unit.qty === 0);
            if(checkQtyUnit) {
                Swal.fire({
                    icon: 'error',
                    title: intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.QUANTITY_UNIT" }) }),
                    confirmButtonText: 'Ok'
                })
                return
            }
            
            const units = selectedUnits.map((data: UnitOption) => {
                return {
                    unitId: data.id,
                    unitName: data.name,
                    convertionToPieces: data.qty
                }
            })

            setSubmitting(true);
            setModalBtnLoading(true)


            try {
                const response = await updateProduct(product.id, values.name, values.color, token, labels, units, values.sku, values.note, values.price, values.vatPercentage || 0)
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: 'FORM.VALIDATION.UPDATE_SUCCESS' }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    closeModalRef.current?.click();
                    setTablesData('products', (tablesData.products.map(item => {
                        if (item.id === product.id) {
                            return {
                                ...item,
                                name: response.data.data.name,
                                color: response.data.data.color,
                                productLabels: response.data.data.productLabels,
                                productUnits: response.data.data.productUnits,
                                sku: response.data.data.sku,
                                note: response.data.data.note,
                                price: response.data.data.price,
                                vatPercentage: response.data.data.vatPercentage
                            }
                        } else {
                            return item
                        }
                    })));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    useEffect(() => {
        setPriceProduct(product.price ? customNumberFormatString(product.price.toString()) : '');
    }, [product]);

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            setSelectedUnits([])
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const getDataLabel = async () => {
        setIsLoadingLabel(true)
        try {
            const response = await getLabelProduct(token)
            if (response.data.success) {
                setLabelOptions(response.data.data.map((label: Label) => {
                    return {
                        value: label.id,
                        label: label.name
                    }
                }))
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setIsLoadingLabel(false)
        }
    }

    const getDataUnit = async () => {
        setIsLoadingUnit(true)
        try {
            const response = await getUnit(token)
            if (response.data.success) {
                const optionIds = product.productUnits.map(opt => opt.unit.id);
                const result = response.data.data.filter((unit: Unit) => !optionIds.includes(unit.id)).map((unit: Unit) => {
                    return {
                        value: unit.id,
                        label: unit.name
                    }
                });
                setUnitOptions(result);
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setIsLoadingUnit(false)
        }
    }

    function handleInputQtyUnit(index: number, e: React.ChangeEvent<HTMLInputElement>) {
        var value = e.target.value ?? 0
        value = customNumberFormatString(value)
        selectedUnits[index].qty = Number(value.replaceAll(".", ""))
        setSelectedUnits([...selectedUnits])
    }

    const handleDeleteUnit = (key: number, data: UnitOption) => {
        selectedUnits.splice(key, 1);
        setSelectedUnits([...selectedUnits]);

        if (unitOptions.some(opt => opt.value !== data.id)) {
            setUnitOptions([...unitOptions, {
                value: data.id,
                label: data.name
            }])
        }
    }

    useEffect(() => {
        if (showAddLabelModal) {
            const modalElement = document.getElementById(`add-label-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddLabelModal(false);
                    // document.getElementById(`update-product-modal-${product.id}`)?.click()
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddLabelModal, setShowAddLabelModal]);

    useEffect(() => {
        if (showAddUnitModal) {
            const modalElement = document.getElementById(`add-unit-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddUnitModal(false);
                    // document.getElementById(`update-product-modal-${product.id}`)?.click()
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddUnitModal, setShowAddUnitModal]);

    useEffect(() => {
        getDataLabel()
        getDataUnit()
    }, [])

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);
    
    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        const formattedValue = customNumberFormatString(value);

        setPriceProduct(formattedValue);
        formik.setFieldValue('price', Number(value.replaceAll(".","")))
    };
    const handleVATChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        if (!isNaN(Number(value)) && Number(value) > 100) {
            return ;   
        }
        const formattedValue = customNumberFormatString(value);
        formik.setFieldValue("vatPercentage", formattedValue)
    };

    return (
        <>
            <div className={`modal fade ${(!showAddLabelModal && !showAddUnitModal) ? 'show' : 'hide'}`} tabIndex={-1} id={`update-product-modal-${product.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} {product.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                        onInput={handleCaps}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.name}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}</label>
                                <div className="row mb-3">
                                    <Select
                                        isMulti
                                        options={labelOptions}
                                        value={selectedLabels}
                                        placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'MENU.LABELS' })}`}
                                        onChange={
                                            option => {
                                                // set the selected labels to add option in
                                                setSelectedLabels((option || []) as SelectOption[]);
                                                formik.handleChange({
                                                    target: {
                                                        name: "label",
                                                        value: option || ""
                                                    }
                                                })
                                            }
                                        }
                                        className="col-10"
                                        isDisabled={isLoadingLabel}
                                    />
                                    <button type="button" className="btn btn-secondary col-2 btn-sm" onClick={() => {
                                        setShowAddLabelModal(true);
                                    }}>
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.UNITS' })}</label>
                                <div className="row mb-3">
                                    <Select
                                        key={nanoid()}
                                        options={unitOptions}
                                        placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.UNITS' })}`}
                                        onChange={
                                            option => {
                                                setSelectedUnits([...selectedUnits, { id: option?.value!, name: option?.label!, qty: 0 }])
                                                setUnitOptions(unitOptions.filter((unit: SelectOption) => unit.value !== option?.value))
                                            }
                                        }
                                        className="col-10"
                                        isDisabled={isLoadingUnit}
                                    />
                                    <button type="button" className="btn btn-secondary col-2 btn-sm" onClick={() => {
                                        setShowAddUnitModal(true);
                                    }}>
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                                {
                                    selectedUnits.map((data: UnitOption, index: number) => (
                                        <div className="mb-3 d-flex">
                                            <div className="input-group group-sm me-3">
                                                <input type="text" className="form-control" value={customNumberFormat(data.qty) === "0" ? '' : customNumberFormat(data.qty)} onInput={(e) => handleCaps(e)} onChange={(e) => handleInputQtyUnit(index, e)} />
                                                <span className="input-group-text" id="basic-addon2">{data.name}</span>
                                            </div>
                                            <button type="button" className="btn btn-icon btn-light-youtube" onClick={() => handleDeleteUnit(index, data)}>
                                                <i className="fas fa-trash fs-4"></i>
                                            </button>
                                        </div>
                                    ))
                                }
                                <label className='form-label fs-6 fw-bolder text-dark'>SKU</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder='SKU'
                                        {...formik.getFieldProps('sku')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.sku && formik.errors.sku },
                                        )}
                                        type='text'
                                        name='sku'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                 <div className="row">
                                {/* Prive */}
                                    <div className="col">
                                        <label className='form-label fs-6 fw-bolder text-dark' >{intl.formatMessage({ id: "FORM.LABEL.PRICE" })}</label>
                                        <div className="input-group input-group-sm mb-3">
                                            <input
                                                value={priceProduct}
                                                onChange={handlePriceChange}
                                               
                                                className={clsx(
                                                    'form-control form-control-lg',
                                                    // { 'is-invalid': isPriceProduct },
                                                )}
                                                type='text'
                                                autoComplete='off'
                                                style={{ zIndex: 0 }}
                                            />
                                        </div>
                                    
                                    </div>
                                    <div className="col">
                                            <label className='form-label fs-6 fw-bolder text-dark '>
                                                {intl.formatMessage({ id: "FORM.LABEL.VAT" })}
                                            </label>
                                            <div className="input-group input-group-sm">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg"
                                                    value={formik.values.vatPercentage}
                                                    onChange={handleVATChange}
                                                    onBlur={() => formik.setFieldTouched("vatPercentage", true)}
                                                    autoComplete="off"
                                                />
                                                <span className="input-group-text">%</span>
                                            </div>
                                            {formik.touched.vatPercentage && formik.errors.vatPercentage && (
                                                <div className="text-danger">
                                                    {formik.errors.vatPercentage}
                                                </div>
                                            )}
                                    </div>

                                </div>
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.NOTE' })}</label>
                                <div className="input-group mb-3">
                                    <textarea
                                        placeholder={intl.formatMessage({ id: 'FORM.LABEL.NOTE' })}
                                        id="floatingTextarea"
                                        {...formik.getFieldProps('note')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.note && formik.errors.note },
                                        )}
                                        style={{ zIndex: 0 }}
                                        name="note"
                                        maxLength={255}
                                        onInput={(e) => handleCaps(e)}
                                    >
                                    </textarea>
                                </div>
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.COLOR' })}</label>
                                <div className="input-group mb-3">
                                    <SketchPicker
                                        color={formik.values.color}
                                        onChange={color => {
                                            formik.handleChange({
                                                target: {
                                                    name: "color",
                                                    value: color.hex
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                            <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                showAddLabelModal && <AddLabel selectedLables={selectedLabels} setSelectedLabels={setSelectedLabels} />
            }
            {
                showAddUnitModal && <AddUnit selectedUnits={selectedUnits} setSelectedUnits={setSelectedUnits} />
            }
        </>
    )
}

export { UpdateProduct }