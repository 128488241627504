import { useEffect, useState } from "react";
import { LabelChatProps } from "../../../interfaces/Chat/LabelChat";
import { useChatStore } from "../../../stores/ChatStore";
import { Modal } from "bootstrap";
import { AddChatLabel } from "../Modal/AddChatLabel";
import { useIntl } from "react-intl";
import { List } from "rsuite";
import { UpdateChatLabel } from "../Modal/UpdateChatLabel";
import { DeleteChatLabel } from "../Modal/DeleteChatLabel";
import { AssignContactLabel } from "../Modal/AssignContactLabel";
import { RemoveContactLabel } from "../Modal/RemoveContactLabel";

interface Props {
}

const ChatDetailLabelSetting = ({ }: Props) => {
    const intl = useIntl()
    const { setMenuActive, selectChatLabel, filteredRecentChatHistories } = useChatStore()

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showAssignContactLabelModal, setShowAssignContactLabelModal] = useState<boolean>(false);
    const [showRemoveContactLabelModal, setShowRemoveContactLabelModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selected, setSelected] = useState<{ [phone: string]: boolean }>({});


    const getInitialsOrPhonePrefix = (recentChatHistory: { chat_name: string; chat_phone: string }): string => {
        if (recentChatHistory.chat_name) {
            const words = recentChatHistory.chat_name.split(' ');
            const initials = words.length > 1
                ? words.map((word: string) => word[0]).join('')
                : words[0][0];

            return recentChatHistory.chat_name !== recentChatHistory.chat_phone
                ? initials
                : recentChatHistory.chat_phone.substring(0, 2).toUpperCase();
        }
        return recentChatHistory.chat_phone.substring(0, 2).toUpperCase();
    };

    useEffect(() => {
        if (showAddModal) {
            const modalElement = document.getElementById(`add-chat-label-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddModal, setShowAddModal]);

    useEffect(() => {
        if (showAssignContactLabelModal) {
            const modalElement = document.getElementById(`assign-contact-label-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAssignContactLabelModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAssignContactLabelModal, setShowAssignContactLabelModal]);

    useEffect(() => {
        if (showRemoveContactLabelModal) {
            const modalElement = document.getElementById(`remove-contact-label-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowRemoveContactLabelModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showRemoveContactLabelModal, setShowRemoveContactLabelModal]);

    useEffect(() => {
        if (showUpdateModal) {
            const modalElement = document.getElementById(`update-chat-label-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal) {
            const modalElement = document.getElementById(`delete-chat-label-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, setShowDeleteModal]);

    return (
        <div className='card card-flush w-100 p-10' style={{ maxHeight: "calc(100vh - 1rem)", height: "calc(100vh - 1rem)" }}>
            {
                Object.values(selected).some(value => value === true) ?
                    <div className="d-flex align-items-center my-1 mb-10 justify-content-between">
                        <div className="d-flex align-items-center gap-5">
                            <i className="p-3 cursor-pointer fas fa-times fs-3" onClick={() => setSelected({})}></i>
                            <div className="mb-0 text-dark fs-3">{Object.entries(selected).filter(([key, value]) => value === true).length} {intl.formatMessage({ id: "FORM.LABEL.SELECTED_DATA" })}</div>
                        </div>
                        <div>
                            <svg onClick={() => setShowAssignContactLabelModal(true)} viewBox="0 0 24 24" height="24" width="24" className="cursor-pointer me-3" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: "#a1a5b7" }}>
                                <path fill="currentColor" fillRule="evenodd" clip-rule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                            </svg>
                            <svg onClick={() => setShowRemoveContactLabelModal(true)} viewBox="0 0 24 24" height="24" width="24" className="cursor-pointer" preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px" enable-background="new 0 0 24 24" style={{ color: "#a1a5b7" }}>
                                <path fill="currentColor" d="M5.811,17.1c-0.507,0-0.92-0.41-0.92-0.911V8.926L3.094,7.128C3.038,7.347,3,7.573,3,7.81v8.38 c0,1.548,1.262,2.802,2.81,2.802L14.961,19l-1.893-1.894L5.811,17.1z M18.215,17.071l3.131-4.414L21.812,12l-4.127-5.818 C17.167,5.447,16.314,5,15.393,5l-9.24,0.008L3.326,2.181C3.071,1.925,2.657,1.925,2.404,2.178L1.943,2.64 C1.688,2.894,1.688,3.306,1.944,3.562l17.961,17.963c0.255,0.255,0.669,0.255,0.923,0.002l0.461-0.461 c0.254-0.254,0.254-0.666-0.002-0.922L18.215,17.071z M8.042,6.897l7.352-0.006c0.302,0,0.58,0.146,0.748,0.384L19.494,12 l-2.635,3.714L8.042,6.897z"></path>
                            </svg>
                        </div>
                    </div>
                    :
                    <div className="d-flex align-items-center my-1 mb-10 justify-content-between">
                        <div className="d-flex align-items-center gap-5">
                            <i className="p-3 cursor-pointer fas fa-solid fa-arrow-left fs-3" onClick={() => setMenuActive("settings-label")}></i>
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-30px me-3">
                                    <span className="symbol-label" style={{ backgroundColor: selectChatLabel.color }}>
                                        <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: "white" }}>
                                            <path fill="currentColor" fillRule="evenodd" clip-rule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                        </svg>
                                    </span>
                                </div>
                                <h1 className="mb-0 text-dark fw-bolder fs-1">{selectChatLabel.name}</h1>
                            </div>
                        </div>
                        <div className="dropdown-center cursor-pointer dropdown">
                            <span className="p-3 badge" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className={`fas fs-3 fa-ellipsis-v`}></i>
                            </span>
                            <ul className="dropdown-menu">
                                <li onClick={() => { setShowUpdateModal(true) }}>
                                    <div className="dropdown-item cursor-pointer">{intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}</div>
                                </li>
                                <li onClick={() => { setShowDeleteModal(true) }}>
                                    <div className="dropdown-item cursor-pointer">{intl.formatMessage({ id: "FORM.ACTION.DELETE" })}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
            }
            {/* {
                filteredRecentChatHistories.filter(chat => selectChatLabel.roomList.includes(chat.chat_phone)).map((data, index) => (
                    <>
                        <div key={index} className="d-flex align-items-center p-3 rounded-2 bg-hover-light" onClick={() => setSelected(prevSelected => ({ ...prevSelected, [data.chat_phone]: !prevSelected[data.chat_phone] }))}>
                            <div className='symbol symbol-45px symbol-circle'>
                                <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                    {
                                        data.profile_picture_url ?
                                            <img src={data.profile_picture_url} className='symbol symbol-45px symbol-circle img-fluid' alt='profile' />
                                            :
                                            getInitialsOrPhonePrefix(data)
                                    }
                                </span>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <div className="ms-5">
                                    <span className="text-gray-800 fw-bolder fs-6">{data.chat_name}</span>
                                    <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">{data.chat_phone}</span>
                                </div>
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="checkbox" checked={selected[data.chat_phone]} />
                                </div>
                            </div>
                        </div>
                    </>
                ))
            } */}
            {
                selectChatLabel.roomList.map((data, index) => {
                    var profile = filteredRecentChatHistories.find(chat => chat.chat_phone === data) ? filteredRecentChatHistories.find(chat => chat.chat_phone === data)!.profile_picture_url : ''
                    var name = filteredRecentChatHistories.find(chat => chat.chat_phone === data) ? filteredRecentChatHistories.find(chat => chat.chat_phone === data)!.chat_name : data

                    return (
                        <>
                            <div key={index} className="d-flex align-items-center p-3 rounded-2 bg-hover-light" onClick={() => setSelected(prevSelected => ({ ...prevSelected, [data]: !prevSelected[data] }))}>
                                <div className='symbol symbol-45px symbol-circle'>
                                    <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                        {
                                            profile ?
                                                <img src={profile} className='symbol symbol-45px symbol-circle img-fluid' alt='profile' />
                                                :
                                                getInitialsOrPhonePrefix({ chat_name: name, chat_phone: data })
                                        }
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between w-100">
                                    <div className="ms-5">
                                        <span className="text-gray-800 fw-bolder fs-6">{name}</span>
                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">{data}</span>
                                    </div>
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input" type="checkbox" checked={selected[data]} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
            }

            {
                showUpdateModal && <UpdateChatLabel />
            }
            {
                showDeleteModal && <DeleteChatLabel />
            }
            {
                showAssignContactLabelModal && <AssignContactLabel dataSelect={selected} setSelected={setSelected} />
            }
            {
                showRemoveContactLabelModal && <RemoveContactLabel dataSelect={selected} setSelected={setSelected} />
            }
        </div>
    )
}

export { ChatDetailLabelSetting }