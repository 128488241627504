import { FC, ReactNode, useEffect, useState } from "react";
import DataTable, { ExpanderComponentProps, TableColumn } from "react-data-table-component";
import { useDataTableStore } from "../../stores/DataTableStore";
import useSWR from 'swr';
import axios from "axios";
import useAccessToken from "../../hooks/AccessToken";
import { Pipeline } from "../../interfaces/Pipeline";
import moment from "moment";
import 'moment/locale/id';
import { DataTableCard } from "./DataTableCard";
import { getDateDifference, getDateSummary, handleCaps } from "../../functions/general";

interface MasterDataTableNoUrlProps {
    tableKey: string
    tableColumns: TableColumn<any>[]
    actions?: ReactNode[]
    expanded?: boolean
    tableData: any
}

interface Props extends ExpanderComponentProps<Pipeline> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    name?: string;
}

const ExpandableRowComponent: React.FC<Props> = ({ data }) => {

    const dateEnd = data.date_end ? moment(data.date_end).format('DD MMMM YYYY') : '-'
    const paymentAge = data.date_end ? getDateDifference(data.date_end, moment().format('YYYY-MM-DD')) : '-'

    return (
        <>
            <div className="p-10">
                <div className="row">
                    <div className="col-4">
                        <DataTableCard
                            className='card-lg'
                            color='info'
                            icon='fas fa-users'
                            title='Sales'
                            text1={`Sales: ${data.owner_name}`}
                            text2="Presales: -"
                        // text2={`Presales: ${data.source_presales_name ?? '-'}`}
                        />
                    </div>
                    <div className="col-4">
                        <DataTableCard
                            className='card-lg'
                            color='primary'
                            icon='fas fa-clipboard'
                            title='Start'
                            text1={`Tanggal dimulai: ${moment(data.date_start).format('DD MMMM YYYY')}`}
                            text2={`Umur: ${getDateSummary(data.lead_age)}`}
                        />
                    </div>
                    <div className="col-4">
                        <DataTableCard
                            className='card-lg'
                            color='success'
                            icon='fas fa-dollar-sign'
                            title='End'
                            text1={`Tanggal terbayar: ${dateEnd}`}
                            text2={`Umur pembayaran: ${paymentAge}`}
                        />
                    </div>
                </div>
                <p className="mt-5">Catatan: {data.note ?? '-'}</p>
                {
                    data.date_invoice &&
                    <>
                        <p>Tanggal Invoice: {moment(data.date_invoice).format('DD-MM-YYYY')}</p>
                        <p>Tanggal Jatuh Tempo Invoice: {moment(data.date_invoice_due).format('DD-MM-YYYY')}</p>
                    </>
                }
            </div>
        </>
    );
};

const MasterDataTableNoUrl: FC<MasterDataTableNoUrlProps> = ({ tableKey, tableColumns, actions, expanded, tableData }) => {

    const [filterText, setFilterText] = useState('');

    const { tablesData, setTablesData, filteredTablesData, setFilteredTablesData, selectedFilters } = useDataTableStore();

    const isDate = (value: any): value is Date | string => {
        return (value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value))));
    };
    
    useEffect(() => {
        if (tablesData[tableKey]) {
            setFilteredTablesData(tableKey, tablesData[tableKey].filter(item => {
                // Check filterText
                if (filterText) {
                    const filterWords = filterText.split(' ');

                    const isTextInValues = Object.values(item).some(val => {
                        if (isDate(val)) {
                            const formattedDate = moment(val).format('DD MMMM YYYY');
                            return filterWords.every(word => {
                                const regexText = '.*' + word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '.*';
                                const regex = new RegExp(regexText, 'i');
                                return regex.test(formattedDate);
                            });
                        }
                        return filterWords.every(word => {
                            const regexText = '.*' + word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '.*';
                            const regex = new RegExp(regexText, 'i');
                            return regex.test(String(val));
                        });
                    });

                    if (!isTextInValues) {
                        return false;
                    }
                }

                // Check each filter in selectedFilters
                for (const filterKey in selectedFilters[tableKey]) {
                    const filterValues = selectedFilters[tableKey][filterKey];
                    if (filterValues.length > 0) {
                        // If there are selected filter values for this filterKey
                        const itemValue = (item as any)[filterKey];

                        if (!filterValues.includes(itemValue)) {
                            return false; // If the item's value for this filterKey is not in the selected filter values, exclude the item.
                        }
                    }
                }

                return true;  // If all filters match (or are empty), and date is in range (if applicable), include this item.
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterText, tablesData, selectedFilters]);

    useEffect(() => {
       setTablesData(tableKey, tableData)
    }, [tableData])

    return (
        <>
            <div className="card rounded">
                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1 skeleton-button" id="divSearch">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                </svg>
                            </span>
                            <input
                                type="search"
                                data-kt-lists-table-filter="search"
                                className="form-control form-control-solid w-210px ps-15 skeleton"
                                placeholder="Cari"
                                id="inputSearch"
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                                onInput={(e) => handleCaps(e)}
                            />
                        </div>
                    </div>
                    <div className="card-toolbar align-self-center">
                        {
                            actions && actions.map((action, index) => (
                                <div key={index} className="me-5 me-md-3">
                                    {action}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="card-body pt-0">
                    <DataTable
                        columns={tableColumns}
                        data={filteredTablesData[tableKey]}
                        expandableRowsHideExpander
                        expandOnRowClicked
                        expandableRows={expanded}
                        expandableRowsComponent={ExpandableRowComponent}
                        pagination
                    />
                </div>
            </div>
        </>
    )
}

export { MasterDataTableNoUrl }