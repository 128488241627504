
import { Link } from 'react-scroll';
import { IMenuDocumentation, ISubMenuDocumentation } from '../../interfaces/OpenAPI';




export const SubMenuDocumentation =({name, to}: ISubMenuDocumentation)=>{
    return (
        <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
            <Link to={to} spy smooth={true} duration={500}>
            {name}
            </Link>
        </li>
    )
}

export const MenuDocumentation = ({name, subMenus}:IMenuDocumentation)=>{
    return (
        <>
            <div className="mt-10" style={{ color: "black", fontWeight: "300", fontSize: "x-small", textTransform: "uppercase" }}>{name}</div>
            <div className="separator separator-dashed mt-2"></div>
                {subMenus.map((menu)=>{
                    return <SubMenuDocumentation name={menu.name} to={menu.to} />
                })}
        </>
    )
}

