import { Buffer } from "buffer";

// Fungsi untuk encoding Base58
export const base58Encode = (input: string) => {
    const BASE58_ALPHABET: string =
        "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";
    const base: bigint = BigInt(BASE58_ALPHABET.length);
    let result: string = "";

    let inputBigInt: bigint = BigInt("0x" + Buffer.from(input).toString("hex"));

    while (inputBigInt > 0n) {
        const remainder: bigint = inputBigInt % base;
        result = BASE58_ALPHABET[Number(remainder)] + result;
        inputBigInt = inputBigInt / base;
    }

    return result;
}

// Fungsi untuk decoding Base58
export const base58Decode = (input: string) => {
    const BASE58_ALPHABET: string =
        "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";
    const base: bigint = BigInt(BASE58_ALPHABET.length);

    let result: bigint = BigInt(0);
    for (let i = 0; i < input.length; i++) {
        result = result * base + BigInt(BASE58_ALPHABET.indexOf(input[i]));
    }

    return Buffer.from(result.toString(16), "hex");
}

// Fungsi untuk encoding ROT8
export const rot8Encode = (input: string) => {
    let result: string = "";
    for (let i = 0; i < input.length; i++) {
        let charCode: number = input.charCodeAt(i);
        if (charCode >= 65 && charCode <= 90) {
            result += String.fromCharCode(((charCode - 65 + 8) % 26) + 65);
        } else if (charCode >= 97 && charCode <= 122) {
            result += String.fromCharCode(((charCode - 97 + 8) % 26) + 97);
        } else {
            result += input[i];
        }
    }
    return result;
}

// Fungsi untuk decoding ROT8
export const rot8Decode = (input: string) => {
    let result: string = "";
    for (let i = 0; i < input.length; i++) {
        let charCode: number = input.charCodeAt(i);
        if (charCode >= 65 && charCode <= 90) {
            result += String.fromCharCode(((charCode - 65 - 8 + 26) % 26) + 65);
        } else if (charCode >= 97 && charCode <= 122) {
            result += String.fromCharCode(((charCode - 97 - 8 + 26) % 26) + 97);
        } else {
            result += input[i];
        }
    }
    return result;
}

export const base64encrypt = (text: string) => {
    return btoa(unescape(encodeURIComponent(text)));
};

export const encryptRot13 = (str: string) => {
    return str.replace(/[a-zA-Z]/g, function (c) {
        let base = c <= "Z" ? "A".charCodeAt(0) : "a".charCodeAt(0);
        return String.fromCharCode(base + ((c.charCodeAt(0) - base + 13) % 26));
    });
};