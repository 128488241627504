import { FC, RefObject, useEffect, useRef, useState } from "react";
import { Attendes, EventData } from "../../interfaces/EventData";
import moment from "moment";
import { KTSVG } from "../../_metronic/helpers";
import { Modal } from "bootstrap";
import FullCalendar from "@fullcalendar/react";
import useSWR from "swr";
import axios from "axios";
import useAccessToken from "../../hooks/AccessToken";
import { EventImpl } from "@fullcalendar/core/internal";
import Swal from "sweetalert2";
import { updateEvent } from "../../api/EventCRUD";
import { invitationResponse } from "../../api/CalendarCRUD";
import { Message, toaster } from "rsuite";
import { useSettingsStore } from "../../stores/SettingsStore";
import { truncateText } from "../../functions/general";
import { useIntl } from "react-intl";
import { DetailEvent } from "../Schedule/DetailEvent";
import { Conference, DetailScheduleConferenceProps, ScheduleConference } from "../../interfaces/Conference";
import useUser from "../../hooks/User";
import { DeleteScheduleConference } from "./DeleteScheduleConference";
import { UpdateScheduleConference } from "./UpdateScheduleConference";
import { createConference } from "../../api/ConferenceCRUD";
import { Tooltip } from 'react-tippy';
import { useLocation } from "react-router-dom";
import session from "redux-persist/lib/storage/session";

interface eventProps {
    id: string,
    events: any
}

interface Props {
    roomList: Conference[]
    calendarRef: RefObject<FullCalendar>
    eventData: EventImpl
    dataCalendars: ScheduleConference[]
    listEvent: eventProps[]
    selectedCalendars: string[]
    setListEvent: React.Dispatch<React.SetStateAction<eventProps[]>>
    setShowDetailEventModal: React.Dispatch<React.SetStateAction<boolean>>
    setDataCalendars: React.Dispatch<React.SetStateAction<ScheduleConference[]>>
}

const DetailScheduleConference: FC<Props> = ({ roomList, calendarRef, eventData, dataCalendars, listEvent, selectedCalendars, setListEvent, setShowDetailEventModal, setDataCalendars }) => {
    const API_URL = process.env.REACT_APP_API_URL
    const { settings } = useSettingsStore()
    const user = useUser()
    const data = dataCalendars.find(data => data.id === eventData.id)
    const [dateNow, setDateNow] = useState<string>(moment().toISOString());
    const passDate = moment(data?.start).isBefore(dateNow)
    const currentDate = moment(data?.start).isBefore(dateNow) && moment(data?.end).isAfter(dateNow)
    const [isConferenceNotReady, setIsConferenceNotReady] = useState<boolean>(moment(data?.start).isAfter(dateNow));
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [detailEvent, setDetailEvent] = useState<DetailScheduleConferenceProps>();
    const [url, setUrl] = useState(API_URL === 'https://sales-api.looyal.id/' ? 'https://vc-dev.wooblazz.com/' : 'https://vc.wooblazz.com/');
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalDetailRef = useRef<HTMLDivElement>(null);
    const token = useAccessToken()
    const intl = useIntl()

    const fetcher = (url: string) => {
        return axios.get(url, {
            headers: {
                'X-Auth-token': token
            },
        }).then(res => res.data.data);
    }

    const { data: eventDetail, error: eventDetailError, isValidating: eventDetailValidating } = useSWR(`${API_URL}conference/company-room/${data?.roomId}/book/${data?.id}`, fetcher, { revalidateOnFocus: false, revalidateOnReconnect: false })

    useEffect(() => {
        if (!eventDetailValidating) {
            setDetailEvent(eventDetail)
        }
    }, [eventDetail]);

    useEffect(() => {
        setTimeout(() => {
            if (isConferenceNotReady) {
                setDateNow(moment().toISOString());
                setIsConferenceNotReady(moment(data?.start).isAfter(moment().toISOString()));
            }
        }, 1000);
    }, [dateNow]);

    const handleStartConference = async (data: DetailScheduleConferenceProps) => {
        Swal.fire({
            title: intl.formatMessage({ id: 'FORM.VALIDATION.CONFIRMATION' }),
            html: intl.formatMessage({ id: 'FORM.VALIDATION.DESC_CONFIRMATION' }),
            icon: "question",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.YES' }),
            cancelButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.NO' }),
            customClass: {
                confirmButton: "btn btn-color-primary btn-active-color-primary",
                cancelButton: "btn btn-active-color-gray-400",
            },
            reverseButtons: true,
            heightAuto: false,
            target: document.getElementById('detail-event-modal'),
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Loading',
                    allowOutsideClick: false,
                    heightAuto: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })

                try {
                    const response = await createConference(data.roomCode, user.data.id, user.data.name, user.data.company_id, "SCHEDULE", data.start, data.end, token)
                    if (response.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            // text: intl.formatMessage({ id: "FORM.VALIDATION.ADD_SESSION_SUCCESS" }),
                            heightAuto: false,
                            target: document.getElementById("detail-event-modal"),
                            timer: 2000
                        })

                        handleJoinConference(data.roomCode)
                        setDetailEvent({
                            ...detailEvent!,
                            session: {
                                ...detailEvent!.session,
                                status: response.data.data.status
                            }
                        });
                        setDataCalendars(dataCalendars.map(data => {
                            if (data.id === eventData.id) {
                                return {
                                    ...data,
                                    roomStatus: response.data.data.status,
                                };
                            } else {
                                return data;
                            }
                        }));
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        confirmButtonText: 'Ok',
                        heightAuto: false,
                    })
                }
            }
        })


    }

    const handleJoinConference = (roomCode: string) => {
        window.open(`${url}company/${user.data.id}/${roomCode}`, "_blank");
    }

    const handleCopyInformation = () => {
        var date = moment(detailEvent?.start).local().format("DD MMMM YYYY") === moment(detailEvent?.end).local().format("DD MMMM YYYY") ? moment(detailEvent?.start).local().format("dddd, DD MMMM YYYY") + " • " + moment(detailEvent?.start).local().format("hh:mm A") + " - " + moment(detailEvent?.end).local().format("hh:mm A") : moment(detailEvent?.start).local().format("dddd, DD MMMM YYYY hh:mm A") + " - " + moment(detailEvent?.end).local().format("dddd, DD MMMM YYYY hh:mm A")
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const copyUrl = `${user.data.company_name} \n${detailEvent?.roomTitle} \n${date}\nTime Zone: ${timeZone} \nLink Wooblazz Meet: ${url}status?rc=${detailEvent?.roomId}&id=${detailEvent?.id} \n`

        navigator.clipboard.writeText(copyUrl).then(() => {
            toaster.push(
                <Message showIcon type={'success'} closable>
                    {intl.formatMessage({ id: 'FORM.VALIDATION.SUCCESS_COPY_INFORMATION' })}
                </Message>, { placement: "topCenter", duration: 2000, style: { zIndex: '9999' } }
            )
        }).catch(err => {
            console.error('Gagal menyalin URL: ', err);
        });
    }

    const handleCopyLink = () => {
        const copyUrl = `${url}status?rc=${detailEvent?.roomId}&id=${detailEvent?.id}`

        navigator.clipboard.writeText(copyUrl).then(() => {
            toaster.push(
                <Message showIcon type={'success'} closable>
                    {intl.formatMessage({ id: 'FORM.VALIDATION.SUCCESS_COPY_URL' })}
                </Message>, { placement: "topCenter", duration: 2000, style: { zIndex: '9999' } }
            )
        }).catch(err => {
            console.error('Gagal menyalin URL: ', err);
        });
    }

    const ActionButtons = (onShowUpdateModal: any, onShowDeleteModal: any) => (
        <>
            <div
                className="btn btn-icon btn-sm btn-color-gray-400 btn-active-icon-primary me-2"
                onClick={() => setShowUpdateModal(true)}
            >
                <span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black"></path>
                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black"></path>
                    </svg>
                </span>
            </div>
            <div
                className="btn btn-icon btn-sm btn-color-gray-400 btn-active-icon-danger me-2"
                onClick={() => setShowDeleteModal(true)}
            >
                <span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path>
                    </svg>
                </span>
            </div>
        </>
    );

    useEffect(() => {
        if (showUpdateModal) {
            const modalElement = document.getElementById('update-event-modal');

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal) {
            const modalElement = document.getElementById('delete-event-modal');

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, setShowDeleteModal]);

    return (
        <>
            <div className={`modal fade ${(showDeleteModal || showUpdateModal) ? "d-none" : "show"}`} tabIndex={-1} id={`detail-event-modal`} style={{ zIndex: "9999" }} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered" style={{ minHeight: "100%", maxWidth: "650px" }}>
                    <div className="modal-content">
                        <div className="modal-header border-0 justify-content-end">
                            {currentDate && isConferenceNotReady && detailEvent?.userId === user.data.id ? (
                                <ActionButtons />
                            ) : !currentDate && isConferenceNotReady && detailEvent?.userId === user.data.id ? (
                                <ActionButtons />
                            ) : null}
                            <div className="btn btn-icon btn-sm btn-color-gray-500 btn-active-icon-primary" data-bs-dismiss="modal" ref={closeModalDetailRef}>
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        {
                            eventDetailValidating ?
                                <div className="modal-body pt-0 pb-10 px-lg-17" style={{ height: "100px" }}>
                                    <div className="loading-overlay">
                                        <div className="loader"></div>
                                    </div>
                                </div>
                                :
                                <div className="modal-body pt-0 pb-10 px-lg-17">
                                    <div className="mb-9">
                                        <div className="d-flex align-items-center mb-2">
                                            <span className="fs-3 fw-bolder me-3">{detailEvent?.roomTitle}</span>
                                        </div>
                                        {
                                            moment(detailEvent?.start).local().format("DD MMMM YYYY") === moment(detailEvent?.end).local().format("DD MMMM YYYY") ?
                                                <div className="fs-6">{moment(detailEvent?.start).local().format("DD MMMM YYYY")} • {moment(detailEvent?.start).local().format("HH:mm")} - {moment(detailEvent?.end).local().format("HH:mm")}</div>
                                                : <div className="fs-6">{moment(detailEvent?.start).local().format("DD MMMM YYYY HH:mm")} - {moment(detailEvent?.end).local().format("DD MMMM YYYY HH:mm")}</div>
                                        }
                                    </div>

                                    <div className="mb-2 d-flex">
                                        <span className="svg-icon svg-icon-1 svg-icon-muted me-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="black" />
                                                <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="black" />
                                            </svg>
                                        </span>
                                        <div className='fs-5'>{intl.formatMessage({ id: 'FORM.LABEL.CREATED_BY' }) + " " + detailEvent?.userName}</div>
                                    </div>
                                    <div className="mb-5 d-flex">
                                        <span className="svg-icon svg-icon-1 svg-icon-muted me-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <defs />
                                                <g stroke="none" strokeWidth="1"  fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <rect fill="#000000" x="2" y="6" width="13" height="12" rx="2" />
                                                    <path d="M22,8.4142119 L22,15.5857848 C22,16.1380695 21.5522847,16.5857848 21,16.5857848 C20.7347833,16.5857848 20.4804293,16.4804278 20.2928929,16.2928912 L16.7071064,12.7071013 C16.3165823,12.3165768 16.3165826,11.6834118 16.7071071,11.2928877 L20.2928936,7.70710477 C20.683418,7.31658067 21.316583,7.31658098 21.7071071,7.70710546 C21.8946433,7.89464181 22,8.14899558 22,8.4142119 Z" fill="#000000" opacity="0.3" />
                                                </g>
                                            </svg>
                                        </span>
                                        <div className='fs-5'>{intl.formatMessage({ id: 'FORM.LABEL.ROOM' }) + " " + detailEvent?.roomCode}</div>
                                    </div>


                                    {
                                        (currentDate || isConferenceNotReady) &&
                                        <>
                                            <button className="btn btn-sm btn-flex btn-light-primary fw-bolder me-3" onClick={handleCopyInformation}>
                                                <KTSVG path="media/icons/duotune/general/gen028.svg" className="svg-icon svg-icon-5 svg-icon-gray-500 me-1" />
                                                {intl.formatMessage({ id: 'FORM.ACTION.COPY_INFORMATION' })}
                                            </button>
                                            <button className="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder" onClick={handleCopyLink}>
                                                <KTSVG path="media/icons/duotune/coding/cod007.svg" className="svg-icon svg-icon-5 svg-icon-gray-500 me-1" />
                                                {intl.formatMessage({ id: 'FORM.ACTION.COPY_LINK' })}
                                            </button>
                                        </>
                                    }

                                    {
                                        detailEvent?.userId === user.data.id &&
                                            (currentDate || isConferenceNotReady) ?
                                            detailEvent?.session !== null && detailEvent?.session.status === "ACTIVE" ? (
                                                <button className={`btn btn-sm btn-success btn-flex btn-light fw-bolder ${user.data.id !== detailEvent?.session.creatorId && "disabled"}`} style={{ float: "right" }} onClick={() => handleJoinConference(detailEvent?.roomCode!)}>
                                                    {intl.formatMessage({ id: 'FORM.ACTION.JOIN' })}
                                                </button>
                                            ) : (
                                                <button className={`btn btn-sm btn-primary btn-flex btn-light fw-bolder`} style={{ float: "right" }} onClick={() => handleStartConference(detailEvent!)}>
                                                    {intl.formatMessage({ id: 'FORM.ACTION.START' })}
                                                </button>
                                            )
                                            : <></>
                                    }

                                    {
                                        (currentDate || isConferenceNotReady) && detailEvent?.userId === user.data.id && detailEvent?.session !== null && user.data.id !== detailEvent?.session.creatorId &&
                                        <Tooltip
                                            title={intl.formatMessage({ id: 'TOOLTIP.CONFERENCE_IN_USE' })}
                                            theme="light"
                                            arrow={true}
                                            className="btn btn-icon btn-flush"
                                            style={{ cursor: "default", display: "flex", float: "right" }}
                                        >
                                            <i className='fas fa-info-circle ms-2 fs-7'></i>
                                        </Tooltip>
                                    }
                                </div>
                        }
                    </div>

                </div>
            </div>

            {
                showUpdateModal && <UpdateScheduleConference roomList={roomList} closeModalDetailRef={closeModalDetailRef} calendarRef={calendarRef} detailEvent={detailEvent!} dataCalendars={dataCalendars} listEvent={listEvent} selectedCalendars={selectedCalendars} setListEvent={setListEvent} setDataCalendars={setDataCalendars} />
            }
            {
                showDeleteModal && <DeleteScheduleConference closeModalDetailRef={closeModalDetailRef} calendarRef={calendarRef} eventData={eventData} dataCalendars={dataCalendars} listEvent={listEvent} setListEvent={setListEvent} />
            }
        </>
    )
}

export { DetailScheduleConference }