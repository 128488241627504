import React, { useEffect, useRef } from 'react'
import { ScanQR } from '../ScanQR'
import useUser from '../../../hooks/User'
import { useChatStore } from '../../../stores/ChatStore'
import useModalEffect from '../../../hooks/useModalEffect'
import { Modal } from 'bootstrap'

interface ModalSqanQrProps {
    isOpen: boolean
    onClose: () => void
}

const ModalSqanQr = ({ isOpen, onClose }: ModalSqanQrProps) => {
    const user = useUser()

    if (!isOpen) return null

    return (
        <div 
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1050
            }}
        >
            <div 
                style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    width: '90%',
                    maxWidth: '500px'
                }}
            >
                <div className='modal-header'>
                    <h5 className='modal-title'>Scan QR</h5>
                    <button 
                        type='button' 
                        className='btn-close' 
                        onClick={onClose}
                        aria-label="Close"
                    />
                </div>
                <div className='modal-body d-flex justify-content-center align-items-center'>
                    <iframe 
                        src={`https://wa.looyal.id/scan/${user.data.chat_phone}`} 
                        title="scan-qr" 
                        height={'250px'} 
                    />
                </div>
                <div className='modal-footer'>
                    <button 
                        type='button' 
                        className='btn btn-secondary' 
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ModalSqanQr