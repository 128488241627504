import { useEffect, useRef, useState } from "react";
import { useChatStore } from "../../../stores/ChatStore";
import useAccessToken from "../../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../_metronic/helpers";
import Swal from "sweetalert2";
import { assignContactChatLabel } from "../../../api/ChatLabelsCRUD";

interface Props {
    dataSelect: {
        [phone: string]: boolean;
    };
    setSelected: (value: any) => void
}

const AssignContactLabel = ({ dataSelect, setSelected }: Props) => {
    const token = useAccessToken();
    const intl = useIntl();

    const { chatLabels, setChatLabels, selectChatLabel, setSelectChatLabel, setSelectChatWindow } = useChatStore();

    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const checkboxRef = useRef<{ [key: string]: HTMLInputElement | null }>({});

    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const [selectNumber, setSelectNumber] = useState<string[]>([]);
    const [dataAssignLabel, setDataAssignLabel] = useState<{ [key: string]: { phone: string[], check: boolean } }>({});

    const handleSave = async () => {
        setModalBtnLoading(true)

        const result = Object.entries(dataAssignLabel).reduce<{ [key: string]: string[] }>((acc, [key, value]) => {
            acc[key] = value.phone;
            return acc;
        }, {});

        try {
            const response = await assignContactChatLabel(result, token)
            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    heightAuto: false,
                    title: `${intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" })}`,
                    timer: 2000,
                    timerProgressBar: true
                })
                closeModalRef.current?.click();
                setSelected({})
                if (selectChatLabel.id) {
                    setSelectChatLabel(response.data.data.find((item: any) => item.id === selectChatLabel.id))
                }
                setChatLabels(response.data.data)
                setSelectChatWindow([])
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Gagal',
                confirmButtonText: 'Ok',
                heightAuto: false,
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    const handleCheckboxChange = (labelId: string, checked: boolean) => {
        setDataAssignLabel((prev) => {
            const updated = { ...prev };

            if (checked) {
                updated[labelId].phone = [...new Set([...updated[labelId].phone, ...selectNumber])];
                updated[labelId].check = true;
            } else {
                updated[labelId].phone = updated[labelId].phone.filter((phone) => !selectNumber.includes(phone));
                updated[labelId].check = false
            }

            return updated;
        });
    };

    useEffect(() => {
        const selectedKeys = Object.entries(dataSelect)
            .filter(([key, value]) => value)
            .map(([key]) => key);

        const result = chatLabels.reduce<{ [key: string]: { phone: string[], check: boolean } }>((acc, item) => {
            const matchedPhone = item.roomList.filter((room) => selectedKeys.includes(room));
            const isAnyKeyMatched = selectedKeys.some((key) => item.roomList.includes(key));
            // if (matchedPhone.length > 0) {
            acc[item.id] = {
                phone: item.roomList,
                check: isAnyKeyMatched
            };
            // }
            return acc;
        }, {});

        setSelectNumber(selectedKeys);
        setDataAssignLabel(result);
    }, [dataSelect, chatLabels]);

    return (
        <div className="modal fade" tabIndex={-1} id="assign-contact-label-modal" ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Labeli Item</h5>
                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal">
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                        </div>
                    </div>
                    <div className="modal-body">
                        {chatLabels.map((data) => (
                            <div key={data.id} className="d-flex align-items-center p-3 rounded-2 bg-hover-light" onClick={() => checkboxRef.current[data.id]?.click()}>
                                <div className="symbol symbol-30px me-3">
                                    <span className="symbol-label" style={{ backgroundColor: data.color }}>
                                        <svg
                                            viewBox="0 0 24 24"
                                            height="20"
                                            width="20"
                                            className="cursor-pointer"
                                            preserveAspectRatio="xMidYMid meet"
                                            fill="none"
                                            style={{ color: "white" }}
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"
                                            ></path>
                                        </svg>
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between w-100">
                                    <div className="ms-5">
                                        <span className="text-gray-800 fw-bolder fs-6">{data.name}</span>
                                    </div>
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input
                                            ref={(el) => (checkboxRef.current[data.id] = el)}
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={dataAssignLabel[data.id]?.check}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => handleCheckboxChange(data.id, e.target.checked)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal" ref={closeModalRef}>
                            {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                        </button>
                        <button
                            onClick={handleSave}
                            type="button"
                            className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`}
                            data-kt-indicator={modalBtnLoading ? "on" : "off"}
                        >
                            <span className="indicator-label">{intl.formatMessage({ id: "FORM.ACTION.SAVE" })}</span>
                            <span className="indicator-progress">
                                Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { AssignContactLabel };
