import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import moment from "moment";
import {Pipeline } from "../../interfaces/Pipeline";
import { useIntl } from "react-intl";
import useUser from "../../hooks/User";
import { MasterDataTable } from "../DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { UserActivity } from "../../interfaces/UserActivity";

interface DetailProductPipelineProps {
    pipeline: Pipeline
    startDate: string
    endDate: string
}

const ActivityPipeline: FC<DetailProductPipelineProps> = ({ pipeline, startDate, endDate }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const intl = useIntl()
    const user = useUser()

    const tableColumns: TableColumn<UserActivity>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TIME' }),
            selector: row => row.date_created?.toLowerCase() ?? "-",
            sortable: true,
            cell: (row) => {
                return row.date_created && moment(row.date_created).format('dddd, DD MMM YYYY HH:mm')
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.USER' }),
            width: '20%',
            selector: row => row.user_name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.user_name
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTIVITY' }),
            width: '60%',
            selector: row => row.description?.toLowerCase() ?? "-",
            sortable: true,
            cell: (row) => {
                if (row.activity_type === 'INSERT' && row.table_type === 'PIPELINE LOG') {
                    return <div>
                        <span>{row.description}</span><br />
                        <p dangerouslySetInnerHTML={{ __html: row.new_data.Deskripsi }}></p>
                    </div>
                } else {
                    return <p>{row.description}</p>
                }
            }
        },
    ];

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id={`activity-pipeline-modal-${pipeline.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.ACTIVITIES" })} {pipeline.merchant_name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form noValidate>
                            <div className="modal-body">
                                <MasterDataTable
                                    tableKey="pipeline-activities"
                                    tableColumns={tableColumns}
                                    apiURL={`user-activities?tableType=PIPELINE&activitySourceId=${pipeline.id}`}
                                    dateStart={moment(startDate).format('YYYY-MM-DD')}
                                    dateEnd={moment(endDate).format('YYYY-MM-DD')}
                                    orderBy={1}
                                    order="desc"
                                    expanded
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ActivityPipeline }