import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'
import { LeadProgress } from '../interfaces/Progress';

type ProgressState = {
    progress: LeadProgress[]; // Change the type to Record<string, Setting>
    setProgress: (progress: LeadProgress[]) => void;
}

export const useProgressStore = create<ProgressState>()(
    persist(
        (set) => ({
            progress: [],

            setProgress: (progress) => {
                set({ progress });
            },
        }),
        {
            name: 'progress-store',
            storage: createJSONStorage(() => localStorage),
        }
    )
)