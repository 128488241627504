import { FC, useState } from "react"
import { exportExcel } from "../../functions/exportExcel"
import { useDataTableStore } from "../../stores/DataTableStore"
import { useSettingsStore } from "../../stores/SettingsStore"
import { ResponseSuccessValidationPhoneProp } from "../../interfaces/ValidationPhone"
import { UserModel } from "../../app/modules/auth/models/UserModel"
import { useIntl } from "react-intl"
import useNameColumnExcel from "../../hooks/NameColumnExcel"
import { useCustomeStore } from "../../stores/CustomeStore"
import { usePipelineStore } from "../../stores/PipelineStore"
import { Dropdown } from "rsuite";
import { SelectOption } from "../../interfaces/SelectOption"
import Swal from "sweetalert2"
import useAccessToken from "../../hooks/AccessToken"
import { getUserPerformance } from "../../api/Dashboard"
import { DashboardLeadsReportTable, DetailMonthlyDashboardWidget, DetailStaticDashboardWidget } from "../../interfaces/Dashboard"
import { exportExcelUserPerformance } from "../../functions/ExportExcel/userPerformance"
import moment from 'moment';
import { exportExcelMeetingActivityCategoryPerformance } from "../../functions/ExportExcel/meetingActivityCategoryPerformance"
import { exportExcelSourceCategoryPerformance } from "../../functions/ExportExcel/sourceCategoryPerformance"
import { exportExcelWidgetDashboard } from "../../functions/ExportExcel/widgetDashboard"
import { getDateSummary } from "../../functions/general"
import { exportExcelMerchant, exportExcelMeeting, exportExcelPipeline } from "../../functions/ExportExcel/index"
import { useMerchantStore } from "../../stores/MerchantStore"
import { exportPDFPaidSchedule } from "../../functions/ExportPDF/paidSchedule"
import { exportExcelAccountReceivable } from "../../functions/ExportExcel/accountReceivable"
import { useReceivableStore } from "../../stores/Receivable"
import useNameColumn from "../../hooks/useNameColumn"
import { AccountReceivableType } from "../../interfaces/AccountReceivable"

interface DataTableExportProps {
    source: string,
    type?: 'pdf' | 'excel'
    columns?: any,
    data?: ResponseSuccessValidationPhoneProp[]
    user?: UserModel
    dateStart?: string
    dateEnd?: string
    nameFile?: string
    keyString?: string
    listDropdown?: SelectOption[]
    customParams?: any
    dataExportCustom?: AccountReceivableType[]
}

const DataTableExport: FC<DataTableExportProps> = ({ source, type = 'excel', columns, data, user, dateStart, dateEnd, nameFile, keyString, listDropdown = [], customParams, dataExportCustom }) => {
    const { filteredTablesData, tablesData, filterTextTable, isFilterDate, isFullyLoaded } = useDataTableStore()
    const { selectMultiPipeline } = usePipelineStore()
    const { selectMultiMerchant } = useMerchantStore()
    const { settings } = useSettingsStore()
    const intl = useIntl()
    const token = useAccessToken()
    const nameHeader = useNameColumnExcel();
    const { companyId, keyCustomfield } = useCustomeStore()
    const [loadingFetch, setLoadingFetch] = useState<boolean>(false)
    const { tempSortTableData } = useMerchantStore()
    const { selectMultiPipelineCustomField } = useReceivableStore();
    const nameHeaderExcel = useNameColumn()


    const calculatePerformance = (obj: any, desc: string, prospectName: string, totalProspectQ: number, totalPaidQ: number) => {
        let prospectAchievementPercent = (obj.leadsCount / totalProspectQ) * 100;
        let paidAchievementPercent = (obj.paidLeadsCount / totalPaidQ) * 100;

        let total = obj.dropLeadsCount + obj.coldLeadsCount + obj.hotLeadsCount + obj.paidLeadsCount;
        let lostPercent = (obj.dropLeadsCount / total) * 100;
        let coldPercent = (obj.coldLeadsCount / total) * 100;
        let hotPercent = (obj.hotLeadsCount / total) * 100;
        let paidPercent = (obj.paidLeadsCount / total) * 100;
        lostPercent = isNaN(lostPercent) ? 0 : lostPercent
        coldPercent = isNaN(coldPercent) ? 0 : coldPercent
        hotPercent = isNaN(hotPercent) ? 0 : hotPercent
        paidPercent = isNaN(paidPercent) ? 0 : paidPercent
        let totalPercent = lostPercent + coldPercent + hotPercent + paidPercent;

        return {
            desc,
            key: obj.branchName,
            prospect: prospectName,
            prospectTarget: 0,
            prospectAchievementQ: obj.leadsCount,
            prospectAchievementPercent: isNaN(prospectAchievementPercent) ? 0 : Math.round(prospectAchievementPercent * 100) / 100,
            paidTarget: 0,
            paidAchievementQ: obj.paidLeadsCount,
            paidAchievementPercent: isNaN(paidAchievementPercent) ? 0 : Math.round(paidAchievementPercent * 100) / 100,
            lostQ: obj.dropLeadsCount,
            lostPercent: Math.round(lostPercent * 100) / 100,
            coldQ: obj.coldLeadsCount,
            coldPercent: Math.round(coldPercent * 100) / 100,
            hotQ: obj.hotLeadsCount,
            hotPercent: Math.round(hotPercent * 100) / 100,
            paidQ: obj.paidLeadsCount,
            paidPercent: Math.round(paidPercent * 100) / 100,
            totalQ: total,
            totalPercent: Math.round(totalPercent * 100) / 100
        };
    };

    const calculateUsersPerformance = (obj: any, desc: string, prospectName: string, targetPaid: number, targetProspect: number) => {
        let prospectAchievementPercent = (obj.leadsCount / targetProspect) * 100;
        let paidAchievementPercent = (obj.paidLeadsCount / targetPaid) * 100;

        let total = obj.dropLeadsCount + obj.coldLeadsCount + obj.hotLeadsCount + obj.paidLeadsCount;
        let lostPercent = (obj.dropLeadsCount / total) * 100;
        let coldPercent = (obj.coldLeadsCount / total) * 100;
        let hotPercent = (obj.hotLeadsCount / total) * 100;
        let paidPercent = (obj.paidLeadsCount / total) * 100;
        lostPercent = isNaN(lostPercent) ? 0 : lostPercent
        coldPercent = isNaN(coldPercent) ? 0 : coldPercent
        hotPercent = isNaN(hotPercent) ? 0 : hotPercent
        paidPercent = isNaN(paidPercent) ? 0 : paidPercent
        let totalPercent = lostPercent + coldPercent + hotPercent + paidPercent;

        return {
            desc,
            key: obj.branchName,
            prospect: prospectName,
            prospectTarget: targetProspect,
            prospectAchievementQ: obj.leadsCount,
            prospectAchievementPercent: isNaN(prospectAchievementPercent) ? 0 : Math.round(prospectAchievementPercent * 100) / 100,
            paidTarget: targetPaid,
            paidAchievementQ: obj.paidLeadsCount,
            paidAchievementPercent: isNaN(paidAchievementPercent) ? 0 : Math.round(paidAchievementPercent * 100) / 100,
            lostQ: obj.dropLeadsCount,
            lostPercent: Math.round(lostPercent * 100) / 100,
            coldQ: obj.coldLeadsCount,
            coldPercent: Math.round(coldPercent * 100) / 100,
            hotQ: obj.hotLeadsCount,
            hotPercent: Math.round(hotPercent * 100) / 100,
            paidQ: obj.paidLeadsCount,
            paidPercent: Math.round(paidPercent * 100) / 100,
            totalQ: total,
            totalPercent: Math.round(totalPercent * 100) / 100
        };
    };

    const calculateMeetingActivityCategoriesPerformance = (obj: any) => {
        let prospectAchievementPercent = (obj.leadsCount / obj.target) * 100;

        return {
            desc: obj.activityCategoryName,
            key: obj.branchName,
            prospectTarget: obj.target,
            prospectAchievementQ: obj.leadsCount,
            prospectAchievementPercent: isNaN(prospectAchievementPercent) ? 0 : Math.round(prospectAchievementPercent * 100) / 100,
        }
    }

    const fetchData = async (item: SelectOption) => {
        setLoadingFetch(true)
        try {
            const response = await getUserPerformance(item.value, dateStart!, dateEnd!, token)
            if (response.data.success) {
                const resultBranch: DashboardLeadsReportTable[] = [];
                const result: DashboardLeadsReportTable[] = [];
                const resultMeeting: DashboardLeadsReportTable[] = [];
                const keys: string[] = []
                let nameFile = intl.formatMessage({ id: "FORM.LABEL.USER_PERFORMANCE" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })
                let name = ''

                if (item.value === 'sources') {
                    let dataBranch = response.data.data.branchSourcesPerformance
                    let data = response.data.data.sourcesPerformance
                    let totalProspectQ = 0
                    let totalPaidQ = 0

                    data.forEach((item: any) => {
                        totalProspectQ += item.leadsCount;
                        totalPaidQ += item.paidLeadsCount;
                    });

                    result.push(
                        ...Object.values(data).map((obj: any) =>
                            calculatePerformance(obj, "Sumber", obj.sourceName, totalProspectQ, totalPaidQ)
                        ),
                    );

                    Object.keys(dataBranch).forEach((key: string) => {
                        let totalBranchProspectQ = 0;
                        let totalBranchPaidQ = 0;

                        dataBranch[key].forEach((item: any) => {
                            totalBranchProspectQ += item.leadsCount;
                            totalBranchPaidQ += item.paidLeadsCount;
                        });

                        keys.push(key);
                        resultBranch.push(...dataBranch[key].map((obj: any) => calculatePerformance(obj, "Sumber", obj.sourceName, totalBranchProspectQ, totalBranchPaidQ)));
                    });
                    name += `[Sumber] ${nameFile}`
                } else if (item.value === 'labels') {
                    let dataBranch = response.data.data.branchLabelsPerformance
                    let data = response.data.data.labelsPerformance
                    let totalProspectQ = 0
                    let totalPaidQ = 0

                    data.forEach((item: any) => {
                        totalProspectQ += item.leadsCount;
                        totalPaidQ += item.paidLeadsCount;
                    });

                    result.push(
                        ...Object.values(data).map((obj: any) =>
                            calculatePerformance(obj, "Kategori", obj.labelName, totalProspectQ, totalPaidQ)
                        ),
                    );

                    Object.keys(dataBranch).forEach((key: string) => {
                        let totalBranchProspectQ = 0;
                        let totalBranchPaidQ = 0;

                        dataBranch[key].forEach((item: any) => {
                            totalBranchProspectQ += item.leadsCount;
                            totalBranchPaidQ += item.paidLeadsCount;
                        });

                        keys.push(key);
                        resultBranch.push(...dataBranch[key].map((obj: any) => calculatePerformance(obj, "Kategori", obj.labelName, totalBranchProspectQ, totalBranchPaidQ)));
                    });
                    name += `[Kategori] ${nameFile}`
                } else if (item.value === 'users') {
                    let dataBranch = response.data.data.branchUsersPerformance
                    let data = response.data.data.usersPerformance

                    result.push(
                        ...Object.values(data).map((obj: any) =>
                            calculateUsersPerformance(obj, "Total Cabang", obj.ownerName, (obj.ownerName).includes('DUIN-TU') ? 15 : 5, 75)
                        ),
                    );

                    Object.keys(dataBranch).forEach((key: string) => {
                        keys.push(key);
                        resultBranch.push(...dataBranch[key].map((obj: any) => calculateUsersPerformance(obj, "Total Cabang", obj.ownerName, (obj.ownerName).includes('DUIN-TU') ? 15 : 5, 75)));
                    });

                    name += `[Total Cabang] ${nameFile}`
                } else if (item.value === 'meeting-activity-categories') {
                    let dataBranch = response.data.data.branchMeetingActivityCategoriesPerformance
                    let data = response.data.data.meetingActivityCategoriesPerformance

                    resultMeeting.push(
                        ...Object.values(data).map((obj: any) =>
                            calculateMeetingActivityCategoriesPerformance(obj)
                        ),
                    );

                    Object.keys(dataBranch).forEach((key: string) => {
                        keys.push(key);
                        resultBranch.push(...dataBranch[key].map((obj: any) => calculateMeetingActivityCategoriesPerformance(obj)));
                    });

                    name += `[${settings.meeting_title}] ${nameFile}`
                }

                if (dateStart === dateEnd) name += ` ${moment(dateStart).format('DD MMM YYYY')}`
                else name += ` ${moment(dateStart).format('DD MMM YYYY')} - ${moment(dateEnd).format('DD MMM YYYY')}`

                if (item.value === 'meeting-activity-categories') exportExcelMeetingActivityCategoryPerformance({ nameFile: name, data: resultMeeting, dataBranch: resultBranch, keys })
                else if (item.value === 'users') exportExcelUserPerformance({ nameFile: name, data: result, dataBranch: resultBranch, keys })
                else exportExcelSourceCategoryPerformance({ nameFile: name, data: result, dataBranch: resultBranch, keys })
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
                heightAuto: false
            })
        } finally {
            setLoadingFetch(false)
        }
    }

    const renderButton = (props: any, ref: any) => {
        if (loadingFetch) {
            return (
                <button {...props} ref={ref} className="btn btn btn-light-success mt-3 disabled">
                    <i className="fas fa-file-download fs-2 me-2"></i> Loading ...<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </button>
            );
        } else {
            return (
                <button {...props} ref={ref} className="btn btn btn-light-success mt-3">
                    <i className="fas fa-file-download fs-2 me-2"></i> {intl.formatMessage({ id: "MASTERS.DATATABLE.ACTIONS.EXPORT_EXCEL" })}
                </button>
            );
        }
    };

    if (listDropdown.length > 0) {
        return (
            <Dropdown renderToggle={renderButton} title={`${intl.formatMessage({ id: 'FORM.ACTION.ADD' })} ${settings.meeting_title}`} noCaret placement="bottomEnd">
                {
                    listDropdown.map((item, index) => (
                        <Dropdown.Item key={index} onClick={() => fetchData(item)}>{item.label}</Dropdown.Item>
                    ))
                }
            </Dropdown>
        )
    } else if (source === 'WidgetStaticDashboard' || source === 'WidgetMonthlyDashboard') {
        var dataExport: any[] = []
        if (keyString === 'Static All Pipelines Average Age') {
            filteredTablesData['widget-static']?.map((row: DetailStaticDashboardWidget, index) => {
                var temp_excel = [];

                temp_excel.push(
                    row.leadLogProgressName,
                    getDateSummary(Math.round(Number(row.leadAge))),
                    row.leadCount
                );

                dataExport.push(temp_excel);
            });
        } else if (keyString === 'Monthly Created Pipelines Count') {
            filteredTablesData['widget-monthly']?.map((row: DetailMonthlyDashboardWidget, index) => {
                var temp_excel = [];

                temp_excel.push(
                    row.merchant_name,
                    row.owner_name,
                    Math.ceil(row.nominal) ?? 0,
                    moment(row.date_start).format("DD MMM YYYY"),
                );

                dataExport.push(temp_excel);
            });
        } else if (keyString === 'Monthly Completed Meetings Count') {
            filteredTablesData['widget-monthly']?.map((row: DetailMonthlyDashboardWidget, index) => {
                var temp_excel = [];

                temp_excel.push(
                    row.merchant_name,
                    row.owner_name,
                    moment(row.date_meet).format("DD MMM YYYY"),
                    row.all_day ? moment(row.date_meet_end).subtract(1, 'days').local().format("DD MMM YYYY") : moment(row.date_meet_end).format("DD MMM YYYY"),
                    row.note ?? '-'
                );

                dataExport.push(temp_excel);
            });
        } else if (keyString === 'Monthly Award Pipelines Count' || keyString === 'Monthly Loss Pipelines Count' || keyString === 'Monthly Prospect Pipelines Count' || keyString === 'Monthly Lead-Gen Pipelines Count') {
            filteredTablesData['widget-monthly']?.map((row: DetailMonthlyDashboardWidget, index) => {
                var temp_excel = [];

                temp_excel.push(
                    row.owner_name,
                    row.merchant_count ?? 0,
                    Number(row.nominal) ?? 0,
                );

                dataExport.push(temp_excel);
            });
        } else if (source === 'WidgetMonthlyDashboard') {
            filteredTablesData['widget-monthly']?.map((row: DetailMonthlyDashboardWidget, index) => {
                var temp_excel = [];

                temp_excel.push(
                    row.merchant_name,
                    row.owner_name,
                    Math.ceil(row.nominal) ?? 0,
                    moment(row.date_start).format("DD MMM YYYY"),
                    moment(row.date_end).format("DD MMM YYYY")
                );

                dataExport.push(temp_excel);
            });
        } else if (source === 'WidgetStaticDashboard') {
            filteredTablesData['widget-static']?.map((row: DetailStaticDashboardWidget, index) => {
                var temp_excel = [];

                temp_excel.push(
                    row.merchant_name,
                    row.owner_name,
                    row.lead_progress_name,
                    Math.ceil(row.nominal),
                );

                dataExport.push(temp_excel);
            });
        }

        return (
            <button
                type="button"
                className={`btn btn-light-success skeleton skeleton-button ${source.includes("template") ? "mb-3 w-100" : "mt-3"}`}
                id="optionImport"
                onClick={() =>
                    exportExcelWidgetDashboard({ nameFile: nameFile!, data: dataExport, columns: columns, dateStart: dateStart!, dateEnd: dateEnd! })
                }
            >
                <i className="fas fa-file-download fs-2 me-2"></i>
                {intl.formatMessage({ id: "MASTERS.DATATABLE.ACTIONS.EXPORT_EXCEL" })}
            </button>
        )
    } else {
        const handleExport = () => {
            if (!columns && source !== 'account-receivable') return;

            switch (source) {
                case 'Pipeline':
                    return exportExcelPipeline(settings.pipeline_title, source, settings, nameHeader.export, { companyId, keyCustomfield }, selectMultiPipeline, filteredTablesData['pipelines'], columns, user);
                case 'Merchant':
                    return exportExcelMerchant(settings.merchant_title, source, nameHeader.export, { companyId, keyCustomfield }, selectMultiMerchant, filteredTablesData['merchants'], columns, user);
                case 'Meeting':
                    return exportExcelMeeting(settings.meeting_title, source, settings, nameHeader.export, { companyId, keyCustomfield }, filteredTablesData['meetings'], columns);
                case 'success-validation-phone':
                    if (data) return exportExcel('Validasi Telepon', source, settings, nameHeader.export, { companyId, keyCustomfield }, selectMultiPipeline, data, columns);
                    break;
                case 'template-validation-phone':
                    return exportExcel('Template Validasi Telepon', source, settings, nameHeader.export, { companyId, keyCustomfield }, selectMultiPipeline, selectMultiMerchant, [], columns);
                case 'Attendance':
                    return exportExcel('Attendance Recap', source, settings, nameHeader.export, { companyId, keyCustomfield }, selectMultiPipeline, selectMultiMerchant, filteredTablesData['all-user-attendances'], columns, user);
                case 'Paid Schedule Termin':
                    return exportPDFPaidSchedule(nameFile!, filteredTablesData['paid-schedule-termin'], customParams.dataPaidScheduleTermin);
                case 'account-receivable':
                    if (dataExportCustom && dataExportCustom.length > 0) {
                        return exportExcelAccountReceivable(
                            selectMultiPipelineCustomField?.name || 'Account_Receivable',
                            source || 'account-receivable',
                            nameHeaderExcel,
                            dataExportCustom as any,
                            selectMultiPipelineCustomField
                        );
                    }
                    break;
                default:
                    return;
            }
        };

        if (type === 'excel') {
            return (
                <button
                    type="button"
                    className={`btn btn-light-success skeleton skeleton-button ${source.includes("template") ? "mb-3 w-100" : "mt-3"}`}
                    onClick={handleExport}
                    disabled={!isFullyLoaded}
                >
                    <i className="fas fa-file-download fs-2 me-2"></i>
                    {
                        source.includes("template") ? "Download Template" : intl.formatMessage({ id: "MASTERS.DATATABLE.ACTIONS.EXPORT_EXCEL" })
                    }
                </button>
            )
        } else {
            return (
                <button
                    type="button"
                    className={`btn btn-light-danger skeleton skeleton-button mt-3`}
                    onClick={handleExport}
                    disabled={!isFullyLoaded}
                >
                    <i className="fas fa-file-download fs-2 me-2"></i>
                    {intl.formatMessage({ id: "MASTERS.DATATABLE.ACTIONS.EXPORT_PDF" })}
                </button>
            )
        }
    }
}

export { DataTableExport }