import { useEffect, useState } from "react";
import { LabelChatProps } from "../../../interfaces/Chat/LabelChat";
import { useChatStore } from "../../../stores/ChatStore";
import { Modal } from "bootstrap";
import { AddChatLabel } from "../Modal/AddChatLabel";
import { useIntl } from "react-intl";
import { UpdateChatLabel } from "../Modal/UpdateChatLabel";
import { DeleteChatLabel } from "../Modal/DeleteChatLabel";

interface Props {
}

const ChatLabelSetting = ({ }: Props) => {
    const intl = useIntl()
    const { chatLabels, setMenuActive, setSelectChatLabel } = useChatStore()
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        if (showAddModal) {
            const modalElement = document.getElementById(`add-chat-label-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddModal, setShowAddModal]);

    useEffect(() => {
        if (showUpdateModal) {
            const modalElement = document.getElementById(`update-chat-label-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal) {
            const modalElement = document.getElementById(`delete-chat-label-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, setShowDeleteModal]);

    return (
        <div className='card card-flush w-100 p-10' style={{ maxHeight: "calc(100vh - 1rem)", height: "calc(100vh - 1rem)" }}>
            <div className="d-flex align-items-center my-1 mb-10 justify-content-between">
                <div className="d-flex align-items-center gap-5">
                    <i className="p-3 cursor-pointer fas fa-solid fa-arrow-left fs-3" onClick={() => setMenuActive("settings")}></i>
                    <h1 className="mb-0 text-dark fw-bolder fs-1">Label</h1>
                </div>
                <i className="p-3 cursor-pointer fas fa-solid fa-plus fs-3" onClick={() => setShowAddModal(true)}></i>
            </div>
            <div style={{ overflowY: "scroll", height: "100%" }}>
                {
                    chatLabels.map((label, index) => (
                        <div className="d-flex align-items-center p-3 rounded-2 bg-hover-light" key={index}>
                            <div className="symbol symbol-30px me-5" onClick={() => { setMenuActive("settings-detail-label"); setSelectChatLabel(label) }}>
                                <span className="symbol-label" style={{ backgroundColor: label.color }}>
                                    <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: "white" }}>
                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                    </svg>
                                </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="me-5 w-100" onClick={() => { setMenuActive("settings-detail-label"); setSelectChatLabel(label) }}>
                                    <span className="text-gray-800 fw-bolder fs-6">{label.name}</span>
                                    <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">{label.roomCount} Data</span>
                                </div>
                                <div className="dropdown-center dropdown">
                                    <span className="badge px-3" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className={`fas fs-8 fa-ellipsis-v`}></i>
                                    </span>
                                    <ul className="dropdown-menu">
                                        <li onClick={() => { setShowUpdateModal(true); setSelectChatLabel(label) }}>
                                            <div className="dropdown-item cursor-pointer">{intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}</div>
                                        </li>
                                        <li onClick={() => { setShowDeleteModal(true); setSelectChatLabel(label) }}>
                                            <div className="dropdown-item cursor-pointer">{intl.formatMessage({ id: "FORM.ACTION.DELETE" })}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            {
                showAddModal && <AddChatLabel />
            }
            {
                showUpdateModal && <UpdateChatLabel />
            }
            {
                showDeleteModal && <DeleteChatLabel />
            }
        </div>
    )
}

export { ChatLabelSetting }