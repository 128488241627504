import { create } from 'zustand';
import { Merchant } from '../interfaces/Merchant';
import { SettingMultiMerchants } from '../interfaces/Settings';

type MerchantState = {
    merchants: Merchant[];
    multiMerchants: SettingMultiMerchants[];
    simpleMerchants: Merchant[];
    merchantLoading: boolean;
    simpleMerchantLoading: boolean;
    tempSortTableData: Merchant[];
    selectMultiMerchant:  SettingMultiMerchants,
    setMerchants: (merchants: Merchant[]) => void;
    setMultiMerchants:(multiMerchants: SettingMultiMerchants[])=>void;
    setSimpleMerchants: (simpleMerchants: Merchant[]) => void;
    setMerchantLoading: (merchantLoading: boolean) => void;
    setSimpleMerchantLoading: (simpleMerchantLoading: boolean) => void;
    setTempSortTableData: (tempSortTableData: Merchant[], sort: 'desc' | 'asc') => void;
    setSelectMultiMerchant: (selectMultiMerchant: SettingMultiMerchants) => void;
    
}

export const useMerchantStore = create<MerchantState>((set, getState) => ({
    merchants: [],
    simpleMerchants: [],
    merchantLoading: false,
    simpleMerchantLoading: false,
    multiMerchants:[],
    tempSortTableData: [],
    selectMultiMerchant: {} as SettingMultiMerchants,
    setMerchants: (merchants) => {
        set({ merchants });
    },
    setMultiMerchants: (multiMerchants) => {
        set({ multiMerchants });
    },
    setSimpleMerchants: (simpleMerchants) => {
        set({ simpleMerchants });
    },
    setMerchantLoading: (merchantLoading) => {
        set({ merchantLoading });
    },
    setSimpleMerchantLoading: (simpleMerchantLoading) => {
        set({ simpleMerchantLoading });
    },
    setTempSortTableData: (tempSortTableData: Merchant[], sort: 'desc' | 'asc') => {
        const sortedData = [...tempSortTableData].sort((a, b) => {
            const dateA = new Date(a.dateCreated).getTime();
            const dateB = new Date(b.dateCreated).getTime();

            return sort === 'desc' ? dateB - dateA : dateA - dateB;
        });

        set({ tempSortTableData: sortedData });
    },
    setSelectMultiMerchant: (selectMultiMerchant) => {
        set({ selectMultiMerchant });
    },
}));