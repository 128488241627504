export type SinertekProgressType = (typeof SINERTEK_PROGRESS)[keyof typeof SINERTEK_PROGRESS]

export type SinertekCustomFieldKeys = typeof SINERTEK_CUSTOM_FIELDS[keyof typeof SINERTEK_CUSTOM_FIELDS]

export const SINERTEK_CUSTOM_FIELDS = {
    FILE_SURAT_JALAN_KEY: 'ba797179-3129-43f4-884d-765ee0d37736',
    NO_SURAT_JALAN_KEY: '29e4f5c7-ab6d-47bf-9e00-0f5ad2f40e8a',
    NO_QUOTATION_KEY: 'a436fd70-9fe3-42c8-834a-7878c5ef9bec',
    FILE_QUOTATION_KEY: '982d3a83-94a4-44c7-90c3-8dadf95edc68',
    NO_PO_KEY: '58685157-9c3d-4b3f-8cee-025ec996f75a',
    FILE_PO_KEY: 'c6d12b93-6c12-4730-ac2e-fcf8f258274e',
    NO_KP_KEY: '58969428-175f-4217-aa1f-73f98adc9189',
    FILE_KONFIRMASI_PESANAN_KEY: 'c5ce2275-9abd-4ff6-806c-2efb90039305',
    JADWAL_PENGIRIMAN_KEY: 'd4233c11-a81f-4acc-b67a-9859d771e215',
    TANGGAL_SURAT_JALAN_KEY: 'ee914f44-22b8-402f-8ac8-e86a5d3a59af',
    NAMA_EKSPEDISI_KEY: 'ffbfb596-6c5e-4eb6-a312-3c079305567e',
    NAMA_KURIR_KEY: '265b1e14-a9fe-43af-b857-b78a2171ceef',
    TANGGAL_KIRIM_KEY: '37ae0150-fcad-4c89-874f-aaf1fae2d1a5',
    NO_RESI_KEY: '2a524d1c-1139-4f86-b3af-1034a7dbe85e',
    // FILE_POD_KEY: 'ca52ff17-50b4-4219-af9d-06da179e1182', // lama
    FILE_POD_KEY: '1bb37d9a-f404-48d3-bb4c-65e1a3e8b6d3',
    KIRIM_RESI_PENGIRIMAN_KEY: '269178d3-7bd9-4c4b-b547-28e21fd7a264',
    KIRIM_POD_KEY: '58b45640-6811-4ff7-a614-a9d2c2a61ec5',
    FILE_RESI_PENGIRIMAN_KEY: '9d4cf984-e537-4ee1-b4ed-aece446a4889',
    NOMOR_INVOICE: 'd302b6a6-ca92-4e2c-b57f-cf3a3f8cea2b',
    FILE_INVOICE: '940f9010-be4f-4888-9f12-5f0c9a1212fa',
    // ======= MDC =======
    HASIL_PSIKOTEST_KEY: 'f17d7f63-23b5-49af-853c-774435debfc2',
    // ======= MITRA USAHA =======
    KTP_SUAMI_ISTRI_KEY: '39c22156-3629-4c73-af6d-2fd203b783e3',
    KK_KEY: 'bdb19002-9016-47b9-9434-343bc20a0571',
    NPWP_KEY: 'c8d8fc19-5857-4ab8-a60c-68af6578c7db',
    SURAT_KETERANGAN_KERJA_KEY: 'fd61bd1f-f45a-4092-8147-45a57b823fb2',
    REKENING_KORAN_KEY: 'd5a8d479-5c8a-408a-83b6-86d547030ef6',
    BANK_KEY: 'ee979ddf-a136-4910-9358-78d37f7314b4',
    BI_CHECKING_KEY: 'cdb8fa9e-817a-47a5-aa65-bd66352cb7e6',
    CREDIT_ANALYST_KEY: 'aa0bb7e3-a999-4226-8607-6062aa8ab603',
    PENENTUAN_NILAI_PLAFON_KEY: 'b4c5abf8-4a69-45af-94f8-d708eb1f8ba7',
    AKAD_KEY: 'e6d5f23d-395d-4625-894a-da2a0ab75d2c',
} as const

export const SINERTEK_PROGRESS = {
    INPUT_SURAT_JALAN_ID: '16aa3919-7b5e-4818-a3f2-4335e7a2bfec',
    ORDER_RESI_PENGIRIMAN_ID: '7c86792b-2669-4d0b-b43e-f4b9c03c991f',
    QUOTATION_ID: '1be96c88-7808-4b70-82e8-ac47c0349756',
    PO_ID: '0b497985-d5ca-4fad-8aa7-774169950284',
    ORDER_CONFIRMATION_ID: '4e90223e-f157-443a-ad99-b86bc7b26751',
    POD_ID: '7d04d235-0dc2-4591-950e-a8fc42f9c8c1',
    KIRIM_TAGIHAN_ID: '2a94d432-8cfc-4dc2-9a04-b4c68a885c2d',
    PENAGIHAN_ID: 'b4ee36f4-0889-4f5a-9c2d-66958f89f239',
    PAID_ID: '2610efb4-9f6c-4f6c-85ec-b2774d0a7aa2',
    // ======= MDC =======
    PSIKOTEST_ID: 'fa752402-65d4-4385-bf9b-1b7a47b8b020',
    // ======= MITRA USAHA =======
    UTJ_ID: 'c4acf50e-71bb-44ce-b73c-85c626903cb0',
    BI_CHECKING_ONGOING_ID: 'a14d0749-ffc8-4632-81ef-e3d4c1788aac',
    BI_CHECKING_DONE_ID: '8287d697-cc54-4b33-a132-8d1a3e53ea07',
    CREDIT_ANALYST_ID: 'e37ed966-c20f-4532-9790-0bcec5c21368',
    PENENTUAN_NILAI_PLAFON_ID: '4fdede65-da4d-4920-89f4-97293ba91d6b',
    AKAD_ID: '5a060ac7-7aca-4988-ad1d-0eecc1224f3c',
} as const

export const progressToCustomFieldMap: Partial<Record<SinertekProgressType, string[]>> = {
    // ======= SINERTEK =======
    [SINERTEK_PROGRESS.QUOTATION_ID]: [SINERTEK_CUSTOM_FIELDS.NO_QUOTATION_KEY, SINERTEK_CUSTOM_FIELDS.FILE_QUOTATION_KEY],
    [SINERTEK_PROGRESS.PO_ID]: [SINERTEK_CUSTOM_FIELDS.NO_PO_KEY, SINERTEK_CUSTOM_FIELDS.FILE_PO_KEY],
    [SINERTEK_PROGRESS.ORDER_CONFIRMATION_ID]: [SINERTEK_CUSTOM_FIELDS.NO_KP_KEY, SINERTEK_CUSTOM_FIELDS.JADWAL_PENGIRIMAN_KEY, SINERTEK_CUSTOM_FIELDS.FILE_KONFIRMASI_PESANAN_KEY],
    [SINERTEK_PROGRESS.INPUT_SURAT_JALAN_ID]: [SINERTEK_CUSTOM_FIELDS.NO_SURAT_JALAN_KEY, SINERTEK_CUSTOM_FIELDS.TANGGAL_SURAT_JALAN_KEY, SINERTEK_CUSTOM_FIELDS.FILE_SURAT_JALAN_KEY],
    // [SINERTEK_PROGRESS.ORDER_RESI_PENGIRIMAN_ID]: [SINERTEK_CUSTOM_FIELDS.TANGGAL_KIRIM_KEY, SINERTEK_CUSTOM_FIELDS.NAMA_EKSPEDISI_KEY, SINERTEK_CUSTOM_FIELDS.NAMA_KURIR_KEY, SINERTEK_CUSTOM_FIELDS.KIRIM_RESI_PENGIRIMAN_KEY, SINERTEK_CUSTOM_FIELDS.NO_RESI_KEY],
    [SINERTEK_PROGRESS.ORDER_RESI_PENGIRIMAN_ID]: [SINERTEK_CUSTOM_FIELDS.FILE_RESI_PENGIRIMAN_KEY, SINERTEK_CUSTOM_FIELDS.TANGGAL_KIRIM_KEY, SINERTEK_CUSTOM_FIELDS.NAMA_EKSPEDISI_KEY, SINERTEK_CUSTOM_FIELDS.NAMA_KURIR_KEY, SINERTEK_CUSTOM_FIELDS.KIRIM_RESI_PENGIRIMAN_KEY],
    [SINERTEK_PROGRESS.POD_ID]: [SINERTEK_CUSTOM_FIELDS.FILE_POD_KEY, SINERTEK_CUSTOM_FIELDS.KIRIM_POD_KEY],
    [SINERTEK_PROGRESS.KIRIM_TAGIHAN_ID]: [SINERTEK_CUSTOM_FIELDS.NOMOR_INVOICE, SINERTEK_CUSTOM_FIELDS.FILE_INVOICE],
    // ======= MDC =======
    [SINERTEK_PROGRESS.PSIKOTEST_ID]: [SINERTEK_CUSTOM_FIELDS.HASIL_PSIKOTEST_KEY],
    // ======= MITRA USAHA =======
    [SINERTEK_PROGRESS.UTJ_ID]: [SINERTEK_CUSTOM_FIELDS.KTP_SUAMI_ISTRI_KEY, SINERTEK_CUSTOM_FIELDS.KK_KEY, SINERTEK_CUSTOM_FIELDS.NPWP_KEY, SINERTEK_CUSTOM_FIELDS.SURAT_KETERANGAN_KERJA_KEY, SINERTEK_CUSTOM_FIELDS.REKENING_KORAN_KEY],
    [SINERTEK_PROGRESS.BI_CHECKING_ONGOING_ID]: [SINERTEK_CUSTOM_FIELDS.BANK_KEY],
    [SINERTEK_PROGRESS.BI_CHECKING_DONE_ID]: [SINERTEK_CUSTOM_FIELDS.BI_CHECKING_KEY],
    [SINERTEK_PROGRESS.CREDIT_ANALYST_ID]: [SINERTEK_CUSTOM_FIELDS.CREDIT_ANALYST_KEY],
    [SINERTEK_PROGRESS.PENENTUAN_NILAI_PLAFON_ID]: [SINERTEK_CUSTOM_FIELDS.PENENTUAN_NILAI_PLAFON_KEY],
    [SINERTEK_PROGRESS.AKAD_ID]: [SINERTEK_CUSTOM_FIELDS.AKAD_KEY],
}

/*

"id"    "name"
"1be96c88-7808-4b70-82e8-ac47c0349756"    "Quotation" V
"16aa3919-7b5e-4818-a3f2-4335e7a2bfec"    "Input Surat Jalan" V 
"7c86792b-2669-4d0b-b43e-f4b9c03c991f"    "Resi Pengiriman" V
"7d04d235-0dc2-4591-950e-a8fc42f9c8c1"    "POD (Prove Order Delivery)" V
"2a94d432-8cfc-4dc2-9a04-b4c68a885c2d"    "Kirim Tagihan" V
"b4ee36f4-0889-4f5a-9c2d-66958f89f239"    "Penagihan" V
"2610efb4-9f6c-4f6c-85ec-b2774d0a7aa2"    "Paid" V
"0b497985-d5ca-4fad-8aa7-774169950284"    "Purchase Order (PO)" V
"4e90223e-f157-443a-ad99-b86bc7b26751"    "Order Confirmation" V

Progress Quotation >> 
Custom field : No QUO
attachment : File QUO

Progress PO >> 
Custom field : No PO
attachment : File PO

Progress Order Confirm >>
custom field : #No KP
custom field : jadwal pengiriman 
attachment : File Konfirmasi Order

progress  Input Surat Jalan >>
custom field : No Surat jalan (DN)
custom field : tanggal surat jalan
Attachment : surat jalan ( lembar asli )

progress   Resi pengiriman >> 
custom field : tanggal kirim 
custom field : Nama ekspedisi 
custom field : nama kurir
custom field : Kirim Resi (Multiply choiche "YA dan TIDAK")
attachment : File attachment resi

progress  POD (Prove of Delivery) >> 
attachment : File attachment POD
custom field : Kirim POD (Multiply choiche "YA dan TIDAK")
 
progress  Kirim Tagihan >> 
Only update log

progress  Penagihan >> 
Only update log

progress  Paid >> 
Only update log
*/