import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import { KTSVG } from "../../_metronic/helpers";
import { getDailyAttendance, getHolidays } from "../../api/AttendanceCRUD";
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { exportExcel } from "../../functions/exportExcel";
import { useSettingsStore } from "../../stores/SettingsStore";
import useUser from "../../hooks/User";
import { useIntl } from "react-intl";
import usePredefinedRanges from "../../hooks/PredefinedRanges";
import useNameColumnExcel from "../../hooks/NameColumnExcel";
import { usePipelineStore } from "../../stores/PipelineStore";
import { useMerchantStore } from "../../stores/MerchantStore";

export declare type ValueType = [Date?, Date?];
export declare type DateRange = [Date, Date];
export interface RangeType {
    label: React.ReactNode;
    value: DateRange | ((value?: ValueType) => DateRange);
    closeOverlay?: boolean;
    placement?: 'bottom' | 'left';
}

const ExportAttendance: FC = () => {
    const { selectMultiPipeline } = usePipelineStore()
    const { selectMultiMerchant } = useMerchantStore()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const intl = useIntl();
    const predefinedRanges = usePredefinedRanges();

    const user = useUser()
    const { settings } = useSettingsStore()
    const nameHeader = useNameColumnExcel();

    const validationSchema = Yup.object().shape({
        startDate: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE_RANGE" }) })).nullable()
    });

    const initialValues = {
        startDate: '',
        endDate: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)

            try {
                const response = await getDailyAttendance(
                    moment(values.startDate).format('YYYY-MM-DD'),
                    moment(values.endDate).format('YYYY-MM-DD'),
                    token
                )
                // get holiday 
                let responseHoliday: object[] = [];
                if (moment(values.startDate).format('YYYY') === moment(values.endDate).format('YYYY')) {
                    responseHoliday = await getHolidays(moment(values.startDate).format('YYYY'));
                }
                else {
                    let betweenYears = [];

                    if (moment(values.startDate) < moment(values.endDate)){
                        var date = moment(values.startDate).startOf('year');

                        while (date < moment(values.endDate)) {
                            betweenYears.push(date.format('YYYY'));
                            date.add(1,'year');
                        }
                    }

                    await Promise.all(betweenYears.map(async (year) => {
                        const publicHoliday = await getHolidays(year);
                        responseHoliday = [...responseHoliday, ...publicHoliday]
                    }))
                }
                if (response.data.success) {
                    exportExcel('Daily Attendance Recap ' + moment(values.startDate).locale('id').format('D MMM YY') + ' - '+ moment(values.endDate).locale('id').format('D MMM YY'), 'Daily Attendance', settings, nameHeader.export, {}, selectMultiPipeline,selectMultiMerchant, response.data.data, [], user, values.startDate, values.endDate, '', responseHoliday!)
                    closeModalRef.current?.click();
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    text: error.response.data.text?? "",
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });
    
    useEffect(() => {
        const handleResize = () => {
              if (window.innerWidth < 666) {
                  setIsMobile(true);
              } else {
                  setIsMobile(false);
              }
          };
  
          // Initial check
          handleResize();
  
          window.addEventListener("resize", handleResize);
  
          return () => {
              window.removeEventListener("resize", handleResize);
          };
      }, []);

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={"export-attendance-modal"} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.DOWNLOAD_SUMMARY" })} {intl.formatMessage({ id: "MENU.ATTENDANCE" })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                {/* time */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.DATE_RANGE" })}</label>
                                <div className="input-group mb-3">
                                    <DateRangePicker
                                        cleanable={false}
                                        character=" - "
                                        format="dd MMM yyyy"
                                        locale={
                                            {
                                                sunday: 'Mg',
                                                monday: 'Sn',
                                                tuesday: 'Sl',
                                                wednesday: 'Rb',
                                                thursday: 'Km',
                                                friday: 'Jm',
                                                saturday: 'Sb',
                                                ok: 'Gunakan',
                                            }
                                        }
                                        ranges={predefinedRanges}
                                        placement="bottomEnd"
                                        shouldDisableDate={(date) => {
                                            return date > new Date();
                                        }}
                                        onChange={(value) => {
                                            if (value && value.length === 2) {
                                                if (value[0]) formik.setFieldValue('startDate', value[0])
                                                else formik.setFieldValue('startDate', null)
                                            
                                                if (value[1]) formik.setFieldValue('endDate', value[1])
                                                else formik.setFieldValue('endDate', null)
                                            }
                                        }}
                                        showOneCalendar={isMobile}
                                        isoWeek={true}
                                        container={document.querySelector('#export-attendance-modal') as HTMLElement}
                                        style={{ width: "100%", zIndex: 0 }}
                                    />
                                    {formik.touched.startDate && formik.errors.startDate && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.startDate}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.DOWNLOAD" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ExportAttendance }