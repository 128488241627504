import { FC, useRef, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { useDataTableStore } from "../../../stores/DataTableStore";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import useAccessToken from "../../../hooks/AccessToken";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { useIntl } from "react-intl";
import { addTemplateSettingMerchant } from "../../../api/SettingCRUD";
import { usePipelineStore } from "../../../stores/PipelineStore";
import useHandleCloseModal from "../../../hooks/HandleCloseModal";
import { useFormik } from "formik";
import clsx from "clsx";
import { handleCaps } from "../../../functions/general";
import { useMerchantStore } from "../../../stores/MerchantStore";



const AddTemplateSettingMerchant: FC = () => {
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const token = useAccessToken()
    const {multiMerchants, setMultiMerchants } = useMerchantStore()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const { settings } = useSettingsStore()

    const intl = useIntl()

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true);
            setSubmitting(true);

            try {
                const response = await addTemplateSettingMerchant(values.name, {}, token)
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    setMultiMerchants([...multiMerchants, {...response.data.data, label: response.data.data.name, value: response.data.data.id}])
                    closeModalRef.current?.click();
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    heightAuto: false,
                    // target: document.getElementById(`add-merchant-modal`),
                    confirmButtonText: 'Ok'
                });
            } finally {
                setModalBtnLoading(false);
                setSubmitting(false);
            }
        },
    });

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);


    return (
            <div className="modal fade" tabIndex={-1} id="add-template-setting-merchant-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD_TEMPLATE' }, { title: settings.merchant_title })}</h5>
                            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={handleCloseModal} >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={intl.formatMessage({ id: 'FORM.LABEL.NAME' })}
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.name}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    
}

export { AddTemplateSettingMerchant }