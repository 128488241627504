import React, { useEffect, useRef, } from 'react'
import { Pipeline } from '../../../interfaces/Pipeline'
import { KTSVG } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import useHandleCloseModal from '../../../hooks/HandleCloseModal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useAccessToken from '../../../hooks/AccessToken'
import Swal from 'sweetalert2'
import { PaymentTermin } from '../../../interfaces/Termin/Termin'
import InputAttachmentTermin from './InputAttachmentTermin'
import { insertPaymentTerminAttachment } from '../../../api/ResourceAPI'
import { mutate } from 'swr'
import { finishPaymentTermin, IFinishPaymentTermin } from '../../../api/PipelineCRUD'
import { DatePicker } from "rsuite";
import useTodayRange from '../../../hooks/TodayRange'
import { isBeforeToday } from '../../../functions/general'

const API_URL = process.env.REACT_APP_API_URL

interface UpdateDetailTerminProps {
    termin: PaymentTermin;
    pipeline: Pipeline;
    setShowPaymentModal: (show: boolean) => void;
}

const ModalPaymentTermin = ({ termin, pipeline, setShowPaymentModal }: UpdateDetailTerminProps) => {
    const intl = useIntl()
    const token = useAccessToken()
    const modalRef = useRef<HTMLDivElement>(null)
    const closeModalRef = useRef<HTMLButtonElement>(null)
    const todayRange = useTodayRange();

    const validationSchema = Yup.object().shape({
        progress: Yup.string().required('Progress is required'),
        receiptUrls: Yup.mixed().nullable(),
        datePaid: Yup.mixed()
            .nullable()
            .required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PAYMENT_DATE" }) }))
    });

    const formik = useFormik({
        initialValues: {
            progress: 'PAID',
            receiptUrls: null,
            datePaid: null
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const data: IFinishPaymentTermin = {
                    progress: 'PAID',
                    receiptUrls: [],
                    datePaid: values.datePaid
                };

                if (values.receiptUrls) {
                    const attachment = await insertPaymentTerminAttachment(values.receiptUrls);
                    if (attachment.success) {
                        data.receiptUrls = [attachment.data[0]];
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: `Terjadi Kesalahan pada saat mengunggah file`
                        })
                        return;
                    }
                }


                const response = await finishPaymentTermin(termin.id, data, token)
                if (response) {
                    Swal.fire(
                        intl.formatMessage({ id: 'FORM.CONFIRM_SUCCESS' }),
                        intl.formatMessage({ id: 'FORM.CONFIRM.PAYMENT_COMPLETED' }),
                        'success'
                    )
                    mutate(`${API_URL}payment-termins?leadId=${pipeline.id}`)
                    closeModalRef.current?.click()
                }

            } catch (error) {
                console.error('Error submitting form:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Terjadi kesalahan saat memproses pembayaran'
                });
            }
        }
    });

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik)

    return (
        <div className="modal fade" tabIndex={-1} id={`payment-detail-termin-modal-${pipeline.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {intl.formatMessage({ id: "FORM.LABEL.PAYMENT_TERM" })}
                        </h5>
                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={handleCloseModal}>
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            <div className='row g-3'>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label fs-6 fw-bolder text-dark">
                                            {intl.formatMessage({ id: "FORM.LABEL.PROOF_OF_PAYMENT_TERM" })}
                                        </label>
                                        <div className="col-md-12">
                                            <InputAttachmentTermin key={`receiptUrls`} keyString={`receiptUrls`} formik={formik} isMulti={false} type="input" accept="image/png, image/jpeg, image/jpg" typeCustomField='paymentTermin' />
                                        </div>
                                        {formik.touched.receiptUrls && formik.errors.receiptUrls && (
                                            <div className="invalid-feedback d-block">
                                                {formik.errors.receiptUrls}
                                            </div>
                                        )}
                                    </div>
                                    <div className='form-group mt-3'>
                                        <label className="form-label fs-6 fw-bolder text-dark required">{intl.formatMessage({ id: "FORM.LABEL.PAYMENT_DATE" })}</label>
                                        <DatePicker
                                            value={formik.values.datePaid ? new Date(formik.values.datePaid) : null}
                                            onChange={(date) => formik.setFieldValue('datePaid', date)}
                                            ranges={todayRange}
                                            oneTap
                                            editable={false}
                                            format="dd MMM yyyy"
                                            defaultValue={null}
                                            style={{ width: "100%" }}
                                            className="form-control p-1"
                                            container={document.querySelector(`#payment-detail-termin-modal-${pipeline.id}`) as HTMLElement}
                                            shouldDisableDate={isBeforeToday}
                                        />
                                        {formik.touched.datePaid && formik.errors.datePaid && (
                                            <div className="invalid-feedback d-block">
                                                {formik.errors.datePaid}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light d-none" data-bs-dismiss="modal" ref={closeModalRef}>
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="button" className="btn btn-light" onClick={handleCloseModal}>
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="submit"
                                className={`btn btn-primary ${formik.isSubmitting ? 'disabled' : ''}`}
                                data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}
                                disabled={formik.isSubmitting}
                            >
                                <span className='indicator-label'>
                                    {intl.formatMessage({ id: "FORM.ACTION.PAID" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ModalPaymentTermin