import { FC, useEffect, useRef } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useChatStore } from "../../stores/ChatStore";
import { isImage, isVideo } from "../../functions/general";

interface ChatAttachmentModalProps {
    messageId: string
    url?: string
}

const ChatAttachmentModal: FC<ChatAttachmentModalProps> = ({ messageId, url }) => {
    const { mediaDownloads } = useChatStore();
    const downloadUrl = mediaDownloads[messageId]?.url ?? url;

    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (videoRef.current) {
            // Tambahkan query string unik untuk mencegah cache
            const uniqueUrl = downloadUrl;
            const sourceElement = videoRef.current.querySelector("source");

            if (sourceElement) {
                sourceElement.src = uniqueUrl;
                videoRef.current.load(); // Reload video untuk memperbarui sumbernya
            }
        }
    }, [downloadUrl]);

    const handleDownload = async () => {
        if (downloadUrl) {
            try {
                const response = await fetch(downloadUrl);
                const blob = await response.blob();
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = `attachment-${messageId}.jpg`; // Set a file name
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            } catch (error) {
                console.error("Failed to download the file:", error);
            }
        }
    };

    return (
        <div className="modal fade" tabIndex={-1} id="chat-attachment-modal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Media</h5>
                        <div className="d-flex align-items-center">
                            {/* Download Button */}
                            {downloadUrl && (
                                <button
                                    onClick={handleDownload}
                                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                    aria-label="Download"
                                >
                                    <i className="fas fa-download"></i>
                                </button>
                            )}
                            {/* Close Button */}
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-body d-flex justify-content-center">
                        {
                            isImage(downloadUrl) ?
                                <img
                                    src={downloadUrl}
                                    alt=""
                                    className="img-fluid"
                                    style={{ maxHeight: '80vh', objectFit: 'contain' }}
                                />
                                :
                                isVideo(downloadUrl) &&
                                <video
                                    ref={videoRef}
                                    controls
                                    className="img-fluid"
                                    style={{ maxHeight: '80vh', objectFit: 'contain' }}
                                >
                                    <source src={downloadUrl} type="video/mp4" />
                                </video>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ChatAttachmentModal };