export function formatFileSize(bytes: number): string {
  if (bytes < 1024) {
    return bytes + ' B'
  } else if (bytes < 1024 * 1024) {
    return Math.round(bytes / 1024) + ' KB'
  } else if (bytes < 1024 * 1024 * 1024) {
    return Math.round(bytes / (1024 * 1024)) + ' MB'
  } else {
    return Math.round(bytes / (1024 * 1024 * 1024)) + ' GB'
  }
}

export function extractTextFromHtml(html: string) {
  // Add spaces around HTML tags
  html = html?.replace(/</g, ' < ').replace(/>/g, ' > ')

  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  let text = doc.body.textContent || ''

  // Remove excess whitespace
  text = text.replace(/\s+/g, ' ').trim()

  // Add space after punctuation if not followed by a space
  text = text.replace(/([.!?])([^\s])/g, '$1 $2')

  // Add space before '<' if not preceded by a space (for any remaining tags)
  text = text.replace(/([^\s])</g, '$1 <')

  // Add space after '>' if not followed by a space (for any remaining tags)
  text = text.replace(/>([^\s])/g, '> $1')

  // Add newline before "Pada" (assuming it starts a new section)
  text = text.replace(/(\S)(\s*Pada\s)/g, '$1\n\n$2')

  // Add newline before email addresses
  text = text.replace(/(\S)(\s*)([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g, '$1\n$3')

  // Add newline after "menulis:"
  text = text.replace(/(menulis:)(\s*)(\S)/g, '$1\n$3')

  // Remove any remaining HTML tags
  text = text.replace(/<[^>]*>/g, '')

  // Remove formatting marks
  text = text.replace(/\*([^*\n]+)\*/g, '$1')  // Remove *bold*
  text = text.replace(/_([^_\n]+)_/g, '$1')    // Remove _italic_
  text = text.replace(/~([^~\n]+)~/g, '$1')    // Remove ~strikethrough~

  // Final cleanup of multiple spaces
  text = text.replace(/\s+/g, ' ').trim()

  return text
}

export function getFileTypeFromName(name: any) {
  if (name) {
    let splitUrl = typeof name === 'object' ? name.name.split('.') : name.split('.')
    var fileExtension = splitUrl[splitUrl.length - 1]

    // Determine file type based on extension
    switch (fileExtension.toLowerCase()) {
      case 'xlsx':
      case 'xlx':
        return 'xlsx'
      case 'doc':
        return 'doc'
      case 'ppt':
        return 'ppt'
      case 'pdf':
        return 'pdf'
      case 'png':
      case 'jpg':
      case 'jpeg':
        return fileExtension.toLowerCase()
      default:
        return ''
    }
  } else return ''
}

export function downloadAttachment(filename: string, base64: string, type: string) {
  const linkSource = `data:${type};base64,${base64}`
  const downloadLink = document.createElement('a')
  const fileName = filename
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

export function removeQuotes(str: string) {
  const nameEmailRegex = /^"([^"]+)"\s*<([^>]+)>$/;
  const match = str.match(nameEmailRegex);

  if (match) {
    const name = match[1];
    const email = match[2];
    return `${name} <${email}>`;
  }

  return str;
}

export function extractEmail(str: string): string {
  const emailRegex = /<([^>]+)>/;
  const match = str.match(emailRegex);
  return match ? match[1] : '';
}

export function generatePassword() {
  const length = 12;
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const symbols = "!@";

  let allCharacters = lowercase + uppercase + numbers + symbols;
  let password = '';

  // Menambahkan minimal satu huruf kapital, satu angka, dan satu simbol
  password += uppercase[Math.floor(Math.random() * uppercase.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += symbols[Math.floor(Math.random() * symbols.length)];

  // Mengisi sisa password hingga panjang 12 karakter
  for (let i = 3; i < length; i++) {
    password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
  }

  // Mengacak urutan password agar karakter yang ditambahkan di awal tidak selalu muncul di posisi yang sama
  return password.split('').sort(() => 0.5 - Math.random()).join('');
}

export const getNextContentCharCode = (currentContent: string) => {
  const currentCharCode = currentContent.charCodeAt(0);
  return String.fromCharCode(currentCharCode + 1);
}

export async function getBase64(file: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}