import 'moment/locale/id';
import { FC, useEffect, useState, useRef } from "react"
import { Link } from 'react-router-dom'
import useGeoLocation from "../../../hooks/GeoLocation";
import useCamera from "../../../hooks/Camera";
import axios from "axios";
import useSWR, { mutate } from "swr";
import useAccessToken from "../../../hooks/AccessToken";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { MyAttendance, TodayAttendance } from "../../../interfaces/Attendance";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import subDays from 'date-fns/subDays';
import { Modal } from "bootstrap";
import { AttendanceAttachments } from "../../../components/Attendance/AttendanceAttachments";
import useUser from '../../../hooks/User'
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { CapturePhoto } from "../../../components/Attendance/CapturePhoto";
import Swal from "sweetalert2";
import { insertPhotoData } from "../../../api/AttendanceCRUD";
import { Buffer } from 'buffer';
import { Map, Marker, GeoJson, GeoJsonFeature } from "pigeon-maps"
import usePredefinedRanges from '../../../hooks/PredefinedRanges';
import useCalendarLocale from '../../../hooks/CalendarLocale';
import { useDispatch } from 'react-redux';
import * as auth from '../../../app/modules/auth/redux/AuthRedux'
import { useIntl } from 'react-intl';
import { dataURLtoFile, getRandomFileName } from '../../../functions/general';

const Attendance: FC = () => {
    const geolocation = useGeoLocation()
    const camera = useCamera()
    const token = useAccessToken()
    const API_URL = process.env.REACT_APP_API_URL
    const [todayData, setTodayData] = useState<TodayAttendance>({} as TodayAttendance)
    const [attendance, setAttendance] = useState<MyAttendance>();
    const [updateType, setUpdateType] = useState<string>("");

    const today = new Date()
    const [startDate, setStartDate] = useState<Date>(subDays(today, 7));
    const [endDate, setEndDate] = useState<Date>(today);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);
    const [fileAttachment, setFileAttachment] = useState<string>('');
    const [photoData, setPhotoData] = useState<string>('');
    const [loadingPhoto, setLoadingPhoto] = useState<boolean>(true);
    const user = useUser()

    const [img, setImg] = useState(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false);

    const color = `hsl(${0 % 360}deg 39% 70%)`
    const dateTimeRef = useRef<HTMLSpanElement>(null);
    const dispatch = useDispatch()

    const predefinedRanges = usePredefinedRanges();
    const calendarLocale = useCalendarLocale();
    const intl = useIntl()

    useEffect(() => {
        const secondsTimer = setInterval(() => {
            if (dateTimeRef.current) {
                dateTimeRef.current.innerText = moment(new Date()).format("HH:mm:ss")
            }
        }, 1000);
        return () => clearInterval(secondsTimer);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 666) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const { data = [], error, isLoading } = useSWR(API_URL + `get-today-attendance`, fetcher)

    function wait(delay: number) {
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    function fetchRetry(name: string, tries: number) {
        function onError(err: any) {
            let triesLeft = tries - 1;
            if (!triesLeft) {
                throw err;
            }
            return wait(1000).then(() => fetchRetry(name, triesLeft));
        }
        fetch('https://tool.looyal.id/face/check-data?name=' + name)
            .then(response => response.json())
            .then(data => {
                if (data.photo) setPhotoData(data.photo);
                setLoadingPhoto(false);
            })
            .catch(onError);
    }

    useEffect(() => {
        fetchRetry(user.data.username, 2)
    }, [])

    useEffect(() => {
        if (!isLoading) {
            setTodayData(data)
        }
    }, [data])

    useEffect(() => {
        if (startDate && endDate) {
            mutate(`${API_URL}my-user-attendances`);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (showAttachmentModal && fileAttachment && attendance && updateType) {
            const modalElement = document.getElementById(`attachments-attendance-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAttachmentModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAttachmentModal, fileAttachment, attendance, updateType, setShowAttachmentModal]);

    const tableColumns: TableColumn<MyAttendance>[] = [
        {
            name: intl.formatMessage({ id: "FORM.OPTION.TYPE.DATE" }),
            selector: row => row.dateClockIn?.toLowerCase() ?? "-",
            sortable: true,
            cell: (row) => {
                return row.dateClockIn && moment(row.dateClockIn).format('DD MMM YYYY')
            }
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.START_HOUR" }),
            selector: row => row.dateClockIn?.toLowerCase() ?? "-",
            sortable: true,
            cell: (row) => {
                return row.dateClockIn && moment(row.dateClockIn).format('HH:mm')
            }
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.END_HOUR" }),
            selector: row => row.dateClockOut?.toLowerCase() ?? "-",
            sortable: true,
            cell: (row) => {
                if (row.dateClockOut) return moment(row.dateClockOut).format('HH:mm')
                else return '-'
            }
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_IN_PHOTO" }),
            width: '150px',
            center: true,
            cell: (row) => {
                if (row.imageClockIn) {
                    return (
                        <span role="button" onClick={
                            () => {
                                setShowAttachmentModal(true);
                                setFileAttachment(row.imageClockIn);
                                setAttendance(row);
                                setUpdateType("clockIn")
                            }
                        }>
                            <i className="fas fs-2 fa-eye"></i>
                        </span>
                    )
                }
                else return '';
            }
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_OUT_PHOTO" }),
            width: '150px',
            center: true,
            cell: (row) => {
                if (row.imageClockOut) {
                    return (
                        <span role="button" onClick={
                            () => {
                                setShowAttachmentModal(true);
                                setFileAttachment(row.imageClockOut);
                                setAttendance(row);
                                setUpdateType("clockOut")
                            }
                        }>
                            <i className="fas fs-2 fa-eye"></i>
                        </span>
                    )
                }
                else return '-';
            }
        }
    ]

    const savePhoto = async () => {
        setModalBtnLoading(true)

        try {
            if (img) {
                var file = dataURLtoFile(img, getRandomFileName() + '.jpeg', Buffer);

                if (file) {
                    const response = await insertPhotoData(
                        user.data.username,
                        file,
                    );

                    if (response.data.success) {
                        Swal.fire({
                            icon: 'success',
                            heightAuto: false,
                            title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                            timer: 2000,
                            timerProgressBar: true
                        })
                        .then(() => {
                            setPhotoData(response.data.photo);

                            const newUserData = { ...user, data: { ...user.data, profile_picture: img } };
                            dispatch(auth.actions.fulfillUser(newUserData))
                        })
                    }
                }
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
                .then(() => {
                    setImg(null)
                })
        } finally {
            setModalBtnLoading(false)
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between mb-2">
                <ol className="breadcrumb text-muted fs-6 fw-bold">
                    <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: "MENU.ATTENDANCE" })}</li>
                </ol>
                <span className="fw-bold fs-6" ref={dateTimeRef}></span>
            </div>
            {((!geolocation.coords && geolocation.error) || !camera.isCamera) && (
                <div className='mb-5 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT1' }, {title: ( !geolocation.coords && geolocation.error && camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION_CAMERA" }) : camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.CAMERA" }) : intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION" }) )})}<br /><br /><b>{intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT2' }, {title: ( !geolocation.coords && geolocation.error && camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION_CAMERA" }) : camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.CAMERA" }) : intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION" }) )})}</b><br />{intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT3' } )}<br />{intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT4' }, {title: ( !geolocation.coords && geolocation.error && camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION_CAMERA" }) : camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.CAMERA" }) : intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION" }) )})}</div>
                </div>
            )}
            {loadingPhoto ? (
                <div className="text-center w-100">
                    <span className='indicator-progress' style={{ display: 'block' }}>
                        Loading...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                </div>
            ) : photoData ? (
                <div className="">
                    <div className="row justify-content-center align-items-center pt-15">
                        {geolocation.coords && camera.isCamera ? (
                            <>
                                {!todayData.clockInTime && (
                                    <div className="text-center pb-5">
                                        <span className="m-0 fs-3 d-block mb-5">{intl.formatMessage({ id: "ATTENDANCE.GREETING.HELLO" })} {user.data.name}, {intl.formatMessage({ id: "ATTENDANCE.GREETING.CLOCK_IN" })}</span>
                                        <Link to={`clock-in`}>
                                            <div className="btn btn-primary btn-lg fw-bolder fs-3"><i className="fas fa-sign-in-alt me-3" style={{ fontSize: "50px", lineHeight: "normal" }}></i> {intl.formatMessage({ id: "FORM.ACTION.CLOCK_IN" })}</div>
                                        </Link>
                                    </div>
                                )}
                                {todayData.clockInTime && !todayData.clockOutTime && (
                                    <>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3">
                                            <div className="card card-xl-stretch">
                                                <div className="card-header border-0">
                                                    <h3 className="card-title fw-bolder text-dark">{intl.formatMessage({ id: "FORM.ACTION.CLOCK_IN" })}</h3>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="d-flex gap-5 flex-column flex-md-row align-items-center">
                                                        <img src={todayData.clockInImage} alt="clock_in_photo" width={200} height={200} style={{ borderRadius: "10px", objectFit: "cover" }}></img>
                                                        <div className="w-100">
                                                            <div className="fs-4 text-muted">{moment(todayData.clockInTime).format('dddd, D MMMM YYYY')}</div>
                                                            <div className="fs-4 mb-3 text-dark fw-bolder">{moment(todayData.clockInTime).format('HH:mm')}</div>
                                                            <Map height={150} defaultCenter={[todayData.positionClockIn.latitude, todayData.positionClockIn.longitude]} defaultZoom={15} attribution={false}>
                                                                {
                                                                    <Marker
                                                                        style={{ borderRadius: "10px" }}
                                                                        width={50}
                                                                        height={50}
                                                                        anchor={[todayData.positionClockIn.latitude, todayData.positionClockIn.longitude]}
                                                                        color={color}
                                                                    />
                                                                }
                                                            </Map>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3">
                                            <span className="m-0 fs-3 d-block mb-5">{intl.formatMessage({ id: "ATTENDANCE.GREETING.HELLO" })} {user.data.name}, {intl.formatMessage({ id: "ATTENDANCE.GREETING.CLOCK_OUT" })}</span>
                                            <Link to={`clock-out`}>
                                                <div className="btn btn-google btn-lg fw-bolder fs-3"><i className="fas fa-sign-out-alt me-3" style={{ fontSize: "50px", lineHeight: "normal" }}></i> {intl.formatMessage({ id: "FORM.ACTION.CLOCK_OUT" })}</div>
                                            </Link>
                                        </div>
                                    </>
                                )}
                                {todayData.clockInTime && todayData.clockOutTime && (
                                    <>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3">
                                            <div className="card card-xl-stretch">
                                                <div className="card-header border-0">
                                                    <h3 className="card-title fw-bolder text-dark">{intl.formatMessage({ id: "FORM.ACTION.CLOCK_IN" })}</h3>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="d-flex gap-5 flex-column flex-md-row align-items-center">
                                                        <img src={todayData.clockInImage} alt="clock_in_photo" width={200} height={200} style={{ borderRadius: "10px", objectFit: "cover" }}></img>
                                                        <div className="w-100">
                                                            <div className="fs-4 text-muted">{moment(todayData.clockInTime).format('dddd, D MMMM YYYY')}</div>
                                                            <div className="fs-4 mb-3 text-dark fw-bolder">{moment(todayData.clockInTime).format('HH:mm')}</div>
                                                            <Map height={150} defaultCenter={[todayData.positionClockIn.latitude, todayData.positionClockIn.longitude]} defaultZoom={15} attribution={false}>
                                                                {
                                                                    <Marker
                                                                        style={{ borderRadius: "10px" }}
                                                                        width={50}
                                                                        height={50}
                                                                        anchor={[todayData.positionClockIn.latitude, todayData.positionClockIn.longitude]}
                                                                        color={color}
                                                                    />
                                                                }
                                                            </Map>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3">
                                            <div className="card card-xl-stretch">
                                                <div className="card-header border-0">
                                                    <h3 className="card-title fw-bolder text-dark">{intl.formatMessage({ id: "FORM.ACTION.CLOCK_OUT" })}</h3>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="d-flex gap-5 flex-column flex-md-row align-items-center">
                                                        <img src={todayData.clockOutImage} alt="clock_out_photo" width={200} height={200} style={{ borderRadius: "10px", objectFit: "cover" }}></img>
                                                        <div className="w-100">
                                                            <div className="fs-4 text-muted">{moment(todayData.clockOutTime).format('dddd, D MMMM YYYY')}</div>
                                                            <div className="fs-4 mb-3 text-dark fw-bolder">{moment(todayData.clockOutTime).format('HH:mm')}</div>
                                                            <Map height={150} defaultCenter={[todayData.positionClockOut.latitude, todayData.positionClockOut.longitude]} defaultZoom={15} attribution={false}>
                                                                {
                                                                    <Marker
                                                                        width={50}
                                                                        height={50}
                                                                        anchor={[todayData.positionClockOut.latitude, todayData.positionClockOut.longitude]}
                                                                        color={color}
                                                                    />
                                                                }
                                                            </Map>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {!todayData.clockInTime && (
                                    <div className="text-center pb-5">
                                        <span className="m-0 fs-3 d-block mb-5">{intl.formatMessage({ id: "ATTENDANCE.GREETING.HELLO" })} {user.data.name}, {intl.formatMessage({ id: "ATTENDANCE.GREETING.CLOCK_IN" })}</span>
                                        <div className="btn btn-primary btn-lg fw-bolder fs-3 disabled" style={{ cursor: "not-allowed" }}><i className="fas fa-sign-in-alt me-3" style={{ fontSize: "50px", lineHeight: "normal" }}></i> {intl.formatMessage({ id: "FORM.ACTION.CLOCK_IN" })}</div>
                                    </div>
                                )}
                                {todayData.clockInTime && !todayData.clockOutTime && (
                                    <>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3">
                                            <div className="card card-xl-stretch">
                                                <div className="card-header border-0">
                                                    <h3 className="card-title fw-bolder text-dark">{intl.formatMessage({ id: "FORM.ACTION.CLOCK_IN" })}</h3>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="d-flex gap-5 flex-column flex-md-row align-items-center">
                                                        <img src={todayData.clockInImage} alt="clock_in_photo" width={200} height={200} style={{ borderRadius: "10px", objectFit: "cover" }}></img>
                                                        <div className="w-100">
                                                            <div className="fs-4 text-muted">{moment(todayData.clockInTime).format('dddd, D MMMM YYYY')}</div>
                                                            <div className="fs-4 mb-3 text-dark fw-bolder">{moment(todayData.clockInTime).format('HH:mm')}</div>
                                                            <Map height={150} defaultCenter={[todayData.positionClockIn.latitude, todayData.positionClockIn.longitude]} defaultZoom={15} attribution={false}>
                                                                {
                                                                    <Marker
                                                                        style={{ borderRadius: "10px" }}
                                                                        width={50}
                                                                        height={50}
                                                                        anchor={[todayData.positionClockIn.latitude, todayData.positionClockIn.longitude]}
                                                                        color={color}
                                                                    />
                                                                }
                                                            </Map>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3">
                                            <span className="m-0 fs-3 d-block mb-5">{intl.formatMessage({ id: "ATTENDANCE.GREETING.HELLO" })} {user.data.name}, {intl.formatMessage({ id: "ATTENDANCE.GREETING.CLOCK_OUT" })}</span>
                                            <div className="btn btn-google btn-lg fw-bolder fs-3 disabled" style={{ cursor: "not-allowed" }}><i className="fas fa-sign-out-alt me-3" style={{ fontSize: "50px", lineHeight: "normal" }}></i> {intl.formatMessage({ id: "FORM.ACTION.CLOCK_OUT" })}</div>
                                        </div>
                                    </>
                                )}
                                {todayData.clockInTime && todayData.clockOutTime && (
                                    <>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3">
                                            <div className="card card-xl-stretch">
                                                <div className="card-header border-0">
                                                    <h3 className="card-title fw-bolder text-dark">{intl.formatMessage({ id: "FORM.ACTION.CLOCK_IN" })}</h3>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="d-flex gap-5 flex-column flex-md-row align-items-center">
                                                        <img src={todayData.clockInImage} alt="clock_in_photo" width={200} height={200} style={{ borderRadius: "10px", objectFit: "cover" }}></img>
                                                        <div className="w-100">
                                                            <div className="fs-4 text-muted">{moment(todayData.clockInTime).format('dddd, D MMMM YYYY')}</div>
                                                            <div className="fs-4 mb-3 text-dark fw-bolder">{moment(todayData.clockInTime).format('HH:mm')}</div>
                                                            <Map height={150} defaultCenter={[todayData.positionClockIn.latitude, todayData.positionClockIn.longitude]} defaultZoom={15} attribution={false}>
                                                                {
                                                                    <Marker
                                                                        style={{ borderRadius: "10px" }}
                                                                        width={50}
                                                                        height={50}
                                                                        anchor={[todayData.positionClockIn.latitude, todayData.positionClockIn.longitude]}
                                                                        color={color}
                                                                    />
                                                                }
                                                            </Map>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3">
                                            <div className="card card-xl-stretch">
                                                <div className="card-header border-0">
                                                    <h3 className="card-title fw-bolder text-dark">{intl.formatMessage({ id: "FORM.ACTION.CLOCK_OUT" })}</h3>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="d-flex gap-5 flex-column flex-md-row align-items-center">
                                                        <img src={todayData.clockOutImage} alt="clock_out_photo" width={200} height={200} style={{ borderRadius: "10px", objectFit: "cover" }}></img>
                                                        <div className="w-100">
                                                            <div className="fs-4 text-muted">{moment(todayData.clockOutTime).format('dddd, D MMMM YYYY')}</div>
                                                            <div className="fs-4 mb-3 text-dark fw-bolder">{moment(todayData.clockOutTime).format('HH:mm')}</div>
                                                            <Map height={150} defaultCenter={[todayData.positionClockOut.latitude, todayData.positionClockOut.longitude]} defaultZoom={15} attribution={false}>
                                                                {
                                                                    <Marker
                                                                        width={50}
                                                                        height={50}
                                                                        anchor={[todayData.positionClockOut.latitude, todayData.positionClockOut.longitude]}
                                                                        color={color}
                                                                    />
                                                                }
                                                            </Map>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="card mt-5">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="fs-6 fw-bold">{intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.ATTENDANCE_HISTORY" })}</div>
                                <DateRangePicker
                                    cleanable={false}
                                    character=" - "
                                    format="dd MMM yyyy"
                                    locale={calendarLocale}
                                    value={[startDate, endDate]}
                                    ranges={predefinedRanges}
                                    placement="bottomEnd"
                                    shouldDisableDate={(date) => {
                                        return date > today;
                                    }}
                                    onChange={(value) => {
                                        if (value && value.length === 2) {
                                            setStartDate(value[0]);
                                            setEndDate(value[1]);
                                        }
                                    }}
                                    showOneCalendar={isMobile}
                                    disabled={loading}
                                    isoWeek={true}
                                />
                            </div>
                            <MasterDataTable
                                tableKey="my-user-attendances"
                                apiURL='my-user-attendances'
                                tableColumns={tableColumns}
                                dateStart={moment(startDate).format('YYYY-MM-DD')}
                                dateEnd={moment(endDate).format('YYYY-MM-DD')}
                                setLoading={setLoading}
                                orderBy={1}
                                order="desc"
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-md-7 col-12 mt-5">
                        <h4 className="mb-5">{intl.formatMessage({ id: "ATTENDANCE.REFERENCE.INSTRUCTION" })}</h4>
                        <ol>
                            <li className="mb-4">{intl.formatMessage({ id: "ATTENDANCE.REFERENCE.INSTRUCTION1" })}</li>
                            <li className="mb-4">{intl.formatMessage({ id: "ATTENDANCE.REFERENCE.INSTRUCTION2" })}</li>
                            <li className="mb-4">{intl.formatMessage({ id: "ATTENDANCE.REFERENCE.INSTRUCTION3" })}</li>
                        </ol>
                        <div className="text-center">
                            <p>{intl.formatMessage({ id: "ATTENDANCE.REFERENCE.EXAMPLE" })}</p>
                            <img src={toAbsoluteUrl('/media/avatars/300-27.jpg')} alt="example_photo" width={150}></img>
                        </div>
                    </div>
                    <div className="col-md-5 col-12 mt-5">
                        {camera.isCamera ? (
                            <CapturePhoto keyString='attendance' img={img!} setImg={setImg!} func={savePhoto} modalBtnLoading={modalBtnLoading!}></CapturePhoto>
                        ) : (
                            <div className="d-flex justify-content-center">
                                <div className='alert alert-danger m-0 text-center p-9 border border-danger' style={{ width: "max-content" }}>
                                    <i className="fas fa-video-slash text-danger mb-5" style={{ fontSize: "50px" }}></i>
                                    <div className='alert-text fw-bold'>{intl.formatMessage({ id: "ATTENDANCE.REFERENCE.CAMERA" })}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {
                showAttachmentModal && <AttendanceAttachments image={fileAttachment!} attendance={attendance!} updateType={updateType!}/>
            }
        </>
    )
}

export { Attendance }