// db.ts
import { openDB } from 'idb';
import { StateStorage } from 'zustand/middleware';

const dbName = 'chat-database';
const storeName = 'chat';

// Initialize the database
const dbPromise = openDB(dbName, 1, {
    upgrade(db) {
        db.createObjectStore(storeName);
    },
});

const get = async (key: string): Promise<string | null> => {
    try {
        const result = await (await dbPromise).get(storeName, key);
        return result;
    } catch (error) {
        console.error(`Error getting item with key "${key}":`, error);
        return null;
    }
};

const set = async (key: string, value: string): Promise<void> => {
    try {
        await (await dbPromise).put(storeName, value, key);
    } catch (error) {
        console.error(`Error setting item with key "${key}":`, error);
    }
};

const del = async (key: string): Promise<void> => {
    try {
        await (await dbPromise).delete(storeName, key);
    } catch (error) {
        console.error(`Error deleting item with key "${key}":`, error);
    }
};

const clearDB = async (): Promise<void> => {
    try {
        const db = await dbPromise;
        const tx = db.transaction(storeName, 'readwrite');
        await tx.objectStore(storeName).clear();
        await tx.done;
    } catch (error) {
        console.error('Error clearing IndexedDB:', error);
    }
};

const clearDBExceptIncomingMessageNotifications = async (): Promise<void> => {
    try {
        const db = await dbPromise;
        const tx = db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);

        // Ambil semua key di object store
        const allKeys = await store.getAllKeys();

        // Hapus semua key kecuali 'incomingMessageNotifications'
        for (const key of allKeys) {
            if (key !== 'incomingMessageNotifications') {
                await store.delete(key);
            }
        }

        await tx.done;
        console.log('All keys except "incomingMessageNotifications" have been deleted.');
    } catch (error) {
        console.error('Error clearing partial IndexedDB:', error);
    }
};

// Adding global error handling to catch memory issues or other unexpected crashes
window.onerror = (msg, url, lineNo, columnNo, error) => {
    console.error('Global error captured:', { msg, url, lineNo, columnNo, error });
    return false;
};

window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled rejection (possibly memory-related):', event.reason);
});

const storage: StateStorage = {
    getItem: async (name: string): Promise<string | null> => {
        return await get(name);  // Retrieve data based on the key
    },
    setItem: async (name: string, value: string): Promise<void> => {
        await set(name, value);  // Save data under the specific key
    },
    removeItem: async (name: string): Promise<void> => {
        await del(name);  // Remove data for a specific key
    },
};

export { clearDB, clearDBExceptIncomingMessageNotifications };
export default storage;
