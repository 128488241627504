import { Element } from "react-scroll"
import { Description as DescriptionComp } from "./Description"
import { Code as CodeComp} from "./Code"
import { IDocumentationProps } from "../../interfaces/OpenAPI"



export const  Documentation =({Description, Code }:IDocumentationProps)=>{
return (
    <div className="row">
    <div className="col-12 col-lg-6 p-10 pt-0">
        <Element name={Description.id}>
            <DescriptionComp
                id={Description.id}
                name={Description.name}
                endpoint={Description.endpoint}
                description={Description.description}
                method={Description.method}
                token={Description.token}
                requestBody={Description.requestBody}
                queryParameters={Description.queryParameters}
            />
        </Element>
    </div>
    <div className="col-12 col-lg-6 p-10 pt-0" style={{ backgroundColor: "#323F4C", color: "white", minHeight: "100vh" }}>
        <CodeComp
            type={Code.type}
            responseExample={Code.responseExample}
            python={Code.python}
            javascript={Code.javascript}
            php={Code.php}
            bash={Code.bash}
        />
    </div>
</div>
)
}