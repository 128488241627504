import { useDispatch } from "react-redux"
import { Outlet } from "react-router-dom"
import * as auth from '../../app/modules/auth/redux/AuthRedux'
import { useEffect } from "react"
import { getSettingAll } from "../../api/SettingCRUD"
import { useSettingsStore } from "../../stores/SettingsStore"
import Swal from "sweetalert2"

const WebView: React.FC = () => {
  const dispatch = useDispatch()
  const { setSettings } = useSettingsStore()

  useEffect(() => {
    var path = window.location.pathname.replace("/", "").split("/")
    var url = window.location.origin

    const getSetting = async () => {
      try {
        const response = await getSettingAll(path[1])
        if (response.data.success) {
          setSettings({
            merchant_title: response.data.data.merchant_title ? response.data.data.merchant_title : "Klien",
            merchant_custom_fields: response.data.data.merchant_custom_fields ? response.data.data.merchant_custom_fields : "",
            pipeline_title: response.data.data.pipeline_title ? response.data.data.pipeline_title : "Pipeline",
            pipeline_custom_fields: response.data.data.pipeline_custom_fields ? response.data.data.pipeline_custom_fields : "",
            meeting_title: response.data.data.meeting_title ? response.data.data.meeting_title : "Meeting",
            meeting_custom_fields: response.data.data.meeting_custom_fields ? response.data.data.meeting_custom_fields : "",
            users_title: response.data.data.users_title ? response.data.data.users_title : 'Users',
            users_custom_fields: response.data.data.users_custom_fields ? response.data.data.users_custom_fields : '',
            termins_custom_fields: response.data.data.termins_custom_fields ? response.data.data.termins_custom_fields : '',
            caps_lock_inputs: response.data.data.caps_lock_inputs === "true" ? true : false,
            merchant_duplicate: response.data.data.merchant_duplicate === "true" ? true : false,
            merchant_phone_can_be_changed: response.data.data.merchant_phone_can_be_changed === "true" ? true : false,
            hide_top_three_sales: response.data.data.hide_top_three_sales === "true" ? true : false,
            feature_check_number: response.data.data.feature_check_number === "true" ? true : false,
            feature_pipeline: response.data.data.feature_pipeline === "true" ? true : false,
            feature_meeting: response.data.data.feature_meeting === "true" ? true : false,
            feature_chat: response.data.data.feature_chat === "true" ? true : false,
            pipeline_date_min: response.data.data.pipeline_date_min ? response.data.data.pipeline_date_min : "",
            template_pipeline: response.data.data.template_pipeline ? response.data.data.template_pipeline : "",
            feature_reimbursement: response.data.data.feature_reimbursement === "false" ? false : true,
            update_log_hpp_progress: response.data.data.update_log_hpp_progress === "true" ? true : false
          })
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'Ok'
        })
      }
    }

    if (path[0] === "t") {
      const fetchData = async () => {
        await getSetting()
        localStorage.setItem("web-view", "true")
        dispatch(auth.actions.login(path[1]))
        window.location.href = url + "/" + path[2]
      }

      fetchData();
    }
  }, [])

  return (
    <Outlet />
  )
}

export { WebView }