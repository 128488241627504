import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan } from '../partials'
import { MenuComponent } from '../assets/ts/components'
import { ChangePassword } from '../../components/ChangePasswordModal'
import useAccessToken from '../../hooks/AccessToken'
import { useChatStore } from '../../stores/ChatStore'
import { ChangeProfile } from '../../components/ChangeProfileModal'
import { useSettingsStore } from '../../stores/SettingsStore'
import { ExpiredModal } from '../../components/ExpiredModal'
import { LanguageModal } from '../../components/LanguageModal'
import { ChangePhoneModal } from '../../components/ChangePhoneModal'
import ModalSelectUser from '../../components/Chat/Onboarding/ModalSelectUser'

const MasterLayout = () => {
  const location = useLocation()
  const token = useAccessToken()

  const { setInteracted } = useChatStore()
  const { settings } = useSettingsStore()
  const webView = localStorage.getItem("web-view") === "true" ? true : false

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  // detect if user interacted with the page
  // useEffect(() => {
  //   const handleUserInteracted = () => {
  //     setInteracted(true)
  //   }

  //   window.addEventListener('click', handleUserInteracted)
  //   window.addEventListener('mousemove', handleUserInteracted)
  //   window.addEventListener('keypress', handleUserInteracted)
  //   window.addEventListener('scroll', handleUserInteracted)

  //   return () => {
  //     window.removeEventListener('click', handleUserInteracted)
  //     window.removeEventListener('mousemove', handleUserInteracted)
  //     window.removeEventListener('keypress', handleUserInteracted)
  //     window.removeEventListener('scroll', handleUserInteracted)
  //   }
  // }, [])

  useEffect(() => {
    const toggleButton = document.getElementById('kt_aside_toggle');
    if (toggleButton) {
      if (window.location.pathname !== '/calendar') {
        toggleButton.classList.remove('active');
        document.body.removeAttribute('data-kt-aside-minimize');
      } else {
        toggleButton.classList.add('active');
        document.body.setAttribute('data-kt-aside-minimize', 'on');
      }
    }
  }, [window.location.pathname])

  document.addEventListener('input', (event) => {
    const targetElement = event.target as HTMLInputElement | HTMLTextAreaElement;
    // Check if the current route is not "/login", caps_lock_inputs is enabled, and input type is not 'file'
    if (
      (targetElement instanceof HTMLInputElement || targetElement instanceof HTMLTextAreaElement) &&
      settings.caps_lock_inputs &&
      window.location.pathname !== '/auth' && window.location.pathname !== '/settings' &&
      targetElement.type !== 'file' && targetElement.type !== 'checkbox' && !targetElement.className.includes("txt-password") &&
      !targetElement.className.includes("link") && !targetElement.className.includes("txtUsername") &&
      !targetElement.className.includes("txt-email") && !targetElement.className.includes("link") && !targetElement.className.includes("txt-log")
    ) {
      targetElement.value = targetElement.value.toUpperCase();
    }
  });

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid pt-15' id='kt_wrapper'>
          {
            !webView &&
            <HeaderWrapper />
          }
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid' style={window.location.pathname === '/calendar' ? { paddingBottom: '10px' } : webView ? { paddingTop: '0px' } : { padding: '30px 0px' }}>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <ChangeProfile />
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          {
            !webView &&
            <Footer />
          }
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer />
      <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <Main />
      <InviteUsers />
    <UpgradePlan /> */}
      {/* end:: Modals */}

      <ModalSelectUser />
      <ChangePassword type={"dashboard"} firstToken={token} />
      <ChangePhoneModal />
      <ExpiredModal />
      <LanguageModal />
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
