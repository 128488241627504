import { useFormik } from "formik";
import React, { FC, createRef, useEffect, useState } from "react";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { SelectOption } from "../../interfaces/SelectOption";
import { useSettingsStore } from "../../stores/SettingsStore";
import useAccessToken from "../../hooks/AccessToken";
import { CustomField } from "../../interfaces/Settings";
import { saveSetting } from "../../api/SettingCRUD";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { Modal } from "bootstrap";
import { AddBanner } from "../Banner/AddBanner";
import { Banner } from "../../interfaces/Banner";
import axios from "axios";
import useSWR from "swr";
import { UpdateBanner } from "../Banner/UpdateBanner";
import { DeleteBanner } from "../Banner/DeleteBanner";
import { Cropper, ReactCropperElement } from "react-cropper";
import { sortBanner } from "../../api/BannerCRUD";
import { useIntl } from "react-intl";
import { ArrayWidget, ListWidget, Widget, WidgetProps } from "../../interfaces/Widget";
import { sortWidget } from "../../api/WidgetCRUD";
import { Dropdown } from "rsuite";
import { SketchPicker } from "react-color";
import { CustomColumnWidget } from "../Dashboard/CustomColumnWidget";
import { CustomColumnWidgetOrder } from "../Dashboard/CustomColumnWidgetOrder";

interface Props {
    optionType: SelectOption[]
}

const renderIconButton = (props: any, ref: any, color: string) => {
    return (
        <button className="btn btn-sm btn-icon" type="button" {...props} ref={ref} style={{ background: "none", width: "auto" }}>
            <i className="fas fa-palette fs-5" style={{ color: color }}></i>
        </button>
    );
};

const CustomFieldDashboard: FC<Props> = ({ optionType }) => {
    const token = useAccessToken()

    const [showInsertModal, setShowInsertModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [dataBanner, setDataBanner] = useState<Banner[]>([]);
    const [dataDisplayBanner, setDataDisplayBanner] = useState<Banner[]>([]);
    const [banner, setBanner] = useState<Banner>();
    const [loadingSaveBanner, setLoadingSaveBanner] = useState(false);

    const intl = useIntl()
    const [dataWidget, setDataWidget] = useState<Widget>({} as Widget);
    const [dataDisplayWidget, setDataDisplayWidget] = useState<Widget>({} as Widget);
    const [widget, setWidget] = useState<Widget>({} as Widget);
    const [loadingSaveWidget, setLoadingSaveWidget] = useState(false);

    const handleDisplayBanner = (data: Banner) => {
        const isDataExists = dataDisplayBanner.some((banner: Banner) => banner.id === data.id);

        if (!isDataExists) {
            setDataDisplayBanner([...dataDisplayBanner, data]);
        }
    }

    const handleSpliceBanner = (data: Banner) => {
        const newArray = [...dataDisplayBanner.filter((banner: Banner) => banner.id !== data.id)];
        setDataDisplayBanner(newArray);
    }

    const saveDisplayBanner = async () => {
        setLoadingSaveBanner(true)

        var listBanner: Array<string> = []
        dataDisplayBanner.map((data) => {
            listBanner.push(data.id)
        })

        try {
            const response = await sortBanner(
                listBanner,
                token
            )
            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: `${intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" })}`,
                    text: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                    heightAuto: false,
                })
                mutateBanner()
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                heightAuto: false,
            })
        } finally {
            setLoadingSaveBanner(false)
        }
    }

    const handleWidget = (data: ArrayWidget, type: string, action: string) => {
        if (type === 'static') {
            if (action === 'show') {
                if (data.color) {
                    const filteredArray = dataWidget.static.filter((widget: ArrayWidget) => widget.id !== data.id);
                    setDataWidget({ ...dataWidget, static: filteredArray });
                    setDataDisplayWidget({ ...dataDisplayWidget, static: [...dataDisplayWidget.static, data] });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: intl.formatMessage({ id: "FORM.VALIDATION.NOT_SELECTED_COLOR" }),
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })
                }
            } else {
                const filteredArray = dataDisplayWidget.static.filter((widget: ArrayWidget) => widget.id !== data.id);
                setDataDisplayWidget({ ...dataDisplayWidget, static: filteredArray });
                setDataWidget({ ...dataWidget, static: [...dataWidget.static, data] });
            }
        } else if (type === 'monthly') {
            if (action === 'show') {
                if (data.color) {
                    const filteredArray = dataWidget.monthly.filter((widget: ArrayWidget) => widget.id !== data.id);
                    setDataWidget({ ...dataWidget, monthly: filteredArray });
                    setDataDisplayWidget({ ...dataDisplayWidget, monthly: [...dataDisplayWidget.monthly, data] });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: intl.formatMessage({ id: "FORM.VALIDATION.NOT_SELECTED_COLOR" }),
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })
                }
            } else {
                const filteredArray = dataDisplayWidget.monthly.filter((widget: ArrayWidget) => widget.id !== data.id);
                setDataDisplayWidget({ ...dataDisplayWidget, monthly: filteredArray });
                setDataWidget({ ...dataWidget, monthly: [...dataWidget.monthly, data] });
            }
        } else {
            if (action === 'show') {
                if (data.color) {
                    const filteredArray = dataWidget.pieChart.filter((widget: ArrayWidget) => widget.id !== data.id);
                    setDataWidget({ ...dataWidget, pieChart: filteredArray });
                    setDataDisplayWidget({ ...dataDisplayWidget, pieChart: [...dataDisplayWidget.pieChart, data] });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: intl.formatMessage({ id: "FORM.VALIDATION.NOT_SELECTED_COLOR" }),
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })
                }
            } else {
                const filteredArray = dataDisplayWidget.pieChart.filter((widget: ArrayWidget) => widget.id !== data.id);
                setDataDisplayWidget({ ...dataDisplayWidget, pieChart: filteredArray });
                setDataWidget({ ...dataWidget, pieChart: [...dataWidget.pieChart, data] });
            }
        }
    }

    const saveDisplayWidget = async () => {
        setLoadingSaveWidget(true)

        var listWidget: any = {
            "companyDashboardStaticWidgetsId": [],
            "companyDashboardMonthlyWidgetsId": [],
            "companyDashboardPieChartWidgetsId": [],
            "companyDashboardFixedWidgetsId": [],
        };

        dataDisplayWidget.static.forEach(function (data) {
            listWidget.companyDashboardStaticWidgetsId.push({
                "id": data.id,
                "color": data.color
            });
        });

        dataDisplayWidget.monthly.forEach(function (data) {
            listWidget.companyDashboardMonthlyWidgetsId.push({
                "id": data.id,
                "color": data.color
            });
        });

        dataDisplayWidget.pieChart.forEach(function (data) {
            listWidget.companyDashboardPieChartWidgetsId.push({
                "id": data.id,
                "color": data.color
            });
        });

        dataDisplayWidget.fixed.forEach(function (data) {
            listWidget.companyDashboardFixedWidgetsId.push({
                "id": data.id,
                "color": data.color
            });
        });

        try {
            const response = await sortWidget(
                listWidget,
                token
            )
            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: `${intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" })}`,
                    text: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                    heightAuto: false,
                })
                // mutateBanner()
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                heightAuto: false,
            })
        } finally {
            setLoadingSaveWidget(false)
        }
    }

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL

    const { data, error, isLoading } = useSWR(`${API_URL}company-informations`, fetcher)
    const { data: displayBanner = [], error: displayBannerError, isLoading: displayBannerLoading, mutate: mutateBanner } = useSWR(`${API_URL}company`, fetcher)
    const { data: displayWidget = [], error: displayWidgetError, isValidating: displayWidgetLoading, mutate: mutateDisplayWidget } = useSWR(`${API_URL}company-dashboard-widgets`, fetcher)
    const { data: listWidget = [], error: listWidgetError, isValidating: listWidgetLoading, mutate: mutateListWidget } = useSWR(`${API_URL}dashboard-widgets`, fetcher)

    useEffect(() => {
        if (showInsertModal) {
            const modalElement = document.getElementById(`tambah-banner-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowInsertModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showInsertModal, setShowInsertModal]);

    useEffect(() => {
        if (showUpdateModal && banner) {
            const modalElement = document.getElementById(`update-banner-modal-${banner.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, banner, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal && banner) {
            const modalElement = document.getElementById(`delete-banner-modal-${banner.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, banner, setShowDeleteModal]);

    useEffect(() => {
        if (!isLoading) {
            const bannerData = data.map((data: Banner) => ({
                id: data.id,
                name: data.name,
                banner: data.banner,
                description: data.description,
            }));
            setDataBanner(bannerData)
        }
    }, [data])

    useEffect(() => {
        if (!displayBannerLoading) {
            const bannerData = displayBanner.companyInformations.map((data: Banner) => ({
                id: data.id,
                name: data.name,
                banner: data.banner,
                description: data.description,
            }));
            setDataDisplayBanner(bannerData)
        }
    }, [displayBanner])

    useEffect(() => {
        if (!displayWidgetLoading) {
            const widgetData: Widget = {
                static: [],
                monthly: [],
                pieChart: [],
                fixed: []
            };
            const displayData: Widget = {
                static: [],
                monthly: [],
                pieChart: [],
                fixed: []
            };

            displayWidget.forEach((data: WidgetProps) => {
                switch (data.dashboardWidget.type) {
                    case "STATIC":
                        if (data.order === null) {
                            widgetData.static.push({
                                color: data.color,
                                id: data.dashboardWidget.id,
                                name: data.dashboardWidget.name,
                                alias: data.dashboardWidget.alias,
                                type: data.dashboardWidget.type,
                                order: data.order
                            });
                        } else {
                            displayData.static.push({
                                color: data.color,
                                id: data.dashboardWidget.id,
                                name: data.dashboardWidget.name,
                                alias: data.dashboardWidget.alias,
                                type: data.dashboardWidget.type,
                                order: data.order
                            })
                        }
                        break;
                    case "MONTHLY":
                        if (data.order === null) {
                            widgetData.monthly.push({
                                color: data.color,
                                id: data.dashboardWidget.id,
                                name: data.dashboardWidget.name,
                                alias: data.dashboardWidget.alias,
                                type: data.dashboardWidget.type,
                                order: data.order
                            });
                        } else {
                            displayData.monthly.push({
                                color: data.color,
                                id: data.dashboardWidget.id,
                                name: data.dashboardWidget.name,
                                alias: data.dashboardWidget.alias,
                                type: data.dashboardWidget.type,
                                order: data.order
                            })
                        }
                        break;
                    case "MONTHLY PIE CHART":
                        if (data.order === null) {
                            widgetData.pieChart.push({
                                color: data.color,
                                id: data.dashboardWidget.id,
                                name: data.dashboardWidget.name,
                                alias: data.dashboardWidget.alias,
                                type: data.dashboardWidget.type,
                                order: data.order
                            });
                        } else {
                            displayData.pieChart.push({
                                color: data.color,
                                id: data.dashboardWidget.id,
                                name: data.dashboardWidget.name,
                                alias: data.dashboardWidget.alias,
                                type: data.dashboardWidget.type,
                                order: data.order
                            })
                        }
                        break;
                    case "STATIC PIE CHART":
                        if (data.order === null) {
                            widgetData.pieChart.push({
                                color: data.color,
                                id: data.dashboardWidget.id,
                                name: data.dashboardWidget.name,
                                alias: data.dashboardWidget.alias,
                                type: data.dashboardWidget.type,
                                order: data.order
                            });
                        } else {
                            displayData.pieChart.push({
                                color: data.color,
                                id: data.dashboardWidget.id,
                                name: data.dashboardWidget.name,
                                alias: data.dashboardWidget.alias,
                                type: data.dashboardWidget.type,
                                order: data.order
                            })
                        }
                        break;
                    case "FIXED":
                        // if (data.order === null) {
                        //     widgetData.fixed.push({
                        //         color: data.color,
                        //         id: data.dashboardWidget.id,
                        //         name: data.dashboardWidget.name,
                        //         alias: data.dashboardWidget.alias,
                        //         type: data.dashboardWidget.type,
                        //         order: data.order
                        //     });
                        // } else {
                        displayData.fixed.push({
                            color: data.color,
                            id: data.dashboardWidget.id,
                            name: data.dashboardWidget.name,
                            alias: data.dashboardWidget.alias,
                            type: data.dashboardWidget.type,
                            order: data.order
                        })
                        // }
                        break;
                    default:
                        break;
                }
            });

            setDataWidget(widgetData);
            setDataDisplayWidget(displayData);
        }
    }, [displayWidget, displayWidgetLoading]);

    useEffect(() => {
        if (!listWidgetLoading) {
            const widgetData: Widget = {
                static: [],
                monthly: [],
                pieChart: [],
                fixed: []
            };

            listWidget.forEach((data: ListWidget) => {
                switch (data.type) {
                    case "STATIC":
                        widgetData.static.push({
                            id: data.id,
                            name: data.name,
                            alias: data.alias,
                            type: data.type,
                            description: data.description,
                            dateCreated: data.dateCreated,
                        });
                        break;
                    case "MONTHLY":
                        widgetData.monthly.push({
                            id: data.id,
                            name: data.name,
                            alias: data.alias,
                            type: data.type,
                            description: data.description,
                            dateCreated: data.dateCreated,
                        });
                        break;
                    case "MONTHLY PIE CHART":
                        widgetData.pieChart.push({
                            id: data.id,
                            name: data.name,
                            alias: data.alias,
                            type: data.type,
                            description: data.description,
                            dateCreated: data.dateCreated,
                        });
                        break;
                    case "STATIC PIE CHART":
                        widgetData.pieChart.push({
                            id: data.id,
                            name: data.name,
                            alias: data.alias,
                            type: data.type,
                            description: data.description,
                            dateCreated: data.dateCreated,
                        });
                        break;
                    case "FIXED":
                        widgetData.fixed.push({
                            id: data.id,
                            name: data.name,
                            alias: data.alias,
                            type: data.type,
                            description: data.description,
                            dateCreated: data.dateCreated,
                        });
                        break;
                    default:
                        break;
                }
            });


            setWidget(widgetData);
        }
    }, [listWidget]);

    return (
        <>
            <div className="card card-flush mb-5">
                <div className="card-header collapsible cursor-pointer rotate collapsed" data-bs-toggle="collapse" data-bs-target="#kt_card_collapsible_widget" style={{ justifyContent: "left", minHeight: "50px" }}>
                    <div className="card-toolbar rotate-90">
                        <span className="svg-icon svg-icon-1">
                            <span className="svg-icon svg-icon-dark svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z" fill="black" />
                                </svg>
                            </span>
                        </span>
                    </div>
                    <h5 className="card-title ms-3 fs-4">{intl.formatMessage({ id: "FORM.LABEL.DASHBOARD_WIDGET" })}</h5>
                </div>
                <div id="kt_card_collapsible_widget" className="collapse">
                    <div className="card-body pt-0">
                        <div className="form">
                            <div className="card-body">
                                {
                                    (displayWidgetLoading || listWidgetLoading) ?
                                        <div className="loading-overlay">
                                            <div className="loader"></div>
                                        </div>
                                        :
                                        <form>
                                            <div className="row mb-3">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <h5 className="font-weight-bold mb-0">{intl.formatMessage({ id: "FORM.LABEL.DISPLAY_ORDER_WIDGET" })}</h5>
                                                    </div>
                                                    <button type="button" onClick={() => saveDisplayWidget()} className={`btn btn-primary ${loadingSaveWidget ? "disabled" : ""}`} data-kt-indicator={loadingSaveWidget ? 'on' : 'off'}>
                                                        <span className="indicator-label">
                                                            {intl.formatMessage({ id: "FORM.ACTION.SAVE_WIDGET_ORDER" })}
                                                        </span>
                                                        <span className="indicator-progress">
                                                            Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>

                                            {intl.formatMessage({ id: "FORM.LABEL.STATIC" })}
                                            <div className="row mb-5">
                                                {
                                                    dataDisplayWidget?.static?.map((data: ArrayWidget, index: number) => {
                                                        return (
                                                            <CustomColumnWidgetOrder
                                                                index={index}
                                                                type="static"
                                                                data={data}
                                                                dataDisplayWidget={dataDisplayWidget}
                                                                setDataDisplayWidget={setDataDisplayWidget}
                                                                handleWidget={handleWidget}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>

                                            {intl.formatMessage({ id: "FORM.LABEL.MONTHLY" })}
                                            <div className="row mb-5">
                                                {
                                                    dataDisplayWidget?.monthly?.map((data: ArrayWidget, index: number) => {
                                                        return (
                                                            <CustomColumnWidgetOrder
                                                                index={index}
                                                                type="monthly"
                                                                data={data}
                                                                dataDisplayWidget={dataDisplayWidget}
                                                                setDataDisplayWidget={setDataDisplayWidget}
                                                                handleWidget={handleWidget}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>

                                            {intl.formatMessage({ id: "FORM.LABEL.PIE_CHART" })}
                                            <div className="row">
                                                {
                                                    dataDisplayWidget?.pieChart?.map((data: ArrayWidget, index: number) => {
                                                        return (
                                                            <CustomColumnWidgetOrder
                                                                index={index}
                                                                type="pieChart"
                                                                data={data}
                                                                dataDisplayWidget={dataDisplayWidget}
                                                                setDataDisplayWidget={setDataDisplayWidget}
                                                                handleWidget={handleWidget}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>

                                            {
                                                !(widget?.static?.length === dataDisplayWidget?.static?.length &&
                                                widget?.monthly?.length === dataDisplayWidget?.monthly?.length &&
                                                widget?.pieChart?.length === dataDisplayWidget?.pieChart?.length) &&
                                                <>
                                                    <div className="separator separator-dashed my-10"></div>
                                                    <small>*{intl.formatMessage({ id: "FORM.LABEL.CLICK_DISPLAY_WIDGET" })}</small><br />
                                                </>
                                            }

                                            {
                                                widget?.static?.length !== dataDisplayWidget?.static?.length && intl.formatMessage({ id: "FORM.LABEL.STATIC" })
                                            }
                                            <div className="row">
                                                {
                                                    widget?.static?.map((data: ArrayWidget, index: number) => {
                                                        if (!dataDisplayWidget?.static?.find((item) => item.id === data.id)) {
                                                            return (
                                                                <CustomColumnWidget
                                                                    index={index}
                                                                    type="static"
                                                                    data={data}
                                                                    widget={widget}
                                                                    setWidget={setWidget}
                                                                    handleWidget={handleWidget}
                                                                />
                                                            )
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div><br />

                                            {
                                                widget?.monthly?.length !== dataDisplayWidget?.monthly?.length && intl.formatMessage({ id: "FORM.LABEL.MONTHLY" })
                                            }
                                            <div className="row">
                                                {
                                                    widget?.monthly?.map((data: ArrayWidget, index: number) => {
                                                        if (!dataDisplayWidget?.monthly?.find((item) => item.id === data.id)) {
                                                            return (
                                                                <CustomColumnWidget
                                                                    index={index}
                                                                    type="monthly"
                                                                    data={data}
                                                                    widget={widget}
                                                                    setWidget={setWidget}
                                                                    handleWidget={handleWidget}
                                                                />
                                                            )
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div><br />

                                            {
                                                widget?.pieChart?.length !== dataDisplayWidget?.pieChart?.length && intl.formatMessage({ id: "FORM.LABEL.PIE_CHART" })
                                            }
                                            <div className="row">
                                                {
                                                    widget?.pieChart?.map((data: ArrayWidget, index: number) => {
                                                        if (!dataDisplayWidget?.pieChart?.find((item) => item.id === data.id)) {
                                                            return (
                                                                <CustomColumnWidget
                                                                    index={index}
                                                                    type="pieChart"
                                                                    data={data}
                                                                    widget={widget}
                                                                    setWidget={setWidget}
                                                                    handleWidget={handleWidget}
                                                                />
                                                            )
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div>
                                        </form>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="card card-flush">
                <div className="card-header collapsible cursor-pointer rotate collapsed" data-bs-toggle="collapse" data-bs-target="#kt_card_collapsible_banner" style={{ justifyContent: "left", minHeight: "50px" }}>
                    <div className="card-toolbar rotate-90">
                        <span className="svg-icon svg-icon-1">
                            <span className="svg-icon svg-icon-dark svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z" fill="black" />
                                </svg>
                            </span>
                        </span>
                    </div>
                    <h5 className="card-title ms-3 fs-4">{intl.formatMessage({ id: "FORM.LABEL.DASHBOARD_BANNER" })}</h5>
                </div>
                <div id="kt_card_collapsible_banner" className="collapse">
                    <div className="card-body pt-0">
                        <div className="form">
                            <div className="card-body">
                                <form>
                                    <div className="row mb-3">
                                        <div className="col-lg-12 d-flex align-items-center justify-content-between">
                                            <div>
                                                <h5 className="font-weight-bold mb-0">{intl.formatMessage({ id: "FORM.LABEL.BANNER_DISPLAY_SEQUENCE" })}</h5>
                                                <span>{intl.formatMessage({ id: "FORM.GUIDE.BANNER_DISPLAY_SEQUENCE" })}</span>
                                            </div>
                                            <button type="button" onClick={() => saveDisplayBanner()} className={`btn btn-primary ${loadingSaveBanner ? "disabled" : ""}`} data-kt-indicator={loadingSaveBanner ? 'on' : 'off'}>
                                                <span className="indicator-label">
                                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE_BANNER_SEQUENCE" })}
                                                </span>
                                                <span className="indicator-progress">
                                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="row mb-6" style={{ overflow: "auto", flexWrap: "unset", height: "150px" }}>
                                        {
                                            dataDisplayBanner.map((data: Banner) => (
                                                <div className="col-xl-3">
                                                    <div className="card-banner card-custom">
                                                        <div className="card-body ribbon ribbon-top" style={{ padding: "0px" }}>
                                                            <div className="ribbon-label bg-danger">
                                                                <i className="fas fa-times text-white p-1 cursor-pointer" onClick={() => { handleSpliceBanner(data) }}></i>
                                                            </div>
                                                            {
                                                                data.banner ?
                                                                    <img
                                                                        alt={data.name}
                                                                        className='h-100 w-100'
                                                                        style={{ maxHeight: "150px", minHeight: "150px" }}
                                                                        src={data.banner}
                                                                    />
                                                                    :
                                                                    <img
                                                                        className='h-100 w-100'
                                                                        style={{ maxHeight: "150px", minHeight: "150px" }}
                                                                        src={toAbsoluteUrl('/media/assets/no-image.png')}
                                                                        alt="No Img"
                                                                    />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="separator separator-dashed my-10"></div>

                                    <div className="row mb-3">
                                        <div className="col-lg-12 d-flex align-items-center justify-content-between">
                                            <h5 className="font-weight-bold">{intl.formatMessage({ id: "FORM.LABEL.BANNER_CATALOG" })}</h5>
                                            <button type="button" className="btn btn-sm btn-primary mt-5 mt-md-0" onClick={() => setShowInsertModal(true)} >
                                                <i className="fas fa-plus"></i> {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.BANNER" })}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {
                                            dataBanner.map((data: Banner, index: number) => (
                                                <div className="col-xl-3" key={index}>
                                                    <div className="card-banner card-custom">
                                                        <div className="card-body ribbon ribbon-top" style={{ padding: "0px" }}>
                                                            <div className="ribbon-label bg-danger">
                                                                <i className="fas fa-eye me-2 text-white p-1 cursor-pointer" onClick={() => { handleDisplayBanner(data) }}></i>
                                                                <i className="fas fa-edit me-2 text-white p-1 cursor-pointer" onClick={() => { setShowUpdateModal(true); setBanner(data) }}></i>
                                                                <i className="fas fa-trash text-white p-1 cursor-pointer" onClick={() => { setShowDeleteModal(true); setBanner(data) }}></i>
                                                            </div>
                                                            {
                                                                data.banner ?
                                                                    <img
                                                                        alt={data.name}
                                                                        className='h-100 w-100'
                                                                        style={{ maxHeight: "150px", minHeight: "150px" }}
                                                                        src={data.banner}
                                                                    />
                                                                    :
                                                                    <img
                                                                        className='h-100 w-100'
                                                                        style={{ maxHeight: "150px", minHeight: "150px" }}
                                                                        src={toAbsoluteUrl('/media/assets/no-image.png')}
                                                                        alt="No Img"
                                                                    />
                                                            }
                                                        </div>
                                                        <div className="card-footer" style={{ padding: "10px" }} dangerouslySetInnerHTML={{ __html: data.description }}>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showInsertModal && <AddBanner dataBanner={dataBanner} setDataBanner={setDataBanner} />
            }
            {
                showUpdateModal && <UpdateBanner dataBanner={dataBanner} banner={banner!} setDataBanner={setDataBanner} />
            }
            {
                showDeleteModal && <DeleteBanner dataBanner={dataBanner} banner={banner!} setDataBanner={setDataBanner} />
            }
        </>
    )
}

export { CustomFieldDashboard }