import axios from 'axios';
import React, { ReactNode, useEffect, useState } from 'react'
import DataTable, { TableColumn } from "react-data-table-component";
import { useIntl } from 'react-intl';
import useSWR, { mutate } from 'swr';
import moment from 'moment-timezone';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useEmailStore } from '../../../../stores/EmailStore';
import { KTSVG } from '../../../../_metronic/helpers';
import { handleCaps } from '../../../../functions/general';
import { useDataTableStore } from '../../../../stores/DataTableStore';
import { BroadcastEmailTypes } from '../../../../interfaces/Email/BroadCastEmailType';

interface MasterTableEmailProps {
    tableColumns: TableColumn<any>[]
    actions?: ReactNode[]
    tableKey: string
    apiURL: string
}

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL

const isDate = (value: any): value is Date | string => {
    return moment(value, moment.ISO_8601, true).isValid();
};

const MasterTableEmail = ({ actions, tableColumns, tableKey, apiURL }: MasterTableEmailProps) => {
    const { tablesData, setTablesData, filteredTablesData, setFilteredTablesData, selectedFilters, setFilterTextTable, filterTextTable } = useDataTableStore();
    const { setTypeEmail, isDetailActive, setIsDetailActive, idDetailBroadcastEmail } = useEmailStore()
    const [filterText, setFilterText] = useState('');

    const intl = useIntl()

    const fetcher = async (url: string) => {
        const response = await axios.get(url, {
            headers: {
                'X-Auth-token': `Bearer ${localStorage.getItem('token_email')}`
            }
        })
        return response.data.data
    }

    const { data = [], error, isLoading, isValidating, mutate } = useSWR(API_URL_EMAIL + apiURL, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })

    useEffect(() => {
        if (!isLoading) {
            if (tableKey === 'detailDraftBroadcastEmail') {
                const filterContentById = tablesData.draftBroadcastEmail.filter((item: BroadcastEmailTypes) => item.id === idDetailBroadcastEmail)
                setTablesData(tableKey, filterContentById[0].content)
            } else {
                const newDataWihtoutUpdateDate = data.map((item: any) => { // mapping ulang untuk menghindari unexpected search filter
                    delete item.updatedAt
                    return item
                })
                setTablesData(tableKey, newDataWihtoutUpdateDate)
            }
        }
    }, [isValidating])

    useEffect(() => {
        if (tablesData[tableKey]) {
            const filteredData = tablesData[tableKey].filter((item) => {
                if (filterText) {
                    const filterWords = filterText.split(' ');

                    const isTextInValues = Object.values(item).some(val => {
                        if (isDate(val)) {
                            const formattedDate = moment(val).format('DD MMMM YYYY');
                            return filterWords.every(word => {
                                const regexText = '.*' + word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '.*';
                                const regex = new RegExp(regexText, 'i');
                                return regex.test(formattedDate);
                            });
                        }
                        return filterWords.every(word => {
                            const regexText = '.*' + word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '.*';
                            const regex = new RegExp(regexText, 'i');
                            return regex.test(String(val));
                        });
                    });

                    if (!isTextInValues) {
                        return false;
                    }
                }

                return true
            })
            setFilteredTablesData(tableKey, filteredData)
        }
    }, [filterText, tablesData, selectedFilters]);

    useEffect(() => {
        setFilterText(filterTextTable[tableKey])
    }, [filterTextTable])

    if (error) return (
        <div className="container mt-5 p-0">
            <div className="alert alert-danger" role="alert">
                <h1>Error</h1>
                <span className="lead">{error.response.data.message}</span>
            </div>
        </div>
    )

    return (
        <div className="card rounded" style={{ overflowY: 'hidden' }}>
            {
                (isLoading || isValidating) &&
                <div className="loading-overlay">
                    <div className="loader"></div>
                </div>
            }
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {
                        isDetailActive && (
                            <span
                                className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                                onClick={() => {
                                    if (tableKey === 'detailGroupEmail') {
                                        setTypeEmail('groupEmail')
                                    } else if (tableKey === 'detailBroadcastEmail') {
                                        setTypeEmail('broadcastEmail')
                                    } else if (tableKey === 'detailDraftBroadcastEmail') {
                                        setTypeEmail('broadcastEmail')
                                    }
                                    setIsDetailActive(false)
                                }}
                            >
                                <span className='svg-icon svg-icon-1 m-0'>
                                    <KTSVG path='/media/icons/duotune/arrows/arr063.svg' />
                                </span>
                            </span>
                        )
                    }
                    <div className="d-flex align-items-center position-relative my-1 skeleton-button" id="divSearch">
                        <span className="svg-icon svg-icon-1 position-absolute ms-6">
                            <KTSVG path="/media/icons/duotune/general/gen021.svg" className='my-auto' />
                        </span>
                        <input
                            type="text"
                            data-kt-lists-table-filter="search"
                            className="form-control form-control-solid w-210px ps-15 skeleton"
                            placeholder={intl.formatMessage({ id: 'MASTERS.DATATABLE.SEARCH.PLACEHOLDER' })}
                            id="inputSearch"
                            value={filterText}
                            onChange={e => { setFilterText(e.target.value); setFilterTextTable(tableKey, e.target.value) }}
                            disabled={isLoading}
                            onInput={(e) => handleCaps(e)}
                        />
                        <OverlayTrigger
                            key='refresh'
                            placement='bottom'
                            overlay={<Tooltip id='tooltip-refresh'>Refresh</Tooltip>}
                        >
                            <span
                                id='refresh'
                                className='btn btn-sm btn-icon btn-active-light-primary cursor-pointer ms-3'
                                data-toggle='tooltip'
                                data-placement='top'
                                title=''
                                onClick={() => {
                                    mutate()
                                }}
                            >
                                <span className='svg-icon svg-icon-2'>
                                    <KTSVG path='media/icons/duotune/arrows/arr029.svg' />
                                </span>
                            </span>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between gap-3'>
                    {
                        actions && actions.map((action, index) => {
                            return (
                                <div key={index} className='d-flex justify-content-center align-items-center'>
                                    {action}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="card-body pt-0"
                style={{ maxWidth: '1150px', overflowX: 'auto' }}>
                <DataTable
                    columns={tableColumns}
                    data={filteredTablesData[tableKey]!}
                    pagination
                />
            </div>
        </div>
    )
}

export default MasterTableEmail