import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const CHAT_LABEL = `${API_URL}/chat/labels`

export const insertChatLabel = (name: string, color: string, token: string) => {
    return axios.post(CHAT_LABEL,
        {
           name,
           color
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const updateChatLabel = (id: string, name: string, color: string, token: string) => {
    return axios.put(CHAT_LABEL+"/"+id,
        {
           name,
           color
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const deleteChatLabel = (id: string, token: string) => {
    return axios.delete(CHAT_LABEL+"/"+id,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const assignContactChatLabel = (labels: { [key: string]: string[] }, token: string) => {
    return axios.post(CHAT_LABEL+"/assign",
        {
            labels
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}
