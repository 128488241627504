import React, { useEffect } from 'react'
import { formatFileSize } from '../../functions/email'

interface PreviewAttachmentProps {
  filename: string
  size: number
  srcPreview?: string
  onClick: () => void
}

const PreviewAttachment = ({ filename, size, srcPreview, onClick }: PreviewAttachmentProps) => {
  let assignFileUndefined = ''

  if (!filename) {
    assignFileUndefined += 'unknown.eml'
  }

  const getExtension = filename?.split('.')[1]

  const getSrcPreview = () => {
    if (getExtension === 'pdf' || getExtension === 'PDF') {
      return '/media/logos/iconPdf.svg'
    } else if (getExtension === 'xlsx' || getExtension === 'xlx') {
      return '/media/logos/xlxscIcon.svg'
    } else if (getExtension === 'pdf') {
      return '/media/logos/iconPdf.svg'
    } else if (getExtension === 'docx' || getExtension === 'doc') {
      return '/media/logos/docIcon.svg'
    } else if (getExtension === 'ppt' || getExtension === 'pptx') {
      return '/media/logos/pptIcon.svg'
    } else {
      return '/media/logos/documentIcon.svg'
    }
  }


  const removeExtension = filename?.split('.')[0]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '220px',
        height: '150px',
        border: '1px solid #ccc',
        borderRadius: '2px',
        overflow: 'hidden',
        position: 'relative',
        marginTop: '5px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {srcPreview ? (
          <img
            src={srcPreview}
            alt={filename}
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
          />
        ) : (
          <img
            src={getSrcPreview()}
            alt={filename}
            style={{ maxWidth: '56%', maxHeight: '56%', objectFit: 'contain' }}
          />
        )}

        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '5px',
            backgroundColor: '#F5F5F5',
            zIndex: 99,
          }}
          className='d-flex justify-content-between px-2 align-items-center py-2'
        >
          <img
            src={srcPreview ? '/media/logos/image.svg' : getSrcPreview()}
            alt='icon'
            style={{
              width: '20px',
              height: '20px',
              marginRight: '10px',
              verticalAlign: 'middle',
            }}
          />
          <h6
            style={{
              margin: 0,
              fontSize: '10px',
              fontWeight: 'bold',
              lineHeight: '20px',
              verticalAlign: 'middle',
            }}
          >
            {
              !filename ? (
                <>
                  {assignFileUndefined}
                  <span style={{ opacity: 0.5 }}>({formatFileSize(size)})</span>
                </>
              ) : (
                filename?.length > 12 ? (
                  <>
                    {removeExtension.slice(0, 12)}.{getExtension}
                    <span style={{ opacity: 0.5 }}>({formatFileSize(size)})</span>
                  </>
                ) : (
                  <>
                    {removeExtension}.{getExtension}{' '}
                    <span style={{ opacity: 0.5 }}>({formatFileSize(size)})</span>
                  </>
                )
              )
            }
          </h6>
          {
            filename &&
            <span style={{ marginLeft: '15px' }} className='cursor-pointer' onClick={onClick}>
              <img
                style={{ width: '17px', height: '17px' }}
                src='/media/logos/downloadIcon.svg'
                alt='download-icon'
              />
            </span>
          }
        </div>
      </div>
    </div>
  )
}

export default PreviewAttachment
