import { create } from 'zustand';
import { Pipeline } from '../interfaces/Pipeline';
import { SettingMultiPipelines } from '../interfaces/Settings';

type PipelineState = {
    pipelines: Pipeline[];
    pipelineLoading: boolean;
    multiPipelines: SettingMultiPipelines[];
    selectMultiPipeline: SettingMultiPipelines;
    setPipelineLoading: (pipelineLoading: boolean) => void;
    setPipelines: (pipelines: Pipeline[]) => void;
    setMultiPipelines: (multiPipelines: SettingMultiPipelines[]) => void;
    setSelectMultiPipeline: (selectMultiPipeline: SettingMultiPipelines) => void;
}

export const usePipelineStore = create<PipelineState>((set, getState) => ({
    pipelines: [],
    pipelineLoading: false,
    multiPipelines: [],
    selectMultiPipeline: {} as SettingMultiPipelines,

    setPipelines: (pipelines) => {
        set({ pipelines });
    },
    setPipelineLoading: (pipelineLoading) => {
        set({ pipelineLoading });
    },
    setMultiPipelines: (multiPipelines) => {
        set({ multiPipelines });
    },
    setSelectMultiPipeline: (selectMultiPipeline) => {
        set({ selectMultiPipeline });
    },
}));