import axios from "axios";
import { CompanyType } from "../hooks/useShowCompanyById";

const WA_URL = 'https://wa.looyal.id/'
const WOOBLAZZ_URL = 'https://apiw.looyal.id/'
const API_URL = process.env.REACT_APP_API_URL

const CHECK_SESSION = 'chat/check-session-status'
const WOOBLAZZ_LOGIN = 'v3/broadcast/get_otp'
const WOOBLAZZ_VERIFY = 'v3/broadcast/submit_otp'
const AGREE_TNC = 'user-agree-chat-tnc'


export const checkSession = async (token: string, customToken?: string, company?: CompanyType | null) => {
    return axios.get(API_URL + `chat/check-session-status${customToken ? `?${company}Token=${customToken}` : ''}`, {
        headers: {
            'x-auth-token': token
        }
    })
}

export const loginWooblazz = async (phoneNumber: string) => {
    return axios.post(WOOBLAZZ_URL + WOOBLAZZ_LOGIN, { phone: phoneNumber })
}

export const verifyWooblazz = async (phoneNumber: string, otp: string) => {
    return axios.post(WOOBLAZZ_URL + WOOBLAZZ_VERIFY, { phone: phoneNumber, otp: otp })
}

export const agreeTNC = async (token: string) => {
    return axios.post(API_URL + AGREE_TNC, {}, {
        headers: {
            'x-auth-token': token
        }
    })
}

export const downloadMedia = async (message_id: string, token: string) => {
    return axios.get(
        API_URL + `chat/${message_id}/attachment`,
        {
            headers: {
                'x-auth-token': token
            }
        }
    )
}

export const generateQR = async (mixedEncoded: string) => {
    return axios.get(WA_URL + `scan/number/${mixedEncoded}`)
}