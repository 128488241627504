import { FC, ReactNode, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Modal } from "bootstrap";
import useUser from "../../../hooks/User";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReimburseCategory } from "../../../interfaces/ReimbursementCategory";
import { DeleteReimbursementCategory } from "../../../components/ReimbursementCategory/DeleteReimbursementCategory";
import { AddReimbursementCategory } from "../../../components/ReimbursementCategory/AddReimbursementCategory";
import { UpdateReimbursementCategory } from "../../../components/ReimbursementCategory/UpdateReimbursementCategory";
import { useIntl } from "react-intl";

const ReimbursementCategories: FC = () => {

    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [category, setCategory] = useState<ReimburseCategory>();
    const user = useUser();
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const intl = useIntl()

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const tableColumns: TableColumn<ReimburseCategory>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '70px',
            cell: (row) => {
                return (
                    <>
                        {
                            // (
                            //     ((row.creator_id === user.data.id) || user.data.is_superadmin) ||
                            //     (user.data.permission_pipeline_progress_update !== 'NONE' || user.data.permission_pipeline_progress_delete !== 'NONE')
                            // ) &&
                            <>
                                <IconButton
                                    aria-label={`more-${row.id}`}
                                    id={`menu-anchor-${row.id}`}
                                    onClick={() => handleClick(row.id)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    id={`menu-${row.id}`}
                                    anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                                    open={openMenuId === row.id}
                                    onClose={handleClose}
                                    disableScrollLock={true}
                                >
                                    {
                                        // (
                                        //     ((row.creator_id === user.data.id) || user.data.is_superadmin) ||
                                        //     (user.data.permission_pipeline_progress_update !== 'NONE')
                                        // ) &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowUpdateModal(true);
                                            setCategory(row);
                                        }}>
                                            <i className="fas fa-edit fs-4 me-4"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                                        </MenuItem>
                                    }

                                    {
                                        // (
                                        //     ((row.creator_id === user.data.id) || user.data.is_superadmin) ||
                                        //     (user.data.permission_pipeline_progress_delete !== 'NONE')
                                        // ) &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowDeleteModal(true);
                                            setCategory(row);
                                        }}>
                                            <i className="fas fa-trash fs-3 me-5"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                                        </MenuItem>
                                    }
                                </Menu>
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            // width: '25%',
            selector: row => row.name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.name
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COLOR' }),
            // width: '75px',
            center: true,
            selector: row => row.color,
            cell: row => {
                return (
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '6px',
                            backgroundColor: row.color,
                        }}
                    />
                );
            },
        }
    ];

    let actions: ReactNode[] = []
    actions.push(<AddReimbursementCategory />)

    useEffect(() => {
        if (showUpdateModal && category) {
            const modalElement = document.getElementById(`update-category-modal-${category.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, category, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal && category) {
            const modalElement = document.getElementById(`delete-category-modal-${category.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, category, setShowDeleteModal]);

    return (
        <>
            <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'FORM.MENU.CATEGORIES' })}</li>
            </ol>
            <MasterDataTable
                tableKey="reimbursement-categories"
                tableColumns={tableColumns}
                actions={actions}
                apiURL="reimbursement-categories"
            />
            {
                showUpdateModal && <UpdateReimbursementCategory category={category!} />
            }
            {
                showDeleteModal && <DeleteReimbursementCategory category={category!} />
            }

        </>
    )
}

export { ReimbursementCategories }