import { useEffect, useState } from "react";
import { useChatStore } from "../../../stores/ChatStore";
import { Modal } from "bootstrap";
import { useIntl } from "react-intl";
import { AddQuickReply } from "../Modal/QuickReply/AddQuickReply";
import { DeleteQuickReply } from "../Modal/QuickReply/DeleteQuickReply";
import { QuickReply } from "../../../interfaces/Chat/QuickReply";
import { UpdateQuickReply } from "../Modal/QuickReply/UpdateQuickReply";
import { getFileType, getRandomFileName, isFile, isValidTypeAttachment, isVideo } from "../../../functions/general";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const ChatQuickReplySetting = () => {
    const intl = useIntl();
    const { quickReplies, setMenuActive } = useChatStore();
    const [quickReply, setQuickReply] = useState<QuickReply>();
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [expandedMessages, setExpandedMessages] = useState<{ [key: number]: boolean }>({});

    const toggleMessage = (index: number) => {
        setExpandedMessages((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    useEffect(() => {
        if (showAddModal) {
            const modalElement = document.getElementById(`add-chat-quick-reply-modal`);
            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();
                const handleModalHide = () => setShowAddModal(false);
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);
                return () => modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
            }
        }
    }, [showAddModal]);

    useEffect(() => {
        if (showUpdateModal) {
            const modalElement = document.getElementById(`update-chat-quick-reply-modal`);
            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();
                const handleModalHide = () => setShowUpdateModal(false);
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);
                return () => modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
            }
        }
    }, [showUpdateModal]);

    useEffect(() => {
        if (showDeleteModal) {
            const modalElement = document.getElementById(`delete-chat-quick-reply-modal`);
            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();
                const handleModalHide = () => setShowDeleteModal(false);
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);
                return () => modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
            }
        }
    }, [showDeleteModal]);

    const handleUpdateQuickReply = (quickReply: QuickReply) => {
        setQuickReply(quickReply);
        setShowUpdateModal(true);
    };

    const handleDeleteQuickReply = (quickReply: QuickReply) => {
        setQuickReply(quickReply);
        setShowDeleteModal(true);
    };

    return (
        <div className='card card-flush w-100 p-10' style={{ maxHeight: "calc(100vh - 1rem)", height: "calc(100vh - 1rem)" }}>
            <div className="d-flex align-items-center my-1 mb-10 justify-content-between">
                <div className="d-flex align-items-center gap-5">
                    <i className="p-3 cursor-pointer fas fa-solid fa-arrow-left fs-3" onClick={() => setMenuActive("settings")}></i>
                    <h1 className="mb-0 text-dark fw-bolder fs-1">Balas Cepat</h1>
                </div>
                <i className="p-3 cursor-pointer fas fa-solid fa-plus fs-3" onClick={() => setShowAddModal(true)}></i>
            </div>
            <div style={{ overflowY: "scroll", height: "100%" }}>
                {
                    quickReplies.length === 0 ?
                        <div className="text-center">
                            <h1 className="text-gray-400">Tidak ada balasan cepat</h1>
                        </div>
                        :
                        quickReplies.map((quickReply, index) => (
                            <>
                                {/* {index !== 0 && <div className="separator separator-dashed my-4"></div>} */}
                                <div
                                    className="d-flex align-items-center p-3 rounded-2 bg-hover-light cursor-pointer"
                                    key={index}
                                >
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                        <div className="mx-5 w-100">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="w-100" onClick={() => toggleMessage(index)}>
                                                    <div className="text-gray-800 fw-bolder fs-3">{quickReply.shortcut}</div>
                                                    <div className="text-gray-600 fs-4" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                                        {expandedMessages[index] || quickReply.message.length <= (quickReply.attachments ? 30 : 40)
                                                            ? quickReply.message
                                                            : `${quickReply.message.slice(0, quickReply.attachments ? 30 : 40)}...`}
                                                    </div>
                                                </div>
                                                <div className="dropdown-center dropdown">
                                                    <span className="badge px-3" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fs-8 fa-ellipsis-v"></i>
                                                    </span>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <div className="dropdown-item cursor-pointer" onClick={() => handleUpdateQuickReply(quickReply)}>
                                                                {intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="dropdown-item cursor-pointer" onClick={() => handleDeleteQuickReply(quickReply)}>
                                                                {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))
                }
            </div>

            {showAddModal && <AddQuickReply />}
            {showUpdateModal && quickReply && <UpdateQuickReply quickReply={quickReply} />}
            {showDeleteModal && quickReply && <DeleteQuickReply quickReply={quickReply} />}
        </div>
    );
};

export { ChatQuickReplySetting };