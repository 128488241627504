import { FC, ReactNode, useEffect, useRef, useState } from "react";
import moment from "moment";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { UserModel } from "../../modules/auth/models/UserModel";
import { RootState } from "../../../setup";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { User } from "../../../interfaces/User";
import { AddUser } from "../../../components/user/AddUser";
import { UpdateUser } from "../../../components/user/UpdateUser";
import { DeleteUser } from "../../../components/user/DeleteUser";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDataTableStore } from "../../../stores/DataTableStore";
import { useCompanyStore } from "../../../stores/CompanyStore";
import { useIntl } from "react-intl";
import { customNumberFormat, handleCaps } from "../../../functions/general";
import Pagination from "../../../components/Pagination";
import { Dropdown } from "rsuite";
import { DataTableImport } from "../../../components/DataTable/DataTableImport";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";

const renderIconButton = (props: any, ref: any) => {
    return (
        <button {...props} ref={ref} style={{ backgroundColor: "white" }}>
            <i className={`fas fs-8 fa-ellipsis-v`}></i>
        </button>
    );
};

interface MasterCardUserProps {
    dataUser: any
    setUserData: any
    setShowUpdateUserModal: (value: boolean) => void
    setShowDeleteUserModal: (value: boolean) => void
}

const MasterCardUsers: FC<MasterCardUserProps> = ({dataUser, setUserData, setShowUpdateUserModal, setShowDeleteUserModal}) => {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const intl = useIntl()


    return (
        <div className="col-xl-6 col-xxl-4">
            <div className="card h-100">
                <div className="mx-5 mt-5">
                    <div style={{ float: "left", marginTop: "7px" }} className="badge badge-light">{dataUser.leader_name}</div>
                    <div style={{ float: "right" }}>
                        <Dropdown renderToggle={renderIconButton} placement="bottomEnd">
                            {
                                (
                                    (dataUser.id === user.data.id || user.data.is_superadmin) ||
                                    (user.data.permission_user_update !== 'NONE') && user.data.is_superadmin
                                ) &&
                                <Dropdown.Item onClick={() => { setShowUpdateUserModal(true); setUserData(dataUser) }}>
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </Dropdown.Item>
                            }

                            {
                                (
                                    (dataUser.id === user.data.id || user.data.is_superadmin) ||
                                    (user.data.permission_user_delete !== 'NONE') && user.data.is_superadmin
                                ) &&
                                <Dropdown.Item onClick={() => { setShowDeleteUserModal(true); setUserData(dataUser) }}>
                                    {intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}
                                </Dropdown.Item>
                            }
                        </Dropdown>
                    </div>
                </div>

                <div className="card-body d-flex flex-center flex-column p-9">
                    <div className="symbol symbol-65px symbol-circle mb-5">
                        {
                            dataUser.profile_picture ?
                                <img src={dataUser.profile_picture} alt="img" style={{ width: '60px', height: '60px', objectFit: 'cover' }} />
                                :
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'gray',
                                    borderRadius: '50%',
                                    width: '60px',
                                    height: '60px',
                                    float: "left"
                                }}>
                                    <label style={{ color: 'white' }}>
                                        {(dataUser.name).toUpperCase().split(' ').slice(0, 2).map((word: string) => word.charAt(0)).join('')}
                                    </label>
                                </div>
                        }
                    </div>
                    <label className="fs-4 text-gray-800 fw-bolder mb-0">{dataUser.name}</label>
                    <div className="fw-bold text-gray-600 mb-6">{dataUser.role_name}</div>
                    <div className="d-flex d-grid gap-5">
                        <div className="d-flex flex-column flex-shrink-0 me-4" style={{ maxWidth: "50%" }}>
                            <span className="d-flex align-items-center fs-7 fw-bold text-black mb-2">
                                <span className="svg-icon svg-icon-4 me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="black"></path>
                                        <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="black"></path>
                                    </svg>
                                </span>
                                <span>{dataUser.city_name}</span>
                            </span>
                            <span className="d-flex align-items-center text-black fw-bold fs-7">
                                <span className="svg-icon svg-icon-4 me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
                                        <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
                                    </svg>
                                </span>
                                {dataUser.phone}
                            </span>
                        </div>
                        <div className="d-flex flex-column flex-shrink-0" style={{ maxWidth: "50%" }}>
                            <span className="d-flex align-items-center fs-7 fw-bold text-black mb-2">
                                <span className="svg-icon svg-icon-6 svg-icon-gray-600 me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M22 7H2V11H22V7Z" fill="black" />
                                        <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z" fill="black" />
                                    </svg>
                                </span>
                                <span>{dataUser.nik ?? "-"}</span>
                            </span>
                            <span className="d-flex align-items-center text-black fw-bold fs-7">
                                <span className="svg-icon svg-icon-6 svg-icon-gray-600 me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" width="12" height="2" rx="1" transform="matrix(-1 0 0 1 15.5 11)" fill="black" />
                                        <path d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z" fill="black" />
                                        <path d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z" fill="#C4C4C4" />
                                    </svg>
                                </span>
                                <span>{moment(dataUser.date_joined).format("DD MMM YYYY")}</span>
                            </span>
                        </div>
                    </div>
                    <div className="separator separator-dashed mb-9"></div>
                    <div className="text-center">
                        Usename : {dataUser.username ?? "-"}
                    </div>
                    <div className="text-center">
                        {intl.formatMessage({ id: 'FORM.LABEL.ATTENDANCE_TEMPLATE' })} : {dataUser.attendanceTemplateName ?? "-"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { MasterCardUsers }