import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import moment from "moment";
import { insertTemplateAttendance } from "../../api/AttendanceCRUD";
import { DatePicker } from "rsuite";
import { Map, Marker, GeoJson, GeoJsonFeature } from "pigeon-maps";
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps } from "../../functions/general";
import TimeIcon from '@rsuite/icons/Time';
import AsyncSelect from 'react-select/async'
import { useMemo } from 'react';
import { UpdateWorkHours } from "./UpdateWorkHours";
import { Modal } from "bootstrap";
import Checkbox from "./Checkbox";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
interface SelectOption {
    value: string
    label: string
}

const customNumberFormat = (valueString: string) => {
    const onlyNumbers = valueString.replace(/\D+/g, '');  // remove non-digits
    const parts = onlyNumbers.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
}

const AddAttendanceTemplate: FC = () => {
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()
    const [latitudeMap, setLatitudeMap] = useState<number>(0);
    const [longitudeMap, setLongitudeMap] = useState<number>(0);
    const color = `hsl(${0 % 360}deg 39% 70%)`
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [geoJsonFeatureLocation, setGeoJsonFeatureLocation] = useState<Object>({})
    const [radius, setRadius] = useState<number>(0)
    const [showUpdateHoursModal, setShowUpdateHoursModal] = useState<boolean>(false);

    const intl = useIntl()

    const daysOrders = [
        {
            id: 0,
            name: intl.formatMessage({ id: 'SUNDAY' }),
            active: false
        },
        {
            id: 1,
            name: intl.formatMessage({ id: 'MONDAY' }),
            active: false
        },
        {
            id: 2,
            name: intl.formatMessage({ id: 'TUESDAY' }),
            active: false
        },
        {
            id: 3,
            name: intl.formatMessage({ id: 'WEDNESDAY' }),
            active: false
        },
        {
            id: 4,
            name: intl.formatMessage({ id: 'THURSDAY' }),
            active: false
        },
        {
            id: 5,
            name: intl.formatMessage({ id: 'FRIDAY' }),
            active: false
        },
        {
            id: 6,
            name: intl.formatMessage({ id: 'SATURDAY' }),
            active: false
        }
    ];

    const [days, setDays] = useState(daysOrders);
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const checkNullArray = (array: any[]) => {
        return array.every(function (v) { return v === null || v === '' || v === undefined; })
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(`${intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, {title: intl.formatMessage({ id: 'FORM.LABEL.NAME' })})}`),
        hourStart: Yup.array().of(Yup.string().nullable()).min(7, intl.formatMessage({id: "FORM.VALIDATION.NOT_COMPLETE"}, {title: intl.formatMessage({id: "FORM.LABEL.CLOCK_IN_HOUR"})}))
            .when('tolerance', {
                is: (tolerance: any) => tolerance && tolerance.length > 0,
                then: Yup.array().of(Yup.string().nullable()).min(7, intl.formatMessage({id: "FORM.VALIDATION.NOT_COMPLETE"}, {title: intl.formatMessage({id: "FORM.LABEL.CLOCK_IN_HOUR"})}))
                    .test('is-array-null', intl.formatMessage({id: "FORM.VALIDATION.ATTENDANCE.1"}), value => {
                        return value ? !checkNullArray(value) : true;  // If value is present, then check format
                    })
            }),
        // hourEnd: Yup.array().of(Yup.string().required('Jam keluar harus diisi').nullable()).min(7, "Jam keluar tidak lengkap"),
        coordinate: Yup.mixed()
            .when('radius', {
                is: (radius: any) => radius && radius.length > 0,
                then: Yup.mixed().required(intl.formatMessage({id: "FORM.VALIDATION.ATTENDANCE.2"}))
            }),
        tolerance: Yup.string()
            .test('is-numeric', 'Invalid format', value => {
                return value ? !isNaN(Number(value.replace(/\./g, ''))) : true;  // If value is present, then check format
            }),
        radius: Yup.string()
            .test('is-numeric', 'Invalid format', value => {
                return value ? !isNaN(Number(value.replace(/\./g, ''))) : true;  // If value is present, then check format
            })
    });

    const initialValues = {
        name: '',
        hourStart: ['', '', '', '', '', '', ''],
        hourEnd: ['', '', '', '', '', '', ''],
        location: '',
        coordinate: '',
        tolerance: '',
        radius: '',
    }

    const handleToleranceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = customNumberFormat(e.target.value);
        formik.setFieldValue('tolerance', formattedValue);
    };

    const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = customNumberFormat(e.target.value);
        formik.setFieldValue('radius', formattedValue);
        setRadius(Number(e.target.value.replace(/\./g, '')))
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)

            try {
                const timeClockIn = values.hourStart.map(hour => (
                    hour ? moment(hour).format("HH:mm:ss") : null
                ));
                const timeClockInTolerance = values.hourStart.map(hour => (
                    hour ? moment(hour).add(parseInt((values.tolerance ?? 0).split('.').join('')), 'minutes').format("HH:mm:ss") : null
                ));
                const timeClockOut = values.hourEnd.map(hour => (
                    hour ? moment(hour).format("HH:mm:ss") : null
                ));

                const latitude = values.coordinate ? parseFloat(values.coordinate.split(", ")[0]) : null;
                const longitude = values.coordinate ? parseFloat(values.coordinate.split(", ")[1]) : null;

                const latitudeArray = Array(7).fill(latitude);
                const longitudeArray = Array(7).fill(longitude);

                const radiusArray = Array(7).fill(values.radius ? parseInt((values.radius).split('.').join('')) : null);

                const response = await insertTemplateAttendance(
                    values.name,
                    timeClockIn,
                    timeClockInTolerance,
                    timeClockOut,
                    latitudeArray,
                    longitudeArray,
                    latitudeArray,
                    longitudeArray,
                    radiusArray,
                    radiusArray,
                    values.location,
                    values.location,
                    token
                )
                if (response.data.success) {
                    Swal.fire({
                        icon: "success",
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" }),
                        heightAuto: false,
                    })
                        .then(() => {
                            closeModalRef.current?.click();
                        })
                    closeModalRef.current?.click();
                    setTablesData('attendance-templates', ([response.data.data, ...tablesData['attendance-templates']]));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            if (!showUpdateHoursModal) {
                formik.resetForm()
                formik.setFieldValue('hourStart', ['', '', '', '', '', '', ''])
                formik.setFieldValue('hourEnd', ['', '', '', '', '', '', ''])
                formik.setFieldValue('location', '')
                setOptions([])
                setLatitudeMap(0)
                setLongitudeMap(0)
                setDays(daysOrders)
                setSelectAll(false)
            }
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik, showUpdateHoursModal, setShowUpdateHoursModal]);

    useEffect(() => {
        if (formik.values.location) {
            fetch('https://tool.looyal.id/general/gmaps/latlong?address=' + formik.values.location)
                .then(response => response.json())
                .then(data => {
                    setLatitudeMap(data.data.lat);
                    setLongitudeMap(data.data.lon)
                    formik.setFieldValue("coordinate", data.data.lat + ", " + data.data.lon);
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }, [formik.values.location]);

    const loadOptions = useMemo(() => {
        return async (inputValue: any) => {
            if (inputValue) {
                const res = await fetch('https://tool.looyal.id/general/gmaps/address?search=' + inputValue);
                const data = await res.json();
                const results = data.data;

                if (results.length === 0) {
                    return [];
                }
                else {
                    const optionData = results.map((option: string) => ({
                        value: option,
                        label: option
                    }));

                    setOptions(optionData);
                    return optionData
                }
            }
        };
    }, [formik.values.location, setOptions]);

    useEffect(() => {
        if (radius > 0) {
            const circleToPolygon = require("circle-to-polygon");

            const coordinates = [longitudeMap, latitudeMap]; //[lon, lat]
            const numberOfEdges = 32; //optional that defaults to 32

            let polygon = circleToPolygon(coordinates, radius, numberOfEdges);

            setGeoJsonFeatureLocation({
                type: "Feature",
                geometry: polygon,
                properties: { prop0: "value0" },
            });
        }
    }, [radius, latitudeMap, longitudeMap])

    const changeAllHours = (hourStart: string, hourEnd: string) => {
        for (let i = 0; i < 7; i++) {
            if (hourStart && days[i].active) formik.setFieldValue(`hourStart[${i}]`, hourStart)
            else formik.setFieldValue(`hourStart[${i}]`, formik.values.hourStart[i] ?? null)

            if (hourEnd && days[i].active) formik.setFieldValue(`hourEnd[${i}]`, hourEnd)
            else formik.setFieldValue(`hourEnd[${i}]`, formik.values.hourEnd[i] ?? null)
        }
        setDays(daysOrders)
        setSelectAll(false)
    }

    useEffect(() => {
        if (showUpdateHoursModal) {
            const modalElement = document.getElementById(`update-work-hours-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateHoursModal(false);
                    document.getElementById(`tambah-attendance-button`)?.click()
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateHoursModal, setShowUpdateHoursModal]);

    const handleDayClick = (id: number) => {
        const daysCopy = [...days];
        const dayToUpdate = daysCopy.find((day) => day.id === id);

        if (dayToUpdate) dayToUpdate.active = !dayToUpdate.active;
        if (dayToUpdate && !dayToUpdate.active && selectAll) {
            handleSelectAll();
        }
        setDays(daysCopy);

        let flag = true;
        for (let i = 0; i < days.length; i++) {
            flag = flag && days[i].active;
        }

        if (flag && !selectAll) {
            handleSelectAll();
        }
    };

    const handleSelectAll = () => {
        setSelectAll((prevState) => !prevState);

        setDays(days.map((day) => ({ ...day, active: !selectAll })));
    };

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <button type="button"
                className="btn btn-primary mt-3"
                data-bs-toggle="modal"
                data-bs-target="#tambah-attendance-modal"
                id="tambah-attendance-button"
            >
                <i className="fas fa-plus"></i> {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.ATTENDANCE_TEMPLATE" })}
            </button>
            <div className="modal fade" tabIndex={-1} id="tambah-attendance-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.ATTENDANCE_TEMPLATE" })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                {/* name */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.NAME" })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.name}</span>
                                    </div>
                                )}
                                {/* Hour */}
                                <label className='form-label fs-6 fw-bolder text-dark mb-3'>{intl.formatMessage({id: "FORM.LABEL.WORK_HOUR"})}</label>
                                <div className="row mb-2 bg-secondary align-items-center">
                                    <div className="col-form-label col-1 form-check form-check-custom justify-content-center pe-0">
                                        <Checkbox id={8} checked={selectAll} onChange={handleSelectAll} />
                                    </div>
                                    <div className="col-11">
                                        <button type="button" className={"btn btn-sm my-2 btn-outline btn-outline-dark btn-active-dark bg-white " + (days.every(day => !day.active) ? "disabled" : "")} data-bs-dismiss="modal" onClick={() => {
                                            setShowUpdateHoursModal(true);
                                        }}>{intl.formatMessage({id: "FORM.ACTION.MASS_UPDATE"})}</button>
                                    </div>
                                </div>
                                <div className="mb-1">
                                    {
                                        daysOrders.map((day) => (
                                            <div className="row mb-2" key={day.id}>
                                                <div className="col-form-label col-1 form-check form-check-custom justify-content-center pe-0">
                                                    <Checkbox id={day.id} checked={days.find((x) => x.id === day.id)?.active} onChange={() => handleDayClick(day.id)} />
                                                </div>
                                                <label className="col-xl-3 col-lg-3 col-10 col-form-label font-weight-bold">{day.name}</label>
                                                <div className="col-lg-3 col-xl-3 col-5">
                                                    <DatePicker
                                                        // ranges={ranges}
                                                        editable={false}
                                                        format="HH:mm"
                                                        value={formik.values.hourStart[day.id] ? new Date(formik.values.hourStart[day.id]) : null}
                                                        style={{ width: "100%", zIndex: 0 }}
                                                        container={document.querySelector('#tambah-attendance-modal') as HTMLElement}
                                                        onChange={(value: any) => {
                                                            if (value) formik.setFieldValue(`hourStart[${day.id}]`, value)
                                                            else formik.setFieldValue(`hourStart[${day.id}]`, null)
                                                        }}
                                                        caretAs={TimeIcon}
                                                    />
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-2 text-center col-form-label fw-normal">{intl.formatMessage({id: "FORM.LABEL.TO"})}</div>
                                                <div className="col-xl-3 col-lg-3 col-5">
                                                    <DatePicker
                                                        editable={false}
                                                        format="HH:mm"
                                                        value={formik.values.hourEnd[day.id] ? new Date(formik.values.hourEnd[day.id]) : null}
                                                        style={{ width: "100%", zIndex: 0 }}
                                                        container={document.querySelector('#tambah-attendance-modal') as HTMLElement}
                                                        onChange={(value: any) => {
                                                            if (value) formik.setFieldValue(`hourEnd[${day.id}]`, value)
                                                            else formik.setFieldValue(`hourEnd[${day.id}]`, null)
                                                        }}
                                                        caretAs={TimeIcon}
                                                    />
                                                    {formik.touched.hourEnd && formik.errors.hourEnd && formik.errors.hourEnd[day.id] && (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors.hourEnd[day.id]}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {formik.touched.hourStart && formik.errors.hourStart && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.hourStart}</span>
                                        </div>
                                    )}
                                </div>
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: "FORM.LABEL.CLOCK_IN.TOLERANCE"})}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={intl.formatMessage({id: "FORM.LABEL.CLOCK_IN.TOLERANCE"})}
                                        value={formik.values.tolerance}
                                        onChange={handleToleranceChange}
                                        onBlur={formik.handleBlur}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.tolerance && formik.errors.tolerance },
                                        )}
                                        type='text'
                                        name='nominal'
                                        autoComplete='off'
                                        style={{ zIndex: 0 }}
                                    />
                                    <span className="ms-3 text-center align-self-center">{intl.formatMessage({id: "MINUTES"})}</span>
                                </div>
                                {formik.touched.tolerance && formik.errors.tolerance && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.tolerance}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: "FORM.LABEL.ATTENDANCE_LOCATION"})}</label>
                                <div className="input-group mb-3">
                                    <AsyncSelect
                                        key={"key_" + formik.values.location}
                                        cacheOptions
                                        loadOptions={loadOptions}
                                        defaultOptions
                                        placeholder={`${intl.formatMessage({id: "FORM.ACTION.CHOOSE_ATTENDANCE_LOCATION"})}`}
                                        value={options.find((option) => option.value === formik.values.location)}
                                        onChange={
                                            option => {
                                                formik.handleChange({
                                                    target: {
                                                        name: "location",
                                                        value: option?.value
                                                    }
                                                })
                                            }
                                        }
                                        className="w-100"
                                        onBlur={
                                            () => formik.values.location === "" && formik.setFieldTouched("location", true)
                                        }
                                    />
                                </div>
                                {formik.touched.coordinate && formik.errors.coordinate && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.coordinate}</span>
                                    </div>
                                )}
                                {/* Latitude Longitude */}
                                <div className="input-group mb-3 d-none">
                                    <input
                                        placeholder="Titik koordinat"
                                        value={formik.values.coordinate}
                                        className={clsx(
                                            'form-control form-control-lg',
                                        )}
                                        type='text'
                                        name='coordinate'
                                        autoComplete='off'
                                        style={{ zIndex: 0 }}
                                    />
                                </div>
                                {
                                    latitudeMap && longitudeMap ?
                                        <Map height={200} center={[latitudeMap, longitudeMap]} defaultZoom={16} attribution={false}>
                                            {Object.keys(geoJsonFeatureLocation).length > 0 ?
                                                <GeoJson
                                                    svgAttributes={{
                                                        fill: "#c9909080",
                                                        strokeWidth: "1",
                                                        stroke: "white",
                                                        r: "20",
                                                    }}
                                                >
                                                    <GeoJsonFeature feature={geoJsonFeatureLocation} />
                                                </GeoJson> : <></>
                                            }
                                            <Marker
                                                width={50}
                                                height={50}
                                                anchor={[latitudeMap, longitudeMap]}
                                                color={color}
                                            />
                                        </Map> : <></>
                                }
                                {/* Radius */}
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: "FORM.LABEL.ATTENDANCE_LOCATION_RADIUS"})}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={intl.formatMessage({id: "FORM.LABEL.ATTENDANCE_LOCATION_RADIUS"})}
                                        value={formik.values.radius}
                                        onChange={handleRadiusChange}
                                        onBlur={formik.handleBlur}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.radius && formik.errors.radius },
                                        )}
                                        type='text'
                                        name='nominal'
                                        autoComplete='off'
                                        style={{ zIndex: 0 }}
                                    />
                                    <span className="ms-3 text-center align-self-center">{intl.formatMessage({id: "METERS"})}</span>
                                </div>
                                {formik.touched.radius && formik.errors.radius && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.radius}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.ADD" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {
                showUpdateHoursModal && <UpdateWorkHours changeAllHours={changeAllHours} />
            }
        </div>
    )
}

export { AddAttendanceTemplate }