import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

const GET_SETTING_ALL = `${API_URL}settings`
const SAVE_SETTING = `${API_URL}setting`
const ADD_TEMPLATE_SETTING_PIPELINE = `${API_URL}lead-templates`
const TEMPLATE_SETTING_MERCHANT = `${API_URL}merchant-templates`
const SET_ACCESS_PROGRESS_PIPELINE = `${API_URL}lead-template-lead-progress-accesses/`
const SET_ACCESS_PRODUCT_PIPELINE = `${API_URL}lead-template-product-accesses/`
const GET_MERCHANT_LINK = `${API_URL}merchant-link`
const GET_LIST_MERCHANT_LINK = `${API_URL}merchant-link/custom-list`
const SAVE_MERCHANT_LINK = `${API_URL}merchant-link`

export const getSettingAll = (token: string) => {
    return axios.get(GET_SETTING_ALL,
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const saveSetting = (key: string, value: string, token: string) => {
    return axios.put(SAVE_SETTING,
        {
            key,
            value
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const addTemplateSettingPipeline = (name: string, customFields: object, token: string) => {
    return axios.post(ADD_TEMPLATE_SETTING_PIPELINE,
        {
            name,
            customFields
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const updateTemplateSettingPipeline = (id: string, name: string, customFields: object, token: string) => {
    return axios.patch(ADD_TEMPLATE_SETTING_PIPELINE+"/"+id,
        {
            name,
            customFields
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const deleteTemplateSettingPipeline = (id: string, token: string) => {
    return axios.delete(ADD_TEMPLATE_SETTING_PIPELINE+"/"+id,
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}


export const setAccessProgressPipeline = (leadProgressIds: string[], idPipeline: string, token: string) => {
    return axios.patch(SET_ACCESS_PROGRESS_PIPELINE+idPipeline,
        {
            leadProgressIds
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const setAccessProductPipeline = (productIds: string[], idPipeline: string, token: string) => {
    return axios.patch(SET_ACCESS_PRODUCT_PIPELINE+idPipeline,
        {
            productIds
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}


export const addTemplateSettingMerchant = (name: string, customFields: object, token: string) => {
    return axios.post(TEMPLATE_SETTING_MERCHANT,
        {
            name,
            customFields
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const deleteTemplateSettingMerchant = (id: string, token: string) => {
    return axios.delete(TEMPLATE_SETTING_MERCHANT+"/"+id,
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const getTemplateSettingMerchant = (token: string) => {
    return axios.get(TEMPLATE_SETTING_MERCHANT,
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}


export const updateTemplateSettingMerchant = (id: string, name: string, customFields: object, leadTemplateIds:object, token: string) => {
    return axios.patch(TEMPLATE_SETTING_MERCHANT+"/"+id,
        {
            name,
            customFields, 
            leadTemplateIds
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const getMerchantLinkSetting = (idTemplate: string, token: string) => {
    return axios.post(GET_MERCHANT_LINK,
        {
            idTemplate
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const getListMerchantLinkSetting = (idSourceTemplate: string, token: string) => {
    return axios.post(GET_LIST_MERCHANT_LINK,
        {
            idSourceTemplate
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const saveSettingMerchantLink = (merchantLinkId: string, merchantTemplateSourceId: string, merchantTemplateDestination: any, token: string) => {
    return axios.patch(SAVE_MERCHANT_LINK,
        {
            merchantLinkId,
            merchantTemplateDestination,
            merchantTemplateSourceId
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}