import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Tooltip } from 'react-tippy';
import { Meeting, MeetingAttachment, MeetingAttendance } from "../../../interfaces/Meeting";
import { CustomField } from "../../../interfaces/Settings";
import { useSettingsStore } from "../../../stores/SettingsStore";
import useUser from "../../../hooks/User";
import { useCustomeStore } from "../../../stores/CustomeStore";
import { customNumberFormat } from "../../../functions/general";
import { KTSVG } from "../../../_metronic/helpers";
import { ExpandableElement } from "../../General/ExpandableElement";

interface DetailMeetingProps {
    data: Meeting
    setShowDetailMeetingModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ChatDetailMeeting: FC<DetailMeetingProps> = ({ data, setShowDetailMeetingModal }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const intl = useIntl()
    const [customMeeting, setCustomMeeting] = useState({} as any)
    const [customFields, setCustomFields] = useState<CustomField>({})
    const { settings } = useSettingsStore()
    const user = useUser()
    const { companyId } = useCustomeStore()

    useEffect(() => {
        const customData: any = {
            attachments: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHOTO' }),
                value: data.attachments.length > 0 ? returnImage(data.attachments as MeetingAttachment[]) : "-"
            },
            merchant_name: {
                name: settings.merchant_title,
                value: data.merchant_name
            },
            owner_name: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
                value: data.owner_name
            },
            date_meet: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
                value: data.allDay ? moment(data.date_meet).format("DD MMM YYYY") : moment(data.date_meet).format("DD MMM YYYY HH:mm")
            },
            date_meet_end: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
                value: data.date_meet_end === null ? "-" : data.allDay ? moment(data.date_meet_end).format("DD MMM YYYY") : moment(data.date_meet_end).format("DD MMM YYYY HH:mm")
            },
            attendees: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PARTICIPANT' }),
                value: data.attendees.length > 0 ? data.attendees.map((data: MeetingAttendance) => data.name).join(', ') : "-"
            },
            creator_name: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
                value: data.creator_name
            },
            location: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LOCATION' }),
                value: data.latitude && data.longitude ? data.location : "-"
            },
            reminderMinutes: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.REMINDER' }),
                value: data.reminderMinutes === null ? "-" : customNumberFormat(parseInt(data.reminderMinutes)) + " Menit"
            },
            status: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.STATUS' }),
                value: returnStatus(data)
            },
            note: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
                value: data.note ? <ExpandableElement text={(data.note).replaceAll('\n', '<br/>')} maxHeight={100} /> : "-"
            },
        }

        const newData = {
            ...customData,
            ...Object.keys(customFields).reduce((acc, key) => {
                let value;
                if (customFields[key].type === 'date') {
                    value = data.custom_fields[key] ? moment(data.custom_fields[key]).format("DD MMM YYYY") : "-";
                } else if (customFields[key].type === 'image' || customFields[key].type === 'images') {
                    value = data.custom_fields[key] ? returnImage(data.custom_fields[key] as string[]) : "-";
                } else if (customFields[key].type === 'file' || customFields[key].type === 'files') {
                    value = data.custom_fields[key] ? returnFile(data.custom_fields[key]) : "-";
                } else if (customFields[key].type === 'multiple') {
                    value = data.custom_fields[key] ? returnMultiple(data.custom_fields[key] as string) : "-";
                } else if (customFields[key].type === 'link') {
                    value = data.custom_fields[key] ? returnLink(data.custom_fields[key] as string) : "-";
                } else if (customFields[key].type === 'serial') {
                    value = data.custom_fields[key] ? (data.custom_fields[key] as string[]).join('') : "-";
                } else {
                    value = data.custom_fields[key] ? data.custom_fields[key] : "-";
                }

                return {
                    ...acc,
                    [key]: {
                        name: customFields[key].name,
                        value
                    }
                };
            }, {})
        };

        setCustomMeeting(newData);
    }, [customFields])

    useEffect(() => {
        if (settings.meeting_custom_fields) {
            setCustomFields(JSON.parse(settings.meeting_custom_fields))
        }
    }, [settings])

    const returnImage = (url: string | string[] | MeetingAttachment[]) => {
        if (Array.isArray(url)) {
            return (url as MeetingAttachment[] | string[]).map((data: MeetingAttachment | string, index: number) => (
                <span key={index}>
                    <a href={(data as MeetingAttachment).url ? (data as MeetingAttachment).url as string : data as string} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })}</a>
            );
        }
    };

    const returnFile = (url: string | string[]) => {
        if (Array.isArray(url)) {
            return url.map((data: string, index: number) => (
                <span key={index}>
                    <a href={data} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })}</a>
            );
        }
    };

    const returnStatus = (data: Meeting) => {
        if (data.status === intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) || data.status === 'SCHEDULED') {
            return <span className="badge badge-info" >{intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' })}</span>
        } else if (data.status === intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) || data.status === 'COMPLETED') {
            return <span className="badge badge-success" >{intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' })}</span>
        } else if (data.status === intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) || data.status === 'ONGOING') {
            return <span className="badge badge-warning" >{intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' })}</span>
        } else {
            return (
                <>
                    {
                        (data.meetingStatusNote)?.includes("sistem") ?
                            <Tooltip
                                title={`${intl.formatMessage({ id: 'TOOLTIP.AUTO_CANCEL_SYSTEM' })}`}
                                theme="light"
                                arrow={true}
                                style={{ cursor: "default", display: "flex", alignItems: "center", width: "min-content" }}
                            >
                                <span className="badge badge-danger" >{intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' })}</span>
                                <i className='fas fa-info-circle ms-2 fs-7'></i>
                            </Tooltip>
                            :
                            <span className="badge badge-danger" >{intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' })}</span>
                    }
                </>
            )
        }
    };

    const returnMultiple = (data: string) => {
        const values = data && data.toString()
        return values ? values.split(',').join(', ') : '-'
    }

    const returnLink = (data: string) => {
        return (
            <a href={data} rel="noreferrer" className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600" target="_blank">Lihat<i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i></a>
        )
    }

    return (
        <div className="card" style={{ minHeight: "calc(100vh - 1rem)" }}>
            <h3 className="py-3 px-5 d-flex align-items-center gap-3">
                <i className="fas fa-times fs-3 text-hover-primary cursor-pointer" onClick={() => setShowDetailMeetingModal(false)}></i>
                Detail {data.owner_name}
            </h3>
            <div className="card-body row">
                {
                    Object.keys(customMeeting).map((key, index) => {
                        return (
                            <div className="row pb-2 col-12">
                                <div className="col-3 text-end" style={{ fontWeight: "500", color: "#767373" }}>{customMeeting[key].name}</div>
                                <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{customMeeting[key].value}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export { ChatDetailMeeting }