import clsx from 'clsx';
import React, { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { getFileType, getNameUrl, getRandomFileName, isValidTypeAttachment } from '../../functions/general';
import { toAbsoluteUrl } from '../../_metronic/helpers';
import { is } from 'date-fns/locale';

interface Props {
    keyString: string
    formik: any
    accept: string
    isMulti: boolean
    type: string
    isShowPreview?: boolean
}

const InputAttachment: FC<Props> = ({ keyString, formik, accept, isMulti, type, isShowPreview = true }) => {
    const refArray = useRef<(HTMLInputElement | null)[]>([]);
    const intl = useIntl()
    const typeImage = ['png', 'jpg', 'jpeg']
    const typeVideo = ['mp4', 'mkv', 'webm', 'mov']
    const typeFile = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'text']
    const typeAttachment = [...typeImage, ...typeFile, ...typeVideo]
    var dataFile = formik.values[keyString as keyof typeof formik.values]
    // const fileInputRef = useRef<(HTMLInputElement | null)[]>([])

    function handleChange(e: React.ChangeEvent<HTMLInputElement>, keyString: string) {
        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

        if (e.target.files && e.target.files.length > 0) {
            if (isMulti) {
                var multiAttachment = []

                for (let i = 0; i < e.target.files.length; i++) {
                    const file = e.target.files[i];

                    if (!isValidTypeAttachment(file.name, typeFile) && accept.includes('pdf') && !accept.includes('image') && !accept.includes('video')) {
                        Swal.fire({
                            icon: "error",
                            title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_FILE_NOT_VALID' })}`,
                            heightAuto: false,
                            confirmButtonText: 'Ok'
                        })

                        return false
                    } else if (!isValidTypeAttachment(file.name, typeImage) && accept.includes('image') && !accept.includes('pdf') && !accept.includes('video')) {
                        Swal.fire({
                            icon: "error",
                            title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_IMAGE_NOT_VALID' })}`,
                            heightAuto: false,
                            confirmButtonText: 'Ok'
                        })

                        return false
                    } else if (!isValidTypeAttachment(file.name, typeVideo) && accept.includes('video') && !accept.includes('image') && !accept.includes('pdf')) {
                        Swal.fire({
                            icon: "error",
                            title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_VIDEO_NOT_VALID' })}`,
                            heightAuto: false,
                            confirmButtonText: 'Ok'
                        })

                        return false
                    } else if (!isValidTypeAttachment(file.name, typeAttachment)) {
                        Swal.fire({
                            icon: "error",
                            title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_FILE_NOT_VALID' })}`,
                            heightAuto: false,
                            confirmButtonText: 'Ok'
                        })
                    } else if (file.size > MAX_FILE_SIZE) {
                        Swal.fire({
                            icon: "error",
                            title: `${intl.formatMessage({ id: 'FORM.VALIDATION.MAX_FILE_SIZE_5MB' })}`,
                            heightAuto: false,
                            confirmButtonText: 'Ok'
                        })

                        return false
                    } else {
                        multiAttachment.push(file)
                    }
                }

                if (formik.values[keyString as keyof typeof formik.values] === null) {
                    formik.setFieldValue(keyString, multiAttachment);
                } else {
                    formik.setFieldValue(keyString, [...formik.values[keyString as keyof typeof formik.values], ...multiAttachment]);
                }
            } else {
                const file = e.target.files[0];

                if (!isValidTypeAttachment(file.name, typeFile) && accept.includes('pdf') && !accept.includes('image') && !accept.includes('video')) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_FILE_NOT_VALID' })}`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })

                    return false
                } else if (!isValidTypeAttachment(file.name, typeImage) && accept.includes('image') && !accept.includes('pdf') && !accept.includes('video')) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_FILE_NOT_VALID' })}`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })
                } else if (!isValidTypeAttachment(file.name, typeVideo) && accept.includes('video') && !accept.includes('image') && !accept.includes('pdf')) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_VIDEO_NOT_VALID' })}`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })

                    return false
                } else if (!isValidTypeAttachment(file.name, typeAttachment)) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.FORMAT_FILE_NOT_VALID' })}`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })
                } else if (file.size > MAX_FILE_SIZE) {
                    formik.setFieldValue(keyString, null);

                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.MAX_FILE_SIZE_5MB' })}`,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    })

                    return false
                } else {
                    formik.setFieldValue(keyString, file)
                }
            }
        }
    }

    function deleteFile(indexDelete: number) {
        if (isMulti) {
            if (indexDelete !== -1) {
                const filteredFile = formik.values[keyString as keyof typeof formik.values].filter((file: File | string, index: number) => index !== indexDelete);

                if (filteredFile.length === 0) formik.setFieldValue(keyString, null)
                else formik.setFieldValue(keyString, filteredFile)
            } else {
                formik.setFieldValue(keyString, null)
            }
        } else {
            formik.setFieldValue(keyString, null)
        }


        const ref = refArray.current[keyString as any];
        if (ref && ref.value) {
            ref.value = "";
        }
    }

    return (
        <>
            {formik.values[keyString as keyof typeof formik.values] && (formik.values[keyString as keyof typeof formik.values]).length > 0 && isMulti && isShowPreview && (
                <div className="d-flex flex-wrap gap-5">
                    {formik.values[keyString as keyof typeof formik.values].map((file: File | string, index: number) => {
                        if (isValidTypeAttachment(typeof file === "string" ? file : file.name, typeFile)) {
                            return (
                                <div className="position-relative">
                                    {
                                        getFileType(typeof file === "string" ? file : file.name) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                            : getFileType(typeof file === "string" ? file : file.name) === "doc" || getFileType(typeof file === "string" ? file : file.name) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                : getFileType(typeof file === "string" ? file : file.name) === "ppt" || getFileType(typeof file === "string" ? file : file.name) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                    : getFileType(typeof file === "string" ? file : file.name) === "xlsx" || getFileType(typeof file === "string" ? file : file.name) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                        : <img src={toAbsoluteUrl('/media/logos/file.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                    }
                                    <div className="position-absolute translate-middle badge badge-sm badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(index)}><i className="fas fa-times text-white"></i></div>
                                </div>
                            )
                        } else if (isValidTypeAttachment(typeof file === "string" ? file : file.name, typeVideo)) {
                            return (
                                <div className="position-relative">
                                    <img src={toAbsoluteUrl('/media/logos/video.png')} className="mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                    <div className="position-absolute translate-middle badge badge-sm badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(index)}><i className="fas fa-times text-white"></i></div>
                                </div>
                            )
                        } else {
                            return (
                                <div className="position-relative">
                                    <img src={typeof file === "string" ? file : URL.createObjectURL(file)} className="rounded mb-2" alt={typeof file === "string" ? file : file.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                    <div className="position-absolute translate-middle badge badge-sm badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(index)}><i className="fas fa-times text-white"></i></div>
                                </div>
                            )
                        }
                    })}
                </div>
            )}

            {formik.values[keyString as keyof typeof formik.values] && !isMulti && isShowPreview && (
                <div className="d-flex flex-wrap gap-5">
                    {
                        isValidTypeAttachment(typeof dataFile === "string" ? dataFile : dataFile.name, typeFile) ?
                            <div className="position-relative">
                                {
                                    getFileType(typeof dataFile === "string" ? dataFile : dataFile.name) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} className="mb-2" alt={typeof dataFile === "string" ? dataFile : dataFile.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                        : getFileType(typeof dataFile === "string" ? dataFile : dataFile.name) === "doc" || getFileType(typeof dataFile === "string" ? dataFile : dataFile.name) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} className="mb-2" alt={typeof dataFile === "string" ? dataFile : dataFile.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                            : getFileType(typeof dataFile === "string" ? dataFile : dataFile.name) === "ppt" || getFileType(typeof dataFile === "string" ? dataFile : dataFile.name) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} className="mb-2" alt={typeof dataFile === "string" ? dataFile : dataFile.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                : getFileType(typeof dataFile === "string" ? dataFile : dataFile.name) === "xlsx" || getFileType(typeof dataFile === "string" ? dataFile : dataFile.name) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} className="mb-2" alt={typeof dataFile === "string" ? dataFile : dataFile.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                    : <img src={toAbsoluteUrl('/media/logos/file.png')} className="mb-2" alt={typeof dataFile === "string" ? dataFile : dataFile.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                }
                                <div className="position-absolute translate-middle badge badge-sm badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(-1)}><i className="fas fa-times text-white"></i></div>
                            </div>
                            :
                            isValidTypeAttachment(typeof dataFile === "string" ? dataFile : dataFile.name, typeVideo) ?
                                <div className="position-relative">
                                    <img src={toAbsoluteUrl('/media/logos/video.png')} className="rounded mb-2" alt={typeof dataFile === "string" ? dataFile : dataFile.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                    <div className="position-absolute translate-middle badge badge-sm badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(-1)}><i className="fas fa-times text-white"></i></div>
                                </div>
                                :
                                <div className="position-relative">
                                    <img src={typeof dataFile === "string" ? dataFile + "?" + getRandomFileName() : URL.createObjectURL(dataFile)} className="rounded mb-2" alt={typeof dataFile === "string" ? dataFile : dataFile.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                    <div className="position-absolute translate-middle badge badge-sm badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(-1)}><i className="fas fa-times text-white"></i></div>
                                </div>
                    }
                </div>
            )}

            <div className="d-flex">
                <input
                    multiple={isMulti}
                    id={`fileInput-${type}-${keyString}`}
                    type="file"
                    ref={ref => {
                        refArray.current[keyString as any] = ref; // took this from your guide's example.
                    }}
                    accept={accept}
                    style={{ zIndex: 0 }}
                    onChange={(e) => handleChange(e, keyString)}
                    onBlur={() => formik.setFieldTouched(keyString, true)}
                    className={clsx(
                        'form-control form-control-lg d-none',
                        { 'is-invalid': formik.touched[keyString as keyof typeof formik.touched] && formik.errors[keyString as keyof typeof formik.errors] },
                    )}
                // capture="environment"
                />

                <div className="input-group">
                    <label className="input-group-text cursor-pointer" htmlFor={`fileInput-${type}-${keyString}`}>{intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })}</label>
                    <label
                        htmlFor={`fileInput-${type}-${keyString}`}
                        id={`fileInputLabel-${type}-${keyString}`}
                        className={clsx(
                            'form-control form-control-lg',
                            { 'is-invalid': formik.touched[keyString as keyof typeof formik.touched] && formik.errors[keyString as keyof typeof formik.errors] },
                            'align-items-center'
                        )}
                        style={{ cursor: 'pointer', textOverflow: "ellipsis", overflow: "hidden", display: "inline-block", whiteSpace: "nowrap", width: "1px" }}
                    >
                        {
                            formik.values[keyString as keyof typeof formik.values] === null || formik.values[keyString as keyof typeof formik.values] === '' ?
                                intl.formatMessage({ id: "FORM.LABEL.DATA_NOT_FOUND" }) :
                                typeof formik.values[keyString as keyof typeof formik.values] === 'string' ?
                                    getNameUrl(formik.values[keyString as keyof typeof formik.values]) :
                                    isMulti ?
                                        formik.values[keyString as keyof typeof formik.values]?.length + " data" :
                                        formik.values[keyString as keyof typeof formik.values]?.name
                        }
                    </label>
                </div>

                {
                    isMulti && formik.values[keyString as keyof typeof formik.values] && formik.values[keyString as keyof typeof formik.values]?.length > 0 &&
                    <button type="button" className="btn btn-light-danger ms-3 btn-sm" onClick={() => { deleteFile(-1) }}>
                        {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                    </button>
                }
                {
                    !isMulti && formik.values[keyString as keyof typeof formik.values] &&
                    <button type="button" className="btn btn-light-danger ms-3 btn-sm" onClick={() => { deleteFile(-1) }}>
                        {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                    </button>
                }
            </div >
        </>
    );
};

export default InputAttachment;


