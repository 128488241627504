import React, { useEffect, useRef, useState } from 'react'
import { Pipeline } from '../../../interfaces/Pipeline'
import { useIntl } from 'react-intl'
import useHandleCloseModal from '../../../hooks/HandleCloseModal'
import { useFormik } from 'formik'
import { PaymentTermin } from '../../../interfaces/Termin/Termin'
import { KTSVG } from '../../../_metronic/helpers'
import { deletePaymentTermin } from '../../../api/PipelineCRUD'
import Swal from 'sweetalert2'
import useAccessToken from '../../../hooks/AccessToken'

interface ModalDeleteTerminProps {
    termin: PaymentTermin
    pipeline: Pipeline
    terminNumber: number
    onSuccess: () => void
}

const ModalDeleteTermin = ({ termin, pipeline, terminNumber, onSuccess }: ModalDeleteTerminProps) => {
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const token = useAccessToken()
    const intl = useIntl()

    const handleDeleteTermin = async () => {
        setModalBtnLoading(true)
        try {
            const response = await deletePaymentTermin(termin.id, token)
            if (response.success) {
                Swal.fire(
                    intl.formatMessage({ id: 'FORM.CONFIRM_SUCCESS' }),
                    intl.formatMessage({ id: 'FORM.CONFIRM.TERMIN_DELETED' }),
                    'success'
                )
                closeModalRef.current?.click()
                onSuccess()
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message || error.message || 'Terjadi Kesalahan',
                confirmButtonText: 'Ok'
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    return (
        <div className="modal fade" tabIndex={-1} id={`delete-detail-termin-modal-${pipeline.id}`}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.DELETE" })} {intl.formatMessage({ id: 'FORM.LABEL.TERMIN_NUMBER' })} {terminNumber}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <p>{intl.formatMessage({ id: 'FORM.CONFIRMATION.DELETE' }, { title: intl.formatMessage({ id: 'FORM.LABEL.TERMIN_NUMBER' }) })} {terminNumber}</p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                            ref={closeModalRef}
                        >
                            {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                        </button>
                        <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleDeleteTermin}>
                            <span className="indicator-label">
                                {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                            </span>
                            <span className="indicator-progress">
                                Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDeleteTermin