import { FC, useState } from "react";
import { useDataTableStore } from "../../../stores/DataTableStore";
import { useCustomFieldStore } from "../../../stores/FieldEmailStore";
import { useIntl } from "react-intl";
import useNameColumnExcelEmail from "../hooks/useNameColumnExcelEmail";
import { exportTemplateExcelEmailGroup } from "./exportTemplateExcelEmail";

interface DetailExportGroupEmailProps {
    source: string
}

const DetailExportGroupEmail: FC<DetailExportGroupEmailProps> = ({ source }) => {
    const { filteredTablesData, filterTextTable } = useDataTableStore()
    const intl = useIntl()
    const nameHeader = useNameColumnExcelEmail()

    return (
        <button
            type="button"
            className={`btn btn-light-success skeleton skeleton-button mt-3`}
            id="optionImport"
            onClick={() =>
                source === 'template-detail-group-email' ? exportTemplateExcelEmailGroup(source, 'export', 'Template Export Email', nameHeader, filteredTablesData['detailGroupEmail']) : null
            }
        >
            <i className="fas fa-file-download fs-2"></i>
            {intl.formatMessage({ id: "MASTERS.DATATABLE.ACTIONS.EXPORT_EXCEL" })}
        </button>
    )
}

export { DetailExportGroupEmail }