import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import { FC, useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import useSWR from "swr";
import useAccessToken from "../../../hooks/AccessToken";
import FullCalendarComponent from '@fullcalendar/react';
import { Button, ButtonGroup, DropdownButton } from "react-bootstrap";
import { Calendar } from "../../../interfaces/Calendar";
import { Modal } from "bootstrap";
import { AddCalendar } from "../../../components/Schedule/AddCalendar";
import { AddEvent } from "../../../components/Schedule/AddEvent";
import { DetailEvent } from "../../../components/Schedule/DetailEvent";
import { EventImpl } from "@fullcalendar/core/internal";
import Swal from 'sweetalert2'
import { updateEventTime } from '../../../api/EventCRUD'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment'
import Select from "react-select";
import { SelectOption } from '../../../interfaces/SelectOption'
import useUser from '../../../hooks/User'
import { DatasetController } from 'chart.js'
import { isUUID } from '../../../functions/general'
import { getCalendar, getEvent } from '../../../api/ScheduleCRUD'
import { settings } from 'cluster'
import { useSettingsStore } from '../../../stores/SettingsStore'
import { useIntl } from 'react-intl'
import { useLanguageStore } from '../../../stores/LanguageStore'

interface eventProps {
    id: string,
    events: any
}

const MasterSchedule: FC = () => {
    const { settings } = useSettingsStore()
    const user = useUser()
    const modalRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowMorePopover, setIsShowMorePopover] = useState<boolean>(false);
    const [showAddCalendarModal, setShowAddCalendarModal] = useState<boolean>(false);
    const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);
    const [showDetailEventModal, setShowDetailEventModal] = useState<boolean>(false);
    const [selectedCalendar, setSelectedCalendar] = useState<string>(user.data.id);
    const [event, setEvent] = useState<EventImpl>();
    const [listEvent, setListEvent] = useState<eventProps[]>([]);
    const [calendarOptions, setCalendarOptions] = useState<SelectOption[]>([]);
    const [selectedCalendars, setSelectedCalendars] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>();
    const calendarRef = useRef<FullCalendarComponent>(null);
    const [viewTitle, setViewTitle] = useState<string>(calendarRef.current?.getApi().view.title ?? '');
    const [viewMode, setViewMode] = useState<string>("month");
    const [dataCalendars, setDataCalendars] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL
    const token: any = useAccessToken()
    const intl = useIntl()
    const { locale } = useLanguageStore()

    const allCalendarOptions: SelectOption[] = [
        { value: "ALL", label: intl.formatMessage({ id: "CALENDAR.FILTER.ALL" }) },
    ]

    useEffect(() => {
        if (showAddCalendarModal) {
            const modalElement = document.getElementById('tambah-calendar-modal');

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddCalendarModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddCalendarModal, setShowAddCalendarModal]);

    useEffect(() => {
        if (showAddEventModal) {
            const modalElement = document.getElementById('add-event-modal');

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddEventModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddEventModal, setShowAddEventModal]);

    useEffect(() => {
        if (showDetailEventModal) {
            const modalElement = document.getElementById('detail-event-modal');

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDetailEventModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDetailEventModal, setShowDetailEventModal]);

    const fetcher = (url: string) => {
        return axios.get(url, {
            headers: {
                'X-Auth-token': token
            },
        }).then(res => res.data.data);
    }

    const { data: calendar = [], error: calendarError, isLoading: calendarLoading, isValidating: calendarValidating } = useSWR(`${API_URL}calendar-list`, fetcher)

    const getCalendarList = async () => {
        try {
            const response = await getCalendar({ token: token })
            if (response.data.success) {
                var dataCalendars = response.data.data

                const dataAllCalendars = dataCalendars.map((eachData: any) => ({
                    ...eachData,
                    name: eachData.nameOverride
                        ? "[" + (eachData.type === "PIPELINE" ? settings.pipeline_title : eachData.type === "MEETING" ? settings.meeting_title : eachData.type) + "] " + eachData.nameOverride
                        : "[" + (eachData.type === "PIPELINE" ? settings.pipeline_title : eachData.type === "MEETING" ? settings.meeting_title : eachData.type) + "] " + eachData.name
                }))

                setDataCalendars(dataAllCalendars)
                if (localStorage.getItem("selectedCalendars") && localStorage.getItem("selectedCalendars") !== "[]") {
                    var dataSelectCalendars = JSON.parse(localStorage.getItem("selectedCalendars") || "[]")
                    setSelectedCalendars(dataSelectCalendars)
                } else {
                    setSelectedCalendars([...dataCalendars.filter((value: any) => value.name === user.data.name).map((value: any) => value.id)]);
                }

                setCalendarOptions([
                    ...allCalendarOptions,
                    ...dataCalendars
                        .filter((value: Calendar, index: number, self: Calendar[]) => self.findIndex(item => (item.owner.id === value.owner.id) && item.owner.id !== "172557bb-e15d-43a7-87b2-c8dd063d14fd") === index)
                        .map((data: Calendar) => ({
                            value: data.owner.id,
                            label: data.owner.name
                        }))
                ])

                dataCalendars.forEach((calendar: Calendar) => {
                    getEventList(calendar.id)
                })

                fetchNationalHoliday()
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setIsLoading(false)
        }
    }

    const getEventList = async (id: string) => {
        try {
            const response = await getEvent({ token: token, id: id })
            if (response.data.success) {
                var dataEvents = response.data.data

                var data = dataEvents.map((value: any) => {
                    if (value.type !== "OTHER") {
                        return { ...value, title: "[" + (value.type === "PIPELINE" ? settings.pipeline_title : value.type === "MEETING" ? settings.meeting_title : value.type) + "] " + value.title };
                    } else {
                        return { ...value, title: value.title };
                    }
                });


                if (calendarRef.current?.getApi().getEventSourceById(id)?.id !== id) {
                    if (localStorage.getItem("selectedCalendars") && localStorage.getItem("selectedCalendars") !== "[]") {
                        if (data.some((value: any) => JSON.parse(localStorage.getItem("selectedCalendars") || "[]").includes(value.calendar.id))) {
                            calendarRef.current?.getApi().addEventSource({ id: id, events: data })
                        }
                    } else {
                        if (data.some((value: any) => value.calendar.name === user.data.name)) {
                            calendarRef.current?.getApi().addEventSource({ id: id, events: data })
                        }
                    }

                    setListEvent((prev) => [...prev, { id: id, events: data }])
                }
            }
        } catch (error: any) {
            console.log(error.response.data.message)
        }
    }

    const fetchNationalHoliday = async () => {
        try {
            const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/id.indonesian%23holiday@group.v.calendar.google.com/events?key=AIzaSyAc73HE8m42QuBt7aNdiVmpIKv9aThqTNM`);
            const data = await response.json();

            data.items.map((item: any) => {
                var dataEvent = {
                    id: item.id,
                    title: item.summary,
                    start: item.start.date || item.start.dateTime,
                    end: item.end.date || item.end.dateTime,
                    color: '#d92929',
                }

                if (calendarRef.current?.getApi().getEventSourceById(dataEvent.id)?.id !== dataEvent.id) {
                    calendarRef.current?.getApi().addEventSource({ id: dataEvent.id, events: [dataEvent] });
                    setListEvent((prev) => [...prev, { id: dataEvent.id, events: [dataEvent] }])
                }
            });
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    useEffect(() => {
        setIsLoading(true)

        getCalendarList()
        setViewTitle(calendarRef.current?.getApi().view.title ?? '')
    }, [])

    useEffect(() => {
        setViewTitle(calendarRef.current?.getApi().view.title ?? '')
    }, [locale])
    // useEffect(() => {
    //     calendarRef.current?.getApi().removeAllEvents()
    //     calendarRef.current?.getApi().removeAllEventSources();
    //     setListEvent([])
    //     setIsLoading(true)

    //     if (!calendarValidating) {
    //         const dataAllCalendars = calendar.map((eachData: any) => ({
    //             ...eachData,
    //             name: eachData.type !== "PERSONAL" ? eachData.nameOverride ? "[" + eachData.type + "] " + eachData.nameOverride : "[" + eachData.type + "] " + eachData.name : eachData.nameOverride ?? eachData.name
    //         }))

    //         setDataCalendars(dataAllCalendars)
    //         setSelectedCalendars(["all", ...calendar.map((value: any) => value.id)])
    //         setCalendarOptions([
    //             ...allCalendarOptions,
    //             ...calendar
    //                 .filter((value: Calendar, index: number, self: Calendar[]) => self.findIndex(item => (item.owner.id === value.owner.id) && item.owner.id !== "172557bb-e15d-43a7-87b2-c8dd063d14fd") === index)
    //                 .map((data: Calendar) => ({
    //                     value: data.owner.id,
    //                     label: data.owner.name
    //                 }))
    //         ])

    //         var listEventTemp: eventProps[] = []
    //         calendar.forEach((e: any) => {
    //             fetcher(`${API_URL}calendars/${e.id}/events`)
    //                 .then(x => {
    //                     var data = x.map((value: any) => {
    //                         if (value.type !== "OTHER") return { ...value, title: "[" + value.type + "] " + value.title }
    //                         else return { ...value, title: value.title }
    //                     })

    //                     console.log(listEventTemp.findIndex((value: any) => value.id === e.id))
    //                     if (listEventTemp.findIndex((value: any) => value.id === e.id) === -1) {
    //                         calendarRef.current?.getApi().addEventSource({ id: e.id, events: data })
    //                         listEventTemp.push({ id: e.id, events: data })
    //                     }

    //                 });
    //         });

    //         const fetchNationalHoliday = async () => {
    //             try {
    //                 const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/id.indonesian%23holiday@group.v.calendar.google.com/events?key=AIzaSyAc73HE8m42QuBt7aNdiVmpIKv9aThqTNM`);
    //                 const data = await response.json();

    //                 // Memformat event sesuai dengan format yang diterima oleh addEventSource
    //                 data.items.map((item: any) => {
    //                     var dataEvent = {
    //                         id: item.id,
    //                         title: item.summary,
    //                         start: item.start.date || item.start.dateTime,
    //                         end: item.end.date || item.end.dateTime,
    //                         color: '#d92929',
    //                     }

    //                     if (calendarRef.current?.getApi().getEventSourceById(dataEvent.id)?.id !== dataEvent.id) {
    //                         calendarRef.current?.getApi().addEventSource({ id: dataEvent.id, events: [dataEvent] });
    //                         listEventTemp.push({ id: dataEvent.id, events: [dataEvent] })
    //                     }
    //                 });

    //                 // Menambahkan sumber event ke kalender setelah semua event diformat

    //                 // Memperbarui state setelah menambahkan sumber event
    //             } catch (error) {
    //                 console.error('Error fetching events:', error);
    //             }
    //         };


    //         fetchNationalHoliday()
    //         setIsLoading(false)
    //         setListEvent(listEventTemp)
    //     }
    // }, [calendar, calendarValidating])

    const handleScroll = (event: any) => {
        const calendarElement = calendarRef.current?.getApi();
        const scrollDirection = event.deltaY > 0 ? 'down' : 'up';

        if (calendarElement?.view.type === "dayGridMonth" && !isShowMorePopover) {
            if (scrollDirection === 'down') {
                calendarElement?.next();
            } else {
                calendarElement?.prev();
            }
        }

        setViewTitle(calendarRef.current?.getApi().view.title ?? '')
    };

    const handleCheckboxChange = (id: string) => {
        if (selectedCalendars.includes(id)) {
            if (id === "all") {
                selectedCalendars.map((value) => {
                    calendarRef.current?.getApi().getEventSourceById(value)?.remove()
                })
                setSelectedCalendars([])
                localStorage.setItem("selectedCalendars", JSON.stringify([]))
            } else {
                calendarRef.current?.getApi().getEventSourceById(id)?.remove()
                setSelectedCalendars(selectedCalendars.filter((item) => item !== id && item !== "all"));
                localStorage.setItem("selectedCalendars", JSON.stringify(selectedCalendars.filter((item) => item !== id && item !== "all")))
            }
        } else {
            if (id === "all") {
                selectedCalendars.map((value) => {
                    calendarRef.current?.getApi().getEventSourceById(value)?.remove()
                })
                setSelectedCalendars(["all", ...calendar.map((value: any) => value.id)])
                localStorage.setItem("selectedCalendars", JSON.stringify(["all", ...calendar.map((value: any) => value.id)]))
                listEvent.forEach((e: any) => {
                    if (isUUID(e.id)) calendarRef.current?.getApi().addEventSource({ id: e.id, events: e.events })
                })
            } else {
                calendarRef.current?.getApi().addEventSource(listEvent.find((value: any) => value.id === id) ?? [])
                var dataSelectCalendar = [...selectedCalendars, id]

                if (dataSelectCalendar.length === calendar.length) {
                    setSelectedCalendars(["all", ...dataSelectCalendar])
                    localStorage.setItem("selectedCalendars", JSON.stringify(["all", ...dataSelectCalendar]))
                } else {
                    setSelectedCalendars(dataSelectCalendar);
                    localStorage.setItem("selectedCalendars", JSON.stringify(dataSelectCalendar))
                }
            }
        }
    };

    function sortCalendar(a: { name: string }, b: { name: string }) {
        const hasSymbol = (name: string) => /\[.*?\]/.test(name);
        if (hasSymbol(a.name) && !hasSymbol(b.name)) {
            return 1; // Pindahkan entitas dengan simbol ke bawah
        }
        if (!hasSymbol(a.name) && hasSymbol(b.name)) {
            return -1; // Pindahkan entitas tanpa simbol ke atas
        }
        return a.name.localeCompare(b.name);
    }

    const renderChecklist = () => {
        return (
            <>
                {
                    selectedCalendar === "ALL" &&
                    <div className="form-check m-3" style={{ width: "300px" }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`checkbox-all`}
                            value={"all"}
                            checked={selectedCalendars.includes("all")}
                            onChange={() => handleCheckboxChange("all")}
                        />
                        <label className="form-check-label" htmlFor={`checkbox-all`}>
                            {intl.formatMessage({ id: "CALENDAR.FILTER.ALL" })}
                        </label>
                    </div>
                }

                {
                    dataCalendars.sort(sortCalendar).map((item: Calendar, index: number) => {
                        if (selectedCalendar === item.owner.id || selectedCalendar === "ALL") {
                            return (
                                <div key={index} className="form-check m-3" style={{ width: "300px" }}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`checkbox-${index}`}
                                        value={item.id}
                                        checked={selectedCalendars.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                    />
                                    <label className="form-check-label" htmlFor={`checkbox-${index}`}>
                                        {item.name}
                                    </label>
                                </div>

                            )
                        }
                    })
                }
            </>
        )
    };

    const handleDateClick = (dateStr: any) => {
        setStartDate(dateStr.dateStr)
        setShowAddEventModal(true)
    }

    const handleEventClick = (info: any) => {
        if (isUUID(info.event.id)) {
            setEvent(info.event)
            setShowDetailEventModal(true)
        }
    }

    const handleTodayButtonClick = () => {
        calendarRef.current?.getApi().gotoDate(moment().toISOString());
        setViewTitle(calendarRef.current?.getApi().view.title ?? '')
    };

    useEffect(() => {
        const handleClickOutsideModal = (event: any) => {
            const moreLink = event?.target?.closest('.fc-more-link');
            if (moreLink && !isShowMorePopover) setIsShowMorePopover(true)
            else setIsShowMorePopover(false)
        };

        document.addEventListener('click', handleClickOutsideModal);

        return () => {
            document.removeEventListener('click', handleClickOutsideModal);
        };
    }, [isShowMorePopover, setIsShowMorePopover]);



    return (
        <>
            <div className='row'>
                <DropdownButton size="sm" title={intl.formatMessage({ id: 'FORM.LABEL.CALENDAR_LIST' })} id="dropdown" className='col-4 col-sm-3 col-lg-2 col-xl-2 pb-3' >
                    <Select
                        styles={{
                            control: (baseStyles, { isFocused }) => ({
                                ...baseStyles,
                                border: "none",
                                borderRadius: "5px",
                                borderColor: "#eef3f7",
                                backgroundColor: "#eef3f7",
                                margin: "10px 10px 20px 10px"
                            }),
                            option: (baseStyles, { isSelected, isFocused }) => ({
                                ...baseStyles,
                                backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                cursor: 'pointer'
                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: 'none',
                            },
                        })}
                        onChange={(value) => {
                            setSelectedCalendar(value!.value)
                        }}
                        value={calendarOptions.find((data) => data.value === selectedCalendar)}
                        className='react-select-styled me-3 z-index-2'
                        classNamePrefix='react-select'
                        options={calendarOptions}
                        placeholder='Pilih Scope'
                        isSearchable={true}
                    />
                    <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                        {renderChecklist()}
                    </div>
                </DropdownButton>
                <div className='col-8 col-sm-6 col-lg-8 col-xl-8 pb-3'>
                    <div className="nav rounded-pill" style={{ width: "300px", margin: "auto", backgroundColor: "white" }}>
                        <button style={{ width: "100px", padding: "0.6rem 0px" }} className={`nav-link btn btn-active btn-active-primary fw-bolder btn-color-gray-600 rounded-pill ${viewMode === "month" && "active"}`}
                            onClick={() => {
                                calendarRef.current?.getApi().changeView('dayGridMonth')
                                setViewTitle(calendarRef.current?.getApi().view.title ?? '')
                                setViewMode("month")
                            }}
                        >
                            {intl.formatMessage({ id: "CALENDAR.LABEL.MONTH" })}
                        </button>
                        <button style={{ width: "100px", padding: "0.6rem 0px" }} className={`nav-link btn btn-active btn-active-primary fw-bolder btn-color-gray-600 rounded-pill ${viewMode === "week" && "active"}`}
                            onClick={() => {
                                calendarRef.current?.getApi().changeView('timeGridWeek')
                                setViewTitle(calendarRef.current?.getApi().view.title ?? '')
                                setViewMode("week")
                            }}
                        >
                            {intl.formatMessage({ id: "CALENDAR.LABEL.WEEK" })}
                        </button>
                        <button style={{ width: "100px", padding: "0.6rem 0px" }} className={`nav-link btn btn-active btn-active-primary fw-bolder btn-color-gray-600 rounded-pill ${viewMode === "day" && "active"}`}
                            onClick={() => {
                                calendarRef.current?.getApi().changeView('timeGridDay')
                                setViewTitle(calendarRef.current?.getApi().view.title ?? '')
                                setViewMode("day")
                            }}
                        >
                            {intl.formatMessage({ id: "CALENDAR.LABEL.DAY" })}
                        </button>
                    </div>
                </div>
                <div className='col-12 col-sm-3 col-lg-2 col-xl-2 text-end pb-3'>
                    <Button variant="primary" size="sm"
                        onClick={() => {
                            setStartDate(moment().toISOString())
                            setShowAddEventModal(true)
                        }}
                    >
                        <span>
                            <i className="fas fa-plus me-2"></i>
                            {intl.formatMessage({ id: "CALENDAR.ACTION.CREATE_NEW" })}
                        </span>
                    </Button>

                </div>
            </div>

            <div className="card p-3" onWheel={handleScroll} style={{ height: "100%" }} ref={modalRef}>
                <div className='d-flex justify-content-between align-items-center'>
                    <label style={{ fontSize: "x-large", fontWeight: "600" }}>{viewTitle}</label>
                    <div className='d-flex'>
                        <button className="btn btn-sm me-3" style={{ color: "#009ef7", border: "solid 2px #009ef7", padding: "6px 15px" }} onClick={handleTodayButtonClick}>{intl.formatMessage({ id: "CALENDAR.LABEL.TODAY" })}</button>
                        <div>
                            <button className="btn btn-sm me-1"
                                style={{ border: "solid 2px #009ef7", padding: "6px 15px" }}
                                onClick={() => {
                                    calendarRef.current?.getApi().prev()
                                    setViewTitle(calendarRef.current?.getApi().view.title ?? '')
                                }}
                            >
                                <i className="fas fa-chevron-left" style={{ color: "#009ef7" }}></i>
                            </button>
                            <button className="btn btn-sm"
                                style={{ border: "solid 2px #009ef7", padding: "6px 15px" }}
                                onClick={() => {
                                    calendarRef.current?.getApi().next()
                                    setViewTitle(calendarRef.current?.getApi().view.title ?? '')
                                }}
                            >
                                <i className="fas fa-chevron-right" style={{ color: "#009ef7" }}></i>
                            </button>
                        </div>
                    </div>
                </div>

                {
                    isLoading ?
                        <div className="loading-overlay">
                            <div className="loader"></div>
                        </div>
                        :
                        <FullCalendar
                            nowIndicator={true}
                            timeZone={moment.tz.guess()}
                            ref={calendarRef}
                            plugins={[momentTimezonePlugin, rrulePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            // googleCalendarApiKey={process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY}
                            // eventSources={[
                            //     {
                            //         googleCalendarId: 'id.indonesian#holiday@group.v.calendar.google.com',
                            //         color: '#d92929',
                            //         textColor: 'white',
                            //         editable: false
                            //     }
                            // ]}
                            initialView='dayGridMonth'
                            // editable={true}
                            droppable={true}
                            dayMaxEventRows={2}
                            dayMaxEvents={2}
                            eventMaxStack={2}
                            navLinks={true}
                            dateClick={handleDateClick}
                            // eventChange={handleEventChange}
                            height={"100%"}
                            headerToolbar={{
                                left: '',
                                center: '',
                                right: ''
                            }}
                            locale={locale}
                            eventTimeFormat={{
                                hour: "numeric",
                                minute: "2-digit",
                                meridiem: false,
                            }}
                            eventClick={handleEventClick}
                            // moreLinkClick={handleMoreLinkClick}
                            themeSystem="Flatly"
                        />
                }

            </div>
            {
                showAddCalendarModal && <AddCalendar />
            }
            {
                showAddEventModal && <AddEvent startDate={startDate} calendarRef={calendarRef} listEvent={listEvent} setListEvent={setListEvent} selectedCalendars={selectedCalendars} setSelectedCalendars={setSelectedCalendars} calendar={dataCalendars} sortCalendar={sortCalendar} />
            }
            {
                showDetailEventModal && <DetailEvent eventData={event!} calendar={calendar} calendarRef={calendarRef} listEvent={listEvent} setListEvent={setListEvent} selectedCalendars={selectedCalendars} />
            }
        </>
    );
}

export { MasterSchedule }