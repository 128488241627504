import { useEffect } from "react"
import { useState } from "react"
import { useCustomeStore } from "../stores/CustomeStore"

export type CompanyType = 'happyPlay' | 'rainbowEdu' | 'giaGroup' | 'oky'

const useShowCompanyById = (id: string) => {
    const [company, setCompany] = useState<CompanyType | null>(null)
    const [companyChatToken, setCompanyChatToken] = useState<string>('')
    const { companyId } = useCustomeStore()

    useEffect(() => {
        if (id === companyId.happyPlay) {
            setCompany('happyPlay')
            setCompanyChatToken('custom/happy-play/chat-tokens')
        } else if (id === companyId.rainbowEdu) {
            setCompany('rainbowEdu')
            setCompanyChatToken('custom/rainbow-edu/chat-tokens')
        } else if (id === companyId.giaGroup) {
            setCompany('giaGroup')
            setCompanyChatToken('custom/gia-group/chat-tokens')
        } else if (id === companyId.oky) {
            setCompany('oky')
            setCompanyChatToken('custom/oky/chat-tokens')
        } else {
            setCompany(null)
        }
    }, [id, companyId])

    return { company, companyChatToken }
}

export default useShowCompanyById