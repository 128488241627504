import { FC, useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";

interface CropperBannerProps {
    originalImage: string
    setAttachment: (banner: string) => void
    deleteAttachment: () => void
}

const CropperBanner: FC<CropperBannerProps> = ({ originalImage, setAttachment, deleteAttachment }) => {

    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement | null>(null);
    const deleteModalRef = useRef<HTMLButtonElement | null>(null);

    const cropperRef = useRef<ReactCropperElement>(null);
    const [base64Crop, setBase64Crop] = useState('');

    const intl = useIntl();

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;

        // Mendapatkan canvas hasil cropper
        const croppedCanvas = cropper?.getCroppedCanvas();

        // Menentukan ukuran canvas yang diinginkan
        const targetWidth = 1920; // Ganti dengan ukuran yang diinginkan
        const targetHeight = 1080; // Ganti dengan ukuran yang diinginkan

        // Membuat canvas baru dengan ukuran yang diinginkan
        const scaledCanvas = document.createElement('canvas');
        scaledCanvas.width = targetWidth;
        scaledCanvas.height = targetHeight;

        // Menggambar gambar hasil cropper ke canvas baru dengan ukuran yang diinginkan
        const scaledContext = scaledCanvas.getContext('2d');
        if (scaledContext) {
            scaledContext?.drawImage(
                croppedCanvas!,
                0,
                0,
                Number(croppedCanvas?.width),
                Number(croppedCanvas?.height),
                0,
                0,
                targetWidth,
                targetHeight
            );

            // Menampilkan canvas atau melakukan operasi lain yang diinginkan
            setBase64Crop(scaledCanvas.toDataURL());
        }
    };

    return (
        <div>
            <div className="modal" tabIndex={-1} id="cropper-banner-modal" ref={modalRef} data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.CROP" })} {intl.formatMessage({ id: "FORM.LABEL.BANNER" })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                            </div>
                        </div>
                        <div className="modal-body">
                            <Cropper
                                // modal={false}
                                // dragMode="move"
                                src={originalImage}
                                style={{ height: "400px", width: "100%" }}
                                preview={".img-preview"}
                                // zoomTo={1}
                                aspectRatio={16 / 9}
                                guides={false}
                                // highlight={false}
                                crop={onCrop}
                                ref={cropperRef}
                            // movable={false}
                            // viewMode={1}
                            // autoCropArea={1}
                            // cropBoxResizable={false}
                            // cropBoxMovable={false}
                            // toggleDragModeOnDblclick={false}
                            // scalable={false}
                            // zoomOnTouch={false}
                            // zoomOnWheel={false}
                            />
                            {/* <h2 className="mt-2">Pratinjau</h2>
                            <div
                                className="img-preview border border-2 border-primary"
                                draggable={false}
                                style={{ width: "100%", float: "left", height: "300px" }}
                            /> */}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={deleteModalRef}
                                onClick={deleteAttachment}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => deleteModalRef.current?.click()}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>

                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button className="btn btn-primary" type="submit" onClick={() => {
                                setAttachment(base64Crop)
                                closeModalRef.current?.click()
                                Swal.fire({
                                    icon: "success",
                                    title: `${intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" })}!`,
                                    text: `${intl.formatMessage({ id: "FORM.VALIDATION.SUCCESS" }, {title: intl.formatMessage({id: "FORM.ACTION.CROP.2"})})}.`,
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            }}>
                                {intl.formatMessage({ id: "FORM.ACTION.CROP" })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { CropperBanner }