import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const INSERT_TARGET = `${API_URL}user-target`

export const insertTarget = async (ownerId: string, value: string, dateStart: string, targetType: string, token: string) => {
    return axios.post(INSERT_TARGET, {
        ownerId,
        value,
        dateStart,
        targetType
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}
