import { create } from 'zustand';
import { UserSession } from '../interfaces/User';

type UserSessionState = {
    userSessions: UserSession[];
    setUserSessions: (pipeline: UserSession[]) => void;
}

export const useUserSessionStore = create<UserSessionState>((set, getState) => ({
    userSessions: [],

    setUserSessions: (userSessions) => {
        set({ userSessions });
    },
}));