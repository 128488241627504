import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Formik } from 'formik'
import { KTSVG } from '../../../../_metronic/helpers'
import { formatFileSize } from '../../../../functions/email'

interface DragAndDropFileProps {
  onDrop: (acceptedFiles: File[]) => void
  onClose: () => void
  values: File[]
  totalFileSize?: number
  setTotalFileSize?: React.Dispatch<React.SetStateAction<number>>
  valuesDragAndDropFile?: File[]
  setValuesDragAndDropFile?: Dispatch<SetStateAction<File[]>>
  setTemporaryFile?: Dispatch<SetStateAction<File[]>>
  onSave?: () => void
}

const DragAndDropFile: React.FC<DragAndDropFileProps> = ({
  onDrop,
  onClose,
  values,
  totalFileSize,
  setTotalFileSize,
  valuesDragAndDropFile,
  setValuesDragAndDropFile,
  setTemporaryFile,
  onSave
}) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [concatFile, setConcatFile] = useState<File[]>([])

  const handleFileDrop = (acceptedFiles: File[]) => {
    const newFiles = [...concatFile, ...acceptedFiles];
    setConcatFile(newFiles);
    setValuesDragAndDropFile?.(newFiles);
    const newTotalSize = newFiles.reduce((total, file) => total + file.size, 0);
    setTotalFileSize?.(newTotalSize);
    setIsDisabled(newTotalSize > 25 * 1024 * 1024);
    onDrop(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileDrop,
    multiple: true,
  })

 const removeFileForConcat = (index: number) => {
    const updatedFiles = concatFile.filter((_, i) => i !== index);
    setConcatFile(updatedFiles);
    setValuesDragAndDropFile?.(updatedFiles);
    if (setTotalFileSize) {
      const newTotalSize = updatedFiles.reduce((total, file) => total + file.size, 0);
      setTotalFileSize(newTotalSize);
      setIsDisabled(newTotalSize > 25 * 1024 * 1024);
    }
  }

  const handleCancel = () => {
    setConcatFile([]);
    setValuesDragAndDropFile?.([]);
    const initialTotalSize = values.reduce((total, file) => total + file.size, 0);
    setTotalFileSize?.(initialTotalSize);
    setIsDisabled(initialTotalSize > 25 * 1024 * 1024);
    onClose();
  };

  const handleSave = () => {
    setTemporaryFile?.([...concatFile]);
    const newTotalSize = concatFile.reduce((total, file) => total + file.size, 0);
    setTotalFileSize?.(newTotalSize);
    setValuesDragAndDropFile?.([...concatFile]);
    setIsDisabled(newTotalSize > 25 * 1024 * 1024);
    onSave?.();
    onClose();
  }

  useEffect(() => {
    const initialFiles = values.length > 0 ? values : valuesDragAndDropFile || [];
    setConcatFile(initialFiles);
    setValuesDragAndDropFile?.(initialFiles);
    const initialTotalSize = initialFiles.reduce((total, file) => total + file.size, 0);
    setTotalFileSize?.(initialTotalSize);
    setIsDisabled(initialTotalSize > 25 * 1024 * 1024);
  }, []);

  return (
    <div className='modal' style={{ display: 'block' }}>
      <div
        onClick={handleCancel}
        className='modal-overlay'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
      <div
        className='modal-dialog'
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000,
          width: '600px',
          maxWidth: '90%',
        }}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' style={{ fontSize: '24px' }}>
              Lampiran
            </h5>
            <span
              className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
              onClick={handleCancel}
            >
              <span className='svg-icon svg-icon-1'>
                <KTSVG path='/media/icons/duotune/general/gen034.svg' />
              </span>
            </span>
          </div>
          <div className='modal-body'>
            <div
              style={{
                backgroundColor: '#F6F6F6',
                border: `2px dashed ${isDragActive ? '#009EF7' : '#B2B2B2'}`,
                borderRadius: '8px',
                padding: '24px',
                textAlign: 'center',
                transition: 'background-color 0.3s ease',
                cursor: 'pointer',
                margin: '10px 24px 10px 24px',
              }}
              {...getRootProps({
                className: 'dropzone dropzones-queue',
              })}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <div>
                  <img
                    style={{ width: '60px', height: '60px' }}
                    src='/media/icons/duotune/general/images.svg'
                    alt='images-icon'
                  />
                  <p
                    style={{
                      marginTop: '16px',
                      color: '#333',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    Letakkan file di sini ...
                  </p>
                </div>
              ) : (
                <div>
                  <img
                    style={{ width: '60px', height: '60px' }}
                    src='/media/icons/duotune/general/images.svg'
                    alt='images-icon'
                  />
                  <p
                    style={{
                      marginTop: '16px',
                      color: '#333',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    Seret & Lepaskan, atau <span style={{ color: '#0066CC' }}>telusuri</span>
                  </p>
                </div>
              )}
            </div>
            <div
              style={{
                display: 'block',
                maxHeight: '120px',
                overflowY: 'auto',
              }}
            >
              {concatFile.map((file: File, index: number) => (
                <div className='dropzone dropzone-queue px-8 py-1' key={index}>
                  <div
                    className='dropzone-items'
                    style={{ padding: '7px', borderRadius: '5px', backgroundColor: '#f1faff' }}
                  >
                    <div
                      className='dropzone-item d-flex justify-content-between'
                      style={{ alignItems: 'center' }}
                    >
                      <div className='dropzone-file'>
                        <div className='dropzone-filename' style={{ color: '#7e8299' }}>
                          <span>{file.name}</span>
                          <strong> ({formatFileSize(file.size)})</strong>
                        </div>
                      </div>
                      <div className='dropzone-toolbar'>
                        <span
                          className='dropzone-delete cursor-pointer'
                          onClick={() => removeFileForConcat(index)}
                        >
                          <i className='bi bi-x fs-1'></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='modal-footer'>
            {totalFileSize && totalFileSize > 25 * 1024 * 1024 ? (
              <div className='text-danger mb-2 w-100 ms-10'>
                Total ukuran file attachment melebihi batas 25MB. Mohon kurangi ukuran file.
              </div>
            ) : (
              <></>
            )}
            <span onClick={handleCancel} className='btn btn-secondary'>
              Batal
            </span>
            <span
              onClick={isDisabled ? undefined : handleSave}
              className={`btn btn-primary ${isDisabled ? 'disabled' : ''}`}
              style={{
                pointerEvents: isDisabled ? 'none' : 'auto',
                opacity: isDisabled ? 0.5 : 1,
              }}
            >
              Simpan
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DragAndDropFile
