import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import useAccessToken from "../../hooks/AccessToken";
import { useSettingsStore } from "../../stores/SettingsStore";
import { Merchant, MergeMerchantProps } from "../../interfaces/Merchant";
import 'react-phone-input-2/lib/style.css'
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { mergeMerchants } from "../../api/MerchantsCRUD";
import Swal from "sweetalert2";
import { mutate } from "swr";
import { TableColumn } from "react-data-table-component";
import { getFileType, getNameUrl, isUUID } from "../../functions/general";
import { CustomField } from "../../interfaces/Settings";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import moment from "moment";
import { useMerchantStore } from "../../stores/MerchantStore";
import { useParams } from "react-router-dom";

interface UpdatePhoneMerchantProps {
    merchant: Merchant
    column: TableColumn<Merchant>[]
}

interface ColumnSelectProps {
    id: string
    value: string
    same: boolean
}

const MergeMerchant: FC<UpdatePhoneMerchantProps> = ({ merchant, column }) => {
    const API_URL = process.env.REACT_APP_API_URL
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { templateMerchantId } = useParams()
    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const { selectMultiMerchant } = useMerchantStore()
    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()
    const { settings } = useSettingsStore()
    const [stepForm, setStepForm] = useState("1");
    const [searchMerchant, setSearchMerchant] = useState("");
    const [selectedMerchantId, setSelectedMerchantId] = useState("");
    const [listMerchant, setListMerchant] = useState<Merchant[]>([]);
    const [dataOrigin, setDataOrigin] = useState({} as MergeMerchantProps);
    const [dataDestination, setDataDestination] = useState({} as MergeMerchantProps);
    const [columnSelect, setColumnSelect] = useState({} as any);
    const [checkAll, setCheckAll] = useState({ origin: false, destination: false });
    const [customFields, setCustomFields] = useState<CustomField>({})
    const [countValSame, setCountValSame] = useState<number>(0)
    const intl = useIntl()

    const validationSchema = Yup.object().shape({
        merchantDestinationId: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.CHOOSE" }, { title: settings.merchant_title })),
        // mergeDataId: (stepForm === "2") ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.CHOOSE" }, { title: 'Data' })) : Yup.string().notRequired().nullable(),
    });

    const initialValues = {
        merchantDestinationId: '',
        merchantDestinationName: '',
        mergeDataId: ''
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (stepForm === "1") setStepForm("2")
            else {
                const isEmptyValues = Object.values(columnSelect).some(value => {
                    return value === ''
                });

                if (isEmptyValues) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.NOT_BEEN_SELECTED' })}`,
                        heightAuto: false,
                        target: document.getElementById('merge-merchant-modal'),
                        confirmButtonText: 'Ok'
                    })

                    return false
                }

                setModalBtnLoading(true)
                var mergeData: MergeMerchantProps = {
                    name: '',
                    phone: '',
                    cityId: '',
                    cityName: '',
                    email: '',
                    customFields: {},
                    userOwnerId: '',
                    userOwnerName: '',
                }

                Object.keys(columnSelect).map((key: string) => {
                    if (columnSelect[key] === 'origin') {
                        if (key === 'name') mergeData.name = dataOrigin.name
                        if (key === 'phone') mergeData.phone = dataOrigin.phone
                        if (key === 'email') mergeData.email = dataOrigin.email
                        if (key === 'city') {
                            mergeData.cityId = dataOrigin.cityId
                            mergeData.cityName = dataOrigin.cityName
                        }
                        if (key === 'pic') {
                            mergeData.userOwnerId = dataOrigin.userOwnerId
                            mergeData.userOwnerName = dataOrigin.userOwnerName
                        }
                        if (isUUID(key)) mergeData.customFields = { ...mergeData.customFields, [key]: dataOrigin.customFields[key] ? dataOrigin.customFields[key] : null }
                    } else if (columnSelect[key] === 'destination') {
                        if (key === 'name') mergeData.name = dataDestination.name
                        if (key === 'phone') mergeData.phone = dataDestination.phone
                        if (key === 'email') mergeData.email = dataDestination.email
                        if (key === 'city') {
                            mergeData.cityId = dataDestination.cityId
                            mergeData.cityName = dataDestination.cityName
                        }
                        if (key === 'pic') {
                            mergeData.userOwnerId = dataDestination.userOwnerId
                            mergeData.userOwnerName = dataDestination.userOwnerName
                        }
                        if (isUUID(key)) mergeData.customFields = { ...mergeData.customFields, [key]: dataDestination.customFields[key] ? dataDestination.customFields[key] : null }
                    }
                })

                try {
                    const res = await mergeMerchants(merchant.id, values.merchantDestinationId, mergeData, token)
                    if (res.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: 'ALERT.TITLE.SUCCESS' }),
                            text: intl.formatMessage({ id: 'FORM.VALIDATION.MERGE_SUCCESS' }),
                            heightAuto: false,
                        });
                        closeModalRef.current?.click();
                        mutate(`${API_URL}merchants?merchantTemplateId=${templateMerchantId}`);
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        heightAuto: false,
                        confirmButtonText: 'Ok'
                    });
                } finally {
                    setModalBtnLoading(false)
                }
            }
        }
    });

    const handleSearch = (search: string) => {
        setSearchMerchant(search);

        if (search) {
            const dataSearch = tablesData['merchants'].filter((merchant: Merchant) =>
                merchant.name.toLowerCase().includes(search.toLowerCase()) ||
                merchant.cityName?.toLowerCase().includes(search.toLowerCase())
            );
            setListMerchant(dataSearch);
        } else {
            setListMerchant(tablesData['merchants']);
        }
    };

    const returnCustomField = (key: string, type: string) => {
        var listCustomField = customFields[key];
        var dataCustomField = type === 'origin' ? dataOrigin.customFields[key] as any : dataDestination.customFields[key] as any;
        var dataCustomFieldMercahnt = type === 'origin' ? dataOrigin as any : dataDestination as any;

        if (!dataCustomField) {
            return "-";
        }

        if (listCustomField.type === 'merchant') {
            return (dataCustomFieldMercahnt as any)[`custom_fields.${key}`] ?? "-";
        }

        if (listCustomField.type === 'multiple') {
            return dataCustomField.toString().split(',').join(', ')
        }

        if (listCustomField.type === 'date') {
            return moment(dataCustomField).format("DD MMM YYYY")
        }

        if (listCustomField.type === 'link') {
            return (
                <a href={dataCustomField} rel="noreferrer" className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600" target="_blank">
                    Lihat <i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i>
                </a>
            )
        }

        if (listCustomField.type === 'files' || listCustomField.type === 'file') {
            if (typeof dataCustomField == "string") {
                return (
                    <div className="d-flex align-items-center border border-dashed border-gray-300 rounded ps-2 mb-3">
                        {
                            getFileType(dataCustomField) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} alt="" style={{ width: "20px" }} />
                                : getFileType(dataCustomField) === "doc" || getFileType(dataCustomField) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} alt="" style={{ width: "20px" }} />
                                    : getFileType(dataCustomField) === "ppt" || getFileType(dataCustomField) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} alt="" style={{ width: "20px" }} />
                                        : getFileType(dataCustomField) === "xlsx" || getFileType(dataCustomField) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} alt="" style={{ width: "20px" }} />
                                            : <img src={toAbsoluteUrl('/media/logos/file.png')} alt="" style={{ width: "20px" }} />
                        }
                        <a href={dataCustomField} className="m-3" target="_blank" rel="noopener noreferrer">{getNameUrl(dataCustomField)}</a>
                    </div>
                );
            } else {
                return (
                    <>
                        {dataCustomField!.map((data: string, index: number) => {
                            return (
                                <div key={index} className="d-flex align-items-center border border-dashed border-gray-300 rounded ps-2 mb-3">
                                    {
                                        getFileType(data) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} alt="" style={{ width: "20px" }} />
                                            : getFileType(data) === "doc" || getFileType(data) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} alt="" style={{ width: "20px" }} />
                                                : getFileType(data) === "ppt" || getFileType(data) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} alt="" style={{ width: "20px" }} />
                                                    : getFileType(data) === "xlsx" || getFileType(data) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} alt="" style={{ width: "20px" }} />
                                                        : <img src={toAbsoluteUrl('/media/logos/file.png')} alt="" style={{ width: "20px" }} />
                                    }
                                    <a href={data} className="m-3" target="_blank" rel="noopener noreferrer">{getNameUrl(data)}</a>
                                </div>
                            );
                        })}
                    </>
                );
            }
        }

        if (listCustomField.type === 'images' || listCustomField.type === 'image') {
            if (typeof dataCustomField == "string") {
                return (
                    <div className="d-flex align-items-center border border-dashed border-gray-300 rounded ps-2 mb-3">
                        <img src={toAbsoluteUrl('/media/logos/image.png')} alt="" style={{ width: "20px" }} />
                        <a href={dataCustomField} className="m-3" target="_blank" rel="noopener noreferrer">{getNameUrl(dataCustomField)}</a>
                    </div>
                );
            } else {
                return (
                    <>
                        {dataCustomField!.map((data: string, index: number) => {
                            return (
                                <div className="d-flex align-items-center border border-dashed border-gray-300 rounded ps-2 mb-3">
                                    <img src={toAbsoluteUrl('/media/logos/image.png')} alt="" style={{ width: "20px" }} />
                                    <a key={index} href={data} className="m-3" target="_blank" rel="noopener noreferrer">{getNameUrl(data)}</a>
                                </div>
                            );
                        })}
                    </>
                );
            }
        }

        return dataCustomField;
    };

    const checkValueSame = (data: any) => {
        var val = false
        if (data.id === 'name') {
            if (dataOrigin.name === dataDestination.name) {
                columnSelect.name = 'origin'
                val = true
            } else val = false
        }
        if (data.id === 'city') {
            if (dataOrigin.cityName === dataDestination.cityName) {
                columnSelect.city = 'origin'
                val = true
            } else val = false
        }
        if (data.id === 'phone') {
            if (dataOrigin.phone === dataDestination.phone) {
                columnSelect.phone = 'origin'
                val = true
            } else val = false
        }
        if (data.id === 'pic') {
            if (dataOrigin.userOwnerName === dataDestination.userOwnerName) {
                columnSelect.pic = 'origin'
                val = true
            } else val = false
        }
        if (isUUID(data.id as string)) {
            if (returnCustomField(data.id as string, 'origin') === returnCustomField(data.id as string, 'destination')) {
                columnSelect[data.id] = 'origin'
                val = true
            } else val = false
        }

        return val
    }

    const handleCheckValue = (data: TableColumn<Merchant>, type: "origin" | "destination") => {
        let newColumnSelect = { ...columnSelect };
        let countColumnSelect = Object.values(newColumnSelect).length - 1;
        let totalVal = Object.values(newColumnSelect).filter(value => value === type).length;
        let checkCountVal = countColumnSelect - countValSame
        newColumnSelect[data.id as string] = type;

        if (checkCountVal === totalVal) setCheckAll({ origin: type === "origin", destination: type === "destination" })
        else setCheckAll({ origin: false, destination: false })
        setColumnSelect(newColumnSelect);
    }

    const handleCheckAll = (type: string) => {
        let newColumnSelect = { ...columnSelect };
        Object.keys(newColumnSelect).forEach((key) => {
            newColumnSelect[key] = type;
        });

        setColumnSelect(newColumnSelect);
        setCheckAll({
            origin: type === 'origin',
            destination: type === 'destination',
        })
    }

    const handleBack = () => {
        setStepForm("1")
        setColumnSelect({})
        setCheckAll({ origin: false, destination: false })
    }

    useEffect(() => {
        if (formik.values.merchantDestinationId) {
            const origin = tablesData['merchants'].find((data: Merchant) => data.id === merchant.id);
            const destination = tablesData['merchants'].find((data: Merchant) => data.id === formik.values.merchantDestinationId);

            if (origin) {
                setDataOrigin({
                    ...origin,
                    userOwnerId: origin.ownerId,
                    userOwnerName: origin.ownerName,
                });
                // setDataOrigin({
                //     name: origin.name,
                //     phone: origin.phone,
                //     cityId: origin.cityId,
                //     cityName: origin.cityName,
                //     userOwnerId: origin.ownerId,
                //     userOwnerName: origin.ownerName,
                //     email: origin.email,
                //     customFields: origin.customFields,
                // });
            }

            if (destination) {
                setDataDestination({
                    ...destination,
                    userOwnerId: destination.ownerId,
                    userOwnerName: destination.ownerName,
                });
                // setDataDestination({
                //     name: destination.name,
                //     phone: destination.phone,
                //     cityId: destination.cityId,
                //     cityName: destination.cityName,
                //     userOwnerId: destination.ownerId,
                //     userOwnerName: destination.ownerName,
                //     email: destination.email,
                //     customFields: destination.customFields,
                // });
            }

            let newColumnSelect = { ...columnSelect };
            let count = 0
            if (origin.name === destination.name) { newColumnSelect.name = "origin"; count++ }
            if (origin.phone === destination.phone) { newColumnSelect.phone = "origin"; count++ }
            if (origin.cityId === destination.cityId) { newColumnSelect.city = "origin"; count++ }
            if (origin.ownerId === destination.ownerId) { newColumnSelect.pic = "origin"; count++ }

            column.forEach((data: TableColumn<Merchant>) => {
                const id = data.id as string;

                if (isUUID(id) && ((origin.customFields[id] === destination.customFields[id]) || (!origin.customFields[id] && !destination.customFields[id]))) {
                    newColumnSelect[id] = "origin";
                    count++
                }
            });

            setCountValSame(count)
            setColumnSelect(newColumnSelect);

        }
    }, [formik.values.merchantDestinationId])

    useEffect(() => {
        setListMerchant(tablesData['merchants'])
    }, [tablesData['merchants']])

    useEffect(() => {
        const newDataColumn = column.reduce((acc: any, data: TableColumn<Merchant>) => {
            if (
                data.id !== 'fixedLeft' &&
                data.id !== 'lastMeeting' && data.id !== 'lastPipeline' &&
                data.id !== 'countPipeline' && data.id !== 'countMeeting' &&
                data.id !== 'creatorName' && data.id !== 'dateCreated' && data.id !== 'whatsapp'
            ) {
                acc[data.id as string] = "";
            }
            return acc;
        }, {});

        setColumnSelect(newDataColumn);
    }, [selectedMerchantId]);

    useEffect(() => {
        if (selectMultiMerchant) {
            setCustomFields(selectMultiMerchant.customFields)
        }
    }, [selectMultiMerchant])

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            formik.setFieldValue('phone', "+62")
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id={`merge-merchant-modal-${merchant.id}`} ref={modalRef}>
                <div className="modal-dialog mw-1000px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>{intl.formatMessage({ id: "FORM.ACTION.MERGE_DATA" })} {settings.merchant_title}</h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleCloseModal}>
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="modal-body scroll-y m-5">
                            <div className="stepper stepper-links d-flex flex-column first" id="kt_create_account_stepper" data-kt-stepper="true">
                                <div className="stepper-nav py-5">
                                    <div className={`stepper-item ${stepForm === "1" ? "current" : "pending"}`} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title">{settings.merchant_title}</h3>
                                    </div>
                                    <div className={`stepper-item ${stepForm === "2" ? "current" : "pending"}`} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title">Data</h3>
                                    </div>
                                </div>
                                <form className="mx-auto w-100 p-10 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={formik.handleSubmit} noValidate>
                                    <div className={`${stepForm === "1" ? "current" : "pending"}`} data-kt-stepper-element="content">
                                        <div className="w-100">
                                            <div className="pb-10">
                                                <h2 className="fw-bolder text-dark">{intl.formatMessage({ id: "FORM.LABEL.CHOOSE" })} {settings.merchant_title}</h2>
                                                <div className="text-muted fw-bold fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_MERGE_DATA_1" }, { title: settings.merchant_title })} <b className="text-black">{merchant.name}</b></div>
                                            </div>
                                            <input type="search" className="form-control form-control-solid mb-3" placeholder={intl.formatMessage({ id: "FORM.LABEL.SEARCH" })} value={searchMerchant} onChange={(e) => handleSearch(e.target.value)} />
                                            <div className="pb-10 fv-row fv-plugins-icon-container pe-3" style={{ overflowY: "auto", maxHeight: "300px" }}>
                                                {listMerchant.map((dataMerchant: Merchant) => {
                                                    if (dataMerchant.id !== merchant.id) {
                                                        return (
                                                            <label
                                                                className="d-flex flex-stack mb-5 cursor-pointer"
                                                                key={dataMerchant.id}
                                                                onClick={() => {
                                                                    formik.setFieldValue('merchantDestinationId', dataMerchant.id);
                                                                    formik.setFieldValue('merchantDestinationName', dataMerchant.name);
                                                                    setSelectedMerchantId(dataMerchant.id); // Set selected merchant
                                                                }}
                                                            >
                                                                <span className="d-flex align-items-center me-2">
                                                                    <span className="d-flex flex-column">
                                                                        <span className="fw-bolder text-gray-800 fs-5">{dataMerchant.name}</span>
                                                                        <span className="fs-6 fw-bold text-muted">{dataMerchant.cityName}</span>
                                                                    </span>
                                                                </span>
                                                                <span className="form-check form-check-custom form-check-solid">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        id={`${dataMerchant.id}`}
                                                                        name="merchantDestination" // Same name for all radio buttons
                                                                        checked={selectedMerchantId === dataMerchant.id} // Control checked state
                                                                        onChange={() => setSelectedMerchantId(dataMerchant.id)} // Update selected state
                                                                    />
                                                                </span>
                                                            </label>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                            {formik.touched.merchantDestinationId && formik.errors.merchantDestinationId && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{formik.errors.merchantDestinationId}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`${stepForm === "2" ? "current" : "pending"}`} data-kt-stepper-element="content">
                                        <div className="w-100">
                                            <div className="pb-10">
                                                <h2 className="fw-bolder d-flex align-items-center text-dark">{intl.formatMessage({ id: "FORM.LABEL.CHOOSE" })} Data</h2>
                                                <div className="text-muted fw-bold fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_MERGE_DATA_2" })}</div>
                                            </div>
                                            <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <div className="table-responsive" style={{ maxHeight: "300px" }}>
                                                    <table className="table table-row-dashed border-gray-300 align-middle gy-6">
                                                        <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                                                            <tr>
                                                                <td className="fs-4 fw-bolder">{intl.formatMessage({ id: "FORM.LABEL.COLUMN" })}</td>
                                                                <td className="min-w-200px">
                                                                    <div className="form-check form-check-solid">
                                                                        <input className="form-check-input" type="radio" id={'checkAll'} name={'checkAll'} checked={checkAll.origin} onChange={(e) => handleCheckAll('origin')} />
                                                                        <label className="form-check-label ps-2" htmlFor={'checkAll'}>{intl.formatMessage({ id: "FORM.ACTION.SELECT_ALL" })}</label>
                                                                    </div>
                                                                </td>
                                                                <td className="min-w-200px">
                                                                    <div className="form-check form-check-solid">
                                                                        <input className="form-check-input" type="radio" id={'checkAll'} name={'checkAll'} checked={checkAll.destination} onChange={(e) => handleCheckAll('destination')} />
                                                                        <label className="form-check-label ps-2" htmlFor={'checkAll'}>{intl.formatMessage({ id: "FORM.ACTION.SELECT_ALL" })}</label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="fs-6 fw-bold">
                                                            {
                                                                Object.keys(dataOrigin).length > 0 && Object.keys(dataDestination).length > 0 && column.map((data, index) => {
                                                                    if (
                                                                        data.id !== 'fixedLeft' && data.id !== 'lastMeeting' && data.id !== 'lastPipeline' &&
                                                                        data.id !== 'countPipeline' && data.id !== 'countMeeting' && data.id !== 'creatorName' &&
                                                                        data.id !== 'dateCreated' && data.id !== 'whatsapp'
                                                                    ) {
                                                                        return (
                                                                            <tr>
                                                                                <td>{data.name}</td>
                                                                                <td>
                                                                                    <div className="form-check form-check-solid">
                                                                                        {
                                                                                            !checkValueSame(data) && <input className="form-check-input" type="radio" id={`${data.id}-origin`} name={`${data.id}`} checked={columnSelect[data.id as string] === "origin"} onChange={() => handleCheckValue(data, 'origin')} />
                                                                                        }
                                                                                        {
                                                                                            data.id === 'name' ? <label className="form-check-label ps-2" htmlFor={`${data.id}-origin`}>{dataOrigin.name}</label>
                                                                                                : data.id === 'city' ? <label className="form-check-label ps-2" htmlFor={`${data.id}`}>{dataOrigin.cityName}</label>
                                                                                                    : data.id === 'phone' ? <label className="form-check-label ps-2" htmlFor={`${data.id}`}>{dataOrigin.phone}</label>
                                                                                                        : data.id === 'email' ? <label className="form-check-label ps-2" htmlFor={`${data.id}`}>{dataOrigin.email ?? "-"}</label>
                                                                                                            : data.id === 'pic' ? <label className="form-check-label ps-2" htmlFor={`${data.id}`}>{dataOrigin.userOwnerName}</label>
                                                                                                                : isUUID(data.id as string) ? returnCustomField(data.id as string, 'origin')
                                                                                                                    : <></>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-check form-check-solid">
                                                                                        {
                                                                                            !checkValueSame(data) && <input className="form-check-input" type="radio" id={`${data.id}-destination`} name={`${data.id}`} checked={columnSelect[data.id as string] === "destination"} onChange={() => handleCheckValue(data, 'destination')} />
                                                                                        }
                                                                                        {
                                                                                            data.id === 'name' ? <label className="form-check-label ps-2" htmlFor={`${data.id}-destination`}>{dataDestination.name}</label>
                                                                                                : data.id === 'city' ? <label className="form-check-label ps-2" htmlFor={`${data.id}`}>{dataDestination.cityName}</label>
                                                                                                    : data.id === 'phone' ? <label className="form-check-label ps-2" htmlFor={`${data.id}`}>{dataDestination.phone}</label>
                                                                                                        : data.id === 'email' ? <label className="form-check-label ps-2" htmlFor={`${data.id}`}>{dataDestination.email ?? "-"}</label>
                                                                                                            : data.id === 'pic' ? <label className="form-check-label ps-2" htmlFor={`${data.id}`}>{dataDestination.userOwnerName}</label>
                                                                                                                : isUUID(data.id as string) ? returnCustomField(data.id as string, 'destination')
                                                                                                                    : <></>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {formik.touched.mergeDataId && formik.errors.mergeDataId && (
                                                    <div className='fv-plugins-message-container text-danger'>
                                                        <span role='alert' className="text-danger">{formik.errors.mergeDataId}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-light d-none"
                                        data-bs-dismiss="modal"
                                        ref={closeModalRef}
                                    >
                                        {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                    </button>
                                    {
                                        stepForm === "2" &&
                                        <div className="d-flex flex-stack mt-10">
                                            <button type="button" className="btn btn-light" onClick={handleBack}>
                                                <span className="svg-icon svg-icon-4 me-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"></rect>
                                                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black"></path>
                                                    </svg>
                                                </span>
                                                {intl.formatMessage({ id: "FORM.ACTION.BACK" })}
                                            </button>
                                            <button type="submit" className={`btn btn-primary ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                                <span className="indicator-label">
                                                    {intl.formatMessage({ id: "FORM.ACTION.MERGE_DATA" })}
                                                </span>
                                                <span className="indicator-progress">
                                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                    }

                                    {
                                        stepForm === "1" &&
                                        <div className="d-flex flex-stack justify-content-end mt-10">
                                            <button type="submit" className="btn btn-primary">
                                                {intl.formatMessage({ id: "FORM.ACTION.CONTINUE" })}
                                                <span className="svg-icon svg-icon-4 ms-1 me-0">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                                                        <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export { MergeMerchant }