import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const LIMITS = `${API_URL}reimbursement-limits/`;

export const insertLimit = async (
  ownerId: string,
  period: string,
  type: string,
  currencyId: number,
  amount: number,
  token: string
) => {
  return axios.post(
    LIMITS,
    {
      ownerId,
      period,
      type,
      currencyId,
      amount,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const insertLimitBulk = async (data: [], token: string) => {
  return axios.post(
    LIMITS + 'bulk',
    {
      data,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateLimit = async (id: string, amount: number, token: string) => {
  return axios.put(
    LIMITS + id,
    {
      amount,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const deleteLimit = async (id: string, token: string) => {
  return axios.delete(LIMITS + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const getLimitDetail = async (id: string, token: string) => {
  return axios.get(LIMITS + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};
