import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { useIntl } from 'react-intl';

export declare type ValueType = Date;
export declare type DateRange = Date;
export interface RangeType {
    label: React.ReactNode;
    value: DateRange | ((value?: ValueType) => DateRange);
    closeOverlay?: boolean;
    placement?: 'bottom' | 'left';
}

const useTodayRange = (compact: boolean = false) => {

    const intl = useIntl();

    const todayRange: RangeType[] = [
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.TODAY" }),
            value: new Date(),
            placement: 'bottom'
        },
    ];

    return todayRange;
}

export default useTodayRange;