import { useCallback, useEffect, useState } from 'react'
import { CardSendEmail } from '../../../components/Email/CardSendEmail'
import { customNumberFormat } from '../../../functions/general'
import { KTSVG } from '../../../_metronic/helpers'
import { useEmailStore } from '../../../stores/EmailStore'
import Swal from 'sweetalert2'
import { DetailEmail } from '../../../components/Email/DetailEmail'
import axios from 'axios'
import useSWR from 'swr'
import React from 'react'
import { deleteEmail, saveDraft } from '../../../api/EmailCRUD'
import SideBar from '../../../components/Email/SideBar'
import { ThreadedEmailDetail } from '../../../components/Email/ThreadedEmailDetail'
import { LoginEmail } from '../../../components/Email/LoginEmail'
import GroupEmail from '../../../components/Email/BroadcastEmail/table/GroupEmail'
import ProfileAvatar from '../../../components/Email/ProfileAvatar'
import AnalyticsBroadcast from '../../../components/Email/BroadcastEmail/table/BroadcastEmail'
import DetailGroupEmail from '../../../components/Email/BroadcastEmail/table/DetailGroupEmail'
import { useLocation } from 'react-router-dom';
import { CardEmail } from '../../../components/Email/CardEmail'
import DetailBroadcastEmail from '../../../components/Email/BroadcastEmail/table/DetailBroadcastEmail'
import DetailDraftBroadcast from '../../../components/Email/BroadcastEmail/table/DetailDraftBroadcast'
import { useUserStore } from '../../../hooks/UserEmail'

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL
const Emails = () => {
  const [qtySidebar, setQtySidebar] = useState({
    inbox: 0,
    // sent: 0,
    draft: 0,
    trash: 0,
    archive: 0,
    spam: 0,
  })
  const {
    openSendEmail,
    setValuesFromDraft,
    typeMenu,
    setTypeMenu,
    activeSidebar,
    setActiveSidebar,
    tokenEmail,
    setTokenEmail,
    typeEmail,
    setTypeEmail,
    valueSaveDraft,
    setValuesSaveDraft,
    valuesFromDraft,
    setOpenSendEmail,
    threadEmail,
    setShowSideBar,
    currentSubject,
    setDataEditValuesBroadcast,
    dataEditValuesBroadcast,
  } = useEmailStore()
  const { clearUserEmail, userEmail } = useUserStore()
  const location = useLocation()

  function checkCountZeroQtySidebar(value: number) {
    if (value === 0) {
      return ''
    } else {
      return `(${value})`
    }
  }

  useEffect(() => {
    const updateTitle = () => {
      if (typeEmail === 'detailEmail' || typeEmail === 'threadedEmail') {
        document.title = `${currentSubject} - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'formLogin') {
        document.title = 'Wooblazz Mail - Login'
      } else if (typeMenu === 'inbox') {
        document.title = `Inbox ${checkCountZeroQtySidebar(qtySidebar.inbox)} - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'sent') {
        document.title = `Sent - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'draft') {
        document.title = `Draft ${checkCountZeroQtySidebar(qtySidebar.draft)} - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'trash') {
        document.title = `Trash ${checkCountZeroQtySidebar(qtySidebar.trash)} - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'archive') {
        document.title = `Archive ${checkCountZeroQtySidebar(qtySidebar.archive)} - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'spam') {
        document.title = `Spam ${checkCountZeroQtySidebar(qtySidebar.spam)} - ${userEmail?.email} - Wooblazz Mail`
      } else {
        document.title = 'Wooblazz Mail'
      }
    }

    const timeoutId = setTimeout(updateTitle, 100)

    return () => clearTimeout(timeoutId)
  }, [typeMenu, setTypeMenu, typeEmail, currentSubject, userEmail?.email, qtySidebar])

  const fetcher = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          'X-auth-token': 'Bearer ' + tokenEmail,
        },
      })
      return response.data.data
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        setTypeEmail('formLogin')
        setTypeMenu('formLogin')
        setTokenEmail('')
        clearUserEmail()
      } else {
        throw error
      }
    }
  }

  const {
    data: dataCountEmails = [], // berisi sebuah data yang menampung object type mailbox : sent/inbox/archive, dan unseenCount
    mutate: countEmailsMutate,
    isValidating: countEmailIsValidating,
  } = useSWR(`${API_URL_EMAIL}dashboard/count-emails`, fetcher)

  const updateQtySidebar = useCallback(() => {
    if (!countEmailIsValidating) {
      setQtySidebar({
        inbox: dataCountEmails[0]?.unseenCount || 0,
        // sent: dataCountEmails[1]?.unseenCount || 0, 
        trash: dataCountEmails[2]?.unseenCount || 0,
        draft: dataCountEmails[5]?.unseenCount || 0,
        archive: dataCountEmails[4]?.unseenCount || 0,
        spam: dataCountEmails[3]?.unseenCount || 0,
      });
    }
  }, [typeEmail, countEmailIsValidating]);

  useEffect(() => {
    updateQtySidebar();
  }, [updateQtySidebar]);

  useEffect(() => {
    if (tokenEmail) {
      setTypeEmail('listEmail')
    } else setTypeEmail('formLogin')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const btnLogoutEmail = () => {
    Swal.fire({
      title: 'Keluar',
      text: 'Apakah Anda yakin ingin keluar?',
      icon: 'warning',
      showCancelButton: !0,
      buttonsStyling: !1,
      confirmButtonText: 'Ya, keluar',
      cancelButtonText: 'Tidak',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-active-light me-3',
      },
      reverseButtons: true,
      heightAuto: false,
      preConfirm: () => {
        setTokenEmail('')
        clearUserEmail()
        setTypeEmail('formLogin')
      },
    })
  }

  const handleSaveEmailToDraft = async () => {
    try {
      const response = await saveDraft(
        valueSaveDraft.from,
        valueSaveDraft.to,
        valueSaveDraft.subject,
        valueSaveDraft.body.trim(),
        valueSaveDraft.cc,
        valueSaveDraft.bcc,
        valueSaveDraft.files
      )
      if (response?.status === 200) {
        setValuesSaveDraft({
          from: userEmail?.email || '',
          to: [],
          subject: '',
          body: '',
          cc: '',
          bcc: '',
          files: [],
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      countEmailsMutate()
    }
  }

  useEffect(() => {
    countEmailsMutate()
  }, [typeMenu])

  useEffect(() => { // clear value ketika pindah menu
    if (dataEditValuesBroadcast && typeMenu !== 'sendMail') {
      setDataEditValuesBroadcast(null)
    }
  }, [typeMenu])

  useEffect(() => {
    if (typeEmail !== 'sendEmail') {
      setValuesFromDraft({ // untuk clear values from draft ketika pindah menu
        from: '',
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        body: '',
        attachments: [],
        date: '',
      })
    }
  }, [typeMenu, openSendEmail])

  const handleDeleteEmailOnDraft = async () => {
    try {
      const emailId = valuesFromDraft?.id
      const response = await deleteEmail(emailId, 'draft')
      return response
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // ini untuk ketika pindah menu email akan save ke draft
    if (
      typeMenu !== 'sendMail' &&
      (valueSaveDraft.to.length > 0 ||
        valueSaveDraft.body.length > 1 ||
        valueSaveDraft.subject ||
        (valueSaveDraft.files && valueSaveDraft.files.length >= 1))
    ) {
      if (valuesFromDraft?.id) {
        handleDeleteEmailOnDraft()
      }
      handleSaveEmailToDraft()
      countEmailsMutate()
      // ketika pindah menu setOpenSendEmail(false) <- ini di gunakan untuk conditional render ketika detailEmail ingin di edit jika true maka render emailValueFromDraft
      setOpenSendEmail(false)
    }
  }, [typeMenu])

  const handleRouteBroadcast = () => {
    if (location.pathname === '/broadcast') {
      setTypeEmail('broadcastEmail')
    } else if (location.pathname === '/group-contact') {
      setTypeEmail('groupEmail')
    }
  }

  useEffect(() => {
    handleRouteBroadcast()
  }, [location])

  const handleShowSideBar = () => {
    setShowSideBar((prevState: boolean) => !prevState)
  }

  const noNavbar = ['groupEmail', 'sendEmail', 'broadcastEmail', 'detailGroupEmail']

  return (
    <>
      {typeEmail === '' ? (
        <></>
      ) : typeEmail === 'formLogin' ? (
        <LoginEmail />
      ) : (
        <div
          id='kt_content_container'
          className=''
          style={{ margin: '0', maxWidth: 'unset', overflowWrap: 'anywhere' }}
        >
          <div className='d-flex flex-column flex-lg-row'>
            <SideBar
              setTypeEmail={setTypeEmail}
              activeSidebar={activeSidebar}
              btnLogoutEmail={btnLogoutEmail}
              qtySidebar={qtySidebar}
              setActiveSidebar={setActiveSidebar}
              setTypeMenu={setTypeMenu}
            />
            <div className='d-lg-block d-none bg-white flex-row-auto w-260px w-lg-275px' style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              overflowY: 'auto'
            }}>
              <div className='card card-flush' style={{ height: '100%', borderRadius: 0 }}>
                <span
                  className='d-flex justify-content-center align-content-center cursor-pointer m-3'
                  onClick={() => {
                    setTypeEmail('listEmail')
                    setTypeMenu('inbox')
                    setActiveSidebar({
                      inbox: 'active',
                      sent: '',
                      draft: '',
                      trash: '',
                      archive: '',
                      spam: '',
                      groupEmail: '',
                      broadcastEmail: ''
                    })
                  }}
                >
                  <img
                    src='/media/logos/wooblazz-email.png'
                    alt='Wooblazz Mail Logo'
                    style={{
                      maxWidth: '250px',
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                  />
                </span>
                <div className='card-body'>
                  <span
                    className='btn btn-primary d-flex justify-content-center align-content-center gap-3 text-uppercase w-100 mb-6'
                    onClick={() => {
                      setTypeEmail('sendEmail')
                      setTypeMenu('sendMail')
                      setActiveSidebar({
                        inbox: '',
                        sent: '',
                        draft: '',
                        trash: '',
                        archive: '',
                        spam: '',
                        groupEmail: '',
                        broadcastEmail: ''
                      })
                    }}
                  >
                    <img width={20} src='/media/svg/general/pen.svg' alt='icon-pen' />
                    <span className='my-auto'>Pesan Baru</span>
                  </span>
                  <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-5'>
                    <div className='menu-item mb-3'>
                      <span
                        className={`menu-link ${activeSidebar.inbox}`}
                        onClick={() => {
                          setTypeEmail('listEmail')
                          setTypeMenu('inbox')
                          setActiveSidebar({
                            inbox: 'active',
                            sent: '',
                            draft: '',
                            trash: '',
                            archive: '',
                            spam: '',
                            groupEmail: '',
                            broadcastEmail: ''
                          })
                        }}
                      >
                        <span className='menu-icon'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com010.svg'
                            className='svg-icon svg-icon-2 me-3'
                          />
                        </span>
                        <span className='menu-title fw-bolder'>Kotak Masuk</span>
                        {qtySidebar.inbox > 0 && (
                          <span className='badge badge-light-success'>
                            {customNumberFormat(qtySidebar.inbox)}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='menu-item mb-3'>
                      <span
                        className={`menu-link ${activeSidebar.sent}`}
                        onClick={() => {
                          setTypeEmail('listEmail')
                          setTypeMenu('sent')
                          setActiveSidebar({
                            inbox: '',
                            sent: 'active',
                            draft: '',
                            trash: '',
                            archive: '',
                            spam: '',
                            groupEmail: '',
                            broadcastEmail: ''
                          })
                        }}
                      >
                        <span className='menu-icon'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen016.svg'
                            className='svg-icon svg-icon-2 me-3'
                          />
                        </span>
                        <span className='menu-title fw-bolder'>Terkirim</span>
                        {/* {qtySidebar.sent > 0 && (
                          <span className='badge badge-light-primary'>
                            {customNumberFormat(qtySidebar.sent)}
                          </span>
                        )} */}
                      </span>
                    </div>
                    <div className='menu-item mb-3'>
                      <span
                        className={`menu-link ${activeSidebar.draft}`}
                        onClick={() => {
                          setTypeEmail('listEmail')
                          setTypeMenu('draft')
                          setActiveSidebar({
                            inbox: '',
                            sent: '',
                            draft: 'active',
                            trash: '',
                            archive: '',
                            spam: '',
                            groupEmail: '',
                            broadcastEmail: ''
                          })
                        }}
                      >
                        <span className='menu-icon'>
                          <KTSVG
                            path='/media/icons/duotune/files/fil003.svg'
                            className='svg-icon svg-icon-2 me-3'
                          />
                        </span>
                        <span className='menu-title fw-bolder'>Draf</span>
                        {qtySidebar.draft > 0 && (
                          <span className='badge badge-light-info'>
                            {customNumberFormat(qtySidebar.draft)}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='menu-item mb-3'>
                      <span
                        className={`menu-link ${activeSidebar.spam}`}
                        onClick={() => {
                          setTypeEmail('listEmail')
                          setTypeMenu('spam')
                          setActiveSidebar({
                            inbox: '',
                            sent: '',
                            draft: '',
                            trash: '',
                            archive: '',
                            spam: 'active',
                            groupEmail: '',
                            broadcastEmail: ''
                          })
                        }}
                      >
                        <span className='menu-icon'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon svg-icon-2 me-3'
                          />
                        </span>
                        <span className='menu-title fw-bolder'>Spam</span>
                        {qtySidebar.spam > 0 && (
                          <span className='badge badge-light-warning'>
                            {customNumberFormat(qtySidebar.spam)}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='menu-item mb-3'>
                      <span
                        className={`menu-link ${activeSidebar.archive}`}
                        onClick={() => {
                          setTypeEmail('listEmail')
                          setTypeMenu('archive')
                          setActiveSidebar({
                            inbox: '',
                            sent: '',
                            draft: '',
                            trash: '',
                            archive: 'active',
                            spam: '',
                            groupEmail: '',
                            broadcastEmail: ''
                          })
                        }}
                      >
                        <span className='menu-icon'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen056.svg'
                            className='svg-icon svg-icon-2 me-3'
                          />
                        </span>
                        <span className='menu-title fw-bolder'>Arsip</span>
                        {qtySidebar.archive > 0 && (
                          <span className='badge badge-light-dark'>
                            {customNumberFormat(qtySidebar.archive)}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='menu-item'>
                      <span
                        className={`menu-link ${activeSidebar.trash}`}
                        onClick={() => {
                          setTypeEmail('listEmail')
                          setTypeMenu('trash')
                          setActiveSidebar({
                            inbox: '',
                            sent: '',
                            draft: '',
                            trash: 'active',
                            archive: '',
                            spam: '',
                            groupEmail: '',
                            broadcastEmail: ''
                          })
                        }}
                      >
                        <span className='menu-icon'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon svg-icon-2 me-3'
                          />
                        </span>
                        <span className='menu-title fw-bolder'>Sampah</span>
                        {qtySidebar.trash > 0 && (
                          <span className='badge badge-light-danger'>
                            {customNumberFormat(qtySidebar.trash)}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{ borderTop: '0.8px solid #E4E4E4' }}
                    className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary pt-5 gap-3'
                  >
                    <div className='menu-item'>
                      <span
                        className={`menu-link ${activeSidebar.broadcastEmail}`}
                        onClick={() => {
                          setTypeEmail('broadcastEmail')
                          setTypeMenu('broadcastEmail')
                          setActiveSidebar({
                            inbox: '',
                            sent: '',
                            draft: '',
                            trash: '',
                            archive: '',
                            spam: '',
                            groupEmail: '',
                            broadcastEmail: 'active'
                          })
                        }}
                      >
                        <span className='menu-icon'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com002.svg'
                            className='svg-icon svg-icon-2 me-3'
                          />
                        </span>
                        <span className='menu-title fw-bolder'>Broadcast Email</span>
                      </span>
                    </div>
                    <div className='menu-item'>
                      <span
                        className={`menu-link ${activeSidebar.groupEmail}`}
                        onClick={() => {
                          setTypeEmail('groupEmail')
                          setTypeMenu('groupEmail')
                          setActiveSidebar({
                            inbox: '',
                            sent: '',
                            draft: '',
                            trash: '',
                            archive: '',
                            spam: '',
                            groupEmail: 'active',
                            broadcastEmail: ''
                          })
                          // navigate('/email/group-contact')
                        }}
                      >
                        <span className='menu-icon'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com014.svg'
                            className='svg-icon svg-icon-2 me-3'
                          />
                        </span>
                        <span className='menu-title fw-bolder'>Group Email</span>
                      </span>
                    </div>
                    <div className='menu-item' onClick={() => btnLogoutEmail()}>
                      <div className='menu-link'>
                        <span className='menu-icon'>
                          <span className='svg-icon svg-icon-2 me-3'>
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr077.svg'
                              className='svg-icon-2'
                            />
                          </span>
                        </span>
                        <span className='menu-title fw-bold'>Keluar</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column flex-grow-1'>
              {
                noNavbar.includes(typeEmail) && (
                  <div style={{ height: '5em', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }} className='d-flex bg-white container-fluid'>
                    <span className='cursor-pointer nav-hamburg d-lg-none d-block my-auto' onClick={handleShowSideBar}>
                      <img width={30} src='/media/svg/general/navHamburg.svg' alt='hamburg-nav' />
                    </span>
                    <span className='d-flex align-items-center' style={{ marginLeft: 'auto' }}>
                      {/* Navbar untuk mobile */}
                      <ProfileAvatar />
                    </span>
                  </div>
                )
              }
              <div className='container-fluid mt-5 email-body-content'>
                {typeEmail === 'listEmail' ? (
                  <CardEmail typeMenu={typeMenu} />
                ) : typeEmail === 'sendEmail' ? (
                  <CardSendEmail countEmailsMutate={countEmailsMutate} />
                  // <CardSendEmail />
                ) : typeEmail === 'threadedEmail' ? (
                  <ThreadedEmailDetail threadedEmails={Array.isArray(threadEmail) ? threadEmail : [threadEmail]} />
                  // <ThreadedEmailDetail threadedEmails={Array.isArray(threadEmail) ? threadEmail : [threadEmail]} />
                ) : typeEmail === 'detailEmail' ? (
                  <DetailEmail typeMenu={typeMenu} countEmailsMutate={countEmailsMutate} />
                  // <DetailEmail typeMenu={typeMenu} />
                ) : typeEmail === 'groupEmail' ? (
                  <GroupEmail />
                ) : typeEmail === 'detailGroupEmail' ? (
                  <DetailGroupEmail />
                ) : typeEmail === 'broadcastEmail' ? (
                  <AnalyticsBroadcast />
                ) : typeEmail === 'detailBroadcastEmail' ? (
                  <DetailBroadcastEmail />
                ) : typeEmail === 'detailDraftBroadcastEmail' ? (
                  <DetailDraftBroadcast />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  )
}

export { Emails }
