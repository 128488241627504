import { FC, useRef, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import { useDataTableStore } from '../../stores/DataTableStore'
import useAccessToken from '../../hooks/AccessToken'
import { Role } from '../../interfaces/Role'
import Select from 'react-select'
import { User } from '../../interfaces/User'
import { updateUser } from '../../api/UsersCRUD'
import { nanoid } from '@reduxjs/toolkit'
import useSWR from 'swr'
import axios from 'axios'
import { City } from '../../interfaces/City'
import { SelectOption } from '../../interfaces/SelectOption'
import { Leader } from '../../interfaces/Leader'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import { handleCaps, isValidUrl } from '../../functions/general'
import { AttendanceTemplate } from '../../interfaces/Attendance'
import { useCompanyStore } from '../../stores/CompanyStore'
import { useIntl } from 'react-intl'
import { useSettingsStore } from '../../stores/SettingsStore'
import { CustomField } from '../../interfaces/Settings'
import moment from 'moment'
import { DatePicker } from 'rsuite'
import useTodayRange from '../../hooks/TodayRange'
import { insertUserFieldAttachment, insertUserFieldMultiAttachment } from '../../api/ResourceAPI'
import useHandleCloseModal from '../../hooks/HandleCloseModal'
import InputAttachment from '../Input/InputAttachment'
import InputPhone from '../Input/InputPhone'

interface Props {
  userData: User
}

interface CityOption {
  value: string
  label: string
}

const UpdateUser: FC<Props> = ({ userData }) => {
  const modalRef = useRef<HTMLDivElement | null>(null)
  const closeModalRef = useRef<HTMLButtonElement>(null)
  const [modalBtnLoading, setModalBtnLoading] = useState(false)
  const { tablesData, setTablesData } = useDataTableStore()
  const token = useAccessToken()
  const [leadersData, setLeadersData] = useState<SelectOption[]>([])
  const [cityData, setCityData] = useState<SelectOption[]>([])
  const [roleData, setRoleData] = useState<SelectOption[]>([])
  const [templateData, setTemplateData] = useState<SelectOption[]>([])
  const { companies, setCompanies } = useCompanyStore()

  const { settings } = useSettingsStore()
  const [customFields, setCustomFields] = useState<CustomField>({})
  const refArray = useRef<(HTMLInputElement | null)[]>([])
  const todayRange = useTodayRange()

  const intl = useIntl()

  function getFileTypeFromUrl(url: any) {
    if (url && Object.keys(url).length !== 0) {
      var splitUrl = typeof url === 'object' ? url.name.split('.') : url.split('.')
      var fileExtension = splitUrl[splitUrl.length - 1]

      // Menentukan tipe file berdasarkan ekstensi
      switch (fileExtension.toLowerCase()) {
        case 'xlsx':
          return 'xlsx'
        case 'xlx':
          return 'xlsx'
        case 'doc':
          return 'doc'
        case 'ppt':
          return 'ppt'
        case 'pdf':
          return 'pdf'
        default:
          return ''
      }
    } else return ''
  }

  const fetcher = (url: string) =>
    axios
      .get(url, {
        headers: {
          'X-Auth-token': token,
        },
      })
      .then((res) => res.data.data)

  const API_URL = process.env.REACT_APP_API_URL

  const {
    data: leaders = [],
    error: leadersError,
    isLoading: leadersLoading,
  } = useSWR(`${API_URL}user/leaders`, fetcher)
  const {
    data: city = [],
    error: cityError,
    isLoading: cityLoading,
  } = useSWR(`${API_URL}cities`, fetcher)
  const {
    data: role = [],
    error: roleError,
    isLoading: roleLoading,
  } = useSWR(`${API_URL}roles`, fetcher)
  const {
    data: template = [],
    error: templateError,
    isLoading: templateLoading,
  } = useSWR(`${API_URL}attendance-templates`, fetcher)

  const formSchema = Object.keys(customFields).reduce(
    (obj, item) => ({
      ...obj,
      [item]:
        (customFields[item].type === 'multiple' || customFields[item].type === 'images' || customFields[item].type === 'files')
          ? customFields[item].required ? Yup.array().min(1, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).nullable()
            : Yup.array().notRequired().nullable()
          : customFields[item].type === 'date'
            ? customFields[item].required ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).nullable() : Yup.string().notRequired().nullable()
            : customFields[item].type === 'link' ?
              customFields[item].required ?
                Yup.string().nullable().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).test('is-required', intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" }), function (value) {
                  if (value && !isValidUrl(value)) return false;
                  return true;
                })
                :
                Yup.string().nullable().test('is-required', intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" }), function (value) {
                  if (value && !isValidUrl(value)) return false;
                  return true;
                })
              : customFields[item].required ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).nullable() : Yup.string().notRequired().nullable()
    }),
    {}
  )


  const initialValues: { [key: string]: any } = {
    name: userData.name,
    nik: userData.nik ? userData.nik : '',
    city: userData.city_id,
    cityName: userData.city_name,
    role: userData.role_id,
    leader: userData.leader_id ? userData.leader_id : '',
    template: userData.attendanceTemplateId ? userData.attendanceTemplateId : '',
    ...Object.fromEntries(
      Object.keys(customFields).map((key) => [
        key,
        userData.customFields && userData.customFields[key]
          ? customFields[key].type === 'date'
            ? moment(userData.customFields[key], 'YYYY-MM-DD', true).isValid()
              ? userData.customFields[key]
              : null
            : customFields[key].type === 'multiple' // handle option multiple agar menjadi array
              ? Array.isArray(userData.customFields[key])
                ? userData.customFields[key]
                : typeof userData.customFields[key] === 'string'
                  ? userData.customFields[key].split(',')
                  : []
              : userData.customFields[key]
          : null,
      ])
    ),
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(
        { id: 'FORM.VALIDATION.FIELD_REQUIRED' },
        { title: intl.formatMessage({ id: 'FORM.LABEL.NAME' }) }
      )
    ),
    city: Yup.string().required(
      intl.formatMessage(
        { id: 'FORM.VALIDATION.FIELD_REQUIRED' },
        { title: intl.formatMessage({ id: 'FORM.LABEL.CITY' }) }
      )
    ),
    role: Yup.string().required(
      intl.formatMessage(
        { id: 'FORM.VALIDATION.FIELD_REQUIRED' },
        { title: intl.formatMessage({ id: 'FORM.MENU.ROLES' }) }
      )
    ),
    template: Yup.string().notRequired().nullable(),
    ...formSchema,
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      setModalBtnLoading(true)
      const customFieldsValues: Record<string, string> = Object.keys(customFields).reduce(
        (obj, item) => ({
          ...obj,
          [item]: (values as Record<string, string>)[item],
        }),
        {}
      );


      await Promise.all(
        Object.keys(customFields).map(async (key) => {
          if (customFields[key].type === 'image' || customFields[key].type === 'file') {
            try {
              if (values[key as keyof typeof values] && typeof values[key as keyof typeof values] === 'object') {
                const resourceResponse = await insertUserFieldAttachment(values[key as keyof typeof values]);
                customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
              }
            } catch (error) {
              console.log(error);
            }
          } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
            try {
              if (values[key as keyof typeof values]) {
                var dataMultiAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item !== 'string')
                var dataUrlAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item === 'string')

                const resourceResponse = await insertUserFieldMultiAttachment(dataMultiAttachment);
                (customFieldsValues[key] as any) = resourceResponse.data.data.length > 0 ? [...dataUrlAttachment, ...resourceResponse.data.data] : dataUrlAttachment.length > 0 ? dataUrlAttachment : null;
              }
            } catch (error) {
              console.log(error);
            }
          } else if (customFields[key].type === 'multiple') {
            var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
            customFieldsValues[key] = multipleValues as string
          }
        })
      );

      try {
        const response = await updateUser({
          id: userData.id,
          name: values.name,
          nik: values.nik === '' ? undefined : values.nik,
          cityId: values.city,
          roleId: values.role,
          leaderId: values.leader === '' ? undefined : values.leader,
          token: token,
          attendanceTemplateId: values.template === '' ? undefined : values.template,
          customFields: customFieldsValues,
        })
        if (response.data.success) {
          Swal.fire({
            icon: 'success',
            heightAuto: false,
            title: intl.formatMessage({ id: 'FORM.VALIDATION.UPDATE_SUCCESS' }),
            timer: 2000,
            timerProgressBar: true,
          })
          closeModalRef.current?.click()
          setTablesData(
            'users',
            tablesData.users.map((item) => {
              if (item.id === userData.id) {
                return {
                  ...item,
                  name: response.data.data.name,
                  nik: response.data.data.nik,
                  city_id: response.data.data.city_id,
                  city_name: response.data.data.city_name,
                  role_id: response.data.data.role_id,
                  role_name: response.data.data.role_name,
                  leader_id: response.data.data.leader_id,
                  leader_name: response.data.data.leader_name,
                  attendanceTemplateId: response.data.data.attendanceTemplateId,
                  attendanceTemplateName: response.data.data.attendanceTemplateName,
                  customFields: response.data.data.customFields,
                }
              } else {
                return item
              }
            })
          )
        }
      } catch (error: any) {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'Ok',
        })
      } finally {
        setModalBtnLoading(false)
      }
    },
  })


  useEffect(() => {
    // Get the modal element
    const modalElement = modalRef.current

    // Define the event listener
    const handleModalHidden = () => {
      formik.resetForm()

      Object.keys(customFields).map((key, index) => {
        // Reset
        const ref = refArray.current[index]
        if (ref && ref.value) {
          ref.value = ''
        }

        if (customFields[key].type === 'multiple') {
          var checkboxes = document.getElementsByName(key)
          checkboxes.forEach((checkbox: any) => {
            checkbox.checked = false
          })
        }

        if (customFields[key].type === 'date') {
          formik.setFieldValue(key, null)
        }
      })
    }

    // Attach the event listener
    modalElement?.addEventListener('hidden.bs.modal', handleModalHidden)

    // Cleanup
    return () => {
      modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden)
    }
  }, [formik])

  const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

  useEffect(() => {
    if (!leadersLoading) {
      const leaderData = leaders.map((leader: Leader) => ({
        value: leader.id,
        label: leader.name,
      }))
      setLeadersData(leaderData)
    }
  }, [leaders])

  useEffect(() => {
    if (!cityLoading) {
      const cityData = city.map((city: City) => ({
        value: city.id,
        label: city.name,
      }))
      cityData.sort((a: CityOption, b: CityOption) => a.label.localeCompare(b.label))
      setCityData(cityData)
    }
  }, [city])

  useEffect(() => {
    if (!roleLoading) {
      const roleData = role.map((role: Role) => ({
        value: role.id,
        label: role.name,
      }))
      setRoleData(roleData)
    }
  }, [role])

  useEffect(() => {
    if (!templateLoading) {
      let templateData = template.map((template: AttendanceTemplate) => ({
        value: template.id,
        label: template.name,
      }))
      setTemplateData(templateData)
    }
  }, [template])

  useEffect(() => {
    if (settings.users_custom_fields) {
      setCustomFields(JSON.parse(settings.users_custom_fields))
    }
  }, [settings])

  if (leadersError) return <div>error...</div>
  if (cityError) return <div>error...</div>
  if (roleError) return <div>error...</div>
  if (templateError) return <div>error...</div>

  return (
    <div className="modal fade" tabIndex={-1} id={`update-user-modal-${userData.id}`} ref={modalRef}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.UPDATE" })} {userData.name}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              onClick={handleCloseModal}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="modal-body">
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}
              {/* Nama */}
              <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.NAME" })}</label>
              <div className="input-group mb-3">
                <input
                  placeholder={intl.formatMessage({ id: "FORM.LABEL.NAME" })}
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control form-control-lg',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                  )}
                  type='text'
                  name='name'
                  autoComplete='off'
                  onInput={(e) => handleCaps(e)}
                />
              </div>
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert' className="text-danger">{formik.errors.name}</span>
                </div>
              )}
              {/* NIK */}
              <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.ID_NUMBER" })}</label>
              <div className="input-group mb-3">
                <input
                  placeholder={intl.formatMessage({ id: "FORM.LABEL.ID_NUMBER" })}
                  {...formik.getFieldProps('nik')}
                  className={clsx(
                    'form-control form-control-lg',
                    { 'is-invalid': formik.touched.nik && formik.errors.nik },
                  )}
                  type='text'
                  name='nik'
                  autoComplete='off'
                  maxLength={16}
                  onInput={(event) => {
                    // Replace non-digits
                    event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]/g, '');

                    // Remove leading zeros
                    while (event.currentTarget.value.startsWith("0")) {
                      event.currentTarget.value = event.currentTarget.value.substring(1);
                    }
                  }}
                />
              </div>
              {formik.touched.nik && formik.errors.nik && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert' className="text-danger">{formik.errors.nik}</span>
                </div>
              )}
              {/* Leader */}
              <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.LEADER" })}</label>
              <div className="input-group mb-3">
                <Select
                  key={nanoid()}
                  options={leadersData}
                  placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.LEADER" })}`}
                  value={leadersData.find(option => option.value === formik.values.leader)}
                  onChange={
                    option => formik.handleChange({
                      target: {
                        name: "leader",
                        value: option?.value
                      }
                    })
                  }
                  onBlur={
                    () => formik.values.leader === "" && formik.setFieldTouched("leader", true)
                  }
                  className="w-100"
                  isDisabled={leadersLoading}
                  isClearable={true}
                />
              </div>
              {formik.touched.leader && formik.errors.leader && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert' className="text-danger">{formik.errors.leader}</span>
                </div>
              )}
              {/* City */}
              <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.CITY" })}</label>
              <div className="input-group mb-3">
                <Select
                  key={nanoid()}
                  options={cityData}
                  placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.CITY" })}`}
                  value={cityData.find(option => option.value === formik.values.city)}
                  onChange={
                    option => {
                      formik.handleChange({
                        target: {
                          name: "city",
                          value: option?.value
                        }
                      })
                      formik.setFieldValue('cityName', option?.label)
                    }}
                  onBlur={
                    () => formik.values.city === "" && formik.setFieldTouched("city", true)
                  }
                  className="w-100"
                  isDisabled={cityLoading}
                />
              </div>
              {formik.touched.city && formik.errors.city && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert' className="text-danger">{formik.errors.city}</span>
                </div>
              )}
              {/* Role */}
              <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.ROLE" })}</label>
              <div className="input-group mb-3">
                <Select
                  key={nanoid()}
                  options={roleData}
                  placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.ROLE" })}`}
                  value={roleData.find(option => option.value === formik.values.role)}
                  onChange={
                    option => formik.handleChange({
                      target: {
                        name: "role",
                        value: option?.value
                      }
                    })
                  }
                  onBlur={
                    () => formik.values.role === "" && formik.setFieldTouched("role", true)
                  }
                  className="w-100"
                  isDisabled={roleLoading}
                  isClearable={true}
                />
              </div>
              {formik.touched.role && formik.errors.role && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert' className="text-danger">{formik.errors.role}</span>
                </div>
              )}
              {/* Attendance Template */}
              {companies.companyFeatureLimitUserAttendance &&
                <>
                  <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.ATTENDANCE_TEMPLATE" })}</label>
                  <div className="input-group mb-3">
                    <Select
                      key={nanoid()}
                      options={templateData}
                      placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.ATTENDANCE_TEMPLATE" })}`}
                      value={templateData.find(option => option.value === formik.values.template)}
                      onChange={
                        option => formik.handleChange({
                          target: {
                            name: "template",
                            value: option?.value
                          }
                        })
                      }
                      onBlur={
                        () => formik.values.template === "" && formik.setFieldTouched("template", true)
                      }
                      className="w-100"
                      isDisabled={templateLoading}
                      isClearable={true}
                    />
                  </div>
                  {formik.touched.template && formik.errors.template && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert' className="text-danger">{formik.errors.template}</span>
                    </div>
                  )}
                </>
              }
              {/* Custom Fields */}
              {
                customFields && Object.keys(customFields).map((key, index) => {
                  if (customFields[key].type === 'text') {
                    return (
                      <div key={index}>
                        <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                        <div className="input-group mb-3">
                          <input
                            placeholder={customFields[key].name}
                            {...formik.getFieldProps(key)}
                            className={clsx(
                              'form-control form-control-lg',
                              { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                            )}
                            type='text'
                            name={key}
                            autoComplete='off'
                            style={{ zIndex: 0 }}
                            required={true}
                            onInput={(e) => handleCaps(e)}
                            ref={ref => {
                              refArray.current[index] = ref; // took this from your guide's example.
                            }}
                          />
                        </div>
                        {
                          formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                          (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                            </div>
                          )
                        }
                      </div>
                    )
                  } else if (customFields[key].type === 'link') {
                    return (
                      <div key={index}>
                        <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                        <div className="input-group mb-3">
                          <input
                            placeholder={customFields[key].name}
                            {...formik.getFieldProps(key)}
                            className={clsx(
                              'form-control form-control-lg txt-link',
                              { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                            )}
                            type='text'
                            name={key}
                            autoComplete='off'
                            style={{ zIndex: 0 }}
                            required={true}
                            ref={ref => {
                              refArray.current[index] = ref; // took this from your guide's example.
                            }}
                          />
                        </div>
                        {
                          formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                          (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                            </div>
                          )
                        }
                      </div>
                    )
                  } else if (customFields[key].type === 'image') {

                    return (
                      <>
                        {/* Attachments */}
                        <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                        <small><i>*Format JPG, JPEG, PNG</i></small>
                        <InputAttachment keyString={key} formik={formik} isMulti={false} type="update" accept="image/png, image/jpeg, image/jpg" />
                        {
                          formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                          (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                            </div>
                          )
                        }
                      </>
                    )
                  } else if (customFields[key].type === 'images') {
                    return (
                      <>
                        {/* Attachments */}
                        <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                        <small><i>*Format JPG, JPEG, PNG</i></small>
                        <InputAttachment keyString={key} formik={formik} isMulti={true} type="update" accept="image/png, image/jpeg, image/jpg" />
                        {
                          formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                          (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                            </div>
                          )
                        }
                      </>
                    )
                  } else if (customFields[key].type === 'file') {
                    var fileType = getFileTypeFromUrl(formik.values[key as keyof typeof formik.values])

                    return (
                      <>
                        {/* Attachments */}
                        <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                        <small><i>*Format PDF, XLS, XLSX, DOCX, TXT</i></small>
                        <InputAttachment keyString={key} formik={formik} isMulti={false} type="update" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                        {
                          formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                          (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                            </div>
                          )
                        }
                      </>
                    )
                  } else if (customFields[key].type === 'files') {
                    return (
                      <>
                        {/* Attachments */}
                        <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                        <small><i>*Format PDF, XLS, XLSX, DOCX, TXT</i></small>
                        <InputAttachment keyString={key} formik={formik} isMulti={true} type="update" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                        {
                          formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                          (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                            </div>
                          )
                        }
                      </>
                    )
                  } else if (customFields[key].type === 'date') {
                    return (
                      <>
                        {/* Date */}
                        <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                        <div className="input-group mb-3">
                          <DatePicker
                            ranges={todayRange}
                            oneTap
                            editable={false}
                            format="dd MMM yyyy"
                            value={formik.values[key as keyof typeof formik.values] ? new Date(formik.values[key as keyof typeof formik.values]) : null}
                            defaultValue={null}
                            style={{ width: "100%", zIndex: 0 }}
                            container={
                              document.querySelector(
                                '#update-user-modal-' + userData.id
                              ) as HTMLElement
                            }
                            onChange={(value: any) => {
                              if (value) formik.setFieldValue(key, moment(value).format('YYYY-MM-DD'))
                              else formik.setFieldValue(key, null)
                            }}
                          />
                        </div>
                        {
                          formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                          (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                            </div>
                          )
                        }
                      </>
                    )
                  } else if (customFields[key].type === 'option') {

                    const options = customFields[key].value.map((option, index) => ({
                      value: option,
                      label: option
                    }))

                    return (
                      <>
                        <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                        <div className="input-group mb-3">
                          <Select
                            key={nanoid()}
                            isClearable
                            options={options}
                            placeholder={`Pilih ${customFields[key].name}`}
                            value={options.find(option => option.value === formik.values[key as keyof typeof formik.values])}
                            onChange={
                              option => formik.handleChange({
                                target: {
                                  name: key,
                                  value: option?.value
                                }
                              })
                            }
                            onBlur={
                              () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                            }
                            className="w-100"
                          />
                        </div>
                        {
                          formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                          (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                            </div>
                          )
                        }
                      </>
                    )
                  } else if (customFields[key].type === 'multiple') {
                    return (
                      <>
                        <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                        <div className="input-group mb-3">
                          <div className="form-check form-check-custom form-check-solid" style={{ display: 'contents' }}>
                            {
                              customFields[key].value.map((option, index) => (
                                <div style={{ display: 'flex', padding: "3px" }}>
                                  <input
                                    key={index}
                                    {...formik.getFieldProps(key)}
                                    className="form-check-input"
                                    type="checkbox"
                                    value={option as string}
                                    name={key}
                                    checked={formik.values[key as keyof typeof formik.values]?.includes(option)}
                                  />
                                  <label className="form-check-label me-2">{option}</label>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        {
                          formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                          (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                            </div>
                          )
                        }
                      </>
                    )
                  } else if (customFields[key].type === 'phone') {
                    return (
                      <div>
                        <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                        <InputPhone keyString={key} formik={formik} type="input" />
                        {
                          formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                          (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  return null
                })
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light d-none"
                data-bs-dismiss="modal"
                ref={closeModalRef}
              >
                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
              </button>
              <button
                type="button"
                className="btn btn-light"
                onClick={handleCloseModal}
              >
                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
              </button>
              <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                <span className="indicator-label">
                  {intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}
                </span>
                <span className="indicator-progress">
                  Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export { UpdateUser }
