import axios from 'axios'
import instance from './axios/axiosEmail'
import Swal from 'sweetalert2'
import { url } from 'inspector'
import { ContentType } from '../interfaces/Email/BroadCastEmailType'
import { IDetailGroupEmail, IUpdateGroupContactEmail, IUpdateDetailGroupEmail, ICreateBroadcastEmail, IUpdateBroadcastEmail, IGroupContactEmail, ICreateDraftBroadcastEmail, IUpdateDraftBroadcastEmail } from '../interfaces/Email/InputEmailTypes'

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL

export const loginEmail = async (email: string, password: string, host?: string) => {
  try {
    const response = await instance.post('auth/login', { email, password, host })
    return response
  } catch (error: any) {
    // Mengecek status code nya
    if (error.response.data.message === 'Invalid credentials - IMAP login failed') {
      Swal.fire({
        icon: 'error',
        title: 'Email atau Password salah',
        confirmButtonText: 'Ok',
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok',
      })
    }
  }
}

export const sendEmail = async (
  from: string,
  to: string | string[],
  cc: string | string[],
  bcc: string | string[],
  subject: string,
  body: string,
  attachment_data?: File[]
) => {
  try {
    const formData = new FormData();
    formData.append('from', from);
    formData.append('to', Array.isArray(to) ? to.join(',') : to);
    formData.append('cc', Array.isArray(cc) ? cc.join(',') : cc);
    formData.append('bcc', Array.isArray(bcc) ? bcc.join(',') : bcc);
    formData.append('subject', subject);
    formData.append('body', body);

    if (attachment_data && attachment_data.length > 0) {
      attachment_data.forEach((file, index) => {
        formData.append(`attachment_data`, file);
      });
    }

    const response = await instance.post('sender/send-email', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (error: any) {
    Swal.fire({
      icon: 'error',
      title: 'Gagal Mengirim',
      confirmButtonText: 'Ok',
    });
  }
};

export const deleteEmail = async (uid: string | number | undefined, type: string) => {
  try {
    const response = await instance.delete('utils/delete-email', {
      data: {
        uid,
        type,
      },
    })
    return response
  } catch (error: any) {
    Swal.fire({
      icon: 'error',
      title: error.response.data.message,
      confirmButtonText: 'Ok',
    })
  }
}

export const moveEmail = async (uid: string | number, type_from: string, type_to: string) => {
  try {
    const response = await instance.post('utils/move-email', { uid, type_from, type_to })
    return response
  } catch (error: any) {
    Swal.fire({
      icon: 'error',
      title: error.response.data.message,
      confirmButtonText: 'Ok',
    })
  }
}

export const saveDraft = async (
  from: string,
  to: string | string[],
  subject: string,
  body: string,
  cc?: string,
  bcc?: string,
  attachment_data?: File[]
) => {
  try {
    const formData = new FormData();
    formData.append('from', from);
    formData.append('to', Array.isArray(to) ? to.join(',') : to);
    formData.append('subject', subject);
    formData.append('body', body);
    formData.append('cc', cc ? cc : '');
    formData.append('bcc', bcc ? bcc : '');
    if (attachment_data && attachment_data.length > 0) {
      attachment_data.forEach((file, index) => {
        formData.append(`attachment_data`, file);
      });
    }
    const response = await instance.post('utils/save-email-draft', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  } catch (error: any) {
    Swal.fire({
      icon: 'error',
      title: error.response.data.message,
      confirmButtonText: 'Ok',
    })
  }
}

export const searchAccount = async (query: string) => {
  try {
    const response = await instance.post('utils/search-account', { query })
    return response.data.message
  } catch (error: any) {
    Swal.fire({
      icon: 'error',
      title: error.response.data.message,
      confirmButtonText: 'Ok',
    })
    return []
  }
}

export const sendReplyEmail = async (
  uid: number,
  type: string,
  body: string,
  attachment_data: File[],
  cc?: string
) => {
  try {
    const formData = new FormData();
    formData.append('uid', uid.toString());
    formData.append('type', type);
    formData.append('body', body);
    if (attachment_data && attachment_data.length > 0) {
      attachment_data.forEach((file, index) => {
        formData.append(`attachment_data`, file);
      });
    }
    formData.append('cc', cc ? cc : '');


    const response = await instance.post('sender/send-reply-email', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  } catch (error: any) {
    // console.log('berjalan')
    Swal.fire({
      icon: 'error',
      title: 'Gagal Reply',
      confirmButtonText: 'Ok',
    })
  }
}

export const changePassword = async (email: string, oldPassword: string, newPassword: string) => {
  try {
    const response = await instance.post('account/change-password', { email, oldPassword, newPassword })
    return response
  } catch (error: any) {
    Swal.fire({
      icon: 'error',
      title: error.response.data.message,
      confirmButtonText: 'Ok',
    })
  }
}

// Begin Api Broadcast Email ------------------------------------------------

export const getGroupContactEmail = async (url: string) => {
  try {
    const response = await instance.get(url)
    return response.data.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const getVariableCustome = async (url: string) => {
  try {
    const response = await instance.get(url)
    return response.data.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const createGroupContactEmail = async (data: IGroupContactEmail) => {
  try {
    const response = await instance.post(`${API_URL_EMAIL}groupcontact/create-group`, data)
    // tambahkan header nantinya
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const deleteGroupContactEmail = async (id: number | string) => {
  try {
    const response = await instance.delete(`${API_URL_EMAIL}groupcontact/delete-group?id=${id}`)
    return response
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const updateGroupContactEmailCustomField = async (idGroupContact: string, data: IUpdateGroupContactEmail) => {
  try {
    const values = {
      id: idGroupContact,
      customField: [data]
    }
    const response = await instance.patch(`${API_URL_EMAIL}groupcontact/update-group`, values)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const updateGroupContactEmail = async (data: IUpdateGroupContactEmail) => {
  try {
    const response = await instance.patch(`${API_URL_EMAIL}groupcontact/update-group`, data)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

// Detail Group Contact Email

export const getDetailGroupContactEmail = async (url: string) => {
  try {
    const response = await instance.get(url)
    return response.data.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const createDetailGroupContactEmail = async (data: IDetailGroupEmail) => {
  try {
    const response = await instance.post(`${API_URL_EMAIL}groupcontact/create-user-group-contact`, data)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const updateDetailGroupContactEmail = async (data: IUpdateDetailGroupEmail) => {
  try {
    const response = await instance.patch(`${API_URL_EMAIL}groupcontact/update-user-group-contact`, data)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const deleteDetailGroupContactEmail = async (id: number | string) => {
  try {
    const response = await instance.delete(`${API_URL_EMAIL}groupcontact/delete-user-group-contact?id=${id}`)
    return response
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

// Broadcast Email
export const createBroadcastEmail = async (data: ICreateBroadcastEmail) => {
  try {
    const response = await instance.post(`${API_URL_EMAIL}broadcast/create-broadcast`, data)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const getBroadcastEmail = async (url: string) => {
  try {
    const response = await instance.get(url)
    return response.data.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const updateBroadcastEmail = async (data: IUpdateBroadcastEmail) => {
  try {
    const response = await instance.patch(`${API_URL_EMAIL}broadcast/update-broadcast`, data)
    return response.data
  } catch (error: any) {
    Swal.fire({
      icon: 'error',
      title: error.response.data.message,
      confirmButtonText: 'Ok',
    })
  }
}

export const deleteBroadcastEmail = async (id: number | string) => {
  try {
    const response = await instance.delete(`${API_URL_EMAIL}broadcast/delete-broadcast?id=${id}`)
    return response
  } catch (error: any) {
    Swal.fire({
      icon: 'error',
      title: error.response.data.message,
      confirmButtonText: 'Ok',
    })
  }
}
// End of Api Broadcast Email

// Get Column

export const getVariableColumn = async (url: string) => {
  try {
    const response = await instance.get(url)
    return response.data.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const getColumnCustomFields = async (url: string) => {
  try {
    const response = await instance.get(url)
    return response.data.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const getCheckColumnCustomFields = async (url: string) => {
  return instance.get(url)
}

// End Api Broadcast Email

// Begin : Action Puase, Stop, Resume

export const pauseBroadcastEmail = async (id: number | string) => {
  try {
    const response = await instance.get(`${API_URL_EMAIL}broadcast/pause-broadcast?id=${id}`)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const stopBroadcastEmail = async (id: number | string) => {
  try {
    const response = await instance.get(`${API_URL_EMAIL}broadcast/stop-broadcast?id=${id}`)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const resumeBroadcastEmail = async (id: number | string) => {
  try {
    const response = await instance.get(`${API_URL_EMAIL}broadcast/resume-broadcast?id=${id}`)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}
// End : Action Puase, Stop, Resume

// Begin BroadcastEmail Draft

export const getDraftBroadcastEmail = async (url: string) => {
  try {
    const response = await instance.get(url)
    return response.data.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const createDraftBroadcastEmail = async (data: ICreateDraftBroadcastEmail) => {
  try {
    const response = await instance.post(`${API_URL_EMAIL}broadcast/create-broadcast-draft`, data)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const updateDraftBroadcastEmail = async (data: IUpdateDraftBroadcastEmail) => {
  try {
    const response = await instance.patch(`${API_URL_EMAIL}broadcast/update-broadcast-draft`, data)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response?.data?.message || 'An error occurred',
      confirmButtonText: 'Ok',
    })
  }
}

export const deleteDraftBroadcastEmail = async (id: number | string) => {
  try {
    const response = await instance.delete(`${API_URL_EMAIL}broadcast/delete-broadcast-draft?id=${id}`)
    return response
  } catch (error: any) {
    Swal.fire({
      icon: 'error',
      title: error.response.data.message || 'An Error occured',
      confirmButtonText: 'Ok',
    })
  }
}

// End BroadcastEmail Draft

// Get Link Checker for insert url 

export const getLinkChecker = async (id: string) => {
  try {
    const response = await instance.get(`${API_URL_EMAIL}/linkchecker/get-link?id=${id}`)
    return response.data
  } catch (error: any) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: error.response.data.message || 'An Error occured',
      confirmButtonText: 'Ok',
    })
  }
}

// End Of Get Link Checker for insert url 