import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'
import { Settings } from '../interfaces/Settings';

type SettingsState = {
    settings: Settings; // Change the type to Record<string, Setting>
    setSettings: (settings: Settings) => void;
}

export const useSettingsStore = create<SettingsState>()(
    persist(
        (set) => ({
            settings: {
                merchant_title: 'Klien',
                pipeline_title: 'Pipeline',
                meeting_title: 'Meeting',
                merchant_custom_fields: '',
                pipeline_custom_fields: '',
                meeting_custom_fields: '',
                pipeline_date_min: "",
                template_pipeline: "",
                caps_lock_inputs: false,
                merchant_duplicate: false,
                merchant_phone_can_be_changed: false,
                hide_top_three_sales: false,
                feature_check_number: false,
                feature_pipeline: false,
                feature_meeting: false,
                feature_chat: false,
                feature_chat_super_admin: false,
                pipeline_abandoned_lock: false,
                feature_meeting_mode: "0",
                chat_show_pic_only: false,
                meeting_auto_cancel: -1,
                radius_visit: 1,
                update_meeting: false,
                meeting_visit_disable_capture_camera: false
            } as Settings, // Initialize settings as an object
            setSettings: (settings) => {
                set({ settings });
            },
        }),
        {
            name: 'settings-store',
            storage: createJSONStorage(() => localStorage),
        }
    )
)