import { FC, ReactNode, useEffect, useState } from "react";
import { DashboardWidget } from "../../../components/Dashboard/DashboardWidget";
import { UserModel } from "../../modules/auth/models/UserModel";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import axios from "axios";
import useAccessToken from "../../../hooks/AccessToken";
import useSWR, { mutate } from "swr";
import { countTopUserPercent, customNumberFormat, getProgressColor, roundNominal } from '../../../functions/general';
import { Carousel, DatePicker } from "rsuite";
import { SummaryProgressWidget } from '../../../components/Dashboard/SummaryProgressWidget';
import { UserPerformance, UserTarget } from '../../../interfaces/UserPerformance';
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { TopUsersWidget } from "../../../components/Dashboard/TopUsersWidget";
import { UserScope } from "../../../interfaces/UserScope";
import { UserScopeModal, defaultScope } from "../../../components/DataTable/UserScopeModal";
import { Modal } from "bootstrap";
import { WidgetMonthlyModal } from "../../../components/Dashboard/WidgetMonthlyModal";
import { WidgetStaticModal } from "../../../components/Dashboard/WidgetStaticModal";
import { Summary } from "../../../interfaces/Summary";
import { useSettingsStore } from "../../../stores/SettingsStore";
import RadioGroup from "@mui/material/RadioGroup";
import { Row, Col } from "react-bootstrap";
import { TopUserFilterModal } from "../../../components/Dashboard/TopUserFilterModal";
import { Role } from "../../../interfaces/Role";
import { TopUser } from "../../../interfaces/TopUser";
import useUser from "../../../hooks/User";
import { Banner } from "../../../interfaces/Banner";
import CarouselBanner from "../../../components/Banner/CarouselBanner";
import { useCompanyStore } from "../../../stores/CompanyStore";
import moment from "moment";
import { ContentsPieChartDashboardWidget, DashboardLeadsReportTable, DashboardWidgetProps, DetailPieChartDashboardWidget, DetailStaticDashboardWidget, MonthlyDashboardWidget, PieChartDashboardWidget, StaticDashboardWidget } from "../../../interfaces/Dashboard";
import { getDashboardtWidget } from "../../../api/WidgetCRUD";
import { DashboardPie } from "../../../components/Dashboard/DashboardPie";
import { ar } from "date-fns/locale";
import { useIntl } from "react-intl";
import { MasterDataTableTanstack } from "../../../components/DataTable/MasterDataTableTanstack";
import {
    ColumnDef,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
    RowData,
    ExpandedState,
    createColumnHelper,
} from "@tanstack/react-table";
import { useCustomeStore } from "../../../stores/CustomeStore";
import { DataTableExport } from "../../../components/DataTable/DataTableExport";
import { endOfMonth } from "date-fns";
import useModalEffect from "../../../hooks/useModalEffect";
import { WidgetDetailMerchantModal } from "../../../components/Dashboard/WidgetDetailMerchantModal";

export declare type ValueType = [Date?, Date?];
export declare type DateRange = [Date, Date];
export interface RangeType {
    label: React.ReactNode;
    value: DateRange | ((value?: ValueType) => DateRange);
    closeOverlay?: boolean;
    placement?: 'bottom' | 'left';
}

const Dashboard: FC = () => {
    const user = useUser()
    const { settings } = useSettingsStore()
    const { companyId } = useCustomeStore()
    const [dashboardError, setDashboardError] = useState("")
    const [dashboardLoading, setDashboardLoading] = useState(false)

    const [topUsers, setTopUsers] = useState<TopUser[]>([])
    const [topUserFilter, setTopUserFilter] = useState<'nominal' | 'progress' | 'product' | 'meeting'>(user.data.company_id === companyId.kawasaki1 || user.data.company_id === companyId.kawasaki2 ? 'product' : 'nominal')
    const [topUserRole, setTopUserRole] = useState<string | undefined>('all')
    const [dashboardWidget, setDashboardWidget] = useState<DashboardWidgetProps>()
    const [dataWidgetStaticModal, setDataWidgetStaticModal] = useState<StaticDashboardWidget>()
    const [dataWidgetMonthlyModal, setDataWidgetMonthlyModal] = useState<MonthlyDashboardWidget>()
    const [dataWidgetDetailMerchantModal, setDataWidgetDetailMerchantModal] = useState<DetailStaticDashboardWidget>({} as DetailStaticDashboardWidget)

    const [showWidgetStaticModal, setShowWidgetStaticModal] = useState<boolean>(false);
    const [showWidgetMonthlyModal, setShowWidgetMonthlyModal] = useState<boolean>(false);
    const [showWidgetDetailMerchantModal, setShowWidgetDetailMerchantModal] = useState<boolean>(false);

    const [tableColumns, setTableColumns] = useState<TableColumn<UserPerformance>[]>([])
    const { companies } = useCompanyStore()
    ChartJS.register(ArcElement)

    const token = useAccessToken()
    const [date, setDate] = useState<Date>(new Date());
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const [user_id, setUser_id] = useState<string>(user.data.id);
    const [user_name, setUser_name] = useState<string>(user.data.name);
    const [scope, setScope] = useState<UserScope>(defaultScope(
        user.data.is_superadmin ? 'ALL' : user.data.has_subordinate ? 'TEAM' : 'SELF'
    ));
    const intl = useIntl()
    const optionUserPerformance = [
        { label: 'Total Cabang', value: 'users' },
        { label: 'Sumber', value: 'sources' },
        { label: 'Kategori', value: 'labels' },
        { label: settings.meeting_title, value: 'meeting-activity-categories' },
    ]

    const baseTableColumns: TableColumn<UserPerformance>[] = [
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NAME" }),
            width: '20%',
            selector: row => row.user_name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.user_name
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CONVERSION_RATE" }),
            selector: row => row.convertionRate ?? 0,
            right: true,
            sortable: true,
            cell: row => Math.round(row.convertionRate ?? 0) + "%"
        },
    ]

    const columnHelper = createColumnHelper<DashboardLeadsReportTable>()
    const columns = [
        columnHelper.accessor("desc", {
            id: "desc",
            cell: (info) => info.getValue(),
            header: "Deskripsi",
            meta: {
                rowSpan: 3,
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
        columnHelper.accessor("prospect", {
            cell: (info) => info.getValue(),
            header: "Prospek",
            meta: {
                rowSpan: 3,
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
        columnHelper.group({
            header: "PROSPEK",
            columns: [
                columnHelper.accessor("prospectTarget", {
                    header: "Target",
                    meta: {
                        rowSpan: 2,
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.group({
                    header: "Pencapaian",
                    columns: [
                        columnHelper.accessor("prospectAchievementQ", {
                            header: "Q",
                            meta: {
                                styleHeader: {
                                    alignContent: "center",
                                    textAlign: "center"
                                },
                                styleBody: {
                                    textAlign: "right"
                                }
                            },
                        }),
                        columnHelper.accessor("prospectAchievementPercent", {
                            header: "%",
                            cell: (info) => info.getValue() + "%",
                            meta: {
                                styleHeader: {
                                    alignContent: "center",
                                    textAlign: "center"
                                },
                                styleBody: {
                                    textAlign: "right"
                                }
                            },
                        }),
                    ],
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        }
                    },
                }),
            ],
            meta: {
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
        columnHelper.group({
            header: "PAID",
            columns: [
                columnHelper.accessor("paidTarget", {
                    header: "Target",
                    meta: {
                        rowSpan: 2,
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.group({
                    header: "Pencapaian",
                    columns: [
                        columnHelper.accessor("paidAchievementQ", {
                            header: "Q",
                            meta: {
                                styleHeader: {
                                    alignContent: "center",
                                    textAlign: "center"
                                },
                                styleBody: {
                                    textAlign: "right"
                                }
                            },
                        }),
                        columnHelper.accessor("paidAchievementPercent", {
                            header: "%",
                            cell: (info) => info.getValue() + "%",
                            meta: {
                                styleHeader: {
                                    alignContent: "center",
                                    textAlign: "center"
                                },
                                styleBody: {
                                    textAlign: "right"
                                }
                            },
                        }),
                    ],
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        }
                    },
                }),
            ],
            meta: {
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
        columnHelper.group({
            header: "Lost",
            meta: {
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
            columns: [
                columnHelper.accessor("lostQ", {
                    header: "Q",
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.accessor("lostPercent", {
                    header: "%",
                    cell: (info) => info.getValue() + "%",
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
            ],
        }),
        columnHelper.group({
            header: "Cold",
            meta: {
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
            columns: [
                columnHelper.accessor("coldQ", {
                    header: "Q",
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.accessor("coldPercent", {
                    header: "%",
                    cell: (info) => info.getValue() + "%",
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
            ],
        }),
        columnHelper.group({
            header: "Hot",
            meta: {
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
            columns: [
                columnHelper.accessor("hotQ", {
                    header: "Q",
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.accessor("hotPercent", {
                    header: "%",
                    cell: (info) => info.getValue() + "%",
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
            ],
        }),
        columnHelper.group({
            header: "Paid",
            meta: {
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
            columns: [
                columnHelper.accessor("paidQ", {
                    header: "Q",
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.accessor("paidPercent", {
                    header: "%",
                    cell: (info) => info.getValue() + "%",
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
            ],
        }),
        columnHelper.group({
            header: "TOTAL",
            meta: {
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
            columns: [
                columnHelper.accessor("totalQ", {
                    header: "Q",
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.accessor("totalPercent", {
                    header: "%",
                    cell: (info) => info.getValue() + "%",
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
            ],
        }),
    ];

    let actions: ReactNode[] = [
        <DataTableExport
            source={"User Performance"}
            dateStart={`${date.getFullYear()}-${date.getMonth() + 1}-01`}
            dateEnd={moment().isSame(date, 'month') ? moment().format("YYYY-MM-DD") : moment(endOfMonth(date)).format("YYYY-MM-DD")}
            columns={columns}
            user={user!}
            listDropdown={optionUserPerformance}
        />
    ]

    const fetcher = (url: string) => {
        const params = {
            date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-01',
            user_id: user_id,
            scope: scope
        };
        return axios.get(url, {
            headers: {
                'X-Auth-token': token
            },
            params
        }).then(res => res.data.data);
    }

    const API_URL = process.env.REACT_APP_API_URL
    const getWidget = async () => {
        setDashboardLoading(true)
        try {
            const response = await getDashboardtWidget(moment(date).format('YYYY-MM') + '-01', user_id, scope, token)
            const response2 = await getDashboardtWidget(moment(date).subtract(1, 'month').format('YYYY-MM') + '-01', user_id, scope, token)
            if (response.data.success && response2.data.success) {
                const dashboard = {
                    ...response.data.data,
                    monthly: response.data.data.monthly.map((eachData: any) => ({
                        ...eachData,
                        value2: response2.data.data.monthly.find((data: any) => data.alias === eachData.alias)?.value || null
                    }))
                };

                // let dashboard = response.data.data
                setDashboardWidget(dashboard)
            }
        } catch (error: any) {
            setDashboardError(error.response?.data.message)
        } finally {
            setDashboardLoading(false)
        }
    }
    
    useEffect(() => {
        getWidget()
        if (date && isFiltered) {
            mutate(`${API_URL}dashboard/users-performance`);
            mutate(`${API_URL}kawasaki/users-performance`);
        }
    }, [date]);

    const { data: performance = [], error: performanceError, isLoading: performanceLoading, isValidating: performanceValidating } = useSWR(`${API_URL}dashboard/users-performance`, fetcher, { revalidateOnFocus: false, revalidateOnReconnect: false })
    useEffect(() => {
        if (!performanceLoading) {
            var listPerformance = performance.usersPerformance?.map((value: any) => (value));

            if (topUserFilter === 'nominal') {
                listPerformance.sort((a: UserPerformance, b: UserPerformance) => b.userTargets[1].progress - a.userTargets[1].progress);
            } else if (topUserFilter === 'product') {
                listPerformance.sort((a: UserPerformance, b: UserPerformance) => b.userTargets[0].progress - a.userTargets[0].progress);
            } else if (topUserFilter === 'meeting') {
                listPerformance.sort((a: UserPerformance, b: UserPerformance) => b.userTargets[2].progress - a.userTargets[2].progress);
            } else {
                listPerformance.sort((a: UserPerformance, b: UserPerformance) => {
                    const aPercentage = a.userTargets[1].target ? (a.userTargets[1].progress / a.userTargets[1].target) * 100 : 0;
                    const bPercentage = b.userTargets[1].target ? (b.userTargets[1].progress / b.userTargets[1].target) * 100 : 0;

                    return Math.round(bPercentage) - Math.round(aPercentage);
                });
            }

            if (topUserRole !== 'all' && topUserRole !== undefined) {
                listPerformance = listPerformance.filter((value: UserPerformance) => value.role_id === topUserRole)
            }

            const topUsers = listPerformance.slice(0, 3).map((value: UserPerformance) => ({
                user_name: value.user_name,
                role_name: value.role_name,
                user_profile_picture: value.user_profile_picture,
                progress_sold_product: topUserFilter !== 'progress' ? value.userTargets[0].progress : countTopUserPercent(value.userTargets[0].progress, value.userTargets[0].target),
                target_sold_product: topUserFilter !== 'progress' ? value.userTargets[0].target : 100,
                progress_revenue: topUserFilter !== 'progress' ? roundNominal(Number(value.userTargets[1].progress)) : countTopUserPercent(roundNominal(Number(value.userTargets[1].progress)) as number, value.userTargets[1].target),
                target_revenue: topUserFilter !== 'progress' ? value.userTargets[1].target : 100,
                progress_meeting: topUserFilter !== 'progress' ? value.userTargets[2].progress : countTopUserPercent(value.userTargets[2].progress, value.userTargets[2].target),
                target_meeting: topUserFilter !== 'progress' ? value.userTargets[2].target : 100,
            }))
            
            setTopUsers(topUsers)
        }
    }, [performance, topUserRole, topUserFilter])

    useModalEffect({
        showModal: showWidgetStaticModal,
        setShowModal: setShowWidgetStaticModal,
        modalId: `widget-static-modal`
    })

    useModalEffect({
        showModal: showWidgetMonthlyModal,
        setShowModal: setShowWidgetMonthlyModal,
        modalId: `widget-monthly-modal`
    })

    useModalEffect({
        showModal: showWidgetDetailMerchantModal,
        setShowModal: setShowWidgetDetailMerchantModal,
        modalId: `widget-detail-merchant-modal`
    })

    useEffect(() => {
        if (!performanceLoading && performance) {
            const headerArray = [...new Set(performance.usersPerformance.flatMap((user: UserPerformance) => user.userTargets.map((target: UserTarget) => target.name)))];

            const tableColumnsWithUserTargets: TableColumn<UserPerformance>[] = [
                ...baseTableColumns,
                ...new Set(headerArray.flatMap((header: any, indexHeader: number) => {
                    return {
                        name: `${header}`,
                        selector: (row: UserPerformance) => row.userTargets[indexHeader].progress,
                        sortable: true,
                        right: true,
                        cell: (row: UserPerformance) => customNumberFormat(row.userTargets[indexHeader].progress ?? 0) + '/' + customNumberFormat(row.userTargets[indexHeader].target ?? 0)
                    }
                })),
            ];

            setTableColumns(tableColumnsWithUserTargets);
        }
    }, [performance, performanceLoading]);

    if (dashboardError) return <div className="alert alert-danger">Error: {dashboardError}</div>

    return (
        <>
            <div className="d-flex justify-content-between mb-5">
                <div className="text-gray-900 fs-2x fw-bold">
                    <span>Halo, {user.data.name}</span>
                </div>
                <div className="row">
                    <div className="col">
                        <DatePicker
                            format="MMM yyyy"
                            ranges={[]}
                            oneTap
                            value={date}
                            onChange={(value) => {
                                if (value) {
                                    setDate(value)
                                    setIsFiltered(true)
                                }
                            }}
                            style={{ width: 200, backgroundColor: "white" }}
                            shouldDisableDate={(date) => {
                                return date > new Date();
                            }}
                            placement="bottomEnd"
                        />
                    </div>
                </div>
            </div>

            {
                (user.data.permission_pipeline_read !== 'OWNED' && user.data.permission_pipeline_read !== 'NONE') &&
                <UserScopeModal
                    user_id={user_id}
                    setUser_id={setUser_id}
                    user_name={user_name}
                    setUser_name={setUser_name}
                    scope={scope}
                    setScope={setScope}
                    permission={user.data.is_superadmin ? "ALL" : "TEAM"}
                    funct={getWidget}
                />
            }

            <div className="row">
                {
                    !dashboardWidget ?
                        <div className="loading-overlay" style={{ position: 'unset', background: 'unset' }}>
                            <div className="loader" style={{ border: '8px solid #ffffff', borderTop: '8px solid #3498db' }}></div>
                        </div>
                        :
                        <>
                            <div className={`${dashboardWidget?.pieChart.length === 1 ? 'col-12 col-lg-6 col-xl-8' : 'col-12'}`}>
                                <div className="row">
                                    {
                                        dashboardWidget?.static.map((data: StaticDashboardWidget, index: number) => {
                                            if (data.detail !== null) {
                                                return (
                                                    <div
                                                        className={`col-12 col-lg-6 col-xl-${dashboardWidget?.pieChart.length === 1 ? dashboardWidget.static.length % 3 === 0 ? '4' : '6' : '4'} mb-6 text-center`}
                                                        key={index}
                                                        onClick={(e) => { setShowWidgetStaticModal(true); setDataWidgetStaticModal(data) }}
                                                    >
                                                        <DashboardWidget
                                                            className={`card-xl-stretch card-effect`}
                                                            color={data.color}
                                                            icon=""
                                                            description={data.alias}
                                                            progress={data.value}
                                                            isLoading={dashboardLoading}
                                                        />
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div
                                                        className={`col-12 col-lg-6 col-xl-${dashboardWidget?.pieChart.length === 1 ? dashboardWidget.static.length % 3 === 0 ? '4' : '6' : '4'} mb-6 text-center`}
                                                        key={index}
                                                    >
                                                        <DashboardWidget
                                                            className={`overflow-hidden`}
                                                            color={data.color}
                                                            icon=""
                                                            description={data.alias}
                                                            progress={data.value}
                                                            isLoading={dashboardLoading}
                                                        />
                                                    </div>
                                                )
                                            }
                                        })
                                    }

                                    {
                                        dashboardWidget?.static?.length! > 0 &&
                                        <div className="separator separator-dashed mt-4 mb-10"></div>
                                    }
                                </div>
                                <div className="row">
                                    {
                                        dashboardWidget?.monthly.map((data: MonthlyDashboardWidget, index: number) => {
                                            if (data.detail !== null) {
                                                return (
                                                    <div
                                                        className={`col-12 col-lg-6 col-xl-6 mb-6`}
                                                        key={index}
                                                        onClick={(e) => { setShowWidgetMonthlyModal(true); setDataWidgetMonthlyModal(data) }}
                                                    >
                                                        <DashboardWidget
                                                            className={`card-xl-stretch card-effect`}
                                                            color={data.color}
                                                            icon={`${(data.title).includes("Nominal") ? "/media/assets/wallet.png" : "/media/assets/document.png"}`}
                                                            description={data.alias}
                                                            progress={data.value}
                                                            progress2={data.value2}
                                                            date={moment(date).format("MMM YYYY")}
                                                            isLoading={dashboardLoading}
                                                        />
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div
                                                        className={`col-12 col-lg-6 col-xl-6 mb-6`}
                                                        key={index}
                                                    >
                                                        <DashboardWidget
                                                            className={`overflow-hidden`}
                                                            color={data.color}
                                                            icon={`${(data.title).includes("Nominal") ? "/media/assets/wallet.png" : "/media/assets/document.png"}`}
                                                            description={data.alias}
                                                            progress={data.value}
                                                            progress2={data.value2}
                                                            date={moment(date).format("MMM YYYY")}
                                                            isLoading={dashboardLoading}
                                                        />
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-6">
                                        <div className="card-dashboard green">
                                            {
                                                dashboardWidget && dashboardWidget.fixed['Fixed User Target Achievements'] &&
                                                <SummaryProgressWidget
                                                    date={moment(date).format("MMM YYYY")}
                                                    summary={dashboardWidget.fixed['Fixed User Target Achievements']}
                                                    summaryLoading={dashboardLoading}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${dashboardWidget?.pieChart.length === 1 ? 'col-12 col-lg-6 col-xl-4' : 'col-12'} text-center`}>
                                <div className="row h-100">
                                    {
                                        dashboardWidget?.pieChart.map((data: PieChartDashboardWidget, index: number) => {
                                            return (
                                                <div
                                                    className={`col-12 col-lg-6 col-xl-${dashboardWidget?.pieChart.length === 1 ? '12' : dashboardWidget.pieChart.length % 3 === 0 ? '4' : '6'} mb-6 text-center`}
                                                    key={index}
                                                >
                                                    <DashboardPie
                                                        title={data.alias}
                                                        className=''
                                                        date={(data.title).includes("Static") ? "" : moment(date).format("MMM YYYY")}
                                                        data={data}
                                                        isLoading={dashboardLoading}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
                }
            </div>
            <br />

            {
                (!settings.hide_top_three_sales || (settings.hide_top_three_sales && user.data.is_superadmin)) &&
                <div className="card mb-6">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <h1>Top 3 {intl.formatMessage({ id: "FORM.LABEL.USER" })}</h1>
                            <TopUserFilterModal
                                role_id={user.data.role_id}
                                setRole_id={setTopUserRole}
                                filter={topUserFilter}
                                setFilter={setTopUserFilter}
                            />
                        </div>
                        {
                            topUsers.length > 0 ?
                                <TopUsersWidget
                                    key={topUserRole}
                                    topUsers={topUsers}
                                    filter={topUserFilter}
                                    performanceLoading={performanceLoading}
                                    performanceValidating={performanceValidating}
                                />
                                :
                                // no data found
                                <h3 className="text-center">{intl.formatMessage({ id: "FORM.LABEL.DATA_NOT_FOUND" })}</h3>
                        }

                    </div>
                </div>
            }

            {
                (user.data.company_id === companyId.looyalIT || user.data.company_id === companyId.kawasaki1 || user.data.company_id === companyId.kawasaki2) ?
                    <div className="card mb-6">
                        <div className="card-body">
                            <h1>{intl.formatMessage({ id: "FORM.LABEL.USER_PERFORMANCE" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })}</h1>
                            {moment(date).format("MMM YYYY")}
                            <MasterDataTableTanstack
                                tableKey="dashboard-leads-report"
                                tableColumns={columns}
                                apiURL="kawasaki/users-performance"
                                dateStart={`${date.getFullYear()}-${date.getMonth() + 1}-01`}
                                dateEnd={moment(date).format("YYYY-MM-DD")}
                                autoReload={true}
                                actions={actions}
                            />
                        </div>
                    </div>
                    :
                    (user.data.company_prefix === "tkd" || (user.data.is_superadmin || user.data.has_subordinate)) &&
                    <div className="card mb-6">
                        <div className="card-body">
                            <h1>{intl.formatMessage({ id: "FORM.LABEL.USER_PERFORMANCE" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })}</h1>
                            {moment(date).format("MMM YYYY")}
                            <MasterDataTable
                                tableKey="users-performance"
                                apiURL='dashboard/users-performance'
                                dateStart={`${date.getFullYear()}-${date.getMonth() + 1}-01`}
                                tableColumns={tableColumns}
                            />
                        </div>
                    </div>
            }

            {
                companies.companyInformations?.length > 0 &&
                <div className="card">
                    <div className="card-body mb-4">
                        <CarouselBanner dataBanner={companies.companyInformations} />
                    </div>
                </div>
            }

            {
                (showWidgetStaticModal && !dashboardLoading && dashboardWidget !== undefined) &&
                <WidgetStaticModal data={dataWidgetStaticModal!} setShowWidgetStaticModal={setShowWidgetStaticModal} setShowWidgetDetailMerchantModal={setShowWidgetDetailMerchantModal} setDataWidgetDetailMerchantModal={setDataWidgetDetailMerchantModal} dateStart={`${date.getFullYear()}-${date.getMonth() + 1}-01`} dateEnd={moment().isSame(date, 'month') ? moment().format("YYYY-MM-DD") : moment(endOfMonth(date)).format("YYYY-MM-DD")} />
            }
            {
                (showWidgetMonthlyModal && !dashboardLoading && dashboardWidget !== undefined) &&
                <WidgetMonthlyModal data={dataWidgetMonthlyModal!} dateStart={`${date.getFullYear()}-${date.getMonth() + 1}-01`} dateEnd={moment().isSame(date, 'month') ? moment().format("YYYY-MM-DD") : moment(endOfMonth(date)).format("YYYY-MM-DD")} />
            }
            {
                showWidgetDetailMerchantModal &&
                <WidgetDetailMerchantModal data={dataWidgetDetailMerchantModal} setShowWidgetStaticModal={setShowWidgetStaticModal} setShowWidgetDetailMerchantModal={setShowWidgetDetailMerchantModal} />
            }
        </>
    )
}

export { Dashboard }