import axios from 'axios';
import { SubProgress } from '../interfaces/Progress';

const API_URL = process.env.REACT_APP_API_URL;

const PROGRESS = `${API_URL}lead_progress/`;
const LEAD_PROGRESS = `${API_URL}lead_progresses`;
const ACCESS_PROGRESS_LEAD = `${API_URL}lead-template-lead-progress-accesses/`;

export const insertProgress = async (
  name: string,
  progressType: string,
  color: string,
  subProgress: any,
  token: string,
  isRequiredAttachment: boolean,
  isRequiredUpdateProductHpp?: boolean,
  isSplittable?: boolean
) => {
  return axios.post(
    PROGRESS,
    {
      name,
      progressType,
      color,
      subProgress,
      isRequiredUpdateProductHpp,
      isRequiredAttachment,
      isSplittable
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateProgress = async (
  id: string,
  name: string,
  color: string,
  subProgress: any,
  deleteSubProgress: any,
  token: string,
  isRequiredAttachment: boolean,
  isRequiredUpdateProductHpp?: boolean,
  isSplittable?: boolean
) => {
  return axios.patch(
    PROGRESS + id,
    {
      name,
      color,
      subProgress,
      deleteSubProgress,
      isRequiredUpdateProductHpp,
      isRequiredAttachment,
      isSplittable
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const deleteProgress = async (id: string, token: string) => {
  return axios.delete(PROGRESS + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const getProgresses = async (token: string) => {
  return axios.get(LEAD_PROGRESS, {
    headers: {
      'X-Auth-token': token,
    },
  })
}

export const getAccessProgressesPipeline = async (id: string, token: string) => {
  return axios.get(ACCESS_PROGRESS_LEAD + id, {
    headers: {
      'X-Auth-token': token,
    },
  })
}
