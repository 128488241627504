import React, { useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';

interface PreviewAvatarProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string;
}

const PreviewAvatar: React.FC<PreviewAvatarProps> = ({ isOpen, onClose, imageUrl }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const modalInstance = useRef<Modal | null>(null);

  useEffect(() => {
    if (modalRef.current) {
      modalInstance.current = new Modal(modalRef.current);
    }

    return () => {
      if (modalInstance.current) {
        modalInstance.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (modalInstance.current) {
      if (isOpen) {
        modalInstance.current.show();
      } else {
        modalInstance.current.hide();
      }
    }
  }, [isOpen]);

  const handleClose = () => {
    if (modalInstance.current) {
      modalInstance.current.hide();
    }
    onClose();
  };

  return (
    <div className="modal fade" ref={modalRef} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Profile Picture</h5>
            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
          </div>
          <div className="modal-body text-center">
            <img
              src={imageUrl}
              alt="Full size avatar"
              className="img-fluid rounded"
              style={{ maxHeight: '70vh' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewAvatar;