import React, { ReactNode, useEffect, useState } from 'react'
import MasterTableEmail from '../table/MasterTableEmail'
import { useIntl } from 'react-intl'
import { TableColumn } from 'react-data-table-component'
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment-timezone'
import { CustomFieldType, DetailGroupEmailTypes } from '../../../../interfaces/Email/BroadCastEmailType'
import { Modal } from 'bootstrap'
import { useEmailStore } from '../../../../stores/EmailStore';
import { UpdateDetailGroupEmail } from '../updateModal/UpdateDetailGroupEmail';
import { DeleteDetailGroupEmail } from '../deleteModal/DeleteDetailGroupEmail';
import AddDetailGroupEmail from '../addModal/AddDetailGroupEmail';
import ManageColumnsModal from '../updateModal/ManageColumnsModal';
import { getColumnCustomFields } from '../../../../api/EmailCRUD';
import useSWR from 'swr';
import DetailImportGroupEmail from '../../TableImport/DetailImportGroupEmail';
import { useCustomFieldStore } from '../../../../stores/FieldEmailStore';
import { DetailExportGroupEmail } from '../../TableImport/DetailExportGroupEmail';
import { useDataTableStore } from '../../../../stores/DataTableStore';

const API_URL = process.env.REACT_APP_API_URL_EMAIL

const DetailGroupEmail = () => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showUpdateDetailGroup, setShowUpdateDetailGroup] = useState<boolean>(false);
  const [openMenuId, setOpenMenuId] = useState<string | number | null>(null);
  const [groupContactEmail, setGroupContactEmail] = useState<DetailGroupEmailTypes | null>(null);
  const intl = useIntl()
  const { idDetailGroupEmail, typeEmail, customVariables, setCustomVariables } = useEmailStore()
  const { setCustomFieldStore } = useCustomFieldStore()
  const { tablesData, setTablesData } = useDataTableStore();

  const fetchCustomVariables = async () => {
    try {
      const response = await getColumnCustomFields(`${API_URL}groupcontact/colloum-group-contact?idGroupContact=${idDetailGroupEmail}`)
      if (response) {
        setCustomVariables(response)
        setCustomFieldStore(response[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (typeEmail === 'detailGroupEmail') {
      fetchCustomVariables()
    }
  }, [typeEmail])

  const handleClick = (menuId: string | number) => {
    setOpenMenuId(menuId);
  };
  const handleClose = () => {
    setOpenMenuId(null);
  };

  const baseTableColumns: TableColumn<DetailGroupEmailTypes>[] = [
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
      allowOverflow: true,
      width: '70px',
      id: 'fixedLeft',
      cell: (row) => {
        return (
          <>
            <IconButton
              aria-label={`more-${row.id}`}
              id={`menu-anchor-${row.id}`}
              onClick={() => handleClick(row.id)}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id={`menu-${row.id}`}
              anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
              open={openMenuId === row.id}
              onClose={handleClose}
              disableScrollLock={true}
            >
              <MenuItem onClick={() => {
                handleClose();
                setShowUpdateDetailGroup(true);
                setGroupContactEmail(row)
              }}>
                <i className="fas fa-edit fs-4 me-3"></i>
                <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE_EMAIL' })}</span>
              </MenuItem>

              <MenuItem onClick={() => {
                handleClose();
                setShowDeleteModal(true);
                setGroupContactEmail(row)
              }}>
                <i className="fas fa-trash fs-3 me-4"></i>
                <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
              </MenuItem>
            </Menu>
          </>
        )
      },
    },
    {
      name: 'Nama',
      width: '250px',
      sortable: true,
      allowOverflow: true,
      reorder: true,
      selector: row => row.name,
    },
    {
      name: 'Email',
      width: '250px',
      sortable: true,
      allowOverflow: true,
      reorder: true,
      selector: row => row.email,
    },
    {
      name: 'Tgl Dibuat',
      width: '200px',
      sortable: true,
      cell: row => moment(row.createdAt).format("DD MMMM YYYY"),
      reorder: true,
    },
  ];

  const customFieldColumns: TableColumn<DetailGroupEmailTypes>[] = Array.isArray(customVariables)
    ? customVariables.flatMap((column: { [key: string]: CustomFieldType }) => {
      return Object.entries(column).map(([key, value]) => ({
        name: value?.name,
        width: '200px',
        sortable: true,
        reorder: true,
        cell: (row: DetailGroupEmailTypes) => {
          const customFieldData = row.dataCustomField?.find(field => Object.keys(field)[0] === key);
          if (customFieldData) {
            const fieldValue = customFieldData[key];
            if (value?.type === 'date' && fieldValue) {
              return moment(fieldValue).format("DD MMMM YYYY");
            }
            return fieldValue || '-';
          }
          return '-';
        }
      }));
    })
    : [];
  const tableColumns: TableColumn<DetailGroupEmailTypes>[] = [
    ...baseTableColumns,
    ...customFieldColumns
  ];

  useEffect(() => {
    if (showDeleteModal && groupContactEmail) {
      const modalElement = document.getElementById(`delete-detail-group-email-modal-${groupContactEmail.id}`);

      if (modalElement) {
        const modalInstance = new Modal(modalElement);
        modalInstance.show();

        const handleModalHide = () => {
          setShowDeleteModal(false);
        };

        modalElement.addEventListener('hidden.bs.modal', handleModalHide);

        return () => {
          modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
        };
      }
    }
  }, [showDeleteModal, groupContactEmail, setShowDeleteModal]);

  useEffect(() => {
    if (showUpdateDetailGroup && groupContactEmail) {
      const modalElement = document.getElementById(`update-detail-group-contact-modal-${groupContactEmail.id}`);

      if (modalElement) {
        const modalInstance = new Modal(modalElement);
        modalInstance.show();

        const handleModalHide = () => {
          setShowUpdateDetailGroup(false);
        };

        modalElement.addEventListener('hidden.bs.modal', handleModalHide);

        return () => {
          modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
        };
      }
    }
  }, [showUpdateDetailGroup, groupContactEmail, setShowUpdateDetailGroup]);

  let actions: ReactNode[] = []
  actions.push(
    <ManageColumnsModal idGroupContact={idDetailGroupEmail} />,
    <DetailExportGroupEmail source='template-detail-group-email' />,
    <DetailImportGroupEmail source='template-detail-group-email' />,
    <AddDetailGroupEmail idGroupContact={idDetailGroupEmail} />)

  return (
    <>
      <div className='row'>
        <MasterTableEmail
          tableColumns={tableColumns}
          actions={actions}
          tableKey='detailGroupEmail'
          apiURL={`groupcontact/user-list-group-contact?idGroupContact=${idDetailGroupEmail}`}
        />
      </div>

      {showUpdateDetailGroup && <UpdateDetailGroupEmail groupDetailEmail={groupContactEmail!} />}

      {showDeleteModal && (
        <DeleteDetailGroupEmail data={groupContactEmail!} />
      )}
    </>
  )
}

export default DetailGroupEmail