import axios from 'axios'
import { PhonesType, UserModel } from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify-token`
export const SALES_LOGIN_URL = `${API_URL}/sales-login`
export const PRESALES_LOGIN_URL = `${API_URL}/presales-login`
export const MANAGER_LOGIN_URL = `${API_URL}/manager-login`
export const SUPER_ADMIN_LOGIN_URL = `${API_URL}/superadmin-login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const RESET_PASSWORD = `${API_URL}user-reset-password`
export const FIRST_RESET_PASSWORD = `${API_URL}user-first-reset-password`
export const CONFIRM_RESET_PASSWORD_URL = `${API_URL}/confirm-reset-password`
export const USER_LOGIN = `${API_URL}user-login`
export const USER_SEND_OTP = `${API_URL}user-send-otp`
export const USER_VERIFY_TOKEN = `${API_URL}user-verify-token`

export function resetPassword(username: string, userNewPassword: string, userOtp: string, token: string) {
  return axios.post(RESET_PASSWORD,
    {
      username,
      userNewPassword,
      userOtp
    },
    {
      headers: {
        'x-auth-token': token
      }
    }
  )
}

export function firstResetPassword(username: string, userNewPassword: string, token: string) {
  return axios.post(FIRST_RESET_PASSWORD,
    {
      username,
      userNewPassword
    },
    {
      headers: {
        'x-auth-token': token
      }
    }
  )
}

export function userLogin(username: string, password: string, otp: string, device: string) {
  return axios.post(USER_LOGIN, {
    username,
    password,
    otp,
    device
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(USER_VERIFY_TOKEN, {
    headers: {
      'x-auth-token': token
    }
  })
}

export function confirmResetPassword(resetPasswordSessionId: string, newPassword: string, otpId: string, otp: string) {
  return axios.post(CONFIRM_RESET_PASSWORD_URL,
    {
      resetPasswordSessionId,
      newPassword,
      otpId,
      otp
    },
  )
}

interface IPhoneType {
  phones: PhonesType[]
}

export const changePhoneUser = (data: IPhoneType, token: string) => {
  const response = axios.put(`${API_URL}user/phones`, data, {
    headers: {
      'x-auth-token': token
    }
  })
  return response
}

export const createPrimaryPhone = (number: string, token: string) => {
  const response = axios.post(`${API_URL}user/phones/primary`, { number }, {
    headers: {
      'x-auth-token': token
    }
  })
  return response
}
