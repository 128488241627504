import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../../hooks/AccessToken";
import { KTSVG } from "../../../_metronic/helpers";
import { handleCaps } from "../../../functions/general";
import { Pipeline, TemplatePipelineProps } from "../../../interfaces/Pipeline";
import { TableColumn } from "react-data-table-component";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../../hooks/HandleCloseModal";
import { useTemplatePipeline } from "../../../stores/TemplatePipeline";
import { insertTemplatePipeline, updateTemplatePipeline } from "../../../api/PipelineCRUD";
import { usePipelineStore } from "../../../stores/PipelineStore";

interface ModifyTemplateProps {
    type: string
    manageColumns: TableColumn<Pipeline>[]
    template: TemplatePipelineProps
}

const ModifyTemplate: FC<ModifyTemplateProps> = ({ type, manageColumns, template }) => {
    const token = useAccessToken()
    const intl = useIntl()
    const { selectedFilters } = useDataTableStore();
    const { selectMultiPipeline } = usePipelineStore()
    const { userTemplatePipeline, companyTemplatePipeline, setUserTemplatePipeline, setCompanyTemplatePipeline } = useTemplatePipeline()
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TEMPLATE_NAME" }) })),
    });

    const initialValues = {
        name: type === 'update-template-user' || type === 'update-template-company' ? template.name : '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true);
            setSubmitting(true);

            var dataTemplate = {}
            if (type === 'insert-template-user' || type === 'insert-template-company') {
                var dataSort: string[] = []
                var dataHide: string[] = []
                manageColumns.map(item => {
                    dataSort.push(item.id as string);

                    if (item.omit === true) {
                        dataHide.push(item.id as string);
                    }
                });

                dataTemplate = {
                    hide: dataHide,
                    sort: dataSort,
                    use: false,
                    filter: {
                        leadProducts: [...selectedFilters['pipelines'].leadProducts],
                        lead_progress_name: [...selectedFilters['pipelines'].lead_progress_name],
                        lead_progress_progress_type: [...selectedFilters['pipelines'].lead_progress_progress_type],
                        owner_name: [...selectedFilters['pipelines'].owner_name],
                        leadProductLabels: [...selectedFilters['pipelines'].leadProductLabels],
                        lead_source_name: [...selectedFilters['pipelines'].lead_source_name]
                    }
                }
            } else if (type === 'update-template-user' || type === 'update-template-company') {
                dataTemplate = { ...template.content, use: false }
            }

            try {
                var response: any = {}
                if (type === 'insert-template-user') response = await insertTemplatePipeline('user', selectMultiPipeline.id, values.name, JSON.stringify(dataTemplate), token)
                else if (type === 'insert-template-company') response = await insertTemplatePipeline('company', selectMultiPipeline.id, values.name, JSON.stringify(dataTemplate), token)
                else if (type === 'update-template-user' || type === 'update-template-company') response = await updateTemplatePipeline(template.id, selectMultiPipeline.id, values.name, JSON.stringify(dataTemplate), token)

                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })

                    var dataUpdateUserTemplate = userTemplatePipeline.map((data: TemplatePipelineProps) => ({
                        ...data,
                        content: {
                            ...data.content,
                            use: false,
                        }
                    }))
                    var dataUpdateCompanyTemplate = companyTemplatePipeline.map((data: TemplatePipelineProps) => ({
                        ...data,
                        content: {
                            ...data.content,
                            use: false,
                        }
                    }))

                    if (type === 'insert-template-user') {
                        setCompanyTemplatePipeline([...dataUpdateCompanyTemplate])
                        setUserTemplatePipeline([...dataUpdateUserTemplate, {
                            ...response.data.data,
                            content: {
                                ...dataTemplate,
                                use: true
                            }
                        }]);
                    } else if (type === 'update-template-user') {
                        setUserTemplatePipeline(userTemplatePipeline.map(item => {
                            if (item.id === template.id) {
                                return {
                                    ...item,
                                    name: values.name,
                                }
                            } else {
                                return item
                            }
                        }));
                    } else if (type === 'insert-template-company') {
                        setUserTemplatePipeline([...dataUpdateUserTemplate])
                        setCompanyTemplatePipeline([...dataUpdateCompanyTemplate, {
                            ...response.data.data,
                            content: {
                                ...dataTemplate,
                                use: true
                            }
                        }]);
                    } else if (type === 'update-template-company') {
                        setCompanyTemplatePipeline(companyTemplatePipeline.map(item => {
                            if (item.id === template.id) {
                                return {
                                    ...item,
                                    name: values.name,
                                }
                            } else {
                                return item
                            }
                        }));
                    }

                    closeModalRef.current?.click();
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                });
            } finally {
                setModalBtnLoading(false);
                setSubmitting(false);
                formik.resetForm();
            }
        },
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id="add-template-pipeline-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{type === 'update-template-user' || type === 'update-template-company' ? `${intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} ${intl.formatMessage({ id: 'FILTER.LABEL.TEMPLATE' })} ${template.name}` : `${intl.formatMessage({ id: 'FORM.ACTION.ADD' })} ${intl.formatMessage({ id: 'FILTER.LABEL.TEMPLATE' })}`}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.TEMPLATE_NAME' })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        id="name"
                                        placeholder='Nama'
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.name}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ModifyTemplate }