import React, { FC, useEffect, useState } from 'react'
import { useEmailStore } from '../../stores/EmailStore'
import moment from 'moment'
import { KTSVG } from '../../_metronic/helpers'
import ReplyEmail from './ReplyEmail'
import DetailMoreInfo from './DetailMoreInfo'
import TopNavbar from './TopNavbar'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import PreviewAttachment from './PreviewAttachment'
import { downloadAttachment, extractEmail, getFileTypeFromName, removeQuotes } from '../../functions/email'
import useAccessToken from '../../hooks/AccessToken'
import { useUserStore } from '../../hooks/UserEmail'

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL

interface Props {
  threadedEmails: any
}

const ThreadedEmailDetail: FC<Props> = ({ threadedEmails }) => {
  const { setTypeEmail, typeMenu, uidThread, tokenEmail, setIsthreadOpen, detailEmail, setCurrentSubject } = useEmailStore()
  const [isReplyOpen, setIsReplyOpen] = useState(false)
  const [expandedEmails, setExpandedEmails] = useState<number[]>([])
  const [replyType, setReplyType] = useState<'reply' | 'replyAll'>('reply')
  const [getCC, setGetCC] = useState([])
  const token = useAccessToken()
  const { userEmail } = useUserStore()

  const toggleOpenReply = (type: 'reply' | 'replyAll') => {
    setIsReplyOpen((prevState) => !prevState)
    setReplyType(type)
  }

  const getSenderName = (email: any) => {
    const getLastIndex = email.length - 1
    return extractEmail(email[getLastIndex].from.text)
  }

  const getBodyReplyMail = (email: any) => {
    const getLastIndex = email.length - 1
    return email[getLastIndex].textAsHtml
  }

  const fetcher = (url: string) =>
    axios
      .post(
        url,
        {
          type: typeMenu,
          uid: uidThread,
        },
        {
          headers: {
            'X-auth-token': 'Bearer ' + tokenEmail,
            'x-crm-auth-token': token,
          },
        }
      )
      .then((res) => res.data.data)

  const { data: emailData = [], isValidating: emailIsValidating } = useSWR(
    `${API_URL_EMAIL}dashboard/read-email`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  useEffect(() => {
    if (!emailIsValidating && emailData) {
      mutate(`${API_URL_EMAIL}dashboard/count-emails`)
    }
  }, [emailIsValidating, detailEmail.read])

  const toggleEmailExpansion = (index: number) => {
    setExpandedEmails(prev =>
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    )
  }

  const splitEmailContent = (content: string) => {
    const padaIndex = content.indexOf('Pada');
    const onIndex = content.indexOf('On');
    const separatorIndex = Math.min(
      padaIndex !== -1 ? padaIndex : Infinity,
      onIndex !== -1 ? onIndex : Infinity
    );

    if (separatorIndex !== Infinity) {
      // Check if the found keyword is followed by a date-like string
      const nextFewChars = content.substr(separatorIndex, 20).toLowerCase();
      if (nextFewChars.includes('day,') || nextFewChars.includes('day ') || nextFewChars.match(/\d{1,2}(st|nd|rd|th)?/)) {
        return {
          visibleContent: content.substring(0, separatorIndex).trim(),
          hiddenContent: content.substring(separatorIndex).trim()
        };
      }
    }

    return { visibleContent: content, hiddenContent: '' };
  };

  const getCcRecipients = () => {
    if (getCC?.length > 0) {
      return getCC;
    }
    return [];
  }

  const getAllRecipients = () => {
    const toRecipients = Array.isArray(threadedEmails[0].data[0].to) ? threadedEmails[0].data[0].to : [];
    const ccRecipients = getCcRecipients();
    return [...toRecipients, ...ccRecipients]
      .filter(email => email !== userEmail?.email)
      .filter((email, index, self) => self.indexOf(email) === index); // Remove duplicates
  }

  useEffect(() => {
    if (threadedEmails[0]?.data?.length > 0) {
      const getCCArray = threadedEmails[0]?.data.map((email: any) => email?.cc?.value?.map((val: any) => val.address))
      const getLastIndex = getCCArray.length - 1
      setGetCC(getCCArray[getLastIndex])
    }
  }, [])

  useEffect(() => {
    if (threadedEmails[0]?.data?.length > 0) {
      setCurrentSubject(threadedEmails[0]?.data[0]?.subject)
    }
  }, [threadedEmails, setCurrentSubject])

  return (
    <>
      <div className='d-flex align-items-center gap-3 position-relative mb-5'>
        <TopNavbar />
      </div>
      <div className='card'>
        <div className='card-header align-items-center py-5 gap-5'>
          <div className='d-flex'>
            <span
              className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
              onClick={() => {
                setTypeEmail('listEmail')
                setIsthreadOpen(false)
              }}
            >
              <span className='svg-icon svg-icon-1 m-0'>
                <KTSVG path='/media/icons/duotune/arrows/arr063.svg' />
              </span>
            </span>
          </div>
        </div>
        {emailIsValidating ? (
          <div className='card-body p-0' style={{ marginTop: '300px' }}>
            <div className='loading-overlay'>
              <div className='loader'></div>
            </div>
          </div>
        ) : (
          <div className='card-body'>
            {threadedEmails[0]?.data?.map((email: any, index: number) => {
              const { visibleContent, hiddenContent } = splitEmailContent(email.html || email.textAsHtml || '')
              return (
                <>
                  <div key={index} className='mb-10 border-bottom'>
                    {index === 0 ? (
                      <div className='d-flex flex-wrap gap-2 justify-content-between mb-8'>
                        <div className='d-flex align-items-center flex-wrap gap-5'>
                          <div className='d-flex flex-column'>
                            <h2 className='fw-bold me-3 my-1' style={{ wordBreak: 'break-word' }}>
                              {email.subject}
                            </h2>
                          </div>
                        </div>
                      </div>
                    ) : <></>}
                    <div data-kt-inbox-message='message_wrapper'>
                      <div className='d-flex flex-wrap gap-2 flex-stack' data-kt-inbox-message='header'>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-50 me-4'>
                            <span className='symbol-label text-danger'>
                              {email.from.text.startsWith(`"`) ? email.from.text.substring(1, 3).toUpperCase() : email.from.text.substring(0, 2).toUpperCase()}
                            </span>
                          </div>
                          <div className='pe-5'>
                            <div className='d-flex align-items-center flex-wrap gap-1'>
                              <span className='fw-bolder text-dark'>
                                {email.from.text === userEmail?.email ? removeQuotes(email.from.text) : extractEmail(email.from.text)}
                              </span>
                              {email.merchantName ? (
                                <span className='ms-2 d-flex align-content-center justify-content-center'>
                                  <img src="media/svg/general/merchant.svg" alt="merchant" className="h-20px" />
                                  <h6 className='my-auto ms-2 opacity-50 fs-7'>{email.merchantName}</h6>
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div data-kt-inbox-message='details'>
                              <span className='d-flex gap-2'>
                                <span className='text-muted fw-bold'>
                                  {
                                    `Kepada ${email.to.text === userEmail?.email ? removeQuotes(email.to.text) : extractEmail(email.to.text)}`
                                  }
                                </span>
                                <div style={{ border: 'none !important' }} className='dropdown'>
                                  <button
                                    // style={{ border: 'none' }}
                                    // className='dropdown-toggle'
                                    type='button'
                                    data-bs-toggle='dropdown'
                                    aria-expanded='false'
                                  >
                                    <i className='fas fa fa-caret-down'></i>
                                  </button>
                                  <DetailMoreInfo
                                    date={moment(email.date).format('D MMMM YYYY, HH:mm')}
                                    from={email.from.text === userEmail?.email ? removeQuotes(email.from.text) : extractEmail(email.from.text)}
                                    subject={email.subject}
                                    to={email.to.text === userEmail?.email ? removeQuotes(email.to.text) : extractEmail(email.to.text)}
                                    cc={email?.cc?.value?.map((val: any) => val.address).join(', ')}
                                    bcc={email?.bcc?.value?.map((val: any) => val.address) ?? []}
                                  />
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex align-items-center flex-wrap gap-2'>
                          <span className='fw-bold text-muted text-end me-3'>
                            {moment(email.date).format('DD MMMM YYYY, HH:mm')}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'block',
                          maxWidth: '100vw',
                          overflowX: 'auto',
                        }}
                        className='py-5'
                      >
                        <div dangerouslySetInnerHTML={{ __html: visibleContent }}></div>
                        {hiddenContent && (
                          <>
                            <div className='d-flex align-items-center cursor-pointer mb-2 bg-gray-200 rounded border' style={{ width: '20px' }} onClick={() => toggleEmailExpansion(index)}>
                              <KTSVG
                                path={'media/icons/duotune/general/gen052.svg'}
                                className='svg-icon-2'
                              />
                            </div>
                            {/* <span className='ms-2' style={{color: 'blue'}}>{expandedEmails.includes(index) ? 'Hide' : 'Show'} previous messages</span> */}
                            {expandedEmails.includes(index) && (
                              <div dangerouslySetInnerHTML={{ __html: hiddenContent }}></div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {email && email?.attachments?.length > 0 && (
                      <>
                        <div key={index} className='separator separator-dashed my-6'></div>
                        <span className='fw-bolder text-dark'>
                          {email.attachments.length + ' Lampiran'}
                        </span>
                        <div key={index} className='mb-4' style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                          {email.attachments.map((attachment: any) => {
                            return <div key={attachment.filename}>
                              {['jpeg', 'jpg', 'png'].includes(
                                getFileTypeFromName(attachment.filename)
                              ) ? (
                                <PreviewAttachment
                                  {...attachment}
                                  srcPreview={'data:' + attachment.content.type + ';base64,' + attachment.content.base64}
                                  onClick={() =>
                                    downloadAttachment(
                                      attachment.filename,
                                      attachment.content.data,
                                      attachment.content.contentType
                                    )
                                  }
                                />
                              ) : getFileTypeFromName(attachment.filename) === 'xlsx' ? (
                                <PreviewAttachment
                                  {...attachment}
                                  onClick={() =>
                                    downloadAttachment(
                                      attachment.filename,
                                      attachment.content.data,
                                      attachment.content.contentType
                                    )
                                  }
                                />
                              ) : getFileTypeFromName(attachment.filename) === 'pdf' ? (
                                <PreviewAttachment
                                  {...attachment}
                                  onClick={() =>
                                    downloadAttachment(
                                      attachment.filename,
                                      attachment.content.data,
                                      attachment.content.contentType
                                    )
                                  }
                                />
                              ) : getFileTypeFromName(attachment.filename) === 'docx' ? (
                                <PreviewAttachment
                                  {...attachment}
                                  onClick={() =>
                                    downloadAttachment(
                                      attachment.filename,
                                      attachment.content.data,
                                      attachment.content.contentType
                                    )
                                  }
                                />
                              ) : getFileTypeFromName(attachment.filename) === 'ppt' || 'pptx' ? (
                                <PreviewAttachment
                                  {...attachment}
                                  onClick={() =>
                                    downloadAttachment(
                                      attachment.filename,
                                      attachment.content.data,
                                      attachment.content.contentType
                                    )
                                  }
                                />
                              ) : (
                                <PreviewAttachment
                                  {...attachment}
                                  onClick={() =>
                                    downloadAttachment(
                                      attachment.filename,
                                      attachment.content.data,
                                      attachment.content.contentType
                                    )
                                  }
                                />
                              )}
                            </div>
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )
            })}

            {!isReplyOpen ? (
              <div className="d-flex gap-2">
                <span
                  className='btn btn-sm cursor-pointer button-hover d-flex justify-content-center align-items-center'
                  onClick={() => toggleOpenReply('reply')}
                  style={{
                    fontSize: '1.1rem',
                    border: '0.7px solid black',
                    borderRadius: '30px',
                  }}
                >
                  <img className='w-25px svg-icon' src='/media/svg/general/reply.svg' alt='reply' />
                  <span className='ms-1 fw-bold'>Reply</span>
                </span>
                {(getCcRecipients().length > 0 || (Array.isArray(threadedEmails[0].data[0].to) && threadedEmails[0].data[0].to.length > 1)) && (
                  <span
                    className='btn btn-sm cursor-pointer button-hover d-flex justify-content-center align-items-center'
                    onClick={() => toggleOpenReply('replyAll')}
                    style={{
                      fontSize: '1.1rem',
                      border: '0.7px solid black',
                      borderRadius: '30px',
                    }}
                  >
                    <img className='w-20px svg-icon' src='/media/svg/general/reply-all.svg' alt='reply all' />
                    <span className='ms-1 fw-bold'>Reply All</span>
                  </span>
                )}
              </div>
            ) : (
              <ReplyEmail
                setIsReplyOpen={setIsReplyOpen}
                emailTo={getSenderName(threadedEmails[0].data)}
                cc={replyType === 'replyAll' ? getAllRecipients() : undefined}
                bodyForReply={getBodyReplyMail(threadedEmails[0].data)}
                threadEmailDate={threadedEmails[0].data[threadedEmails[0].data.length - 1].date}
                replyType={replyType}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export { ThreadedEmailDetail }