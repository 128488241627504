import React, { useRef, useEffect, useState } from 'react';
import { Map, Marker, GeoJson, GeoJsonFeature } from "pigeon-maps"
import { KTSVG } from '../../_metronic/helpers';
import { AttendanceTemplate, MyAttendance } from '../../interfaces/Attendance';
import { useIntl } from 'react-intl';
interface NoteModalProps {
    attendance: MyAttendance
    note: string
    updateType: string
}

const AttendanceInfo: React.FC = () => {

    const intl = useIntl()

    return (
        <>
            <button type="button"
                className="btn border border-dark bg-white bg-hover-secondary mt-3"
                data-bs-toggle="modal"
                data-bs-target="#info-attendance-modal"
            >
                <i className="fas fa-info-circle text-dark fs-2"></i> {intl.formatMessage({ id: "FORM.LABEL.INFORMATION" })}
            </button>
            <div className="modal fade" tabIndex={-1} id={`info-attendance-modal`}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title text-center w-100" style={{ lineBreak: 'anywhere' }}>{intl.formatMessage({ id: "FORM.LABEL.INFORMATION" })}</h3>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className='row mb-0 mb-md-6'>
                                <div className='col-12 col-md-6 mb-md-0 mb-6'>
                                    <h5 className='fw-bolder mb-4'>{intl.formatMessage({ id: "FORM.LABEL.TIME" })}</h5>
                                    <div className='mb-3'><i className="fas fa-clock fs-4 text-danger me-3"></i> {intl.formatMessage({ id: "ATTENDANCE_INFO.OFF_TIME" })}</div>
                                    <div className='mb-3'><i className="fas fa-clock fs-4 text-primary me-3"></i> {intl.formatMessage({ id: "ATTENDANCE_INFO.ON_TIME" })}</div>
                                </div>
                                <div className='col-12 col-md-6 mb-md-0 mb-6'>
                                    <h5 className='fw-bolder mb-4'>{intl.formatMessage({ id: "FORM.LABEL.PHOTO" }, { title: "" })}</h5>
                                    <div className='mb-3'><i className="fas fa-eye fs-4 text-danger me-3"></i> {intl.formatMessage({ id: "ATTENDANCE_INFO.OFF_PHOTO" })}</div>
                                    <div className='mb-3'><i className="fas fa-eye fs-4 text-primary me-3"></i> {intl.formatMessage({ id: "ATTENDANCE_INFO.ON_PHOTO" })}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 mb-md-0 mb-6'>
                                    <h5 className='fw-bolder mb-4'>{intl.formatMessage({ id: "FORM.LABEL.LOCATION" })}</h5>
                                    <div className='mb-3'><i className="fas fa-map-marker-alt fs-4 text-danger me-3"></i> {intl.formatMessage({ id: "ATTENDANCE_INFO.OFF_LOCATION" })}</div>
                                    <div className='mb-3'><i className="fas fa-map-marker-alt fs-4 text-primary me-3"></i> {intl.formatMessage({ id: "ATTENDANCE_INFO.ON_LOCATION" })}</div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <h5 className='fw-bolder mb-4'>{intl.formatMessage({ id: "FORM.LABEL.NOTE" })}</h5>
                                    <div className='mb-3'><i className="fas fa-sticky-note fs-4 text-muted me-3"></i> {intl.formatMessage({ id: "ATTENDANCE_INFO.NO_NOTES" })}</div>
                                    <div className='mb-3'><i className="fas fa-sticky-note fs-4 text-primary me-3"></i> {intl.formatMessage({ id: "ATTENDANCE_INFO.NOTES" })}</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary w-100"
                                data-bs-dismiss="modal"
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { AttendanceInfo }