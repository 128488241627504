import { FC, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { Meeting } from "../../interfaces/Meeting";
import { deleteMeeting } from "../../api/MeetingsCRUD";
import moment from "moment";
import { useIntl } from "react-intl";

interface Props {
    meeting: Meeting
}

const DeleteMeeting: FC<Props> = ({ meeting }) => {

    const closeModalRef = useRef<HTMLButtonElement>(null);

    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()

    const intl = useIntl()

    const handleDeleteMeeting = async () => {
        Swal.fire({
            title: intl.formatMessage({ id: 'FORM.VALIDATION.NOTIFICATION' }),
            html: intl.formatMessage({ id: 'FORM.VALIDATION.SEND_NOTIFICATION' }),
            icon: "question",
            showCancelButton: true,
            showDenyButton: true,
            buttonsStyling: false,
            confirmButtonText: intl.formatMessage({ id: 'FORM.ACTION.SEND_NOTIFICATION' }),
            denyButtonText: intl.formatMessage({ id: 'FORM.ACTION.DONT_SEND_NOTIFICATION' }),
            cancelButtonText: intl.formatMessage({ id: 'FORM.ACTION.CANCEL' }),
            customClass: {
                confirmButton: "btn btn-active-color-primary",
                denyButton: "btn btn-active-color-gray-400",
                cancelButton: "btn btn-active-color-gray-400",
            },
            reverseButtons: true,
            heightAuto: false,
            target: document.getElementById(`delete-meeting-modal-${meeting.id}`),
        }).then(async (result) => {
            var notification = false

            if (result.isConfirmed) {
                notification = true
            } else if (result.isDenied) {
                notification = false
            }

            if (!result.isDismissed) {
                setModalBtnLoading(true)
                try {
                    const response = await deleteMeeting(meeting.id, token, notification)
                    if (response.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" }),
                            heightAuto: false,
                        })
                        closeModalRef.current?.click();
                        setTablesData('meetings', (tablesData.meetings.filter((data) => data.id !== meeting.id)))
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        confirmButtonText: 'Ok'
                    })
                } finally {
                    setModalBtnLoading(false)
                }
            }
        })
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`delete-meeting-modal-${meeting.id}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.DELETE" })} {meeting.merchant_name} <br /> {intl.formatMessage({ id: "FORM.LABEL.DATE" }, { title: "" })} {moment(meeting.date_created).format('DD-MM-YYYY')}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>{intl.formatMessage({ id: "FORM.CONFIRMATION" })} {intl.formatMessage({ id: "FORM.ACTION.DELETE" }).toLowerCase()} {meeting.merchant_name} <br />{intl.formatMessage({ id: "FORM.LABEL.DATE" }, { title: "" })} {moment(meeting.date_created).format('DD-MM-YYYY')}?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleDeleteMeeting}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export { DeleteMeeting }