import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { useEmailStore } from '../../stores/EmailStore'
import { deleteEmail, saveDraft, sendEmail } from '../../api/EmailCRUD'
import { KTSVG } from '../../_metronic/helpers'
import AutoCompleteInput from './AutoCompleteInput'
import DragAndDropFile from './BroadcastEmail/FileUploader/DragAndDropFile'
import { formatFileSize, getBase64 } from '../../functions/email'
import 'react-tooltip/dist/react-tooltip.css'
import quillEmoji from 'quill-emoji'
import 'quill-emoji/dist/quill-emoji.css'
import Resizer from 'react-image-file-resizer'
import BoradCastEmailCore from './BroadcastEmail/BroadcastEmailCore'
import { useAttachment } from './hooks/useAttachment'
import { useAttachmentGeneral } from './hooks/useAttachmentGeneral'
import { useUserStore } from '../../hooks/UserEmail'
import { MAX_FILES_SIZE } from './Constant/generalConstEmail'
import { useImagePaste } from './hooks/useImagePaste'


const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji

Quill.register(
  {
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji,
  },
  true
)

interface CardSendEmailProps {
  countEmailsMutate?: () => void
}
interface TypeEmail {
  name: string
  email: string
}

const CardSendEmail: FC<CardSendEmailProps> = ({ countEmailsMutate }) => {
  const [cc, setCc] = useState(false)
  const [bcc, setBcc] = useState(false)
  const {
    valuesFromDraft,
    openSendEmail,
    setOpenSendEmail,
    setValuesFromDraft,
    setValuesSaveDraft,
    setTypeMenu,
    setActiveSidebar,
    setTypeEmail,
    toggleBroadcast,
    setToggleBroadcast
  } = useEmailStore()
  const { userEmail } = useUserStore()
  const quillRef = useRef<ReactQuill>(null)
  const {
    temporaryCopyFile,
    setTemporaryCopyFile,
    dragAndDropFile,
    setDragAndDropFile,
    totalFileSize,
    setTotalFileSize,
    showModal,
    handleOpenAttachment,
    handleCloseModal,
    onDrop,
    removeFile,
  } = useAttachmentGeneral();

  const { handlePaste, checkFileSizeLimit } = useImagePaste({
    quillRef,
    setTemporaryCopyFile,
    setTotalFileSize,
    totalFileSize,
    showModal
  });

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link'],
      ['emoji'],
    ],
    'emoji-toolbar': true,
    'emoji-shortname': true,
  }

  const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'link', 'image', 'emoji']

  const handleResetFormAll = () => {
    countEmailsMutate?.()
    formik.resetForm()
    setValuesFromDraft({
      from: userEmail?.email || '',
      to: '',
      cc: '',
      bcc: '',
      subject: '',
      body: '',
      attachments: [],
      date: '',
    })
    formik.setFieldValue('to', '')
    formik.setFieldValue('cc', '')
    formik.setFieldValue('bcc', '')
  }

  const navigateToSentBox = () => {
    setTypeEmail('listEmail')
    setTypeMenu('sent')
    setActiveSidebar({
      inbox: '',
      sent: 'active',
      draft: '',
      trash: '',
      archive: '',
      spam: '',
      groupEmail: '',
      broadcastEmail: '',
    })
  }

  const formik = useFormik({
    initialValues: openSendEmail
      ? {
        from: valuesFromDraft?.from || userEmail?.email || '',
        to: valuesFromDraft?.to || '',
        cc: valuesFromDraft?.cc || '', // mengecek jika valuesFormDraft cc bcc nya length nya 0 maka cuman return string kosong
        bcc: valuesFromDraft?.cc || '',
        subject: valuesFromDraft?.subject || '',
        body: valuesFromDraft?.body || '',
        files: [],
      }
      : {
        from: userEmail?.email || '',
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        body: '',
        files: [],
      },
    enableReinitialize: true,

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      if (!values.to && !values.cc && !values.bcc) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Tentukan minimal satu penerima email',
          heightAuto: false,
        })
        setSubmitting(false)
        return
      }
      try {
        const response = await sendEmail(
          values.from,
          values.to,
          values.cc,
          values.bcc,
          values.subject,
          values.body,
          values.files
        )
        if (response?.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Berhasil!',
            text: 'Email Terkirim.',
            heightAuto: false,
          })
          if (valuesFromDraft?.id) {
            // untuk delete email on Draft yang sudah berhasil terkirim
            const deleteResponse = await handleDeleteEmailOnDraft()
            if (deleteResponse?.status === 200) {
              handleResetFormAll()
            }
          }
          handleResetFormAll()
          navigateToSentBox()
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'Ok',
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleDeleteEmailOnDraft = async () => {
    try {
      const emailId = valuesFromDraft?.id
      const typeMenu = 'draft'
      const response = await deleteEmail(emailId, typeMenu)
      return response
    } catch (error) {
      console.log(error)
    } finally {
      setOpenSendEmail(false)
    }
  }

  const handleSaveDraft = async () => {
    if (
      formik.values.to !== '' ||
      formik.values.subject !== '' ||
      formik.values.body !== '' ||
      formik.values.files
    ) {
      try {
        const response = await saveDraft(
          userEmail?.email || '',
          formik.values.to,
          formik.values.subject,
          formik.values.body,
          formik.values.cc,
          formik.values.bcc,
          formik.values.files
        )
        if (response?.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Berhasil!',
            text: 'Draft berhasil disimpan.',
            heightAuto: false,
          })

          if (valuesFromDraft?.id) {
            handleDeleteEmailOnDraft()
          }
          handleResetFormAll()
          setValuesFromDraft({
            from: userEmail?.email || '',
            to: '',
            cc: '',
            bcc: '',
            subject: '',
            body: '',
            attachments: [],
            date: '',
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  // handleBeforeUnloadListener
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (formik.values.to || formik.values.subject || formik.values.body) {
        event.preventDefault()
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [formik.values.to, formik.values.subject, formik.values.body, userEmail?.email])
  // End: handleBeforeUnloadListener

  const handleResetForm = () => {
    Swal.fire({
      title: 'Konfirmasi',
      text: 'Apakah Anda yakin ingin reset semua form?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Ya, reset',
      cancelButtonText: 'Batal',
    }).then((result) => {
      if (result.isConfirmed) {
        formik.resetForm()
        formik.setFieldValue('files', [])
        setTemporaryCopyFile([])
        setTotalFileSize(0)
        setValuesFromDraft({
          from: userEmail?.email || '',
          to: '',
          cc: '',
          bcc: '',
          subject: '',
          body: '',
          attachments: [],
          date: '',
        })
        formik.setFieldValue('to', '')
        formik.setFieldValue('cc', '')
        formik.setFieldValue('bcc', '')
      }
    })
  }


  useEffect(() => {
    if (openSendEmail && valuesFromDraft?.cc?.length !== 0 && valuesFromDraft?.bcc?.length !== 0) {
      setCc(true)
      setBcc(true)
    } else if (openSendEmail && valuesFromDraft?.cc?.length !== 0) {
      setCc(true)
    } else if (openSendEmail && valuesFromDraft?.bcc?.length !== 0) {
      setBcc(true)
    } else {
      setCc(false)
      setBcc(false)
    }
  }, [openSendEmail, valuesFromDraft?.cc, valuesFromDraft?.bcc])

  // handleSetValueSaveDraft
  useEffect(() => {
    if (
      formik.values.to !== '' ||
      formik.values.subject !== '' ||
      formik.values.body !== '' ||
      (formik.values.files && formik.values.files.length >= 1)
    ) {
      setValuesSaveDraft({
        from: formik.values.from || userEmail?.email || '',
        to: formik.values.to,
        subject: formik.values.subject,
        body: formik.values.body,
        cc: formik?.values?.cc,
        bcc: formik?.values?.bcc,
        files: formik?.values?.files,
      })
    }
  }, [formik.values])
  // untuk handle auto save draft

  const getImagesFormEditor = document.querySelectorAll('.ql-editor img') as NodeListOf<HTMLImageElement>
  getImagesFormEditor.forEach((img) => {
    img.style.maxWidth = '120px'
  })

  useEffect(() => {
    checkFileSizeLimit(temporaryCopyFile);
  }, [totalFileSize, formik.values.files, showModal, checkFileSizeLimit, temporaryCopyFile]);

  useEffect(() => {
    const editor = quillRef.current?.getEditor()
    if (editor) {
      editor.root.addEventListener('paste', handlePaste)
      return () => {
        editor.root.removeEventListener('paste', handlePaste)
      }
    }
  }, [handlePaste])

  useEffect(() => {
    if (!valuesFromDraft) return
    setTemporaryCopyFile([...valuesFromDraft.attachments])
  }, [valuesFromDraft])

  useEffect(() => {
    formik.setFieldValue('files', temporaryCopyFile)
  }, [temporaryCopyFile])

  const hanldeMultiInput = (event: any, value: any) => {
    const formatDataEmail = value?.map((val: TypeEmail | string) => {
      if (typeof val === 'string') {
        return val
      } else {
        return val.email
      }
    })
    formik.setFieldValue('to', formatDataEmail)
  }
  const handleMultiInputCc = (event: any, value: any) => {
    const formatDataEmail = value?.map((val: TypeEmail | string) => {
      if (typeof val === 'string') {
        return val
      } else {
        return val.email
      }
    })
    formik.setFieldValue('cc', formatDataEmail)
  }
  const handleMultiInputBcc = (event: any, value: any) => {
    const formatDataEmail = value?.map((val: TypeEmail | string) => {
      if (typeof val === 'string') {
        return val
      } else {
        return val.email
      }
    })
    formik.setFieldValue('bcc', formatDataEmail)
  }

  const handleBodyChange = (value: string) => {
    if (value.replace(/<p>\s*<br\s*\/?>\s*<\/p>/g, '').trim().length === 0) {
      formik.setFieldValue('body', ' ');
    } else {
      // Ensure all "cid:" references are properly formatted in HTML
      const updatedValue = value.replace(/src="cid:([^"]+)"/g, (match, cid) => {
        return `src="cid:${cid}" data-cid="${cid}"`;
      });
      formik.setFieldValue('body', updatedValue);
    }
  };

  const handleToggleBroadcast = () => {
    setToggleBroadcast(!toggleBroadcast)
  }

  return (
    <>
      <div className='card'>
        <div className='card-header align-items-center'>
          <div className='card-title d-flex'>
            {toggleBroadcast ? <h2>Broadcast Email</h2> : <h2>Pesan Baru</h2>}
          </div>
          <button className={`${toggleBroadcast ? 'badge rounded-pill bg-dark' : 'badge rounded-pill bg-light text-dark'} cursor-pointer`} onClick={handleToggleBroadcast}>Broadcast</button>
        </div>
        {
          toggleBroadcast ? <BoradCastEmailCore /> : (
            <div className='card-body p-0'>
              <form onSubmit={formik.handleSubmit} noValidate>
                <div className='d-block'>
                  <div className='align-items-center border-bottom ps-8 min-h-50px d-flex'>
                    <>
                      <label className='text-dark fw-bolder'>Kepada</label>
                      <AutoCompleteInput
                        //filter boolean digunakan untuk menghilangkan empty string, agar menjadi []
                        value={
                          Array.isArray(formik.values.to)
                            ? formik.values.to.filter(Boolean)
                            : [formik.values.to].filter(Boolean)
                        }
                        onChange={hanldeMultiInput}
                      />
                    </>
                    <span className='ms-auto text-end px-8'>
                      <span
                        className='text-muted fs-bold cursor-pointer text-hover-primary me-2'
                        onClick={() => setCc(true)}
                      >
                        Cc
                      </span>
                      <span
                        className='text-muted fs-bold cursor-pointer text-hover-primary'
                        onClick={() => setBcc(true)}
                      >
                        Bcc
                      </span>
                    </span>
                  </div>

                  {cc && (
                    <div className='align-items-center border-bottom ps-8 pe-5 min-h-50px d-flex'>
                      <div className='text-dark fw-bolder'>Cc</div>
                      <AutoCompleteInput
                        value={
                          Array.isArray(formik.values.cc)
                            ? formik.values.cc.filter(Boolean)
                            : [formik.values.cc].filter(Boolean)
                        }
                        onChange={handleMultiInputCc}
                      />
                      <span
                        className='btn btn-clean btn-xs btn-icon'
                        onClick={() => {
                          setCc(false)
                        }}
                      >
                        <i className='la la-close'></i>
                      </span>
                    </div>
                  )}

                  {bcc && (
                    <div className='align-items-center border-bottom ps-8 pe-5 min-h-50px d-flex'>
                      <div className='text-dark fw-bolder'>Bcc</div>
                      <AutoCompleteInput
                        value={
                          Array.isArray(formik.values.bcc)
                            ? formik.values.bcc.filter(Boolean)
                            : [formik.values.bcc].filter(Boolean)
                        }
                        onChange={handleMultiInputBcc}
                      />
                      <span
                        className='btn btn-clean btn-xs btn-icon'
                        onClick={() => {
                          setBcc(false)
                        }}
                      >
                        <i className='la la-close'></i>
                      </span>
                    </div>
                  )}
                  <div className='border-bottom'>
                    <input
                      autoComplete='off'
                      className='form-control form-control-transparent border-0 px-8 min-h-45px'
                      placeholder='Subject'
                      {...formik.getFieldProps('subject')}
                    />
                  </div>

                  <div className='ql-toolbar-send-mail ql-snow p-0 border-bottom border-top-0 border-right-0 border-left-0 pb-3 m-3'>
                    <div id='kt_inbox_form_editor' className='bg-transparent border-0 h-400px'>
                      <ReactQuill
                        style={{ height: '85%', maxWidth: '100%' }}
                        theme='snow'
                        value={formik.values.body}
                        onChange={handleBodyChange}
                        modules={modules}
                        formats={formats}
                        ref={quillRef}
                      />
                    </div>
                    {showModal && (
                      <DragAndDropFile
                        onDrop={onDrop}
                        onClose={handleCloseModal}
                        values={temporaryCopyFile}
                        totalFileSize={totalFileSize}
                        setTotalFileSize={setTotalFileSize}
                        valuesDragAndDropFile={dragAndDropFile}
                        setTemporaryFile={setTemporaryCopyFile}
                        setValuesDragAndDropFile={setDragAndDropFile}
                      />
                    )}
                  </div>
                  <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {temporaryCopyFile.map((file: File, index) => {
                      return (
                        <div className='dropzone dropzone-queue px-8 py-1' key={index}>
                          <div
                            className='dropzone-items'
                            style={{ padding: '7px', borderRadius: '5px', backgroundColor: '#f1faff' }}
                          >
                            <div
                              className='dropzone-item dz-processing dz-success dz-complete d-flex justify-content-between'
                              id=''
                              style={{ alignItems: 'center' }}
                            >
                              <div className='dropzone-file'>
                                <div
                                  className='dropzone-filename'
                                  title='some_image_file_name.jpg'
                                  style={{ color: '#7e8299' }}
                                >
                                  <span data-dz-name=''>{file.name}</span>
                                  <strong> ({formatFileSize(file.size)})</strong>
                                </div>
                                <div className='dropzone-error' data-dz-errormessage=''></div>
                              </div>
                              <div className='dropzone-toolbar'>
                                <span
                                  className='dropzone-delete cursor-pointer'
                                  onClick={() => removeFile(index)}
                                >
                                  <i className='bi bi-x fs-1'></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='d-flex flex-stack gap-2 py-5 ps-8 pe-5 border-top'>
                  <div className='d-flex align-items-center me-3'>
                    <span className='btn btn-sm px-4 me-2 button-hover' onClick={handleSaveDraft}>
                      <span className='svg-icon svg-icon-2'>
                        <KTSVG path='/media/svg/general/draft.svg' />
                      </span>
                      <span className='ms-2 d-none d-md-inline-block'>Simpan Ke Draft</span>
                    </span>
                    <div
                      style={{ width: '1px', height: '35px', backgroundColor: '#ccc', margin: '0 10px' }}
                    ></div>
                    <span className='btn btn-sm  px-4 me-2 button-hover' onClick={handleOpenAttachment}>
                      <span className='svg-icon svg-icon-2'>
                        <KTSVG path='/media/svg/general/attachment.svg' />
                      </span>
                      <span className='ms-2 d-none d-md-inline-block'>Lampiran</span>
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <span
                      className='cursor-pointer'
                      onClick={handleResetForm}
                    >
                      <h5 className='my-auto'>Batal</h5>
                    </span>
                    <div
                      style={{ width: '1px', height: '35px', backgroundColor: '#ccc', margin: '0 10px' }}
                    ></div>
                    <div className='btn-group me-4'>
                      <button
                        type='submit'
                        className={`btn btn-primary fs-bold px-6 ${formik.isSubmitting ? 'disabled' : ''
                          }`}
                        data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}
                        disabled={totalFileSize > MAX_FILES_SIZE}
                      >
                        <span className='indicator-label'>Kirim</span>
                        <span className='indicator-progress'>
                          Loading...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )
        }

      </div>
    </>
  )
}

export { CardSendEmail }
