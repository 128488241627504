import { useIntl } from "react-intl";

const useFilterScopePhrase = (scope: string, title: string, username?: string) => {

    const intl = useIntl();

    let prefix: string;
    let suffix: string;

    if (scope.toUpperCase() === 'ALL') {
        prefix = intl.formatMessage({ id: "FILTER.SCOPE.PHRASE.ALL" }, { title: title });
    } else if (username !== undefined) {
        prefix = intl.formatMessage({ id: "FILTER.SCOPE.PHRASE.USER" }, { title: title, user: username });
    } else {
        prefix = intl.formatMessage({ id: "FILTER.SCOPE.PHRASE.OWNED" }, { title: title });
    }

    switch (scope.toUpperCase()) {
        case 'TEAM':
            suffix = intl.formatMessage({ id: "FILTER.SCOPE.PHRASE.TEAM" });
            break;
        case 'WHOLE-TEAM':
            suffix = intl.formatMessage({ id: "FILTER.SCOPE.PHRASE.WHOLE_TEAM" });
            break;
        default:
            suffix = '';
            break;
    }

    return `${prefix} ${suffix}`;
}

export default useFilterScopePhrase;