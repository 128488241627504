import React, { FC } from 'react'
import { FormikProps } from 'formik'
import { CustomField, TypeSerial } from '../../../interfaces/Settings'
import { DatePicker } from "rsuite"
import clsx from 'clsx'
import moment from 'moment-timezone'
import { nanoid } from '@reduxjs/toolkit'
import Select from 'react-select'
import { useIntl } from 'react-intl'
import { handleCaps, isValidUrl } from '../../../functions/general'
import InputPhone from '../../Input/InputPhone'
import InputAttachmentTermin from '../DetailTermin/InputAttachmentTermin'
import { AddLogFormValues } from '../Logs/AddLog'
import useUser from '../../../hooks/User'
import { useCustomeStore } from '../../../stores/CustomeStore'

interface Props {
    customFieldTermin: CustomField
    formik: FormikProps<AddLogFormValues>
    errors: any
    inputRefs: React.RefObject<(HTMLInputElement | null)[]>;
}

const RenderTerminCustomFieldLog: FC<Props> = ({ customFieldTermin, formik, errors, inputRefs }) => {
    const intl = useIntl()
    const user = useUser()
    const { companyId } = useCustomeStore()
    
    // Helper function to safely check touched/errors
    const isTouched = (key: string): boolean => {
        return Boolean(
            errors.customFields?.[key]
        )
    }

    const getError = (key: string): string | undefined => {
        return errors.customFields?.[key];
    };

    const handleLinkValidation = (e: React.FocusEvent<HTMLInputElement>, key: string) => {
        const value = e.target.value;
        if (value && !isValidUrl(value)) {
            formik.setFieldError(`terminForm.customFields.${key}`, intl.formatMessage({ id: 'FORM.VALIDATION.INVALID_URL' }));
        }
    };

    const filteredCustomFields: Record<string, CustomField[keyof CustomField]> = Object.entries(customFieldTermin).reduce((acc, [key, field]) => {
        if (user?.data.company_id === companyId.maspionSquare &&
            (field.name === 'No. Invoice' || field.name === 'Tanggal Create Nomor Invoice')) {
            return acc;
        }
        return { ...acc, [key]: field };
    }, {} as Record<string, CustomField[keyof CustomField]>);

    return (
        <div className="custom-fields-container">
            {Object.keys(filteredCustomFields).map((key, index) => {
                const field = filteredCustomFields[key]
                switch (field.type) {
                    case 'text':
                        return (
                            <div key={index}>
                                <label
                                    className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}
                                >
                                    {field.name}
                                </label>
                                <div className='input-group mb-3'>
                                    <input
                                        placeholder={field.name}
                                        {...formik.getFieldProps(`terminForm.customFields.${key}`)}
                                        className={clsx('form-control form-control-lg', {
                                            'is-invalid': isTouched(key) && getError(key),
                                        })}
                                        type='text'
                                        name={`terminForm.customFields.${key}`}
                                        autoComplete='off'
                                        style={{ zIndex: 0 }}
                                        required={field.required}
                                        onInput={(e) => handleCaps(e)}
                                        onBlur={() => {
                                            formik.setFieldTouched(`terminForm.customFields.${key}`, true);
                                        }}
                                        ref={(ref) => {
                                            if (inputRefs.current) {
                                                inputRefs.current[index] = ref
                                            }
                                        }}
                                    />
                                </div>
                                {getError(key) && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className='text-danger'>
                                            {getError(key)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )

                    case 'link':
                        return (
                            <div key={index}>
                                <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>
                                    {field.name}
                                </label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={field.name}
                                        {...formik.getFieldProps(`terminForm.customFields.${key}`)}
                                        className={clsx(
                                            'form-control form-control-lg txt-link',
                                            { 'is-invalid': isTouched(key) && getError(key) },
                                        )}
                                        type='text'
                                        name={`terminForm.customFields.${key}`}
                                        autoComplete='off'
                                        onBlur={(e) => {
                                            formik.setFieldTouched(`terminForm.customFields.${key}`, true);
                                            handleLinkValidation(e, key);
                                        }}
                                        style={{ zIndex: 0 }}
                                        required={field.required}
                                        ref={ref => {
                                            if (inputRefs.current) {
                                                inputRefs.current[index] = ref
                                            }
                                        }}
                                    />
                                </div>
                                {getError(key) && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className='text-danger'>
                                            {getError(key)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )

                    case 'image':
                        return (
                            <div className='col-md-12 mb-3'>
                                {/* Attachments */}
                                <span className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</span><br />
                                <small><i>*Format PNG, JPG, JPEG</i></small>
                                <InputAttachmentTermin key={key} keyString={key} formik={formik} isMulti={false} type="input" accept="image/png, image/jpeg, image/jpg" typeCustomField='addLog' />
                                {getError(key) && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className='text-danger'>
                                            {getError(key)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )

                    case 'images':
                        return (
                            <div key={key} className='col-md-12 mb-3'>
                                {/* Attachments */}
                                <span className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</span><br />
                                <small><i>*Format PNG, JPG, JPEG</i></small>
                                <InputAttachmentTermin key={key} keyString={key} formik={formik} isMulti={true} type="input" accept="image/png, image/jpeg, image/jpg" typeCustomField='addLog' />
                                {getError(key) && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className='text-danger'>
                                            {getError(key)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )

                    case 'file':
                        return (
                            <div key={key} className="col-md-12 mb-3">
                                <span className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>
                                    {field.name}
                                </span><br />
                                <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                                <InputAttachmentTermin
                                    key={key}
                                    keyString={key}
                                    formik={formik}
                                    isMulti={false}
                                    type="input"
                                    accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    typeCustomField='addLog'
                                />
                                {getError(key) && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className='text-danger'>
                                            {getError(key)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )

                    case 'files':
                        return (
                            <div key={key} className="col-md-12 mb-3">
                                <span className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>
                                    {field.name}
                                </span><br />
                                <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                                <InputAttachmentTermin
                                    key={key}
                                    keyString={key}
                                    formik={formik}
                                    isMulti={true}
                                    type="input"
                                    accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    typeCustomField='addLog'
                                />
                                {getError(key) && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className='text-danger'>
                                            {getError(key)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )

                    case 'date':
                        return (
                            <div key={index}>
                                <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>
                                    {field.name}
                                </label>
                                <div className='input-group mb-3'>
                                    <DatePicker
                                        ranges={[]}
                                        oneTap
                                        editable={false}
                                        format='dd MMM yyyy'
                                        value={formik.values.terminForm?.customFields?.[key] ?
                                            new Date(formik.values.terminForm.customFields[key]) :
                                            null}
                                        onBlur={() => {
                                            formik.setFieldTouched(`terminForm.customFields.${key}`, true);
                                        }}
                                        style={{ width: '100%', zIndex: 0 }}
                                        onChange={(value: any) => {
                                            if (value)
                                                formik.setFieldValue(
                                                    `terminForm.customFields.${key}`,
                                                    moment(value).format('YYYY-MM-DD')
                                                )
                                            else
                                                formik.setFieldValue(`terminForm.customFields.${key}`, null)
                                        }}
                                        container={document.querySelector('#tambah-log-modal') as HTMLElement}
                                    />
                                </div>
                                {getError(key) && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className='text-danger'>
                                            {getError(key)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )

                    case 'option':
                        const options = field.value.map((option: string | TypeSerial) => ({
                            value: option,
                            label: option,
                        }))

                        return (
                            <div key={index}>
                                <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>
                                    {field.name}
                                </label>
                                <div className='input-group mb-3'>
                                    <Select
                                        key={nanoid()}
                                        isClearable
                                        options={options}
                                        placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${field.name}`}
                                        value={
                                            options.find(
                                                (option) =>
                                                    option.value === formik.values.terminForm?.customFields?.[key]
                                            ) || null
                                        }
                                        onChange={(option) =>
                                            formik.setFieldValue(
                                                `terminForm.customFields.${key}`,
                                                option?.value || ''
                                            )
                                        }
                                        onBlur={() =>
                                            formik.values.terminForm?.customFields?.[key] === '' &&
                                            formik.setFieldTouched(`terminForm.customFields.${key}`, true)
                                        }
                                        className='w-100'
                                    />
                                </div>
                                {getError(key) && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className='text-danger'>
                                            {getError(key)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )

                    case 'multiple':
                        return (
                            <div key={index}>
                                <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>
                                    {field.name}
                                </label>
                                <div className='input-group mb-3'>
                                    <div className='form-check form-check-custom form-check-solid' style={{ display: 'contents' }}>
                                        {field.value.map((option: string | TypeSerial, optionIndex: number) => (
                                            <div style={{ display: 'flex', padding: '3px', overflowWrap: 'anywhere' }} key={optionIndex}>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value={option as string}
                                                    name={`terminForm.customFields.${key}`}
                                                    onChange={(e) => {
                                                        const currentValues = formik.values.terminForm?.customFields?.[key] || []
                                                        let newValues
                                                        if (e.target.checked) {
                                                            newValues = [...currentValues, e.target.value]
                                                        } else {
                                                            newValues = currentValues.filter((value: string) => value !== e.target.value)
                                                        }
                                                        formik.setFieldValue(`terminForm.customFields.${key}`, newValues)
                                                    }}
                                                    onBlur={() => {
                                                        formik.setFieldTouched(`terminForm.customFields.${key}`, true);
                                                    }}
                                                    checked={formik.values.terminForm?.customFields?.[key]?.includes(option as string) || false}
                                                />
                                                <label className='form-check-label me-2'>{option}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {getError(key) && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className='text-danger'>
                                            {getError(key)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )

                    case 'phone':
                        return (
                            <div key={index} className='mb-3'>
                                <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>
                                    {field.name}
                                </label>
                                <InputPhone
                                    keyString={key}
                                    formik={formik}
                                    type="input"
                                    typeField='terminForm'
                                />
                                {getError(key) && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className='text-danger'>
                                            {getError(key)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )

                    default:
                        return null
                }
            })}
        </div>
    )
}

export default RenderTerminCustomFieldLog