import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../../_metronic/helpers";
import useHandleCloseModal from "../../../../hooks/HandleCloseModal";
import { handleCaps } from "../../../../functions/general";
import { updateDetailGroupContactEmail, getColumnCustomFields } from "../../../../api/EmailCRUD";
import { DetailGroupEmailTypes } from "../../../../interfaces/Email/BroadCastEmailType";
import { DatePicker } from 'rsuite';
import moment from 'moment';
import useSWR from 'swr';
import { useEmailStore } from "../../../../stores/EmailStore";
import { IUpdateDetailGroupEmail } from "../../../../interfaces/Email/InputEmailTypes";

interface UpdateEmailProps {
    groupDetailEmail: DetailGroupEmailTypes
}

interface CustomFieldType {
    id: string
    name: string
    type: string
    required: boolean
}

interface FormValues {
    name: string;
    email: string;
    dataCustomField: {
        [key: string]: string;
    };
}

const API_URL = process.env.REACT_APP_API_URL_EMAIL

const UpdateDetailGroupEmail: FC<UpdateEmailProps> = ({ groupDetailEmail }) => {
    const intl = useIntl();
    const token = useAccessToken();
    const { tablesData, setTablesData } = useDataTableStore();
    const { idDetailGroupEmail } = useEmailStore()
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [customFields, setCustomFields] = useState<CustomFieldType[] | null>();

    const { data = [], isLoading } = useSWR(`${API_URL}groupcontact/colloum-group-contact?idGroupContact=${idDetailGroupEmail}`, getColumnCustomFields)

    useEffect(() => {
        if (data && data.length > 0) {
            const formattedColumns = Object.entries(data[0]).map(([id, columnData]: [string, any]) => ({
                id,
                name: columnData.name,
                type: columnData.type,
                required: columnData.required,
            }))
            setCustomFields(formattedColumns)
        }
    }, [data])

    const formik = useFormik<FormValues>({
        enableReinitialize: true,
        initialValues: {
            name: groupDetailEmail.name,
            email: groupDetailEmail.email,
            dataCustomField: groupDetailEmail.dataCustomField
                ? groupDetailEmail.dataCustomField.reduce((acc: { [key: string]: string }, field: { [key: string]: string }) => {
                    const key = Object.keys(field)[0];
                    acc[key] = field[key];
                    return acc;
                  }, {} as { [key: string]: string })
                : {} // Inisialisasi sebagai objek kosong jika dataCustomField tidak ada
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'Name' })),
            email: Yup.string().email('Invalid email').required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'Email' })),
            dataCustomField: Yup.object().shape(
                customFields?.reduce((shape, field) => {
                    if (field.required) {
                        shape[field.id] = Yup.string().required(`${field.name} is required`);
                    }
                    return shape;
                }, {} as { [key: string]: Yup.StringSchema }) || {}
            )
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            try {
                const dataCustomField = Object.entries(values.dataCustomField).map(([key, value]) => ({ [key]: value }));
                const updateEmailData: IUpdateDetailGroupEmail = {
                    id: groupDetailEmail.id,
                    name: values.name,
                    email: values.email,
                    dataCustomField,
                };
                const response = await updateDetailGroupContactEmail(updateEmailData);
                if (response) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_EMAIL_SUCCESS" })}`,
                        timer: 1500,
                        timerProgressBar: true
                    });
                    setTablesData('detailGroupEmail', tablesData.detailGroupEmail.map((item: any) =>
                        item.id === groupDetailEmail.id ? response.data : item
                    ));
                    closeModalRef.current?.click();
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response?.data?.message || 'An error occurred',
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                });
            } finally {
                setSubmitting(false);
            }
        }
    });

    const handleCustomFieldChange = (fieldId: string, value: string | null) => {
        formik.setFieldValue(`dataCustomField.${fieldId}`, value);
    };

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id={`update-detail-group-contact-modal-${groupDetailEmail.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} Contact Email</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {/* Name */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={`${intl.formatMessage({ id: 'FORM.LABEL.NAME' })}`}
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.name}</span>
                                    </div>
                                )}
                                {/* Email */}
                                <label className='form-label fs-6 fw-bolder text-dark required'> Email</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={`Email`}
                                        {...formik.getFieldProps('email')}
                                        className={clsx(
                                            'form-control form-control-lg txt-email',
                                            { 'is-invalid': formik.touched.email && formik.errors.email },
                                        )}
                                        type='text'
                                        name='email'
                                        autoComplete='off'
                                    />
                                </div>
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.email}</span>
                                    </div>
                                )}
                                {/* Custom Fields */}
                                {customFields?.map((field) => (
                                    <div key={field.id}>
                                        <label className={`${field.required ? 'required' : ''} form-label fs-6 fw-bolder text-dark`}>{field.name}</label>
                                        <div className='input-group mb-3'>
                                            {field.type === 'text' && (
                                                <input
                                                    type="text"
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': formik.touched.dataCustomField && formik.touched.dataCustomField[field.id] && formik.errors.dataCustomField && formik.errors.dataCustomField[field.id] },
                                                    )}
                                                    placeholder={field.name}
                                                    name={`dataCustomField.${field.id}`}
                                                    value={(formik.values.dataCustomField && formik.values.dataCustomField[field.id]) || ''}
                                                    onChange={(e) => handleCustomFieldChange(field.id, e.target.value)}
                                                    autoComplete='off'
                                                />
                                            )}
                                            {field.type === 'number' && (
                                                <input
                                                    type="number"
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': formik.touched.dataCustomField && formik.touched.dataCustomField[field.id] && formik.errors.dataCustomField && formik.errors.dataCustomField[field.id] },
                                                    )}
                                                    placeholder={field.name}
                                                    name={`dataCustomField.${field.id}`}
                                                    value={(formik.values.dataCustomField && formik.values.dataCustomField[field.id]) || ''}
                                                    onChange={(e) => handleCustomFieldChange(field.id, e.target.value)}
                                                    autoComplete='off'
                                                />
                                            )}
                                            {field.type === 'date' && (
                                                <DatePicker
                                                    format="dd MMM yyyy"
                                                    style={{ width: "100%", zIndex: 0 }}
                                                    container={document.querySelector(`#update-detail-group-contact-modal-${groupDetailEmail.id}`) as HTMLElement}
                                                    value={formik.values.dataCustomField[field.id] ? new Date(formik.values.dataCustomField[field.id]) : null}
                                                    onChange={(value: Date | null, event: React.SyntheticEvent<Element, Event>) => {
                                                        if (value) {
                                                            handleCustomFieldChange(field.id, moment(value).format('YYYY-MM-DD'));
                                                        } else {
                                                            handleCustomFieldChange(field.id, null);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {formik.touched.dataCustomField && formik.touched.dataCustomField[field.id] && formik.errors.dataCustomField && formik.errors.dataCustomField[field.id] && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.dataCustomField[field.id]}</span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${formik.isSubmitting ? 'disabled' : ''}`} data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { UpdateDetailGroupEmail }