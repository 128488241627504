import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import useAccessToken from "../../hooks/AccessToken";
import { Source } from "../../interfaces/Source";
import { updateSource } from "../../api/SourcesCRUD";
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps } from "../../functions/general";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

interface Props {
    source: Source
}

const UpdateSource: FC<Props> = ({ source }) => {

    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()

    const intl = useIntl()

    const formik = useFormik({
        initialValues: {
            name: source.name,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Nama harus diisi'),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            try {
                const response = await updateSource(source.id, values.name, token)
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    closeModalRef.current?.click();
                    setTablesData('lead_sources', (tablesData.lead_sources.map(item => {
                        if (item.id === source.id) {
                            return {
                                ...item,
                                name: response.data.data.name,
                            }
                        } else {
                            return item
                        }
                    })));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div className="modal fade" tabIndex={-1} id={`update-source-modal-${source.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} {source.name}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    {...formik.getFieldProps('name')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                    )}
                                    type='text'
                                    name='name'
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.name}</span>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleCloseModal}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { UpdateSource }