// AutoCompleteInput.tsx
import React, {useEffect, useState} from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import {makeStyles} from '@mui/styles'
import {KTSVG} from '../../_metronic/helpers'
import {searchAccount} from '../../api/EmailCRUD'
interface AutoCompleteInputProps {
  value: string[]
  onChange: (event: React.ChangeEvent<{}>, value: string[] | null) => void
}

const useStyles = makeStyles({
  textField: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
})

export default function AutoCompleteInput({value, onChange}: AutoCompleteInputProps) {
  const classes = useStyles()
  const [searchData, setSearchData] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    // Sebaiknya pada bagian ini gunakan custom hooks
    const handler = setTimeout(() => {
      const fetchData = async () => {
        try {
          const data = await searchAccount(inputValue)
          setSearchData(data)
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }

      if (inputValue.length > 0) {
        fetchData()
      } else {
        setSearchData([])
      }
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [inputValue])

  const handleCustomOptions = (inputValue: string, options: string[]) => {
    if (inputValue.includes('.') && inputValue.includes('@')) {
      const newOption = inputValue
      return [...(options || []), newOption]
    }
    return options
  }

  const filterOptions = (options: string[], state: object) => {
    const inputValue = (state as {inputValue: string}).inputValue || ''
    const customOptions = handleCustomOptions(inputValue, options)
    const filtered = customOptions?.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    )
    return filtered || []
  }

  return (
    <Stack spacing={3} sx={{width: '100%', marginLeft: '5px'}}>
      <Autocomplete
        multiple
        id='tags-standard'
        options={searchData || []}
        inputValue={inputValue}
        value={value}
        onChange={(event, newValue) => {
          onChange(event, newValue)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} variant='standard' className={classes.textField} />
        )}
        renderOption={(props, option) => {
          const optionStr = option as string
          return (
            <li {...props} style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              <KTSVG
                path='media/icons/duotune/communication/com013.svg'
                className='svg-icon-primary svg-icon-2x'
              />
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-1'>
                  {optionStr.split('@')[0]}
                </span>
                <span className='fw-bold text-muted'>{optionStr}</span>
              </div>
            </li>
          )
        }}
        filterOptions={filterOptions}
      />
    </Stack>
  )
}
