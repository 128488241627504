import React, { useCallback, useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { useEmailStore } from '../../stores/EmailStore'
import Swal from 'sweetalert2'
import { useDebounce } from "react-use"
import ProfileAvatar from './ProfileAvatar'

const TopNavbar: React.FC = () => {
  const {
    filterText,
    setFilterText,
    setShowSideBar,
    showClearIcon,
    setShowClearIcon,
  } = useEmailStore()
  const [searchInput, setSearchInput] = useState(filterText)

  const updateFiltered = useCallback(() => {
    setFilterText(searchInput)
    setShowClearIcon(searchInput !== '')
  }, [searchInput, setFilterText, setShowClearIcon])

  useDebounce(updateFiltered, 500, [searchInput])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchInput(value)
  }


  const handleShowSideBar = () => {
    setShowSideBar((prevState: boolean) => !prevState)
  }

  const handleClearSearch = () => {
    setSearchInput('')
    setFilterText('')
    setShowClearIcon(false)
  }


  return (
    <>
      {/* Navbar untuk mobile */}
      <span className='cursor-pointer nav-hamburg d-lg-none d-block' onClick={handleShowSideBar}>
        <img width={30} src='/media/svg/general/navHamburg.svg' alt='hamburg-nav' />
      </span>
      {/* End: navbar mobile */}
      <span style={{ width: '100%' }} className='d-flex align-items-center position-relative'>
        <span className='svg-icon svg-icon-2 position-absolute ms-4'>
          <KTSVG path='media/icons/duotune/general/gen021.svg' />
        </span>
        <input
          type='text'
          value={searchInput}
          onChange={handleInputChange}
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '23px',
            paddingRight: showClearIcon ? '40px' : '12px',
          }}
          data-kt-inbox-listing-filter='search'
          className='py-5 form-control form-control-sm form-control-solid ps-12 custom-placeholder search-input'
          placeholder='Telusuri pesan'
        />
        {showClearIcon && (
          <span
            className='svg-icon svg-icon-2 position-absolute cursor-pointer'
            style={{ right: '15px' }}
            onClick={handleClearSearch}
          >
            <KTSVG path='media/icons/duotune/general/gen034.svg' />
          </span>
        )}
      </span>
      <ProfileAvatar />
    </>
  )
}

export default TopNavbar
