import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { style } from '@mui/system';
import 'moment-duration-format';
import addDays from 'date-fns/addDays';

interface Props {
    nameFile: string
    data: any[]
    dataBranch: any[]
    keys: string[]
}

export const exportExcelUserPerformance = async ({ nameFile, data, dataBranch, keys }: Props) => {
    const workbook = createWorkbook();

    const worksheet = createWorksheet(workbook, 'Semua Cabang');

    worksheet.getRow(1).values = [
        `Deskripsi`,
        `Prospek`,
        `PROSPEK`,
        ``,
        ``,
        `PAID`,
        ``,
        ``,
        `Lost`,
        ``,
        `Cold`,
        ``,
        `Hot`,
        ``,
        `Paid`,
        ``,
        `TOTAL`,
        ``
    ];

    worksheet.getRow(2).values = [
        ``,
        ``,
        `Target`,
        `Pencapaian`,
        ``,
        `Target`,
        `Pencapaian`,
        ``,
        ``,
        ``,
        ``,
        ``,
        ``,
        ``,
        ``,
    ];

    worksheet.getRow(3).values = [
        ``,
        ``,
        ``,
        `Q`,
        `%`,
        ``,
        `Q`,
        `%`,
        `Q`,
        `%`,
        `Q`,
        `%`,
        `Q`,
        `%`,
        `Q`,
        `%`,
        `Q`,
        `%`,
    ];

    worksheet.mergeCells('A1', 'A3');
    worksheet.mergeCells('C1', 'E1');
    worksheet.mergeCells('D2', 'E2');
    worksheet.mergeCells('B1', 'B3');
    worksheet.mergeCells('C2', 'C3');
    worksheet.mergeCells('F2', 'F3');
    worksheet.mergeCells('G2', 'H2');
    worksheet.mergeCells('F1', 'H1');
    worksheet.mergeCells('I1', 'J2');
    worksheet.mergeCells('K1', 'L2');
    worksheet.mergeCells('M1', 'N2');
    worksheet.mergeCells('O1', 'P2');
    worksheet.mergeCells('Q1', 'R2');

    addDataRows(worksheet, data);
    setColumnWidths(worksheet);
    styleColumns(worksheet);
    worksheet.mergeCells('A4', 'A' + (3 + data.length));

    keys.map((key: string, index: number) => {
        if (dataBranch.find((item: any) => item.key === key)) {
            const worksheet = createWorksheet(workbook, key);

            worksheet.getRow(1).values = [
                `Deskripsi`,
                `Prospek`,
                `PROSPEK`,
                ``,
                ``,
                `PAID`,
                ``,
                ``,
                `Lost`,
                ``,
                `Cold`,
                ``,
                `Hot`,
                ``,
                `Paid`,
                ``,
                `TOTAL`,
                ``
            ];

            worksheet.getRow(2).values = [
                ``,
                ``,
                `Target`,
                `Pencapaian`,
                ``,
                `Target`,
                `Pencapaian`,
                ``,
                ``,
                ``,
                ``,
                ``,
                ``,
                ``,
                ``,
            ];

            worksheet.getRow(3).values = [
                ``,
                ``,
                ``,
                `Q`,
                `%`,
                ``,
                `Q`,
                `%`,
                `Q`,
                `%`,
                `Q`,
                `%`,
                `Q`,
                `%`,
                `Q`,
                `%`,
                `Q`,
                `%`,
            ];

            worksheet.mergeCells('A1', 'A3');
            worksheet.mergeCells('C1', 'E1');
            worksheet.mergeCells('D2', 'E2');
            worksheet.mergeCells('B1', 'B3');
            worksheet.mergeCells('C2', 'C3');
            worksheet.mergeCells('F2', 'F3');
            worksheet.mergeCells('G2', 'H2');
            worksheet.mergeCells('F1', 'H1');
            worksheet.mergeCells('I1', 'J2');
            worksheet.mergeCells('K1', 'L2');
            worksheet.mergeCells('M1', 'N2');
            worksheet.mergeCells('O1', 'P2');
            worksheet.mergeCells('Q1', 'R2');

            addDataRows(worksheet, dataBranch.filter((item: any) => item.key === key));
            setColumnWidths(worksheet);
            styleColumns(worksheet);
            worksheet.mergeCells('A4', 'A' + (3 + dataBranch.filter((item: any) => item.key === key).length));
        }
    })
    exportWorkbook(workbook, `${nameFile}.xlsx`);
};

const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Looyal';
    workbook.lastModifiedBy = 'Looyal';
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
};

const createWorksheet = (workbook: ExcelJS.Workbook, sheetName: string) => {
    let worksheet = workbook.addWorksheet(sheetName);
    return worksheet;
};

const addDataRows = (worksheet: ExcelJS.Worksheet, dataBranch: any[],) => {
    dataBranch.forEach((rowData, index) => {
        var tempData: string[] = []
        tempData.push(
            rowData.desc,
            rowData.prospect,
            rowData.prospectTarget,
            rowData.prospectAchievementQ,
            rowData.prospectAchievementPercent,
            rowData.paidTarget,
            rowData.paidAchievementQ,
            rowData.paidAchievementPercent,
            rowData.lostQ,
            rowData.lostPercent,
            rowData.coldQ,
            rowData.coldPercent,
            rowData.hotQ,
            rowData.hotPercent,
            rowData.paidQ,
            rowData.paidPercent,
            rowData.totalQ,
            rowData.totalPercent,
        )

        worksheet.addRow(tempData);
    });

};

const setColumnWidths = (worksheet: ExcelJS.Worksheet) => {
    const colWidths = [25, 35];
    worksheet.columns = colWidths.map((width) => ({ width }));
};

const styleColumns = (worksheet: ExcelJS.Worksheet) => {
    const allColumns = ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'];
    allColumns.map((key) => {
        worksheet.getColumn(key).alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
        }
    });

    worksheet.getColumn('A').alignment = {
        vertical: 'middle',
        wrapText: true,
    }

    worksheet.getRow(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheet.getRow(2).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheet.getRow(3).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };

    worksheet.getRow(1).font = {
        name: 'Arial Black',
        size: 10,
        bold: true,
    };
    worksheet.getRow(2).font = {
        name: 'Arial Black',
        size: 10,
        bold: true,
    };
    worksheet.getRow(3).font = {
        name: 'Arial Black',
        size: 10,
        bold: true,
    };
};

const exportWorkbook = async (workbook: ExcelJS.Workbook, fileName: string) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    });
};
