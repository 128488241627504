/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import { DefaultTitle } from './page-title/DefaultTitle'
import { Topbar } from './Topbar'
import moment from 'moment'
import 'moment/locale/id';
import { useEffect, useState } from 'react'
import Select from 'react-select';
import useAccessToken from '../../../../hooks/AccessToken'
import { SelectOption } from '../../../../interfaces/SelectOption';
import axios from 'axios'
import useSWR from 'swr'
import { CompanyBranch } from '../../../../interfaces/CompanyBranch'
import useUser from '../../../../hooks/User'
import { switchCompany } from '../../../../api/CompanyCRUD'
import { useDispatch } from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

export function HeaderWrapper() {

  const user = useUser()
  const token = useAccessToken()
  const dispatch = useDispatch()

  const API_URL = process.env.REACT_APP_API_URL

  const { config, classes, attributes } = useLayout()
  const { header, aside } = config

  const [userCompanyId, setUserCompanyId] = useState<string>(user.data.id)
  const [companyBranchesOptions, setCompanyBranchesOptions] = useState<SelectOption[]>([])

  const fetcher = (url: string) => axios.get(url, {
    headers: {
      'X-Auth-token': token
    }
  }).then(res => res.data.data)
    .catch(error => {
      throw error.response ? error.response.data : error;
    })

  const { data: companyBranches = [], error: companyBranchesError, isLoading: companyBranchesLoading } = useSWR(user.data.is_superadmin ? `${API_URL}company-branches` : null, fetcher)

  useEffect(() => {
    if (companyBranchesError) {
      console.log("Error fetching company branches:", companyBranchesError.message);
      // Handle error accordingly, e.g., show error message to the user
      return;
    }

    if (!companyBranchesLoading && companyBranches.length > 0) {
      setCompanyBranchesOptions(
        companyBranches?.filter((companyBranch: CompanyBranch) => companyBranch.userId !== user.data.id)?.map((companyBranch: CompanyBranch) => ({
            label: companyBranch.companyName,
            value: companyBranch.userId
          }))
      );
    }
  }, [companyBranches])

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
  }

  const handleSwitchBranch = async (selectedOptions: any) => {
    setUserCompanyId(selectedOptions.value)
    enableSplashScreen()
    try {
      const response = await switchCompany(selectedOptions.value, token)

      if (response.status === 200) {
        dispatch(auth.actions.login(response.data.data.token))
      }
    } catch (error) {
      console.log(error)
    } finally {
      window.location.href = '/'
    }
  }

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
      style={{ zIndex: "20" }}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        <span className="align-self-center fs-3">
          {
            !user.data.is_superadmin ?
              moment().locale('id').format('dddd, DD MMM YYYY')
              :
              companyBranchesOptions &&
              <Select
                placeholder="Pilih Perusahaan"
                defaultValue={{
                  label: user.data.company_name,
                  value: user.data.id
                }}
                value={companyBranchesOptions.find(option => option.value === userCompanyId)}
                options={companyBranchesOptions}
                isDisabled={companyBranchesLoading}
                onChange={handleSwitchBranch}
              />
          }
        </span>
        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              {/* <Header /> */}
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0 align-self-center'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
