import { FC, useEffect, useRef, useState } from "react";
import { useVirtualizer } from '@tanstack/react-virtual';
import { useChatStore } from "../../../stores/ChatStore";
import { ChatMessage } from "./ChatMessage";
import moment from "moment-timezone";
import { KTSVG } from "../../../_metronic/helpers";
import useUser from "../../../hooks/User";
import CheckboxList from "./CheckboxList";

const ChatMessageList: FC = () => {
    const {
        messages, filteredRecentChatHistories, phoneNumber, persistedMessages,
        showChat, setFilteredRecentChatHistories, scrollToId, setScrollToId, isOpenCheckBoxForward
    } = useChatStore();

    const [isAtBottom, setIsAtBottom] = useState(true);
    const user = useUser();

    const parentRef = useRef<HTMLDivElement>(null);

    // The virtualizer
    const virtualizer = useVirtualizer({
        count: messages.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 2200,
        overscan: 10,
        enabled: true,
    });

    const items = virtualizer.getVirtualItems();

    useEffect(() => {
        const lastMessage = messages[messages.length - 1];
        const recentChat = filteredRecentChatHistories.find(recentChat => recentChat.chat_phone === phoneNumber);

        if (showChat && ((lastMessage && recentChat?.isFirstLoaded && scrollToId !== lastMessage.id) || (lastMessage && lastMessage.type === 'out' && lastMessage.sender))) {
            scrollToChat(messages.length - 1);

            setFilteredRecentChatHistories((prevState) => prevState.map(chat => {
                if (chat.chat_phone === phoneNumber) {
                    return {
                        ...chat,
                        isFirstLoaded: false
                    };
                }
                return chat;
            }));
        } else if (showChat && scrollToId) {
            scrollToChat(messages.findIndex(message => message.id === scrollToId));
            setScrollToId('');
        }
    }, [phoneNumber, scrollToId, messages, showChat]);

    const scrollToChat = (index: number) => {
        virtualizer.scrollToIndex(index, { align: 'center' });
    };

    const handleScrollToButtonClick = () => {
        scrollToChat(messages.length - 1);
        setIsAtBottom(true)
    };

    // Scroll listener to determine if the last message is visible
    useEffect(() => {
        const handleScroll = () => {
            const lastMessageIndex = messages.length - 1;
            const lastVirtualItem = items.find(item => item.index === lastMessageIndex);

            // Check if the last message is within the visible range
            const isLastMessageVisible = lastVirtualItem && lastVirtualItem.start >= virtualizer.scrollElement?.scrollTop!
                && lastVirtualItem.end <= virtualizer.scrollElement!.scrollTop + virtualizer.scrollElement!.clientHeight;

            setIsAtBottom(isLastMessageVisible!);
        };

        const scrollElement = parentRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollElement) {
                scrollElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [items, messages.length, virtualizer]);

    return (
        <div
            ref={parentRef}
            className="card-body my-2"
            style={{
                height: '100%',
                overflowY: 'auto',
                position: 'relative', // Make this element the containing block for the sticky item
                contain: 'strict',
            }}
        >
            <div
                style={{
                    height: virtualizer.getTotalSize(),
                    width: '100%',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${items[0]?.start ?? 0}px)`,
                    }}
                >
                    {items.map((virtualItem, index) => {
                        return (
                            <div
                                key={virtualItem.key.toString()}
                                data-index={virtualItem.index}
                                ref={virtualizer.measureElement}
                                className={`message-item ${isOpenCheckBoxForward ? 'message-with-checkbox' : ''}`}
                            >
                                {(virtualItem.index === 0 || (virtualItem.index > 0 && moment(messages[virtualItem.index].time).format('DD MMMM YYYY') !== moment(messages[virtualItem.index - 1].time).format('DD MMMM YYYY'))) && (
                                    <div className="d-flex justify-content-center">
                                        <span className="badge badge-light-primary fs-7 fw-bold mb-2">
                                            {String(moment().isoWeek() === moment(messages[virtualItem.index].time).isoWeek() && moment().startOf('day').diff(moment(messages[virtualItem.index].time, 'YYYY-MM-DD HH:mm:ss').startOf('day'), 'days') > 1
                                                ? moment(messages[virtualItem.index].time).format('dddd')
                                                : moment().startOf('day').diff(moment(messages[virtualItem.index].time, 'YYYY-MM-DD HH:mm:ss').startOf('day'), 'days') === 1
                                                    ? 'Yesterday'
                                                    : moment().startOf('day').diff(moment(messages[virtualItem.index].time, 'YYYY-MM-DD HH:mm:ss').startOf('day'), 'days') === 0
                                                        ? 'Today'
                                                        : moment(messages[virtualItem.index].time).format('DD MMMM YYYY')).toUpperCase()}
                                        </span>
                                    </div>
                                )}
                                {
                                    // virtualItem.index !== 0 &&
                                    // ((messages[virtualItem.index].phone === user.data.chat_phone && messages[virtualItem.index - 1].phone !== user.data.chat_phone) ||
                                    // // same number but different day
                                    // (messages[virtualItem.index].phone === user.data.chat_phone && moment(messages[virtualItem.index].time).format('DD MMMM YYYY') !== moment(messages[virtualItem.index - 1].time).format('DD MMMM YYYY')))
                                    // &&
                                    // <span className="d-flex justify-content-end">
                                    //     {messages[virtualItem.index].sender?.name}
                                    // </span>
                                }
                                <div className="position-relative">
                                    {isOpenCheckBoxForward && (
                                        <CheckboxList
                                            message={messages[virtualItem.index]}
                                        // isSelected={selectedMessages.has(messages[virtualItem.index].id as string)}
                                        // onToggle={handleToggleMessage}
                                        />
                                    )}
                                    <ChatMessage
                                        message={messages[virtualItem.index]}
                                        key={messages[virtualItem.index].id}
                                        scrollToChat={scrollToChat}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* Sticky button at the bottom right */}
            {!isAtBottom && (
                <span
                    style={{
                        position: 'sticky',
                        bottom: 0,           // Stick to the bottom of the parent
                        right: 0,            // Stick to the right of the parent
                    }}
                >
                    <p className="text-end">
                        <span
                            style={{
                                cursor: "pointer",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
                            }}
                            className="bg-white border border-1 py-6 px-4 rounded"
                            onClick={handleScrollToButtonClick}
                        >
                            <KTSVG path="media/icons/duotune/arrows/arr004.svg" className="svg-icon-dark svg-icon-2x" />
                        </span>
                    </p>
                </span>
            )}
        </div>
    );
};

export { ChatMessageList };