import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { customNumberFormat, getDateSummary } from "../../functions/general";
import { ListProduct, Pipeline } from "../../interfaces/Pipeline";
import { useSettingsStore } from "../../stores/SettingsStore";
import DataTable, { TableColumn } from "react-data-table-component";
import { MasterDataTable } from "../DataTable/MasterDataTable";
import useUser from "../../hooks/User";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from "bootstrap";
import { AddLog } from "./Logs/AddLog";
import { useIntl } from "react-intl";

interface DetailProductPipelineProps {
}

const AbandonedPipeline: FC<DetailProductPipelineProps> = ({ }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { settings } = useSettingsStore()
    const user = useUser()
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [showAddLogModal, setShowAddLogModal] = useState<boolean>(false);
    const [pipeline, setPipeline] = useState<Pipeline>();
    const menuItemRef = useRef(null);
    const intl = useIntl()

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const tableColumns: TableColumn<Pipeline>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            allowOverflow: true,
            width: '150px',
            id: 'fixedLeft',
            cell: (row) => {
                return (
                    <>
                        {
                            (
                                ((row.creator_id === user.data.id || row.owner_id === user.data.id) || user.data.is_superadmin) ||
                                (user.data.permission_pipeline_log_create !== 'NONE')
                            ) && (row.lead_progress_progress_type !== "FAIL" && row.lead_progress_progress_type !== "SUCCESS") &&
                            <button className="btn btn-sm btn-primary" onClick={() => {
                                handleClose();
                                setShowAddLogModal(true);
                                setPipeline(row);
                            }} data-bs-dismiss="modal">
                                <span>{intl.formatMessage({ id: 'FORM.ACTION.ADD_LOG' })}</span>
                            </button>
                        }
                    </>
                )
            },
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' }, { title: settings.merchant_title }),
            width: '250px',
            selector: row => row.merchant_name,
            sortable: true,
            allowOverflow: true,
            reorder: true,
            cell(row) {
                switch (row.hot_warm_cold_status) {
                    case 'hot':
                        return <><span>{row.merchant_name}</span><span className="badge badge-light-danger ms-3">Hot</span></>
                    case 'warm':
                        return <><span>{row.merchant_name}</span><span className="badge badge-light-warning ms-3">Warm</span></>
                    case 'cold':
                        return <><span>{row.merchant_name}</span><span className="badge badge-light-primary ms-3">Cold</span></>
                    default:
                        return row.merchant_name
                }
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
            width: '150px',
            allowOverflow: true,
            selector: row => Number(row.merchant_phone),
            cell(row) {
                return row.merchant_phone;
            },
            sortable: true,
            reorder: true,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.AGE' }),
            width: '100px',
            allowOverflow: true,
            selector: row => Number(row.lead_age),
            cell(row) {
                return getDateSummary(row.lead_age);
            },
            sortable: true,
            reorder: true,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }),
            width: '170px',
            style: {
                justifyContent: 'space-between'
            },
            selector: row => Number(row.nominal),
            cell(row) {
                return (
                    <>
                        <span>Rp</span><span>{row.nominal && customNumberFormat(row.nominal)}</span>
                    </>
                )
            },
            sortable: true,
            reorder: true,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT' }),
            width: '170px',
            selector: row => row.leadProducts.map((data: ListProduct) => data.productName).join(', '),
            sortable: true,
            reorder: true,
            cell: row => (
                <div dangerouslySetInnerHTML={{ __html: row.leadProducts.map((data: ListProduct) => data.productName).join('<br>') }} />
            )
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SUM_PRODUCTS' }),
            width: '150px',
            style: {
                justifyContent: 'right'
            },
            selector: row => customNumberFormat(row.leadProducts.reduce((total, data) => total + Number(data.leadProductQty), 0)),
            cell: row => (
                <span>{customNumberFormat(row.leadProducts.reduce((total, data) => total + Number(data.leadProductQty), 0))}</span>
            ),
            sortable: true,
            reorder: true,
        },
    ];

    useEffect(() => {
        if (showAddLogModal && pipeline) {
            const modalElement = document.getElementById(`tambah-log-modal`);
            const modalElementAbandonedPipeline = document.getElementById(`abandoned-pipeline-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    const modalInstanceAbandonedPipeline = new Modal(modalElementAbandonedPipeline!);
                    setShowAddLogModal(false);
                    modalInstanceAbandonedPipeline.show()
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddLogModal, pipeline, setShowAddLogModal]);

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id={`abandoned-pipeline-modal`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.LABEL.LIST_TITLE' }, { title: settings.pipeline_title })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        {/* {
                            isLoading ?
                                <div className="modal-body" style={{ height: "200px" }}>
                                    <div className="loading-overlay">
                                        <div className="loader"></div>
                                    </div>
                                </div>
                                :
                                <div className="modal-body">
                                    <DataTable
                                        columns={tableColumns}
                                        data={pipeline}
                                        expandableRowsHideExpander
                                        expandOnRowClicked
                                        pagination
                                    />
                                </div>
                        } */}
                        <div className="modal-body">
                            <MasterDataTable
                                tableKey="pipelinesAbandoned"
                                tableColumns={tableColumns}
                                apiURL="abandoned-leads?leadAge=5"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                showAddLogModal && <AddLog lead={pipeline!} showAddLogButton />
            }
        </div>
    )
}

export { AbandonedPipeline }