import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const CITY = `${API_URL}lead_source/`
const SOURCES = `${API_URL}lead_sources/`

export const insertSource = async (name: string, token: string) => {
    return axios.post(CITY, {
        name,
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updateSource = async (id: string, name: string, token: string) => {
    return axios.patch(CITY + id, {
        name,
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const deleteSource = async (id: string, token: string) => {
    return axios.delete(CITY + id, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const getLeadSources = async (token: string) => {
    return axios.get(SOURCES, {
        headers: {
            'X-Auth-token': token

        }
    })
}