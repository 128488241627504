import axios from 'axios';
import { HppLeadProducts, ListProduct, PipelineAttachment } from '../interfaces/Pipeline';

const API_URL = process.env.REACT_APP_API_URL;

const LOG = `${API_URL}lead_log`;
const SEND_REPORT_LOG = `${API_URL}generated-report`;

interface TypeAttachment {
  name: string,
  url: string
}

export interface CustomFieldType {
  [key: string]: any
}

export const insertLog = async (
  leadId: string,
  description: string,
  leadProgressId: string,
  subProgressId: string,
  token: string,
  attachments?: TypeAttachment[],
  leadProducts?: HppLeadProducts[],
  customFields?: CustomFieldType
) => {
  return axios.post(
    LOG,
    {
      description,
      leadId,
      leadProgressId,
      subProgressId,
      attachments,
      leadProducts,
      customFields
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const sendReportLog = async (
  receiversId: string[],
  fileName: string,
  fileUrl: string,
  fileMimeType: string,
  token: string
) => {
  return axios.post(
    SEND_REPORT_LOG,
    {
      receiversId,
      fileName,
      fileUrl,
      fileMimeType,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};
