import React, { FC, useEffect, useState } from 'react'
import { HeaderUserMenu } from '../../../partials'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { Link } from 'react-router-dom'
import { useLanguageStore } from '../../../../stores/LanguageStore'
import ReactCountryFlag from 'react-country-flag'
import { changeLanguage } from '../../../../api/Language'
import useAccessToken from '../../../../hooks/AccessToken'
import moment from "moment";
import { Modal } from 'bootstrap'
import useSWR from 'swr'

const Topbar: FC = () => {
  const APP_ENV = process.env.REACT_APP_ENV
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const {data} = useSWR(`https://tool.looyal.id/face/check-data?name=${user.data.username}`, (url) => fetch(url).then(res => res.json()).then((res) => res.url))
  const { locale } = useLanguageStore()
  moment.locale(locale)
  const [isShowModalLanguage, setIsShowModalLanguage] = useState(false)

  useEffect(() => {
    if (isShowModalLanguage) {
      const modalElement = document.getElementById(`language-modal`);

      if (modalElement) {
        const modalInstance = new Modal(modalElement);
        modalInstance.show();

        // This listener sets showChat to false when the modal is closed
        const handleModalHide = () => {
          setIsShowModalLanguage(false);
        };

        // Attach the event listener
        modalElement.addEventListener('hidden.bs.modal', handleModalHide);

        // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
        return () => {
          modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
        };
      }
    }
  }, [isShowModalLanguage, setIsShowModalLanguage]);

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className="d-flex align-items-center cursor-pointer btn btn-muted btn-active-light btn-active-color-primary h-30px h-md-40px px-3">
        <div className="dropdown me-3" id="dropdown-language">
          <span className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {
              locale === "id" ?
                <ReactCountryFlag countryCode="ID" svg style={{ border: "solid 1px #000000", height: "auto" }} /> :
                <ReactCountryFlag countryCode="US" svg style={{ border: "solid 1px #000000", height: "auto" }} />
            }
          </span>
          <ul className="dropdown-menu" style={{ right: 0, left: "auto" }}>
            <li>
              <span className="dropdown-item" role="button" onClick={() => setIsShowModalLanguage(true)}>
                {
                  locale === "id" ?
                    <span>
                      <ReactCountryFlag countryCode="US" svg className="me-1" />
                      English
                    </span> :
                    <span>
                      <ReactCountryFlag countryCode="ID" svg className="me-1" />
                      Indonesia
                    </span>
                }
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex align-items-center cursor-pointer btn btn-muted btn-active-light btn-active-color-primary h-30px h-md-40px px-3">
        <Link to={"/calendar"}>
          <i className="fas fa-calendar fs-3"></i>
        </Link>
      </div>
      <div className="d-flex align-items-center cursor-pointer btn btn-muted btn-active-light btn-active-color-primary h-30px h-md-40px px-3" data-kt-menu-trigger='click' data-kt-menu-attach='parent' data-kt-menu-placement='bottom-end' data-kt-menu-flip='bottom'>
        {
          APP_ENV === 'development' &&
          <div className="watermark me-5">Dev</div>
        }
        {!data ?
          <span className="menu-icon">
            <i className="fas fa-user fs-3"></i>
          </span>
          :
          <div className='symbol symbol-circle symbol-35px'>
            <img src={data} className="profile-picture" alt="user"></img>
          </div>
        }
        <div className="fw-bolder d-flex align-items-center fs-5 text-dark ms-2 menu-title" id="txtNameHeader">{user.data.name}</div>
      </div>
      <HeaderUserMenu />
    </div>
  )
}

export { Topbar }
