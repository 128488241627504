import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import { Meeting, MeetingAttendance } from '../../interfaces/index';
import { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import 'moment-duration-format';
import { UserModel } from '../../app/modules/auth/models/UserModel';
import { generateLabel } from './exportExcelUtil';


export const exportExcelMeeting = async (
  nameFile: string,
  source: string,
  settings: any,
  nameHeader: {
    [key: string]: string[];
  },
  param: any,
  data?: any[],
  columns?: TableColumn<Meeting>[],
) => {
  let exportData: any[] = [];
  var arrayCustomFieldMeeting = settings.meeting_custom_fields
    ? JSON.parse(settings.meeting_custom_fields)
    : [];

  const workbook = createWorkbook();
  const worksheet = createWorksheet(workbook, source);

  if (source === 'Meeting') {
    Array.from(data!).map((row: any, index) => {
      var temp_excel = [];
      temp_excel.push(
        row.attachments.length > 0 ? row.attachments[0].url : '-',
        row.merchant_name,
        row.owner_name,
        generateLabel(row),
        row.allDay ? moment(row.date_meet).format('DD MMM YYYY') : moment(row.date_meet).format('DD MMM YYYY HH:mm'),
        row.date_meet_end === null ? '-' : row.allDay ? moment(row.date_meet_end).format('DD MMM YYYY') : moment(row.date_meet_end).format('DD MMM YYYY HH:mm'),
        row.attendees.length > 0 ? row.attendees.map((data: MeetingAttendance) => data.name).join(', ') : '-',
        row.creator_name,
        row.note,
        row.location,
        row.reminderMinutes === null ? '-' : String(row.reminderMinutes),
        row.type === "MEETING" ? "-" : row.duration,
        row.type === "MEETING" ? "Meeting" : "Visit",
        row.status
      );

      exportData.push(temp_excel);
    });

    if (settings.meeting_custom_fields) {
      data!.map((row: any, indexData: number) => {
        Object.keys(arrayCustomFieldMeeting).map((field, index) => {
          if (arrayCustomFieldMeeting[field].type === 'date') {
            exportData[indexData].push(
              row.custom_fields && row.custom_fields[field]
                ? moment(row.custom_fields[field]).format('DD MMM YYYY')
                : '-'
            );
          } else if (arrayCustomFieldMeeting[field].type === 'multiple') {
            exportData[indexData].push(
              row.custom_fields && row.custom_fields[field]
                ? row.custom_fields[field].toString().split(',').join(', ')
                : '-'
            );
          } else if (arrayCustomFieldMeeting[field].type === 'serial') {
            exportData[indexData].push(
              row.custom_fields && row.custom_fields[field]
                ? row.custom_fields[field].join('')
                : '-'
            );
          } else {
            exportData[indexData].push(
              row.custom_fields && row.custom_fields[field] ? row.custom_fields[field] : '-'
            );
          }
        });
      });
    }

    var header = [...nameHeader.meeting, ...Object.keys(arrayCustomFieldMeeting).map((field: any) => arrayCustomFieldMeeting[field].name)]
    worksheet.getRow(1).values = header

    addDataRows(worksheet, exportData, source, param, header, columns!);
    setColumnWidths(worksheet, source, arrayCustomFieldMeeting, exportData);
    styleColumns(worksheet, source, param, columns!, 1);
  }
  exportWorkbook(workbook, source, `${nameFile}.xlsx`);
};

const createWorkbook = () => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Looyal';
  workbook.lastModifiedBy = 'Looyal';
  workbook.created = new Date();
  workbook.modified = new Date();
  return workbook;
};

const createWorksheet = (workbook: ExcelJS.Workbook, source: string, sheetName: string = 'Sheet1') => {
  let worksheet;
  worksheet = workbook.addWorksheet(sheetName);
  return worksheet;
};

const addDataRows = (
  worksheet: ExcelJS.Worksheet,
  data: any[],
  source: string,
  param: any,
  header: string[],
  columns: TableColumn<Meeting>[],
  user?: UserModel
) => {
  if (source === 'Meeting') {
    // Set Data Body
    data.forEach((rowData, index) => {
      worksheet.addRow(rowData);
    });

    // Dynamic column
    const columnIndicesToRemove: number[] = [];
    if (user?.data.company_id === "7763fccc-af52-43e1-8907-4678ab2423d1") columnIndicesToRemove.push(7)

    // Cari semua indeks kolom yang perlu dihapus
    header.forEach((item, headerIndex) => {
      columns.forEach((column) => {
        if (column.omit && item === column.name) {
          columnIndicesToRemove.push(headerIndex + 1); // +1 untuk 1-based index
        }
      });
    });

    // Hapus kolom dari worksheet berdasarkan indeks yang ditemukan
    columnIndicesToRemove.sort((a, b) => b - a).forEach((index) => {
      worksheet.spliceColumns(index, 1);
    });
  } else {
    // Set Data Body
    data.forEach((rowData, index) => {
      worksheet.addRow(rowData);
    });
  }
};

const setColumnWidths = (worksheet: ExcelJS.Worksheet, source: string, fields: any[], data: any) => {
  if (source === 'Meeting') {
    const colWidths = [30, 30, 20, 20, 20, 30, 30, 30, 30, 30, 30, 30, 30, 30, ...Object.keys(fields).map((field) => 25)];
    worksheet.columns = colWidths.map((width) => ({ width }));
  }
};

const styleColumns = (
  worksheet: ExcelJS.Worksheet,
  source: string,
  param: any,
  columns: TableColumn<Meeting>[],
  indexRow: number,
  user?: UserModel
) => {
  worksheet.getRow(indexRow).alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  worksheet.getRow(indexRow).eachCell(function (cell) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'F6991D',
      },
    };
  });
  worksheet.getRow(indexRow).font = {
    name: 'Arial Black',
    size: 10,
    bold: true,
  };

  var endRow = worksheet.actualRowCount;
};



const exportWorkbook = async (workbook: ExcelJS.Workbook, source: string, fileName: string) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  });
};
