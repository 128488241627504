/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { Modal } from "bootstrap";
import Swal from 'sweetalert2'
import { checkSession } from '../../../../api/ChatHelper'
import { useChatStore } from '../../../../stores/ChatStore'
import { getSettingAll } from '../../../../api/SettingCRUD'
import useAccessToken from '../../../../hooks/AccessToken'
import { useSettingsStore } from '../../../../stores/SettingsStore'
import { Drawer } from 'rsuite'
import { ListLinkDevice } from '../../../../components/LinkDevice/ListLinkDevice'
import { deleteUserSession, userSession } from '../../../../api/UsersCRUD'
import { useUserSessionStore } from '../../../../stores/UserSessionStore'
import useUser from '../../../../hooks/User'
import moment from 'moment'
import { getCompanies } from '../../../../api/CompanyCRUD'
import { useCompanyStore } from '../../../../stores/CompanyStore'
import useSWR from 'swr'
import axios from 'axios'
import { customNumberFormat } from '../../../../functions/general'
import { useIntl } from 'react-intl'
import { Subordinate } from '../../../../interfaces/Subordinate'
import { useSubordinateStore } from '../../../../stores/SubordinateStore'
import { clearDB } from '../../../../db'
import { ExpiredModal } from '../../../../components/ExpiredModal'
import { ChangePhoneModal } from '../../../../components/ChangePhoneModal';
import { usePipelineStore } from '../../../../stores/PipelineStore';
import { SettingMultiPipelines } from '../../../../interfaces/Settings';
import { useMerchantStore } from '../../../../stores/MerchantStore';

const HeaderUserMenu: FC = () => {
  const user = useUser()
  const token = useAccessToken()
  const intl = useIntl()
  const dispatch = useDispatch()

  const { setMultiPipelines, setSelectMultiPipeline } = usePipelineStore()
  const { userSessions, setUserSessions } = useUserSessionStore()
  const { companies, setCompanies } = useCompanyStore()
  const { setConnected } = useChatStore()
  const { setSettings } = useSettingsStore()
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isShowModalExpired, setIsShowModalExpired] = useState(false);
  const [dataVersion, setDataVersion] = useState("");
  const { setSubordinates } = useSubordinateStore()

  const API_URL = process.env.REACT_APP_API_URL
  const fetcher = (url: string) => {
    return axios
      .get(url, {
        headers: {
          'X-Auth-token': token,
        },
      })
      .then((res) => res.data.data)
  }

  const handleCheckSession = async () => {
    try {
      const response = await checkSession(user.data.phone)
      setConnected(response.data.status)
    } catch (error) {
      console.log(error)
    }
  }

  const getSetting = async () => {
    try {
      const response = await getSettingAll(token)
      if (response.data.success) {
        setSettings({
          merchant_title: response.data.data.merchant_title ? response.data.data.merchant_title : "Klien",
          merchant_custom_fields: response.data.data.merchant_custom_fields ? response.data.data.merchant_custom_fields : "",
          pipeline_title: response.data.data.pipeline_title ? response.data.data.pipeline_title : "Pipeline",
          pipeline_custom_fields: response.data.data.pipeline_custom_fields ? response.data.data.pipeline_custom_fields : "",
          meeting_title: response.data.data.meeting_title ? response.data.data.meeting_title : "Meeting",
          meeting_custom_fields: response.data.data.meeting_custom_fields ? response.data.data.meeting_custom_fields : "",
          users_title: response.data.data.users_title ? response.data.data.users_title : 'Users',
          users_custom_fields: response.data.data.users_custom_fields ? response.data.data.users_custom_fields : '',
          termins_custom_fields: response.data.data.termins_custom_fields ? response.data.data.termins_custom_fields : '',
          caps_lock_inputs: response.data.data.caps_lock_inputs === "true" ? true : false,
          merchant_duplicate: response.data.data.merchant_duplicate === "true" ? true : false,
          merchant_phone_can_be_changed: response.data.data.merchant_phone_can_be_changed === "true" ? true : false,
          hide_top_three_sales: response.data.data.hide_top_three_sales === "true" ? true : false,
          feature_check_number: response.data.data.feature_check_number === "true" ? true : false,
          feature_pipeline: response.data.data.feature_pipeline === "true" ? true : false,
          feature_meeting: response.data.data.feature_meeting === "true" ? true : false,
          feature_chat: response.data.data.feature_chat === "true" ? true : false,
          feature_chat_super_admin: response.data.data.feature_chat_super_admin === "true" ? true : false,
          pipeline_abandoned_lock: response.data.data.pipeline_abandoned_lock === "true" ? true : false,
          pipeline_date_min: response.data.data.pipeline_date_min ? response.data.data.pipeline_date_min : "",
          template_pipeline: response.data.data.template_pipeline ? response.data.data.template_pipeline : "",
          feature_meeting_mode: response.data.data.feature_meeting_mode ? response.data.data.feature_meeting_mode : "0",
          meeting_auto_cancel: response.data.data.meeting_auto_cancel ? Number(response.data.data.meeting_auto_cancel) : -1,
          feature_reimbursement: response.data.data.feature_reimbursement === "false" ? false : true,
          update_log_hpp_progress: response.data.data.update_log_hpp_progress === "true" ? true : false,
          chat_show_pic_only: response.data.data.chat_show_pic_only === "true" ? true : false,
          radius_visit: response.data.data.radius_visit ? Number(response.data.data.radius_visit) : 1,
          update_meeting: response.data.data.update_meeting === "true" ? true : false,
          unlimited_add_lead_log: response.data.data.unlimited_add_lead_log === "true" ? true : false,
          feature_activities: response.data.data.feature_activities ? response.data.data.feature_activities === 'true' ? true : false : true,
          feature_attendance: response.data.data.feature_attendance ? response.data.data.feature_attendance === 'true' ? true : false : true,
          feature_conference: response.data.data.feature_conference ? response.data.data.feature_conference === 'true' ? true : false : true,
          feature_email: response.data.data.feature_email ? response.data.data.feature_email === 'true' ? true : false : true,
          feature_label: response.data.data.feature_label ? response.data.data.feature_label === 'true' ? true : false : true,
          feature_merchant: response.data.data.feature_merchant ? response.data.data.feature_merchant === 'true' ? true : false : true,
          // feature_phone_validation: response.data.data.feature_phone_validation ? response.data.data.feature_phone_validation === 'true' ? true : false : true,
          feature_product: response.data.data.feature_product ? response.data.data.feature_product === 'true' ? true : false : true,
          feature_progress: response.data.data.feature_progress ? response.data.data.feature_progress === 'true' ? true : false : true,
          feature_source: response.data.data.feature_source ? response.data.data.feature_source === 'true' ? true : false : true,
          feature_target: response.data.data.feature_target ? response.data.data.feature_target === 'true' ? true : false : true,
          feature_unit: response.data.data.feature_unit ? response.data.data.feature_unit === 'true' ? true : false : true,
          feature_import_excel_pipeline: response.data.data.feature_import_excel_pipeline ? response.data.data.feature_import_excel_pipeline === 'true' ? true : false : false,
          meeting_visit_disable_capture_camera: response.data.data.meeting_visit_disable_capture_camera ? response.data.data.meeting_visit_disable_capture_camera === 'true' ? true : false : false,
        })
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok',
      })
    }
  }

  const getCompany = async () => {
    try {
      const response = await getCompanies(token)
      if (response.data.success) {
        setCompanies(response.data.data)
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok',
      })
    }
  }

  const getUserSession = async () => {
    try {
      const response = await userSession(token)
      if (response.data.success) {
        setUserSessions(response.data.data)
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok',
      })
    }
  }

  const getUserURL = () => {
    if (user.data.permission_user_target_set === 'ALL') {
      return `${API_URL}users`;
    } else if (user.data.permission_user_target_set === 'TEAM' || user.data.permission_user_target_set === 'TEAM EXCEPT SELF') {
      return `${API_URL}user/one-level-subordinates`;
    } else {
      return `${API_URL}user/subordinates`;
    }
  };

  const logout = () => {
    Swal.fire({
      title: intl.formatMessage({ id: "ALERT.TITLE.LOG_OUT" }),
      text: intl.formatMessage({ id: "ALERT.TEXT.LOG_OUT" }),
      icon: "warning",
      showCancelButton: !0,
      buttonsStyling: !1,
      confirmButtonText: intl.formatMessage({ id: "FORM.ACTION.LOG_OUT" }),
      cancelButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.NO" }),
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-active-light',
      },
      reverseButtons: true,
      heightAuto: false,
      preConfirm: async () => {
        try {
          const response = await deleteUserSession(token, token)
          if (response.data.success) {
            dispatch(auth.actions.logout())
            localStorage.clear()
            clearDB()
          }
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: error.response.data.message,
            confirmButtonText: 'Ok',
          })
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
  }
  const { setMultiMerchants, multiMerchants } = useMerchantStore()
  const { data: company = [], isLoading: companyLoading } = useSWR(`${API_URL}company`, fetcher)
  const { data: version = [], error: versionError, isLoading: versionLoading, isValidating: versionValidating } = useSWR(`${API_URL}version`, fetcher)
  const { data: subordinate = [], error: subordinateError, isLoading: subordinateLoading } = useSWR(getUserURL(), fetcher);
  const { data: leadTemplate = [], error: leadTemplateError, isLoading: leadTemplateLoading } = useSWR(
    `${API_URL}lead-templates`, 
    async (url: string) => {
      try {
        const response = await fetcher(url);
        return Array.isArray(response) ? response : [];
      } catch (error) {
        console.error('Error fetching lead templates:', error);
        return [];
      }
    }
  );
  const { data: resultMultiMerchant = [], error: resultMultiMerchantError, isLoading: resultMultiMerchantisLoading } = useSWR(`${API_URL}merchant-templates`, fetcher)


  useEffect(() => {
    setMultiMerchants(resultMultiMerchant)
  }, [resultMultiMerchant])

  useEffect(() => {
    if (!leadTemplateLoading && leadTemplate) {
      if (Array.isArray(leadTemplate) && leadTemplate.length > 0) {
        const result = leadTemplate.map((lead: SettingMultiPipelines) => ({
          ...lead,
          label: lead.name,
          value: lead.id,
        }));

        let dataLocal = localStorage.getItem("selectMultiPipeline")
        if (dataLocal) {
          setSelectMultiPipeline(JSON.parse(dataLocal))
        } else {
          setSelectMultiPipeline(result[0])
        }
        setMultiPipelines(result);
      } else {
        console.log('leadTemplate is empty or not an array:', leadTemplate);
        setMultiPipelines([]);
        setSelectMultiPipeline({} as SettingMultiPipelines);
      }
    }
  }, [leadTemplate, leadTemplateLoading]);


  useEffect(() => {
    if (!companyLoading) {
      setCompanies(company)
    }
  }, [company])

  useEffect(() => {
    if (user) {
      let dateNow = moment().format('YYYY-MM-DD')
      let dateExp = moment(user.data.company_date_expired).format('YYYY-MM-DD')
      let expired = moment(dateExp).isBefore(dateNow)

      if (expired) {
        setIsShowModalExpired(true)
      }
    }
  }, [user])

  useEffect(() => {
    if (!versionLoading) {
      if (!localStorage.getItem('version-crm')) {
        setDataVersion(version)
        localStorage.setItem('version-crm', version)
      } else if (version !== localStorage.getItem("version-crm")) {
        setDataVersion(version)
        localStorage.setItem('version-crm', version)
        window.location.reload();
      }
    }
  }, [version])

  useEffect(() => {
    if (!subordinateLoading) {
      const data = subordinate.map((subordinate: Subordinate) => ({
        value: subordinate.id,
        label: subordinate.name,
      }));

      if (
        user.data.permission_user_target_set === 'WHOLE TEAM' ||
        user.data.permission_user_target_set === 'TEAM'
      ) {
        data.unshift({ value: user.data.id, label: user.data.name });
      }
      setSubordinates(data);
    }
  }, [subordinate]);

  useEffect(() => {
    getSetting()
  }, [])

  useEffect(() => {
    if (isShowModalExpired) {
      const modalElement = document.getElementById(`expired-modal`);

      if (modalElement) {
        const modalInstance = new Modal(modalElement);
        modalInstance.show();

        // This listener sets showChat to false when the modal is closed
        const handleModalHide = () => {
          setIsShowModalExpired(false);
        };

        // Attach the event listener
        modalElement.addEventListener('hidden.bs.modal', handleModalHide);

        // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
        return () => {
          modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
        };
      }
    }
  }, [isShowModalExpired, setIsShowModalExpired]);

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>{user.data.name}</div>
              <div className='fw-bold text-muted fs-7'>
                Exp. {moment(companies.date_expired).format('DD MMMM YYYY')}
              </div>

              <div className='d-flex align-items-center text-gray-400 mt-3'>
                <span className='svg-icon svg-icon-4 me-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      opacity='0.3'
                      d='M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z'
                      fill='black'
                    ></path>
                    <path
                      d='M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z'
                      fill='black'
                    ></path>
                  </svg>
                </span>
                {customNumberFormat(companies.usersCount ?? 0)} /{' '}
                {customNumberFormat(companies.limit_user ?? 0)}
              </div>
              {
                user.data.isWaSessionActive ?
                  <span className="badge badge-light-success fw-bolder mt-2" style={{ width: 'fit-content' }}>
                    <i className="fas fa-link me-2 fs-8"></i> {intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CONNECT_CHAT' })}
                  </span >
                  :
                  <span className="badge badge-light-danger fw-bolder mt-2" style={{ width: 'fit-content' }}>
                    <i className="fas fa-unlink me-2 fs-8"></i> {intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DISCONNECT_CHAT' })}
                  </span>
              }

              {/* <div className="fw-bold text-muted fs-7">Limit User: <span className="badge badge-success">{companies.usersCount} / {companies.limit_user}</span></div> */}
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          {/* { !user.data.profile_picture &&
            <a data-bs-toggle="modal" data-bs-target="#modal-change-profile" className="menu-link px-5">
              <span className="fas fa-id-badge fs-4 menu-icon"></span>
              <span className="menu-title">Ubah Foto Profil</span>
            </a>
          } */}
          <a data-bs-toggle="modal" data-bs-target="#modal-change-password" className="menu-link px-5">
            <span className="fas fa-key fs-4 menu-icon"></span>
            <span className="menu-title">{intl.formatMessage({ id: "FORM.ACTION.CHANGE_PASSWORD" })}</span>
          </a>
          <a data-bs-toggle="modal" data-bs-target="#modal-change-phone-user" className="menu-link px-5">
            <span className="fas fa-phone fs-4 menu-icon"></span>
            <span className="menu-title">{intl.formatMessage({ id: "FORM.ACTION.UPDATE_PHONE" })}</span>
          </a>
          <a onClick={() => { setOpenDrawer(true); getUserSession() }} className="menu-link px-5">
            <span className="fas fa-link fs-4 menu-icon"></span>
            <span className="menu-title">{intl.formatMessage({ id: "FORM.ACTION.LINKED_DEVICES" })}</span>
          </a>
          <a href="/open-api" target='_blank' className="menu-link px-5">
            <span className="fas fa-code fs-4 menu-icon"></span>
            <span className="menu-title">{intl.formatMessage({ id: "MENU.API_DOCUMENTATION" })}</span>
          </a>
          <a onClick={logout} className="menu-link px-5" id="btnLogout">
            <span className="fas fa-sign-out-alt fs-4 menu-icon"></span>
            <span className="menu-title">{intl.formatMessage({ id: "FORM.ACTION.LOG_OUT" })}</span>
          </a>
        </div>
      </div>

      <Drawer size='xs' open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Drawer.Header>
          <Drawer.Title style={{ fontWeight: "500" }}>{intl.formatMessage({ id: "FORM.ACTION.LINKED_DEVICES" })}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ padding: '30px 30px' }}>
          {userSessions.map((data, index) => {
            return <ListLinkDevice data={data} />
          })}
        </Drawer.Body>
      </Drawer>
    </>
  )
}

export { HeaderUserMenu }
