import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { SelectOption } from "../../interfaces/SelectOption";
import { useSettingsStore } from "../../stores/SettingsStore";
import useAccessToken from "../../hooks/AccessToken";
import { CustomField, TypeSerial } from "../../interfaces/Settings";
import { saveSetting } from "../../api/SettingCRUD";
import { useIntl } from 'react-intl';
import clsx from "clsx";
import * as Yup from 'yup';
import moment from "moment";
import { KTSVG } from "../../_metronic/helpers";
import { useSortable } from "@dnd-kit/sortable";
import zIndex from "@mui/material/styles/zIndex";
import { Role } from "../../interfaces/Role";
import { select } from "redux-saga/effects";

interface Props {
    id: string
    type: string
    customField: CustomField;
    index: number;
    optionType: SelectOption[]
    optionsResetSerial: SelectOption[]
    dataRole?: SelectOption[]
    dataMerchant?: SelectOption[]
    setCustomField: (data: CustomField) => void;
}

const RowCustomField: FC<Props> = ({ id, type, customField, index, optionType, optionsResetSerial, dataMerchant, dataRole, setCustomField }) => {
    const intl = useIntl();
    const { settings } = useSettingsStore()

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id,
        transition: {
            duration: 300, // Longer duration for a smoother transition
            easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)', // Natural easing
        },
    });

    var style = {};
    if (isDragging) {
        style = {
            transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
            transition,
            boxShadow: isDragging ? 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' : 'none',
            backgroundColor: "#ffffff",
            borderRadius: "15px",
            zIndex: 1,
            position: "relative"
        }
    } else {
        style = {
            transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
            transition,
            backgroundColor: "#ffffff",
            borderRadius: "15px",
        }
    }

    const changeColumn = (id: string, value: string, index: number, type: string) => {
        const arrayField = Object.keys(customField);

        if (type === "name") {
            arrayField.map((keys) => {
                if (keys === id) {
                    customField[keys].name = value
                }
            })
            setCustomField({ ...customField });
        } else if (type === "required") {
            arrayField.map((keys) => {
                if (keys === id) {
                    customField[keys].required = !customField[id].required
                    customField[id].score = ""
                }
            })
            setCustomField({ ...customField });
        } else if (type === "type") {
            arrayField.map((keys) => {
                if (keys === id) {
                    customField[keys].type = value

                    if (value === "option" || value === "multiple") customField[keys].value = [`${intl.formatMessage({ id: "FORM.LABEL.OPTION" })} 1`]
                    else if (value === "user" || value === "pipeline" || value === "merchant") customField[keys].value = ['all']
                    else if (value === "serial") {
                        customField[keys].value = [
                            {
                                value: "1",
                                type: 'serial',
                                reset: 'no',
                                offset: 1
                            }
                        ]

                        if (keys === id) {
                            customField[keys].required = true
                        }
                    } else customField[keys].value = []
                }
            })
            setCustomField({ ...customField });
        } else if (type === "inputOption") {
            customField[id].value[index] = value
            setCustomField({ ...customField });
        } else if (type === "score") {
            customField[id].score = value
            setCustomField({ ...customField });
        }
    }

    const deleteOption = (id: string, index: number) => {
        customField[id].value.splice(index, 1)
        setCustomField({ ...customField });
    }

    const addOption = (id: string) => {
        const addOption = { ...customField, [id]: { ...customField[id], value: [...customField[id].value, `${intl.formatMessage({ id: "FORM.LABEL.OPTION" })} ` + (customField[id].value.length + 1)] } };
        setCustomField(addOption as CustomField);
    }

    const handleChangeSerial = (id: string, value: string, index: number, type: string) => {
        if (type === "type") {
            customField[id].value[index] = {
                value: value === "text" ? "" : value,
                type: value === "DD" || value === "MM" || value === "YYYY" ? "date" : value,
            }
        } else if (type === "reset") {
            (customField[id].value[index] as TypeSerial) = {
                ...customField[id].value[index] as TypeSerial,
                reset: value
            }
        } else if (type === "offset") {
            (customField[id].value[index] as TypeSerial) = {
                ...customField[id].value[index] as TypeSerial,
                offset: Number(value)
            }
        } else if (type === "serial") {
            (customField[id].value[index] as TypeSerial) = {
                ...customField[id].value[index] as TypeSerial,
                value: value
            }
        }

        setCustomField({ ...customField });
    }

    const handleSelectMerchant = (selectMerchant: SelectOption[], action: any) => {
        var endIndex = selectMerchant.length - 1
        if(selectMerchant.length > 0) {
            if(selectMerchant[endIndex].value === 'all') setCustomField({ ...customField, [id]: { ...customField[id], value: ['all'] } });
            else {
                const filteredMerchants = selectMerchant.filter(item => item.value !== 'all');
                setCustomField({ ...customField, [id]: { ...customField[id], value: filteredMerchants.map(item => item.value) } });
            }
        } else {
            setCustomField({ ...customField, [id]: { ...customField[id], value: ['all'] } });
        }
    }

    const deleteSerial = (id: string, index: number) => {
        setCustomField({ ...customField, [id]: { ...customField[id], value: (customField[id].value as string[]).filter((value, i) => i !== index) } } as CustomField)
    }

    const addSerial = (id: string) => {
        // setCustomField(prevCustomField => {
        //     const newValues = [...prevCustomField[id].value];
        //     newValues.splice(newValues.length - 1, 0, { value: "", type: 'text' });

        //     return {
        //         ...prevCustomField,
        //         [id]: {
        //             ...prevCustomField[id],
        //             value: newValues
        //         }
        //     } as CustomField;
        // });
    }

    const formatResult = (formatString: TypeSerial[]) => {
        return formatString.map(item => {
            switch (item.type) {
                case "date":
                    return moment().format(item.value);
                case "serial":
                    var number = item.offset === 0 ? 1 : item.offset
                    return number!.toString().padStart(Number(item.value), '0'); // untuk nilai serial tetap
                default:
                    return item.value; // untuk text tetap
            }
        }).join("");
    };

    const deleteColumn = (id: string, index: number) => {
        Swal.fire({
            title: `${intl.formatMessage({ id: "FORM.ACTION.DELETE" })} ${customField[id].name}`,
            text: `${intl.formatMessage({ id: "FORM.DELETE_CONFIRMATION" }, { title: customField[id].name })}`,
            icon: "warning",
            showCancelButton: !0,
            buttonsStyling: !1,
            confirmButtonText: `${intl.formatMessage({ id: "FORM.CONFIRMATION.YES" })}, ${intl.formatMessage({ id: "FORM.ACTION.DELETE" })}!`,
            cancelButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.NO" }),
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-active-light",
            },
            reverseButtons: true,
            heightAuto: false,
        }).then((result) => {
            if (result.isConfirmed) {
                delete customField[id];
                setCustomField({ ...customField });
            }
        });
    }

    return (
        <div className="px-10 py-5" ref={setNodeRef} style={style} >
            <div className="row">
                <label className="col-xl-3 col-lg-3 col-form-label fw-bolder fs-5 mb-0 required">{intl.formatMessage({ id: "FORM.LABEL.COLUMN" })} {index + 1}</label>
                <div className={`${type === "pipeline" ? "col-lg-5 col-xl-5" : "col-lg-6 col-xl-6"}`}>
                    <input
                        className={'form-control mb-3'}
                        type='text'
                        name='value'
                        value={customField[id].name}
                        onChange={(e) => {
                            changeColumn(id, e.target.value, index, "name")
                        }}
                    />
                    {
                        customField[id].name === "" &&
                        <div className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME_COLUMN" }) })}</div>
                    }
                </div>
                <div className="col-xl-3 col-lg-3">
                    <Select
                        id={nanoid()}
                        options={optionType}
                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.TYPE" })}`}
                        defaultValue={optionType[0]}
                        value={optionType.find(option => option.value === customField[id].type)}
                        className="w-100 mb-3"
                        onChange={(selectedOptions: any) => { changeColumn(id, selectedOptions.value, index, "type") }}
                        isDisabled={!customField[id].new ? true : false}
                    />
                </div>
                {
                    type === "pipeline" &&
                    <div className="col-xl-1 col-lg-1">
                        <input
                            disabled={customField[id].required ? true : false}
                            type="text"
                            value={customField[id].score}
                            onChange={(e) => changeColumn(id, e.target.value, index, "score")}
                            className="form-control mb-3"
                            placeholder={intl.formatMessage({ id: "FORM.LABEL.SCORE" })}
                        />
                    </div>
                }
            </div>

            {
                (customField[id].type === "option" || customField[id].type === "multiple") &&
                customField[id].value.map((value, indexValue) => (
                    <div className="row" key={indexValue}>
                        <label className="col-xl-3"></label>
                        <div className="col-lg-9 col-xl-9">
                            <ul>
                                <li className="mb-5 text-gray-700">
                                    <div className="w-100 d-flex">
                                        <div className="fv-row w-100 px-3 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                            <input className="form-control form-control-solid form-control-sm" type="text" value={value as string} onChange={(e) => { changeColumn(id, e.target.value, indexValue, "inputOption") }} />
                                            {
                                                value === "" &&
                                                <div className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.OPTION" }) })}</div>
                                            }
                                        </div>
                                        {
                                            customField[id].value.length > 1 &&
                                            <button className="btn btn-icon btn-sm btn-transparent btn-active-color-primary btn-remove-row" type="button" onClick={() => deleteOption(id, indexValue)}>
                                                <i className="fas fa-times-circle fs-1"></i>
                                            </button>
                                        }
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                ))
            }

            {
                customField[id].type === "merchant" &&
                <div className="row">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-9">
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: "1px solid #E4E6EF",
                                    backgroundColor: "none",
                                }),
                                option: (baseStyles, { isSelected, isFocused }) => ({
                                    ...baseStyles,
                                    backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                    cursor: 'pointer'
                                }),
                                indicatorSeparator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    display: 'none',
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'none',
                                },
                            })}
                            isMulti
                            onChange={(selectedOptions: any, actionMeta: any) => handleSelectMerchant(selectedOptions, actionMeta)}
                            value={dataMerchant?.filter((option) => (customField[id].value as string[]).includes(option.value))}
                            className='react-select-styled mb-5'
                            classNamePrefix='react-select'
                            options={dataMerchant}
                            placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} settings.merchant_title`}
                        />
                    </div>
                </div>
            }

            {
                (customField[id].type === "option" || customField[id].type === "multiple") &&
                <div className="row mb-5">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-9">
                        <button type="button" className="btn btn-secondary btn-sm" style={{ fontSize: "0.8rem" }} onClick={() => addOption(id)}>
                            <i className="fas fa-plus"></i>
                            {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.OPTION" })}
                        </button>
                    </div>
                </div>
            }

            {
                customField[id].type === 'serial' &&
                <>
                    <div className="row">
                        <label className="col-xl-3"></label>
                        <div className="col-lg-9 col-xl-9" style={{ display: "flex", gap: "5px", flexFlow: "wrap" }}>
                            {
                                (customField[id].value as TypeSerial[]).map((value, indexValue) => (
                                    value.type !== "serial" &&
                                    <div className="input-group input-group-sm mb-1" style={{ width: "auto", height: "min-content" }}>
                                        <span className="input-group-text cursor-pointer">
                                            <div className="dropdown">
                                                <span data-bs-toggle="dropdown" aria-expanded="false">
                                                    <KTSVG path="/media/icons/duotune/arrows/arr072.svg" className="svg-icon svg-icon-3" />
                                                </span>
                                                <ul className="dropdown-menu dropdown-menu-end">
                                                    <li className="dropdown-item cursor-pointer" onClick={() => handleChangeSerial(id, "text", indexValue, "type")}>
                                                        {intl.formatMessage({ id: "FORM.ACTION.TEXT" })}
                                                    </li>
                                                    <li className="dropdown-item cursor-pointer" onClick={() => handleChangeSerial(id, "DD", indexValue, "type")}>
                                                        {intl.formatMessage({ id: "FORM.ACTION.DATE" })}
                                                    </li>
                                                    <li className="dropdown-item cursor-pointer" onClick={() => handleChangeSerial(id, "MM", indexValue, "type")}>
                                                        {intl.formatMessage({ id: "FORM.ACTION.MONTH" })}
                                                    </li>
                                                    <li className="dropdown-item cursor-pointer" onClick={() => handleChangeSerial(id, "YYYY", indexValue, "type")}>
                                                        {intl.formatMessage({ id: "FORM.ACTION.YEAR" })}
                                                    </li>
                                                </ul>
                                            </div>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={(value.value === "DD" || value.value === "MM" || value.value === "YYYY") ? true : false}
                                            placeholder={intl.formatMessage({ id: "FORM.PLACEHOLDER.ENTER" }, { title: intl.formatMessage({ id: "FORM.ACTION.TEXT" }) })}
                                            value={value.value === "DD" ? intl.formatMessage({ id: "FORM.ACTION.DATE" }) : value.value === "MM" ? intl.formatMessage({ id: "FORM.ACTION.MONTH" }) : value.value === "YYYY" ? intl.formatMessage({ id: "FORM.ACTION.YEAR" }) : value.value}
                                            onChange={(e) => { handleChangeSerial(id, (e.target.value).replace(/\s+/g, ''), indexValue, "serial") }}
                                        />
                                        <span className="input-group-text cursor-pointer" onClick={() => deleteSerial(id, indexValue)}>
                                            <KTSVG path="/media/icons/duotune/arrows/arr015.svg" className="svg-icon svg-icon-3" />
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="row">
                        <label className="col-xl-3"></label>
                        {
                            (customField[id].value as TypeSerial[]).map((value, indexValue) => (
                                value.type === "serial" &&
                                <>
                                    <div className="col-lg-2 col-xl-2 d-flex align-items-center mb-1">
                                        <span className="ms-3 fw-bolder" style={{ width: "25%" }}>
                                            Digit :
                                        </span>
                                        <input type="text" className="form-control form-control-solid form-control-sm ms-3" value={value.value} onChange={(e) => { handleChangeSerial(id, (e.target.value).replace(/[^0-9]/g, ''), indexValue, "serial") }} style={{ width: "100%" }} />
                                    </div>
                                    <div className="col-lg-2 col-xl-2 d-flex align-items-center mb-1">
                                        <span className="fw-bolder" style={{ width: "35%" }}>
                                            Reset :
                                        </span>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    border: "none", // Menghilangkan simbol garis
                                                    backgroundColor: "#F5F8FA",
                                                }),
                                                option: (baseStyles, { isSelected, isFocused }) => ({
                                                    ...baseStyles,
                                                    backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                                    cursor: 'pointer'
                                                }),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: 'none',
                                                },
                                            })}
                                            onChange={(data: any) => { handleChangeSerial(id, data.value, indexValue, "reset") }}
                                            value={optionsResetSerial.find(option => option.value === value.reset)}
                                            className='react-select-styled z-index-3 w-100'
                                            classNamePrefix='react-select'
                                            options={optionsResetSerial}
                                            placeholder='Select an option'
                                            isSearchable={false}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-xl-3 d-flex align-items-center mb-1">
                                        <span className="ms-3 fw-bolder" style={{ width: "35%" }}>
                                            {intl.formatMessage({ id: 'FORM.LABEL.INITIAL_SERIAL' })} :
                                        </span>
                                        <input type="text" value={value.offset === 0 ? "" : value.offset} onChange={(e) => handleChangeSerial(id, (e.target.value).replace(/[^0-9]/g, ''), indexValue, "offset")} className="form-control form-control-solid form-control-sm ms-3" style={{ width: "100%" }} />
                                    </div>
                                </>
                            ))
                        }
                    </div>
                </>
            }

            {
                customField[id].type === 'serial' &&
                <div className="row mb-5">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-9 mt-2">
                        <button type="button" className="btn btn-secondary btn-sm" style={{ fontSize: "0.8rem" }} onClick={() => addSerial(id)}>
                            <i className="fas fa-plus"></i>
                            {intl.formatMessage({ id: "FORM.ACTION.ADD" })} Prefix Serial
                        </button>
                        <span className="ms-3 fw-bolder text-gray-600">
                            {intl.formatMessage({ id: "FORM.LABEL.EXAMPLE_RESULT" })} : {formatResult(customField[id].value as TypeSerial[])}
                        </span>
                    </div>
                </div>
            }

            {/* {
                customField[id].type === 'user' &&
                <div className="row mb-5">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-9 mt-2">
                        <Select
                            isMulti={true}
                            key={nanoid()}
                            options={dataRole}
                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.ROLE" })}`}
                            value={dataRole?.filter((option) => (customField[id].value as string[]).includes(option.value))}
                            // onChange={

                            // }
                            className="w-100"
                        />
                        {
                            customField[id].value.length === 0 &&
                            <div className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.ROLE" }) })}</div>
                        }
                    </div>
                </div>

            }

            {
                customField[id].type === 'pipeline' &&
                <div className="row mb-5">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-9 mt-2">
                        <Select
                            isMulti={true}
                            key={nanoid()}
                            options={dataRole}
                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.ROLE" })}`}
                            value={dataRole?.filter((option) => (customField[id].value as string[]).includes(option.value))}
                            // onChange={

                            // }
                            className="w-100"
                        />
                        {
                            customField[id].value.length === 0 &&
                            <div className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.ROLE" }) })}</div>
                        }
                    </div>
                </div>

            } */}

            <div className="row">
                <label className="col-xl-3"></label>
                <div className="col-lg-9 col-xl-9">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" value={`${customField[id].required}`} id={`require${index}`} checked={customField[id].required} onChange={(e) => { changeColumn(id, e.target.value, index, "required") }} disabled={customField[id].type === 'serial' ? true : false} />
                            <label className="form-check-label" htmlFor={`require${index}`}>
                                {intl.formatMessage({ id: "FORM.VALIDATION.REQUIRED" })}
                            </label>
                        </div>
                        <div className="d-flex align-items-center justify-content-end gap-5">
                            <i className="fas fa-arrows-alt-v cursor-move" style={{ fontSize: "1.5rem" }} {...attributes} {...listeners}></i>
                            <i className="fas fa-trash menu-icon cursor-pointer" onClick={() => deleteColumn(id, index)} style={{ fontSize: "1.5rem" }}></i>
                        </div>
                    </div>
                </div>
            </div>

            {
                Object.keys(customField).length - 1 !== index &&
                <div className="separator separator-dashed mt-6"></div>
            }
        </div>
    )
}

export { RowCustomField }