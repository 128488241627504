import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const REIMBURSE = `${API_URL}reimbursement/`;

export const insertReimbursement = async (
  name: string,
  description: string,
  currencyId: number,
  dateSpend: string,
  reimbursementCategoryId: string,
  value: number,
  attachmentUrls: Array<string>,
  referenceType: string,
  referenceId: string | null,
  token: string
) => {
  return axios.post(
    REIMBURSE,
    {
      name,
      description,
      currencyId,
      dateSpend,
      reimbursementCategoryId,
      value,
      attachmentUrls,
      referenceType,
      referenceId,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateReimbursement = async (
  id: string,
  name: string,
  description: string,
  currencyId: number,
  dateSpend: string,
  reimbursementCategoryId: string,
  value: number,
  attachmentUrls: Array<string>,
  token: string
) => {
  return axios.put(
    REIMBURSE + id,
    {
      name,
      description,
      currencyId,
      dateSpend,
      reimbursementCategoryId,
      value,
      attachmentUrls,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const deleteReimbursement = async (id: string, token: string) => {
  return axios.delete(REIMBURSE + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const approveReimbursement = async (id: string, token: string) => {
  return axios.patch(
    REIMBURSE + id + '/approve',
    {},
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const rejectReimbursement = async (id: string, rejectedReason: string, token: string) => {
  return axios.patch(
    REIMBURSE + id + '/reject',
    {
      rejectedReason,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const payOffReimbursement = async (
  id: string,
  paidOffAttachmentUrls: Array<string>,
  token: string
) => {
  return axios.patch(
    REIMBURSE + id + '/pay-off',
    {
      paidOffAttachmentUrls,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};
