import { useChatStore } from "../../../stores/ChatStore";

const ChatSetting = () => {
    const { setMenuActive } = useChatStore()

    const handleLabelClick = () => {
        setMenuActive("settings-label")
    }

    const handleQuickReplyClick = () => {
        setMenuActive("settings-quick-reply")
    }

    return (
        <div className='card card-flush w-100 p-10' style={{ maxHeight: "calc(100vh - 1rem)", height: "calc(100vh - 1rem)" }}>
            <h1 className="text-dark fw-bolder fs-1 my-1 mb-10">Pengaturan</h1>
            <div className="d-flex align-items-center p-3 rounded-2 bg-hover-light" onClick={handleLabelClick}>
                <div className="symbol symbol-30px me-5">
                    <span className="symbol-label">
                        <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: "#a1a5b7" }}>
                            <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                        </svg>
                    </span>
                </div>
                <div className="me-5">
                    <span className="text-gray-800 fw-bolder fs-6">Label</span>
                    <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Kelola chat dan pelanggan</span>
                </div>
            </div>
            <div className="separator separator-dashed my-4"></div>
            <div className="d-flex align-items-center p-3 rounded-2 bg-hover-light" onClick={handleQuickReplyClick}>
                <div className="symbol symbol-30px me-5">
                    <span className="symbol-label">
                        <span className="svg-icon svg-icon-3 svg-icon-gray-600">
                            <i className="fas fa-solid fa-bolt"></i>
                        </span>
                    </span>
                </div>
                <div className="me-5">
                    <span className="text-gray-800 fw-bolder fs-6">Balas Cepat</span>
                    <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Gunakan kembali pesan yang sering dikirim</span>
                </div>
            </div>
        </div>
    )
}

export { ChatSetting }