import React, { RefObject } from 'react'
import { FormikProps } from 'formik'
import { CustomField } from '../../../interfaces/Settings'
import { useIntl } from 'react-intl'
import { AddLogFormValues } from './AddLog'
import { DatePicker } from "rsuite"
import moment from 'moment'
import Select from 'react-select'
import InputAttachmentGeneral from '../../Input/InputAttachmentGeneral'
import { ALWAYS_REQUIRED_FIELDS } from '../../../constant/General'

interface Props {
  formik: FormikProps<AddLogFormValues>
  customFields: CustomField
  filteredCustomFields: CustomField
  fileInputRefs: RefObject<{ [key: string]: HTMLInputElement | null }>
}

const RenderCustomFieldSinertek: React.FC<Props> = ({
  formik,
  customFields,
  filteredCustomFields,
  fileInputRefs,
}) => {
  const intl = useIntl()

  const handleClearInput = (key: string, field: any) => {
    if (field.type === 'file') {
      formik.setFieldValue(`customFields.${key}`, null)
      if (fileInputRefs.current?.[key]) {
        fileInputRefs.current[key]!.value = ''
      }
    } else {
      formik.setFieldValue(`customFields.${key}`, '')
    }
  }

  const isFile = (value: unknown): value is File => {
    return value instanceof File
  }

  const renderField = (key: string, field: any) => {
    switch (field.type) {
      case 'text':
        return (
          <input
            type="text"
            className="form-control"
            placeholder={field.name}
            onChange={(e) => {
              formik.setFieldValue(`customFields.${key}`, e.target.value)
            }}
            onBlur={() => formik.setFieldTouched(`customFields.${key}`, true)}
            value={typeof formik.values.customFields[key] === 'string' ? formik.values.customFields[key] : ''}
          />
        )

      case 'date':
        return (
          <DatePicker
            value={formik.values.customFields[key] ? new Date(formik.values.customFields[key]) : null}
            onChange={(date) => {
              formik.setFieldValue(
                `customFields.${key}`,
                date ? moment(date).format('YYYY-MM-DD') : ''
              )
            }}
            onBlur={() => formik.setFieldTouched(`customFields.${key}`, true)}
            format="dd MMM yyyy"
            className="form-control p-1"
            style={{ width: "100%" }}
            editable={false}
            container={document.querySelector('#tambah-log-modal') as HTMLElement}
          />
        )

      case 'option':
        const options = field.value ? field.value.map((option: string) => ({
          value: option,
          label: option
        })) : []

        return (
          <Select
            isClearable
            options={options}
            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${field.name}`}
            value={options.find((option: any) => option.value === formik.values.customFields[key])}
            onChange={(option) => {
              formik.setFieldValue(`customFields.${key}`, option?.value || '')
            }}
            onBlur={() => formik.setFieldTouched(`customFields.${key}`, true)}
            className="w-100"
          />
        )

      case 'multiple':
        return (
          <div className="form-check form-check-custom form-check-solid" style={{ display: 'contents' }}>
            {field.value.map((option: string, index: number) => (
              <div style={{ display: 'flex', padding: "3px", overflowWrap: "anywhere" }} key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={option}
                  checked={Array.isArray(formik.values.customFields[key]) && formik.values.customFields[key].includes(option)}
                  onChange={(e) => {
                    const currentValues = Array.isArray(formik.values.customFields[key])
                      ? formik.values.customFields[key]
                      : [];

                    if (e.target.checked) {
                      formik.setFieldValue(`customFields.${key}`, [...currentValues, option]);
                    } else {
                      formik.setFieldValue(
                        `customFields.${key}`,
                        currentValues.filter((value: string) => value !== option)
                      );
                    }
                  }}
                  onBlur={() => formik.setFieldTouched(`customFields.${key}`, true)}
                />
                <label className="form-check-label me-2">{option}</label>
              </div>
            ))}
          </div>
        )
    }
  }

  const renderClearButton = (key: string, field: any) => {
    if (field.type === 'file' && isFile(formik.values.customFields[key])) {
      return (
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={() => handleClearInput(key, field)}
        >
          <i className="fas fa-trash fs-5"></i>
        </button>
      )
    }

    if (field.type !== 'file' && formik.values.customFields[key]) {
      return (
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={() => handleClearInput(key, field)}
        >
          <i className="fas fa-times fs-5"></i>
        </button>
      )
    }

    return null
  }

  const acceptFile = (field: any) => {
    if (field === 'file') {
      return 'application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation'
    } else {
      return 'image/png, image/jpeg, image/jpg'
    }
  } 

  return (
    <>
      {Object.entries(filteredCustomFields).map(([key, field]) => {
        const isAlwaysRequired = ALWAYS_REQUIRED_FIELDS.includes(key);
        const isFieldRequired = isAlwaysRequired || field.required;

        const hasError = formik.errors.customFields &&
          typeof formik.errors.customFields === 'object' &&
          key in formik.errors.customFields

        const errorMessage = hasError
          ? (formik.errors.customFields as Record<string, string>)[key]
          : ''

        if (field.type === 'file') {
          return (
            <div key={key} className="col-md-12 mb-3">
              <span className={`form-label fs-6 fw-bolder text-dark ${isFieldRequired && 'required'}`}>{field.name}</span><br />
              <InputAttachmentGeneral keyString={key} formik={formik} isMulti={false} type="update" accept={acceptFile('file')} typeCustomField='default' />
            </div>
          )
        } else if (field.type === 'image') {
          return (
            <div key={key} className="col-md-12 mb-3">
              <span className={`form-label fs-6 fw-bolder text-dark ${isFieldRequired && 'required'}`}>{field.name}</span><br />
              <InputAttachmentGeneral keyString={key} formik={formik} isMulti={false} type="update" accept={acceptFile('image')} typeCustomField='default' />
            </div>
          )
        } else {
          return (
            <div key={key} className="col-md-12 mb-3">
              <div className="form-group">
                <label className={`form-label fs-6 fw-bolder text-dark ${isFieldRequired ? 'required' : ''}`}>{field.name}</label>
                <div className="input-group">
                  {renderField(key, field)}
                  {(field.type !== 'option' && field.type !== 'date' && field.type !== 'multiple') && renderClearButton(key, field)}
                </div>
                {hasError && (
                  <div className="error-message text-danger mt-1">
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
          )
        }
      })}
    </>
  )
}

export default RenderCustomFieldSinertek