import { FC } from "preact/compat";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { useEffect, useState } from "react";
import { Label } from "../../../interfaces/Label";
import { TableColumn } from "react-data-table-component";
import { AddLabel } from "../../../components/Labels/AddLabel";
import useUser from "../../../hooks/User";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DeleteLabel } from "../../../components/Labels/DeleteLabel";
import { Modal } from "bootstrap";
import { UpdateLabel } from "../../../components/Labels/UpdateLabel";
import { useIntl } from "react-intl";

const MasterLabels: FC = () => {

    const user = useUser()

    const [label, setLabel] = useState<Label>();
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const [openMenuId, setOpenMenuId] = useState<string | null>(null);

    const intl = useIntl()

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const tableColumns: TableColumn<Label>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '70px',
            cell: (row) => {
                return (
                    <>
                        {
                            (
                                ((row.creatorId === user.data.id) || user.data.is_superadmin)
                            ) &&
                            <>
                                <IconButton
                                    aria-label={`more-${row.id}`}
                                    id={`menu-anchor-${row.id}`}
                                    onClick={() => handleClick(row.id)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    id={`menu-${row.id}`}
                                    anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                                    open={openMenuId === row.id}
                                    onClose={handleClose}
                                    disableScrollLock={true}
                                >
                                    {
                                        (
                                            ((row.creatorId === user.data.id) || user.data.is_superadmin)
                                        ) &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowUpdateModal(true);
                                            setLabel(row);
                                        }}>
                                            <i className="fas fa-edit fs-4 me-4"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                                        </MenuItem>
                                    }
                                    {
                                        (
                                            ((row.creatorId === user.data.id) || user.data.is_superadmin)
                                        ) &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowDeleteModal(true);
                                            setLabel(row);
                                        }}> 
                                            <i className="fas fa-trash fs-3 me-5"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                                        </MenuItem>
                                    }
                                </Menu>
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            selector: row => row.name,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TYPE' }),
            wrap: true,
            allowOverflow: true,
            style: {
                display: "flex",
                flexWrap: "wrap"
            },
            cell: (row) => {
                const arrType = []
                if (row.isUsableByMeeting){
                    arrType.push(intl.formatMessage({id:'FORM.OPTION.TYPE.MEETING'}))
                }
                if (row.isUsableByMerchant){
                    arrType.push(intl.formatMessage({id:'FORM.OPTION.TYPE.MERCHANT'}))
                }
                if(row.isUsableByPipeline){
                    arrType.push(intl.formatMessage({id:'FORM.OPTION.TYPE.PIPELINE'}))
                }
                if(row.isUsableByProduct){
                    arrType.push(intl.formatMessage({id:'FORM.OPTION.TYPE.PRODUCT'}))
                }
                return arrType.map((type)=>{
                    return  (
                        <div className="badge badge-primary me-1 my-1">{type}</div>                
                    )
            })
        }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COLOR' }),
            selector: row => row.color,
            cell: row => {
                return (
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '6px',
                            backgroundColor: row.color,
                        }}
                    />
                );
            },
        },
    ]
    

    const actions = [
        <AddLabel />
    ]

    useEffect(() => {
        if (showUpdateModal && label) {
            const modalElement = document.getElementById(`update-label-modal-${label.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, label, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal && label) {
            const modalElement = document.getElementById(`delete-label-modal-${label.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, label, setShowDeleteModal]);
    return (
        <>
            <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'MENU.LABELS' })}</li>
            </ol>
            <MasterDataTable
                tableKey="labels"
                tableColumns={tableColumns}
                actions={actions}
                apiURL="labels"
            />
            {
                showUpdateModal && <UpdateLabel label={label!} />
            }
            {
                showDeleteModal && <DeleteLabel label={label!} />
            }
        </>
    )
}

export { MasterLabels }