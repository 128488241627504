import { FC, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { approveReimbursement } from "../../api/ReimbursementCRUD";
import { Reimburse } from "../../interfaces/Reimbursement";
import { useIntl } from "react-intl";

interface Props {
    reimburse: Reimburse
}

const ApproveReimbursement: FC<Props> = ({ reimburse }) => {

    const closeModalRef = useRef<HTMLButtonElement>(null);

    const token = useAccessToken()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const intl = useIntl()

    const handleApprove = async () => {
        setModalBtnLoading(true)
        try {
            const response = await approveReimbursement(reimburse.id, token)
            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                    text: `${intl.formatMessage({ id: "FORM.VALIDATION.APPROVE_SUCCESS" })}.`,
                    heightAuto: false,
                })
                closeModalRef.current?.click();

                setTablesData('reimbursements', (tablesData['reimbursements'].map(item => {
                    if (item.id === reimburse.id) {
                        return {
                            ...item,
                            approvalStatus: response.data.data.approvalStatus,
                            dateApproved: response.data.data.dateApproved,
                            reference: response.data.data.reference
                        }
                    } else {
                        return item
                    }
                })));
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`approve-reimbursement-modal-${reimburse.id}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.APPROVE' })} {reimburse.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>
                                {intl.formatMessage({ id: 'FORM.LABEL.DESC_APPROVE_1' }, { title: reimburse.name })} ? <br />
                                {intl.formatMessage({ id: 'FORM.LABEL.DESC_APPROVE_2' })}
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
                            </button>
                            <button type="button" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleApprove}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.APPROVE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ApproveReimbursement }