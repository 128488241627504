import { FC, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import useAccessToken from "../../../../hooks/AccessToken";
import { useChatStore } from "../../../../stores/ChatStore";
import { KTSVG } from "../../../../_metronic/helpers";
import { QuickReply } from "../../../../interfaces/Chat/QuickReply";
import { deleteQuickReply } from "../../../../api/quickReplyCRUD";

interface DeleteQuickReplyProps {
    quickReply: QuickReply
}

const DeleteQuickReply = ({ quickReply }: DeleteQuickReplyProps) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const token = useAccessToken()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { quickReplies, setQuickReplies } = useChatStore()

    const intl = useIntl()

    const handleDeletePipeline = async () => {
        setModalBtnLoading(true)
        try {
            const response = await deleteQuickReply(quickReply.id, token)
            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                    text: `${intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" })}.`,
                    heightAuto: false,
                })
                closeModalRef.current?.click();
                setQuickReplies(quickReplies.filter((data) => data.id !== quickReply.id))
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`delete-chat-quick-reply-modal`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.DELETE_QUICK_REPLY" }, { title: quickReply.shortcut })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>{intl.formatMessage({ id: "FORM.VALIDATION.DESC_DELETE_QUICK_REPLY" }, { title: quickReply.shortcut })}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleDeletePipeline}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DeleteQuickReply }