import { useFormik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { useSettingsStore } from "../../stores/SettingsStore";
import { CustomField, SettingMultiPipelines, TypeSerial } from "../../interfaces/Settings";
import { SelectOption } from "../../interfaces/SelectOption";
import { saveSetting } from "../../api/SettingCRUD";
import { useIntl } from "react-intl";
import clsx from "clsx";
import * as Yup from 'yup';
import moment from "moment";
import { KTSVG } from "../../_metronic/helpers";
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { RowCustomField } from "./RowCustomField";
import { CustomFieldMultiPipeline } from "./CustomFiledMultiPipeline";
import axios from "axios";
import useSWR from "swr";
import { AddTemplateSettingPipeline } from "./AddTemplateSettingPipeline";
import { DeleteTemplateSettingPipeline } from "./DeleteTemplateSettingPipeline";
import { usePipelineStore } from "../../stores/PipelineStore";
import { Role } from "../../interfaces/Role";
import { getAccessProgressesPipeline } from "../../api/ProgressesCRUD";
import { getAccessRolePipeline } from "../../api/RolesCRUD";
import { getAccessProductPipeline } from "../../api/ProductsCRUD";
import { resolve } from "dns";

interface Props {
    dataMerchant: SelectOption[]
    optionType: SelectOption[]
    optionsResetSerial: SelectOption[]
    dataProgres: SelectOption[]
    dataProduct: SelectOption[]
    dataRole: SelectOption[]
}

const CustomFieldPipeline: FC<Props> = ({ dataMerchant, optionType, optionsResetSerial, dataProgres, dataProduct, dataRole }) => {
    const { settings, setSettings } = useSettingsStore()
    const { multiPipelines } = usePipelineStore()
    const token = useAccessToken()
    const [selectTemplate, setSelectTemplate] = useState<SettingMultiPipelines>({} as SettingMultiPipelines)
    const linkRef = useRef(null);
    const [resultMultiPipelines, setResultMultiPipelines] = useState<SettingMultiPipelines[]>([])

    // Pipeline
    const [loadingPipelinePreferences, setLoadingPipelinePreferences] = useState(false)
    const intl = useIntl()

    const formikPreferences = useFormik({
        initialValues: {
            key: '',
            value: settings.pipeline_title,
            checkAddLeadLog: settings.unlimited_add_lead_log
        },
        validationSchema: Yup.object().shape({
            value: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (values.value === "") return false

            setLoadingPipelinePreferences(true)

            try {
                const response = await saveSetting("pipeline_title", values.value, token)
                const response2 = await saveSetting("unlimited_add_lead_log", String(values.checkAddLeadLog), token)
                if (response.data.success && response2.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    setSettings({
                        ...settings,
                        pipeline_title: values.value,
                        unlimited_add_lead_log: values.checkAddLeadLog
                    })
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.respons.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setLoadingPipelinePreferences(false)
            }
        }
    })

    const handleCheckAddLeadLog = (checked: boolean) => {
        formikPreferences.setFieldValue('checkAddLeadLog', checked)
    }

    useEffect(() => {
        const updateMultiPipelines = async () => {
            try {
                const updatedPipelines = await Promise.all(
                    multiPipelines.map(async (item: SettingMultiPipelines) => {
                        try {
                            // Lakukan 2 panggilan API secara paralel untuk setiap item
                            const [resProgress, resProduct] = await Promise.all([
                                getAccessProgressesPipeline(item.id, token),
                                getAccessProductPipeline(item.id, token),
                            ]);

                            if (resProgress.data.success && resProduct.data.success) {
                                return {
                                    ...item,
                                    progress: resProgress.data.data.map((progressItem: any) => ({
                                        ...progressItem,
                                        label: progressItem.name,
                                        value: progressItem.id,
                                    })),
                                    product: resProduct.data.data.map((productItem: any) => ({
                                        ...productItem,
                                        label: productItem.name,
                                        value: productItem.id,
                                    })),
                                };
                            }
                        } catch (error) {
                            console.error(`Error updating pipeline ${item.id}:`, error);
                        }

                        // Jika terjadi error atau data tidak valid, kembalikan item asli
                        return item;
                    })
                );

                // Tetapkan hasil langsung tanpa array sementara
                setResultMultiPipelines(updatedPipelines);
            } catch (error) {
                console.error("Error updating multiPipelines:", error);
            }
        };

        updateMultiPipelines();
    }, [multiPipelines]);

    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">{intl.formatMessage({ id: "FORM.LABEL.PREFERENCES" }, { title: settings.pipeline_title })}</h3>
                    </div>
                </div>
                <div>
                    <form onSubmit={formikPreferences.handleSubmit} noValidate>
                        <div className="card-body border-top px-9 py-9">
                            <div className="row">
                                <span className="col-md-7 col-lg-9 col-xl-9 d-flex flex-column align-items-start">
                                    <span className="fw-bolder fs-5 mb-0">{`${intl.formatMessage({ id: "FORM.LABEL.NAME" })} `}</span>
                                    <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_SETTING_NAME" })}</span>
                                </span>
                                <div className="col-md-5 col-lg-3 col-xl-3">
                                    <input
                                        {...formikPreferences.getFieldProps('value')}
                                        className={clsx(
                                            'form-control',
                                            { 'is-invalid': formikPreferences.touched.value && formikPreferences.errors.value },
                                        )}
                                        type='text'
                                        name='value'
                                    />
                                    {formikPreferences.touched.value && formikPreferences.errors.value && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formikPreferences.errors.value}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="separator separator-dashed my-6"></div>
                            <div className="row">
                                <label className="col-md-7 col-lg-9 col-xl-9 form-check form-switch form-check-custom form-check-solid align-items-start cursor-pointer">
                                    <input className="form-check-input me-3" type="checkbox" {...formikPreferences.getFieldProps('checkAddLeadLog')} checked={formikPreferences.values.checkAddLeadLog!} onChange={(e) => handleCheckAddLeadLog(e.target.checked)} />
                                    <span className="form-check-label d-flex flex-column align-items-start">
                                        <span className="fw-bolder fs-5 mb-0">{intl.formatMessage({ id: "FORM.LABEL.SETTING_ADD_LEAD_LOG" })}</span>
                                        <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_SETTING_ADD_LEAD_LOG" }, { title: settings.pipeline_title })}</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="submit" className={`btn btn-primary ${loadingPipelinePreferences ? "disabled" : ""}`} data-kt-indicator={loadingPipelinePreferences ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {
                resultMultiPipelines.length > 0 &&
                <>
                    <div className="d-flex align-items-center gap-3">
                        <div className="card-toolbar custom-tabs mb-3">
                            <ul className="nav">
                                {
                                    resultMultiPipelines.map((data: SettingMultiPipelines, index: number) => (
                                        <li className="nav-item" style={{ position: "relative" }} key={data.id}>
                                            <a
                                                ref={index === 0 ? linkRef : null}
                                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light ps-4 fw-bolder ${index === 0 ? 'active pe-4' : 'pe-8'}`}
                                                data-bs-toggle="tab"
                                                href={`#tab_${data.id}`}
                                            >
                                                <span>{data.name}</span>
                                            </a>

                                            {
                                                index !== 0 &&
                                                <span
                                                    className="text-hover-primary cursor-pointer"
                                                    onClick={() => setSelectTemplate(data)}
                                                    style={{ position: "absolute", right: "5px", top: "7px" }}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#delete-template-setting-pipeline-modal"
                                                >
                                                    <KTSVG path="media/icons/duotune/general/gen040.svg" className="svg-icon-5 m-0" />
                                                </span>
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <span className='menu-icon cursor-pointer text-hover-primary mb-3' data-bs-toggle="modal" data-bs-target="#add-template-setting-pipeline-modal">
                            <KTSVG path="media/icons/duotune/general/gen041.svg" className='svg-icon-1' />
                        </span>
                    </div>


                    <div className="tab-content" id="myTabContent">
                        {
                            resultMultiPipelines.map((data: SettingMultiPipelines, index: number) => (
                                <div className={`tab-pane fade ${index === 0 && 'show active'}`} id={`tab_${data.id}`} role="tabpanel">
                                    <CustomFieldMultiPipeline key={data.id} data={data} optionType={optionType} dataMerchant={dataMerchant} optionsResetSerial={optionsResetSerial} dataProgres={dataProgres} dataProduct={dataProduct} dataRole={dataRole} />
                                </div>
                            ))
                        }
                    </div>
                </>
            }

            <AddTemplateSettingPipeline />
            <DeleteTemplateSettingPipeline selectTemplate={selectTemplate} linkRef={linkRef} />
        </>
    )
}

export { CustomFieldPipeline }