import { FC, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import DataTable, { TableColumn } from "react-data-table-component";
import { Modal } from "bootstrap";
import { AddRole } from "../../../components/Roles/AddRole";
import { Role } from "../../../interfaces/Role";
import { UpdateRole } from "../../../components/Roles/UpdateRole";
import { DeleteRole } from "../../../components/Roles/DeleteRole";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ImportExcelValidationPhone } from "../../../components/ValidationPhone/ImportExcelValidationPhone";
import { ResponseErrorValidationPhoneProp, ResponseSuccessValidationPhoneProp } from "../../../interfaces/ValidationPhone";
import { DataTableExport } from "../../../components/DataTable/DataTableExport";
import Swal from "sweetalert2";

const MasterValidationPhone: FC = () => {
    const [dataSuccess, setDataSuccess] = useState<ResponseSuccessValidationPhoneProp[]>(localStorage.getItem("data_validation_success") ? JSON.parse(localStorage.getItem("data_validation_success")!) : [])
    const [dataError, setDataError] = useState<ResponseErrorValidationPhoneProp[]>(localStorage.getItem("data_validation_error") ? JSON.parse(localStorage.getItem("data_validation_error")!) : [])

    const tableColumnsSuccess: TableColumn<ResponseSuccessValidationPhoneProp>[] = [
        {
            name: 'Nama',
            width: '200px',
            selector: row => row.name?.toLowerCase() ?? "-",
            sortable: true,
        },
        {
            name: 'Telepon',
            width: "150px",
            selector: row => row.phoneNumber,
            sortable: true,
        },
        {
            name: 'Nama Merchant',
            width: "150px",
            selector: row => row.merchant,
            sortable: true,
        },
        {
            name: 'Provider',
            width: "150px",
            selector: row => row.provider,
            sortable: true,
        },
        {
            name: 'Tag',
            selector: row => typeof row.tags === 'string' ? row.tags : row.tags === null ? "-" : row.tags.join(", "),
            sortable: true,
        },
    ];

    const tableColumnsError: TableColumn<ResponseErrorValidationPhoneProp>[] = [
        {
            name: 'Telepon',
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: 'Nama Merchant',
            selector: row => row.merchant,
            sortable: true,
        },
        {
            name: 'Pesan Error',
            selector: row => row.message,
            sortable: true,
        },
    ];

    const deleteData = () => {
        Swal.fire({
            title: "Hapus",
            text: "Apakah Anda yakin ingin hapus?",
            icon: "warning",
            showCancelButton: !0,
            buttonsStyling: !1,
            confirmButtonText: "Ya, hapus",
            cancelButtonText: "Tidak",
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-active-light",
            },
            reverseButtons: true,
            heightAuto: false,
            preConfirm: async () => {
                localStorage.removeItem("data_validation_success")
                localStorage.removeItem("data_validation_error")
                setDataSuccess([])
                setDataError([])
            }
        })
    }

    return (
        <>
            <div className="d-flex justify-content-between mb-5">
                <ol className="breadcrumb text-muted fs-6 fw-bold">
                    <li className="breadcrumb-item text-dark">Validasi Telepon</li>
                </ol>

                <div className="d-flex gap-3">
                    <button type="button" className="btn btn-light-danger mt-3" onClick={deleteData}>
                        <i className="fas fa-trash fs-2 me-2"></i>Hapus Data
                    </button>
                    <ImportExcelValidationPhone
                        dataSuccess={dataSuccess}
                        dataError={dataError}
                        setDataSuccess={setDataSuccess}
                        setDataError={setDataError}
                    />
                </div>
            </div>

            {
                dataSuccess.length > 0 &&
                <div className="card rounded">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title">
                            Data Sukses
                        </div>
                        <div className="me-5 me-md-3">
                            <DataTableExport source={"success-validation-phone"} columns={tableColumnsSuccess} data={dataSuccess} />
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <DataTable
                            columns={tableColumnsSuccess}
                            data={dataSuccess}
                            pagination
                        />
                    </div>
                </div>
            }

            {
                dataError.length > 0 &&
                <div className="card rounded mt-5">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title">
                            Data Error
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <DataTable
                            columns={tableColumnsError}
                            data={dataError}
                            pagination
                        />
                    </div>
                </div>
            }

        </>
    )
}

export { MasterValidationPhone }