import axios from "axios"


const API_URL = process.env.REACT_APP_API_URL
const GET_DASHBAORD_WIDGET = `${API_URL}dashboard/widgets`
const SORT_WIDGET = `${API_URL}company-dashboard-widgets`

export const getDashboardtWidget = async (date: string, user_id: string, scope: string, token: string) => {
    return axios.get(GET_DASHBAORD_WIDGET+"?date="+date+"&user_id="+user_id+"&scope="+scope,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const sortWidget = async (listWidget: any, token: string) => {
    return axios.put(SORT_WIDGET,
        {
            ...listWidget
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}
