import { TableColumn } from "react-data-table-component";
import { useIntl } from "react-intl"
import { Link, useParams } from "react-router-dom";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable"
import { ProgressSettingsModal } from "../../../components/Roles/ProgressSettings/ProgressSettingsModal";
import { ProgressSettings } from "../../../interfaces/ProgressSettings";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { DeleteProgressSettingsModal } from "../../../components/Roles/ProgressSettings/DeleteProgressSettingsModal";
import { useSettingsStore } from "../../../stores/SettingsStore";

const MasterProgressSettings = () => {
    const intl = useIntl()

    const { id } = useParams();

    const { settings } = useSettingsStore()
    const [openMenu, setOpenMenu] = useState<string | null>();
    const [progressSettings, setProgressSettings] = useState<ProgressSettings | null>();

    const decodeId = (id: string) => {
        const roleName = id.split('~')[0]
        const roleId = atob(id.split('~')[1])

        return { roleName, roleId }
    }

    const { roleName, roleId } = decodeId(id!)

    const handleClick = (progressSettings: ProgressSettings) => {
        setOpenMenu(progressSettings.roleId);
        setProgressSettings(progressSettings)
    };
    const handleClose = () => {
        setOpenMenu(null);
    };

    const convertLead = (text: string) => {
        return text.replaceAll('_', ' ').toLowerCase().replace('lead', settings.pipeline_title)
    }

    const tableColumns: TableColumn<ProgressSettings>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '70px',
            id: 'fixedLeft',
            cell: (row) => {
                return (
                    <>
                        {
                            row.roleId &&
                            <>
                                <IconButton
                                    aria-label={`more-${row.roleId}`}
                                    id={`menu-anchor-${row.roleId}`}
                                    onClick={() => handleClick(row)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    id={`menu-${row.roleId}`}
                                    anchorEl={document.getElementById(`menu-anchor-${row.roleId}`)}
                                    open={openMenu === row.roleId}
                                    disableScrollLock={true}
                                    onClose={handleClose}
                                >
                                    <MenuItem
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-progress-settings-modal"
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    >
                                        <i className="fas fa-edit fs-4 me-4"></i>
                                        <span>
                                            {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                        </span>
                                    </MenuItem>
                                </Menu>
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: 'Access Type',
            style: { textTransform: 'capitalize' },
            cell(row, rowIndex, column, id) {
                return row.accessType && convertLead(row.accessType)
            },
        },
        {
            name: 'Progresses',
            selector: row => row.leadProgresses ? row.leadProgresses.length > 0 ? row.leadProgresses.map(leadProgress => leadProgress.name).join(', ') : '-' : 'Semua Progress',
        }
    ]

    useEffect(() => {
        const addModal = document.getElementById(`add-progress-settings-modal`);
        const deleteModal = document.getElementById(`delete-progress-settings-modal`);

        if (addModal) {
            // This listener sets showChat to false when the modal is closed
            const handleModalHide = () => {
                handleClose();
                setProgressSettings(null);
            };

            // Attach the event listener
            addModal.addEventListener('hidden.bs.modal', handleModalHide);

            // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
            return () => {
                addModal.removeEventListener('hidden.bs.modal', handleModalHide);
            };
        }

        if (deleteModal) {
            // This listener sets showChat to false when the modal is closed
            const handleModalHide = () => {
                handleClose();
                setProgressSettings(null);
            };

            // Attach the event listener
            deleteModal.addEventListener('hidden.bs.modal', handleModalHide);

            // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
            return () => {
                deleteModal.removeEventListener('hidden.bs.modal', handleModalHide);
            };
        }
    }, []);

    return (
        <>
            <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                <li className="breadcrumb-item text-dark">
                    <Link to='/roles'>
                        {intl.formatMessage({ id: 'MENU.ROLES' })}
                    </Link>
                </li>
                <li>{intl.formatMessage({ id: "FORM.ACTION.PROGRESS_SETTINGS" })} {roleName}</li>
            </ol>
            <MasterDataTable
                tableKey="leadProgressAccesses"
                tableColumns={tableColumns}
                apiURL={`role-lead-progress-accesses?roleId=${roleId}`}
            />

            {
                roleId &&
                <ProgressSettingsModal roleId={roleId} progressSettings={progressSettings!} />
            }

            {
                progressSettings &&
                <DeleteProgressSettingsModal progressSettings={progressSettings} />
            }
        </>
    )
}

export { MasterProgressSettings }