import {  IVarDocumentationProps } from "../../../interfaces/OpenAPI";

export const getDocumentationCityProps = ({intl}:IVarDocumentationProps )=>{    
    const getCitiesExample = {
        data: [
            { id: 1, name: "Kota Surabaya" },
            { id: 2, name: "Kota Malang" },
        ],
        success: true,
        message: "Berhasil mendapatkan daftar kota"
    }

    const python_get_city = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/cities"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    response = requests.request("GET", reqUrl, headers=headersList)

    print(response.text)
    `

    const javascript_get_city = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/cities";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    axios.get(reqUrl, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_get_city = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/cities";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_get_city = `
    curl  -X GET
    'https://crm-api.wooblazz.com/cities'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}'
    `


    // ===================== GET USER =====================
    const getUsersExample = {
        data: [
            {
                attendanceTemplateId: "f9d9c937-0cf2-4b19-1f05-41478f8b3bha6",
                attendanceTemplateName: "SHIFT MALAM123",
                city_id: 131,
                city_name: "Kabupaten Aceh Barat Daya",
                customFields: {
                    "454fd8c5-87cd-4617-8cba-ced92ad48faa": "Surabaya",
                    "454fd8c5-87cd-4617-8cba-ced92ad48fab": null
                },
                date_activated: null,
                date_joined: "2024-10-29 00:00:00",
                date_otp_expired: null,
                id: "dc6b7b0b-b011-9oj1-a7d8-cafahhc8e344",
                is_superadmin: false,
                leader_id: "a4b29d62-9d3e-4769-85f2-9931c31b1714",
                leader_name: "Super Admin",
                name: "Demo",
                nik: null,
                otp: null,
                password: "$2b$11$cOSQqGq1Gi8Wt.dbZz6hdO1UPrdJEwiTO6hzSnb6plXH3sdkBSQA1",
                phone: "621111111",
                phones: [{ number: "621111111", label: "Primary", isPrimary: true }],
                profile_picture: null,
                role_has_subordinate: true,
                role_id: "9e817a40-f8c9-4093-b27a-a00a11223345",
                role_name: "Manager",
                user_target_meeting_count_value: null,
                user_target_pipeline_success_nominal_value: null,
                user_target_product_sold_qty_value: null,
                username: "tes-username",
            }
        ],
        success: true,
        message: "Berhasil mendapatkan daftar user"
    }

    const python_get_user = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/users"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    response = requests.request("GET", reqUrl, headers=headersList)

    print(response.text)
    `

    const javascript_get_user = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/users";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    axios.get(reqUrl, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_get_user = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/users";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_get_user = `
    curl  -X GET
    'https://crm-api.wooblazz.com/users'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}'
    `

    return { getCitiesExample, python_get_city, javascript_get_city,php_get_city, bash_get_city, getUsersExample, python_get_user, javascript_get_user,php_get_user,bash_get_user}
}