import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { pipeline } from "stream";
import { ListProduct, Pipeline } from "../../../interfaces/Pipeline";
import { CustomField } from "../../../interfaces/Settings";
import { useSettingsStore } from "../../../stores/SettingsStore";
import useUser from "../../../hooks/User";
import { useCustomeStore } from "../../../stores/CustomeStore";
import { usePipelineStore } from "../../../stores/PipelineStore";
import { customNumberFormat, getDateSummary, isColorDarkFromHex } from "../../../functions/general";
import { ExpandableElement } from "../../General/ExpandableElement";

interface DetailPipelineProps {
    data: Pipeline
    setShowDetailPipelineModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ChatDetailPipeline: FC<DetailPipelineProps> = ({ data, setShowDetailPipelineModal }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const intl = useIntl()
    const [customLead, setCustomLead] = useState({} as any)
    const [customFields, setCustomFields] = useState<CustomField>({})
    const { settings } = useSettingsStore()
    const user = useUser()
    const { companyId, keyCustomfield } = useCustomeStore()
    const { pipelines, selectMultiPipeline } = usePipelineStore()

    useEffect(() => {
        const customData: any = {
            date_created: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.TYPE.DATE_CREATED' }),
                value: moment(data.date_created).format("DD MMM YYYY HH:mm")
            },
            merchant: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' }, { title: settings.merchant_title }),
                value: returnMerchantName(data)
            },
            pic: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
                value: data.owner_name
            },
            total_log: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TOTAL_LOGS' }),
                value: data.lead_logs_count && customNumberFormat(data.lead_logs_count)
            },
            progress: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PROGRESS' }),
                value: returnProgress(data)
            },
            phone: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
                value: data.merchant_phone
            },
            age: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.AGE' }),
                value: data.lead_age
            },
            source: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SOURCE' }),
                value: returnSource(data)
            },
            product: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT' }),
                value: returnProduct(data)
            },
            label: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LABEL' }),
                value: returnLabel(data)
            },
            nominal: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }),
                value: `${data.nominal && customNumberFormat(data.nominal)}`
            },
            product_qty: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SUM_PRODUCTS' }),
                value: returnQtyProduct(data)
            },
            creator: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
                value: data.creator_name
            },
            start_date: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
                value: moment(data.date_start).format("DD MMM YYYY HH:mm")
            },
            end_date: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
                value: data.date_end ? moment(data.date_end).format("DD MMM YYYY HH:mm") : "-"
            },
            note: {
                name: user.data.company_id === companyId.elgisa ? "Opportunity" : intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
                value: data.note ? <ExpandableElement text={(data.note).replaceAll('\n', '<br/>')} maxHeight={100} /> : "-"
            },
        }

        var newData = {
            ...customData,
            ...Object.keys(customFields).reduce((acc, key) => {
                let value;
                if (customFields[key].type === 'invoice_reminder' && data.custom_fields[key]) {
                    if (JSON.parse(data.custom_fields[key] as string).invoice_url && JSON.parse(data.custom_fields[key] as string).date_due) {
                        value = returnInvoiceReminder(JSON.parse(data.custom_fields[key] as string).invoice_url, JSON.parse(data.custom_fields[key] as string).date_due)
                    } else if (JSON.parse(data.custom_fields[key] as string).invoice_url && !JSON.parse(data.custom_fields[key] as string).date_due) {
                        value = <a href={JSON.parse(data.custom_fields[key] as string).invoice_url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_INVOICE" })}</a>
                    } else if (!JSON.parse(data.custom_fields[key] as string).invoice_url && JSON.parse(data.custom_fields[key] as string).date_due) {
                        value = moment(JSON.parse(data.custom_fields[key] as string).date_due).format("DD MMM YYYY")
                    } else value = "-"
                }
                else if (customFields[key].type === 'date') {
                    value = data.custom_fields[key] ? moment(data.custom_fields[key]).format("DD MMM YYYY") : "-";
                } else if (customFields[key].type === 'image' || customFields[key].type === 'images') {
                    value = data.custom_fields[key] ? returnImage(data.custom_fields[key]) : "-";
                } else if (customFields[key].type === 'file' || customFields[key].type === 'files') {
                    value = data.custom_fields[key] ? returnFile(data.custom_fields[key]) : "-";
                } else if (customFields[key].type === 'multiple') {
                    value = data.custom_fields[key] ? returnMultiple(data.custom_fields[key] as string) : "-";
                } else if (customFields[key].type === 'link') {
                    value = data.custom_fields[key] ? returnLink(data.custom_fields[key] as string) : "-";
                } else if (customFields[key].type === 'serial') {
                    value = typeof data.custom_fields[key] === 'string' ? data.custom_fields[key] : (data.custom_fields[key] as string[])?.length > 0 ? (data.custom_fields[key] as string[]).join('') : '-';
                } else if (customFields[key].type === 'merchant' || customFields[key].type === 'user') {
                    value = (data as any)[`custom_fields.${key}`] ? (data as any)[`custom_fields.${key}`] : "-";
                } else if (customFields[key].type === 'pipeline') {
                    var result: any = pipelines.find((item: any) => item.id === data.custom_fields[key])
                    if (result) {
                        var idTemplate = result.leadTemplateId
                        var idPipeline = data.custom_fields[key] as string
                        value = returnCustomFieldPipeline(idTemplate, idPipeline, (data as any)[`custom_fields.${key}`]);
                    } else value = "-"
                } else {
                    value = data.custom_fields[key] ? data.custom_fields[key] : "-";
                }

                return {
                    ...acc,
                    [key]: {
                        name: customFields[key].name,
                        value
                    }
                };
            }, {})
        };

        if (user.data.company_id === companyId.enterpriseSales) {
            newData = {
                ...newData,
                [keyCustomfield.serial.enterpriseSales]: {
                    name: 'Invoice',
                    value: data.custom_fields[keyCustomfield.serial.enterpriseSales] ? (data.custom_fields[keyCustomfield.serial.enterpriseSales] as string[]).join('') : "-"
                }
            }
        }
        if (user.data.company_id === companyId.enterpriseService) {
            newData = {
                ...newData,
                [keyCustomfield.serial.enterpriseService]: {
                    name: 'Invoice',
                    value: data.custom_fields[keyCustomfield.serial.enterpriseService] ? (data.custom_fields[keyCustomfield.serial.enterpriseService] as string[]).join('') : "-"
                }
            }
        }

        setCustomLead(newData);
    }, [customFields])

    useEffect(() => {
        if (Object.keys(selectMultiPipeline).length !== 0) {
            setCustomFields(selectMultiPipeline.customFields)
        }
    }, [selectMultiPipeline])

    const returnMerchantName = (data: Pipeline) => {
        switch (data.hot_warm_cold_status) {
            case 'hot':
                return <><span>{data.merchant_name}</span><span className="badge badge-light-danger ms-3">Hot</span></>
            case 'warm':
                return <><span>{data.merchant_name}</span><span className="badge badge-light-warning ms-3">Warm</span></>
            case 'cold':
                return <><span>{data.merchant_name}</span><span className="badge badge-light-primary ms-3">Cold</span></>
            default:
                return data.merchant_name
        }
    }

    const returnProgress = (data: Pipeline) => {
        if (data.lead_subprogress_name) {
            if (data.lead_progress_color) return <span className="badge" style={{ backgroundColor: data.lead_progress_color, color: isColorDarkFromHex(data.lead_progress_color) ? "white" : "black" }}>{data.lead_progress_name + " (" + data.lead_subprogress_name + ")"}</span>
            else return <span className="badge badge-light-info" >{data.lead_progress_name + " (" + data.lead_subprogress_name + ")"}</span>
        } else {
            if (data.lead_progress_color) return <span className="badge" style={{ backgroundColor: data.lead_progress_color, color: isColorDarkFromHex(data.lead_progress_color) ? "white" : "black" }}>{data.lead_progress_name}</span>
            else return <span className="badge badge-light-info" >{data.lead_progress_name}</span>
        }
    }

    const returnProduct = (data: Pipeline) => {
        return <div dangerouslySetInnerHTML={{ __html: data.leadProducts?.map((dataProduct: ListProduct) => dataProduct.productName).join(', ') }} />
    }

    const returnLabel = (data: Pipeline) => {
        const uniqueLabels = [...new Set(data.leadProducts?.flatMap(dataLabel => dataLabel.productLabels))];
        return uniqueLabels.length > 0 ? uniqueLabels.join(', ') : "-";
    }

    const returnQtyProduct = (data: Pipeline) => {
        if (data.leadProducts) return customNumberFormat(data.leadProducts?.reduce((total, dataProduct) => total + Number(dataProduct.leadProductQty), 0))
        else return 0
    }

    const returnSource = (data: Pipeline) => {
        switch (data.lead_source_name) {
            case 'INBOUND':
                return <span className="badge badge-light-primary">Inbound</span>
            case 'OUTBOUND':
                return <span className="badge badge-light-success">Outbound</span>
            default:
                return <span className="badge badge-light-info">{data.lead_source_name}</span>
        }
    }

    const returnImage = (url: string | string[]) => {
        if (Array.isArray(url)) {
            return url.map((data: string, index: number) => (
                <span key={index}>
                    <a href={data} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })}</a>
            );
        }
    };

    const returnFile = (url: string | string[]) => {
        if (Array.isArray(url)) {
            return url.map((data: string, index: number) => (
                <span key={index}>
                    <a href={data} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })}</a>
            );
        }
    };

    const returnMultiple = (data: string) => {
        const values = data && data.toString()
        return values ? values.split(',').join(', ') : '-'
    }

    const returnLink = (data: string) => {
        return (
            <a href={data} rel="noreferrer" className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600" target="_blank">Lihat<i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i></a>
        )
    }

    const returnInvoiceReminder = (url: string, date: string) => {
        return (
            <div>
                {moment(date).format("DD MMM YYYY")}, <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_INVOICE" })}</a>
            </div>
        )
    }

    const returnCustomFieldPipeline = (idTemplate: string, idPipeline: string, name: string) => {
        return (
            <a href={`/pipelines/${idTemplate}/log/${idPipeline}`} target="_blank" rel="noopener noreferrer">{name}</a>
        )
    }

    return (
        <div className="card" style={{ minHeight: "calc(100vh - 1rem)" }}>
            <h3 className="py-3 px-5 d-flex align-items-center gap-3">
                <i className="fas fa-times fs-3 text-hover-primary cursor-pointer" onClick={() => setShowDetailPipelineModal(false)}></i>
                Detail {data.merchant_name}
            </h3>
            <div className="card-body row">
                {
                    Object.keys(customLead).map((key, index) => {
                        return (
                            <div className="row pb-2 col-12">
                                <div className="col-3 text-end" style={{ fontWeight: "500", color: "#767373" }}>{customLead[key].name}</div>
                                <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{customLead[key].value}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export { ChatDetailPipeline }