import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useFormik } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../setup";
import Swal from "sweetalert2";
import { UserModel } from "../../app/modules/auth/models/UserModel";
import { Modal } from "bootstrap";
import { OTPModal } from "../OTPModal";
import * as auth from '../../app/modules/auth/redux/AuthRedux'
import { sendOTP } from "../../api/OTP";
import { firstResetPassword, resetPassword } from "../../app/modules/auth/redux/AuthCRUD";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

interface ChangePasswordProps {
    type: string
    firstToken: string
    username?: string
}

const ChangePassword: FC<ChangePasswordProps> = ({ type, firstToken, username }) => {
    const accessToken = useSelector<RootState, string>(({ auth }) => auth.accessToken as string, shallowEqual);
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const modalRef = useRef<HTMLDivElement | null>(null);
    const intl = useIntl()

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);
    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const closeChangePasswordBtnRef = useRef<HTMLButtonElement>(null);
    const [phoneCensored, setUserPhoneCensored] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [oldPassword, setOldPassword] = useState("")
    const dispatch = useDispatch()

    const handleCloseClick = () => {
        formik.resetForm();
    };

    const formSchema = {
        oldPassword: type === 'dashboard'
            ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.OLD_PASSWORD" }) })).min(8, intl.formatMessage({ id: "FORM.VALIDATION.LEAST_8_CHARACTERS" }, { title: intl.formatMessage({ id: "FORM.LABEL.OLD_PASSWORD" }) }))
            : Yup.string().notRequired().nullable()
    }

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NEW_PASSWORD" }) })).min(8, intl.formatMessage({ id: "FORM.VALIDATION.LEAST_8_CHARACTERS" }, { title: intl.formatMessage({ id: "FORM.LABEL.NEW_PASSWORD" }) })),
        confirmPassword: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.LEAST_8_CHARACTERS" }, { title: intl.formatMessage({ id: "FORM.LABEL.CONFIRM_NEW_PASSWORD" }) })).oneOf([Yup.ref('password'), null], intl.formatMessage({ id: "FORM.VALIDATION.PASSWORD_NOT_MATCH" })),
        ...formSchema
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (values.oldPassword === values.password) {
                setStatus(intl.formatMessage({ id: "FORM.VALIDATION.PASSWORD_NOT_SAME" }));
                return false
            }

            setModalBtnLoading(true);
            setSubmitting(true);

            try {
                if (type == "login" && username) {
                    const response = await firstResetPassword(username, values.password, firstToken);
                    if (response.data.success) {
                        dispatch(auth.actions.login(firstToken))
                        closeChangePasswordBtnRef.current?.click();
                    }
                } else if (type == "dashboard") {
                    const response = await sendOTP(user.data.username, values.oldPassword);
                    if (response.data.success) {
                        setUserPhoneCensored(response.data.data.userPhoneCensored)
                        setNewPassword(values.password)
                        setOldPassword(values.oldPassword)
                        const modalElement = document.getElementById(`otp-modal-dashboard`);

                        if (modalElement) {
                            const modalInstance = new Modal(modalElement);
                            modalInstance.show();
                        }

                        closeChangePasswordBtnRef.current?.click();
                    }
                }
            } catch (error: any) {
                setStatus(error.response.data.message);
            } finally {
                setSubmitting(false);
                setModalBtnLoading(false);
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm();
            setShowOldPassword(false)
            setShowNewPassword(false)
            setShowNewConfirmPassword(false)
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeChangePasswordBtnRef, formik);

    return (
        <>
            <div className="modal fade" tabIndex={-1} id="modal-change-password" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.LABEL.CHANGE_PASSWORD" })}</h5>
                            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={handleCloseModal} >
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body fs-6">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className={`form-label fs-6 fw-bolder text-dark required ${type == "dashboard" ? "" : "d-none"}`}>{intl.formatMessage({ id: "FORM.LABEL.OLD_PASSWORD" })}</label>
                                <div className={`input-group mb-3 ${type == "dashboard" ? "" : "d-none"}`}>
                                    <input
                                        placeholder="********"
                                        {...formik.getFieldProps('oldPassword')}
                                        className={clsx('form-control form-control-lg txt-password')}
                                        type={showOldPassword ? 'text' : 'password'}
                                        name="oldPassword"
                                        autoComplete="off"
                                        aria-describedby="groupOldPassword"
                                        style={{ borderRight: "none", borderTop: "1px solid #e5e7ef", borderBottom: "1px solid #e5e7ef", borderLeft: "1px solid #e5e7ef" }}
                                    />
                                    <span className="input-group-text" id="groupOldPassword" onClick={() => setShowOldPassword(!showOldPassword)} style={{ backgroundColor: "white", borderLeft: "none" }}>
                                        {showOldPassword ? <i className="fas fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                                    </span>
                                </div>
                                {formik.touched.oldPassword && formik.errors.oldPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{formik.errors.oldPassword}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.NEW_PASSWORD" })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder='********'
                                        {...formik.getFieldProps('password')}
                                        className={clsx('form-control form-control-lg txt-password')}
                                        type={showNewPassword ? 'text' : 'password'}
                                        name='password'
                                        autoComplete='off'
                                        aria-describedby="groupNewPassword"
                                        style={{ borderRight: "none", borderTop: "1px solid #e5e7ef", borderBottom: "1px solid #e5e7ef", borderLeft: "1px solid #e5e7ef" }}
                                    />
                                    <span className="input-group-text" id="groupNewPassword" onClick={() => setShowNewPassword(!showNewPassword)} style={{ backgroundColor: "white", borderLeft: "none" }}>
                                        {showNewPassword ? <i className="fas fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                                    </span>
                                </div>
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{formik.errors.password}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.CONFIRM_NEW_PASSWORD" })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder='********'
                                        {...formik.getFieldProps('confirmPassword')}
                                        className={clsx('form-control form-control-lg txt-password')}
                                        type={showNewConfirmPassword ? 'text' : 'password'}
                                        name='confirmPassword'
                                        autoComplete='off'
                                        aria-describedby="groupNewConfirmPassword"
                                        style={{ borderRight: "none", borderTop: "1px solid #e5e7ef", borderBottom: "1px solid #e5e7ef", borderLeft: "1px solid #e5e7ef" }}
                                    />
                                    <span className="input-group-text" id="groupNewConfirmPassword" onClick={() => setShowNewConfirmPassword(!showNewConfirmPassword)} style={{ backgroundColor: "white", borderLeft: "none" }}>
                                        {showNewConfirmPassword ? <i className="fas fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                                    </span>
                                </div>
                                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{formik.errors.confirmPassword}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeChangePasswordBtnRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">{intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}</span>
                                    <span className="indicator-progress">Loading...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {
                type === "dashboard" &&
                <OTPModal username={user.data.username} password={newPassword} oldPassword={oldPassword} phone={phoneCensored} type={"dashboard"} />
            }
        </>
    )
}

export { ChangePassword }