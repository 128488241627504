import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import debounce from 'lodash/debounce';

interface Props {
    value: string
    setValue?: Dispatch<SetStateAction<string>>
    hasDisabled?: boolean
    checkWaValid?: (phone: string) => void
    validatePrimaryPhone?: () => boolean
}

const InputPhoneNative: FC<Props> = ({ value, setValue, hasDisabled = false, checkWaValid, validatePrimaryPhone }) => {
    const debouncedCheckWa = useCallback(
        debounce((val: string) => {
            if (val && checkWaValid) {
                checkWaValid(val);
            }
        }, 100),
        [checkWaValid]
    );

    const debouncedValidate = useCallback(
        debounce(() => {
            if (validatePrimaryPhone) {
                validatePrimaryPhone();
            }
        }, 100),
        [validatePrimaryPhone]
    );

    const handleOnchange = (val: string, country: string) => {
        if (setValue) {
            setValue(val);
            debouncedValidate();
            debouncedCheckWa(val);
        }
    }

    return (
        <>
            <PhoneInput
                disabled={hasDisabled}
                countryCodeEditable={true}
                value={value}
                placeholder='62xxxxxxxxx'
                country="id"
                onBlur={() => {
                    if (validatePrimaryPhone) {
                        validatePrimaryPhone();
                    }
                }}
                onChange={(value, country: any) => handleOnchange(value, country.dialCode)}
                enableSearch={true}
                inputStyle={{ width: "100%" }}
                dropdownClass="z-index-3"
            />
        </>
    );
};

export default InputPhoneNative;