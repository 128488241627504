import { FC, useEffect, useState } from 'react'
import { checkSession } from '../../../api/ChatHelper'
import useUser from '../../../hooks/User'
import { useChatStore } from '../../../stores/ChatStore'
import { ToC } from '../../../components/Chat/ToC'
import { PageNoPermisson } from '../general/PageNoPermission'
import { useSettingsStore } from '../../../stores/SettingsStore'
import useAccessToken from '../../../hooks/AccessToken'
import { ChatSidebar } from '../../../components/Chat/ChatSidebar'
import { ChatWindow } from '../../../components/Chat/ChatWindow'
import { SyncMessage } from '../../../interfaces/Chat/SyncMessage'
import { Message } from '../../../interfaces/Chat/Message'
import { Onboarding } from '../../../components/Chat/Onboarding/Onboarding'
import Swal from 'sweetalert2'
import axios from 'axios'
import useSWR from 'swr'
import { MerchantLeads } from '../../../interfaces/Chat/RecentChatHistory'
import { clearDB } from '../../../db'
import { ChatAside } from '../../../components/Chat/ChatAside'
import { ChatSetting } from '../../../components/Chat/Settings/ChatSetting'
import { ChatLabelSetting } from '../../../components/Chat/Settings/ChatLabelSetting'
import { ChatDetailLabelSetting } from '../../../components/Chat/Settings/ChatDetailLabelSetting'
import { ChatQuickReplySetting } from '../../../components/Chat/Settings/ChatQuickReplySetting'
import { SettingMultiPipelines } from '../../../interfaces/Settings'
import { usePipelineStore } from '../../../stores/PipelineStore'
import { useUserStore } from '../../../stores/UserStore'
import { useCustomeStore } from '../../../stores/CustomeStore'
import { useDispatch } from 'react-redux'
import * as auth from '../../../app/modules/auth/redux/AuthRedux'
import ModalForwardChat from '../../../components/Chat/Modal/ForwardChat/ModalForwardChat'
import { USER_MANAGER_HAPPY_PLAY } from '../../../constant/General'
import useShowCompanyById from '../../../hooks/useShowCompanyById'
import { useTokenCustomCompany } from '../../../stores/useTokenCustomCompany'

const Chat: FC = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const token = useAccessToken()
    const {
        setConnected, agreedTNC, recentChatHistories,
        setRecentChatHistories, persistedMessages, socket,
        setPersistedMessages, isFirstSyncing, setIsFirstSyncing,
        connected, lastSynced, setRecentChatIsSyncing, setIsWaitingForNetwork,
        setMessagesIsSyncing, menuActive, setChatLabels, quickReplies, setQuickReplies,
        isOpenForwardChat, setIsOpenForwardChat
    } = useChatStore()
    const { settings } = useSettingsStore()
    const { setPipelines, setPipelineLoading, setSelectMultiPipeline, setMultiPipelines } = usePipelineStore()
    const { setUsers, setUserLoading } = useUserStore()

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [totalRecentChats, setTotalRecentChats] = useState(0)
    const [totalChats, setTotalChats] = useState(0)
    const [offset, setOffset] = useState(0)
    const user = useUser()
    const { companyId } = useCustomeStore()
    const { tokenCustomCompany, numberCustomCompany } = useTokenCustomCompany()
    const [isTabActive, setIsTabActive] = useState(true)
    const dispatch = useDispatch()
    const { company } = useShowCompanyById(user.data.company_id)
    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)
    

    const { data: labels = [], error: labelsError, isLoading: labelsIsLoading } = useSWR(`${API_URL}/chat/labels`, fetcher)
    const { data: quickRepliesData = [], error: quickRepliesError, isLoading: quickRepliesIsLoading } = useSWR(`${API_URL}/chat/quick-replies`, fetcher)
    const { data: leadTemplate = [], error: leadTemplateError, isLoading: leadTemplateLoading } = useSWR(`${API_URL}lead-templates`, fetcher, { revalidateOnFocus: false, revalidateOnReconnect: false })
    const { data: userData = [], error: usersError, isLoading: usersLoading } = useSWR(`${API_URL}users`, fetcher)
    const { data: leads = [], error: leadsError, isLoading: leadsLoading } = useSWR(`${API_URL}simple-leads`, fetcher)

    useEffect(() => {
        const checkConnection = async () => {
            try {
                let customToken = ''
                if (company) {
                    customToken = tokenCustomCompany;
                }

                const response = await checkSession(token, customToken, company)

                if (response.status === 200) {
                    setConnected(response.data.data.isActive)
                }
            } catch (error) {
                console.log(error)
            }
        }
        setRecentChatHistories((prevState) => prevState.map(recentChatHistory => {
            return {
                ...recentChatHistory,
                isFirstLoaded: true
            }
        }))

        checkConnection()
    }, [])

    useEffect(() => {
        if (!leadTemplateLoading) {
            const result = leadTemplate.map((lead: SettingMultiPipelines) => ({
                ...lead,
                label: lead.name,
                value: lead.id,
            }));

            let dataLocal = localStorage.getItem("selectMultiPipeline")
            if (dataLocal) setSelectMultiPipeline(JSON.parse(dataLocal))
            else setSelectMultiPipeline(result[0])
            setMultiPipelines(result);
        }
    }, [leadTemplate]);

    useEffect(() => {
        // setPipelineLoading(leadsLoading)
        if (!leadsLoading) {
            setPipelines(leads)
        }
    }, [leads]);

    useEffect(() => {
        // setUserLoading(usersLoading)
        if (!usersLoading) {
            setUsers(userData)
        }
    }, [userData]);

    useEffect(() => {
        if (!labelsIsLoading) {
            setChatLabels(labels)
        }
    }, [labelsIsLoading])

    useEffect(() => {
        if (!quickRepliesIsLoading && quickRepliesData) {
            setQuickReplies(quickRepliesData)
        }
    }, [quickRepliesIsLoading])

    useEffect(() => {
        if (connected) {
            if (company) {
                if (tokenCustomCompany) {
                    socket.emit('setOption', { token: token, [`${company}Token`]: tokenCustomCompany });
                } else {
                    socket.emit('setOption', { token: token });
                }
            } else {
                socket.emit('setOption', { token: token });
            }
            // swal connecting
            setIsWaitingForNetwork(false)
        }

        // if(user.data.company_id === companyId.happyPlay) {
        //     if(connected) {
        //         if (tokenHappyPlay) {
        //             socket.emit('setOption', { token: token, happyPlayToken: tokenHappyPlay });
        //         }
        //     }
        //     setIsWaitingForNetwork(true)
        // } else {
        //     if (connected) {
        //         socket.emit('setOption', { token: token });
        //     }
        //     setIsWaitingForNetwork(true)
        // }

        return () => {
            socket.off("setOption");
        };
    }, [socket, token, connected, tokenCustomCompany])

    // Server memberikan tanda ketika ada yang salah pada option / konfigurasi
    socket.on('setOptionError', (message) => {
        console.log('setOptionError')
        Swal.fire({
            title: 'Error',
            text: message.error,
            icon: 'error',
            confirmButtonText: 'Refresh',
            didClose: () => {
                clearDB()
                window.location.reload()
            }
        })
    });

    useEffect(() => {
        if (numberCustomCompany) {
            const newUserData = {
                ...user,
                data: {
                    ...user.data,
                    chat_phone: numberCustomCompany
                }
            }
            dispatch(auth.actions.fulfillUser(newUserData))
        }
    }, [numberCustomCompany])

    useEffect(() => {
        const { recentChatHistories } = useChatStore.getState();

        socket.on('v2/connected', ({ totalChats, totalRecentChats }) => {
            setIsWaitingForNetwork(false)
            setTotalRecentChats(totalRecentChats)
            setTotalChats(totalChats)
            if (lastSynced || recentChatHistories.length >= totalRecentChats) {
                socket.emit('getRecentChats', recentChatHistories[0]?.chat_date ?? lastSynced);
            } else {
                setRecentChatHistories(() => [])
                setIsFirstSyncing(true)
                socket.emit('getRecentChats', '', { offset: 0, limit: 500 });
            }

            setRecentChatIsSyncing(true)
        });

        socket.on('initSync', (chatMessages: SyncMessage[]) => {
            let newPersistedMessages = { ...persistedMessages };

            // if(chatMessages.map(chat => chat.chat_message).includes("")) return

            chatMessages.forEach(chatMessage => {
                // if (chatMessage.client_phone === '6283847070417') {
                const newMessage: Message = {
                    id: chatMessage.chat_id,
                    id_reference: chatMessage.chat_id_reference,
                    text: chatMessage.chat_message,
                    time: chatMessage.chat_date,
                    is_from_me: chatMessage.chat_from_me,
                    attachment: chatMessage.chat_attachment,
                    attachment_name: chatMessage.chat_attachment_name,
                    name: chatMessage.chat_name,
                    phone: chatMessage.chat_phone,
                    type: chatMessage.chat_from_me === 1 ? 'out' : 'in',
                    is_editing: false,
                    is_replying: false,
                    sender: chatMessage.sender,
                    chat_j_message: chatMessage.chat_j_message,
                    isForwarded: chatMessage.isForwarded
                };

                // Initialize the messages array if it doesn't exist
                if (!newPersistedMessages[chatMessage.client_phone]) {
                    newPersistedMessages[chatMessage.client_phone] = { messages: [] };
                }

                // Append the new message to the existing array of messages
                newPersistedMessages[chatMessage.client_phone].messages = [
                    newMessage,
                    ...newPersistedMessages[chatMessage.client_phone].messages
                ];
                // }
            });

            setPersistedMessages(() => newPersistedMessages)

            if (offset < totalChats) {
                socket.emit('initSync', { limit: 10000, offset: offset })
                setOffset((prevOffset) => prevOffset + 10000)
            } else {
                setMessagesIsSyncing(false)
                setIsFirstSyncing(false)
            }
        })

        socket.on('disconnect', () => {
            console.log('disconnect')
            if (company) {
                socket.emit('setOption', { token: token, [`${company}Token`]: tokenCustomCompany });
            } else {
                socket.emit('setOption', { token: token });
            }
            setIsWaitingForNetwork(true)
        })

        socket.on('sessionDisconnected', () => {
            Swal.fire({
                title: 'Session Disconnected',
                icon: 'warning'
            })
            setConnected(false)
        })

        return () => {
            socket.off('v2/connected')
            socket.off('initSync')
            socket.off('disconnect')
            socket.off('sessionDisconnected')
        }
    }, [offset, persistedMessages, totalChats, lastSynced])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // useEffect(() => {
    //     let totalMessages = 0
    //     Object.keys(persistedMessages).forEach(phone => {
    //         totalMessages += persistedMessages[phone].messages.length
    //     })

    //     if (totalRecentChats !== 0 && recentChatHistories.length !== 0 && totalRecentChats === recentChatHistories.length && totalMessages === 0) {
    //         console.log('initSync 1')
    //         socket.emit('initSync', { limit: 10000, offset: 0 })
    //         setIsFirstSyncing(true)
    //         setOffset(10000)
    //     }

    // }, [recentChatHistories, persistedMessages, totalRecentChats])

    useEffect(() => {
        let totalMessages = 0
        Object.keys(persistedMessages).forEach(phone => {
            totalMessages += persistedMessages[phone].messages.length
        })

        if (totalMessages < totalChats && totalChats !== 0 && isFirstSyncing) {
            setPersistedMessages(() => ({}))
            setMessagesIsSyncing(true)
            socket.emit('initSync', { limit: 10000, offset: 0 })
            setOffset(10000)
        } else if (totalMessages >= totalChats) {
            setIsFirstSyncing(false)
        }
    }, [totalChats, isFirstSyncing])

    const { phoneNumber } = useChatStore()
    let menu = <ChatSidebar totalRecentChats={totalRecentChats} />
    if (menuActive === "chat") menu = <ChatSidebar totalRecentChats={totalRecentChats} />
    else if (menuActive === "settings") menu = <ChatSetting />
    else if (menuActive === "settings-label") menu = <ChatLabelSetting />
    else if (menuActive === "settings-detail-label") menu = <ChatDetailLabelSetting />
    else if (menuActive === "settings-quick-reply") menu = <ChatQuickReplySetting />

    document.addEventListener('contextmenu', event => event.preventDefault());

    useEffect(() => {
        const channel = new BroadcastChannel('whatsapp_tab_channel');
        channel.postMessage('tab_opened');
        channel.onmessage = (event) => {
            if (event.data === 'tab_opened') {
                setIsTabActive(false);
                Swal.fire({
                    title: 'Wooblazz Chat sedang terbuka di jendela lain',
                    text: 'Klik "Gunakan di Sini" untuk menggunakan Wooblazz Chat di jendela ini.',
                    showCancelButton: true,
                    confirmButtonText: 'Gunakan di Sini',
                    cancelButtonText: 'Tutup',
                    confirmButtonColor: '#00a884',
                    cancelButtonColor: '#8696a0',
                }).then((result) => {
                    if (result.isConfirmed) {
                        channel.postMessage('take_control');
                        setIsTabActive(true);
                        window.location.reload();
                    } else {
                        window.close();
                    }
                });
            } else if (event.data === 'take_control') {
                setIsTabActive(false);
                window.close();
            }
        };

        return () => {
            channel.close();
        };
    }, []);

    return (
        <>
            {isTabActive ? (
                <>
                    {
                        !agreedTNC && <ToC />
                    }
                    {
                        !settings.feature_chat ?
                            <PageNoPermisson title='Chat' />
                            :
                            agreedTNC &&
                            <>
                                {(!connected) && <Onboarding totalRecentChats={totalRecentChats} totalChats={totalChats} />}
                                <div className={`row me-0 mt-2 ${(!connected) && 'd-none'}`}>
                                    <div className={`col-12 col-xl-4 d-${phoneNumber ? 'none' : 'flex'} d-lg-flex`}>
                                        <ChatAside />
                                        {menu}
                                    </div>
                                    <div className={`col-xl-8 d-${phoneNumber ? 'block' : 'none'} d-lg-block`}>
                                        <ChatWindow />
                                    </div>
                                </div>
                                <ModalForwardChat isOpen={isOpenForwardChat} onClose={() => setIsOpenForwardChat(false)} userChats={recentChatHistories} user={user} />
                            </>
                    }
                </>
            ) : null}
        </>
    )
}

export { Chat } 