import { FC, useRef, useEffect, useState } from 'react'
import { shallowEqual, useSelector, connect, useDispatch, ConnectedProps } from 'react-redux'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import { getUserByToken } from './AuthCRUD'
import { RootState } from '../../../../setup'
import { useChatStore } from '../../../../stores/ChatStore'

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
  const { setOriginalChatPhone } = useChatStore()

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'none')
    }
  }

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken(accessToken as string)
          setOriginalChatPhone(user.data.chat_phone)
          dispatch(props.fulfillUser(user))
        }
      } catch (error: any) {
        if (!didRequest.current) {
          if(error.response.status >= 400 && error.response.status < 500) {
            dispatch(props.logout())
          }
          disableSplashScreen()
        }
      } finally {
        setShowSplashScreen(false)
        disableSplashScreen()
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
      disableSplashScreen()
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
