import { FC, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { rejectReimbursement } from "../../api/ReimbursementCRUD";
import { Reimburse } from "../../interfaces/Reimbursement";
import { useFormik } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";
import { handleCaps } from "../../functions/general";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

interface Props {
    reimburse: Reimburse
}

const RejectReimbursement: FC<Props> = ({ reimburse }) => {

    const closeModalRef = useRef<HTMLButtonElement>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const token = useAccessToken()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const intl = useIntl()

    const formik = useFormik({
        initialValues: {
            reason: ''
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.REASON" }) })),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)
            try {
                const response = await rejectReimbursement(reimburse.id, values.reason, token)
                if (response.data.success) {
                    Swal.fire({
                        icon: "success",
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: `${intl.formatMessage({ id: "FORM.VALIDATION.REJECT_SUCCESS" })}.`,
                        heightAuto: false,
                    })
                    closeModalRef.current?.click();

                    setTablesData('reimbursements', (tablesData['reimbursements'].map(item => {
                        if (item.id === reimburse.id) {
                            return {
                                ...item,
                                approvalStatus: response.data.data.approvalStatus,
                                dateRejected: response.data.data.dateRejected,
                                rejectedReason: response.data.data.rejectedReason,
                                reference: response.data.data.reference
                            }
                        } else {
                            return item
                        }
                    })));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`reject-reimbursement-modal-${reimburse.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.REJECT' })} {reimburse.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                <p className="mb-5">
                                    {intl.formatMessage({ id: 'FORM.LABEL.DESC_REASON_REJECT_1' }, { title: reimburse.name })}. <br />
                                    {intl.formatMessage({ id: 'FORM.LABEL.DESC_REASON_REJECT_2' })}
                                </p>
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                {/* Reason */}
                                <div className="input-group mb-3">
                                    <textarea
                                        placeholder={intl.formatMessage({ id: 'FORM.LABEL.REASON' })}
                                        id="floatingTextarea"
                                        {...formik.getFieldProps('reason')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.reason && formik.errors.reason },
                                        )}
                                        style={{ zIndex: 0 }}
                                        name="reason"
                                        maxLength={255}
                                        onInput={(e) => handleCaps(e)}
                                    >
                                    </textarea>
                                </div>
                                {formik.touched.reason && formik.errors.reason && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.reason}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-danger me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.REJECT' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { RejectReimbursement }