import {  IVarDocumentationProps, QueryParameter, RequestBody } from "../../../interfaces/OpenAPI";

export const getDocumentationPipelineProps = ({intl, settings}:IVarDocumentationProps )=>{    
 

    // ===================== GET PIPELINES =====================
    const getPipelinesExample = {
            success: true,
            message: "Berhasil mendapatkan daftar Pipe.",
            data: [
                {
                    id: "2d43416f-510f-4bc9-8d71-d8f355696ef2",
                    lead_template_id: "2607a804-5bff-45bf-b9e4-2d5fc3412ad9",
                    lead_template_name: "Sales Management",
                    creator_id: "e911a7e4-cca7-4257-a8b9-90490c2787b8",
                    creator_name: "RIN SALES",
                    custom_fields: {
                        "f9c5478e-6741-4561-abc1-69c20377a06f": "https://resource.looyal.id/attachments/wooblazz/crm/lead/985e1190-a754-47ef-a9a2-1a6ddb07d134.jpeg"
                    },
                    date_created: "2024-12-05 15:28:43",
                    date_end: null,
                    date_start: "2024-12-05 07:00:00",
                    hot_warm_cold_status: "cold",
                    lead_age: "0",
                    from_excel: false,
                    lead_logs: [],
                    actualLastLeadLog: null,
                    lead_logs_count: 0,
                    lead_progress_color: null,
                    lead_progress_id: null,
                    lead_progress_name: "NEW",
                    lead_progress_is_new_progress: null,
                    lead_progress_progress_type: "ONGOING",
                    lead_source_id: "534fa800-1d00-4f43-acab-f7a4c6499d5f",
                    lead_source_name: "NEW SOURCE",
                    lead_subprogress_name: null,
                    labels: [],
                    leadProducts: [
                        {
                            unitId: "6d0fa803-23ef-4365-97e7-ece4c5a45fcb",
                            unitName: "HEKSADESIMAL",
                            unitAlias: "HM",
                            productId: "9968c5e4-93c6-483c-ab03-07add936713d",
                            productSku: null,
                            productName: "SAMYANG CARBONARA",
                            productNote: null,
                            productColor: "#416a06",
                            productLabels: [
                                {
                                    id: "9513e474-3bd7-40c1-84af-9b760f0303be",
                                    name: "FOODS",
                                    color: null
                                }
                            ],
                            productUnits: [
                                {
                                    convertionToPieces: 9,
                                    unit: {
                                        id: "6d0fa803-23ef-4365-97e7-ece4c5a45fcb",
                                        name: "HEKSADESIMAL",
                                        alias: "HM"
                                    }
                                }
                            ],
                            leadProductId: "3eaba0f2-6c76-4fd2-87b2-6815f9b24fc5",
                            leadProductName: "SAMYANG CARBONARA",
                            leadProductDiscount: 0,
                            leadProductNotes: "",
                            leadProductPrice: 100000,
                            leadProductHpp: 14,
                            leadProductQty: 1
                        }
                    ],
                    meeting_id: null,
                    merchant_id: "ea764283-2b8e-4122-995b-56ef22480329",
                    merchant_is_whatsapp_phone: false,
                    merchant_name: "HARUTO",
                    merchant_phone: "628888888",
                    nominal: 100000,
                    note: "",
                    owner_id: "e911a7e4-cca7-4257-a8b9-90490c2787b8",
                    owner_name: "RIN SALES",
                    owner_username: "lit-rin",
                    owner_status: "ACTIVE",
                    paymentTermins: [],
                    owner_phone: "62895329388818"
                }
            ]    
    }

    const getPipelinesParams: QueryParameter[] = [
        {
            field: 'user_id',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_GET_ID" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) }),
            target: 'getUsers',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })
        },
        {
            field: 'scope',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_SCOPE_PIPELINE" }, { title: settings.merchant_title })
        },
        {
            field: 'date_created_from',
            type: `Date (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({id :"OPEN_API.PARAMS.DESC_DATE"})
        },
        {
            field: 'date_created_to',
            type: `Date (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({id : "OPEN_API.PARAMS.DESC_DATE"})
        },
        {
            field: 'scope',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_SCOPE_PIPELINE" }, { title: settings.merchant_title })
        },
        {
            field: 'showAll',
            type: `Boolean (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({id : "OPEN_API.PARAMS.DESC_BOOLEAN"})
        },
       
    ]

    const python_get_pipelines = `
    import requests
    import json

    params = {
    "user_id": "e3e26237-76e1-4df1-892d-1765563bfa48",
    "scope": "team",
    "date_created_from": "2024-01-01",
    "date_created_to": "2024-12-31",
    "showAll": True
    }


    reqUrl = "https://crm-api.wooblazz.com/v2/leads"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    response = requests.request("GET", reqUrl, headers=headersList, params=params)
    print(response.text)
    `

    const javascript_get_pipelines = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/v2/leads";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    const params = {
    user_id: "e3e26237-76e1-4df1-892d-1765563bfa48",
    scope: "team",
    date_created_from: "2024-01-01",
    date_created_to: "2024-12-31",
    showAll: true
    };

    axios.get(reqUrl, {
    headers: headersList,
    params: params
    })
    .then((response) => {
    console.log(response.data);
    })
    .catch((error) => {
    console.error(error);
    });
    `

    const php_get_pipelines = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/merchants";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $params = [
        "user_id" => "e3e26237-76e1-4df1-892d-1765563bfa48",
        "scope" => "team",
        "date_created_from" => "2024-01-01",
        "date_created_to" => "2024-12-31",
        "showAll" => true
    ];

    $queryString = http_build_query($params);
    $urlWithParams = $reqUrl . '?' . $queryString;

    $ch = curl_init();

    $headers = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    curl_setopt($ch, CURLOPT_URL, $urlWithParams);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

    $response = curl_exec($ch);

    if (curl_errno($ch)) {
        echo 'Error: ' . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);

    ?>
    `

    const bash_get_pipelines = `

    REQ_URL="https://crm-api.wooblazz.com/v2/leads"

    USER_ID="e3e26237-76e1-4df1-892d-1765563bfa48"
    SCOPE="team"
    DATE_FROM="2024-01-01"
    DATE_TO="2024-12-31"
    SHOW_ALL="true"

    URL_WITH_PARAMS="$REQ_URL?user_id=$USER_ID&scope=$SCOPE&date_created_from=$DATE_FROM&date_created_to=$DATE_TO&showAll=$SHOW_ALL"

    curl  -X GET
    'https://crm-api.wooblazz.com/v2/leads'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}'
    `
    // ===================== GET PIPELINE =====================
    const getPipelineExample = {
    success: true,
    message: "Berhasil mendapatkan Pipe.",
    data: {
        id: "db264146-f1e5-4815-9ad2-2341fd5adbfd",
        date_start: "2024-11-22 11:31:00",
        date_end: "",
        nominal: "22",
        note: "dg",
        lead_template_id: "a6f684e4-00e8-4559-ac91-8e62c4e22cfe",
        owner_id: "2276a314-3432-429c-b26e-81fbf170eaa2",
        owner_name: "HER",
        owner_username: "lit-LIT-SHABRINASUPERADMINJGNh",
        merchant_id: "cce2a91f-ad67-43bd-95ba-1bd80977727d",
        merchant_name: "Dari excel",
        merchant_phone: "628999999999",
        merchant_is_whatsapp_phone: false,
        creator_id: "4129f29e-5649-4a52-b8d2-52e3e0225f0a",
        creator_name: "LIT Shabrina Super Admin",
        meeting_id: null,
        date_invoice: "",
        date_invoice_due: "",
        date_created: "2024-11-22 11:33:21",
        lead_subprogress_name: null,
        attachments: [],
        custom_fields: {},
        lead_source_id: "9a4fc2a6-a7a3-435a-9747-f23e77dd5ee6",
        lead_source_name: "OUTER",
        lead_age: "13",
        lead_progress_id: null,
        lead_progress_name: "NEW",
        lead_progress_progress_type: "ONGOING",
        lead_progress_color: null,
        lead_logs_count: 0,
        labels: [],
        hot_warm_cold_status: null,
        leadProducts: [
        {
            leadProductId: "8bcc05b2-f671-49cd-8149-fe6c02a91b8e",
            leadProductName: "FF",
            productId: "898b7010-4f77-4267-aa46-05f7f23b0a54",
            productName: "FF",
            leadProductPrice: "2",
            leadProductQty: "11",
            leadProductHpp: "1",
            leadProductDiscount: "0",
            leadProductNotes: "",
            productLabels: [
            {
                id: null,
                name: null,
                color: null,
            },
            ],
        },
        ],
    },
            
    }
    const python_get_pipeline = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/v2/lead/<<id>>"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }
    response = requests.request("GET", reqUrl, headers=headersList)

    print(response.text)
    `
    const javascript_get_pipeline = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/v2/lead/<<id>>";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    axios.get(reqUrl, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_get_pipeline = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/v2/lead/<<id>>";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_get_pipeline = `
    curl  -X GET
    'https://crm-api.wooblazz.com/v2/lead/<<id>>'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}'
    `

    const getPipelineParam = [
        {
            field: 'id',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_GET_ID" }, { title: intl.formatMessage({id : "OPEN_API.PIPELINE"}) }),
            target: 'getPipelines',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({id : "OPEN_API.PIPELINE"}) })
        }
    ] 

    // ===================== ADD PIPELINE =====================
    const addPipelineResponse = {
        success: true,
        message: "Berhasil membuat Pipe.",
        data: {
          id: "c09b56d7-f4da-4c40-8dc3-6c5fc8e9f6d1",
          lead_template_id: "2607a804-5bff-45bf-b9e4-2d5fc3412ad9",
          date_start: "2024-11-28 16:04:00",
          date_end: "",
          nominal: "100000",
          note: "PIDIDI",
          owner_id: "dc6b7b0b-b011-4e83-a7d8-cafa8dc8e344",
          owner_name: "11",
          owner_username: "lit-11",
          owner_phone: "621111111",
          merchant_id: "ecacf4f4-ed49-4b7f-b181-269ea5a5ad07",
          merchant_name: "1111111111",
          merchant_phone: "62666466446",
          merchant_is_whatsapp_phone: false,
          creator_id: "3f1488d1-ca88-4199-8190-13d890936c45",
          creator_name: "LIT Richard Super Admin",
          meeting_id: null,
          date_invoice: "",
          date_invoice_due: "",
          date_created: "2024-12-09 14:54:57",
          attachments: [],
          custom_fields: {
            "350a106b-edea-45c4-8da2-83fad376d34d": "{\"date_due\":null,\"invoice_url\":null}",
            "9dfd54b2-9b98-45e9-b62f-867db62d710d": null,
            "4a7e92ee-bea5-4389-8c6a-c76407e13f56": null,
            "84dd71cd-f623-473e-81d5-7df3d68bdc41": null,
            "7ddd0b27-e6a6-46c9-b11f-e15a8d331e82": null,
            "9f41d114-6509-4cb1-a12c-8e6a2b455131": null,
            "7696b3a0-ef71-49ea-a34d-22deef15fdef": null,
            "384c3e5b-2f3d-456e-8f54-f1502ec66e29": null,
            "f5b9cfe2-bea2-4b92-a6fd-5c8f8109ed84": null,
            "dd37430b-9673-4bb2-9744-4a6765157760": null,
            "d7e11eda-438d-4e98-bf60-e6e5d1e10aa1": null,
            "31425640-bb2c-40e7-ac9d-0f6472c2e5e7": null,
            "615e9347-d611-4902-90e7-50641868243e": null,
            "c9e4ad29-1ab3-4d45-88d5-2e79cd8959a1": null,
            "7b6ece25-b1f7-45b3-8ad3-25aaa969287b": null,
            "05471553-64b1-44e6-87d0-24ef709f566f": null,
            "196fe54a-e62b-45dd-b6bd-4389781ecde6": null,
            "4f8b6627-fc4f-4d80-9b03-8fbc114fae03": null,
            "1de8b13b-f6ed-4beb-a8f1-8f90415b3dd6": null,
            "f9c5478e-6741-4561-abc1-69c20377a06f": "https://resource.looyal.id/attachments/wooblazz/crm/lead/custom-field/d116facb-80ae-4aa2-b8e5-0b4c74dbd147.jpeg",
            "9a70924d-89c8-43d9-95f1-f3758b320e94": null,
            "2db2799e-c9bc-4989-8193-6559a52cda23": null
          },
          lead_source_id: "8a60d49f-7d26-41c7-952c-72ef3acb3df6",
          lead_source_name: "OUTBOUND",
          lead_age: "10",
          lead_progress_name: "NEW",
          lead_progress_progress_type: "ONGOING",
          lead_progress_color: null,
          hot_warm_cold_status: "cold",
          leadProductQty: 0,
          labels: [
            {
              id: "29e24bf4-e752-4958-9ff1-3c013ef7bbeb",
              name: "AYAM GORENG",
              color: null
            }
          ],
          leadProducts: [
            {
              leadProductId: "fd45d5b3-79d6-4e6b-8748-c78dd2837678",
              leadId: "c09b56d7-f4da-4c40-8dc3-6c5fc8e9f6d1",
              productId: "a0aad63d-a202-4a55-b909-b5cba41ed9c0",
              leadProductUnitId: null,
              productName: "BIHUN GORENG",
              leadProductQty: 1,
              leadProductPrice: 100000,
              leadProductDiscount: 0,
              leadProductHpp: null,
              leadProductNotes: "",
              leadProductName: "BIHUN GORENG",
              leadProductDateCreated: "2024-12-09 14:54:57",
              productLabels: [
                {
                  id: "9513e474-3bd7-40c1-84af-9b760f0303be",
                  name: "FOODS",
                  color: null
                }
              ]
            }
          ]
        }
      };
      

    const addPipelineBody: RequestBody[] = [
        {
            field: 'leadTemplateId',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_GET_ID" }, { title: intl.formatMessage({id:"OPEN_API.TEMPLATE_PIPELINE"})}),
            target: 'getTemplatePipelines',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({id : "OPEN_API.TEMPLATE_PIPELINE"}) })
        },
        {
            field: 'merchantId',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_GET_ID" }, { title: settings.merchant_title}),
            target: 'getMerchants',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: settings.merchant_title })
        },
        {
            field: 'dateStart',
            type: `Date (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({id:"OPEN_API.PARAMS.DESC_DATE"}),
        },
        {
            field: 'leadProducts',
            type: `Array (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({id :"OPEN_API.PARAMS.DESC_PRODUCT"}),
            target: "getProducts",
            target_name: intl.formatMessage({id : "OPEN_API.GET"}, {title: intl.formatMessage({id:"OPEN_API.PRODUCT"})})
        },
        {
            field: 'leadSourceId',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({id :"OPEN_API.PARAMS.DESC_LEAD_SOURCE_ID"}),
            target:"getLeadSources",
            target_name: intl.formatMessage({id : "OPEN_API.GET"}, {title: intl.formatMessage({id:"OPEN_API.PIPELINE_SOURCE"})})
        },
        {
            field: 'leadLabels',
            type: `Array (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({id :"OPEN_API.PARAMS.DESC_LEAD_LABELS"}),
            target: "getLabels",
            target_name: intl.formatMessage({id : "OPEN_API.GET"}, {title: intl.formatMessage({id:"OPEN_API.LABEL"})})
        },
        {
            field: 'ownerId',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_OWNER_ID" }, { title: settings.merchant_title }),
            target: 'getUsers',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })
        },
        {
            field: 'note',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: "",
        },
        {
            field: 'customFields',
            type: `Object (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_CUSTOM_FIELD" }),
            target: 'getSetting',
            target_name: intl.formatMessage({ id: "OPEN_API.CUSTOM_FIELD" }, { title: settings.merchant_title })
        },
    ]

    const python_add_pipeline = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/merchant"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}
    ownerId = "dc6b7b0b-b011-4e83-a7d8-cafa8dc8e344"
    leadTemplateId = "2607a804-5bff-45bf-b9e4-2d5fc3412ad9"
    merchantId = "ecacf4f4-ed49-4b7f-b181-269ea5a5ad07"
    dateStart = "2024-11-28T09:04:00.000Z"
    note = "PIDIDI"
    leadProducts = [
        {
            "productId": "a0aad63d-a202-4a55-b909-b5cba41ed9c0",
            "leadProductName": "BIHUN GORENG",
            "productName": "BIHUN GORENG",
            "leadProductQty": 1,
            "leadProductNotes": "",
            "leadProductPrice": 100000,
            "leadProductTotal": 100000,
            "leadProductDiscount": null,
            "edit": false,
            "leadProductHpp": null,
            "productSku": "",
            "productNote": "",
            "unitId": null,
            "unitName": null,
            "unitAlias": null,
            "productUnits": []
        }
    ]
    leadSourceId = "8a60d49f-7d26-41c7-952c-72ef3acb3df6"
    leadLabels = [
        {
            "id": "29e24bf4-e752-4958-9ff1-3c013ef7bbeb",
            "name": "AYAM GORENG"
        }
    ]
    customFields = {
        "350a106b-edea-45c4-8da2-83fad376d34d": "{\"date_due\":null,\"invoice_url\":null}",
    }

    headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json",
        "X-Auth-token": token
    }

    payload = json.dumps({
        "leadTemplateId": leadTemplateId,
        "merchantId": merchantId,
        "dateStart": dateStart,
        "leadProducts": leadProducts,
        "leadSourceId": leadSourceId,
        "leadLabels": leadLabels,
        "ownerId": ownerId,
        "note": note,
        "customFields": customFields
    })

    response = requests.request("POST", reqUrl, data=payload, headers=headersList)

    print(response.text)
    `

    const javascript_add_pipeline = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/merchant";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}
    const ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8";
    const name = "Test Merchant";
    const cityId = 131;
    const phone = "628221234567";
    const customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    };

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    const payload = {
    "ownerId": ownerId,
    "name": name,
    "cityId": cityId,
    "phone": phone,
    "customFields": customFields,
    };

    axios.post(reqUrl, payload, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_add_pipeline = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/merchant";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}
    $ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8";
    $name = "Test Merchant";
    $cityId = 131;
    $phone = "628221234567";
    $customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    };

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $data = [
        "ownerId" => $ownerId,
        "name" => $name,
        "cityId" => $cityId,
        "phone" => $phone,
        "customFields" => $customFields,
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_add_pipeline = `
    ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8"
    name = "Test Merchant"
    cityId = 131
    phone = "628221234567"
    customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    }

    curl -X POST "https://crm-api.wooblazz.com/merchant" 
    -H "Accept: */*" 
    -H "Content-Type: application/json" 
    -H "X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}"
    -d '{
    "ownerId": "'"$ownerId"'",
    "name": "'"$name"'",
    "cityId": "'"$cityId"'",
    "phone": "'"$phone"'",
    "customFields": "'"$customFields"'",
    }'
    `


    return   {getPipelinesParams, bash_get_pipelines, javascript_get_pipelines, php_get_pipelines, python_get_pipelines, getPipelinesExample, getPipelineExample, python_get_pipeline, javascript_get_pipeline, php_get_pipeline, bash_get_pipeline, getPipelineParam, addPipelineResponse, addPipelineBody, python_add_pipeline, php_add_pipeline, javascript_add_pipeline, bash_add_pipeline}
}