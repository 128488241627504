import { FC, useEffect, useRef, useState } from "react";
import { useSettingsStore } from "../../stores/SettingsStore";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import { SelectOption } from "../../interfaces/SelectOption";
import axios from "axios";
import useSWR, { mutate } from "swr";
import useAccessToken from "../../hooks/AccessToken";
import useUser from "../../hooks/User";
import { DataTableSelect } from "../DataTable/DataTableSelect";
import { TargetType } from "../../app/pages/targets/MasterTargets";
import { Role } from "../../interfaces/Role";
import { useIntl } from "react-intl";

interface FilterTargetProps {
    targetType: any,
    setTargetType: any,
}

const FilterTarget: FC<FilterTargetProps> = ({ targetType, setTargetType }) => {

    const { settings } = useSettingsStore()
    const { setSelectedFilter, selectedFilters } = useDataTableStore();
    const token = useAccessToken()
    const user = useUser()
    const [rolesData, setRolesData] = useState<SelectOption[]>([]);

    const intl = useIntl()

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL

    const { data: roles = [], error: rolesError, isLoading: rolesLoading } = useSWR(`${API_URL}roles`, fetcher)
    const typeTargetData = [{
        label: intl.formatMessage({ id: "TARGET.TYPE.PAID_PIPELINE_NOMINAL" }, { title: settings.pipeline_title }),
        value: "PIPELINE SUCCESS NOMINAL"
    }, {
        label: intl.formatMessage({ id: "TARGET.TYPE.PRODUCTS_SOLD_QUANTITY" }),
        value: "PRODUCT SOLD QTY"
    }, {
        label: intl.formatMessage({ id: "TARGET.TYPE.MEETINGS_COUNT" }),
        value: "MEETING COUNT"
    }]

    const selectConfigs = [
        {
            placeholder: `${intl.formatMessage({id: "FORM.ACTION.CHOOSE"})} ${intl.formatMessage({id: "FORM.LABEL.TARGET"})}`,
            options: typeTargetData,
            onChange: (values: TargetType) => setTargetType(values.value),
            filterKey: "user_targets",
            // tableKey: "targets",
            isNotMulti: true,
            closeMenuOnSelect: true,
            selectedOption: typeTargetData.find(option => option.value === targetType)
        },
        {
            placeholder: `${intl.formatMessage({id: "FORM.ACTION.CHOOSE"})} ${intl.formatMessage({id: "FORM.LABEL.ROLE"})}`,
            filterKey: 'roleName',
            tableKey: 'user_targets',
            options: rolesData,
            onChange: (values: string[]) => setSelectedFilter('user_targets', 'roleName', values)
        }
    ];

    const handleResetFilter = () => {
        // Reset Data 
        setSelectedFilter('user_targets', 'roleName', [])
    }

    useEffect(() => {
        if (!rolesLoading) {
            const rolesData = roles.map((roles: Role) => ({
                value: roles.name,
                label: roles.name
            }));

            setRolesData(rolesData)
        }
    }, [roles])

    useEffect(() => {
        if (targetType) {
            mutate(`${API_URL}user-targets`);
        }
    }, [targetType]);

    return (
        <>
            {/* <button type="button"
                className="btn btn-light mt-3"
                onClick={() => { setOpenDrawer(true) }}
            >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 m-0' />
            </button>

            <Drawer size="xs" open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <Drawer.Header>
                    <Drawer.Title style={{ fontWeight: "500" }}>Filter Target</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body style={{ padding: "30px 30px" }}>
                    <div style={{ fontWeight: "600" }}>Filter Data</div>
                    <div className="separator separator-dashed my-3"></div>
                    {
                        selectConfigs.map((config, index) => (
                            <DataTableSelect
                                placeholder={config.placeholder}
                                key={index}
                                filterKey={config.filterKey}
                                tableKey={config.tableKey}
                                options={config.options}
                                onChange={config.onChange}
                                isNotMulti={config.isNotMulti}
                                closeMenuOnSelect={config.closeMenuOnSelect}
                                selectedOption={config.selectedOption}
                            />
                        ))
                    }
                </Drawer.Body>
            </Drawer> */}

            {
                (selectedFilters["user_targets"] && selectedFilters["user_targets"].roleName.length > 0) ?
                    <>
                        <button className="btn mt-3 me-3" style={{ backgroundColor: "#f1faff", border: "2px solid #009EF7", padding: "calc(0.75rem) calc(1.5rem)" }} id="drawer_filter_target">
                            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 svg-icon-primary m-0' />
                        </button>
                        <button type="button"
                            className="btn btn-white mt-3 text-primary"
                            onClick={() => { handleResetFilter() }}
                        >
                            Reset Filter
                        </button>
                    </>
                    :
                    <button type="button"
                        className="btn btn-light mt-3"
                        id="drawer_filter_target"
                    >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 m-0' />
                    </button>
            }

            <div
                id="kt_drawer_filter_target"
                className="bg-white"
                data-kt-drawer="true"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                data-kt-drawer-toggle="#drawer_filter_target"
                data-kt-drawer-close="#kt_drawer_example_basic_close"
                data-kt-drawer-width="{default:'300px', 'md': '400px'}"
            >
                <div className="card w-100 rounded-0">
                    <div className="card-header pe-5">
                        <div className="card-title">
                            <div className="d-flex justify-content-center flex-column me-3">
                                <span className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1">Filter Target</span>
                            </div>
                        </div>
                        <div className="card-toolbar">
                            <div className="btn btn-sm btn-icon btn-active-light-primary" id="kt_drawer_example_basic_close">
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{ overflowY: "auto" }}>
                        <div style={{ fontWeight: "600" }}>Filter Data</div>
                        <div className="separator separator-dashed my-3"></div>
                        {
                            selectConfigs.map((config, index) => (
                                <DataTableSelect
                                    placeholder={config.placeholder}
                                    key={index}
                                    filterKey={config.filterKey}
                                    tableKey={config.tableKey}
                                    options={config.options}
                                    onChange={config.onChange}
                                    isNotMulti={config.isNotMulti}
                                    closeMenuOnSelect={config.closeMenuOnSelect}
                                    selectedOption={config.selectedOption}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export { FilterTarget }