import { create } from 'zustand';

export type keyCustomfieldProps = {
  serial: {
    enterpriseService: string
    enterpriseSales: string
  }
}

export type CustomeStoreCompanyIdProps = {
  looyalIT: string
  looyalIT1: string
  juraganMaterial: string
  uec: string
  elgisa: string
  tkd: string
  enterpriseService: string
  enterpriseSales: string
  medica: string
  sinertek: string
  kawasaki1: string
  kawasaki2: string
  maxy: string
  happyPlay: string
  hom: string
  vedaPraxis: string
  esb: string
  rainbowEdu: string
  maspionSquare: string
  giaGroup: string
  oky: string
}

export type CustomStoreRoleIdProps = {
  juraganMaterial: {
    scm: string
    salesProductManager: string
    superadmin: string
    salesHead: string
  }
}

export type CustomeStoreProps = {
  companyId: CustomeStoreCompanyIdProps,
  roleId: CustomStoreRoleIdProps,
  progressName: {
    newSales: string
    newService: string
  },
  keyCustomfield: keyCustomfieldProps
};

export const useCustomeStore = create<CustomeStoreProps>((set, getState) => ({
  companyId: {
    looyalIT: "80ef97f0-63ca-4004-acec-30ca645d1b56",
    looyalIT1: "7b87ff92-2206-4eb9-8c22-0df64c9f0271",
    juraganMaterial: "2f8cab9e-bcc6-45ab-8c87-b72ebf096587",
    uec: "9b622351-aa73-430e-a86c-6c1a25b9f7e8",
    elgisa: 'd2f2a3da-3829-4872-8891-cae921d555b5',
    tkd: '25fa94fd-7a2e-11ee-8848-02ce2ac3ab1a',
    enterpriseService: '24aab21e-7645-4184-917c-0d16070c309f',
    enterpriseSales: '5dce5437-2d80-4a76-a081-671f98bc086e',
    medica: '81928e88-73d3-4d74-a9e9-0a9c3a70a096',
    sinertek: '1aa59692-302e-4e6a-b3a2-b3dbaad85943',
    kawasaki1: 'a2240d39-ec64-469a-a84e-2682598fd864',
    kawasaki2: 'aef33592-697f-485a-b68a-7bc49568116f',
    maxy: '639207ae-54cd-4f4d-85fd-857799521a1e',
    happyPlay: '53a8b3c0-2457-4d22-82a2-bdf8cef4fec1',
    vedaPraxis: 'e8c4ba54-c7b0-44d3-a40a-c12b152e6eaf',
    hom: '35d1dc7f-ad91-4b88-8a71-de78cc2cd5a3',
    esb: '7763fccc-af52-43e1-8907-4678ab2423d1',
    rainbowEdu: 'c9740cea-d579-47c3-ab21-0f5c2f0cac3f',
    maspionSquare: '07347c63-d591-4e35-9bb4-c564072fe293',
    giaGroup: '0ea707f0-74b4-4733-841c-da74705fd6db',
    oky: 'a2d0843a-ec7d-49c3-971e-b501eeee8531',
  },
  roleId: {
    juraganMaterial: {
      scm: "97acf673-1589-4e24-8ec0-5c82696e4486",
      salesProductManager: "e18eebab-297a-4061-a02c-51e5f5eac759",
      superadmin: '49ea4306-c837-4df3-bc77-215e7300a479',
      salesHead: '3651b4d5-4964-4547-b690-353069d91e5f'
    },
  },
  progressName: {
    newSales: "new-sales",
    newService: "new-service",
  },
  keyCustomfield: {
    serial: {
      enterpriseService: "e1c0ffdd-1b82-40d3-be7b-2866851a6f72",
      enterpriseSales: "99d1cb49-6afd-4a33-a5a3-6774648501bd"
    }
  }
}));
