import { FC, ReactNode, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Modal } from "bootstrap";
import { Progress } from "../../../interfaces/Progress";
import { AddProgress } from "../../../components/Progresses/AddProgress";
import { DeleteProgress } from "../../../components/Progresses/DeleteProgress";
import { UpdateProgress } from "../../../components/Progresses/UpdateProgress";
import useUser from "../../../hooks/User";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useIntl } from "react-intl";
import { useCustomeStore } from "../../../stores/CustomeStore";

const MasterProgresses: FC = () => {
    const { progressName } = useCustomeStore()
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [progress, setProgress] = useState<Progress>();
    const user = useUser();
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);

    const intl = useIntl();

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const tableColumns: TableColumn<Progress>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '70px',
            cell: (row) => {
                return (
                    <>
                        {
                            ((row.name).toLowerCase() !== progressName.newSales && (row.name).toLowerCase() !== progressName.newService) &&
                            <>
                                <IconButton
                                    aria-label={`more-${row.id}`}
                                    id={`menu-anchor-${row.id}`}
                                    onClick={() => handleClick(row.id)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    id={`menu-${row.id}`}
                                    anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                                    open={openMenuId === row.id}
                                    onClose={handleClose}
                                    disableScrollLock={true}
                                >
                                    {
                                        (user.data.is_superadmin || user.data.permission_pipeline_progress_update !== 'NONE') &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowUpdateModal(true);
                                            setProgress(row);
                                        }}>
                                            <i className="fas fa-edit fs-4 me-4"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                                        </MenuItem>
                                    }

                                    {
                                        (user.data.is_superadmin || user.data.permission_pipeline_progress_delete !== 'NONE') &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowDeleteModal(true);
                                            setProgress(row);
                                        }}>
                                            <i className="fas fa-trash fs-3 me-5"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                                        </MenuItem>
                                    }
                                </Menu>
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            width: '25%',
            selector: row => row.name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.name
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PROGRESS_TYPE' }),
            selector: row => row.progress_type?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.progress_type
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SUB_PROGRESS' }),
            selector: row => row.sub_progress.length > 0 ? row.sub_progress.map(data => data.name).join(", ") : "-",
            sortable: true,
            cell: row => row.sub_progress.length > 0 ? row.sub_progress.map(data => data.name).join(", ") : "-"
            
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COLOR' }),
            width: '75px',
            center: true,
            selector: row => row.color,
            cell: row => {
                return (
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '6px',
                            backgroundColor: row.color,
                        }}
                    />
                );
            },
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
            selector: row => row.creator_name,
        }
    ];

    let actions: ReactNode[] = []
    if (user.data.permission_pipeline_progress_create !== "NONE") {
        actions.push(<AddProgress />)
    }

    useEffect(() => {
        if (showUpdateModal && progress) {
            const modalElement = document.getElementById(`update-progress-modal-${progress.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, progress, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal && progress) {
            const modalElement = document.getElementById(`delete-progress-modal-${progress.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, progress, setShowDeleteModal]);

    return (
        <>
            <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'FORM.MENU.PROGRESSES' })}</li>
            </ol>
            <MasterDataTable
                tableKey="lead_progresses"
                tableColumns={tableColumns}
                actions={actions}
                apiURL="lead_progresses"
            />
            {
                showUpdateModal && <UpdateProgress progress={progress!} />
            }
            {
                showDeleteModal && <DeleteProgress progress={progress!} />
            }

        </>
    )
}

export { MasterProgresses }