import { FC, useEffect, useRef, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import useUser from "../../../hooks/User";
import { loginWooblazz } from "../../../api/ChatHelper";
import { useChatStore } from "../../../stores/ChatStore";
import { OTPModal } from "../../OTPModal";
import { Modal } from "bootstrap";

const ToC: FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const user = useUser()

    const { setAgreedTNC } = useChatStore()

    const handleOnClick = async () => {
        try {
            setIsLoading(true)
            const response = await loginWooblazz(user.data.chat_phone)

            if (response.data.success) {
                const modalElement = document.getElementById(`otp-modal-toc`);

                if (modalElement) {
                    const modalInstance = new Modal(modalElement);
                    modalInstance.show();
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setAgreedTNC(user.data.date_chat_tnc_agreed)
    }, [user.data])

    return (
        <div className="container-fluid">

            <div className="text-center">
                <img className="logo-icon-toc img-fluid p-8" src={toAbsoluteUrl('/media/logos/logo-wooblazz-crm-new.png')} alt='WooBlazz CRM Logo' style={{ width: "25%" }} />
            </div>

            <div className="text-center px-md-5">
                <h1 className="mt-3 mt-md-0 mb-3">Syarat dan Ketentuan</h1>
                <p className="text-toc mb-1">Untuk memberikan layanan terbaik, kami memiliki syarat dan ketentuan untuk memandu Anda.</p>
                <p className="text-toc mb-1">Berlaku Efektif Sejak <b>9 Oktober 2023</b>.</p>
            </div>

            <hr className="mx-md-5 mb-4" />

            <div className="px-md-5 pb-2">
                <p>
                    Syarat dan Ketentuan ini merupakan perjanjian antara pengguna (“Anda”) dan PT Kreasi
                    Tunas Digital (“Kami”), yaitu sebuah perseroan terbatas yang didirikan dan beroperasi
                    secara sah berdasarkan hukum Negara Republik Indonesia dan berdomisili di Surabaya,
                    Indonesia. Syarat dan Ketentuan ini mengatur Anda saat mengakses dan menggunakan
                    aplikasi, situs web (https://corporate.wooblazz.com dan situs web lain yang Kami
                    kelola), fitur, teknologi, konten dan produk yang Kami sediakan (selanjutnya, secara
                    Bersama-sama disebut sebagai “Platform”),
                    <br /><br />
                    Harap membaca Syarat dan Ketentuan ini secara seksama sebelum Anda mulai
                    menggunakan Platform Kami, karena peraturan ini berlaku pada penggunaan Anda
                    terhadap Platform Kami. Jika Anda tidak menyetujui Syarat dan Ketentuan ini, Kami
                    berhak untuk menghentikan akses dan penggunaan Anda terhadap Platform Kami.
                </p>
            </div>

            <div className="px-md-5">
                <p>
                    <b>A. PEMBUKAAN AKUN ANDA</b>
                    <br />
                    Sebelum menggunakan Platform, Anda menyetujui Syarat dan Ketentuan ini dan
                    Kebijakan Privasi, dan mendaftarkan diri Anda dengan memberikan informasi yang Kami
                    butuhkan. Saat melakukan pendaftaran, Kami akan meminta Nama, username, dan alamat agar
                    Sistem Kami membuatkan akun pada Platform (“Akun”) untuk Anda yang dapat
                    digunakan. Setelah itu Anda dapat log in dengan username dan password dari kami,
                    Password dapat anda ubah sendiri melalui halaman Profile.
                    <br /><br />
                    Keamanan dan kerahasiaan Akun Anda, termasuk nama terdaftar, riwayat percakapan,
                    kontak whatsapp dan nomor telepon genggam agen yang terdaftar sepenuhnya
                    merupakan tanggung jawab pribadi Anda. Segala kerugian dan risiko yang ada akibat
                    kelalaian Anda dalam menjaga keamanan dan kerahasiaan sebagaimana disebutkan
                    ditanggung oleh Anda. Dengan demikian, Kami akan menganggap setiap penggunaan
                    atau pesanan yang dilakukan melalui Akun Anda sebagai permintaan yang sah dari Anda.
                    Anda harap segera memberitahukan kepada Kami jika Anda mengetahui atau menduga
                    bahwa Akun Anda telah digunakan tanpa sepengetahuan dan persetujuan Anda. Kami
                    akan melakukan tindakan yang Kami anggap perlu dan dapat Kami lakukan terhadap
                    penggunaan tanpa persetujuan tersebut.
                    <br /><br />
                    Pada pembuatan akun setiap Agent dari Platform, User dapat mengakses setiap
                    Percakapan Agent dengan Customer. Jika User ingin menghapus Account Agent, maka
                    Token yang sudah ditransfer ke Agent tidak dapat dikembalikan/ hangus.
                </p>
                <p>
                    <b>B. INFORMASI PRIBADI ANDA</b>
                    <br />
                    Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian informasi pribadi
                    Anda, seperti nama, riwayat percakapan, kontak whatsapp dan nomor telepon genggam
                    agen yang Anda berikan ketika Anda membuka Akun tunduk pada Kebijakan Privasi, yang
                    merupakan bagian yang tidak terpisahkan dari Syarat dan Ketentuan ini.
                </p>
                <p>
                    <b>C. AKSES TERHADAP PLATFORM KAMI</b>
                    <br />
                    Kami tidak menjamin bahwa Platform Kami, maupun konten di dalamnya, akan selalu
                    tersedia atau tanpa gangguan. Izin untuk mengakses Platform Kami bersifat sementara.
                    Kami dapat menangguhkan, menarik, memberhentikan, maupun mengganti bagian mana
                    pun dari Platform Kami tanpa pemberitahuan sebelumnya. Kami tidak bertanggung jawab
                    atas alasan apa pun yang membuat Platform Kami tidak tersedia pada waktu atau periode
                    tertentu.

                </p>
                <p>
                    <b>D. PENGGUNAAN YANG DILARANG</b>
                    <br />
                    Anda hanya diperbolehkan menggunakan Platform kami untuk tujuan-tujuan yang sah
                    menurut hukum. Anda tidak boleh menggunakan Platform Kami:
                    <br />
                    <span style={{ paddingLeft: "10px" }}>
                        <span>1. Dengan cara-cara yang melanggar hukum dan peraturan lokal, nasional, maupun internasional yang berlaku.</span>
                        <span>2. Dengan cara-cara yang melanggar hukum atau menipu, atau memiliki tujuan atau dampak yang melanggar hukum atau menipu.</span>
                        <span>3. Untuk tujuan membahayakan atau mencoba mencelakakan anak di bawah umur dengan cara apa pun.</span>
                        <span>4. Mengirim, secara sadar menerima, mengunggah, mengunduh, menggunakan, atau menggunakan kembali materi yang tidak sesuai dengan standar konten Kami.</span>
                        <span>5. Menyebarkan atau mengirimkan materi iklan atau promosi yang tidak diinginkan atau tidak sah, serta bentuk permintaan serupa lainnya (seperti spam).</span>
                        <span>6. Dengan sengaja meneruskan data, mengirim atau mengunggah materi yang mengandung virus, trojan, worm, logic bomb, keystroke loggers, spyware, adware, maupun program berbahaya lainnya atau kode komputer sejenis yang dirancang untuk memberikan efek merugikan terhadap pengoperasian perangkat lunak atau perangkat keras apa pun.</span>
                        <span>7. Mereproduksi, menggandakan, menyalin, atau menjual kembali bagian mana pun dari Platform Kami yang bertentangan dengan ketentuan dalam Syarat dan Ketentuan Platform Kami.</span>
                        <span>
                            8. Untuk tidak mengakses tanpa izin, mengganggu, merusak, atau mengacak-acak:
                            <br />
                            <span style={{ paddingLeft: "20px" }}>
                                <span>1. Bagian mana pun dari Platform Kami</span>
                                <span>2. Peralatan atau jaringan di mana Platform Kami tersimpan</span>
                                <span>3. Perangkat lunak apa pun yang digunakan dalam penyediaan Platform Kami</span>
                                <span>4. Peralatan atau jaringan atau perangkat lunak yang dimiliki oleh pihak ketiga mana pun</span>
                            </span>
                        </span>
                    </span>
                </p>
                <p>
                    <b>E. HAK KEKAYAAN INTELEKTUAL</b>
                    <br />
                    Platform kami, termasuk namun tidak terbatas pada, nama, logo, kode program, desain,
                    merek dagang, teknologi, basis data, proses dan model bisnis, dilindungi oleh hak cipta,
                    merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum
                    Republik Indonesia yang terdaftar atas nama Kami. Kami memiliki seluruh hak dan
                    kepentingan atas Platform, termasuk seluruh hak kekayaan intelektual terkait dengan
                    seluruh fitur yang terdapat didalamnya dan hak kekayaan intelektual terkait.
                    <br /><br />
                    Anda dapat mengunduh ekstrak dari halaman tertentu dari Platform Kami untuk kegunaan
                    pribadi selama masa berlangganan Anda.
                    <br /><br />
                    Anda tidak boleh mengubah salinan dalam bentuk kertas maupun digital dari materi apa
                    pun yang telah Anda cetak atau unduh dengan cara apa pun, dan Anda tidak boleh
                    menggunakan ilustrasi, foto-foto, potongan video atau audio, maupun grafis lain secara
                    terpisah dari teks pendampingnya.
                </p>
                <p>
                    <b>F. PENGUMPULAN DATA</b>
                    <br />
                    Platform berhak mengumpulkan informasi pengguna pada saat yang bersangkutan
                    mendaftar pada fasilitas Platform, penyediaan informasi apapun oleh pengguna
                    merupakan bagian dari kebutuhan untuk menunjang penggunaan Platform. Platform
                    berhak mengumpulkan, namun tidak terbatas pada mengumpulkan hal-hal sebagai
                    berikut: informasi umum pengguna, seperti nama, nomor telepon agen, kontak whatsappp
                    dan riwayat percakapan. Platform juga berhak mengumpulkan informasi data-data yang
                    diunggah oleh pengguna mengenai produk-produk atau jasa-jasa mereka (termasuk
                    produk, harga, gambar, dan data lainnya).
                </p>
                <p>
                    <b>G. KEAMANAN DATA</b>
                    <br />
                    Platform tidak akan menjual, menyewakan, atau mengungkapkan informasi pribadi
                    maupun data dari pengguna kepada pihak lain (kecuali apabila diwajibkan oleh hukum
                    yang berlaku). Platform hanya akan menggunakan informasi tersebut untuk menyediakan,
                    menjaga, dan meningkatkan layanan dan untuk mengirimkan informasi dan mendukung
                    kebutuhan Platform, termasuk tanda terima, pengingat, dan pesan dukungan serta pesan
                    administratif
                    <br /><br />
                    Platform dapat menggunakan informasi yang dikumpulkan untuk mengirimkan kepada
                    pengguna, berita dan informasi tentang layanan jasa Platform, untuk memberitahukan
                    kepada pengguna mengenai produk, jasa, promosi, dan informasi terbaru yang ditawarkan
                    <br /><br />
                    Platform dan mitra-mitra yang ditunjuknya. Platform dapat mengungkapkan informasi yang
                    dikumpulkannya dalam hal diwajibkan oleh hukum yang berlaku. Sejauh yang
                    diperbolehkan hukum yang berlaku, Platform dapat mengungkapkan informasi tersebut
                    atas permintaan dari lembaga penegak hukum atau badan pemerintah lainnya, atau untuk
                    membuat Platform dapat mengambil tindakan pencegahan atas kemungkinan adanya
                    kerugian dan melindungi integritas dari Platform.
                </p>
            </div>

            <div className="d-flex justify-content-center mb-10">
                <button className={`btn btn-primary me-10 mx-10 ${isLoading ? "disabled" : ""}`} data-kt-indicator={isLoading ? 'on' : 'off'} onClick={handleOnClick}>
                    <span className="indicator-label">
                        Setuju
                    </span>
                    <span className="indicator-progress">
                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
            <OTPModal username={user.data.username} password="" code="" phone={user.data.chat_phone} type="toc" />
        </div>
    )
}

export { ToC }