import { FC, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select, { components } from 'react-select';
import clsx from "clsx";
import { useLogStore } from "../../../stores/LogStore";
import Swal from "sweetalert2";
import useAccessToken from "../../../hooks/AccessToken";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { Progress } from "../../../interfaces/Progress";
import { insertLog, sendReportLog } from "../../../api/LogsCRUD";
import { nanoid } from "@reduxjs/toolkit";
import { useDataTableStore } from "../../../stores/DataTableStore";
import { Pipeline, PipelineAttachment } from "../../../interfaces/Pipeline";
import { RecentChatHistoryPipeline } from "../../../interfaces/Chat/RecentChatHistory";
import { insertLeadAttachment, insertReportLogdAttachment } from "../../../api/ResourceAPI";
import { Modal } from "bootstrap";
import { useChatStore } from "../../../stores/ChatStore";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { handleCaps, handleCapsSelect } from "../../../functions/general";
import useUser from "../../../hooks/User";
import moment from "moment";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { User } from "../../../interfaces/User";
import { SelectOption } from "../../../interfaces/SelectOption";
import { Workbook } from "exceljs";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { exportExcel } from "../../../functions/exportExcel";
import { exportPDF } from "../../../functions/exportPDF";
import { useIntl } from "react-intl";
import useNameColumnExcel from "../../../hooks/NameColumnExcel";
import { usePipelineStore } from "../../../stores/PipelineStore";
import { useMerchantStore } from "../../../stores/MerchantStore";


interface ReportLogProps {
    lead: Pipeline | RecentChatHistoryPipeline
}

interface SelectOptionWithImg {
    value: string
    label: string
    img: string
}

const SendReportLog: FC<ReportLogProps> = ({ lead }) => {
    const { selectMultiPipeline } = usePipelineStore()
    const { selectMultiMerchant } = useMerchantStore()
    const [optionUsers, setOptionUsers] = useState<SelectOptionWithImg[]>([]);
    const [isLoadingSend, setIsLoadingSend] = useState<boolean>(false);
    const [listUsers, setListUsers] = useState<SelectOptionWithImg[]>([]);
    const [typeFile, setTypeFile] = useState<string>("");
    const [nameFile, setNameFile] = useState<string>("");
    const token = useAccessToken()
    const user = useUser()
    const { settings } = useSettingsStore()
    const { filteredTablesData } = useDataTableStore();
    const nameHeader = useNameColumnExcel();

    const [isOpen, setIsOpen] = useState(false);
    const [isEmptyUser, setIsEmptyUser] = useState(false);
    const [isEmptyTypeFile, setIsEmptyTyeFile] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const intl = useIntl()

    function stripHtmlAndExtractLinks(html: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Extract URLs from anchor tags
        const anchors = doc.getElementsByTagName('a');
        const links: string[] = [];
        for (let i = 0; i < anchors.length; i++) {
            const anchor = anchors[i];
            const url = anchor.getAttribute('data-image-url');
            if (url) {
                links.push(`Gambar ${i + 1}: ${url}`);
            }
        }

        // Get the text content (this will remove all HTML tags)
        let text = doc.body.textContent || "";

        // Remove extra whitespace and newlines
        text = text.replace(/\s+/g, ' ').trim();

        // Add extracted links at the beginning
        if (links.length > 0) {
            text = links.join('\n') + '\n\n' + text;
        }

        // Ensure "Product HPP Change :" is on a new line if it exists
        text = text.replace(/Product HPP Change :/, '\nProduct HPP Change :');

        return text;
    }

    const resetField = () => {
        setListUsers([])
        setTypeFile("")
        setIsEmptyUser(false)
        setIsEmptyTyeFile(false)
    }

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleSend = async () => {
        if (listUsers.length === 0) {
            setIsEmptyUser(true)
        }

        if (typeFile === "") {
            setIsEmptyTyeFile(true)
        }

        if (listUsers.length > 0 && typeFile !== "") {
            setIsLoadingSend(true)

            const formattedLogs = filteredTablesData["logs"].map(log => ({
                ...log,
                description: stripHtmlAndExtractLinks(log.description)
            }));

            const excel = await exportExcel(`[${lead.merchant_name}] Log ${settings.pipeline_title}`, "Report Logs Pipeline", settings, nameHeader.export, {}, selectMultiPipeline, selectMultiMerchant, formattedLogs)
            const pdf = exportPDF(`[${lead.merchant_name}] Log ${settings.pipeline_title}`, nameHeader.export, formattedLogs)

            if (typeFile === "excel" && excel) sendReport(excel)
            else sendReport(pdf)
        }
    }

    const sendReport = async (val: string | File) => {
        await Promise.all([insertReportLogdAttachment(val)])
            .then(async (responses) => {
                const responseResource = responses[0].data;

                if (responseResource.success) {
                    const responseSend = await sendReportLog(
                        listUsers.map((item) => item.value),
                        nameFile,
                        responseResource.data[0],
                        typeFile,
                        token
                    );

                    if (responseSend.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: intl.formatMessage({ id: "FORM.VALIDATION.SEND_SUCCESS" }),
                            heightAuto: false,
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: responseSend.data.message,
                            confirmButtonText: 'Ok',
                            heightAuto: false,
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: responseResource.message,
                        confirmButtonText: 'Ok',
                        heightAuto: false,
                    })
                }
            })
            .finally(() => {
                setIsLoadingSend(false)
            });
    }

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const getUserURL = () => {
        return `${API_URL}users?excludingSelf=true`
    }

    const API_URL = process.env.REACT_APP_API_URL
    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(getUserURL(), fetcher)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
                resetField()
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const userData = users.map((user: User) => ({
            value: user.id,
            label: user.name,
            img: user.profile_picture
        }));

        if (!usersLoading) {
            // if (settings.merchant_duplicate && user.data.has_subordinate) {
            userData.unshift({
                value: user.data.id,
                label: user.data.name,
                img: user.data.profile_picture
            })
            // }
            setOptionUsers(userData)
        }
    }, [users, user])

    useEffect(() => {
        if (isOpen) {
            mutate(getUserURL())
        }
    }, [isOpen])

    const CustomOption = (props: any) => (
        <components.Option {...props} >
            {
                props.data.img !== null ?
                    <img
                        src={props.data.img} // Gantilah dengan properti yang menyimpan URL gambar
                        alt={`Avatar - ${props.label}`}
                        style={{ marginRight: '8px', borderRadius: '50%', width: '21px', height: '21px', objectFit: "cover" }}
                    />
                    :
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'gray',
                        marginRight: '8px',
                        borderRadius: '50%',
                        width: '21px',
                        height: '21px',
                        float: "left"
                    }}>
                        <label style={{ color: 'white', fontSize: "9px" }}>
                            {(props.label).toUpperCase().split(' ').slice(0, 2).map((word: string) => word.charAt(0)).join('')}
                        </label>
                    </div>
            }
            {props.label}
        </components.Option>
    );

    return (
        <>
            <div className="m-0" data-select2-id="select2-data-124-2gk6" ref={dropdownRef}>
                <button className="btn btn-flex btn-secondary" onClick={handleToggle}>
                    <KTSVG path="/media/icons/duotune/arrows/arr078.svg" className='svg-icon-2' /> {intl.formatMessage({ id: "FORM.ACTION.SEND" }, { title: intl.formatMessage({ id: "FORM.LABEL.REPORT" }) })}
                </button>

                {isOpen && (
                    <div className="menu menu-sub menu-sub-dropdown w-250px w-md-400px show custom-dropdown" data-kt-menu="true" id="kt_dropdown_send_report" style={{ zIndex: "105", position: "absolute", margin: "0px" }}>
                        <div className="px-7 py-5">
                            <div className="mb-10" data-select2-id="select2-data-123-w1ti">
                                <label className="form-label fw-bold required">{intl.formatMessage({ id: "FORM.LABEL.USER" })}</label>
                                <Select
                                    openMenuOnClick
                                    closeMenuOnSelect={false}
                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.USER" })}`}
                                    isMulti={true}
                                    value={listUsers} // Pass the selected option for the initial value
                                    onChange={(e: any) => { setListUsers(e); setIsEmptyUser(false) }}
                                    options={optionUsers!}
                                    onInputChange={handleCapsSelect}
                                    components={{
                                        Option: CustomOption,
                                        MultiValue: (props) => (
                                            <components.MultiValue {...props} >
                                                {
                                                    props.data.img !== null ?
                                                        <img
                                                            src={props.data.img} // Gantilah dengan properti yang menyimpan URL gambar
                                                            alt={`Avatar - ${props.data.label}`}
                                                            style={{ marginRight: '8px', borderRadius: '50%', width: '21px', height: '21px', objectFit: "cover" }}
                                                        />
                                                        :
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            backgroundColor: 'gray',
                                                            marginRight: '8px',
                                                            borderRadius: '50%',
                                                            width: '21px',
                                                            height: '21px',
                                                            float: "left"
                                                        }}>
                                                            <label style={{ color: 'white', fontSize: "9px" }}>
                                                                {(props.data.label).toUpperCase().split(' ').slice(0, 2).map((word: string) => word.charAt(0)).join('')}
                                                            </label>
                                                        </div>
                                                }
                                                {props.data?.label}
                                            </components.MultiValue>
                                        ),
                                    }}
                                />
                                {
                                    isEmptyUser &&
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">Harus diisi</span>
                                    </div>
                                }
                            </div>
                            <div className="mb-10">
                                <label className="form-label fw-bold required">{intl.formatMessage({ id: "FORM.LABEL.FILE_TYPE" })}</label>
                                <div className="d-flex">
                                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                        <input className="form-check-input" type="radio" value="excel" checked={typeFile === "excel"} onChange={() => { setTypeFile("excel"); setIsEmptyTyeFile(false); setNameFile(`[${lead.merchant_name}] Log Pipeline.xlsx`) }} />
                                        <span className="form-check-label">{intl.formatMessage({ id: "FORM.LABEL.FILE_TYPE.EXCEL" })}</span>
                                    </label>
                                    <label className="form-check form-check-sm form-check-custom form-check-solid">
                                        <input className="form-check-input" type="radio" value="pdf" checked={typeFile === "pdf"} onChange={() => { setTypeFile("pdf"); setIsEmptyTyeFile(false); setNameFile(`[${lead.merchant_name}] Log Pipeline.pdf`) }} />
                                        <span className="form-check-label">{intl.formatMessage({ id: "FORM.LABEL.FILE_TYPE.PDF" })}</span>
                                    </label>
                                </div>
                                {
                                    isEmptyTypeFile &&
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">Harus diisi</span>
                                    </div>
                                }
                            </div>
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn btn-sm btn-light btn-active-light-primary me-2" onClick={() => { setIsOpen(false); resetField() }}>{intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}</button>
                                {/* <button type="submit" className={`btn btn-sm btn-primary ${isLoadingSend ? "disabled" : ""}`} onClick={() => handleSend()}>Kirim</button> */}
                                <button type="submit" onClick={() => handleSend()} className={`btn btn-sm btn-primary ${isLoadingSend ? "disabled" : ""}`} data-kt-indicator={isLoadingSend ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.SEND" }, { title: "" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export { SendReportLog }