import axios from 'axios';
import { MyAttendance } from '../interfaces/Attendance';

const API_URL = process.env.REACT_APP_API_URL;

const INSERT_CLOCK_IN = `${API_URL}user-clock-in`;
const INSERT_CLOCK_OUT = `${API_URL}user-clock-out`;
const INSERT_DESCRIPTION = `${API_URL}user-attendance`;
const INSERT_TEMPLATE = `${API_URL}attendance-template`;
const INSERT_PHOTO_DATA = `https://tool.looyal.id/face/register`;
const INSERT_PHOTO_ATTENDANCE = `https://tool.looyal.id/face/scan-data`;
const GET_REPORT = `${API_URL}report/daily-attendance-recap`;
const GET_LOG = `${API_URL}user-attendance-change-logs`;

export const insertClockIn = async (
  image: string,
  description: string,
  token: string,
  longitude?: number,
  latitude?: number
) => {
  return axios.post(
    INSERT_CLOCK_IN,
    {
      image,
      description,
      longitude,
      latitude,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const insertClockOut = async (
  image: string,
  description: string,
  token: string,
  longitude?: number,
  latitude?: number
) => {
  return axios.post(
    INSERT_CLOCK_OUT,
    {
      image,
      description,
      longitude,
      latitude,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const insertDescriptionClockIn = async (id: string, descriptionClockIn: string, token: string) => {
  return axios.patch(
    INSERT_DESCRIPTION + '/' + id + '/update-description-clock-in',
    {
      descriptionClockIn,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const insertDescriptionClockOut = async (id: string, descriptionClockOut: string, token: string) => {
  return axios.patch(
    INSERT_DESCRIPTION + '/' + id + '/update-description-clock-out',
    {
      descriptionClockOut,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const insertPhotoData = async (name: string, photo: string | File) => {
  const formData = new FormData();

  formData.append('photo', photo);
  formData.append('name', name);

  return axios.post(INSERT_PHOTO_DATA, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertPhotoAttendance = async (name: string, photo: string | File) => {
  const formData = new FormData();

  formData.append('photo', photo);
  formData.append('name', name);

  return axios.post(INSERT_PHOTO_ATTENDANCE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateClockIn = async (
  id: string,
  attendance: MyAttendance,
  dateClockIn: string,
  token: string
) => {
  return axios.patch(
    INSERT_DESCRIPTION + '/' + id,
    {
      ...attendance,
      dateClockIn: dateClockIn,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateClockOut = async (
  id: string,
  attendance: MyAttendance,
  dateClockOut: string,
  token: string
) => {
  return axios.patch(
    INSERT_DESCRIPTION + '/' + id,
    {
      ...attendance,
      dateClockOut: dateClockOut,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const insertTemplateAttendance = async (
  name: string,
  timeClockIn: Array<string | null>,
  timeClockInTolerance: Array<string | null>,
  timeClockOut: Array<string | null>,
  latitudeClockIn: Array<number | null>,
  longitudeClockIn: Array<number | null>,
  latitudeClockOut: Array<number | null>,
  longitudeClockOut: Array<number | null>,
  radiusClockIn: Array<number | null>,
  radiusClockOut: Array<number | null>,
  addressClockIn: string,
  addressClockOut: string,
  token: string
) => {
  return axios.post(
    INSERT_TEMPLATE,
    {
      name,
      timeClockIn,
      timeClockInTolerance,
      timeClockOut,
      latitudeClockIn,
      longitudeClockIn,
      latitudeClockOut,
      longitudeClockOut,
      radiusClockIn,
      radiusClockOut,
      addressClockIn,
      addressClockOut,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const deleteTemplateAttendance = async (id: string, token: string) => {
  return axios.delete(INSERT_TEMPLATE + '/' + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const updateTemplateAttendance = async (
  id: string,
  name: string,
  timeClockIn: Array<string | null>,
  timeClockInTolerance: Array<string | null>,
  timeClockOut: Array<string | null>,
  latitudeClockIn: Array<number | null>,
  longitudeClockIn: Array<number | null>,
  latitudeClockOut: Array<number | null>,
  longitudeClockOut: Array<number | null>,
  radiusClockIn: Array<number | null>,
  radiusClockOut: Array<number | null>,
  addressClockIn: string,
  addressClockOut: string,
  token: string
) => {
  return axios.patch(
    INSERT_TEMPLATE + '/' + id,
    {
      name,
      timeClockIn,
      timeClockInTolerance,
      timeClockOut,
      latitudeClockIn,
      longitudeClockIn,
      latitudeClockOut,
      longitudeClockOut,
      radiusClockIn,
      radiusClockOut,
      addressClockIn,
      addressClockOut,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const approveClockIn = async (
  id: string,
  attendance: MyAttendance,
  isPermittedClockIn: boolean,
  token: string
) => {
  return axios.patch(
    INSERT_DESCRIPTION + '/' + id,
    {
      ...attendance,
      isPermittedClockIn: isPermittedClockIn,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const approveClockOut = async (
  id: string,
  attendance: MyAttendance,
  isPermittedClockOut: boolean,
  token: string
) => {
  return axios.patch(
    INSERT_DESCRIPTION + '/' + id,
    {
      ...attendance,
      isPermittedClockOut: isPermittedClockOut,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const getDailyAttendance = async (dateStart: string, dateEnd: string, token: string) => {
  return axios.get(GET_REPORT + '?dateStartReport=' + dateStart + '&dateEndReport=' + dateEnd, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const getLogAttendance = async (userAttendanceId: string, token: string) => {
  return axios.get(GET_LOG + '?userAttendanceId=' + userAttendanceId, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const getHolidays = async (year: string) => {
  // return axios.get('https://dayoffapi.vercel.app/api?year=' + year);

  return fetch('https://dayoffapi.vercel.app/api?year=' + year)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => console.warn(error));
};
