import { FC, useEffect, useState } from "react";
import useUser from "../../../hooks/User";
import { useChatStore } from "../../../stores/ChatStore";
import { nanoid } from "@reduxjs/toolkit";
import moment from "moment";
import { Message } from "../../../interfaces/Chat/Message";
import { FileAttachment, MediaAttachment } from "../../../interfaces/Chat/MessageData";
import { fetchBlob, getFileType, isFile, isImage, isVideo } from "../../../functions/general";
import { insertChatAttachment } from "../../../api/ResourceAPI";
import Swal from "sweetalert2";
import { ChatAttachmentPreview } from "./ChatAttachmentPreview";
import EmojiPicker from "emoji-picker-react";
import { QuickReply } from "../../../interfaces/Chat/QuickReply";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { MediaDownload } from "../../../interfaces/Chat/MediaDownload";
import { ReplacementChat } from "../../../interfaces/Chat/ReplacementChat";

const ChatInput: FC = () => {

    const user = useUser()

    const { socket, message, setMessage,
        imageBlobUrls, setImageBlobUrls, videoBlobUrls,
        setVideoBlobUrls, fileBlobUrls, setFileBlobUrls,
        phoneNumber, mediaDownloads, setMediaDownloads,
        messages, setMessages, editMessage,
        replyMessage, setReplyMessage, temporaryChatIds,
        setTemporaryChatIds, replacedChatIds, setReplacedChatIds,
        replacementChat, setReplacementChat, quickReplies,
        setQuickReplies
    } = useChatStore()

    const [isUploadingAttachment, setIsUploadingAttachment] = useState<boolean>(false)
    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false)
    const [isQuickReplyVisible, setIsQuickReplyVisible] = useState<boolean>(false)
    const [filteredQuickReplies, setFilteredQuickReplies] = useState<QuickReply[]>(quickReplies);
    const [textareaRows, setTextareaRows] = useState(1);

    // Adjust the rows dynamically based on the message content length
    const adjustTextareaRows = (text: string) => {
        const lineCount = text.split("\n").length;
        setTextareaRows(Math.min(6, Math.max(1, lineCount))); // limit rows between 1 and 6
    };

    const sendMessage = async (attachment?: MediaAttachment | FileAttachment, attachmentMessage?: string) => {
        setIsQuickReplyVisible(false)
        if (replyMessage) {
            socket.emit("replyChat", {
                receiver_phone: phoneNumber,
                id: replyMessage.id!,
                message: replyMessage.text,
                reply_message: message,
                is_from_me: replyMessage.is_from_me
            })
        }
        if (message.trim() === '' && !attachment) return;

        if ((message || attachment) && !isUploadingAttachment && !replyMessage) {
            const temporaryMessageId = nanoid()

            const newMessage: Message = {
                id: temporaryMessageId,
                phone: user.data.chat_phone,
                name: '',
                type: 'out',
                text: attachmentMessage ?? message,
                time: moment().format('YYYY-MM-DD HH:mm:ss'),
                attachment: attachment?.url,
                attachment_name: attachment?.type === 'file' ? attachment.name : '',
                is_editing: false,
                is_replying: false,
                is_from_me: 1,
                sender: {
                    id: user.data.id,
                    name: user.data.name
                }
            }

            setTemporaryChatIds((prev) => [...prev, temporaryMessageId])

            setMediaDownloads((prevMediaDownloads) => ({
                ...prevMediaDownloads,
                [temporaryMessageId]: {
                    isDownloading: false,
                    isDownloaded: true,
                    url: newMessage.attachment!,
                },
            }));

            // setMessages((prev) => [...prev, newMessage])
            setMessage('')
            setTextareaRows(1)

            socket.emit("sendMessage", {
                receiver_phone: phoneNumber,
                attachment: attachment,
                message: attachmentMessage ?? message
            }, (message_id: string | null) => {
                if (message_id) {
                    try {
                        setMessages((prev) => [...prev, { ...newMessage, id: message_id }])
                        setReplacedChatIds((prevReplacedChatIds) => [...prevReplacedChatIds, message_id]);
                        setReplacementChat((prevReplacementChat) => ({
                            ...prevReplacementChat,
                            [temporaryMessageId!]: message_id,
                        }));

                        setMediaDownloads((prevMediaDownloads: MediaDownload) => {
                            const { [temporaryMessageId!]: removed, ...filteredMediaDownloads } = prevMediaDownloads;

                            return {
                                ...filteredMediaDownloads,
                                [message_id!]: {
                                    isDownloading: false,
                                    isDownloaded: true,
                                    url: newMessage.attachment!,
                                }
                            };
                        });

                        setTemporaryChatIds((prev) => prev.filter((id) => id !== temporaryMessageId));
                    } catch (stateUpdateError) {
                        console.error("Error saat memperbarui state:", stateUpdateError);
                        // Bisa menambahkan notifikasi atau log error di sini
                    }
                } else {
                    throw new Error("Message ID tidak diterima");
                }
            });
        }
    }

    const uploadAttachment = async () => {
        if (!isUploadingAttachment) {
            var listUrlAttachment: any[] = []
            setIsUploadingAttachment(true)

            if (imageBlobUrls[phoneNumber] && imageBlobUrls[phoneNumber].blobs.length > 0) {
                try {
                    setImageBlobUrls({
                        ...imageBlobUrls,
                        [phoneNumber]: {
                            ...imageBlobUrls[phoneNumber],
                            isUploading: true,
                        },
                    });

                    await Promise.all(imageBlobUrls[phoneNumber].blobs.map(async (imageUrl, index) => {
                        try {
                            // Fetch the image blob and filename
                            const { blob, fileName } = await fetchBlob(imageUrl);

                            // Convert the Blob to a File
                            const file = new File([blob], fileName, { type: blob.type });

                            // Now you can use the 'file' object for your operations
                            const resourceResponse = await insertChatAttachment(file);

                            if (resourceResponse.status === 200) {
                                // remove the image from the array
                            }

                            const newMediaAttachment: MediaAttachment = {
                                type: 'image',
                                url: resourceResponse.data.data[0].url,
                            }
                            listUrlAttachment.push(newMediaAttachment)
                            // if (index === imageBlobUrls[phoneNumber].blobs.length - 1) {
                            //     sendMessage(newMediaAttachment, message)
                            // } else {
                            //     sendMessage(newMediaAttachment, '')
                            // }
                        } catch (error) {
                            console.error('Error uploading image:', imageUrl, error);
                        } finally {
                            // setImageBlobUrls({
                            //     ...imageBlobUrls,
                            //     [phoneNumber]: {
                            //         blobs: [],
                            //         isUploading: false,
                            //     },
                            // });
                            setIsUploadingAttachment(false)
                        }
                    }));
                } catch (error) {
                    console.log(error);
                }
            }

            if (videoBlobUrls[phoneNumber] && videoBlobUrls[phoneNumber].blobs.length > 0) {
                try {
                    setVideoBlobUrls({
                        ...videoBlobUrls,
                        [phoneNumber]: {
                            ...videoBlobUrls[phoneNumber],
                            isUploading: true,
                        },
                    });

                    await Promise.all(videoBlobUrls[phoneNumber].blobs.map(async (videoUrl, index) => {
                        try {
                            // Fetch the video blob and filename
                            const { blob, fileName } = await fetchBlob(videoUrl);

                            // Convert the Blob to a File
                            const file = new File([blob], fileName, { type: blob.type });

                            // Now you can use the 'file' object for your operations
                            const resourceResponse = await insertChatAttachment(file);

                            if (resourceResponse.status === 200) {
                                // remove the image from the array
                            }

                            const newMediaAttachment: MediaAttachment = {
                                type: 'video',
                                url: resourceResponse.data.data[0].url,
                            }
                            listUrlAttachment.push(newMediaAttachment)
                            // if (index === videoBlobUrls[phoneNumber].blobs.length - 1) {
                            //     sendMessage(newMediaAttachment, message)
                            // } else {
                            //     sendMessage(newMediaAttachment, '')
                            // }
                        } catch (error) {
                            console.error('Error uploading video:', videoUrl, error);
                        } finally {
                            // setVideoBlobUrls({
                            //     ...videoBlobUrls,
                            //     [phoneNumber]: {
                            //         blobs: [],
                            //         isUploading: false,
                            //     },
                            // });
                            setIsUploadingAttachment(false)
                        }
                    }));
                } catch (error) {
                    console.log(error);
                }
            }

            if (fileBlobUrls[phoneNumber] && fileBlobUrls[phoneNumber].blobs.length > 0) {
                try {
                    setFileBlobUrls({
                        ...fileBlobUrls,
                        [phoneNumber]: {
                            ...fileBlobUrls[phoneNumber],
                            isUploading: true,
                        },
                    });

                    await Promise.all(fileBlobUrls[phoneNumber].blobs.map(async (fileBlob, index) => {
                        try {
                            // Fetch the file blob and filename
                            const { blob } = await fetchBlob(fileBlob.blob);

                            // Convert the Blob to a File
                            const file = new File([blob], fileBlob.name, { type: blob.type });

                            // Now you can use the 'file' object for your operations
                            const resourceResponse = await insertChatAttachment(file);

                            if (resourceResponse.status === 200) {
                                // remove the image from the array
                            }

                            const newFileAttachment: FileAttachment = {
                                type: 'file',
                                url: resourceResponse.data.data[0].url,
                                name: fileBlob.name,
                                mimetype: resourceResponse.data.data[0].filemimetype,
                            }
                            listUrlAttachment.push(newFileAttachment)
                            // if (index === fileBlobUrls[phoneNumber].blobs.length - 1) {
                            //     sendMessage(newFileAttachment, message)
                            // } else {
                            //     sendMessage(newFileAttachment, '')
                            // }
                        } catch (error) {
                            console.error('Error uploading file:', fileBlob.name, error);
                        } finally {
                            // setFileBlobUrls({
                            //     ...fileBlobUrls,
                            //     [phoneNumber]: {
                            //         blobs: [],
                            //         isUploading: false,
                            //     },
                            // });
                            setIsUploadingAttachment(false)
                        }
                    }));
                } catch (error) {
                    console.log(error);
                }
            }

            if (listUrlAttachment.length > 0) {
                await Promise.all(listUrlAttachment.map((attachment, index) => {
                    sendMessage(attachment, listUrlAttachment.length === index + 1 ? message : '')
                }))

                setImageBlobUrls({
                    ...imageBlobUrls,
                    [phoneNumber]: {
                        blobs: [],
                        isUploading: false,
                    },
                });

                setVideoBlobUrls({
                    ...videoBlobUrls,
                    [phoneNumber]: {
                        blobs: [],
                        isUploading: false,
                    },
                });

                setFileBlobUrls({
                    ...fileBlobUrls,
                    [phoneNumber]: {
                        blobs: [],
                        isUploading: false,
                    },
                });
            } else {
                sendMessage()
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>, editedMessage?: Message) => {
        if (e.key === '/') {
            setIsQuickReplyVisible(true)
        } else if (isQuickReplyVisible && e.key === " ") {
            setIsQuickReplyVisible(false)
        }

        // Check if Ctrl key is pressed and the key is 'b'
        if ((e.ctrlKey && e.key.toLowerCase() === 'b') || (e.metaKey && e.key.toLowerCase() === 'b')) {
            // Prevent the default behavior of the browser
            e.preventDefault();

            // Get the selected text
            const selectedText = window.getSelection()?.toString();

            // If there is selected text, replace the selected text with '*'
            if (selectedText) {
                const updatedMessage = message.replace(selectedText, `*${selectedText}*`);
                setMessage(updatedMessage);
            } else {
                // If no text is selected, insert '*' at the current cursor position
                const cursorPosition = e.currentTarget.selectionStart || 0;
                const newMessage =
                    message.slice(0, cursorPosition) + '**' + message.slice(cursorPosition);

                setMessage(newMessage);

                // Adjust the cursor position after inserting '*'
                e.currentTarget.setSelectionRange(cursorPosition + 2, cursorPosition + 2);
            }
        } else if ((e.ctrlKey && e.key.toLowerCase() === 'i') || (e.metaKey && e.key.toLowerCase() === 'i')) {
            // Prevent the default behavior of the browser
            e.preventDefault();

            // Get the selected text
            const selectedText = window.getSelection()?.toString();

            // If there is selected text, replace the selected text with '_'
            if (selectedText) {
                const updatedMessage = message.replace(selectedText, `_${selectedText}_`);
                setMessage(updatedMessage);
            } else {
                // If no text is selected, insert '_' at the current cursor position
                const cursorPosition = e.currentTarget.selectionStart || 0;
                const newMessage =
                    message.slice(0, cursorPosition) + '__' + message.slice(cursorPosition);

                setMessage(newMessage);

                // Adjust the cursor position after inserting '_*'
                e.currentTarget.setSelectionRange(cursorPosition + 2, cursorPosition + 2);
            }
        }

        // Call your existing onKeyDown logic if needed
        if (editedMessage) {
            onEnterPress(e, editedMessage);
        } else {
            onEnterPress(e);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputText = e.target.value;
        setMessage(inputText);
        adjustTextareaRows(inputText); // Adjust rows as user types

        // Find the last occurrence of "/" anywhere in the message
        const lastSlashIndex = inputText.lastIndexOf("/");

        if (lastSlashIndex !== -1) {
            // Extract the keyword after the last "/"
            const shortcut = inputText
                .slice(lastSlashIndex + 1)
                .split(" ")[0] // Stop at the next space to isolate the shortcut
                .trim()
                .toLowerCase(); // Convert shortcut to lowercase

            // Filter quick replies based on the lowercase shortcut
            const matches = quickReplies.filter((reply) =>
                reply.shortcut.toLowerCase().startsWith(shortcut) // Convert reply.shortcut to lowercase
            );

            // Set the filtered replies and show dropdown if matches are found
            setFilteredQuickReplies(matches);
            setIsQuickReplyVisible(matches.length > 0);
        } else {
            // Hide dropdown if there's no "/"
            setIsQuickReplyVisible(false);
        }
    };

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            // Create an array to store blob URLs
            const newImageBlobUrls: string[] = [];
            const newVideoBlobUrls: string[] = [];
            const newFileBlobUrls: { name: string, blob: string }[] = [];

            // Loop through each file
            for (const file of files) {
                // Check if the file is an image (you can modify this check based on your criteria)
                if (file.type.startsWith('image/')) {
                    const blobUrl = URL.createObjectURL(file);
                    newImageBlobUrls.push(blobUrl);
                } else if (file.type.startsWith('video/')) {
                    const blobUrl = URL.createObjectURL(file);
                    newVideoBlobUrls.push(blobUrl);
                } else {
                    const blobUrl = URL.createObjectURL(file);
                    const name = file.name;
                    newFileBlobUrls.push({ name, blob: blobUrl });
                }
            }

            handleSetAttachment(newImageBlobUrls, newVideoBlobUrls, newFileBlobUrls);
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const items = e.clipboardData?.items;

        if (items) {
            const newImageBlobUrls: string[] = [];
            const newVideoBlobUrls: string[] = [];
            const newFileBlobUrls: { name: string, blob: string }[] = [];

            for (const item of items) {
                if (item.type.indexOf('image') !== -1) {
                    const blob = item.getAsFile();

                    if (blob) {
                        const blobUrl = URL.createObjectURL(blob);
                        newImageBlobUrls.push(blobUrl);
                    }
                } else if (item.type.indexOf('video') !== -1) {
                    const blob = item.getAsFile();

                    if (blob) {
                        const blobUrl = URL.createObjectURL(blob);
                        newVideoBlobUrls.push(blobUrl);
                    }
                } else {
                    const blob = item.getAsFile();

                    if (blob) {
                        const blobUrl = URL.createObjectURL(blob);
                        const name = blob.name;
                        newFileBlobUrls.push({ name, blob: blobUrl });
                    }
                }
            }

            if (newImageBlobUrls.length > 0 || newVideoBlobUrls.length > 0) {
                e.preventDefault()
            }

            handleSetAttachment(newImageBlobUrls, newVideoBlobUrls, newFileBlobUrls);
        }
    };

    const handleAttachmentQuickReply = (dataQuickReply: QuickReply) => {
        if (dataQuickReply.attachments) {
            const newImageBlobUrls: string[] = [];
            const newVideoBlobUrls: string[] = [];
            const newFileBlobUrls: { name: string, blob: string }[] = [];

            dataQuickReply.attachments.map((attachment) => {
                if (attachment.type === 'image') {
                    newImageBlobUrls.push(attachment.url);
                } else if (attachment.type === 'video') {
                    newVideoBlobUrls.push(attachment.url);
                } else {
                    newFileBlobUrls.push({ name: attachment.name, blob: attachment.url });
                }
            })

            handleSetAttachment(newImageBlobUrls, newVideoBlobUrls, newFileBlobUrls);
        }
    }

    const handleSetAttachment = (newImageBlobUrls: string[], newVideoBlobUrls: string[], newFileBlobUrls: { name: string, blob: string }[]) => {
        if (!imageBlobUrls[phoneNumber]) {
            setImageBlobUrls({
                ...imageBlobUrls,
                [phoneNumber]: {
                    isUploading: false,  // Add this line if needed
                    blobs: newImageBlobUrls,
                },
            });
        } else {
            setImageBlobUrls({
                ...imageBlobUrls,
                [phoneNumber]: {
                    ...imageBlobUrls[phoneNumber],
                    blobs: [...imageBlobUrls[phoneNumber].blobs, ...newImageBlobUrls],
                },
            });
        }

        if (!videoBlobUrls[phoneNumber]) {
            setVideoBlobUrls({
                ...videoBlobUrls,
                [phoneNumber]: {
                    isUploading: false,  // Add this line if needed
                    blobs: newVideoBlobUrls,
                },
            });
        } else {
            setVideoBlobUrls({
                ...videoBlobUrls,
                [phoneNumber]: {
                    ...videoBlobUrls[phoneNumber],
                    blobs: [...videoBlobUrls[phoneNumber].blobs, ...newVideoBlobUrls],
                },
            });
        }

        if (!fileBlobUrls[phoneNumber]) {
            setFileBlobUrls({
                ...fileBlobUrls,
                [phoneNumber]: {
                    isUploading: false,  // Add this line if needed
                    blobs: newFileBlobUrls,
                },
            });
        } else {
            setFileBlobUrls({
                ...fileBlobUrls,
                [phoneNumber]: {
                    ...fileBlobUrls[phoneNumber],
                    blobs: [...fileBlobUrls[phoneNumber].blobs, ...newFileBlobUrls],
                },
            });
        }
    }

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>, editedMessage?: Message) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            if ((
                (imageBlobUrls[phoneNumber] && imageBlobUrls[phoneNumber].blobs.length > 0) ||
                (videoBlobUrls[phoneNumber] && videoBlobUrls[phoneNumber].blobs.length > 0) ||
                (fileBlobUrls[phoneNumber] && fileBlobUrls[phoneNumber].blobs.length > 0)) &&
                !isUploadingAttachment
            ) {
                uploadAttachment()
            } else if (!isUploadingAttachment && !editedMessage && !replyMessage) {
                sendMessage()
            } else if (editedMessage) {
                Swal.fire({
                    title: 'Mohon tunggu',
                    html: 'Sedang mengedit pesan',
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })
                socket.emit("editChat", {
                    receiver_phone: phoneNumber,
                    id: editedMessage.id!,
                    message: editMessage
                })
            } else if (replyMessage) {
                socket.emit("replyChat", {
                    receiver_phone: phoneNumber,
                    id: replyMessage.id!,
                    message: replyMessage.text,
                    reply_message: message,
                    is_from_me: replyMessage.is_from_me
                })
            }
        }
    }

    const onEmojiClick = (emojiObject: any) => {
        setMessage(message + emojiObject.emoji);
    };

    const handleCancelReplyChat = () => {
        setReplyMessage(undefined)
        setMessages((prev) => prev.map((message) => {
            return {
                ...message,
                is_replying: false,
            }
        }))
    }

    return (
        <>
            {
                (imageBlobUrls[phoneNumber] || videoBlobUrls[phoneNumber] || fileBlobUrls[phoneNumber]) &&
                <div className='mb-5 overflow-auto d-flex' style={{ whiteSpace: 'nowrap' }}>
                    {
                        imageBlobUrls[phoneNumber].blobs.map((blobUrl, index) => (
                            <ChatAttachmentPreview key={index} index={index} blobUrl={blobUrl} type='image' />
                        ))}
                    {
                        videoBlobUrls[phoneNumber].blobs.map((blobUrl, index) => (
                            <ChatAttachmentPreview key={index} index={index} blobUrl={blobUrl} type='video' />
                        ))
                    }
                    {
                        fileBlobUrls[phoneNumber].blobs.map((blob, index) => (
                            <ChatAttachmentPreview index={index} blobUrl={blob.blob} type='file' />
                        ))
                    }
                </div>
            }

            {
                replyMessage &&
                <div className="chat-reply bg-secondary p-3 rounded d-flex justify-content-between">
                    <div id='reply-content'>
                        <p id='reply-header' className='fw-bolder'>
                            <p>
                                {
                                    replyMessage.phone === user.data.chat_phone ?
                                        'You'
                                        :
                                        replyMessage.name
                                }
                            </p>
                            {
                                (
                                    replyMessage.attachment_name!.endsWith('.jpg') ||
                                    replyMessage.attachment_name!.endsWith('.png') ||
                                    replyMessage.attachment_name!.endsWith('.jpeg')
                                ) ?
                                    <span>
                                        <i className="fas fa-image me-1" />
                                        {!replyMessage.text && replyMessage.attachment_name}
                                    </span>
                                    :
                                    (
                                        replyMessage.attachment_name!.endsWith('.mp4') ||
                                        replyMessage.attachment_name!.endsWith('.mov')) ?
                                        <span>
                                            <i className="fas fa-video me-1" />
                                            {!replyMessage.text && replyMessage.attachment_name}
                                        </span>
                                        :
                                        replyMessage.attachment_name &&
                                        <span>
                                            <i className="fas fa-file me-1" />
                                            {!replyMessage.text && replyMessage.attachment_name}
                                        </span>
                            }
                        </p>
                        <p id='reply-text'>
                            {replyMessage.text}
                        </p>
                    </div>
                    <span role="button" onClick={handleCancelReplyChat}>
                        <i className="fas fa-times-circle fa-lg m-2 text-danger"></i>
                    </span>
                </div>
            }

            {isQuickReplyVisible && (
                <div className="quick-reply-dropdown">
                    {filteredQuickReplies.map((reply, index) => (
                        <div
                            key={index}
                            className="quick-reply-option"
                            onClick={() => {
                                // Find the last index of "/"
                                const lastSlashIndex = message.lastIndexOf("/");

                                const [, ...newText] = message.slice(lastSlashIndex + 1, message.length).split(" ")
                                const newMessage = message.slice(0, lastSlashIndex) + reply.message + " " + newText;

                                // Set the updated message
                                handleAttachmentQuickReply(reply);
                                setMessage(newMessage);
                                setIsQuickReplyVisible(false);
                                adjustTextareaRows(newMessage); // Adjust rows after selecting a quick reply
                            }}
                        >
                            <div className="d-flex gap-2">
                                <span className="fw-bolder">{reply.shortcut} -</span>
                                {
                                    reply.attachments &&
                                    reply.attachments.map((attachment, index) => (
                                        isFile(attachment?.url) ?
                                            <div className="position-relative">
                                                {
                                                    getFileType(attachment?.url!) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} className="mb-2" alt={attachment?.name!} style={{ width: '20px', height: '20px', objectFit: 'cover' }} />
                                                        : getFileType(attachment?.url!) === "doc" || getFileType(attachment?.url!) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} className="mb-2" alt={attachment?.name!} style={{ width: '20px', height: '20px', objectFit: 'cover' }} />
                                                            : getFileType(attachment?.url!) === "ppt" || getFileType(attachment?.url!) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} className="mb-2" alt={attachment?.name!} style={{ width: '20px', height: '20px', objectFit: 'cover' }} />
                                                                : getFileType(attachment?.url!) === "xlsx" || getFileType(attachment?.url!) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} className="mb-2" alt={attachment?.name!} style={{ width: '20px', height: '20px', objectFit: 'cover' }} />
                                                                    : <img src={toAbsoluteUrl('/media/logos/file.png')} className="mb-2" alt={attachment?.name!} style={{ width: '20px', height: '20px', objectFit: 'cover' }} />
                                                }
                                            </div>
                                            :
                                            isVideo(attachment?.url) ?
                                                <div className="position-relative">
                                                    <img src={toAbsoluteUrl('/media/logos/video.png')} className="mb-2" alt={attachment?.name!} style={{ width: '20px', height: '20px', objectFit: 'cover' }} />
                                                </div>
                                                :
                                                <div className="position-relative">
                                                    <img src={attachment?.url} className="rounded mb-2" alt={attachment?.name!} style={{ width: '20px', height: '20px', objectFit: 'cover' }} />
                                                </div>
                                    ))
                                }
                                <span>{reply.message.length > 100 ? reply.message.slice(0, 100) + '...' : reply.message}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <textarea
                style={{ resize: 'none', maxHeight: '200px', overflowY: 'auto' }}
                id='chat-input'
                className='form-control form-control-flush mb-3'
                rows={textareaRows}
                data-kt-element='input'
                placeholder='Type a message'
                value={message}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
            ></textarea>

            <div className='d-flex flex-stack'>
                <div className='d-flex align-items-center me-2'>
                    <div>
                        <label htmlFor="fileInput" className="btn btn-sm btn-icon btn-active-light-primary me-1">
                            <i className='bi bi-paperclip fs-1'></i>
                        </label>
                        <input
                            type="file"
                            id="fileInput"
                            multiple
                            style={{ display: 'none' }}
                            onChange={(event) => {
                                handleFileInputChange(event); // Panggil handler Anda
                                event.target.value = ""; // Reset nilai input untuk memungkinkan memilih file yang sama
                            }}
                        // accept only image and video files
                        />
                    </div>
                    <label className="btn btn-sm btn-icon btn-active-light-primary me-1" onClick={() => {
                        setShowEmojiPicker(!showEmojiPicker)
                    }}>
                        <i className="fas fa-smile fs-1" />
                    </label>
                    <div className="position-absolute" style={{ bottom: "20%" }}>
                        <EmojiPicker onEmojiClick={onEmojiClick} open={showEmojiPicker} />
                    </div>
                </div>
                <button
                    className='btn btn-primary'
                    type='button'
                    data-kt-element='send'
                    onClick={() => {
                        if (
                            (imageBlobUrls[phoneNumber] && imageBlobUrls[phoneNumber].blobs.length > 0) || 
                            (videoBlobUrls[phoneNumber] && videoBlobUrls[phoneNumber].blobs.length > 0) || 
                            (fileBlobUrls[phoneNumber] && fileBlobUrls[phoneNumber].blobs.length > 0)
                        ) {
                            uploadAttachment()
                        } else {
                            sendMessage()
                        }
                    }}
                    disabled={isUploadingAttachment}
                >
                    Send
                </button>
            </div>

            {/* {
                showAttachmentModal && <ChatAttachmentModal messageId={messageId} />
            } */}
        </>
    )
}

export { ChatInput }