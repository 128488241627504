import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import moment from 'moment';
import 'moment-duration-format';
import { UserModel } from '../app/modules/auth/models/UserModel';
import { CustomField, SettingMultiMerchants } from '../interfaces/Settings';
import { User } from '../interfaces/User';
import { useCustomeStore } from '../stores/CustomeStore';

export const exportTemplateExcel = async (
  nameFile: string,
  source: string,
  typeAction: string,
  settings: any,
  nameInfo: string,
  nameHeader: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  user?: UserModel,
  param?: any,
  type?: string,
  selectTemplateMerchant?: any,
) => {
  let exportData: any[] = [];

  var arrayCustomFieldMerchants: CustomField = selectTemplateMerchant.customFields ? selectTemplateMerchant.customFields  : [];
  var arrayCustomFieldUsers: CustomField = settings.users_custom_fields ? JSON.parse(settings.users_custom_fields) : {};

  let singleOptionSheetsMerchants = {};
  let singleOptionSheetsUsers = {};

  let filteredCustomFieldUsers = Object.fromEntries(
    Object.entries(arrayCustomFieldUsers).filter(([key, value]) => {
      return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial';
    })
  );
  let filteredCustomFieldMerchants = Object.fromEntries(
    Object.entries(arrayCustomFieldMerchants).filter(([key, value]) => {
      return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial';
    })
  );

  const workbook = createWorkbook();
  const worksheet = createWorksheet(workbook, "Sheet1");
  const worksheetInfo = createWorksheet(workbook, "Info");
  var header: string[] = []
  var subHeader: string[] = []

  // Function to create single option worksheets dynamically
  const createSingleOptionWorksheets = (customFields: CustomField) => {
    let singleOptionSheets: { [key: string]: string } = {};
    let usedNames = new Set<string>();

    Object.entries(customFields).forEach(([key, field]) => {
      if (field.type === 'option') {
        let baseWorksheetName = `Daftar ${field?.name?.replace(/\s+/g, ' ')}`;
        let worksheetName = baseWorksheetName;
        let counter = 1;

        while (usedNames.has(worksheetName.toLowerCase())) {
          worksheetName = `${baseWorksheetName} (${counter})`;
          counter++;
        }

        usedNames.add(worksheetName.toLowerCase());

        let singleOptionSheet = workbook.addWorksheet(worksheetName);
        singleOptionSheet.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
          formatColumns: true,
          autoFilter: true,
        });
        singleOptionSheet.getCell('A1').value = `${field.name}`;

        // Cek apakah 'field.value' adalah array of string atau array of TypeSerial
        if (Array.isArray(field.value) && typeof field.value[0] === 'string') {
          // Jika field.value adalah string[]
          (field.value as string[]).forEach((option: string, index: number) => {
            singleOptionSheet.getCell(`A${index + 2}`).value = option;
          });
        }
        singleOptionSheets[key] = worksheetName;
      }
    });
    return singleOptionSheets;
  };

  if (source === 'template-merchant') {
    header = [
      ...nameHeader.insert.merchant.header,
      ...Object.keys(filteredCustomFieldMerchants).map((field: any) => filteredCustomFieldMerchants[field].name),
    ];

    subHeader = [
      ...nameHeader.insert.merchant.subHeader,
    ];

    let worksheetUser = workbook.addWorksheet('Daftar User');
    await worksheetUser.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetUser.getCell('A1').value = `Daftar User`;
    await param.dataUsers.forEach((user: any) => {
      worksheetUser.addRow([user.name]);
    });

    let worksheetCity = workbook.addWorksheet('Daftar Kota');
    await worksheetCity.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetCity.getCell('A1').value = `Daftar Kota`;
    await param.dataCities.forEach((user: any) => {
      worksheetCity.addRow([user.name]);
    });
    // Daftar Single Option
    singleOptionSheetsMerchants = createSingleOptionWorksheets(filteredCustomFieldMerchants);

    if (type === 'error') {
      header.push('Error')
      subHeader.push(nameHeader.error)
      Array.from(param.listErrorInsert).map((row: any, index) => {
        var temp_excel = [];
        temp_excel.push(
          row.name,
          row.pic_name,
          row.city_name,
          row.phone,
          row.email,
          ...Object.keys(filteredCustomFieldMerchants).map((field: any) =>
            row[field]
          ),
        );

        // Tambahkan kolom error di akhir
        temp_excel.push(row.error);

        exportData.push(temp_excel);
      });
    }

    worksheet.getRow(1).values = header
    worksheet.getRow(2).values = subHeader
    setColumnWidths(worksheet, source, header!, exportData);
    styleColumns(worksheet, source, 1);
    styleColumns(worksheet, source, 2);
    addDataRows(worksheet, exportData, source, type!, typeAction, user!, param, settings, singleOptionSheetsMerchants,selectTemplateMerchant);
    addWorkSheetInfo(worksheetInfo, source, nameFile, nameInfo, nameHeader, user!);
  } else if (source === 'template-user') {
    // Data City
    let worksheetCity = workbook.addWorksheet('Daftar Kota');
    await worksheetCity.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetCity.getCell('A1').value = `Daftar Kota`;
    await param.dataCities.forEach((user: any) => {
      worksheetCity.addRow([user.name]);
    });

    // Data Role
    let worksheetRole = workbook.addWorksheet('Daftar Role');
    await worksheetRole.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetRole.getCell('A1').value = `Daftar Role`;
    await param.dataRoles.forEach((user: any) => {
      worksheetRole.addRow([user.name]);
    });

    // Data Leader
    let worksheetLeader = workbook.addWorksheet('Daftar Atasan');
    await worksheetLeader.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetLeader.getCell('A1').value = `Daftar Atasan`;
    await param.dataLeaders.forEach((user: any) => {
      worksheetLeader.addRow([user.name]);
    });

    // Data Attendance Template
    let worksheetAttendanceTemplate = workbook.addWorksheet('Daftar Template Absen');

    await worksheetAttendanceTemplate.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetAttendanceTemplate.getCell('A1').value = `Daftar Template Absen`;
    await param.dataAttendanceTemplates.forEach((user: any) => {
      worksheetAttendanceTemplate.addRow([user.name]);
    });

    // Daftar Single Option
    singleOptionSheetsUsers = createSingleOptionWorksheets(filteredCustomFieldUsers);

    if (typeAction === 'insert') {
      header = [
        ...nameHeader.insert.user.header,
        ...Object.keys(filteredCustomFieldUsers).map((field: any) => filteredCustomFieldUsers[field].name),
      ];

      subHeader = [
        ...nameHeader.insert.user.subHeader,
      ];

      if (type === 'error') {

        header.push('Error')
        subHeader.push(nameHeader.error)
        Array.from(param.listErrorInsert).map((row: any, index) => {
          var temp_excel = [];

          temp_excel.push(
            row.username,
            row.name,
            row.nik,
            row.phone,
            row.password,
            row.leader_name,
            row.city_name,
            row.role_name,
            row.attendanceTemplate_name,
            row.date_join,
            ...Object.keys(filteredCustomFieldUsers).map((field: any) =>
              row[field]
            ),
            row.error
          );

          exportData.push(temp_excel);
        });
      }

    } else if (typeAction === 'update') {
      await worksheet.protect('mjioerh3uiqWHEIOPHQWIOPEJHQWIO', {
        formatColumns: true,
        autoFilter: true
      });

      header = [
        ...nameHeader.update.user.header,
        ...Object.keys(filteredCustomFieldUsers).map((field: any) => filteredCustomFieldUsers[field].name),
      ];

      subHeader = [
        ...nameHeader.update.user.subHeader,
      ];

      if (type === 'error') {
        header.push('Error')
        subHeader.push(nameHeader.error)
        Array.from(param.listErrorUpdate).map((row: any, index) => {
          var temp_excel = [];

          temp_excel.push(
            btoa(row.id.slice(0, 3) + Math.floor(100 + Math.random() * 900) + row.id.slice(3)),
            row.name,
            row.nik,
            row.leader_name,
            row.city_name,
            row.role_name,
            row.attendanceTemplate_name,
            ...Object.keys(filteredCustomFieldUsers).map((field: any) => {
              if (row.customFields?.hasOwnProperty(field)) {
                // Tambahkan pengecekan untuk tipe 'option' di sini
                if (filteredCustomFieldUsers[field].type === 'option' && row.customFields[field] === undefined) {
                  return null;
                }
                return row.customFields[field];
              } else {
                return null;
              }
            }),
            row.error
          );

          exportData.push(temp_excel);
        });
      } else {
        param.dataTables.map((row: any) => {

          var temp_excel = [];

          temp_excel.push(
            btoa(row.id.slice(0, 3) + Math.floor(100 + Math.random() * 900) + row.id.slice(3)),
            row.name,
            row.nik,
            row.leader_name,
            row.city_name,
            row.role_name,
            row.attendanceTemplateName,
            ...Object.keys(filteredCustomFieldUsers).map((field: any) => {
              if (row.customFields?.hasOwnProperty(field)) {
                // Tambahkan pengecekan untuk tipe 'option' dan 'image' di sini
                if (filteredCustomFieldUsers[field].type === 'option' && row.customFields[field] === undefined) {
                  return null;
                }
                if (filteredCustomFieldUsers[field].type === 'image') {
                  return Array.isArray(row.customFields[field]) ? row.customFields[field][0] : row.customFields[field];
                }
                return row.customFields[field];
              } else {
                return null;
              }
            }),
            row.error
          );

          exportData.push(temp_excel)
        })
      }
    }

    worksheet.getRow(1).values = header
    worksheet.getRow(2).values = subHeader
    setColumnWidths(worksheet, source, header!, exportData);
    styleColumns(worksheet, source, 1);
    styleColumns(worksheet, source, 2);
    addDataRows(worksheet, exportData, source, type!, typeAction, user!, param, settings, singleOptionSheetsUsers);
    addWorkSheetInfo(worksheetInfo, source, nameFile, nameInfo, nameHeader, user!);
  } else if (source === 'template-pipeline') {
    header = [
      `Penanggung Jawab`,
      `Merchant`,
      `Nominal`,
      `Produk`,
      `Progress`,
      `Source`,
      `Tgl. Dimulai`,
      `Tgl. Selesai`,
    ];

    subHeader = [
      `Masukkan Penanggung jawab`,
      `Masukkan Merchant`,
      `Masukkan Nominal Ex: 10000`,
      `Masukkan Produk`,
      `Masukkan Progress`,
      `Masukkan Source`,
      `Ex: 2024-10-30 (YYYY-MM-DD HH:mm)`,
      `Ex: 2024-10-30 (Wajib diisi jika tipe progress BERHASIL/GAGAL)`,
    ];

    // Add "Note" column for Elgisa company after "Tgl. Dimulai"
    if (
      user?.data.company_id === 'd2f2a3da-3829-4872-8891-cae921d555b5' ||
      user?.data.company_id === '80ef97f0-63ca-4004-acec-30ca645d1b56' ||
      user?.data.company_id === '25fa94fd-7a2e-11ee-8848-02ce2ac3ab1a'
    ) {
      header.push(`Note`);
      subHeader.push(`Masukkan catatan`);
    }

    let worksheetUser = workbook.addWorksheet('Daftar User');
    await worksheetUser.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetUser.getCell('A1').value = `Daftar User`;
    await param.dataUsers.forEach((user: any) => {
      worksheetUser.addRow([user.name]);
    });

    let worksheetMerchants = workbook.addWorksheet('Daftar Merchants');
    await worksheetMerchants.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetMerchants.getCell('A1').value = `Daftar Merchants`;
    await param.dataMerchants.forEach((user: any) => {
      worksheetMerchants.addRow([user.name]);
    });

    let worksheetProducts = workbook.addWorksheet('Daftar Produk');
    await worksheetProducts.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetProducts.getCell('A1').value = `Daftar Produk`;
    await param.dataProducts.forEach((user: any) => {
      worksheetProducts.addRow([user.name]);
    });

    let worksheetProgress = workbook.addWorksheet('Daftar Progress');
    await worksheetProgress.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetProgress.getCell('A1').value = `Daftar Progress`;
    await param.dataProgress.forEach((user: any) => {
      worksheetProgress.addRow([user.name]);
    });

    let worksheetSource = workbook.addWorksheet('Daftar Source');
    await worksheetSource.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });

    worksheetSource.getCell('A1').value = `Daftar Source`;
    await param.dataSources.forEach((user: any) => {
      worksheetSource.addRow([user.name]);
    });

    // kalau type nya error akan menambahkan kolom di bawah ini
    if (type === 'error') {
      header.push('Error');
      subHeader.push('Abaikan kolom ini');
      Array.from(param.listErrorInsert).map((row: any, index) => {
        var temp_excel = [];
        temp_excel.push(
          row.pic_name,
          row.merchant_name,
          row.nominal,
          row.product_name,
          row.progress_name,
          row.source_name,
          row.date_start,
          row.date_end
        );

        // Menambahkan row.note jika kondisi terpenuhi
        if (
          user?.data.company_id === 'd2f2a3da-3829-4872-8891-cae921d555b5' ||
          user?.data.company_id === '80ef97f0-63ca-4004-acec-30ca645d1b56' ||
          user?.data.company_id === '25fa94fd-7a2e-11ee-8848-02ce2ac3ab1a'
        ) {
          temp_excel.push(row.note || '');
        }

        // Menambahkan row.error di akhir
        temp_excel.push(row.error);

        exportData.push(temp_excel);
      });
    }

    worksheet.getRow(1).values = header;
    worksheet.getRow(2).values = subHeader;
    setColumnWidths(worksheet, source, header!, exportData);
    styleColumns(worksheet, source, 1);
    styleColumns(worksheet, source, 2);
    addDataRows(worksheet, exportData, source, type!, typeAction, user!, typeAction);
    addWorkSheetInfo(worksheetInfo, source, nameFile, nameInfo, nameHeader, user!);
  } else if (source === 'template-unit') {
    if (typeAction === 'insert') {
      header = [...nameHeader.insert.unit.header];
      subHeader = [...nameHeader.insert.unit.subHeader];

      if (type === 'error') {
        header.push('Error');
        subHeader.push(nameHeader.error);
        Array.from(param.listErrorInsert).map((row: any, index) => {
          var temp_excel = [];

          temp_excel.push(row.name, row.alias, row.error);

          exportData.push(temp_excel);
        });
      }
    } else if (typeAction === 'update') {
      await worksheet.protect('mjioerh3uiqWHEIOPHQWIOPEJHQWIO', {
        formatColumns: true,
        autoFilter: true,
      });

      header = [...nameHeader.update.unit.header];
      subHeader = [...nameHeader.update.unit.subHeader];

      if (type === 'error') {
        header.push('Error');
        subHeader.push(nameHeader.error);
        Array.from(param.listErrorUpdate).map((row: any, index) => {
          var temp_excel = [];

          temp_excel.push(
            btoa(row.id.slice(0, 3) + Math.floor(100 + Math.random() * 900) + row.id.slice(3)),
            row.name,
            row.alias,
            row.error,
            row.color
          );

          exportData.push(temp_excel);
        });
      } else {
        param.dataTables.map((row: any) => {
          if (row.id) {
            var temp_excel = [];

            temp_excel.push(
              btoa(row.id.slice(0, 3) + Math.floor(100 + Math.random() * 900) + row.id.slice(3)),
              row.name,
              row.alias,
              '',
              row.color
            );

            exportData.push(temp_excel);
          }
        });
      }
    }

    worksheet.getRow(1).values = header;
    worksheet.getRow(2).values = subHeader;
    setColumnWidths(worksheet, source, header!, exportData);
    styleColumns(worksheet, source, 1);
    styleColumns(worksheet, source, 2);
    addDataRows(worksheet, exportData, source, type!, typeAction, user!, param);
    addWorkSheetInfo(worksheetInfo, source, nameFile, nameInfo, nameHeader, user!);
  } else if (source === 'template-product') {
    // Data Label
    let worksheetLabel = workbook.addWorksheet('Daftar Label');
    await worksheetLabel.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });
    worksheetLabel.getCell('A1').value = `Daftar Label`;
    await param.dataLabels.forEach((user: any) => {
      worksheetLabel.addRow([user.name]);
    });

    // Data Satuan
    let worksheetUnit = workbook.addWorksheet('Daftar Satuan');
    await worksheetUnit.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
      formatColumns: true,
      autoFilter: true,
    });
    worksheetUnit.getCell('A1').value = `Daftar Satuan`;
    await param.dataUnits.forEach((user: any) => {
      worksheetUnit.addRow([user.name]);
    });

    // Input Label Produk
    let worksheetProductLabel = workbook.addWorksheet('Label Produk (Opsional)');
    worksheetProductLabel.getRow(1).values = [...nameHeader.insert.productLabel.header];
    worksheetProductLabel.getRow(2).values = [...nameHeader.insert.productLabel.subHeader];
    setColumnWidths(worksheetProductLabel, source, [...nameHeader.insert.productLabel.header], exportData);
    styleColumns(worksheetProductLabel, source, 1);
    styleColumns(worksheetProductLabel, source, 2);

    // Input Unit Produk
    let worksheetProductUnit = workbook.addWorksheet('Satuan Produk (Opsional)');
    worksheetProductUnit.getRow(1).values = [...nameHeader.insert.productUnit.header];
    worksheetProductUnit.getRow(2).values = [...nameHeader.insert.productUnit.subHeader];
    setColumnWidths(worksheetProductUnit, source, [...nameHeader.insert.productUnit.header], exportData);
    styleColumns(worksheetProductUnit, source, 1);
    styleColumns(worksheetProductUnit, source, 2);

    if (typeAction === 'insert') {
      addDataRows(worksheetProductLabel, [], source, type!, typeAction, user!, param);
      addDataRows(worksheetProductUnit, [], source, type!, typeAction, user!, param);

      header = [...nameHeader.insert.product.header];
      subHeader = [...nameHeader.insert.product.subHeader];

      if (type === 'error') {
        header.push('Error');
        subHeader.push(nameHeader.error);
        Array.from(param.listErrorInsert).map((row: any, index) => {
          var temp_excel = [];

          temp_excel.push(row.name, row.sku, row.note, row.error);

          exportData.push(temp_excel);
        });
      }
    } else if (typeAction === 'update') {
      await worksheet.protect('mjioerh3uiqWHEIOPHQWIOPEJHQWIO', {
        formatColumns: true,
        autoFilter: true,
      });
      await worksheetProductLabel.protect('mjioerh3uiqWHEIOPHQWIOPEJHQWIO', {
        formatColumns: true,
        autoFilter: true,
      });
      await worksheetProductUnit.protect('mjioerh3uiqWHEIOPHQWIOPEJHQWIO', {
        formatColumns: true,
        autoFilter: true,
      });

      header = [...nameHeader.update.product.header];
      subHeader = [...nameHeader.update.product.subHeader];

      if (type === 'error') {
        header.push('Error');
        subHeader.push(nameHeader.error);
        Array.from(param.listErrorUpdate).map((row: any, index) => {
          var temp_excel = [];

          temp_excel.push(
            btoa(row.id.slice(0, 3) + Math.floor(100 + Math.random() * 900) + row.id.slice(3)),
            row.name,
            row.sku,
            row.note,
            row.error,
            row.color
          );

          exportData.push(temp_excel);
        });
      } else {
        let productLabelsData: any[] = [];
        let productUnitsData: any[] = [];

        param.dataTables.map((row: any) => {
          if (row.sku) {
            var temp_excel = [];

            temp_excel.push(
              btoa(row.id.slice(0, 3) + Math.floor(100 + Math.random() * 900) + row.id.slice(3)),
              row.name,
              row.sku,
              row.note,
              '',
              row.color
            );

            exportData.push(temp_excel);

            if (row.productLabels.length > 0) {
              row.productLabels.map((l: any) => {
                productLabelsData.push([{ formula: `Sheet1!C${exportData.length + 2}` }, l.label.name]);
              });
            }

            if (row.productUnits.length > 0) {
              row.productUnits.map((u: any) => {
                productUnitsData.push([
                  { formula: `Sheet1!C${exportData.length + 2}` },
                  u.unit.name,
                  u.convertionToPieces,
                ]);
              });
            }
          }
        });

        addDataRows(worksheetProductLabel, productLabelsData, source, type!, typeAction, user!, param);
        addDataRows(worksheetProductUnit, productUnitsData, source, type!, typeAction, user!, param);
      }
    }

    worksheet.getRow(1).values = header;
    worksheet.getRow(2).values = subHeader;
    setColumnWidths(worksheet, source, header!, exportData);
    styleColumns(worksheet, source, 1);
    styleColumns(worksheet, source, 2);
    addDataRows(worksheet, exportData, source, type!, typeAction, user!, param);
    addWorkSheetInfo(worksheetInfo, source, nameFile, nameInfo, nameHeader, user!);

    // Modification sheet order
    const _worksheets: any[] = (workbook as any)._worksheets;
    _worksheets[5].orderNo = 2;
    _worksheets[6].orderNo = 3;
    _worksheets[2].orderNo = 4;
    _worksheets[3].orderNo = 5;
    _worksheets[4].orderNo = 6;
  }

  exportWorkbook(workbook, worksheet, source, `${nameFile}.xlsx`, setIsLoading);
};

const addWorkSheetInfo = async (
  worksheetInfo: ExcelJS.Worksheet,
  source: string,
  nameFile: string,
  nameInfo: string,
  nameHeader: any,
  user: UserModel
) => {
  worksheetInfo.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
    formatColumns: true,
    autoFilter: true,
  });

  worksheetInfo.mergeCells('A1', 'B1');
  worksheetInfo.getCell('A1').value = nameHeader.info.fileInformation;
  worksheetInfo.getCell('A2').value = nameHeader.info.downloader;
  worksheetInfo.getCell('B2').value = `${user.data.name}`;
  worksheetInfo.getCell('A3').value = nameHeader.info.downloadTime;
  worksheetInfo.getCell('B3').value = `${moment().format('DD MMM YYYY HH:mm')}`;
  // if (source !== 'template-unit' && source !== 'template-product') {
  //   // excel satuan sama produk ga perlu peringatan ini
  //   worksheetInfo.getCell('A4').value = `Peringatan`;
  //   worksheetInfo.getCell(
  //     'B4'
  //   ).value = `Tipe custom field "Kotak Centang", "Gambar (Lebih dari 1)", "Berkas (Lebih dari 1)" hanya dapat diisi melalui web, tidak dapat diisi melalui Excel.`;
  // } else if (source === 'template-product') {
  //   worksheetInfo.getCell('A4').value = `Peringatan`;
  //   worksheetInfo.getCell(
  //     'B4'
  //   ).value = `Kolom Satuan tidak perlu diisi untuk produk dengan satuan PIECES (PCS). Sistem akan mengisi kolom Satuan secara otomatis.`;
  // }
  worksheetInfo.getCell('A4').value = nameHeader.info.type;
  worksheetInfo.getCell('B4').value = nameInfo;

  worksheetInfo.getRow(1).font = {
    name: 'Arial Black',
    size: 16,
  };
  worksheetInfo.getRow(1).alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  worksheetInfo.getCell('A4').font = {
    color: {
      argb: 'FFFFFFFF',
    },
  };
  worksheetInfo.getCell('B4').font = {
    color: {
      argb: 'FFFFFFFF',
    },
  };

  // worksheetInfo.getCell('A4').font = {
  //   color: {
  //     argb: 'FF5252',
  //   },
  // };
  // worksheetInfo.getCell('B4').font = {
  //   color: {
  //     argb: 'FF5252',
  //   },
  // };

  worksheetInfo.columns = [
    {
      width: 25,
    },
    {
      width: 30,
    },
  ];
};

const createWorkbook = () => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Looyal';
  workbook.lastModifiedBy = 'Looyal';
  workbook.created = new Date();
  workbook.modified = new Date();
  return workbook;
};

const createWorksheet = (workbook: ExcelJS.Workbook, sheetName: string) => {
  const worksheet = workbook.addWorksheet(sheetName);
  return worksheet;
};

const addDataRows = (
  worksheet: ExcelJS.Worksheet,
  data: any[],
  source: string,
  type: string,
  typeAction: string,
  user: UserModel,
  param?: any,
  settings?: any,
  singleOptionSheets?: any,
  selectTemplateMerchant?: any 
) => {
  data.forEach((rowData, index) => {
    worksheet.addRow(rowData);
  });

  let arrayCustomFieldUsers: CustomField = settings?.users_custom_fields
    ? JSON.parse(settings.users_custom_fields)
    : [];

  let arrayCustomFieldMerchants: CustomField = selectTemplateMerchant?.customFields
    ? selectTemplateMerchant?.customFields
    : [];

  let filteredCustomFieldUsers = Object.fromEntries(
    Object.entries(arrayCustomFieldUsers).filter(([key, value]) => {
      return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial';
    })
  );

  let filteredCustomFieldMerchants = Object.fromEntries(
    Object.entries(arrayCustomFieldMerchants).filter(([key, value]) => {
      return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial';
    })
  );

  // Function to add data validation for single option fields
  const addSingleOptionValidation = (startColumn: string, customFields: any) => {
    let currentColumn = startColumn.charCodeAt(0);
    Object.entries(customFields).forEach(([key, field]: [string, any]) => {
      if (field.type === 'option') {
        const columnLetter = String.fromCharCode(currentColumn);
        const worksheetName = singleOptionSheets[key]; // Use the unique worksheet name
        if (typeAction === 'update') {
          var dataLength = type === 'error' ? param.listErrorUpdate.length : param.dataTables.length;
        }
        for (let i = 3; i <= (typeAction === 'update' ? dataLength + 2 : 1000); i++) {
          worksheet.getCell(`${columnLetter}${i}`).dataValidation = {
            type: 'list',
            allowBlank: true,
            showErrorMessage: true,
            formulae: [`='${worksheetName}'!$A$2:$A$${field.value.length + 1}`],
            errorStyle: 'error',
            error: `Pilih ${field.name}`,
          };
        }
      }
      currentColumn++;
    });
  };

  if (source === 'template-merchant') {
    for (let i = 3; i <= 550; i++) {
      worksheet.getCell('B' + i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage: true,
        formulae: ["='Daftar User'!$A$2:$A$550"],
        errorStyle: 'error',
        error: 'Pilih user',
      };

      if (user?.data.company_id !== 'd2f2a3da-3829-4872-8891-cae921d555b5') {
        worksheet.getCell('C' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: ["='Daftar Kota'!$A$2:$A$550"],
          errorStyle: 'error',
          error: 'Pilih kota',
        };
      }
    }

    addSingleOptionValidation('F', filteredCustomFieldMerchants);
  } else if (source === 'template-user') {
    // pada template user di mulai dari kolom ke 11
    if (typeAction === 'insert') {
      for (let i = 3; i <= 550; i++) {
        worksheet.getCell('F' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [`='Daftar Atasan'!$A$2:$A$${param.dataLeaders.length + 2}`],
          errorStyle: 'error',
          error: 'Pilih atasan',
        };

        worksheet.getCell('G' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [`='Daftar Kota'!$A$2:$A$${param.dataCities.length + 2}`],
          errorStyle: 'error',
          error: 'Pilih kota',
        };

        worksheet.getCell('H' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [`='Daftar Role'!$A$2:$A$${param.dataRoles.length + 2}`],
          errorStyle: 'error',
          error: 'Pilih role',
        };

        worksheet.getCell('I' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [`='Daftar Template Absen'!$A$2:$A$${param.dataAttendanceTemplates.length + 2}`],
          errorStyle: 'error',
          error: 'Pilih template absen',
        };
      }

      addSingleOptionValidation('K', filteredCustomFieldUsers);
    } else if (typeAction === 'update') {
      var dataLength = type === 'error' ? param.listErrorUpdate.length : param.dataTables.length;
      for (let i = 3; i <= dataLength + 2; i++) {
        // kode di bawah ini untuk mengatur validaton pada excel
        worksheet.getCell('D' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [`='Daftar Atasan'!$A$2:$A$${param.dataLeaders.length + 2}`],
          errorStyle: 'error',
          error: 'Pilih atasan',
        };

        worksheet.getCell('E' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [`='Daftar Kota'!$A$2:$A$${param.dataCities.length + 2}`],
          errorStyle: 'error',
          error: 'Pilih kota',
        };

        worksheet.getCell('F' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [`='Daftar Role'!$A$2:$A$${param.dataRoles.length + 2}`],
          errorStyle: 'error',
          error: 'Pilih role',
        };

        worksheet.getCell('G' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [`='Daftar Template Absen'!$A$2:$A$${param.dataAttendanceTemplates.length + 2}`],
          errorStyle: 'error',
          error: 'Pilih template absen',
        };
      }

      addSingleOptionValidation('H', filteredCustomFieldUsers);

      worksheet.eachRow({ includeEmpty: true }, function (row, rowIndex) {
        if (rowIndex > 2 && rowIndex <= dataLength + 2) {
          for (let cellIndex = 2; cellIndex <= row.cellCount; cellIndex++) {
            row.getCell(cellIndex).protection = { locked: false };
          }
        }
      });
    }
  } else if (source === 'template-pipeline') {
    for (let i = 3; i < 550; i++) {
      // 3 ini di mulai setelah subheader
      if (typeAction === 'insert') {
        worksheet.getCell('A' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: ["='Daftar User'!$A$2:$A$550"],
          errorStyle: 'error',
          error: 'Pilih user',
        };
        worksheet.getCell('B' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: ["='Daftar Merchants'!$A$2:$A$550"],
          errorStyle: 'error',
          error: 'Pilih Merchants',
        };
        worksheet.getCell('D' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: ["='Daftar Produk'!$A$2:$A$550"],
          errorStyle: 'error',
          error: 'Pilih Produk',
        };
        worksheet.getCell('E' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: ["='Daftar Progress'!$A$2:$A$550"],
          errorStyle: 'error',
          error: 'Pilih Progress',
        };

        worksheet.getCell('F' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: ["='Daftar Source'!$A$2:$A$550"],
          errorStyle: 'error',
          error: 'Pilih Source',
        };

        worksheet.getCell('C' + i).numFmt = '#,##0;-#,##0';
      }
    }
  } else if (source === 'template-unit') {
    if (typeAction === 'update') {
      var dataLength = type === 'error' ? param.listErrorUpdate.length : param.dataTables.length;
      worksheet.eachRow({ includeEmpty: true }, function (row, rowIndex) {
        if (rowIndex > 2 && rowIndex <= dataLength + 2) {
          const cell2 = row.getCell(2);
          const cell3 = row.getCell(3);
          const cell5 = row.getCell(5); // cell 'Warna'

          cell2.protection = { locked: false };
          cell3.protection = { locked: false };

          cell5.font = {
            color: {
              argb: 'FFFFFFFF',
            },
          };
        }
      });
    }
  } else if (source === 'template-product') {
    if (worksheet.name === 'Label Produk (Opsional)') {
      for (let i = 3; i <= 500; i++) {
        worksheet.getCell('A' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [
            `='Sheet1'!$${typeAction === 'insert' ? 'B' : 'C'}$3:$${typeAction === 'insert' ? 'B' : 'C'}$${500 + 3
            }`,
          ],
          errorStyle: 'error',
          error: 'Pilih SKU',
        };

        worksheet.getCell('B' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [`='Daftar Label'!$A$2:$A$${param.dataLabels.length + 2}`],
          errorStyle: 'error',
          error: 'Pilih label',
        };
      }
    } else if (worksheet.name === 'Satuan Produk (Opsional)') {
      for (let i = 3; i <= 500; i++) {
        worksheet.getCell('A' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [
            `='Sheet1'!$${typeAction === 'insert' ? 'B' : 'C'}$3:$${typeAction === 'insert' ? 'B' : 'C'}$${500 + 3
            }`,
          ],
          errorStyle: 'error',
          error: 'Pilih SKU',
        };

        worksheet.getCell('B' + i).dataValidation = {
          type: 'list',
          allowBlank: false,
          showErrorMessage: true,
          formulae: [`='Daftar Satuan'!$A$2:$A$${param.dataUnits.length + 2}`],
          errorStyle: 'error',
          error: 'Pilih satuan',
        };
      }
    }

    if (typeAction === 'update') {
      if (worksheet.name === 'Sheet1') {
        var dataLength = type === 'error' ? param.listErrorUpdate.length : param.dataTables.length;

        worksheet.eachRow({ includeEmpty: true }, function (row, rowIndex) {
          if (rowIndex > 2 && rowIndex <= dataLength + 2) {
            const cell2 = row.getCell(2);
            const cell3 = row.getCell(3);
            const cell4 = row.getCell(4);
            const cell6 = row.getCell(6); // cell 'Warna'

            cell2.protection = { locked: false };
            cell3.protection = { locked: false };
            cell4.protection = { locked: false };
            cell6.font = {
              color: {
                argb: 'FFFFFFFF',
              },
            };
          }
        });
      } else if (
        worksheet.name === 'Label Produk (Opsional)' ||
        worksheet.name === 'Satuan Produk (Opsional)'
      ) {
        // worksheet.eachRow({ includeEmpty: true }, function (row, rowIndex) {
        for (let i = 3; i <= 500; i++) {
          const cell1 = worksheet.getCell('A' + i);
          const cell2 = worksheet.getCell('B' + i);

          cell1.protection = { locked: false };
          cell2.protection = { locked: false };

          if (worksheet.name === 'Satuan Produk (Opsional)') {
            const cell3 = worksheet.getCell('C' + i);
            cell3.protection = { locked: false };
          }
        }
      }
    }
  }
};

const setColumnWidths = (worksheet: ExcelJS.Worksheet, source: string, fields: string[], data: any) => {
  const colWidths = Object.keys(fields).map(() => ({ width: 25 }));
  const colStyles = Object.keys(fields).map(() => ({ numFmt: '@' }));

  if (worksheet.name === 'Satuan Produk (Opsional)') {
    colWidths[1] = { width: 43 };
    colWidths[2] = { width: 36 };
  }

  worksheet.columns = Object.keys(fields).map((field, index) => ({
    ...colWidths[index],
    style: colStyles[index],
  }));
};

const styleColumns = (worksheet: ExcelJS.Worksheet, source: string, indexRow: number) => {
  if (indexRow === 1) {
    worksheet.getRow(indexRow).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getRow(indexRow).font = {
      name: 'Arial Black',
      size: 12,
      bold: true,
    };
    worksheet.getRow(indexRow).eachCell(function (cell) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'F6991D',
        },
      };
    });
  } else if (indexRow === 2) {
    worksheet.getRow(indexRow).eachCell(function (cell) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'F8B219'
        },
      }
    });
  }



  var endRow = worksheet.actualRowCount;
};

const exportWorkbook = async (
  workbook: ExcelJS.Workbook,
  worksheet: ExcelJS.Worksheet,
  source: string,
  fileName: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    setIsLoading(false);
  });
};
