import React, { useRef, useEffect } from 'react'
import { Pipeline } from '../../../interfaces/Pipeline'
import { KTSVG } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import useHandleCloseModal from '../../../hooks/HandleCloseModal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { DatePicker } from 'rsuite'
import { customNumberFormatString, isBeforeToday, isValidUrl } from '../../../functions/general'
import { updatePaymentTermin } from '../../../api/PipelineCRUD'
import useAccessToken from '../../../hooks/AccessToken'
import { useSettingsStore } from '../../../stores/SettingsStore'
import RenderTerminCustomFieldDetail from '../RenderCustomFieldTermin/RenderTerminCustomFieldDetail'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import moment from 'moment'
import useTodayRange from '../../../hooks/TodayRange'
import { PaymentTermin } from '../../../interfaces/Termin/Termin'
import { CustomField } from '../../../interfaces/Settings'
import RenderTerminCustomFieldUpdate from '../RenderCustomFieldTermin/RenderTerminCustomFieldUpdate'
import { insertPaymentTerminAttachmentCustomField, insertPaymentTerminMultiAttachmentCustomField } from '../../../api/ResourceAPI'

interface UpdateDetailTerminProps {
    termin: PaymentTermin;
    pipeline: Pipeline;
    onSuccess: () => void;
    customFieldTermin: CustomField;
    terminNumber: number;
}

const UpdateDetailTermin = ({ termin, pipeline, onSuccess, customFieldTermin, terminNumber }: UpdateDetailTerminProps) => {
    const intl = useIntl()
    const token = useAccessToken()
    const modalRef = useRef<HTMLDivElement>(null)
    const closeModalRef = useRef<HTMLButtonElement>(null)
    const { settings } = useSettingsStore()
    const todayRange = useTodayRange()
    const inputRef = useRef<(HTMLInputElement | null)[]>([])

    const validationSchema = Yup.object().shape({
        nominal: Yup.string().required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.PAYMENT_AMOUNT' }) })),
        vatPercentage: Yup.string().nullable(),
        dateDue: Yup.string().required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.DEADLINE_PAYMENT' }) })),
        customFields: Yup.object().shape(
            Object.keys(customFieldTermin).reduce((acc: any, key: string) => {
                if (['image', 'images', 'file', 'files'].includes(customFieldTermin[key].type)) {
                    acc[key] = customFieldTermin[key].required
                        ? Yup.mixed()
                            .required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: customFieldTermin[key].name }))
                        : Yup.mixed().nullable();
                } else if (customFieldTermin[key].type === 'link') {
                    acc[key] = customFieldTermin[key].required
                        ? Yup.string()
                            .required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: customFieldTermin[key].name }))
                            .test('is-url', intl.formatMessage({ id: 'FORM.VALIDATION.MUST_LINK' }),
                                (value) => !value || isValidUrl(value))
                        : Yup.string()
                            .nullable()
                            .test('is-url', intl.formatMessage({ id: 'FORM.VALIDATION.MUST_LINK' }),
                                (value) => !value || isValidUrl(value));
                } else if (customFieldTermin[key].type === 'multiple') {
                    acc[key] = customFieldTermin[key].required
                        ? Yup.array()
                            .min(1, intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: customFieldTermin[key].name }))
                        : Yup.array().nullable();
                } else if (customFieldTermin[key].type === 'option') {
                    acc[key] = customFieldTermin[key].required
                        ? Yup.mixed()
                            .required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: customFieldTermin[key].name }))
                        : Yup.mixed().nullable();
                } else if (customFieldTermin[key].required) {
                    acc[key] = Yup.string()
                        .required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: customFieldTermin[key].name }));
                }
                return acc;
            }, {})
        ),
    })

    const formik = useFormik({ // note : inputType, togglePayment tidak ada di update termin hanya untuk type di RenderterminCustommField agar tidak error type
        initialValues: {
            inputType: 'nominal',
            togglePayment: false,
            vatPercentage: termin.vatPercentage == null ? "" : termin.vatPercentage.toString(),
            datePaid: termin.datePaid || null,
            nominal: customNumberFormatString(termin.nominalBeforeVat.toString()),
            dateDue: moment(termin.dateDue).format('YYYY-MM-DD'),
            receiptUrls: null as File | null | string[] | string,
            customFields: termin.customFields || {}
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            try {
                const nominal = Number(values.nominal.replaceAll(".", ""))

                for (let key in values.customFields) {
                    if (values.customFields[key] instanceof File) {
                        const response = await insertPaymentTerminAttachmentCustomField(values.customFields[key]);
                        if (response.success) {
                            values.customFields[key] = response.data[0];
                        }
                    } else if (Array.isArray(values.customFields[key]) && values.customFields[key].some((item: any) => item instanceof File)) {
                        const files = values.customFields[key].filter((item: any) => item instanceof File);
                        if (files.length > 0) {
                            const response = await insertPaymentTerminMultiAttachmentCustomField(files);
                            if (response.success) {
                                values.customFields[key] = response.data;
                            }
                        }
                    }
                }
                const vatPercentage = isNaN(Number(values.vatPercentage))? null : Number(values.vatPercentage)
                const updateData = {
                    leadId: termin.leadId,
                    nominal,
                    dateDue: values.dateDue,
                    vatPercentage: vatPercentage,
                    receiptUrls: termin.receiptUrls,
                    customFields: values.customFields
                }

                const response = await updatePaymentTermin(termin.id, updateData, token)
                if (response.success) {
                    Swal.fire(
                        intl.formatMessage({ id: 'FORM.CONFIRM_SUCCESS' }),
                        intl.formatMessage({ id: 'FORM.CONFIRM.TERMIN_UPDATED' }),
                        'success'
                    )
                    closeModalRef.current?.click()
                    onSuccess()
                }
            } catch (error) {
                console.error('Error updating payment termin:', error)
            }
        }
    })

    const handleNominalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? '0'
        const formattedValue = customNumberFormatString(value)
        formik.setFieldValue('nominal', formattedValue)
    }

    const handleDateChange = (date: Date | null) => {
        if (date === null) {
            formik.setFieldValue('dateDue', '')
        } else {
            const filterDate = moment(date).format('YYYY-MM-DD')
            formik.setFieldValue('dateDue', filterDate)
        }
    }
    const handleVATTerminChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Hapus semua karakter non-digit (termasuk titik)
        const rawValue = e.target.value.replace(/[^\d]/g, '');
    
    
        const formattedValue = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        if (Number(rawValue) > 100) {
            formik.setFieldError('vatPercentage', intl.formatMessage({ id: 'FORM.LABEL.PERCENTAGE_MAX' }));
            return;
          }
        formik.setFieldValue('vatPercentage', formattedValue);
        
    };

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik)

    return (
        <div className="modal fade" tabIndex={-1} id={`update-detail-termin-modal-${pipeline.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {intl.formatMessage({ id: "FORM.LABEL.UPDATE" })} {intl.formatMessage({ id: `FORM.LABEL.TERMIN_NUMBER` })} {terminNumber}
                        </h5>
                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={handleCloseModal}>
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}

                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label fs-6 fw-bolder text-dark required">
                                            {intl.formatMessage({ id: "FORM.LABEL.PAYMENT_AMOUNT" })}
                                        </label>
                                        <div className="input-group">
                                            <span className="input-group-text">Rp</span>
                                            <input
                                                type="text"
                                                inputMode="numeric"
                                                className={clsx('form-control', {
                                                    'is-invalid': formik.touched.nominal && formik.errors.nominal,
                                                })}
                                                {...formik.getFieldProps('nominal')}
                                                onChange={handleNominalChange}
                                            />
                                        </div>
                                        {formik.touched.nominal && formik.errors.nominal && (
                                            <div className="invalid-feedback d-block">
                                                {formik.errors.nominal}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* termin ppn */}
                                <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label fs-6 fw-bolder text-dark ">
                                    {intl.formatMessage({ id: "FORM.LABEL.VAT" })}
                                    </label>
                                    <div className="input-group">
                                        <span className="input-group-text">%</span>
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            className="form-control"
                                            value={formik.values.vatPercentage}
                                            onChange={handleVATTerminChange}
                                        />
                                    </div>
                                    {formik.touched.vatPercentage && formik.errors.vatPercentage && (
                                    <div className="invalid-feedback d-block">
                                    {formik.errors.vatPercentage}
                                    </div>
                                )}
                                </div>
                            </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label fs-6 fw-bolder text-dark required">
                                            {intl.formatMessage({ id: "FORM.LABEL.DEADLINE_PAYMENT" })}
                                        </label>
                                        <DatePicker
                                            value={formik.values.dateDue ? new Date(formik.values.dateDue) : null}
                                            onChange={handleDateChange}
                                            ranges={todayRange}
                                            oneTap
                                            editable={false}
                                            format="dd MMM yyyy"
                                            style={{ width: "100%" }}
                                            className={clsx('form-control p-1', {
                                                'is-invalid': formik.touched.dateDue && formik.errors.dateDue,
                                            })}
                                            shouldDisableDate={isBeforeToday}
                                            container={document.querySelector(`#update-detail-termin-modal-${pipeline.id}`) as HTMLElement}
                                        />
                                        {formik.touched.dateDue && formik.errors.dateDue && (
                                            <div className="invalid-feedback d-block">
                                                {formik.errors.dateDue}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <RenderTerminCustomFieldUpdate
                                    customFieldTermin={JSON.parse(settings.termins_custom_fields || '{}')}
                                    formik={formik}
                                    errors={formik.errors}
                                    pipeline={pipeline}
                                    inputRefs={inputRef}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light d-none" data-bs-dismiss="modal" ref={closeModalRef}>
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="button" className="btn btn-light" onClick={handleCloseModal}>
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="submit"
                                className={`btn btn-primary ${formik.isSubmitting ? 'disabled' : ''}`}
                                data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}
                                disabled={formik.isSubmitting}
                            >
                                <span className='indicator-label'>
                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateDetailTermin