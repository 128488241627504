import { useState, useEffect, useRef } from "react";

export function useTimer(duration: number, startTimer?: boolean) {
    const [countDown, setCountDown] = useState(duration);
    const timerRef = useRef<NodeJS.Timer | null>(null);

    useEffect(() => {
        if (startTimer && countDown > 0 && !timerRef.current) {
          timerRef.current = setInterval(() => {
            setCountDown(prev => {
              if (prev <= 1) {
                clearInterval(timerRef.current!);
                timerRef.current = null;
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
        }
    
        return () => {
          if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
          }
        };
      }, [countDown, startTimer]);

    const resetCountDown = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
        setCountDown(duration);
    };

    return { countDown, resetCountDown };
}