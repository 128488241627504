import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const EVENT = `${API_URL}calendars/`

type InsertEvent = {
    name: string
    description?: string
    type: string
    timezone: string
    status: string
    isAllDay: boolean
    start: {
        date: string | null
        dateTime: string | null
    }
    end?: {
        date: string | null
        dateTime: string | null
    }
    color: string
    userAttendees?: object
    merchantAttendees?: object
    recurrenceRule?: string | null
    reminderMinutes?: number | null
    location?: string | null
    idCalendar: string
    sendNotification: boolean
    onlineMeetLink: string | null
    token: string
}

type UpdateEvent = {
    name: string
    description?: string
    type: string
    timezone: string
    isAllDay: boolean
    status: string
    start: {
        date: string | null
        dateTime: string | null
    }
    end?: {
        date: string | null
        dateTime: string | null
    }
    color: string
    userAttendees?: object
    merchantAttendees?: object
    recurrenceRule?: string | null
    reminderMinutes?: number | null
    location?: string | null
    idCalendar: string
    idEvent: string
    sendNotification: boolean
    onlineMeetLink: string | null
    token: string
}

type UpdateEventTime = {
    startTime: string
    endTime: string
    idCalendar: string
    idEvent: string
    token: string
}

export const insertEvent = async ({
    name,
    description,
    type,
    timezone,
    status,
    isAllDay,
    start,
    end,
    color,
    userAttendees,
    merchantAttendees,
    recurrenceRule,
    reminderMinutes,
    location,
    idCalendar,
    sendNotification,
    onlineMeetLink,
    token,
}: InsertEvent) => {
    return axios.post(EVENT + idCalendar + '/events?sendNotification=' + sendNotification, {
        name,
        description,
        type,
        timezone,
        status,
        isAllDay,
        start,
        end,
        color,
        userAttendees,
        merchantAttendees,
        recurrenceRule,
        reminderMinutes,
        onlineMeetLink,
        location,
        idCalendar,
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updateEvent = async ({
    name,
    description,
    type,
    timezone,
    isAllDay,
    status,
    start,
    end,
    color,
    userAttendees,
    merchantAttendees,
    recurrenceRule,
    reminderMinutes,
    location,
    idCalendar,
    idEvent,
    sendNotification,
    onlineMeetLink,
    token,
}: UpdateEvent) => {
    return axios.put(EVENT + idCalendar + '/events/' + idEvent + '?sendNotification=' + sendNotification, {
        name,
        description,
        type,
        timezone,
        isAllDay,
        status,
        start,
        end,
        color,
        userAttendees,
        merchantAttendees,
        recurrenceRule,
        reminderMinutes,
        onlineMeetLink,
        location,
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updateEventTime = async ({
    startTime,
    endTime,
    idCalendar,
    idEvent,
    token,
}: UpdateEventTime) => {
    return axios.put(EVENT + idCalendar + '/events/' + idEvent + '/time', {
        startTime,
        endTime,
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const deleteEvent = async (idCalendar: string, idEvent: string, token: string) => {
    return axios.delete(EVENT + idCalendar + '/events/' + idEvent, {
        headers: {
            'X-Auth-token': token
        }
    })
}