import { FC, useRef, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import Swal from "sweetalert2";
import useAccessToken from "../../../hooks/AccessToken";
import { TemplatePipelineProps } from '../../../interfaces/Pipeline';
import { useIntl } from "react-intl";
import { deleteTemplatePipeline } from "../../../api/PipelineCRUD";
import { useTemplatePipeline } from "../../../stores/TemplatePipeline";

interface Props {
    type: string
    template: TemplatePipelineProps
    reset: () => void
}

const DeleteTemplatePipeline: FC<Props> = ({ type, template, reset }) => {
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const token = useAccessToken()
    const { userTemplatePipeline, companyTemplatePipeline, setUserTemplatePipeline, setCompanyTemplatePipeline } = useTemplatePipeline()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const intl = useIntl()

    const handleDeletePipeline = async () => {
        setModalBtnLoading(true)

        try {
            const response = await deleteTemplatePipeline(template.id, token)
            if (response.data.success) {
                if(template.content.use) reset()

                Swal.fire({
                    icon: 'success',
                    heightAuto: false,
                    title: `${intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" })}`,
                    timer: 2000,
                    timerProgressBar: true
                })
                
                closeModalRef.current?.click();
                if(type === 'delete-template-user') setUserTemplatePipeline(userTemplatePipeline.filter(item => item.id !== template.id));
                else if(type === 'delete-template-company') setCompanyTemplatePipeline(companyTemplatePipeline.filter(item => item.id !== template.id));
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`delete-template-pipeline-modal-${template.id}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.DELETE" })} {template.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>{intl.formatMessage({ id: 'FORM.CONFIRMATION' })} {intl.formatMessage({ id: 'FORM.ACTION.DELETE' }).toLowerCase()} {template.name}?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
                            </button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleDeletePipeline}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DeleteTemplatePipeline }